import React, { useContext, useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Pressable,
  Image,
  Dimensions,
  Platform,
  Modal,
  TextInput,
  ActivityIndicator,
} from "react-native";
// import {
//   H1,
//   H3,
//   H4,
//   P,
//   Label,
// } from "../../../../components/reusable/TextComponent";
import { colors } from "../../../../components/reusable/colour";
// import Icon from "react-native-vector-icons/Entypo";
// import { Checkbox, Tooltip } from "react-native-paper";
import AddIcon from "react-native-vector-icons/Feather";
import TrashIcon from "react-native-vector-icons/Ionicons";
import UploadImage from "../../../../components/reusable/UploadImage";
import { CustomDateField } from "../../../../components/reusable/textFeild";
import AsyncStorage from "@react-native-async-storage/async-storage";
// import { DataStore } from "aws-amplify/datastore";
import { uploadData } from "aws-amplify/storage";
import awsmobile from "../../../../aws-exports";
import {
  PlayerMaster,
  SquadPlayerMaster,
  TeamRegistrations,
  TeamRegistrationsPlayerMaster,
  Tournament,
  UserMaster,
} from "../../../../models";
import { AuthenticationContext } from "../../../../authentication/Authentication.js";
import { getUserMasterData } from "../../../../api/UserMasterAPI";
import { DataStore } from "aws-amplify/datastore";
import { AutoExitModalTrophy } from "../../../../components/reusable/AutoExitModal";
import {
  ErrorModal,
  ModalTrophy,
} from "../../../../components/reusable/AreYouSure";

// import defaultIcon from "../../../../../assets/Avtar/football-club.png";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

const TeamUpdate = ({
  visibleModal,
  RegHandleClose,
  // handleGetRegistrationdata,
  TeamUpdateObj,
}) => {
  const defaultIcon: any = require("../../../../../assets/Avtar/football-club.png");
  const bucketName = awsmobile.aws_user_files_s3_bucket;
  const [modalVisible, setModalVisible] = useState(visibleModal);
  const { createAdminUser, getAdminUser } = useContext(AuthenticationContext);
  const tournamentId = AsyncStorage.getItem("CurrentTournament");
  const [teamObject, setTeamObject]: any = useState();
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorModalMesg, setErrorModalMesg] = useState("");
  const [loading, setLoading] = useState(false);
  const [inputText, setInputText]: any = useState({
    teamName: "",
    coachName: "",
    managerName: "",
    coachMobileNo: "",
    managerMobileNo: "",
    coachphotoURL: "",
    coachUUID: "",
    TeamPhotoURL: "",
  });
  const [UserDetails, setUserDetails] = useState<any>();
  const [playerList, setPlayerList] = useState([]);
  const [SuccessModalVisible, setSuccessModalVisible] = useState(false);
  const [SunccessModalMesg, setSunccessModalMesg] = useState("");
  const handlegetPlayerlist = async (PlayerData) => {
    let playervalue = [];
    PlayerData.map((ele) => {
      playervalue.push({
        Name: ele.playerdata.Name,
        PhotoURL: ele.playerdata.PhotoURL,
        PhotoURI: {},
        DOB:
          ele.playerdata.DateOfBirth !== null ? ele.playerdata.DateOfBirth : "",
        UUID: ele.UserData !== undefined ? ele.UserData.UUID : "",
        id: ele.playerdata.id,
        ContactNo:
          ele.UserData?.ContactNo !== undefined ? ele.UserData?.ContactNo : "",
        contactflag:
          ele.UserData?.ContactNo !== undefined ? "exist" : "noexists",

        RolePlayer: ele.playerdata.RolePlayer,
        RoleCoach: ele.playerdata.RoleCoach,
        Jersey: ele.playerdata.JerseyNo,
        NewPlayer: "No",
      });
    });
    console.log("playervalue", playervalue);
    setPlayerList(playervalue);
  };

  const inputEvent = (field, event) => {
    console.log("hellotext", field, event);
    let values = event.target.value;
    setInputText((lastValue) => {
      return {
        ...lastValue,
        [field]: values,
      };
    });
  };

  const inputPlayerEvent = (field, event, idx) => {
    console.log("hellovalue", event.target.value);
    const updatedPlayerList = playerList.map((player, index) => {
      if (index === idx) {
        return { ...player, [field]: event.target.value };
      }
      return player;
    });
    setPlayerList(updatedPlayerList);
  };

  const handlegetURl = (uri, name, idx, filename) => {
    console.log("handlegetURl", filename, uri, name, idx);
    let obj = { URL: uri, FileName: filename };
    const updatedPlayerList = playerList.map((player, index) => {
      if (index === idx) {
        return { ...player, PhotoURL: uri, PhotoURI: obj };
      }
      return player;
    });
    setPlayerList(updatedPlayerList);
  };

  // const uploadLogoFileToStorage = async (folderName, key) => {
  //   // const contentType = key.split(";")[0].split(":")[1];
  //   console.log("folder name", folderName, "FileName", key.FileName);

  //   try {
  //     const response = await fetch(key.URL);
  //     const blob = await response.blob();
  //     let Response;
  //     await uploadData({
  //       key: `${folderName}/${key.FileName}`,
  //       data: blob,
  //     });
  //     Response = `https://${bucketName}.s3.ap-south-1.amazonaws.com/public/${folderName}/${key.FileName}`;
  //     return Response;
  //   } catch (err) {
  //     console.log("Error uploading file:", err);
  //   }
  // };

  const uploadLogoFileToStorage = async (folderName, key) => {
    // const contentType = key.split(";")[0].split(":")[1];
    console.log("folder name", folderName, "FileName", key.FileName);

    try {
      const response = await fetch(key.URL);
      const blob = await response.blob();
      let Response;
      await uploadData({
        key: `${folderName}/${key.FileName.replace(/ /g, "_")}`,
        data: blob,
      });
      Response = `https://${bucketName}.s3.ap-south-1.amazonaws.com/public/${folderName}/${key.FileName.replace(
        / /g,
        "_"
      )}`;
      return Response;
    } catch (err) {
      console.log("Error uploading file:", err);
    }
  };
  const handleDeletePlayer = async (index, ele) => {
    console.log("indexvalue", index, ele);
    // if(ele.UUID !== ""){
    try {
      const modelToDelete = await DataStore.query(PlayerMaster, ele.id);
      // const Response = await DataStore.delete(modelToDelete);
      // console.log("Response", Response);
      const deleteRecord = DataStore.delete(
        TeamRegistrationsPlayerMaster,
        (data) => data.playerMasterId.eq(modelToDelete.id)
      );
      console.log("deleteRecord", deleteRecord);
      if (deleteRecord) {
        await handlegetData(teamObject, "New");
      }
    } catch (error) {
      console.log("error occure", error);
    }
  };

  const handleNewAddPlayer = () => {
    const newRow = {
      ContactNo: "",
      Name: "",
      PhotoURL: "",
      DOB: "",
      UUID: "",
      RolePlayer: "",
      RoleCoach: "",
      Jersey: "",
      contactflag: "hello",
      NewPlayer: "Yes",
    };
    setPlayerList([...playerList, newRow]);
  };

  const getUserdata = async () => {
    let userDetails = await AsyncStorage.getItem("UserDetail");

    let datauser = await DataStore.query(UserMaster, userDetails);
    console.log("userDetails", datauser);
    setUserDetails(datauser);
    return datauser;
  };

  const playergetdata = async (data) => {
    // console.log("helloplayers", data);
    let Playerpromise: any = await [data.Players];
    let Playerdata = await Playerpromise[0].values;
    console.log("UniquePlayerdatalist", Playerdata);
    let finalplayerdata =
      Playerdata &&
      Playerdata.length > 0 &&
      Playerdata.map(async (ele) => {
        let MasterPlayer = await ele.playerMaster;
        // console.log("JersyNo", MasterPlayer);
        let Userdata = await MasterPlayer.PlayerData;
        let Playerdata = await MasterPlayer;
        let playerobj = {
          playerdata: Playerdata,
          UserData: Userdata,
          // Jersey: MasterPlayer.JerseyNo !== null ? MasterPlayer.JerseyNo : "",
        };
        return playerobj;
      });
    let final = [];
    if (Playerdata && Playerdata.length > 0) {
      final = await Promise.all(finalplayerdata);
    }

    let validPlayerData = [];
    validPlayerData =
      final && final.length > 0 && final.filter((data) => data !== undefined);
    console.log("validPlayerData", validPlayerData);
    let values =
      validPlayerData && validPlayerData.length > 0 ? validPlayerData : [];
    return values;
  };

  const handlegetData = async (key, value) => {
    console.log("hellomodal", value, SunccessModalMesg);
    const subscription = DataStore.observeQuery(TeamRegistrations, (c) =>
      c.id.eq(key)
    ).subscribe(async (snapshot) => {
      console.log("snapshot", snapshot);
      if (snapshot.isSynced == true) {
        const teamregistration: any = snapshot.items;
        console.log("teamregistration", teamregistration);

        setInputText({
          teamName: teamregistration[0].TeamName,
          coachName: teamregistration[0].Coach.Name,
          coachphotoURL: teamregistration[0].Coach.PhotoURL,
          coachUUID: teamregistration[0].Coach.UUID,
          TeamPhotoURL: teamregistration[0].Logo,
        });
        let data = await playergetdata(teamregistration[0]);
        await handlegetPlayerlist(data);

        if (value == "Edit") {
          console.log("closemodal");
          RegHandleClose();
        }
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  };
  useEffect(() => {
    getUserdata();
    console.log("TeamUpdateObj", TeamUpdateObj);
    handlegetData(TeamUpdateObj.RegistrationObj.id, "New");
    setTeamObject(TeamUpdateObj.RegistrationObj.id);
    console.log("TeamUpdateObj", TeamUpdateObj);
  }, []);

  const cognitoUserDetails = async (phoneNumber) => {
    const response = await getAdminUser(phoneNumber);
    if (response) {
      console.log("response", response);

      // console.log("UserDetails : ", response.statusCode);
    }

    return response;
  };

  const addUser = async (phoneNumber) => {
    let finalResponse;
    const userAttribute = [
      {
        Name: "phone_number" /* required */,
        Value: phoneNumber,
      },
    ];
    const response = await createAdminUser(phoneNumber, userAttribute).then(
      async (data) => {
        console.log("CreateUserResponse : ", data);
        finalResponse = data;
      }
    );
    console.log("response", response);
    return finalResponse;
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let PlayerResponse;
      let NumberexistinOntherPlayer = "";
      console.log("handleSubmit", playerList);
      let Teamdata: any = await DataStore.query(TeamRegistrations, teamObject);
      console.log("Teamobj", Teamdata);

      const updatedteamObj = TeamRegistrations.copyOf(Teamdata, (updated) => {
        updated.TeamName = inputText.teamName;
        updated.Logo = inputText.TeamPhotoURL;
        updated.Coach = {
          Name: inputText.coachName,
          UUID: inputText.coachUUID,
          PhotoURL: inputText.coachphotoURL,
        };

        return updated;
      });
      console.log("MatchObjUpdate", updatedteamObj);
      const ResultofteamRegistration = await DataStore.save(updatedteamObj);

      let playerCopy = JSON.parse(JSON.stringify(playerList));
      if (playerCopy && playerCopy.length > 0) {
        // await Promise.all(
        for (let i = 0; i < playerCopy.length; i++) {
          let ele = playerCopy[i];
          if (ele.NewPlayer == "Yes") {
            if (ele.PhotoURI && Object.keys(ele.PhotoURI).length > 0) {
              ele.PhotoURL = await uploadLogoFileToStorage(
                `tournament/${inputText.teamName.replace(/ /g, "_")}`,
                ele.PhotoURI
              );
            }
            if (ele.ContactNo !== "") {
              let res = await cognitoUserDetails("+91" + ele.ContactNo);
              console.log("cognitoUserDetails", res);
              if (res.statusCode === 400) {
                const resAddPlayer = await addUser("+91" + ele.ContactNo);
                console.log("RespfirstTime : ", ele, resAddPlayer);
                ele.UUID = resAddPlayer.User.Username;

                try {
                  let playerSummaryData = await DataStore.save(
                    new PlayerMaster({
                      Name: ele.Name,
                      JerseyNo: ele.Jersey,
                      PhotoURL: ele.PhotoURL,
                      DateOfBirth: ele.DOB,
                    })
                  );
                  let playerUserMasterData = await DataStore.save(
                    new UserMaster({
                      UUID: ele.UUID,
                      Name: ele.Name,
                      ContactNo: Number(ele.ContactNo),
                      EmailId: "",
                      PhotoURL: ele.PhotoURL,
                      CreatedBy: UserDetails.Name,
                      LastUpdatedBy: UserDetails.Name,
                      RoleCoach: false,
                      RolePlayer: true,
                      PlayerSummary: playerSummaryData,
                      DateOfBirth: ele.DOB,
                    })
                  );

                  const original = await DataStore.query(
                    PlayerMaster,
                    playerUserMasterData.userMasterPlayerSummaryId
                  );
                  if (playerUserMasterData.UUID) {
                    let updatedItem = PlayerMaster.copyOf(original, (ele) => {
                      ele.playerMasterPlayerDataUUID =
                        playerUserMasterData.UUID;
                    });
                    await DataStore.save(updatedItem);
                    PlayerResponse = await DataStore.save(
                      new TeamRegistrationsPlayerMaster({
                        teamRegistrationsId: ResultofteamRegistration.id,
                        playerMasterId: updatedItem.id,
                      })
                    );

                    console.log("Updated Item:", updatedItem);
                  } else {
                    console.error("Item not found");
                  }
                } catch (error) {
                  console.error("Error updating item 400:", error);
                  // setLoading(false);
                }
              } else {
                console.log("res2", res);
                let UserMasterdata = await getUserMasterData(res.Username);
                console.log("UserMasterdata", UserMasterdata);
                if (UserMasterdata !== undefined) {
                  if (
                    UserMasterdata &&
                    (UserMasterdata.userMasterPlayerSummaryId == "" ||
                      UserMasterdata.userMasterPlayerSummaryId == null)
                  ) {
                    try {
                      let PlayercreateObj = await DataStore.save(
                        new PlayerMaster({
                          Name: ele.Name,
                          PhotoURL: ele.PhotoURL,
                          JerseyNo: ele.Jersey,
                          DateOfBirth: ele.DOB !== "" ? ele.DOB : "2024-01-02",
                          PlayerData: UserMasterdata,
                        })
                      );
                      PlayerResponse = await DataStore.save(
                        new TeamRegistrationsPlayerMaster({
                          teamRegistrationsId: ResultofteamRegistration.id,
                          playerMasterId: PlayercreateObj.id,
                        })
                      );

                      console.log("squadPlayerMaster", PlayercreateObj);
                    } catch (e) {
                      console.log(
                        "Player Summary Error not Playermaster Exists : ",
                        e
                      );
                      // setLoading(false);
                    }
                  } else {
                    console.log("original");
                    console.log(
                      "UserMasterdata",
                      UserMasterdata.userMasterPlayerSummaryId,
                      ResultofteamRegistration.id
                    );
                    const usedata = await DataStore.query(
                      UserMaster,
                      UserMasterdata.UUID
                    );
                    console.log("usedata", usedata);
                    try {
                      let updatedUser = UserMaster.copyOf(
                        usedata,
                        (updated) => {
                          updated.Name = ele.Name;
                          updated.DateOfBirth =
                            ele.DOB !== "" ? ele.DOB : "2024-01-02";
                          return updated;
                        }
                      );
                      console.log("updatedItem", updatedUser);
                      let updatedUserdata = await DataStore.save(updatedUser);
                      console.log("updatedUserdata", updatedUserdata);
                      let playerdata = await DataStore.query(
                        PlayerMaster,
                        updatedUserdata.userMasterPlayerSummaryId
                      );
                      console.log("helloplayerdata", playerdata);
                      if (playerdata == undefined) {
                        try {
                          let playerSummaryData = await DataStore.save(
                            new PlayerMaster({
                              Name: ele.Name,
                              JerseyNo: ele.Jersey,
                              PhotoURL: ele.PhotoURL,
                              DateOfBirth:
                                ele.DOB !== "" ? ele.DOB : "2024-01-02",
                            })
                          );
                          console.log(
                            "helloplayerSummaryData",
                            playerSummaryData
                          );
                          let updatedItem = UserMaster.copyOf(
                            UserMasterdata,
                            (ele) => {
                              ele.PlayerSummary = playerSummaryData;
                            }
                          );
                          let userdata = await DataStore.save(updatedItem);

                          let updatedPlayermaster = PlayerMaster.copyOf(
                            playerSummaryData,
                            (ele) => {
                              ele.playerMasterPlayerDataUUID = userdata.UUID;
                            }
                          );
                          let resultplayermaster = await DataStore.save(
                            updatedPlayermaster
                          );
                          console.log(
                            "helloresultplayermaster",
                            resultplayermaster
                          );
                          PlayerResponse = await DataStore.save(
                            new TeamRegistrationsPlayerMaster({
                              teamRegistrationsId: ResultofteamRegistration.id,
                              playerMasterId: playerSummaryData.id,
                            })
                          );
                          console.log("PlayerResponse", PlayerResponse);
                        } catch (error) {}
                      } else {
                        let updatedPlayer = PlayerMaster.copyOf(
                          playerdata,
                          (updated) => {
                            updated.Name = ele.Name;
                            updated.DateOfBirth =
                              ele.DOB !== "" ? ele.DOB : "2024-01-02";
                            (updated.JerseyNo = ele.Jersey),
                              (updated.PhotoURL = ele.PhotoURL);
                            return updated;
                          }
                        );
                        let updatedUserdata = await DataStore.save(
                          updatedPlayer
                        );
                        console.log("updatedUserdata", updatedUserdata);
                        try {
                          PlayerResponse = await DataStore.save(
                            new TeamRegistrationsPlayerMaster({
                              teamRegistrationsId: ResultofteamRegistration.id,
                              playerMasterId:
                                UserMasterdata.userMasterPlayerSummaryId,
                            })
                          );
                          console.log("PlayerResponse", PlayerResponse);
                        } catch (error) {
                          console.log("error", error);
                        }
                      }
                    } catch (error) {
                      console.log("helloUser", error);
                    }
                  }
                } else {
                  console.log("UserMasterdatanotundefind", res);

                  try {
                    let playerSummaryData = await DataStore.save(
                      new PlayerMaster({
                        Name: ele.Name,
                        JerseyNo: ele.Jersey,
                        PhotoURL: ele.PhotoURL,
                        DateOfBirth: ele.DOB !== "" ? ele.DOB : "2024-01-02",
                      })
                    );
                    let playerUserMasterData = await DataStore.save(
                      new UserMaster({
                        UUID: res.Username,
                        Name: ele.Name,
                        ContactNo: Number(ele.ContactNo),
                        EmailId: "",
                        PhotoURL: ele.PhotoURL,
                        CreatedBy: UserDetails.Name,
                        LastUpdatedBy: UserDetails.Name,
                        RoleCoach: false,
                        RolePlayer: true,
                        PlayerSummary: playerSummaryData,
                        DateOfBirth: ele.DOB !== "" ? ele.DOB : "2024-01-02",
                      })
                    );

                    const original = await DataStore.query(
                      PlayerMaster,
                      playerUserMasterData.userMasterPlayerSummaryId
                    );

                    if (playerUserMasterData.UUID) {
                      let updatedItem = PlayerMaster.copyOf(original, (ele) => {
                        ele.playerMasterPlayerDataUUID =
                          playerUserMasterData.UUID;
                      });
                      let PlayerData = await DataStore.save(updatedItem);
                      PlayerResponse = await DataStore.save(
                        new TeamRegistrationsPlayerMaster({
                          teamRegistrationsId: ResultofteamRegistration.id,
                          playerMasterId: PlayerData.id,
                        })
                      );

                      console.log("Updated Item:", updatedItem);
                    } else {
                      console.error("Item not found");
                    }
                  } catch (error) {
                    console.error("Error updating item 400:", error);
                  }
                }
              }
            } else {
              try {
                let PlayerData = await DataStore.save(
                  new PlayerMaster({
                    Name: ele.Name,
                    PhotoURL: ele.PhotoURL,
                    JerseyNo: ele.Jersey,
                    DateOfBirth: ele.DOB !== "" ? ele.DOB : "2024-01-02",
                    // PlayerData: value,
                  })
                );

                PlayerResponse = await DataStore.save(
                  new TeamRegistrationsPlayerMaster({
                    teamRegistrationsId: ResultofteamRegistration.id,
                    playerMasterId: PlayerData.id,
                  })
                );

                console.log(
                  "Squad Playermaster data not number exist : ",
                  PlayerResponse
                );
              } catch (error) {
                console.log("error occure", error);
              }
            }
          } else {
            if (ele.PhotoURI && Object.keys(ele.PhotoURI).length > 0) {
              console.log("ele.PhotoURI", ele.PhotoURI);
              ele.PhotoURL = await uploadLogoFileToStorage(
                `tournament/${inputText.teamName.replace(/ /g, "_")}`,
                ele.PhotoURI
              );
            }

            console.log("hello zero", ele.PhotoURL);
            if (ele.contactflag == "noexists" && ele.ContactNo !== "") {
              console.log("hellovinod", ele);
              let res = await cognitoUserDetails("+91" + ele.ContactNo);
              console.log("MIMI2", res);
              if (res == "UserNotFoundException: User does not exist.") {
                console.log("insideExistplayeraddNumber");
                const resAddPlayer = await addUser("+91" + ele.ContactNo);
                console.log("Resp : ", resAddPlayer);
                ele.UUID = resAddPlayer.User.Username;

                const original = await DataStore.query(PlayerMaster, ele.id);
                try {
                  let playerUserMasterData = await DataStore.save(
                    new UserMaster({
                      UUID: ele.UUID,
                      Name: ele.Name,
                      ContactNo: Number(ele.ContactNo),
                      EmailId: "",
                      PhotoURL: ele.PhotoURL,
                      CreatedBy: UserDetails.Name,
                      LastUpdatedBy: UserDetails.Name,
                      RoleCoach: false,
                      RolePlayer: true,
                      PlayerSummary: original,
                      DateOfBirth: ele.DOB,
                    })
                  );

                  let updatedItem = PlayerMaster.copyOf(original, (ele) => {
                    ele.playerMasterPlayerDataUUID = playerUserMasterData.UUID;
                  });
                  PlayerResponse = await DataStore.save(updatedItem);
                  console.log("MimiupdatePlayerdata", PlayerResponse);
                } catch (error) {
                  console.log("error");
                }
              } else {
                try {
                  let UserMasterdata = await getUserMasterData(res.Username);
                  console.log("noexists", UserMasterdata);
                  if (UserMasterdata.userMasterPlayerSummaryId !== ele.id) {
                    console.log("hellobunny");
                    NumberexistinOntherPlayer = "Exists";
                    setErrorModalVisible(true);
                    setErrorModalMesg(
                      `The mobile number ${ele.ContactNo} is already associated with the user ${UserMasterdata.Name}! Please enter a different mobile number`
                    );
                  }
                } catch (error) {}
              }
            } else {
              if (ele.ContactNo == "") {
                console.log("helloexistingPlayer");
                let Playerdata = await DataStore.query(PlayerMaster, ele.id);
                try {
                  let updatePlayerMaster = PlayerMaster.copyOf(
                    Playerdata,
                    (update) => {
                      update.DateOfBirth = ele.DOB;
                      update.Name = ele.Name;
                      update.PhotoURL = ele.PhotoURL;
                      update.JerseyNo = ele.Jersey;
                    }
                  );
                  PlayerResponse = await DataStore.save(updatePlayerMaster);
                } catch (error) {}
              } else {
                let userdata = await DataStore.query(UserMaster, ele.UUID);
                let playerdata = await userdata.PlayerSummary;
                console.log("withoutNumber", playerdata, userdata);
                let updateuserMaster = UserMaster.copyOf(userdata, (update) => {
                  update.DateOfBirth = ele.DOB;
                  update.Name = ele.Name;
                  update.PhotoURL = ele.PhotoURL;
                });
                try {
                  const Response1 = await DataStore.save(updateuserMaster);
                  console.log("Response1", Response1);
                  if (Response1) {
                    let updatePlayerMaster = PlayerMaster.copyOf(
                      playerdata,
                      (update) => {
                        update.DateOfBirth = ele.DOB;
                        update.Name = ele.Name;
                        update.PhotoURL = ele.PhotoURL;
                        update.JerseyNo = ele.Jersey;
                        update.PlayerData = Response1;
                      }
                    );
                    PlayerResponse = await DataStore.save(updatePlayerMaster);
                    console.log("PlayerResponse", PlayerResponse);
                  }
                } catch (error) {
                  console.log("errorcome");
                }
              }
            }
          }
        }
        // );
      }
      console.log(
        "ResultofteamRegistration",
        PlayerResponse,
        ResultofteamRegistration
      );
      if (
        ResultofteamRegistration &&
        PlayerResponse &&
        NumberexistinOntherPlayer == ""
      ) {
        console.log("KIKI");
        setSuccessModalVisible(true);
        console.log("setSuccessModalVisible after:", SuccessModalVisible);
        setSunccessModalMesg("Team Update Successfully");
        // RegHandleClose();
        // await handlegetData(ResultofteamRegistration.id, "Edit");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // const handleSubmit = async () => {
  //   console.log("click on submit button");
  //   console.log("setSuccessModalVisible before:", SuccessModalVisible);
  //   setSuccessModalVisible(true);
  //   console.log("setSuccessModalVisible after:", SuccessModalVisible);
  //   setSunccessModalMesg("Team Update Successfully");
  // };
  const handleOk = () => {
    setSuccessModalVisible(false);
    RegHandleClose();
  };
  const handleSubmitError = () => {
    setErrorModalVisible(false);
  };
  return (
    <View>
      {/* modal started */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {}}
      >
        <View
          style={[styles.centeredView, { backgroundColor: colors.modalBG }]}
        >
          <View style={styles.modalView}>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                gap: 15,
                borderBottomWidth: 1,
                borderBottomColor: colors.aqua,
                paddingBottom: 10,
                marginBottom: 10,
              }}
            >
              {/* <Pressable onPress={() => pickImageAsync()}> */}
              <View style={{ gap: 10, alignItems: "center" }}>
                <UploadImage
                  imageUrl={
                    inputText.TeamPhotoURL !== "" &&
                    inputText.TeamPhotoURl !== null
                      ? inputText.TeamPhotoURL
                      : defaultIcon
                  }
                  index={0}
                />
                <Text
                  style={{
                    color: "red",
                    fontFamily: "PlusJakartaSansRegular",
                  }}
                >
                  (Size 280 X 280)
                </Text>
              </View>
              {/* </Pressable> */}

              <View style={{ gap: 10, flex: 1 }}>
                <View style={styles.teamModalHeadingWrapper2}>
                  <View style={styles.teamModalHeadingWrapper}>
                    <Text style={styles.teamModalLabel}>Team Name</Text>
                    <TextInput
                      editable={true}
                      style={styles.TeamModalInput}
                      placeholder={""}
                      value={inputText.teamName}
                      onChange={(text) => inputEvent("teamName", text)}
                    />
                  </View>
                </View>
                <View style={styles.teamModalHeadingWrapper2}>
                  <View style={styles.teamModalHeadingWrapper}>
                    <Text style={styles.teamModalLabel}>Coach Name</Text>
                    <TextInput
                      style={styles.TeamModalInput}
                      placeholder={""}
                      // value={coachName}
                      editable={true}
                      value={inputText.coachName}
                      onChange={(text) => inputEvent("coachName", text)}
                    />
                  </View>
                  <View style={styles.teamModalHeadingWrapper}>
                    <Text style={styles.teamModalLabel}>Coach Mobile No.</Text>
                    <TextInput
                      style={styles.TeamModalInput}
                      placeholder={""}
                      editable={true}
                      // value={coachMobileNo}
                      // onChangeText={setCoachMobileNo}
                      value={inputText.coachMobileNo}
                      onChange={(text) => inputEvent("coachMobileNo", text)}
                    />
                  </View>
                </View>
                <View style={styles.teamModalHeadingWrapper2}>
                  {/* <View style={styles.teamModalHeadingWrapper}>
                    <Text style={styles.teamModalLabel}>Manager Name</Text>
                    <TextInput
                      style={styles.TeamModalInput}
                      placeholder={""}
                      // value={adminName}
                      editable={false}
                      // onChangeText={setAdminName}
                      value={inputText.managerName}
                      onChange={(text) => inputEvent("managerName", text)}
                    />
                  </View> */}
                  {/* <View style={styles.teamModalHeadingWrapper}>
                    <Text style={styles.teamModalLabel}>
                      Manager Mobile No.
                    </Text>
                    <TextInput
                      style={styles.TeamModalInput}
                      placeholder={""}
                      // value={adminMobileNo}
                      editable={false}
                      value={inputText.managerMobileNo}
                      onChange={(text) => inputEvent("managerMobileNo", text)}
                      // onChangeText={setAdminMobileNo}
                    />
                  </View> */}
                </View>
              </View>
            </View>
            {/* table started here */}
            <View
              style={{
                justifyContent: "center",
                alignItems: "flex-end",
                padding: 6,
                marginTop: 0,
              }}
            >
              <Pressable
                style={styles.addTeamBtn}
                onPress={() => handleNewAddPlayer()}
              >
                <AddIcon name="plus-circle" size={16} color="#fff" />
                <Text
                  style={{
                    color: "#fff",
                    fontSize: 12,
                    fontFamily: "PlusJakartaSansSemiBold",
                  }}
                >
                  Add Player
                </Text>
              </Pressable>
            </View>
            <View
              style={{
                width: "100%",
                marginTop: 5,
                display:
                  Platform.OS === "web" && screenWidth > 760 ? "flex" : "none",
              }}
            >
              {/* table header started */}

              <View style={styles.tableHeaderContainer}>
                <View style={[styles.tableHeaderTitle]}>
                  <Text
                    style={[styles.tableHeaderTitleText, { textAlign: "left" }]}
                  >
                    Player
                  </Text>
                </View>
                <View style={[styles.tableHeaderTitle, { maxWidth: 140 }]}>
                  <Text
                    style={[styles.tableHeaderTitleText, { textAlign: "left" }]}
                  >
                    Mobile No
                  </Text>
                </View>
                <View style={[styles.tableHeaderTitle3]}>
                  <Text style={styles.tableHeaderTitleText}>Date of Birth</Text>
                </View>
                <View style={[styles.tableHeaderJTitle]}>
                  <Text style={styles.tableHeaderTitleText}>J No. </Text>
                </View>
                {/* <View style={[styles.tableHeaderTitle3]}>
                  <Text style={styles.tableHeaderTitleText}>Mobile No.</Text>
                </View> */}
                <View style={{ width: 30 }}></View>
              </View>
              <View
                style={{
                  maxHeight: 0.55 * screenHeight,
                  overflow: "scroll",
                }}
              >
                {playerList &&
                  playerList.length > 0 &&
                  playerList.map((ele, index) => {
                    return (
                      <>
                        <View style={styles.tableDataContainer}>
                          <View
                            style={[
                              styles.tableDataCell,
                              {
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                gap: 5,
                              },
                            ]}
                          >
                            <View style={{ width: 35, height: 35, padding: 0 }}>
                              <UploadImage
                                style={{ width: 35, height: 35, padding: 0 }}
                                imageUrl={
                                  ele.PhotoURL !== ""
                                    ? ele.PhotoURL
                                    : defaultIcon
                                }
                                //   onImageSelect={handleImageSelection}
                                index={index}
                                kindOfFile="logo"
                                onImageSelect={handlegetURl}
                              />
                            </View>
                            <TextInput
                              style={styles.TeamModalInput}
                              placeholder={"Enter Name"}
                              value={ele.Name}
                              onChange={(text) =>
                                inputPlayerEvent("Name", text, index)
                              }
                            />
                          </View>
                          <View
                            style={[
                              styles.tableDataCell,
                              {
                                justifyContent: "flex-start",
                                maxWidth: 140,
                              },
                            ]}
                          >
                            <TextInput
                              style={styles.TeamModalInput}
                              placeholder={"Enter Mob"}
                              value={ele.ContactNo}
                              onChange={(text) =>
                                inputPlayerEvent("ContactNo", text, index)
                              }
                              aria-disabled={
                                ele.contactflag == "exist" ? true : false
                              }
                            />
                          </View>
                          <View style={styles.tableDataCell3}>
                            <CustomDateField
                              label={undefined}
                              id={undefined}
                              containerStyle={{
                                // marginTop: -11,
                                // height: 29,
                                borderColor: "#d3d3d3",
                                background: "transparent",
                                marginBottom: 0,
                              }}
                              placeholder={undefined}
                              isRequired={undefined}
                              value={ele.DOB ? ele.DOB : null}
                              onChangeText={(text) =>
                                inputPlayerEvent("DOB", text, index)
                              }
                              onBlur={undefined}
                            />
                          </View>
                          <View style={styles.tableDataCell2}>
                            <TextInput
                              // editable={true}
                              style={styles.TeamModalInput}
                              placeholder={"J no"}
                              value={ele.Jersey}
                              onChange={(text) =>
                                inputPlayerEvent("Jersey", text, index)
                              }
                              //   value={data.JerseyNo}
                              // onChangeText={handleClickTime}
                            />
                          </View>
                          {/* <View style={styles.tableDataCell3}>
                            <TextInput
                              // editable={true}
                              style={styles.TeamModalInput}
                              placeholder={"Enter mobile no"}
                              value={ele.MobileNo}
                              onChange={(text) =>
                                inputPlayerEvent("MobileNo", text, index)
                              }
                            
                            />
                          </View> */}
                          <Pressable
                            style={styles.scoreSheetDeleteBtn}
                            onPress={() => handleDeletePlayer(index, ele)}
                          >
                            <TrashIcon
                              name="trash-bin-sharp"
                              size={18}
                              color="red"
                            />
                          </Pressable>
                        </View>
                      </>
                    );
                  })}
              </View>
              {/* table header ended */}
            </View>
            {/* table ended here */}
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                gap: 20,
                marginTop: 15,
              }}
            >
              <Pressable
                style={[
                  styles.btnPressable,
                  { borderWidth: 1.5, borderColor: colors.white },
                ]}
                onPress={() => RegHandleClose()}
              >
                <Text style={[styles.btnText, { color: colors.white }]}>
                  Cancel
                </Text>
              </Pressable>
              <Pressable
                style={[
                  styles.btnPressable,
                  {
                    backgroundColor: colors.aqua,
                    borderWidth: 1.5,
                    borderColor: colors.aqua,
                  },
                ]}
                onPress={() => {
                  handleSubmit();
                }}
              >
                <Text style={styles.btnText}>Submit</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
      {SuccessModalVisible == true ? (
        <ModalTrophy
          visibleModal={SuccessModalVisible}
          SuccessMessage={SunccessModalMesg}
          handleOk={handleOk}
        />
      ) : null}

      {errorModalVisible == true ? (
        <ErrorModal
          visibleModal={errorModalVisible}
          SuccessMessage={errorModalMesg}
          header={undefined}
          onSubmit={handleSubmitError}
        />
      ) : null}

      {loading && (
        <Modal transparent animationType="none">
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <ActivityIndicator size="large" color="#0000ff" />
            <Text style={{ color: "white" }}>Submitting</Text>
          </View>
        </Modal>
      )}
      {/* modal ended */}
    </View>
  );
};
const styles = StyleSheet.create({
  //
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    width: Platform.OS === "web" && screenWidth > 760 ? 700 : "95%",
    margin: 20,
    backgroundColor: colors.bgColor,
    borderRadius: 5,
    padding: 15,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    position: "relative",
  },
  //
  scoreTeamLogo: {
    width: 100,
    height: 100,
    borderRadius: 50,
    resizeMode: "contain",
  },
  teamModalHeadingWrapper2: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 15,
  },
  teamModalHeadingWrapper: {
    flex: 1,
  },
  teamModalLabel: {
    fontSize: 16,
    fontFamily: "PlusJakartaSansSemiBold",
    color: colors.white,
  },
  teamModalHeadingText: {
    fontSize: 14,
    fontFamily: "PlusJakartaSansRegular",
  },
  //   table
  tableHeaderContainer: {
    backgroundColor: colors.tableHeader,
    display: "flex",
    flexDirection: "row",
    paddingRight: 15,
    marginBottom: 5,
  },
  tableHeaderTitle: {
    flex: 1,
    paddingVertical: 7,
    padding: 5,
  },
  tableHeaderTitle3: {
    flex: 1,
    maxWidth: 140,
    paddingVertical: 7,
    padding: 5,
  },
  tableHeaderJTitle: {
    width: 60,
    paddingVertical: 7,
    padding: 5,
  },
  tableHeaderTitleText: {
    fontFamily: "PlusJakartaSansBold",
    fontSize: 14,
    color: colors.white,
    textAlign: "center",
  },
  tableDataContainer: {
    backgroundColor: colors.royalBlue,
    display: "flex",
    flexDirection: "row",
    marginTop: 5,
    borderRadius: 5,
  },

  tableDataCell: {
    flex: 1,
    paddingVertical: 5,
    paddingHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  tableDataCell2: {
    width: 60,
    paddingVertical: 5,
    paddingHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  tableDataCellText: {
    textAlign: "center",
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 14,
  },
  //   table ended
  btnText: {
    textAlign: "center",
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 14,
    color: colors.bgColor,
  },
  btnPressable: {
    width: 150,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },
  topPlayerCount: {
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    padding: 5,
    borderRadius: 5,
    width: 150,
    justifyContent: "center",
  },
  addTeamBtn: {
    backgroundColor: colors.orange,
    flexDirection: "row",
    gap: 5,
    alignItems: "center",
    justifyContent: "center",
    width: 100,
    borderRadius: 5,
    padding: 4,
  },
  //
  scoreSheetDeleteBtn: {
    width: 30,
    justifyContent: "center",
    alignItems: "center",
  },
  TeamModalInput: {
    borderColor: "#d3d3d3",
    borderWidth: 1,
    height: 30,
    width: "100%",
    borderRadius: 5,
    paddingHorizontal: 5,
    fontFamily: "PlusJakartaSansExtraLight",
    color: colors.white,
  },
  tableDataCell3: {
    flex: 1,
    maxWidth: 140,
    paddingVertical: 5,
    paddingHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
  },
});
export default TeamUpdate;
