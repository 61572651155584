import React from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  Dimensions,
} from "react-native";
import { colors } from "../../../../components/reusable/colour";
import TrophyIcon from "react-native-vector-icons/Ionicons";
import ColorPicker from "react-native-vector-icons/Ionicons";
import BarIcon from "react-native-vector-icons/Ionicons";
import MobileIcon from "react-native-vector-icons/Entypo";

const screenWidth = Dimensions.get("window").width;

const TournamentManagement = () => {
  return (
    <View style={styles.TMContainer}>
      <View style={styles.container}>
        <View style={styles.TMInsideContainer}>
          <View style={styles.TMLeft}>
            <Text style={styles.orangeHeading}>Tournament Management</Text>
            <Text style={styles.FeaturesHeading}>
              Effortlessly Streamline Tournament Registrations Across Football
              Tournaments
            </Text>
            <View style={styles.TMItemContainer}>
              <View style={styles.TMItem}>
                <View style={styles.TMItemHeadingWrapper}>
                  <TrophyIcon name="trophy-sharp" size={20} color="#ff5733" />
                  <Text style={styles.TMItemHeading}>Live Scoring</Text>
                </View>
                <Text style={styles.TMItemContent}>
                  Stay on top of the action with real-time scoring updates
                  during matches.
                </Text>
              </View>
              <View style={styles.TMItem}>
                <View style={styles.TMItemHeadingWrapper}>
                  <ColorPicker
                    name="color-palette-sharp"
                    size={20}
                    color="#ff5733"
                  />
                  <Text style={styles.TMItemHeading}>
                    Customizable Tracking
                  </Text>
                </View>
                <Text style={styles.TMItemContent}>
                  Tailor scoring rules, player stats, and match details to suit
                  your tournament's needs.
                </Text>
              </View>
              <View style={styles.TMItem}>
                <View style={styles.TMItemHeadingWrapper}>
                  <BarIcon name="bar-chart-sharp" size={20} color="#ff5733" />
                  <Text style={styles.TMItemHeading}> Match Insights</Text>
                </View>
                <Text style={styles.TMItemContent}>
                  Gain valuable insights into match dynamics with detailed
                  analytics, including possession, shot accuracy, and more.
                </Text>
              </View>
              <View style={styles.TMItem}>
                <View style={styles.TMItemHeadingWrapper}>
                  <MobileIcon name="mobile" size={20} color="#ff5733" />
                  <Text style={styles.TMItemHeading}>Mobile Accessibility</Text>
                </View>
                <Text style={styles.TMItemContent}>
                  Access all features on the go with our sleek and user-friendly
                  mobile app.
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.TMRight}>
            <Image
              source={require("../../../../../assets/features/rightSide.png")}
              style={styles.TMRightImage}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  TMContainer: {
    width: "100%",
    paddingVertical: 50,
    overflow: "hidden",
  },
  container: {
    maxWidth: 1320,
    width: "100%",
    height: "100%",
    marginHorizontal: "auto",
    paddingRight: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
    paddingLeft: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
  },
  TMInsideContainer: {
    flexDirection: "row",
    gap: 40,
  },
  TMLeft: {
    width: Platform.OS === "web" && screenWidth > 760 ? "50%" : "100%",
  },

  orangeHeading: {
    color: colors.orangeColour,
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 18 : 16,
    fontFamily:
      Platform.OS === "web" && screenWidth > 760
        ? "PlusJakartaSansSemiBold"
        : "PlusJakartaSansSemiBold",
    textTransform: "uppercase",
    marginBottom: 10,
  },
  FeaturesHeading: {
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 30 : 20,
    fontFamily:
      Platform.OS === "web" && screenWidth > 760
        ? "PlusJakartaSansSemiBold"
        : "PlusJakartaSansSemiBold",
    color: colors.white,
  },
  TMItemContainer: {
    flexDirection: "row",
    gap: 30,
    flexWrap: "wrap",
    marginTop: 40,
  },
  TMItem: {
    maxWidth: "50%",
    minWidth: "40%",
    flex: 1,
  },
  TMItemHeadingWrapper: {
    flexDirection: "row",
    gap: 5,
    alignItems: "center",
    marginBottom: 5,
  },
  TMItemHeading: {
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 20 : 16,
    color: colors.orange,
  },
  TMItemContent: {
    fontFamily: "PlusJakartaSansMedium",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 16 : 14,
    color: colors.white,
  },
  TMRight: {
    flex: 1,
    position: "relative",
    display: Platform.OS === "web" && screenWidth > 760 ? "flex" : "none",
  },
  TMRightImage: {
    position: "absolute",
    width: "100%",
    height: "100%",
    // resizeMode: "cover",
    right: -100,
  },
});

export default TournamentManagement;
