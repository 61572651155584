import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  Platform,
  Image,
  Pressable,
  Picker,
  Modal,
} from "react-native";
import { KnockOutTeamName } from "../../../../components/reusable/TextComponent";
import DropdownComponent from "../../../../components/reusable/DropdownComponent";
import { LinearGradient } from "expo-linear-gradient";
import { DataStore } from "aws-amplify/datastore";
import {
  GroupsMaster,
  MatchMaster,
  MatchMasterUserMaster,
  MatchTeamMaster,
  RoundMaster,
  Tournament,
  UserMaster,
} from "../../../../models";
import { KnockOutDesignFun } from "../../tournamentAdmin/knockOut/KnockOut";
import { colors } from "../../../../components/reusable/colour";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { RoundFormat } from "../../../../models";
import { MatchStatus } from "../../../../models";
import { ErrorModal } from "../../../../components/reusable/AreYouSure";
import { ActivityIndicator } from "react-native-paper";
const screenWidth = Dimensions.get("window").width;

const KnockOut4 = ({
  TournamentObj,
  KnockOutType,
  Roundobj,
  MatchTeamMasterPlayerUpdate,
  RoundFormatName,
}) => {
  const [TeamList, setTeamList] = useState([]);
  const [KnockOutStructure, setKnockOutStructure] = useState([]);
  const [knockoutRund1, setknockoutRund1] = useState([]);
  const [TypeOfknockOut, setTypeOfknockOut] = useState("");
  const [Tournamentobj, setTournamentobj] = useState<any>();
  const [RoundObj, setRoundObj] = useState<any>();
  const [UserDetails, setUserDetails] = useState(null);
  const [FixtureList, setFixtureList] = useState([]);
  const [WithRoundKnockOutList, setWithRoundKnockOutList] = useState([]);
  const [RounFormatname, setRoundFormatname] = useState("");
  const [winnerTeamName, setWinnerTeamName] = useState("");
  const [winnerTeamLogo, setWinnerTeamLogo] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorModalMesg, setErrorModalMesg] = useState("");
  const DefaultLogo: any = require("../../../../../assets/default/defaultLogo.png");
  const getdataForHowManyTeamSelect = async (typeofTeams) => {
    let matchlist = [];
    let data = await KnockOutDesignFun(typeofTeams, matchlist);
    console.log("helloknockOut", data);
    setKnockOutStructure(data);
  };
  const handlegetTournamentObj = async (tournamentObj) => {
    let TournamentObj = await DataStore.query(Tournament, tournamentObj.id);
    setTournamentobj(TournamentObj);
    let Registrationsdata = await TournamentObj?.Registrations?.toArray();
    let Registrationfinaldata = await Promise.all(Registrationsdata);
    console.log("TournamentObj", Registrationfinaldata, TournamentObj);
    let teamlistdata = Registrationfinaldata.filter(
      (ele) => ele.Status == "APPROVED"
    );
    let TeamsArr = [];
    teamlistdata.map((ele) => {
      TeamsArr.push(ele.TeamName);
    });

    console.log("TeamsArr", TeamsArr);
    let TeamArr2 = [];
    TeamsArr &&
      TeamsArr.length > 0 &&
      TeamsArr.map((ele) => {
        TeamArr2.push({
          label: ele,
          value: ele,
        });
      });
    console.log("TeamArr", TeamArr2);
    if (TeamsArr && TeamsArr.length > 0) {
      TeamArr2.push({
        label: "BYE",
        value: "BYE",
      });
    }
    setTeamList(TeamArr2);
  };

  const getUserdata = async () => {
    let userDetails = await AsyncStorage.getItem("UserDetail");
    let data = await DataStore.query(UserMaster, userDetails);
    setUserDetails(data);
  };
  useEffect(() => {
    getFixtures(Roundobj);
    getUserdata();
    handlegetTournamentObj(TournamentObj);
    console.log("KnockOutType", Roundobj, KnockOutType);
    getdataForHowManyTeamSelect(KnockOutType);
    setRoundObj(Roundobj);
    setTypeOfknockOut(KnockOutType);
    if (RoundFormatName) {
      console.log("RoundFormatName", RoundFormatName);
      setRoundFormatname(RoundFormatName);
    }
    // if (KnockOutType == "4") {
    //   setTypeOfknockOut(KnockOutType);
    // } else if (KnockOutType == "8") {
    //   setTypeOfknockOut(KnockOutType);
    // } else if (KnockOutType == "16") {
    //   setTypeOfknockOut(KnockOutType);
    // } else if (KnockOutType == "32") {
    //   setTypeOfknockOut(KnockOutType);
    // } else {
    //   setTypeOfknockOut("");
    // }
  }, [KnockOutType, TournamentObj, Roundobj, RoundFormatName]);

  const inputEvent = (event, idx, Team) => {
    // setCreateFixbtnFlag(true);
    console.log("inputevent", KnockOutStructure, event, idx, Team);
    let MatchArr = [...KnockOutStructure];

    MatchArr.forEach((obj, index) => {
      if (index == 0) {
        obj.value[idx].A = Team == "A" ? event : obj.value[idx].A;
        obj.value[idx].B = Team == "B" ? event : obj.value[idx].B;

        if (Team == "A") {
          obj.value[idx].FlagA = true;
        }
        if (Team == "B") {
          obj.value[idx].FlagB = true;
        }
      }
    });

    let RemoveDuplicates = [];

    MatchArr.map((ele) => {
      ele.value.forEach((obj) => {
        if (ele.Name == "Round1") {
          RemoveDuplicates.push(obj.A, obj.B);
        }
      });
    });
    console.log("RemoveDuplicates", RemoveDuplicates);

    let duplicates = RemoveDuplicates.filter(
      (item, index) => RemoveDuplicates.indexOf(item) != index
    );
    console.log("duplicates", duplicates);

    console.log("MatchArr", MatchArr);
    // console.log("SelectedCompetition", SelectedCompetition);

    // console.log("finalMatches", SelectedCompetition);

    let MatchValue = [];
    let lengthvalue = 0;
    let Winner = 0;
    // let lengthvalue1 = 0;
    for (let i = 1; i <= MatchArr.length; i++) {
      if (i < MatchArr.length) {
        MatchArr[i].value[0].NextValue = 0;
        MatchArr[i].value[0].Type = "";

        const currentValue = MatchArr[i].Name.split("Round")[1];
        const prevValue = MatchArr[i - 1].Name.split("Round")[1];

        if (currentValue > prevValue) {
          Winner = i - 1 == 1 ? 0 : Winner;
          console.log("greatervalue", currentValue, prevValue);
          lengthvalue = i - 1 == 0 ? MatchArr[i - 1].value.length : lengthvalue;

          console.log("lengthvalue", lengthvalue);
          MatchArr[i - 1].value.forEach((ele, index) => {
            if (MatchArr[i - 1].Name !== "Round1") {
              console.log(
                "Winnervalue8",
                MatchArr[i - 1].Name,
                MatchArr.length - 1,
                i + 1
              );
              console.log("hellloround");
              ele.B = `Winner of ${Winner + 1}`;
              Winner = Winner + 1;
              ele.A = `Winner of ${Winner + 1}`;
            }

            if (index % 2 == 0) {
              MatchArr[i - 1].value[index].NextValue = lengthvalue + 1;
              MatchArr[i - 1].value[index].Type = "Team2";
            } else {
              MatchArr[i - 1].value[index].NextValue = lengthvalue;
              MatchArr[i - 1].value[index].Type = "Team1";
            }
            lengthvalue = MatchArr[i - 1].value[index].NextValue;
            Winner = Winner + 1;
          });
        }
      } else {
        if (i == MatchArr.length) {
          console.log("Winner", Winner);
          MatchArr[i - 1].value.forEach((ele, index) => {
            if (MatchArr.length !== 2) {
              if (i == MatchArr.length) {
                ele.B = `Winner of ${Winner + 1}`;
                Winner = Winner + 1;
                ele.A = `Winner of ${Winner + 1}`;
              }
            } else {
              if (i == MatchArr.length) {
                ele.B = `Winner of 1`;
                Winner = Winner + 1;
                ele.A = `Winner of 2`;
              }
            }
          });
        }
      }
    }

    console.log("RoundMatchValue", MatchArr);
    setWithRoundKnockOutList(MatchArr);
    MatchArr.map((val) => {
      val.value.forEach((obj) => {
        obj.Round = val.Name;
        MatchValue.push(obj);
      });
    });

    if (MatchValue && MatchValue.length > 0) {
      console.log("MatchValue", MatchValue);
      setknockoutRund1(MatchValue);
    }
  };

  const handlePlayerdata = async (ele) => {
    let playersDataPromises = await ele.Players.toArray();
    let playersData = await Promise.all(playersDataPromises);
    console.log("playersData", playersData);
    let ArrayofPlayers = [];
    playersData.map(async (obj) => {
      let MasterPlayer = await obj.playerMaster;
      let Playerdata = await MasterPlayer.PlayerData;
      console.log("HelloPlayer", Playerdata);
      if (Playerdata !== undefined) {
        ArrayofPlayers.push(Playerdata);
      }
    });
    console.log("FinalPlayer", ArrayofPlayers);
    return ArrayofPlayers;
  };

  const handleCoachdata = async (ele) => {
    console.log("handleCoachdata", ele);
    let CoachdataPromise = await ele.Coach;
    return CoachdataPromise;
  };

  const handlegetGroupData = (RoundData) => {
    console.log("RoundData", RoundData);
    return new Promise(async (resolve) => {
      let subscription = await DataStore.observeQuery(GroupsMaster, (c) =>
        c.RoundMasterID.eq(RoundData.id)
      ).subscribe(async (snapshot) => {
        if (snapshot.isSynced == true) {
          console.log("snapshot", snapshot);
          let Groupdata = snapshot.items;
          console.log("Groupdata", Groupdata);
          resolve(Groupdata);
        }
      });
    });
  };

  const getFixtures = async (Rounddata) => {
    // let TournamentObj = await DataStore.query(Tournament, tournamentObj.id);
    // let MatchesData = await TournamentObj.Matches.toArray();
    // let MatchesPromises = await Promise.all(MatchesData);
    // console.log("Tournamentobj", TournamentObj);
    // console.log("MatchesPromises", MatchesPromises);
    console.log("Rounddata", Rounddata);
    if (Rounddata.FixturesCreated == true) {
      let Groupdata = await handlegetGroupData(Rounddata);
      console.log("Groupdata", Groupdata);
      let MatchesData = await Groupdata[0].Matches.toArray();
      let MatchesPromises = await Promise.all(MatchesData);

      if (MatchesPromises && MatchesPromises.length > 0) {
        MatchesPromises.sort((a, b) => a.MatchNo - b.MatchNo);
        let MatchObj = MatchesPromises[MatchesPromises.length - 1];
        let Teamfirst = await MatchObj.Team1;
        let TeamSecond = await MatchObj.Team2;
        let Team1Result =
          Teamfirst.Score.FirstHalf +
          Teamfirst.Score.SecondHalf +
          // Teamfirst.Score.PenaltyShootout +
          Teamfirst.Score.ExtraTime;
        let Team2Result =
          TeamSecond.Score.FirstHalf +
          TeamSecond.Score.SecondHalf +
          // TeamSecond.Score.PenaltyShootout +
          TeamSecond.Score.ExtraTime;

        if (Team1Result > Team2Result) {
          console.log("Team1Result", Teamfirst, Team1Result);
          setWinnerTeamName(Teamfirst.TeamName);
          setWinnerTeamLogo(Teamfirst.LogoURL);
        }
        if (Team2Result > Team1Result) {
          console.log("Team2Result", TeamSecond, Team2Result);
          setWinnerTeamName(TeamSecond.TeamName);
          setWinnerTeamLogo(TeamSecond.LogoURL);
        }
      }
      console.log("MatchesPromises", MatchesPromises);
      let MatchData = MatchesPromises.map(async (ele) => ({
        ...ele,
        Team1Obj: await ele.Team1,
        Team2Obj: await ele.Team2,
      }));
      let finalMatches = await Promise.all(MatchData);
      console.log("finalMatches", finalMatches);
      let RoundArray = [];
      let uniqueArray = [];
      finalMatches.map((ele) => {
        console.log("ele", ele);
        RoundArray.push(ele.Nextmatch?.RoundType);
        uniqueArray = Array.from(new Set(RoundArray));
        console.log("uniqueArray", uniqueArray);
      });
      // const reversedArray = uniqueArray;
      const reversedArray = uniqueArray.reverse();

      let finalvalue = [];
      reversedArray.map((ele) => {
        let arrvalue = [];
        finalMatches.map((obj) => {
          if (ele == obj.Nextmatch.RoundType) {
            let ScoreA = obj.Team1Obj.Score;
            let ScoreB = obj.Team2Obj.Score;
            arrvalue.push({
              A: obj.Team1Obj.TeamName,
              B: obj.Team2Obj.TeamName,
              LogourlA: obj.Team1Obj.TeamName.includes("Winner")
                ? null
                : obj.Team1Obj.LogoURL,
              LogourlB: obj.Team2Obj.TeamName.includes("Winner")
                ? null
                : obj.Team2Obj.LogoURL,
              Team1Score: obj.Team1Obj.TeamName.includes("Winner")
                ? 0
                : ScoreA.FirstHalf + ScoreA.SecondHalf + ScoreA.ExtraTime,
              Team2Score: obj.Team2Obj.TeamName.includes("Winner")
                ? 0
                : ScoreB.FirstHalf + ScoreB.SecondHalf + ScoreB.ExtraTime,
              MatchNo: obj.MatchNo,
            });
          }
        });
        finalvalue.push({
          Name: ele,
          value: arrvalue,
          // JSON.parse(arrvalue),
        });
      });
      console.log("finalvalue", finalvalue);
      if (finalvalue && finalvalue.length > 0) {
        setFixtureList(finalvalue);
      }

      let data = await KnockOutDesignFun(null, finalvalue);
      console.log("helloknockOut", data);

      const extractRoundNumber = (name) => parseInt(name.match(/\d+/)[0]);

      data.sort(
        (a, b) => extractRoundNumber(a.Name) - extractRoundNumber(b.Name)
      );
      console.log("SecondTIme", data);
      // data.reverse();
      setKnockOutStructure(data);
    }
  };

  const handleSubmit = () => {
    setErrorModalVisible(false);
  };

  // const handleFixtureCreate = async () => {
  //   console.log("handleFixtureCreate", knockoutRund1);
  // };

  const getPlayerlist = (playersData) => {
    let playermasterdata = [];
    playersData.map(async (obj) => {
      let MasterPlayer = await obj.playerMaster;

      playermasterdata.push({
        Name: MasterPlayer.Name,
        JerseyNo: MasterPlayer.JerseyNo,
        PhotoURL: MasterPlayer.PhotoURL,
        Position: "",
        Starting: true,
        PlayerId: MasterPlayer.id,
        Captain: false,
        Goalkeeper: false,
        TimePlayed: 0,
      });
    });

    return playermasterdata;
  };
  const handleFixtureCreate = async () => {
    setLoading(true);
    console.log("handleFixtureCreate", RoundObj, knockoutRund1, Tournamentobj);
    let Registrationsdata = await Tournamentobj.Registrations?.toArray();
    let Registrationfinaldata = await Promise.all(Registrationsdata);
    console.log("Registrationfinaldata", Registrationfinaldata);

    let Teamdata = Registrationfinaldata.map(async (ele) => ({
      ...ele,
      Playerdata: await handlePlayerdata(ele),
      Coachdata:
        (await handleCoachdata(ele)) == undefined
          ? {}
          : await handleCoachdata(ele),
    }));
    let finalTeamList = await Promise.all(Teamdata);
    console.log("finalTeamLists", finalTeamList, finalTeamList.length);

    let data = [...knockoutRund1];
    let KnockOutMatchArr = data.map((ele) => ({
      ...ele,
      Team1Obj: ele.A.includes("Winner")
        ? {
            TeamName: ele.A,
            Logo: "",
            id: "",
          }
        : ele.A == "BYE"
        ? {
            TeamName: ele.A,
            Logo: "",
            id: "",
          }
        : Registrationfinaldata.find((obj) => obj.TeamName == ele.A),
      Team2Obj: ele.B.includes("Winner")
        ? {
            TeamName: ele.B,
            Logo: "",
            id: "",
          }
        : ele.B == "BYE"
        ? {
            TeamName: ele.B,
            Logo: "",
            id: "",
          }
        : Registrationfinaldata.find((obj) => obj.TeamName == ele.B),
    }));
    console.log("KnockOutMatchArr", KnockOutMatchArr);

    let RemoveDuplicates = [];
    let CompareFlagAB = [];
    KnockOutMatchArr &&
      KnockOutMatchArr.length > 0 &&
      KnockOutMatchArr.map((ele) => {
        if (ele.Round == "Round1") {
          RemoveDuplicates.push(ele.A, ele.B);

          if (ele.FlagA == true && ele.FlagB == true) {
            CompareFlagAB.push(ele.A, ele.B);
          }
        }
      });
    console.log(
      "RemoveDuplicates",
      KnockOutMatchArr,
      CompareFlagAB,
      RemoveDuplicates
    );

    let KnockOutrr = [...KnockOutMatchArr];
    let KnockOutArr = [...KnockOutrr];
    console.log("KnockOutrr", KnockOutrr);
    let RoundList = [...WithRoundKnockOutList];

    let finalvalueofmatch = JSON.parse(JSON.stringify(KnockOutArr));
    // RoundList.map((val) => {
    //   val.value.map((obj) => {
    //     finalvalueofmatch.push(obj);
    //   });
    console.log("finalvalueofmatch", finalvalueofmatch);
    KnockOutArr.forEach((ele, index) => {
      finalvalueofmatch.map((obj, idx) => {
        console.log("first1 enter", `Winner of ${idx + 1}`, ele.A, ele.B);
        if (obj.A == "BYE" && obj.B == "BYE") {
          console.log("first1 enter", `Winner of ${idx + 1}`);
          if (ele.B == `Winner of ${idx + 1}`) {
            console.log("second enter", index);
            ele.B = "BYE";
          }
          if (ele.A == `Winner of ${idx + 1}`) {
            console.log("A second enter", `Winner of ${idx + 2}`);
            ele.A = "BYE";
          }
        } else if (obj.A == "BYE" && obj.B !== "BYE") {
          console.log("first2 enter", `Winner of ${idx + 1}`);
          if (ele.B == `Winner of ${idx + 1}`) {
            console.log("second enter", index);
            ele.B = obj.B;
            ele.Team2Obj = obj.Team2Obj;
          }
          if (ele.A == `Winner of ${idx + 1}`) {
            console.log("A second enter", `Winner of ${idx + 2}`);
            ele.A = obj.B;
            ele.Team1Obj = obj.Team2Obj;
          }
        } else if (obj.A !== "BYE" && obj.B == "BYE") {
          console.log("first3 enter", `Winner of ${idx + 1}`);
          if (ele.B == `Winner of ${idx + 1}`) {
            console.log("second enter", index);
            ele.B = obj.A;
            ele.Team2Obj = obj.Team1Obj;
          }

          if (ele.A == `Winner of ${idx + 1}`) {
            console.log("A second enter", `Winner of ${idx + 2}`);
            ele.A = obj.A;
            ele.Team1Obj = obj.Team1Obj;
          }
        }
      });
      // }
    });
    // });

    console.log("KnockOutArr", KnockOutArr);

    let AllTeamMergeArr = RemoveDuplicates.filter(
      (item, index) => RemoveDuplicates.indexOf(item) != index
    );

    let duplicates = AllTeamMergeArr.filter((ele) => ele !== "BYE");
    console.log("duplicates", duplicates);

    if (duplicates.length > 0) {
      // setConfirmation(true);
      // setmessageforError("you are selected one than more time single team");
      setErrorModalVisible(true);
      setErrorModalMesg("You are selected one than more time single team");
      // alert("you are selected one than more time single team");
    } else if (CompareFlagAB.length !== RemoveDuplicates.length) {
      setErrorModalVisible(true);
      setErrorModalMesg("you are not selected remaining Team dropdown");
    } else {
      let Arrr = [];
      KnockOutArr.map(async (ele) => {
        if (
          (ele.Team1Obj && !("Winner" in ele.Team1Obj)) ||
          (ele.Team2Obj && !("Winner" in ele.Team2Obj))
        ) {
          Arrr.push(
            {
              Teamid: ele.Team1Obj.id,
              TeamName: ele.Team1Obj.TeamName,
              Logo: ele.Team1Obj.Logo,
              TeamShortName: ele.Team1Obj.TeamShortName,
            },
            {
              Teamid: ele.Team2Obj.id,
              TeamName: ele.Team2Obj.TeamName,
              Logo: ele.Team2Obj.Logo,
              TeamShortName: ele.Team1Obj.TeamShortName,
            }
          );
        }
      });

      let GroupObj = {
        GroupName: "A",
        CreatedBy: UserDetails.Name,
        LastUpdatedBy: UserDetails.Name,
        RoundMasterID: RoundObj.id,
        Teams: Arrr,
      };
      console.log("UniqueArrrlist", KnockOutArr, GroupObj);
      try {
        const GroupResponse = await DataStore.save(new GroupsMaster(GroupObj));

        let format = RoundFormat;
        console.log("TeamListArrr", Arrr);
        const Roundmasterdata = await DataStore.query(RoundMaster, RoundObj.id);
        const updateRoundmaster = RoundMaster.copyOf(
          Roundmasterdata,
          (updated) => {
            updated.FixturesCreated = true;
            updated.Format = format.KNOCKOUT;
            // Assuming FixturesCreated is the correct property name
            return updated;
          }
        );

        const UpdateRoundObj = await DataStore.save(updateRoundmaster);
        console.log("Updated RoundObj:", UpdateRoundObj);
        console.log("GroupResponse", GroupResponse, KnockOutArr);
        console.log("AgainKnockoutPrint", KnockOutArr);
        KnockOutArr.forEach(async (ele, key) => {
          // // if (
          ////   (ele.Team1Obj && !("Winner" in ele.Team1Obj)) ||
          ////   (ele.Team2Obj && !("Winner" in ele.Team2Obj))
          //// ) {
          let team1playersData;
          let team2playersData;
          if (
            !ele.Team1Obj.TeamName.includes("Winner") ||
            !ele.Team2Obj.TeamName.includes("Winner")
          ) {
            let playersDataPromises1: any = await [ele.Team1Obj.Players];
            team1playersData = await playersDataPromises1[0].values;
            let playersDataPromises2: any = await [ele.Team2Obj.Players];
            team2playersData = await playersDataPromises2[0].values;
          }

          let TeamFirst = {
            TeamName: ele.Team1Obj.TeamName,
            LogoURL: ele.Team1Obj.Logo,
            TeamShortName: ele.Team1Obj.TeamShortName,
            TeamSubmitted:
              Tournamentobj.SubstitutionType == "ROLLING" ? true : false,
            Score: {
              FirstHalf: 0,
              SecondHalf: 0,
              PenaltyShootout: 0,
              ExtraTime: 0,
              Walkover: false,
            },
            Players:
              Tournamentobj.SubstitutionType == "ROLLING" &&
              !ele.Team1Obj.TeamName.includes("Winner")
                ? await getPlayerlist(team1playersData)
                : [],
            // Players:
            // Tournamentobj.SubstitutionType == "ROLLING"
            //   ? obj.TeamlistAB.Team2Player
            //   : [],
          };
          let TeamSecond = {
            TeamName: ele.Team2Obj.TeamName,
            LogoURL: ele.Team2Obj.Logo,
            TeamSubmitted:
              Tournamentobj.SubstitutionType == "ROLLING" ? true : false,
            TeamShortName: ele.Team2Obj.TeamShortName,
            Score: {
              FirstHalf: 0,
              SecondHalf: 0,
              PenaltyShootout: 0,
              ExtraTime: 0,
              Walkover: false,
            },
            Players:
              Tournamentobj.SubstitutionType == "ROLLING" &&
              !ele.Team2Obj.TeamName.includes("Winner")
                ? await getPlayerlist(team2playersData)
                : [],
          };
          console.log("Team1Team2OBJ", TeamFirst, TeamSecond);
          try {
            let team1Resp = await DataStore.save(
              new MatchTeamMaster(TeamFirst)
            );
            let team2Resp = await DataStore.save(
              new MatchTeamMaster(TeamSecond)
            );
            console.log("MatchTeamRespData", team1Resp, team2Resp);
            let MatchObj = {
              MatchNo: key + 1,
              groupsmasterID: GroupResponse.id,
              tournamentID: Tournamentobj.id,
              GroupName: GroupObj.GroupName,
              Team1: team1Resp,
              Team2: team2Resp,
              Nextmatch: {
                NextValue: ele.NextValue,
                RoundType: ele.Round,
                Type: ele.Type,
              },
              Status: MatchStatus.CREATED,
            };
            console.log("MatchObj", MatchObj);
            try {
              const MatchResponse = await DataStore.save(
                new MatchMaster(MatchObj)
              );
              console.log("MatchResponse", MatchResponse);
              if (MatchResponse) {
                let Teamfirst = await MatchResponse.Team1;
                let TeamSecond = await MatchResponse.Team2;
                let Team1Filtervalue = finalTeamList.filter(
                  (ele) =>
                    ele.TeamName.trim() == Teamfirst.TeamName.trim() ||
                    ele.TeamName.trim() == TeamSecond.TeamName.trim()
                );
                console.log("TeamFilter", Team1Filtervalue);
                let RealtionTabledataTeam1 = [];
                Team1Filtervalue.forEach((obj) => {
                  if (obj.Coachdata && Object.keys(obj.Coachdata).length > 0) {
                    RealtionTabledataTeam1.push({
                      userMasterUUID: obj.Coachdata.id,
                      matchMasterId: MatchResponse,
                    });
                  }
                  if (obj.Playerdata.length > 0) {
                    obj.Playerdata &&
                      obj.Playerdata.length > 0 &&
                      obj.Playerdata.forEach((ele) => {
                        RealtionTabledataTeam1.push({
                          userMasterUUID: ele.UUID,
                          matchMasterId: MatchResponse.id,
                        });
                      });
                  }
                });
                console.log("RealtionTabledataTeam1", RealtionTabledataTeam1);
                RealtionTabledataTeam1 &&
                  RealtionTabledataTeam1.length > 0 &&
                  RealtionTabledataTeam1.forEach(async (obj) => {
                    try {
                      const Response2 = await DataStore.save(
                        new MatchMasterUserMaster(obj)
                      );
                      console.log("responseData", Response2);
                    } catch (error) {
                      console.log("error", error);
                    }
                  });
                getFixtures(UpdateRoundObj);
                MatchTeamMasterPlayerUpdate(Tournamentobj.id, UpdateRoundObj);
              }
            } catch (error) {}
          } catch (error) {}

          // }
        });
      } catch (error) {
        console.log("error", error);
      }
    }
    setLoading(false);
  };

  return (
    <>
      {KnockOutStructure && KnockOutStructure.length > 0 ? (
        <LinearGradient
          style={styles.knockOutMainContainer}
          colors={[colors.orange, colors.blue]}
          start={{ x: 0, y: 1 }}
          end={{ x: 1, y: 1 }}
        >
          {KnockOutStructure &&
            KnockOutStructure.length > 0 &&
            KnockOutStructure.map((ele, idx) => {
              return (
                <View
                  style={
                    ele.Name == "Round1"
                      ? styles.knockOutColumn1
                      : ele.Name == "Round2"
                      ? styles.knockOutColumn2
                      : ele.Name == "Round3"
                      ? styles.knockOutColumn3
                      : ele.Name == "Round4" || ele.Name == "Round5"
                      ? styles.knockOutColumn4
                      : null
                  }
                  // style={styles.knockOutColumn1}
                >
                  {ele.value &&
                    ele.value.length > 0 &&
                    ele.value.map((obj, index) => {
                      return (
                        <View style={styles.KnockOutItem}>
                          {obj.MatchNo && (
                            <View style={styles.knockoutMatchNumberWrapper}>
                              <Text
                                style={styles.knockoutMnumberText}
                              >{`M 0${obj.MatchNo}`}</Text>
                            </View>
                          )}
                          <View style={styles.KnockOutItemTeam}>
                            <View style={styles.KnockOutItemTeamInner}>
                              <Image
                                source={
                                  obj.LogourlA !== "" && obj.LogourlA !== null
                                    ? {
                                        uri: obj.LogourlA,
                                      }
                                    : DefaultLogo
                                }
                                // source={{ uri: obj.LogourlA }}
                                style={styles.teamLogo}
                              />

                              {FixtureList && FixtureList.length == 0 ? (
                                <View style={{ flex: 1 }}>
                                  {ele.Name == "Round1" ? (
                                    <Picker
                                      style={styles.teamsDropdownContainer}
                                      onValueChange={(e) => {
                                        inputEvent(e, index, "A");
                                      }}
                                    >
                                      <Picker.Item
                                        label="Select Team"
                                        value="Select Team"
                                      />
                                      {TeamList &&
                                        TeamList.length > 0 &&
                                        TeamList.map((ele) => {
                                          return (
                                            <Picker.Item
                                              label={ele.label}
                                              value={ele.value}
                                            />
                                          );
                                        })}
                                    </Picker>
                                  ) : null}
                                </View>
                              ) : (
                                <KnockOutTeamName
                                  props={obj.A}
                                  style={{ flex: 1 }}
                                />
                              )}

                              <View style={styles.knockoutTeamScore}>
                                <Text
                                  style={[
                                    styles.knockoutTeamScoreNumber,
                                    {
                                      color:
                                        obj.Team1Score > obj.Team2Score
                                          ? colors.green
                                          : obj.Team1Score < obj.Team2Score
                                          ? colors.red
                                          : colors.white,
                                    },
                                  ]}
                                >
                                  {obj.Team1Score}
                                </Text>
                              </View>
                            </View>
                            {ele.Name == "Round2" ? (
                              <View style={styles.KKVertical1} />
                            ) : null}
                            {ele.Name == "Round3" ? (
                              <View style={styles.KKVertical3} />
                            ) : null}
                            {ele.Name == "Round4" ? (
                              <View style={styles.KKVertical5} />
                            ) : null}

                            {ele.Name == "Round5" ? (
                              <View style={styles.KKVertical7} />
                            ) : null}
                          </View>

                          <View style={styles.KnockOutItemTeam}>
                            <View style={styles.KnockOutItemTeamInner}>
                              <Image
                                source={
                                  obj.LogourlB !== "" && obj.LogourlB !== null
                                    ? {
                                        uri: obj.LogourlB,
                                      }
                                    : DefaultLogo
                                }
                                style={styles.teamLogo}
                              />

                              {FixtureList && FixtureList.length == 0 ? (
                                <View style={{ flex: 1 }}>
                                  {ele.Name == "Round1" ? (
                                    <Picker
                                      style={styles.teamsDropdownContainer}
                                      onValueChange={(e) => {
                                        inputEvent(e, index, "B");
                                      }}
                                    >
                                      <Picker.Item
                                        label="Select Team"
                                        value="Select Team"
                                      />
                                      {TeamList &&
                                        TeamList.length > 0 &&
                                        TeamList.map((ele) => {
                                          return (
                                            <Picker.Item
                                              label={ele.label}
                                              value={ele.value}
                                            />
                                          );
                                        })}
                                    </Picker>
                                  ) : null}
                                </View>
                              ) : (
                                <KnockOutTeamName
                                  props={obj.B}
                                  style={{ flex: 1 }}
                                />
                              )}

                              <View style={styles.knockoutTeamScore}>
                                <Text
                                  style={[
                                    styles.knockoutTeamScoreNumber,
                                    {
                                      color:
                                        obj.Team2Score > obj.Team1Score
                                          ? colors.green
                                          : obj.Team2Score < obj.Team1Score
                                          ? colors.red
                                          : colors.white,
                                    },
                                  ]}
                                >
                                  {obj.Team2Score}
                                </Text>
                              </View>
                            </View>
                            {ele.Name == "Round2" ? (
                              <View style={styles.KKVertical2} />
                            ) : null}
                            {ele.Name == "Round3" ? (
                              <View style={styles.KKVertical4} />
                            ) : null}
                            {ele.Name == "Round4" ? (
                              <View style={styles.KKVertical6} />
                            ) : null}
                            {ele.Name == "Round5" ? (
                              <View style={styles.KKVertical8} />
                            ) : null}
                          </View>
                          {ele.Name == "Round1" ||
                          ele.Name == "Round2" ||
                          ele.Name == "Round3" ||
                          ele.Name == "Round4" ? (
                            <View style={styles.horizontalLine} />
                          ) : null}
                          {ele.Name == "Round5" ? (
                            <View style={styles.horizontalLine2} />
                          ) : null}
                        </View>
                      );
                    })}
                </View>
              );
            })}

          {/* winner */}

          <View style={styles.knockOutColumn2}>
            <View style={styles.winnerChampContainer}>
              <Image
                source={require("../../../../../assets/Admin/tournament/knockout/newWinner.png")}
                style={styles.winnerTrophy}
              />
              {/* <View style={styles.winnerChampTextWrapper}>
            <Text style={styles.winnerChampText}>CHAMPION</Text>
          </View> */}
              <View style={styles.winnerAfterWinContainer}>
                <View style={styles.winnerChampTextWrapper2}>
                  <Image
                    source={
                      winnerTeamLogo !== "" && winnerTeamLogo !== null
                        ? {
                            uri: winnerTeamLogo,
                          }
                        : DefaultLogo
                    }
                    // source={require("../../../../../assets/logo/match_logo8.png")}
                    style={styles.teamLogo}
                  />
                  <Text style={styles.winnerChampText2}>{winnerTeamName}</Text>
                </View>
                {/* <Image
                  source={{ uri: winnerTeamLogo }}
                  style={styles.winnerChampionImage}
                /> */}
              </View>
            </View>
          </View>
        </LinearGradient>
      ) : null}
      {/* {Groupflag == true && FixtureCreateFlag == false ? ( */}
      {KnockOutStructure &&
      KnockOutStructure.length > 0 &&
      RoundObj &&
      RoundObj.FixturesCreated == false &&
      knockoutRund1.length > 0 ? (
        <View style={{ alignItems: "center" }}>
          <Pressable
            style={{
              backgroundColor: colors.royalBlueColour,
              paddingVertical: 6,
              paddingHorizontal: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 160,
              borderRadius: 5,
              marginTop: 10,
            }}
            onPress={handleFixtureCreate}
          >
            <Text
              style={{
                color: colors.white,
                fontFamily: "PlusJakartaSansMedium",
                fontSize: 16,
              }}
            >
              Create Fixture
            </Text>
          </Pressable>
        </View>
      ) : null}

      {errorModalVisible == true ? (
        <ErrorModal
          visibleModal={errorModalVisible}
          SuccessMessage={errorModalMesg}
          onSubmit={handleSubmit}
          header={"Attention"}
        />
      ) : null}

      {loading && (
        <Modal transparent animationType="none">
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <ActivityIndicator size="large" color="#0000ff" />
            <Text style={{ color: "white" }}>Loading</Text>
          </View>
        </Modal>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  knockOutMainContainer: {
    marginTop: 20,
    padding: screenWidth > 760 ? 20 : 5,
    display: "flex",
    flexDirection: "row",
    gap: 0,
    justifyContent: "flex-start",
    // paddingLeft: 100,
    overflow: screenWidth > 760 ? undefined : "scroll",
    paddingVertical: 70,
  },
  knockOutColumn1: {
    display: "flex",
    flexDirection: "column",
    width: screenWidth > 760 ? 250 : 185,
    gap: 180,
  },
  knockOutColumn2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: screenWidth > 760 ? 250 : 185,
    gap: 370,
  },
  KKVertical5: {
    position: "absolute",
    width: 2,
    height: 416,
    top: -416,
    backgroundColor: colors.white,
  },
  KKVertical6: {
    position: "absolute",
    width: 2,
    height: 416,
    bottom: -416,
    backgroundColor: colors.white,
  },
  KKVertical7: {
    position: "absolute",
    width: 2,
    height: 876,
    top: -876,
    backgroundColor: colors.white,
  },
  KKVertical8: {
    position: "absolute",
    width: 2,
    height: 876,
    bottom: -876,
    backgroundColor: colors.white,
  },
  KnockOutItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: 10,
    position: "relative",
    borderRightWidth: 6,
    borderRightColor: colors.blue,
  },
  KnockOutItemTeam: {
    height: screenWidth > 760 ? 40 : 30,
    width: "100%",
    backgroundColor: colors.white,
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  KnockOutItemTeamInner: {
    height: screenWidth > 760 ? 40 : 30,
    width: "100%",
    backgroundColor: colors.white,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    flexDirection: "row",
    gap: 5,
  },
  horizontalLine: {
    position: "absolute",
    width: screenWidth > 760 ? 124 : 92,
    height: 2,
    backgroundColor: colors.white,
    right: screenWidth > 760 ? -129 : -97,
    borderRadius: 10,
  },
  horizontalLine2: {
    position: "absolute",
    width: 54,
    height: 2,
    backgroundColor: colors.white,
    right: -54,
  },
  KKVertical1: {
    position: "absolute",
    width: 2,
    height: 89,
    top: -89,
    backgroundColor: colors.white,
  },
  KKVertical2: {
    position: "absolute",
    width: 2,
    height: 89,
    bottom: -89,
    backgroundColor: colors.white,
  },
  KKVertical3: {
    position: "absolute",
    width: 2,
    height: 186,
    top: -186,
    backgroundColor: colors.white,
  },
  KKVertical4: {
    position: "absolute",
    width: 2,
    height: 186,
    bottom: -186,
    backgroundColor: colors.white,
  },
  teamLogo: {
    width: screenWidth > 760 ? 35 : 25,
    height: screenWidth > 760 ? 35 : 25,
    borderRadius: 20,
    resizeMode: "contain",
    borderColor: "#b6b8dd",
    borderWidth: 1,
    marginLeft: 2,
  },
  knockoutTeamScore: {
    width: screenWidth > 760 ? 40 : 30,
    height: screenWidth > 760 ? 40 : 30,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.blue,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  knockoutTeamScoreNumber: {
    color: colors.white,
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 22,
  },
  winnerTrophy: {
    height: screenWidth > 760 ? 160 : 100,
    width: 160,
    resizeMode: "contain",
  },
  winnerChampText: {
    color: "#FFD028",
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 21,
    letterSpacing: 4,
  },
  winnerChampText2: {
    color: "#060959",
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 18,
  },
  winnerChampContainer: {
    gap: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  winnerChampTextWrapper: {
    backgroundColor: colors.red,
    paddingVertical: 4,
    paddingHorizontal: 40,
  },
  winnerChampTextWrapper2: {
    backgroundColor: colors.white,
    paddingVertical: 4,
    paddingHorizontal: 40,
    // clipPath: "polygon(10% 0,100% 0,90% 100%,0 100%)",
    flexDirection: "row",
    alignItems: "center",
    gap: 5,
  },
  winnerChampionImage: {
    height: 100,
    width: 300,
    resizeMode: "contain",
  },
  winnerAfterWinContainer: {
    flexDirection: "column",
    gap: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  knockOutColumn4: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: screenWidth > 760 ? 250 : 185,
    gap: 1750,
  },
  knockoutMatchNumberWrapper: {
    // clipPath: "polygon(10% 0,100% 0,90% 100%,0 100%)",
    paddingVertical: 4,
    paddingHorizontal: 10,
    backgroundColor: colors.blue,
    position: "absolute",
    // left: -70,
    left: 0,
    top: -29,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  knockoutMnumberText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 16,
  },
  knockOutColumn3: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: screenWidth > 760 ? 250 : 185,
    gap: 830,
  },
  teamsDropdownContainer: {
    width: "100%",
    marginBottom: 0,
    height: 34,
    borderRadius: 5,
    backgroundColor: colors.white,
    color: colors.royalBlue,
    borderColor: "transparent",
    fontFamily: "PlusJakartaSansMedium",
  },
});

export default KnockOut4;
