import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Pressable,
  Image,
  Dimensions,
  Platform,
  Modal,
  TextInput,
} from "react-native";
import {
  H1,
  H3,
  H4,
  Label,
} from "../../../../components/reusable/TextComponent";
import { colors } from "../../../../components/reusable/colour";
import Icon from "react-native-vector-icons/Entypo";
import { Checkbox, Tooltip } from "react-native-paper";
import { Button } from "react-native-elements/dist/buttons/Button";
import { DataStore } from "aws-amplify/datastore";
import { MatchTeamMaster, TeamRegistrations } from "../../../../models";
import SuccessModal from "../../../../components/reusable/SuccessModal";
import { AutoExitModalTrophy } from "../../../../components/reusable/AutoExitModal";
import { ErrorModal } from "../../../../components/reusable/AreYouSure";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

const AddPlayerModal = ({
  visibleModal,
  handleClose,
  TeamDetails,
  Tournamentdata,
  SelectedTeam,
  MatchTeamMasterPlayerUpdate,
  handleFetchData,
  Roundobj,
}) => {
  const [checked, setChecked] = React.useState(false);
  const [modalVisible, setModalVisible] = useState(visibleModal);
  const [PlayerList, setPlayerList] = useState([]);
  const [selectPlayerFlag, setselectPlayerFlag] = useState();
  const [PlayingPlayerList, setPlayingPlayerList] = useState([]);
  const [SubsitutePlayerList, setSubsitutePlayerList] = useState([]);
  const [Captainflag, setCaptainflag] = useState(false);
  const [Goalkeeperflagflag, setGoalkeeperflagflag] = useState(false);
  const [TOurnamentObj, setTOurnamentObj] = useState<any>();
  const [SelectedTeamObj, setSelectedTeamObj] = useState<any>();
  const [RoundData, setRoundData] = useState({});
  const [SuccessModalVisible, setSuccessModalVisible] = useState(false);
  const [SunccessModalMesg, setSunccessModalMesg] = useState("");

  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorModalMesg, setErrorModalMesg] = useState("");

  const getPlayerData = async (playerlist) => {
    console.log("playerlist", playerlist);
    let arrayOfMatches = playerlist.map(async (ele) => {
      let Array = {
        GoalKeeper: false,
        Captain: false,
        Playing: false,
        Subsitute: false,
        Captainflag: null,
        GoalKeeperflag: null,
        PlayerObj: ele,
        JerseyNo: ele.JerseyNo,
      };
      return Array;
    });
    console.log("Uniqueplayerdata", arrayOfMatches);
    // let value = playerlist.map(async (ele) => {
    //   let para = await ele._j;
    //   return para;
    // });
    // console.log("Playerobject", value);
    let final = await Promise.all(arrayOfMatches);
    console.log("finaldataPlayer", final);
    setPlayerList(final);
  };

  useEffect(() => {
    console.log("visibleModal", visibleModal, Tournamentdata);
    console.log("TeamDetails", TeamDetails, SelectedTeam);
    if (Tournamentdata && Object.keys(Tournamentdata).length > 0) {
      console.log("Tournamentdata", Tournamentdata);
      setTOurnamentObj(Tournamentdata);
    }
    setSelectedTeamObj(SelectedTeam);
    if (TeamDetails) {
      let playerlist = [...TeamDetails.playermasterList];
      console.log("playerlist", playerlist);
      getPlayerData(playerlist);
    }
    // if (Roundobj !== undefined) {
    setRoundData(Roundobj);
    // }
  }, [visibleModal, TeamDetails, SelectedTeam, Tournamentdata, Roundobj]);

  const handlecheckbox = (idx, playerobj) => {
    console.log("checkdvalue", idx, playerobj, playerobj.PlayerObj.id);
    setselectPlayerFlag(idx);
    let playerArray = [...PlayerList];
    if (playerobj.JerseyNo !== "") {
      console.log("helllochekbox2", playerobj);
      if (playerobj.Playing == false) {
        let PlayerObj = {
          PlayerId: playerobj.PlayerObj.id,
          UUID:
            playerobj.PlayerObj.UUID !== null ? playerobj.PlayerObj.UUID : "",
          PlayerName: playerobj.PlayerObj.Name,
          JerseyNo: playerobj.JerseyNo,
          GoalKeeper: false,
          Captain: false,
          PhotoURL: playerobj.PlayerObj.PhotoURL,
          Playing: true,
          Subsitute: false,
        };
        playerArray[idx].Captainflag = null;
        playerArray[idx].GoalKeeperflag = null;
        playerArray[idx].Playing = true;
        playerArray[idx].Subsitute = false;
        setPlayingPlayerList([...PlayingPlayerList, PlayerObj]);
      } else {
        const indexfind = PlayingPlayerList.findIndex(
          (element) => element.PlayerId == playerobj.PlayerObj.id
        );
        if (indexfind >= 0) {
          PlayingPlayerList[indexfind].GoalKeeper = false;
          PlayingPlayerList[indexfind].Captain = false;
          playerArray[idx].Playing = false;
          playerArray[idx].Subsitute = false;
          playerArray[idx].GoalKeeper = false;
          playerArray[idx].Captain = false;
          playerArray[idx].Captainflag = "";
          playerArray[idx].GoalKeeperflag = "";
          console.log("slice", indexfind);
          let arr = [...PlayingPlayerList];
          arr.splice(indexfind, 1);
          console.log("values", arr);
          setPlayingPlayerList(arr);
        }
      }
      setPlayerList(playerArray);
    } else {
      console.log("helllo no jersyno");
      setSuccessModalVisible(true);
      setSunccessModalMesg("Jersy No is Mandatory Please enter the Jersy No");
      playerArray[idx].Playing = false;
      setPlayerList(playerArray);
    }
  };

  const handleJersyNo = (value, index, playerobj) => {
    console.log("handlejersy", PlayerList, value, index, playerobj);
    let playerArray = [...PlayerList];
    if (playerArray && playerArray.length > 0) {
      console.log("hellofalse");
      playerArray[index].JerseyNo = value;
      setPlayerList(playerArray);

      if (PlayingPlayerList && PlayingPlayerList.length > 0) {
        const indexfind = PlayingPlayerList.findIndex(
          (element) => element.PlayerId == playerobj.PlayerObj.id
        );
        if (indexfind >= 0) {
          PlayingPlayerList[indexfind].JerseyNo = value;
        }
      }

      if (SubsitutePlayerList && SubsitutePlayerList.length > 0) {
        const indexfind1 = SubsitutePlayerList.findIndex(
          (element) => element.PlayerId == playerobj.PlayerObj.id
        );
        if (indexfind1 >= 0) {
          SubsitutePlayerList[indexfind1].JerseyNo = value;
        }
      }
    }
  };

  const handleSubsitute = (idx, playerobj) => {
    // console.log("hellokeeper", Goalkeeperflagflag);
    console.log("subsitute", idx, playerobj);
    let playerArray = [...PlayerList];
    console.log("checkvalue", idx, playerobj);
    if (playerobj.JerseyNo !== "") {
      if (playerobj.Subsitute == false) {
        let PlayerObj = {
          PlayerId: playerobj.PlayerObj.id,
          UUID:
            playerobj.PlayerObj.UUID !== null ? playerobj.PlayerObj.UUID : "",
          PlayerName: playerobj.PlayerObj.Name,
          JerseyNo: playerobj.JerseyNo,
          GoalKeeper: false,
          Captain: false,
          PhotoURL: playerobj.PlayerObj.PhotoURL,
          Playing: false,
          Subsitute: true,
        };
        playerArray[idx].Captainflag = null;
        playerArray[idx].GoalKeeperflag = null;
        playerArray[idx].Playing = false;
        playerArray[idx].Subsitute = true;

        setSubsitutePlayerList([...SubsitutePlayerList, PlayerObj]);
      } else {
        const indexfind = SubsitutePlayerList.findIndex(
          (element) => element.PlayerId == playerobj.PlayerObj.id
        );
        if (indexfind >= 0) {
          SubsitutePlayerList[indexfind].GoalKeeper = false;
          SubsitutePlayerList[indexfind].Captain = false;
          playerArray[idx].Subsitute = false;
          playerArray[idx].Playing = false;
          playerArray[idx].GoalKeeper = false;
          playerArray[idx].Captain = false;
          playerArray[idx].Captainflag = "";
          playerArray[idx].GoalKeeperflag = "";
          console.log("slice", indexfind);
          let arr = [...SubsitutePlayerList];
          arr.splice(indexfind, 1);
          console.log("values", arr);
          setSubsitutePlayerList(arr);
        }
      }

      console.log("playerArray", playerArray);
      setPlayerList(playerArray);
    } else {
      console.log("helllo no jersyno");
    }
  };

  const handleCaptain = (idx, playerobj) => {
    setCaptainflag(!Captainflag);
    console.log("Captain", playerobj);
    let playerArray = [...PlayerList];
    const indexfind = PlayingPlayerList.findIndex(
      (element) => element.PlayerId == playerobj.PlayerObj.id
    );
    if (playerobj.Captain == false) {
      if (indexfind >= 0) {
        playerArray[idx].Captain = true;
        PlayingPlayerList[indexfind].Captain = true;
      }
    } else {
      if (indexfind >= 0) {
        playerArray[idx].Captain = false;
        PlayingPlayerList[indexfind].Captain = false;
      }
    }
    setPlayerList(playerArray);
  };

  const handleGoalkeeper = (idx, playerobj) => {
    if (playerobj.Playing == true) {
      setGoalkeeperflagflag(!Goalkeeperflagflag);
    }

    console.log("goalkeeper", playerobj);
    let playerArray = [...PlayerList];

    const indexfind = PlayingPlayerList.findIndex(
      (element) => element.PlayerId == playerobj.PlayerObj.id
    );

    const idxfind = SubsitutePlayerList.findIndex(
      (element) => element.PlayerId == playerobj.PlayerObj.id
    );

    if (playerobj.GoalKeeper == false) {
      if (indexfind >= 0) {
        playerArray[idx].GoalKeeper = true;
        PlayingPlayerList[indexfind].GoalKeeper = true;
      }

      if (idxfind >= 0) {
        playerArray[idx].GoalKeeper = true;
        SubsitutePlayerList[idxfind].GoalKeeper = true;
      }
    } else {
      if (indexfind >= 0) {
        playerArray[idx].GoalKeeper = false;
        PlayingPlayerList[indexfind].GoalKeeper = false;
      }

      if (idxfind >= 0) {
        playerArray[idx].GoalKeeper = false;
        SubsitutePlayerList[idxfind].GoalKeeper = false;
      }
    }

    setPlayerList(playerArray);
  };

  const handleSubmit = async () => {
    console.log(
      "SubmitAction",
      PlayingPlayerList,
      SubsitutePlayerList,
      TOurnamentObj,
      SelectedTeamObj
    );
    let mergedArray = [...PlayingPlayerList, ...SubsitutePlayerList];
    console.log("mergedArray", mergedArray);
    let duplicates = mergedArray.filter(
      (item, index) => mergedArray.indexOf(item) != index
    );
    console.log("duplicates", duplicates);
    if (duplicates.length > 0) {
      setErrorModalVisible(true);
      setErrorModalMesg("Jersy No Should be Unique");
      // alert("Jersy No Should be Unique");
    } else {
      if (PlayingPlayerList.length == TOurnamentObj.Playing) {
        let Playerlist = [];
        mergedArray.map((ele) => {
          Playerlist.push({
            Name: ele.PlayerName.trim(),
            JerseyNo: ele.JerseyNo,
            PhotoURL: ele.PhotoURL,
            Position: "",
            Starting:
              ele.Playing == true
                ? true
                : ele.Subsitute == true
                ? false
                : false,
            PlayerId: ele.PlayerId,
            Captain: ele.Captain,
            Goalkeeper: ele.GoalKeeper,
            UUID: ele.UUID !== null ? ele.UUID : "",
            TimePlayed: 0,
          });
        });
        console.log("Playerlist", Playerlist);

        try {
          const getMatchTeamdata = await DataStore.query(
            MatchTeamMaster,
            SelectedTeamObj.id
          );
          if (getMatchTeamdata) {
            const updateMatchTeamSelectedObj = MatchTeamMaster.copyOf(
              getMatchTeamdata,
              (updated) => {
                updated.Players = Playerlist;
                updated.TeamSubmitted = true;
                return updated;
              }
            );

            const UpdatedMatchTeamObj = await DataStore.save(
              updateMatchTeamSelectedObj
            );

            console.log("Updated MatchTeamMaster:", UpdatedMatchTeamObj);
            if (UpdatedMatchTeamObj) {
              handleClose();
              if (RoundData !== undefined) {
                MatchTeamMasterPlayerUpdate(TOurnamentObj.id, RoundData);
              } else {
                handleFetchData();
              }
            }
          } else {
            console.error("Tournament not found");
          }
        } catch (error) {
          console.error("Error updating tournament:", error);
        }
      } else {
        setSuccessModalVisible(true);
        setSunccessModalMesg(
          `You need to select atleast ${TOurnamentObj.Playing} Players`
        );
      }
    }
  };
  const handleOk = () => {
    setSuccessModalVisible(false);
    setSunccessModalMesg("");
  };

  const handleSubmitError = () => {
    setErrorModalVisible(false);
  };
  return (
    <View>
      {/* modal started */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {}}
      >
        <View style={[styles.centeredView, { backgroundColor: "#04040491" }]}>
          <View style={styles.modalView}>
            {/* <Pressable
              style={{ position: "absolute", right: 5, top: 5, zIndex: 2 }}
              onPress={() => handleClose()}
            >
              <Icon name="cross" size={35} color="red" />
            </Pressable> */}

            <H3
              props={"Select players for match M-006 for Test 1 U17"}
              style={{
                marginBottom:
                  Platform.OS === "web" && screenWidth > 760 ? 15 : 5,
                color: colors.white,
              }}
            />

            {PlayerList.length > 0 ? (
              <View
                style={{
                  width: "100%",
                  marginTop: 15,
                  display: "flex",
                }}
              >
                <View
                  style={{
                    marginBottom: 15,
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View style={styles.topPlayerCount}>
                    <Text
                      style={[styles.teamModalLabel, { textAlign: "center" }]}
                    >
                      Playing - ({TOurnamentObj.Playing}/
                      {TOurnamentObj.Substitute})
                    </Text>
                    {/* <Text>
                    Playing - ({PlayingPlayerList.length}/
                    {TOurnamentObj.Playing})
                  </Text>
                  <Text>
                    Substitutes - ({SubsitutePlayerList.length}/
                    {TOurnamentObj.Substitute})
                  </Text> */}
                  </View>
                </View>
                {/* table header started */}
                <View style={styles.tableHeaderContainer}>
                  <View style={[styles.tableHeaderTitle, { flex: 4 }]}>
                    <Text
                      style={[
                        styles.tableHeaderTitleText,
                        { textAlign: "left" },
                      ]}
                    >
                      Player
                    </Text>
                  </View>
                  <View style={[styles.tableHeaderTitle]}>
                    <Text
                      style={[
                        styles.tableHeaderTitleText,
                        {
                          width:
                            Platform.OS === "web" && screenWidth > 760
                              ? null
                              : 40,
                        },
                      ]}
                    >
                      J No.
                    </Text>
                  </View>
                  <View style={[styles.tableHeaderTitle]}>
                    <Text style={styles.tableHeaderTitleText}>P</Text>
                  </View>
                  <View style={[styles.tableHeaderTitle]}>
                    <Text style={styles.tableHeaderTitleText}>S</Text>
                  </View>
                  <View style={[styles.tableHeaderTitle]}>
                    <Text style={styles.tableHeaderTitleText}>C</Text>
                  </View>
                  <View style={[styles.tableHeaderTitle]}>
                    <Text style={styles.tableHeaderTitleText}>GK</Text>
                  </View>
                </View>
                {/* table header ended */}
                <View
                  style={{ maxHeight: 0.55 * screenHeight, overflow: "scroll" }}
                >
                  {/* <Text>{PlayerList[0].PlayerObj.Name}</Text> */}
                  {PlayerList &&
                    PlayerList.length > 0 &&
                    PlayerList.map((ele, index) => {
                      return (
                        <View style={styles.tableDataContainer}>
                          <View
                            style={[
                              styles.tableDataCell,
                              {
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                gap: 5,
                                flex: 4,
                              },
                            ]}
                          >
                            <Image
                              source={require("../../../../../assets/Avtar/Avatar.png")}
                              style={{ width: 30, height: 30 }}
                              resizeMode="contain"
                            />
                            <Text style={styles.tableDataCellText}>
                              {ele.PlayerObj.Name}
                            </Text>
                          </View>
                          <View style={styles.tableDataCell}>
                            <Tooltip
                              title="Jersey Number"
                              theme={{ colors: { primary: "green" } }}
                            >
                              <TextInput
                                style={styles.input}
                                placeholder=""
                                value={ele.JerseyNo}
                                // onChangeText={handleInputChange}
                                onChangeText={(event) => {
                                  handleJersyNo(event, index, ele);
                                }}
                              />
                            </Tooltip>
                          </View>
                          <View style={styles.tableDataCell}>
                            <Tooltip title="Jersey Number">
                              <Checkbox
                                key={index}
                                status={
                                  ele.Playing == true ? "checked" : "unchecked"
                                }
                                onPress={() => {
                                  // (e) => handlecheckbox(e, index, obj)
                                  handlecheckbox(index, ele);
                                }}
                                disabled={
                                  (PlayingPlayerList.length ==
                                    (TOurnamentObj &&
                                      Object.keys(TOurnamentObj.length > 0) &&
                                      TOurnamentObj.Playing) &&
                                    ele.Playing == false &&
                                    PlayingPlayerList.length < index) ||
                                  (PlayingPlayerList.length ==
                                    (TOurnamentObj &&
                                      Object.keys(TOurnamentObj.length > 0) &&
                                      TOurnamentObj.Playing) &&
                                    PlayingPlayerList.length >= index &&
                                    ele.Playing == false) ||
                                  ele.Subsitute == true
                                    ? true
                                    : false
                                }
                              />
                            </Tooltip>
                          </View>
                          <View style={styles.tableDataCell}>
                            <Checkbox
                              key={index}
                              status={
                                ele.Subsitute == true ? "checked" : "unchecked"
                              }
                              onPress={() => handleSubsitute(index, ele)}
                              disabled={
                                (SubsitutePlayerList.length ==
                                  (TOurnamentObj &&
                                    Object.keys(TOurnamentObj.length > 0) &&
                                    TOurnamentObj.Subsitute) &&
                                  ele.Subsitute !== true &&
                                  SubsitutePlayerList.length < index) ||
                                (ele.Subsitute !== true && ele.Playing == true)
                                  ? true
                                  : false
                              }
                            />
                          </View>
                          <View style={styles.tableDataCell}>
                            <Checkbox
                              key={index}
                              status={
                                ele.Captain == true ? "checked" : "unchecked"
                              }
                              onPress={() => {
                                handleCaptain(index, ele);
                              }}
                              disabled={
                                ele.Captain == true && ele.Playing == true
                                  ? false
                                  : ele.Captain !== true &&
                                    ele.Playing == true &&
                                    Captainflag == false
                                  ? false
                                  : true
                              }
                            />
                          </View>
                          <View style={styles.tableDataCell}>
                            <Checkbox
                              key={index}
                              status={
                                ele.GoalKeeper == true ? "checked" : "unchecked"
                              }
                              onPress={() => {
                                handleGoalkeeper(index, ele);
                              }}
                              disabled={
                                ele.Subsitute == true && ele.GoalKeeper !== true
                                  ? false
                                  : ele.Subsitute == true &&
                                    ele.GoalKeeper == true
                                  ? false
                                  : ele.GoalKeeper == true &&
                                    ele.Playing == true
                                  ? false
                                  : ele.GoalKeeper !== true &&
                                    ele.Playing == true &&
                                    Goalkeeperflagflag == false
                                  ? false
                                  : true
                              }
                            />
                          </View>
                        </View>
                      );
                    })}
                </View>

                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 10,
                    gap: 10,
                    flexDirection: "row",
                  }}
                >
                  <Pressable
                    onPress={() => handleClose()}
                    style={{
                      backgroundColor: "transparent",
                      borderColor: colors.white,
                      borderWidth: 1,
                      width: 150,
                      height: 35,
                      borderRadius: 8,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        color: colors.white,
                        fontFamily: "PlusJakartaSansSemiBold",
                      }}
                    >
                      Cancel
                    </Text>
                  </Pressable>

                  {PlayingPlayerList && PlayingPlayerList.length > 0 ? (
                    <Pressable
                      onPress={handleSubmit}
                      style={{
                        backgroundColor: colors.aqua,
                        width: 150,
                        height: 35,
                        borderRadius: 8,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          color: colors.royalBlue,
                          fontFamily: "PlusJakartaSansSemiBold",
                        }}
                      >
                        Submit
                      </Text>
                    </Pressable>
                  ) : null}
                </View>
              </View>
            ) : (
              <View>
                <Text>There are no player in this team</Text>
              </View>
            )}
          </View>
        </View>
      </Modal>
      {SuccessModalVisible == true ? (
        <ErrorModal
          visibleModal={SuccessModalVisible}
          SuccessMessage={SunccessModalMesg}
          onSubmit={handleOk}
          header={undefined}
        />
      ) : // <SuccessModal
      //   visibleModal={SuccessModalVisible}
      //   handleClose={undefined}
      //   SuccessMessage={SunccessModalMesg}
      //   handleConfirm={undefined}
      //   handleOk={handleOk}
      // />
      null}

      {errorModalVisible == true ? (
        <ErrorModal
          visibleModal={errorModalVisible}
          SuccessMessage={errorModalMesg}
          onSubmit={handleSubmitError}
          header={"Attention"}
        />
      ) : null}
      {/* modal ended */}
    </View>
  );
};
const styles = StyleSheet.create({
  //
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    width: Platform.OS === "web" && screenWidth > 760 ? 600 : 380,
    margin: 20,
    backgroundColor: colors.royalBlue,
    borderRadius: 5,
    padding: 15,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    position: "relative",
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  // buttonOpen: {
  //   backgroundColor: "#F194FF",
  // },
  // buttonClose: {
  //   backgroundColor: "#2196F3",
  // },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },

  // new table
  tableHeaderContainer: {
    backgroundColor: colors.blue,
    display: "flex",
    flexDirection: "row",
    paddingRight: 15,
  },
  tableHeaderTitle: {
    flex: 1,
    paddingVertical: 7,
    padding: 5,
  },
  tableHeaderTitleSN: {
    width: 50,
    paddingVertical: 7,
  },
  tableHeaderTitleLoGo: {
    width: 80,
    paddingVertical: 7,
  },
  tableHeaderTitleText: {
    fontFamily: "PlusJakartaSansBold",
    fontSize: 14,
    color: colors.white,
    textAlign: "center",
  },
  tableDataContainer: {
    backgroundColor: colors.bgColor,
    display: "flex",
    flexDirection: "row",
    marginTop: 5,
    borderRadius: 5,
  },

  tableDataCell: {
    flex: 1,
    paddingVertical: 5,
    paddingHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  tableDataCellSN: {
    width: 50,
    paddingVertical: 5,
    paddingHorizontal: 5,
    justifyContent: "center",
  },
  tableDataCellLogo: {
    width: 80,
    paddingVertical: 5,
    paddingHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  tableDataCellText: {
    textAlign: "left",
    fontFamily: "PlusJakartaSansMedium",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 14 : 13,
    width: Platform.OS === "web" && screenWidth > 760 ? null : 100,
    color: colors.white,
  },

  input: {
    width: Platform.OS === "web" && screenWidth > 760 ? 60 : 50,
    height: 30,
    borderColor: colors.borderColor,
    borderWidth: 1,
    borderRadius: 5,
    padding: 5,
    backgroundColor: colors.royalBlue,
    color: colors.white,
  },
  topPlayerCount: {
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    padding: 5,
    borderRadius: 5,
    width: Platform.OS === "web" && screenWidth > 760 ? 150 : 110,
    alignContent: "center",
    justifyContent: "center",
    backgroundColor: colors.bgColor,
  },
  teamModalLabel: {
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 16 : 12,
    fontFamily: "PlusJakartaSansSemiBold",
    color: colors.white,
  },
});
export default AddPlayerModal;
