import React from "react";
import { Helmet } from "react-helmet-async";
export default function SEO({ title, description }) {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="robots" content="index,follow" />
      <meta name="googlebot" content="index,follow" />
      <meta name="language" content="en" />
      <meta name="author" content="Digitrack Sports" />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content="https://www.trackmytournament.com" />
      <meta property="og:site_name" content="TrackMyTournament" />
      <meta property="og:locale" content="en_IE" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:creator" content="Digitrack Sports" />
      <meta name="twitter:card" content="article" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {/* End Twitter tags */}
      <link rel="canonical" href="https://www.trackmytournament.com" />
    </Helmet>
  );
}
