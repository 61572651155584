import { DataStore } from "@aws-amplify/datastore";
import { TMTLoookup } from "../../models"; // Adjust the path as necessary

export const getNextSerialNumber = async (prefix, existingSerial) => {
  if (existingSerial) {
    const currentNumber = parseInt(existingSerial.slice(prefix.length), 10);
    const nextNumber = currentNumber + 1;
    return `${prefix}${nextNumber.toString().padStart(6, "0")}`;
  } else {
    // If no existing serial number, start with the first one
    return `${prefix}000001`;
  }
};

export const handlePlayerSerialNumber = async (originLocation) => {
  const prefix = originLocation === "https://league.yuvha.com" ? "YUV" : "TMT";

  try {
    // Fetch the existing TMTLoookup entry
    const existingLookup = await DataStore.query(TMTLoookup, (c) =>
      c.FieldName.eq("PlayerSerialNumber")
    );
    console.log("existingLookup", existingLookup);

    let existingSerial = null;
    if (existingLookup.length > 0) {
      // If an existing record is found, get the current serial number
      existingSerial = existingLookup[0].FieldValueString;
      console.log("existingSerial", existingSerial);
    }

    const playerSerialNumber = await getNextSerialNumber(
      prefix,
      existingSerial
    );
    console.log("playerSerialNumber", playerSerialNumber);

    if (existingLookup.length > 0) {
      // Update the existing record
      const updatedLookup = await DataStore.save(
        TMTLoookup.copyOf(existingLookup[0], (updated) => {
          updated.FieldValueString = playerSerialNumber;
        })
      );
      console.log("updatedLookup", updatedLookup);
    } else {
      // Create a new record if no existing record
      const addPlayerSerialNumber = await DataStore.save(
        new TMTLoookup({
          FieldName: "PlayerSerialNumber",
          FieldValueString: playerSerialNumber,
          ValueType: "String",
        })
      );
      console.log("addPlayerSerialNumber", addPlayerSerialNumber);
    }

    return playerSerialNumber;
  } catch (error) {
    console.error(
      "Error occurred while creating the player serial number",
      error
    );
    throw error;
  }
};
