import { View, Text } from "react-native";
import React from "react";
import Navbar from "../../components/include/Navbar";

const About = () => {
  return (
    <View>
      <Text>About Page page</Text>
    </View>
  );
};

export default About;
