import {
  View,
  Text,
  StyleSheet,
  Platform,
  Dimensions,
  Image,
} from "react-native";
import React, { useEffect, useState } from "react";
import { LinearGradient } from "expo-linear-gradient";
import { colors } from "../../../../components/reusable/colour";
import PlusIcon from "react-native-vector-icons/Feather";
import {
  MatchMaster,
  PlayerMaster,
  TeamRegistrations,
  Tournament,
} from "../../../../models";
import { DataStore } from "@aws-amplify/datastore";

const screenWidth = Dimensions.get("window").width;
const Counter = () => {
  const Trophy: any = "../../../../../assets/home/counter/trophy.png";
  const Team: any = "../../../../../assets/home/counter/teams.png";
  const Player: any = "../../../../../assets/home/counter/player.png";
  const Match: any = "../../../../../assets/home/counter/match.png";
  const LeftIcon: any = "../../../../../assets/home/counter/left.png";
  const RightIcon: any = "../../../../../assets/home/counter/right.png";

  const [tournamentCount, setTournamentCount] = useState(0);
  const [teamCount, setTeamCount]: any = useState(0);
  const [playerCount, setPlayerCount]: any = useState(0);
  const [matchCount, setMatchCount]: any = useState(0);

  useEffect(() => {
    const getData = async () => {
      const tournaments = await DataStore.query(Tournament);
      if (tournaments && tournaments.length > 0) {
        setTournamentCount(tournaments.length);
      }

      const players = await DataStore.query(PlayerMaster);
      if (players && players.length > 0) {
        setPlayerCount(players.length);
      }

      const teams = await DataStore.query(TeamRegistrations);
      if (teams && teams.length > 0) {
        setTeamCount(teams.length);
      }

      const matches = await DataStore.query(MatchMaster);
      if (matches && matches.length > 0) {
        setMatchCount(matches.length);
      }
    };
    getData();
  }, []);

  return (
    <LinearGradient
      style={styles.gradientBG}
      colors={["#170962", "#3F3D79", "#FF5733"]}
      start={{ x: 0, y: 1 }}
      end={{ x: 1, y: 1 }}
    >
      <Image
        source={{
          uri: LeftIcon,
        }}
        style={styles.leftIconBg}
      />
      <Image
        source={{
          uri: RightIcon,
        }}
        style={styles.rightIconBg}
      />
      <View style={styles.container}>
        <View style={styles.counterContainer}>
          <View style={styles.counterItem}>
            <Image
              source={require("../../../../../assets/home/counter/trophy.png")}
              style={styles.counterIcon}
            />
            <View style={styles.counterNumberRow}>
              <Text style={styles.counterNumber}>{tournamentCount}</Text>
              <PlusIcon name="plus" size={35} color={colors.white} />
            </View>
            <LinearGradient
              style={styles.counterNameBG}
              colors={["#ffffff00", "#ffffff47", "#ffffff00"]}
              start={{ x: 0, y: 1 }}
              end={{ x: 1, y: 1 }}
            >
              <Text style={styles.counterName}>TOURNAMENTS</Text>
            </LinearGradient>
          </View>
          <View style={styles.counterItem}>
            <Image
              source={require("../../../../../assets/home/counter/teams.png")}
              // source={{
              //   uri: Team,
              // }}
              style={styles.counterIcon}
            />
            <View style={styles.counterNumberRow}>
              <Text style={styles.counterNumber}>{teamCount}</Text>
              <PlusIcon name="plus" size={35} color={colors.white} />
            </View>
            <LinearGradient
              style={styles.counterNameBG}
              colors={["#ffffff00", "#ffffff47", "#ffffff00"]}
              start={{ x: 0, y: 1 }}
              end={{ x: 1, y: 1 }}
            >
              <Text style={styles.counterName}> TEAMS</Text>
            </LinearGradient>
          </View>
          <View style={styles.counterItem}>
            <Image
              source={require("../../../../../assets/home/counter/player.png")}
              // source={{
              //   uri: Player,
              // }}
              style={styles.counterIcon}
            />
            <View style={styles.counterNumberRow}>
              <Text style={styles.counterNumber}>{playerCount}</Text>
              <PlusIcon name="plus" size={35} color={colors.white} />
            </View>
            <LinearGradient
              style={styles.counterNameBG}
              colors={["#ffffff00", "#ffffff47", "#ffffff00"]}
              start={{ x: 0, y: 1 }}
              end={{ x: 1, y: 1 }}
            >
              <Text style={styles.counterName}> PLAYERS</Text>
            </LinearGradient>
          </View>
          <View style={styles.counterItem}>
            <Image
              source={require("../../../../../assets/home/counter/match.png")}
              // source={{
              //   uri: Match,
              // }}
              style={styles.counterIcon}
            />
            <View style={styles.counterNumberRow}>
              <Text style={styles.counterNumber}>{matchCount}</Text>
              <PlusIcon name="plus" size={35} color={colors.white} />
            </View>
            <LinearGradient
              style={styles.counterNameBG}
              colors={["#ffffff00", "#ffffff47", "#ffffff00"]}
              start={{ x: 0, y: 1 }}
              end={{ x: 1, y: 1 }}
            >
              <Text style={styles.counterName}>MATCHES</Text>
            </LinearGradient>
          </View>
        </View>
      </View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  gradientBG: {
    paddingVertical: screenWidth > 760 ? 50 : 10,
  },
  container: {
    maxWidth: 1320,
    width: "100%",
    marginHorizontal: "auto",
    paddingRight: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
    paddingLeft: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
  },
  counterIcon: {
    width: 80,
    height: 55,
    resizeMode: "contain",
    marginBottom: 15,
  },
  counterContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 20,
    flexWrap: "wrap",
  },
  counterItem: {
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
    padding: 10,
    // borderColor: colors.white,
    // borderWidth: screenWidth > 760 ? 0 : 1,
    backgroundColor: screenWidth > 760 ? "transparent" : "#ffffff0d",
    flex: 1,
    maxWidth: screenWidth > 760 ? undefined : "50%",
    minWidth: screenWidth > 760 ? undefined : "45%",
    height: Platform.OS !== "web" ? 220 : undefined,
  },
  counterNumber: {
    color: colors.white,
    fontSize: 35,
    fontFamily: "PlusJakartaSansExtraBold",
  },
  counterName: {
    color: colors.white,
    fontSize: 16,
    fontFamily: "PlusJakartaSansMedium",
    textAlign: "center",
  },
  counterNameBG: {
    paddingVertical: 4,
    paddingHorizontal: 20,
  },
  counterNumberRow: {
    flexDirection: "row",
    gap: 5,
    alignItems: "center",
  },
  leftIconBg: {
    position: "absolute",
    width: 190,
    height: 195,
    left: 0,
    top: 0,
    resizeMode: "cover",
  },
  rightIconBg: {
    position: "absolute",
    width: 150,
    height: 150,
    right: 0,
    bottom: 0,
    resizeMode: "cover",
  },
});

export default Counter;
