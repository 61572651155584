export const schema = {
    "models": {
        "TMTLoookup": {
            "name": "TMTLoookup",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "FieldName": {
                    "name": "FieldName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ValueType": {
                    "name": "ValueType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "FieldValueString": {
                    "name": "FieldValueString",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "FieldValueNumber": {
                    "name": "FieldValueNumber",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "FieldValueStringArray": {
                    "name": "FieldValueStringArray",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "FieldValueObjectArray": {
                    "name": "FieldValueObjectArray",
                    "isArray": true,
                    "type": {
                        "nonModel": "ObjectField"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "TMTLoookups",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "CoachMaster": {
            "name": "CoachMaster",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "Name": {
                    "name": "Name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "PhotoURL": {
                    "name": "PhotoURL",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Awards": {
                    "name": "Awards",
                    "isArray": false,
                    "type": {
                        "nonModel": "TeamAwards"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "Games": {
                    "name": "Games",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "Won": {
                    "name": "Won",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "Lost": {
                    "name": "Lost",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "Drawn": {
                    "name": "Drawn",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "CoachData": {
                    "name": "CoachData",
                    "isArray": false,
                    "type": {
                        "model": "UserMaster"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "coachMasterCoachDataUUID"
                        ]
                    }
                },
                "Squads": {
                    "name": "Squads",
                    "isArray": true,
                    "type": {
                        "model": "SquadCoachMaster"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "coachMaster"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "coachMasterCoachDataUUID": {
                    "name": "coachMasterCoachDataUUID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "CoachMasters",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "UserMaster": {
            "name": "UserMaster",
            "fields": {
                "UUID": {
                    "name": "UUID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "Name": {
                    "name": "Name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ContactNo": {
                    "name": "ContactNo",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "EmailId": {
                    "name": "EmailId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "InstagramId": {
                    "name": "InstagramId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "PhotoURL": {
                    "name": "PhotoURL",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "CreatedBy": {
                    "name": "CreatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "LastUpdatedBy": {
                    "name": "LastUpdatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "City": {
                    "name": "City",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "State": {
                    "name": "State",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Country": {
                    "name": "Country",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "PinCode": {
                    "name": "PinCode",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "Sex": {
                    "name": "Sex",
                    "isArray": false,
                    "type": {
                        "enum": "Sex"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "DateOfBirth": {
                    "name": "DateOfBirth",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "RoleCoach": {
                    "name": "RoleCoach",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "RolePlayer": {
                    "name": "RolePlayer",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "UserTournaments": {
                    "name": "UserTournaments",
                    "isArray": true,
                    "type": {
                        "model": "UserMasterTournament"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "userMaster"
                        ]
                    }
                },
                "UserTeams": {
                    "name": "UserTeams",
                    "isArray": true,
                    "type": {
                        "model": "SquadUserMaster"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "userMaster"
                        ]
                    }
                },
                "TeamRegistrations": {
                    "name": "TeamRegistrations",
                    "isArray": true,
                    "type": {
                        "model": "UserMasterTeamRegistrations"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "userMaster"
                        ]
                    }
                },
                "CoachSummary": {
                    "name": "CoachSummary",
                    "isArray": false,
                    "type": {
                        "model": "CoachMaster"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": [
                            "id"
                        ],
                        "targetNames": [
                            "userMasterCoachSummaryId"
                        ]
                    }
                },
                "PlayerSummary": {
                    "name": "PlayerSummary",
                    "isArray": false,
                    "type": {
                        "model": "PlayerMaster"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": [
                            "id"
                        ],
                        "targetNames": [
                            "userMasterPlayerSummaryId"
                        ]
                    }
                },
                "UserMatches": {
                    "name": "UserMatches",
                    "isArray": true,
                    "type": {
                        "model": "MatchMasterUserMaster"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "userMaster"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "userMasterCoachSummaryId": {
                    "name": "userMasterCoachSummaryId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "userMasterPlayerSummaryId": {
                    "name": "userMasterPlayerSummaryId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "UserMasters",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "fields": [
                            "UUID"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "MatchTeamMaster": {
            "name": "MatchTeamMaster",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "Score": {
                    "name": "Score",
                    "isArray": false,
                    "type": {
                        "nonModel": "FootballTeamScore"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "TeamName": {
                    "name": "TeamName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TeamShortName": {
                    "name": "TeamShortName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "LogoURL": {
                    "name": "LogoURL",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TeamSubmitted": {
                    "name": "TeamSubmitted",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "Cards": {
                    "name": "Cards",
                    "isArray": true,
                    "type": {
                        "model": "CardMaster"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "MatchTeamID"
                        ]
                    }
                },
                "Goals": {
                    "name": "Goals",
                    "isArray": true,
                    "type": {
                        "model": "GoalMaster"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "MatchTeamID"
                        ]
                    }
                },
                "Substitutions": {
                    "name": "Substitutions",
                    "isArray": true,
                    "type": {
                        "model": "SubstitutionMaster"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "MatchTeamID"
                        ]
                    }
                },
                "Players": {
                    "name": "Players",
                    "isArray": true,
                    "type": {
                        "nonModel": "MatchPlayer"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "MatchTeamMasters",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "SubstitutionMaster": {
            "name": "SubstitutionMaster",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CreatedBy": {
                    "name": "CreatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "LastUpdatedBy": {
                    "name": "LastUpdatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Time": {
                    "name": "Time",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Half": {
                    "name": "Half",
                    "isArray": false,
                    "type": {
                        "enum": "Half"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "InPlayer": {
                    "name": "InPlayer",
                    "isArray": false,
                    "type": {
                        "nonModel": "MatchPlayer"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "OutPlayer": {
                    "name": "OutPlayer",
                    "isArray": false,
                    "type": {
                        "nonModel": "MatchPlayer"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "MatchTeamID": {
                    "name": "MatchTeamID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "SubstitutionMasters",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byMatchTeamMaster",
                        "fields": [
                            "MatchTeamID"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "CardMaster": {
            "name": "CardMaster",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "CardType": {
                    "name": "CardType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "CreatedBy": {
                    "name": "CreatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "LastUpdatedBy": {
                    "name": "LastUpdatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Time": {
                    "name": "Time",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Half": {
                    "name": "Half",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Player": {
                    "name": "Player",
                    "isArray": false,
                    "type": {
                        "nonModel": "MatchPlayer"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "MatchTeamID": {
                    "name": "MatchTeamID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "TournamentID": {
                    "name": "TournamentID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "CardMasters",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byMatchTeamMaster",
                        "fields": [
                            "MatchTeamID"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTournament",
                        "fields": [
                            "TournamentID"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "GoalMaster": {
            "name": "GoalMaster",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "Penalty": {
                    "name": "Penalty",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "CreatedBy": {
                    "name": "CreatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "LastUpdatedBy": {
                    "name": "LastUpdatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Time": {
                    "name": "Time",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Half": {
                    "name": "Half",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "MatchTeamID": {
                    "name": "MatchTeamID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "TournamentID": {
                    "name": "TournamentID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "Player": {
                    "name": "Player",
                    "isArray": false,
                    "type": {
                        "nonModel": "MatchPlayer"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "GoalMasters",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byMatchTeamMaster",
                        "fields": [
                            "MatchTeamID"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTournament",
                        "fields": [
                            "TournamentID"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Squad": {
            "name": "Squad",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "Name": {
                    "name": "Name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Logo": {
                    "name": "Logo",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "CreatedBy": {
                    "name": "CreatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "LastUpdatedBy": {
                    "name": "LastUpdatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TeamAwards": {
                    "name": "TeamAwards",
                    "isArray": false,
                    "type": {
                        "nonModel": "TeamAwards"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "TournamentsPlayed": {
                    "name": "TournamentsPlayed",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "Admin1": {
                    "name": "Admin1",
                    "isArray": false,
                    "type": {
                        "nonModel": "TeamAdmin"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "Admin2": {
                    "name": "Admin2",
                    "isArray": false,
                    "type": {
                        "nonModel": "TeamAdmin"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "TeamUsers": {
                    "name": "TeamUsers",
                    "isArray": true,
                    "type": {
                        "model": "SquadUserMaster"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "squad"
                        ]
                    }
                },
                "TeamPlayers": {
                    "name": "TeamPlayers",
                    "isArray": true,
                    "type": {
                        "model": "SquadPlayerMaster"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "squad"
                        ]
                    }
                },
                "TeamCoaches": {
                    "name": "TeamCoaches",
                    "isArray": true,
                    "type": {
                        "model": "SquadCoachMaster"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "squad"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Squads",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "TeamRegistrations": {
            "name": "TeamRegistrations",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "TeamName": {
                    "name": "TeamName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TeamShortName": {
                    "name": "TeamShortName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Logo": {
                    "name": "Logo",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Admins": {
                    "name": "Admins",
                    "isArray": true,
                    "type": {
                        "model": "UserMasterTeamRegistrations"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "teamRegistrations"
                        ]
                    }
                },
                "Group": {
                    "name": "Group",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "Coach": {
                    "name": "Coach",
                    "isArray": false,
                    "type": {
                        "nonModel": "TeamAdmin"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "PaymentStatus": {
                    "name": "PaymentStatus",
                    "isArray": false,
                    "type": {
                        "enum": "PaymentStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "LastUpdatedBy": {
                    "name": "LastUpdatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "SquadId": {
                    "name": "SquadId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "PaidAmount": {
                    "name": "PaidAmount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "BalanceAmount": {
                    "name": "BalanceAmount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "Documents": {
                    "name": "Documents",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "Award": {
                    "name": "Award",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Tournament": {
                    "name": "Tournament",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "Status": {
                    "name": "Status",
                    "isArray": false,
                    "type": {
                        "enum": "RegistrationStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "Comments": {
                    "name": "Comments",
                    "isArray": true,
                    "type": {
                        "nonModel": "Comments"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "Players": {
                    "name": "Players",
                    "isArray": true,
                    "type": {
                        "model": "TeamRegistrationsPlayerMaster"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "teamRegistrations"
                        ]
                    }
                },
                "TeamMatches": {
                    "name": "TeamMatches",
                    "isArray": true,
                    "type": {
                        "model": "MatchMasterRegistration"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "teamRegistrations"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "TeamRegistrations",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTournament",
                        "fields": [
                            "Tournament"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "GroupsMaster": {
            "name": "GroupsMaster",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "GroupName": {
                    "name": "GroupName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "GroupName2": {
                    "name": "GroupName2",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "CreatedBy": {
                    "name": "CreatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "LastUpdatedBy": {
                    "name": "LastUpdatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "RoundMasterID": {
                    "name": "RoundMasterID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "GroupStandings": {
                    "name": "GroupStandings",
                    "isArray": true,
                    "type": {
                        "model": "GroupStanding"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "groupsmasterID"
                        ]
                    }
                },
                "Teams": {
                    "name": "Teams",
                    "isArray": true,
                    "type": {
                        "nonModel": "GroupTeam"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "Matches": {
                    "name": "Matches",
                    "isArray": true,
                    "type": {
                        "model": "MatchMaster"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "groupsmasterID"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "GroupsMasters",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byRoundMaster",
                        "fields": [
                            "RoundMasterID"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "GroupStanding": {
            "name": "GroupStanding",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "TeamName": {
                    "name": "TeamName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TeamLogo": {
                    "name": "TeamLogo",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TeamShortName": {
                    "name": "TeamShortName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "LastUpdatedBy": {
                    "name": "LastUpdatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Played": {
                    "name": "Played",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "Won": {
                    "name": "Won",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "Lost": {
                    "name": "Lost",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "Drawn": {
                    "name": "Drawn",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "Position": {
                    "name": "Position",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "CreatedBy": {
                    "name": "CreatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "For": {
                    "name": "For",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "Form": {
                    "name": "Form",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "Against": {
                    "name": "Against",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "Points": {
                    "name": "Points",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "Difference": {
                    "name": "Difference",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "ChangeInPosition": {
                    "name": "ChangeInPosition",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TeamId": {
                    "name": "TeamId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "groupsmasterID": {
                    "name": "groupsmasterID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "GroupStandings",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byGroupsMaster",
                        "fields": [
                            "groupsmasterID"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "RoundMaster": {
            "name": "RoundMaster",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "Name": {
                    "name": "Name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "No": {
                    "name": "No",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "Format": {
                    "name": "Format",
                    "isArray": false,
                    "type": {
                        "enum": "RoundFormat"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "Completed": {
                    "name": "Completed",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "TeamsSelected": {
                    "name": "TeamsSelected",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "FixturesCreated": {
                    "name": "FixturesCreated",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "CreatedBy": {
                    "name": "CreatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "LastUpdatedBy": {
                    "name": "LastUpdatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Tournament": {
                    "name": "Tournament",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "Groups": {
                    "name": "Groups",
                    "isArray": true,
                    "type": {
                        "model": "GroupsMaster"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "RoundMasterID"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "RoundMasters",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTournament",
                        "fields": [
                            "Tournament"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "PlayerMaster": {
            "name": "PlayerMaster",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "SrNo": {
                    "name": "SrNo",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Name": {
                    "name": "Name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "PhotoURL": {
                    "name": "PhotoURL",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "CreatedBy": {
                    "name": "CreatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "LastUpdatedBy": {
                    "name": "LastUpdatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Views": {
                    "name": "Views",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "JerseyNo": {
                    "name": "JerseyNo",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "DateOfBirth": {
                    "name": "DateOfBirth",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "Awards": {
                    "name": "Awards",
                    "isArray": false,
                    "type": {
                        "nonModel": "PlayerAwards"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "Badges": {
                    "name": "Badges",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "PlayerData": {
                    "name": "PlayerData",
                    "isArray": false,
                    "type": {
                        "model": "UserMaster"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "playerMasterPlayerDataUUID"
                        ]
                    }
                },
                "PlayerStatistics": {
                    "name": "PlayerStatistics",
                    "isArray": true,
                    "type": {
                        "model": "StatisticsMaster"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "playerData"
                        ]
                    }
                },
                "Squads": {
                    "name": "Squads",
                    "isArray": true,
                    "type": {
                        "model": "SquadPlayerMaster"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "playerMaster"
                        ]
                    }
                },
                "Teams": {
                    "name": "Teams",
                    "isArray": true,
                    "type": {
                        "model": "TeamRegistrationsPlayerMaster"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "playerMaster"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "playerMasterPlayerDataUUID": {
                    "name": "playerMasterPlayerDataUUID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "PlayerMasters",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "StatisticsMaster": {
            "name": "StatisticsMaster",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "TeamName": {
                    "name": "TeamName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TeamLogo": {
                    "name": "TeamLogo",
                    "isArray": false,
                    "type": "AWSURL",
                    "isRequired": false,
                    "attributes": []
                },
                "TeamShortName": {
                    "name": "TeamShortName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "CreatedBy": {
                    "name": "CreatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "LastUpdatedBy": {
                    "name": "LastUpdatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Goals": {
                    "name": "Goals",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "RedCards": {
                    "name": "RedCards",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "YellowCards": {
                    "name": "YellowCards",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "Games": {
                    "name": "Games",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "Minutes": {
                    "name": "Minutes",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "tournamentID": {
                    "name": "tournamentID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "PlayerId": {
                    "name": "PlayerId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "playerData": {
                    "name": "playerData",
                    "isArray": false,
                    "type": {
                        "model": "PlayerMaster"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "PlayerId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "StatisticsMasters",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTournament",
                        "fields": [
                            "tournamentID"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byPlayerMaster",
                        "fields": [
                            "PlayerId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "MatchMaster": {
            "name": "MatchMaster",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "MatchNo": {
                    "name": "MatchNo",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "Date": {
                    "name": "Date",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "Time": {
                    "name": "Time",
                    "isArray": false,
                    "type": "AWSTime",
                    "isRequired": false,
                    "attributes": []
                },
                "Status": {
                    "name": "Status",
                    "isArray": false,
                    "type": {
                        "enum": "MatchStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "Nextmatch": {
                    "name": "Nextmatch",
                    "isArray": false,
                    "type": {
                        "nonModel": "NextMatch"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "Result": {
                    "name": "Result",
                    "isArray": false,
                    "type": {
                        "enum": "MatchResult"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "MatchStage": {
                    "name": "MatchStage",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "PauseFlag": {
                    "name": "PauseFlag",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "OnlineScoring": {
                    "name": "OnlineScoring",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "PauseTime": {
                    "name": "PauseTime",
                    "isArray": true,
                    "type": {
                        "nonModel": "PauseTimes"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "FirstHalfStarted": {
                    "name": "FirstHalfStarted",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "FirstHalfStartTime": {
                    "name": "FirstHalfStartTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "SecondHalfStarted": {
                    "name": "SecondHalfStarted",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "SecondHalfStarTime": {
                    "name": "SecondHalfStarTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "ExtraTimeStarted": {
                    "name": "ExtraTimeStarted",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "ExtraTimeStartTime": {
                    "name": "ExtraTimeStartTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "FirstHalfEnded": {
                    "name": "FirstHalfEnded",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "FirstHalfEndMinutes": {
                    "name": "FirstHalfEndMinutes",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "FirstHalfEndTime": {
                    "name": "FirstHalfEndTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "SecondHalfEnded": {
                    "name": "SecondHalfEnded",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "SecondHalfEndMinutes": {
                    "name": "SecondHalfEndMinutes",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "SecondHalfEndime": {
                    "name": "SecondHalfEndime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "ExtraTimeEnded": {
                    "name": "ExtraTimeEnded",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "ExtraTimeEndMinutes": {
                    "name": "ExtraTimeEndMinutes",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "ExtraTimeEndTime": {
                    "name": "ExtraTimeEndTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "HalfTime": {
                    "name": "HalfTime",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "CreatedBy": {
                    "name": "CreatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "CreatedDate": {
                    "name": "CreatedDate",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "LastUpdatedBy": {
                    "name": "LastUpdatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "LastUpdatedDate": {
                    "name": "LastUpdatedDate",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "LiveURL": {
                    "name": "LiveURL",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Venue": {
                    "name": "Venue",
                    "isArray": false,
                    "type": {
                        "nonModel": "Venue"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "Views": {
                    "name": "Views",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "groupsmasterID": {
                    "name": "groupsmasterID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tournamentID": {
                    "name": "tournamentID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "tournamentData": {
                    "name": "tournamentData",
                    "isArray": false,
                    "type": {
                        "model": "Tournament"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "tournamentID"
                        ]
                    }
                },
                "MatchUsers": {
                    "name": "MatchUsers",
                    "isArray": true,
                    "type": {
                        "model": "MatchMasterUserMaster"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "matchMaster"
                        ]
                    }
                },
                "MatchTeams": {
                    "name": "MatchTeams",
                    "isArray": true,
                    "type": {
                        "model": "MatchMasterRegistration"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "matchMaster"
                        ]
                    }
                },
                "GroupName": {
                    "name": "GroupName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Team1": {
                    "name": "Team1",
                    "isArray": false,
                    "type": {
                        "model": "MatchTeamMaster"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": [
                            "id"
                        ],
                        "targetNames": [
                            "matchMasterTeam1Id"
                        ]
                    }
                },
                "Team2": {
                    "name": "Team2",
                    "isArray": false,
                    "type": {
                        "model": "MatchTeamMaster"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": [
                            "id"
                        ],
                        "targetNames": [
                            "matchMasterTeam2Id"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "matchMasterTeam1Id": {
                    "name": "matchMasterTeam1Id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "matchMasterTeam2Id": {
                    "name": "matchMasterTeam2Id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "MatchMasters",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byGroupsMaster",
                        "fields": [
                            "groupsmasterID"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTournament",
                        "fields": [
                            "tournamentID"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Tournament": {
            "name": "Tournament",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "Name": {
                    "name": "Name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Level": {
                    "name": "Level",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Description": {
                    "name": "Description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Organizer": {
                    "name": "Organizer",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "OrganizerEmail": {
                    "name": "OrganizerEmail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Logo": {
                    "name": "Logo",
                    "isArray": false,
                    "type": "AWSURL",
                    "isRequired": false,
                    "attributes": []
                },
                "Banner": {
                    "name": "Banner",
                    "isArray": false,
                    "type": "AWSURL",
                    "isRequired": false,
                    "attributes": []
                },
                "StartDate": {
                    "name": "StartDate",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "EndDate": {
                    "name": "EndDate",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ExternalRegistration": {
                    "name": "ExternalRegistration",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "Fees": {
                    "name": "Fees",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Format": {
                    "name": "Format",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Area": {
                    "name": "Area",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "SubstitutionType": {
                    "name": "SubstitutionType",
                    "isArray": false,
                    "type": {
                        "enum": "Substitution"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "NoOfTeamsMin": {
                    "name": "NoOfTeamsMin",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "NoOfTeamsMax": {
                    "name": "NoOfTeamsMax",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "MaxPlayersInSquad": {
                    "name": "MaxPlayersInSquad",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "RegistrationEndDate": {
                    "name": "RegistrationEndDate",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "CutOffDate": {
                    "name": "CutOffDate",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "Playing": {
                    "name": "Playing",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "Substitute": {
                    "name": "Substitute",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "Status": {
                    "name": "Status",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "RulesDocument": {
                    "name": "RulesDocument",
                    "isArray": false,
                    "type": "AWSURL",
                    "isRequired": false,
                    "attributes": []
                },
                "CreatedBy": {
                    "name": "CreatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "LastUpdatedBy": {
                    "name": "LastUpdatedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "City": {
                    "name": "City",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "State": {
                    "name": "State",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "Venues": {
                    "name": "Venues",
                    "isArray": true,
                    "type": {
                        "nonModel": "Venue"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "Prizes": {
                    "name": "Prizes",
                    "isArray": true,
                    "type": {
                        "nonModel": "Prize"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "Sponsors": {
                    "name": "Sponsors",
                    "isArray": true,
                    "type": {
                        "nonModel": "Sponsor"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "Sport": {
                    "name": "Sport",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Type": {
                    "name": "Type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Country": {
                    "name": "Country",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "WinPoints": {
                    "name": "WinPoints",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "LossPoints": {
                    "name": "LossPoints",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "DrawPoints": {
                    "name": "DrawPoints",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "WalkoverScore": {
                    "name": "WalkoverScore",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "AgeGroup": {
                    "name": "AgeGroup",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "SubscriptionPlan": {
                    "name": "SubscriptionPlan",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "Settings": {
                    "name": "Settings",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "Gallery": {
                    "name": "Gallery",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "Admin1": {
                    "name": "Admin1",
                    "isArray": false,
                    "type": {
                        "nonModel": "TeamAdmin"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "Admin2": {
                    "name": "Admin2",
                    "isArray": false,
                    "type": {
                        "nonModel": "TeamAdmin"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "Admin3": {
                    "name": "Admin3",
                    "isArray": false,
                    "type": {
                        "nonModel": "TeamAdmin"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "Views": {
                    "name": "Views",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "Users": {
                    "name": "Users",
                    "isArray": true,
                    "type": {
                        "model": "UserMasterTournament"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "tournament"
                        ]
                    }
                },
                "Registrations": {
                    "name": "Registrations",
                    "isArray": true,
                    "type": {
                        "model": "TeamRegistrations"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "Tournament"
                        ]
                    }
                },
                "Rounds": {
                    "name": "Rounds",
                    "isArray": true,
                    "type": {
                        "model": "RoundMaster"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "Tournament"
                        ]
                    }
                },
                "Statistics": {
                    "name": "Statistics",
                    "isArray": true,
                    "type": {
                        "model": "StatisticsMaster"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "tournamentID"
                        ]
                    }
                },
                "Cards": {
                    "name": "Cards",
                    "isArray": true,
                    "type": {
                        "model": "CardMaster"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "TournamentID"
                        ]
                    }
                },
                "Goals": {
                    "name": "Goals",
                    "isArray": true,
                    "type": {
                        "model": "GoalMaster"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "TournamentID"
                        ]
                    }
                },
                "Matches": {
                    "name": "Matches",
                    "isArray": true,
                    "type": {
                        "model": "MatchMaster"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "tournamentData"
                        ]
                    }
                },
                "Announcements": {
                    "name": "Announcements",
                    "isArray": true,
                    "type": {
                        "model": "Announcement"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "tournamentID"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Tournaments",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Announcement": {
            "name": "Announcement",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "ShortDescription": {
                    "name": "ShortDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Description": {
                    "name": "Description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ByUserName": {
                    "name": "ByUserName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ByUserPhoto": {
                    "name": "ByUserPhoto",
                    "isArray": false,
                    "type": "AWSURL",
                    "isRequired": false,
                    "attributes": []
                },
                "TimeStamp": {
                    "name": "TimeStamp",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "tournamentID": {
                    "name": "tournamentID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Announcements",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTournament",
                        "fields": [
                            "tournamentID"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "SquadCoachMaster": {
            "name": "SquadCoachMaster",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "coachMasterId": {
                    "name": "coachMasterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "squadId": {
                    "name": "squadId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "coachMaster": {
                    "name": "coachMaster",
                    "isArray": false,
                    "type": {
                        "model": "CoachMaster"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "coachMasterId"
                        ]
                    }
                },
                "squad": {
                    "name": "squad",
                    "isArray": false,
                    "type": {
                        "model": "Squad"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "squadId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "SquadCoachMasters",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCoachMaster",
                        "fields": [
                            "coachMasterId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "bySquad",
                        "fields": [
                            "squadId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "UserMasterTournament": {
            "name": "UserMasterTournament",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "userMasterUUID": {
                    "name": "userMasterUUID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "tournamentId": {
                    "name": "tournamentId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "userMaster": {
                    "name": "userMaster",
                    "isArray": false,
                    "type": {
                        "model": "UserMaster"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "userMasterUUID"
                        ]
                    }
                },
                "tournament": {
                    "name": "tournament",
                    "isArray": false,
                    "type": {
                        "model": "Tournament"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "tournamentId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "UserMasterTournaments",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUserMaster",
                        "fields": [
                            "userMasterUUID"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTournament",
                        "fields": [
                            "tournamentId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "SquadUserMaster": {
            "name": "SquadUserMaster",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "userMasterUUID": {
                    "name": "userMasterUUID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "squadId": {
                    "name": "squadId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "userMaster": {
                    "name": "userMaster",
                    "isArray": false,
                    "type": {
                        "model": "UserMaster"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "userMasterUUID"
                        ]
                    }
                },
                "squad": {
                    "name": "squad",
                    "isArray": false,
                    "type": {
                        "model": "Squad"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "squadId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "SquadUserMasters",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUserMaster",
                        "fields": [
                            "userMasterUUID"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "bySquad",
                        "fields": [
                            "squadId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "UserMasterTeamRegistrations": {
            "name": "UserMasterTeamRegistrations",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "userMasterUUID": {
                    "name": "userMasterUUID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "teamRegistrationsId": {
                    "name": "teamRegistrationsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "userMaster": {
                    "name": "userMaster",
                    "isArray": false,
                    "type": {
                        "model": "UserMaster"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "userMasterUUID"
                        ]
                    }
                },
                "teamRegistrations": {
                    "name": "teamRegistrations",
                    "isArray": false,
                    "type": {
                        "model": "TeamRegistrations"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "teamRegistrationsId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "UserMasterTeamRegistrations",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUserMaster",
                        "fields": [
                            "userMasterUUID"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTeamRegistrations",
                        "fields": [
                            "teamRegistrationsId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "MatchMasterUserMaster": {
            "name": "MatchMasterUserMaster",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "userMasterUUID": {
                    "name": "userMasterUUID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "matchMasterId": {
                    "name": "matchMasterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "userMaster": {
                    "name": "userMaster",
                    "isArray": false,
                    "type": {
                        "model": "UserMaster"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "userMasterUUID"
                        ]
                    }
                },
                "matchMaster": {
                    "name": "matchMaster",
                    "isArray": false,
                    "type": {
                        "model": "MatchMaster"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "matchMasterId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "MatchMasterUserMasters",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUserMaster",
                        "fields": [
                            "userMasterUUID"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byMatchMaster",
                        "fields": [
                            "matchMasterId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "SquadPlayerMaster": {
            "name": "SquadPlayerMaster",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "squadId": {
                    "name": "squadId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "playerMasterId": {
                    "name": "playerMasterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "squad": {
                    "name": "squad",
                    "isArray": false,
                    "type": {
                        "model": "Squad"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "squadId"
                        ]
                    }
                },
                "playerMaster": {
                    "name": "playerMaster",
                    "isArray": false,
                    "type": {
                        "model": "PlayerMaster"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "playerMasterId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "SquadPlayerMasters",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "bySquad",
                        "fields": [
                            "squadId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byPlayerMaster",
                        "fields": [
                            "playerMasterId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "TeamRegistrationsPlayerMaster": {
            "name": "TeamRegistrationsPlayerMaster",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "teamRegistrationsId": {
                    "name": "teamRegistrationsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "playerMasterId": {
                    "name": "playerMasterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "teamRegistrations": {
                    "name": "teamRegistrations",
                    "isArray": false,
                    "type": {
                        "model": "TeamRegistrations"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "teamRegistrationsId"
                        ]
                    }
                },
                "playerMaster": {
                    "name": "playerMaster",
                    "isArray": false,
                    "type": {
                        "model": "PlayerMaster"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "playerMasterId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "TeamRegistrationsPlayerMasters",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTeamRegistrations",
                        "fields": [
                            "teamRegistrationsId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byPlayerMaster",
                        "fields": [
                            "playerMasterId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "MatchMasterRegistration": {
            "name": "MatchMasterRegistration",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "teamRegistrationsId": {
                    "name": "teamRegistrationsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "matchMasterId": {
                    "name": "matchMasterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "teamRegistrations": {
                    "name": "teamRegistrations",
                    "isArray": false,
                    "type": {
                        "model": "TeamRegistrations"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "teamRegistrationsId"
                        ]
                    }
                },
                "matchMaster": {
                    "name": "matchMaster",
                    "isArray": false,
                    "type": {
                        "model": "MatchMaster"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetNames": [
                            "matchMasterId"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "MatchMasterRegistrations",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTeamRegistrations",
                        "fields": [
                            "teamRegistrationsId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byMatchMaster",
                        "fields": [
                            "matchMasterId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        }
    },
    "enums": {
        "Substitution": {
            "name": "Substitution",
            "values": [
                "ROLLING",
                "REGULAR"
            ]
        },
        "MatchResult": {
            "name": "MatchResult",
            "values": [
                "TEAM1",
                "TEAM2",
                "DRAW"
            ]
        },
        "MatchStatus": {
            "name": "MatchStatus",
            "values": [
                "CREATED",
                "SCHEDULED",
                "COMPLETED",
                "INPROGRESS",
                "RESCHEDULED",
                "CANCELLED"
            ]
        },
        "RegistrationStatus": {
            "name": "RegistrationStatus",
            "values": [
                "SUBMITTED",
                "APPROVED",
                "REJECTED",
                "CANCELLED"
            ]
        },
        "Half": {
            "name": "Half",
            "values": [
                "FIRST",
                "SECOND",
                "EXTRA_TIME",
                "SHOOTOUT"
            ]
        },
        "RoundFormat": {
            "name": "RoundFormat",
            "values": [
                "KNOCKOUT",
                "SINGLE_ROUND_ROBIN",
                "DOUBLE_ROUND_ROBIN"
            ]
        },
        "Sex": {
            "name": "Sex",
            "values": [
                "MALE",
                "FEMALE",
                "OTHER"
            ]
        },
        "PaymentStatus": {
            "name": "PaymentStatus",
            "values": [
                "PAID",
                "UNPAID",
                "PART_PAID"
            ]
        }
    },
    "nonModels": {
        "PlayerAwards": {
            "name": "PlayerAwards",
            "fields": {
                "PlayerOfTheTournament": {
                    "name": "PlayerOfTheTournament",
                    "isArray": false,
                    "type": {
                        "nonModel": "Awards"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "BestForward": {
                    "name": "BestForward",
                    "isArray": false,
                    "type": {
                        "nonModel": "Awards"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "BestGoalkeeper": {
                    "name": "BestGoalkeeper",
                    "isArray": false,
                    "type": {
                        "nonModel": "Awards"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "BestMidfielder": {
                    "name": "BestMidfielder",
                    "isArray": false,
                    "type": {
                        "nonModel": "Awards"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "BestDefender": {
                    "name": "BestDefender",
                    "isArray": false,
                    "type": {
                        "nonModel": "Awards"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "PauseTimes": {
            "name": "PauseTimes",
            "fields": {
                "PauseStart": {
                    "name": "PauseStart",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "PauseEnd": {
                    "name": "PauseEnd",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "PauseDuration": {
                    "name": "PauseDuration",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Venue": {
            "name": "Venue",
            "fields": {
                "Name": {
                    "name": "Name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Address": {
                    "name": "Address",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Coordinates": {
                    "name": "Coordinates",
                    "isArray": false,
                    "type": {
                        "nonModel": "Coordinates"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Coordinates": {
            "name": "Coordinates",
            "fields": {
                "Lattitude": {
                    "name": "Lattitude",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Longitude": {
                    "name": "Longitude",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Sponsor": {
            "name": "Sponsor",
            "fields": {
                "Name": {
                    "name": "Name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "LogoURL": {
                    "name": "LogoURL",
                    "isArray": false,
                    "type": "AWSURL",
                    "isRequired": false,
                    "attributes": []
                },
                "Type": {
                    "name": "Type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Prize": {
            "name": "Prize",
            "fields": {
                "Name": {
                    "name": "Name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Description": {
                    "name": "Description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "NextMatch": {
            "name": "NextMatch",
            "fields": {
                "NextValue": {
                    "name": "NextValue",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "RoundType": {
                    "name": "RoundType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Type": {
                    "name": "Type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ObjectField": {
            "name": "ObjectField",
            "fields": {
                "Name": {
                    "name": "Name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Value": {
                    "name": "Value",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Awards": {
            "name": "Awards",
            "fields": {
                "Count": {
                    "name": "Count",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "Tournaments": {
                    "name": "Tournaments",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "Comments": {
            "name": "Comments",
            "fields": {
                "Comment": {
                    "name": "Comment",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "By": {
                    "name": "By",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TimeStamp": {
                    "name": "TimeStamp",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "TeamAwards": {
            "name": "TeamAwards",
            "fields": {
                "Winner": {
                    "name": "Winner",
                    "isArray": false,
                    "type": {
                        "nonModel": "Awards"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "RunnerUp": {
                    "name": "RunnerUp",
                    "isArray": false,
                    "type": {
                        "nonModel": "Awards"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "ThirdPlace": {
                    "name": "ThirdPlace",
                    "isArray": false,
                    "type": {
                        "nonModel": "Awards"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "SemiFinalist": {
                    "name": "SemiFinalist",
                    "isArray": false,
                    "type": {
                        "nonModel": "Awards"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "FairPlay": {
                    "name": "FairPlay",
                    "isArray": false,
                    "type": {
                        "nonModel": "Awards"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "FootballTeamScore": {
            "name": "FootballTeamScore",
            "fields": {
                "FirstHalf": {
                    "name": "FirstHalf",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "SecondHalf": {
                    "name": "SecondHalf",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "PenaltyShootout": {
                    "name": "PenaltyShootout",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "ExtraTime": {
                    "name": "ExtraTime",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "Walkover": {
                    "name": "Walkover",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "MatchPlayer": {
            "name": "MatchPlayer",
            "fields": {
                "Name": {
                    "name": "Name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "JerseyNo": {
                    "name": "JerseyNo",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "PhotoURL": {
                    "name": "PhotoURL",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Position": {
                    "name": "Position",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Starting": {
                    "name": "Starting",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "PlayerId": {
                    "name": "PlayerId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "UUID": {
                    "name": "UUID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "Captain": {
                    "name": "Captain",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "Goalkeeper": {
                    "name": "Goalkeeper",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "TimePlayed": {
                    "name": "TimePlayed",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "TeamAdmin": {
            "name": "TeamAdmin",
            "fields": {
                "Name": {
                    "name": "Name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "PhotoURL": {
                    "name": "PhotoURL",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "UUID": {
                    "name": "UUID",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "GroupTeam": {
            "name": "GroupTeam",
            "fields": {
                "Teamid": {
                    "name": "Teamid",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "TeamName": {
                    "name": "TeamName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TeamShortName": {
                    "name": "TeamShortName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Logo": {
                    "name": "Logo",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        }
    },
    "codegenVersion": "3.4.4",
    "version": "6fc5bd517b852754c2cc424559021628"
};