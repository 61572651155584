import React from "react";
import { View, Text, StyleSheet, Platform, Dimensions } from "react-native";
import Layout from "../../../components/include/Layout";
import FeaturesTopSection from "./SubComp/FeaturesTopSection";
import TournamentManagement from "./SubComp/TournamentManagement";
import PlayerPerformance from "./SubComp/PlayerPerformance";
import RealTimeScoring from "./SubComp/RealTimeScoring";
import { colors } from "../../../components/reusable/colour";
const screenWidth = Dimensions.get("window").width;

const Features = () => {
  return (
    <Layout>
      <FeaturesTopSection />
      <TournamentManagement />
      <PlayerPerformance />
      <View style={styles.blueTextContainer}>
        <Text style={styles.blueText}>
          Unlock the Power of Seamless Tournament Management with TMT!
        </Text>
      </View>
      <RealTimeScoring />
    </Layout>
  );
};

const styles = StyleSheet.create({
  blueTextContainer: {
    width: "100%",
    height: 60,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.royalBlueColour,
    paddingHorizontal: 50,
    textAlign: "center",
  },
  blueText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 16 : 12,
  },
});

export default Features;
