import React, { useEffect, useState } from "react";
import {
  View,
  ScrollView,
  Platform,
  StyleSheet,
  Pressable,
  Text,
  Image,
  Dimensions,
  Button,
} from "react-native";
import * as ImagePicker from "expo-image-picker";
import { useRoute } from "@react-navigation/native";
import { colors } from "../../../components/reusable/colour";
import { uploadData } from "aws-amplify/storage";
import { TouchableOpacity } from "react-native-gesture-handler";
import Icon from "react-native-vector-icons/MaterialIcons";
import UploadIcon from "react-native-vector-icons/SimpleLineIcons";
import CommmonButton from "../../../components/reusable/Buttons";
import awsmobile from "../../../aws-exports";
import { ErrorModal } from "../../../components/reusable/AreYouSure";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { DataStore } from "@aws-amplify/datastore";
import { Tournament } from "../../../models";
// import OrangeButton from "../../../components/reusable/Buttons";
const ImageViewer = ({ placeholderImageSource, selectedImage }) => {
  const imageSource = selectedImage
    ? { uri: selectedImage }
    : placeholderImageSource;

  return <Image source={imageSource} style={styles.image} />;
};

const screenWidth = Dimensions.get("window").width;

interface Props {
  style?: any;
  imageStyle?: any;
  imageUrl?: string; // Make the style prop optional
  onImageSelect?;
  kindOfFile?: string;
  index?: Number;
}

const Subgallery = ({
  style,
  imageUrl,
  onImageSelect,
  kindOfFile,
  index,
  imageStyle,
}: Props) => {
  const route = useRoute();
  const bucketName = awsmobile.aws_user_files_s3_bucket;
  // const { imageUrl }: any = route.params;
  const [selectedImages, setSelectedImages] = useState([]);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorModalMesg, setErrorModalMesg] = useState("");
  const [CurrentTournament, setCurrentTournament] = useState("");
  const [tournamentObj, setTournamentObj]: any = useState({});
  const [imageList, setImageList] = useState([]);
  const [Allimagelist, setAllimagelist] = useState([]);

  const getTournamentdata = async () => {
    const TournamentData = await AsyncStorage.getItem("CurrentTournament");
    setCurrentTournament(TournamentData);
    let Tournamentdata = await DataStore.query(Tournament, TournamentData);
    console.log("Tournamentdata", Tournamentdata);
    if (Tournamentdata.Gallery && Tournamentdata.Gallery.length > 0) {
      Tournamentdata.Gallery.map((ele) => {
        setImageList((prevImages) => [
          ...prevImages,
          { URL: ele, FileName: "" },
        ]);
      });
    }
    setTournamentObj(Tournamentdata);
  };
  useEffect(() => {
    getTournamentdata();
    (async () => {
      if (Platform.OS !== "web") {
        const { status } =
          await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== "granted") {
          setErrorModalVisible(true);
          setErrorModalMesg(
            "Sorry, we need camera roll permissions to make this work!"
          );
          // alert("Sorry, we need camera roll permissions to make this work!");
        }
      }
    })();
  }, []);

  const pickMultipleImages = async () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.multiple = true; // Allow multiple file selection
    let arraydata = [];
    input.onchange = (event) => {
      const files: any = event.target.files;
      if (files && files.length > 0) {
        Array.from(files).forEach((file: any) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (e) => {
            const uri = e.target.result;
            const fileName = file.name.replaceAll(" ", "_");
            console.log("File", fileName, file.size);
            console.log("hellouri", uri); // Treat single URI as an array
            arraydata.push({ URL: uri, FileName: fileName });
            setSelectedImages((prevImages) => [
              ...prevImages,
              { URL: uri, FileName: fileName },
            ]);
          };
        });
      } else {
        console.log("No image uploaded");
      }
      console.log("arraydata", arraydata);
      // setSelectedImages([...selectedImages, arraydata]);
    };
    input.click();
  };

  const handleAllgallerydata = () => {
    console.log("hellogallery", selectedImages);
  };

  const handleRemoveImage = (index) => {
    console.log("handleRemoveImage", selectedImages);
    let data = selectedImages.filter((ele, idx) => idx !== index);
    setSelectedImages(data);
  };
  const handlegetAlllist = async () => {
    let arrvalue = [];
    console.log("Allimagelist", Allimagelist);
    let data = [...selectedImages];
    let final = data.map(async (ele) => ({
      files: await uploadLogoFileToStorage(
        `tournament/${tournamentObj.Name.replace(/ /g, "_")}`,
        ele
      ),
    }));
    let allImageslist = await Promise.all(final);

    allImageslist &&
      allImageslist.length > 0 &&
      allImageslist.map((ele) => {
        arrvalue.push(ele.files);
      });
    console.log("handlegetAlllist", arrvalue, allImageslist);

    return arrvalue;
  };

  const handleSubmit = async () => {
    console.log("handleSubmit", selectedImages);
    // let Imagelist = [];
    let finaldata = await handlegetAlllist();
    console.log("handlegetAlllist4", finaldata);
    let dataTournament = await DataStore.query(Tournament, tournamentObj.id);
    const updatedobj = Tournament.copyOf(dataTournament, (updated) => {
      updated.Gallery = finaldata;
      return updated;
    });
    console.log("updatedobj", updatedobj);

    const ResponseUpdatedTournament = await DataStore.save(updatedobj);
    if (ResponseUpdatedTournament) {
      console.log("ResponseUpdatedTournament", ResponseUpdatedTournament);
      setSelectedImages([]);
      getTournamentdata();
    }
  };

  const handleRemoveImage2 = (index) => {
    console.log("handleRemoveImage", Allimagelist);
    let data = imageList.filter((ele, idx) => idx !== index);
    setImageList(data);
  };

  const uploadLogoFileToStorage = async (folderName, key) => {
    // const contentType = key.split(";")[0].split(":")[1];
    console.log("folder name", folderName, "FileName", key.FileName);

    try {
      const response = await fetch(key.URL);
      const blob = await response.blob();
      let Response;
      await uploadData({
        key: `${folderName}/${key.FileName.replace(/ /g, "_")}`,
        data: blob,
      });
      Response = `https://${bucketName}.s3.ap-south-1.amazonaws.com/public/${folderName}/${key.FileName.replace(
        / /g,
        "_"
      )}`;
      return Response;
    } catch (err) {
      console.log("Error uploading file:", err);
    }
  };

  const handleSavechanges = async () => {
    console.log("handleSavechanges", imageList);
    let Arry = [];
    imageList &&
      imageList.length > 0 &&
      imageList.map((ele) => {
        Arry.push(ele.URL);
      });
    console.log("Arry", Arry);
    let dataTournament = await DataStore.query(Tournament, tournamentObj.id);
    const updatedobj = Tournament.copyOf(dataTournament, (updated) => {
      updated.Gallery = Arry;
      return updated;
    });
    console.log("updatedobj", updatedobj);

    const ResponseUpdatedTournament = await DataStore.save(updatedobj);
    if (ResponseUpdatedTournament) {
      console.log("ResponseUpdatedTournament", ResponseUpdatedTournament);
      setImageList([]);
      getTournamentdata();
    }
  };

  return (
    <View>
      <View style={{ alignItems: "center" }}>
        <Pressable
          onPress={pickMultipleImages}
          style={styles.UploadImageButton}
        >
          <UploadIcon name="cloud-upload" size={40} color={colors.orange} />
          <Text
            style={{
              fontFamily: "PlusJakartaSansSemiBold",
              color: colors.white,
              fontSize: 20,
            }}
          >
            Upload Image
          </Text>
          <Text
            style={{ fontFamily: "PlusJakartaSansMedium", color: colors.white }}
          >
            (Supported Formates: JPEG, PNG, GIF)
          </Text>
        </Pressable>
      </View>
      {/* {selectedImages && selectedImages.length > 0 ? ( */}
      <>
        <View
          style={{
            marginVertical: 20,
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            gap: 20,
            flexWrap: "wrap",
          }}
        >
          {selectedImages &&
            selectedImages.length > 0 &&
            selectedImages.map((imageUri, index) => (
              <View style={styles.imageContainerGallery}>
                <Pressable
                  style={styles.imageTrashButton}
                  onPress={() => {
                    handleRemoveImage(index);
                  }}
                >
                  <Icon name="delete" size={28} color="red" />
                </Pressable>
                <Image
                  key={index}
                  source={{ uri: imageUri.URL }}
                  style={styles.image2}
                />
              </View>
            ))}
        </View>

        <View
          style={{
            marginVertical: 20,
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            gap: 20,
            flexWrap: "wrap",
          }}
        >
          {imageList &&
            imageList.length > 0 &&
            imageList.map((imageUri, index) => (
              <View style={styles.imageContainerGallery}>
                <Pressable
                  style={styles.imageTrashButton}
                  onPress={() => {
                    handleRemoveImage2(index);
                  }}
                >
                  <Icon name="delete" size={28} color="red" />
                </Pressable>
                <Image
                  key={index}
                  source={{ uri: imageUri.URL }}
                  style={styles.image2}
                />
              </View>
            ))}
        </View>
        <View style={styles.btnWrapper}>
          <Pressable style={styles.cancelBtn}>
            <Text style={styles.btnText}>Cancel</Text>
          </Pressable>
          <Pressable
            onPress={() => {
              handleSavechanges();
            }}
          >
            {/* <CommmonButton props={"Save Changes"} /> */}
            <Text style={{ color: "white" }}>Save Changes</Text>
          </Pressable>
        </View>
      </>
      {/* // ) : null} */}

      {selectedImages.length > 0 ? (
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          {/* <Pressable
            style={{
              backgroundColor: colors.royalBlueColour,
              paddingVertical: 5,
              paddingHorizontal: 10,
              borderRadius: 5,
              // display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 12,
            }}
          >
            <Text
              style={{
                color: "#fff",
                padding: 5,
              }}
            >
              Add
            </Text>
          </Pressable> */}
          <Pressable onPress={() => handleSubmit()}>
            <Text style={{ color: "white" }}>Add</Text>
            {/* <CommmonButton props={"Add"} /> */}
          </Pressable>
        </View>
      ) : null}

      {errorModalVisible == true ? (
        <ErrorModal
          visibleModal={errorModalVisible}
          SuccessMessage={errorModalMesg}
          onSubmit={handleSubmit}
          header={"Attention"}
        />
      ) : null}

      {/* {selectedImages.length > 0 ? <Button title="Save" /> : null} */}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // alignItems: "center",
    // justifyContent: "center",
  },
  image: {
    width: 200,
    height: 200,
    marginVertical: 10,
  },
  btnWrapper: {
    flexDirection: "row",
    justifyContent:
      Platform.OS === "web" && screenWidth > 760 ? "center" : "center",
    gap: 15,
  },
  saveChangesBtn: {
    backgroundColor: colors.aqua,
    borderColor: colors.aqua,
    borderWidth: 1,
    width: 110,
    height: Platform.OS === "web" && screenWidth > 760 ? 30 : 40,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  btnText: {
    fontFamily: "PlusJakartaSansMedium",
    color: colors.white,
  },
  cancelBtn: {
    borderColor: colors.white,
    borderWidth: 1,
    width: 110,
    height: Platform.OS === "web" && screenWidth > 760 ? 30 : 40,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  image2: {
    width: 200,
    height: 200,
  },
  button: {
    marginVertical: 10,
  },
  text: {
    fontWeight: "700",
    fontFamily: "PlusJakartaSansSemiBold",
  },
  imageContainerGallery: {
    position: "relative",
    borderColor: "#d2d2d2",
    borderWidth: 1,
  },
  imageTrashButton: {
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 9,
    backgroundColor: "#fff",
    padding: 5,
    borderBottomLeftRadius: 25,
  },
  UploadImageButton: {
    borderWidth: 1,
    borderColor: "#c3c3c3",
    borderStyle: "dashed",
    borderRadius: 10,
    padding: 15,
    justifyContent: "center",
    alignItems: "center",
    gap: 7,
  },
});

export default Subgallery;
