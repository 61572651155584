// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Substitution = {
  "ROLLING": "ROLLING",
  "REGULAR": "REGULAR"
};

const MatchResult = {
  "TEAM1": "TEAM1",
  "TEAM2": "TEAM2",
  "DRAW": "DRAW"
};

const MatchStatus = {
  "CREATED": "CREATED",
  "SCHEDULED": "SCHEDULED",
  "COMPLETED": "COMPLETED",
  "INPROGRESS": "INPROGRESS",
  "RESCHEDULED": "RESCHEDULED",
  "CANCELLED": "CANCELLED"
};

const RegistrationStatus = {
  "SUBMITTED": "SUBMITTED",
  "APPROVED": "APPROVED",
  "REJECTED": "REJECTED",
  "CANCELLED": "CANCELLED"
};

const Half = {
  "FIRST": "FIRST",
  "SECOND": "SECOND",
  "EXTRA_TIME": "EXTRA_TIME",
  "SHOOTOUT": "SHOOTOUT"
};

const RoundFormat = {
  "KNOCKOUT": "KNOCKOUT",
  "SINGLE_ROUND_ROBIN": "SINGLE_ROUND_ROBIN",
  "DOUBLE_ROUND_ROBIN": "DOUBLE_ROUND_ROBIN"
};

const Sex = {
  "MALE": "MALE",
  "FEMALE": "FEMALE",
  "OTHER": "OTHER"
};

const PaymentStatus = {
  "PAID": "PAID",
  "UNPAID": "UNPAID",
  "PART_PAID": "PART_PAID"
};

const { TMTLoookup, CoachMaster, UserMaster, MatchTeamMaster, SubstitutionMaster, CardMaster, GoalMaster, Squad, TeamRegistrations, GroupsMaster, GroupStanding, RoundMaster, PlayerMaster, StatisticsMaster, MatchMaster, Tournament, Announcement, SquadCoachMaster, UserMasterTournament, SquadUserMaster, UserMasterTeamRegistrations, MatchMasterUserMaster, SquadPlayerMaster, TeamRegistrationsPlayerMaster, MatchMasterRegistration, PlayerAwards, PauseTimes, Venue, Coordinates, Sponsor, Prize, NextMatch, ObjectField, Awards, Comments, TeamAwards, FootballTeamScore, MatchPlayer, TeamAdmin, GroupTeam } = initSchema(schema);

export {
  TMTLoookup,
  CoachMaster,
  UserMaster,
  MatchTeamMaster,
  SubstitutionMaster,
  CardMaster,
  GoalMaster,
  Squad,
  TeamRegistrations,
  GroupsMaster,
  GroupStanding,
  RoundMaster,
  PlayerMaster,
  StatisticsMaster,
  MatchMaster,
  Tournament,
  Announcement,
  SquadCoachMaster,
  UserMasterTournament,
  SquadUserMaster,
  UserMasterTeamRegistrations,
  MatchMasterUserMaster,
  SquadPlayerMaster,
  TeamRegistrationsPlayerMaster,
  MatchMasterRegistration,
  Substitution,
  MatchResult,
  MatchStatus,
  RegistrationStatus,
  Half,
  RoundFormat,
  Sex,
  PaymentStatus,
  PlayerAwards,
  PauseTimes,
  Venue,
  Coordinates,
  Sponsor,
  Prize,
  NextMatch,
  ObjectField,
  Awards,
  Comments,
  TeamAwards,
  FootballTeamScore,
  MatchPlayer,
  TeamAdmin,
  GroupTeam
};