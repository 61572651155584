import React, { useState } from "react";
import { View, Image, Modal, StyleSheet, Text, Pressable } from "react-native";

const ZoomableImage = ({ imageSource, imageStyle, modalStyle }) => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <View style={styles.container}>
      <Pressable onPress={() => setModalVisible(true)}>
        <Image source={imageSource} style={[imageStyle]} />
      </Pressable>

      <Modal
        visible={modalVisible}
        transparent={true}
        onRequestClose={() => setModalVisible(false)}
      >
        <Pressable
          style={[styles.modalBackground, modalStyle]}
          onPress={() => setModalVisible(false)}
        >
          <View style={styles.modalContainer}>
            <Image
              source={imageSource}
              style={styles.fullImage}
              resizeMode="contain"
            />
          </View>
        </Pressable>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalBackground: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContainer: {
    width: "90%",
    height: "80%",
    backgroundColor: "transparent",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    padding: 10,
    maxHeight: 500,
    maxWidth: 500,
  },
  fullImage: {
    width: "100%",
    height: "100%",
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 10,
    padding: 10,
    backgroundColor: "red",
    borderRadius: 5,
  },
  closeButtonText: {
    color: "white",
    fontWeight: "bold",
  },
});

export default ZoomableImage;
