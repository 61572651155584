import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  Platform,
  Pressable,
  TextInput,
  Image,
  Modal,
  TouchableWithoutFeedback,
  Picker,
  ActivityIndicator,
} from "react-native";
import EditIcon from "react-native-vector-icons/AntDesign";
import {
  H1,
  H2,
  H3,
  H4,
  H5,
  Label,
} from "../../../components/reusable/TextComponent";
import Icon from "react-native-vector-icons/Entypo";
import AddIcon from "react-native-vector-icons/AntDesign";
import Icon2 from "react-native-vector-icons/AntDesign";
import TimePicker from "../../../components/reusable/TimePicker";
import KnockOut4 from "./SubComp/KnockOut4";
import { RadioButton } from "react-native-paper";
import { colors } from "../../../components/reusable/colour";
import AddPlayerModal from "./SubComp/AddPlayerModal";
import ShuffleTeamModal from "./SubComp/ShuffleTeamModal";
import LiveURL from "./SubComp/LiveURL";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { DataStore } from "aws-amplify/datastore";
import ViewMatchDetails from "./SubComp/ViewMatchDetails";
import DeleteIcon from "react-native-vector-icons/Ionicons";
import Youtube from "react-native-vector-icons/AntDesign";
import {
  GroupStanding,
  GroupsMaster,
  MatchMaster,
  MatchMasterUserMaster,
  MatchStatus,
  MatchTeamMaster,
  PlayerMaster,
  RoundMaster,
  TeamRegistrations,
  Tournament,
  UserMaster,
} from "../../../models";
import { createGroups, handleSaveGroup } from "./FixtureIntegration";
import { useNavigation } from "@react-navigation/native";
const screenWidth = Dimensions.get("window").width;
import { StackNavigationProp } from "@react-navigation/stack";
import CustomDatePicker from "../../../components/reusable/CustomeDatePicker";
import { SuccessAutoExitModal } from "../../../components/reusable/AutoExitModal";
import { MatchMasterRegistration } from "../../../models";
import {
  AreYouSureOrange,
  ErrorModal,
} from "../../../components/reusable/AreYouSure";
import AddIcon2 from "react-native-vector-icons/Feather";
import defaultLogo from "../../../../assets/home/logo.png";
import Checkmark from "react-native-vector-icons/Feather";
import Scoreboard from "react-native-vector-icons/MaterialCommunityIcons";
import AddTeamInGroup from "./SubComp/AddTeamInGroup";
import AddMatchModal from "./SubComp/AddMatchModal";
import CustomTimePicker from "../../../components/reusable/CustomeTimePicker";
import ZoomableImage from "../../../components/reusable/ZoomableImage";

const Fixture = () => {
  // const navigation = useNavigation();
  // const navigation = useNavigation();
  const [checkedGroup, setCheckedGroup] = useState("");
  const [checkedFormat, setCheckedFormat] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [fixtureDate, setFixtureDate] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [TeamList, setTeamList] = useState([]);
  const [ActualTeamList, setActualTeamList] = useState([]);
  const [TeamApprovedFlag, setTeamApprovedFlag] = useState([]);
  const [TotalMatch, setTotalMatch] = useState([]);
  const [Grouplist, setGrouplist] = useState([]);
  const [TournamentData, setTournamentData] = useState<any>();
  const [ListOfRound, setListOfRound] = useState([]);
  const [UserDetails, setUserDetails] = useState(null);
  const [FixtureCreateFlag, setFixtureCreateFlag] = useState(false);
  const [MatchListData, setMatchListData] = useState([]);
  const [Roundobj, setRoundobj] = useState<any>();
  const [RoundObject, setRoundObject]: any = useState({});
  const [SelectedTeamObj, setSelectedTeamObj] = useState({});
  const [SelectedTeamPlayerList, setSelectedTeamPlayerList] = useState({});
  const [UpdatedPlayerMatchTeam, setUpdatedPlayerMatchTeam] = useState([]);
  const [PopModalVisible, setPopModalVisible] = useState(false);
  const [SelectedmatchId, setSelectedmatchId] = useState("");
  // const [selectedDate, setSelectedDate] = useState(new Date("01/01/2023"));
  const [selectedDate, setSelectedDate] = useState("");
  const [MatchDateTimeUpdateflag, setMatchDateTimeUpdateflag] = useState(false);
  const [SuccessModalVisible, setSuccessModalVisible] = useState(false);
  const [SunccessModalMesg, setSunccessModalMesg] = useState("");
  const [SelectedTypeOfKnockOut, setSelectedTypeOfKnockOut] = useState("");
  const [SelectedTImeRecord, setSelectedTImeRecord] = useState<Date | null>(
    null
  );
  const [ShuffleTeamVisible, setShuffleTeamVisible] = useState(false);
  const [ShuffleModalVisible, setShuffleModalVisible] = useState(false);
  const [LiveURLVisible, setLiveURLVisible] = useState(false);
  const [viewTeamModalVisible, setViewTeamModalVisible] = useState(false);
  const [grouplistNewTeamAdd, setGrouplistNewTeamAdd] = useState([]);
  const [AddnewTeamdata, setAddnewTeamdata]: any = useState({});

  const [date, setTestDate] = useState(new Date());
  const [GroupName, setGroupName]: any = useState();
  const [NoOfgroups, setNoOfgroups] = useState("");
  const [editIndex, setEditIndex] = useState();
  const [venueList, setVenueList] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState({});
  const { format } = require("date-fns");
  const [TournamentId, setTournamentId] = useState("");
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [deleteSelectedObj, setDeleteSelectedObj] = useState({});

  const [errorModalMesg, setErrorModalMesg] = useState("");
  const [comfirmDeleteModalVisible, setComfirmDeletModalVisible] =
    useState(false);

  const [comfirmDeletModalMesg, setComfirmDeletModalMesg] = useState("");
  const [visibleAddTeamModal, setVisibleAddTeamModal] = useState(false);
  const [SelectedmatchobjforLiveURl, setSelectedmatchobjforLiveURl]: any =
    useState({});
  const [SelectedTeamObjFromGroup, setSelectedTeamObjFromGroup]: any = useState(
    {}
  );
  const [searchFlag, setSearchFlag] = useState(false);
  const [defaultVenue, setdefaultVenue]: any = useState();
  const [addMatchModalVisible, setAddMatchModalVisible] = useState(false);

  const handleDateChange = (newDate) => {
    console.log("handleDateChange", newDate);
    // const dateString = newDate.target.value;

    setSelectedDate(newDate);
  };
  const navigation = useNavigation<StackNavigationProp<any, "StartMatch">>();
  const toggleModal = () => {
    setPopModalVisible(!PopModalVisible);
  };

  const handleVenuechange = (e, idx) => {
    console.log("eventvalue", e, idx);
    setdefaultVenue(e);
    let finaldata = venueList.find((ele, index) => index == idx);
    console.log("finaldata", finaldata);
    setSelectedVenue(finaldata);
  };

  const closeModal = () => {
    setPopModalVisible(false);
  };

  const handleOk = () => {
    setSuccessModalVisible(false);
  };
  const [Groupflag, setGroupflag] = useState(false);
  // const formatdata = [
  //   { label: "4", value: "4" },
  //   { label: "8", value: "8" },
  //   { label: "16", value: "16" },
  //   { label: "32", value: "32" },
  // ];
  const handleTabChange = async (tabIndex: number, value) => {
    console.log("handleTabChange", tabIndex, value);
    setRoundObject(value);
    await getTournament(tabIndex);
    setFixtureCreateFlag(false);
    setMatchDateTimeUpdateflag(false);
    setGrouplist([]);
    setMatchListData([]);
    console.log("tabIndex", tabIndex);
    setActiveTab(tabIndex);
  };
  const setDate = (date) => {
    setFixtureDate(date);
  };

  const handleOpen = (selectedTeamObj) => {
    console.log("selectedMatchObj", selectedTeamObj, TeamList);
    let filterteam = TeamList.filter(
      (ele) => ele.TeamName.trim() == selectedTeamObj.TeamName.trim()
    );
    console.log("filterteam", filterteam);
    setSelectedTeamObj(selectedTeamObj);
    setSelectedTeamPlayerList(filterteam[0]);
    setModalVisible(true);
  };
  const handleClose = () => {
    setModalVisible(false);
  };

  const handleModalClose = () => {
    setVisibleAddTeamModal(false);
  };
  const handleClose2 = () => {
    setModalVisible(false);
  };
  const handleGroup = async (noofgroup) => {
    setTotalMatch([]);
    let Teamtotal = Math.floor(TeamList.length / 2);

    console.log("handleGroup", Teamtotal, noofgroup);
    let flag = true;
    if (Teamtotal >= noofgroup) {
      setNoOfgroups(noofgroup);
      const GroupList = await createGroups(noofgroup, TeamList);
      console.log("MatchGrouplist", TeamList, GroupList);
      const MatchList = await handleSaveGroup(GroupList, TeamList, flag);
      console.log("MatchList", MatchList);
      setGrouplist(GroupList);
      setTotalMatch(MatchList);
    } else {
      setNoOfgroups("");
      setErrorModalVisible(true);
      setErrorModalMesg(
        "Number of Groups can not be more than half of participating team"
      );
      // alert("Number of Groups can not be more than half of participating team");
    }
  };

  const handleSubmit = () => {
    setErrorModalVisible(false);
  };

  const handleNoGroup = async () => {
    let flag = false;
    const MatchList = await handleSaveGroup([], TeamList, flag);
    console.log("MatchList", MatchList);
    setTotalMatch(MatchList);
  };

  const updateSelectedValue = (selectedValue) => {
    console.log("updateSelectedValue", selectedValue);
    setSelectedTypeOfKnockOut(selectedValue);
    // setData(updatedData);
  };

  const handleNewTeamInGroup = async (
    GroupdataList,
    ActualGrouplist,
    targetteam,
    Activetab
  ) => {
    console.log("hellofinalteam", GroupdataList, ActualGrouplist, targetteam);
    setGrouplist(GroupdataList);
    setActiveTab(Activetab);
    setGrouplistNewTeamAdd(ActualGrouplist);

    // if (ActualGrouplist && ActualGrouplist.length > 0) {
    //   setActiveTab(Activetab);
    //   setGrouplistNewTeamAdd(ActualGrouplist);
    //   setAddnewTeamdata(targetteam);
    //   function generateMatchPairings(teams, targetTeam) {
    //     const pairings = [];
    //     teams.forEach((team) => {
    //       if (team.TeamName !== targetTeam) {
    //         pairings.push(`${team.TeamName} Vs ${targetTeam}`);
    //       }
    //     });
    //     return pairings;
    //   }

    //   const targetTeam = targetteam.TeamName;
    //   const matchPairings = generateMatchPairings(
    //     ActualGrouplist[0].Teams,
    //     targetTeam
    //   );
    //   console.log("matchPairings", matchPairings);

    //   setTotalMatch(matchPairings);
    // }
  };

  const handleMatchRemove = async (obj) => {
    console.log("handleMatchRemove", obj);
    const modelToDelete = await DataStore.query(MatchMaster, obj.id);
    const Response = await DataStore.delete(modelToDelete);
    if (Response) {
      setComfirmDeletModalVisible(false);
      getTournament(activeTab);
    }
  };

  const handleSuffleTeamCallBack = async (GroupdataList) => {
    console.log("GroupdataList", GroupdataList);
    setShuffleTeamVisible(false);
    setGrouplist(GroupdataList);
    const MatchList = await handleSaveGroup(GroupdataList, TeamList, true);
    setTotalMatch(MatchList);
  };
  const getTournament = async (RoundNo) => {
    handleCancel();
    console.log("getTournament", RoundNo);
    const TournamentID = await AsyncStorage.getItem("CurrentTournament");
    setTournamentId(TournamentID);
    console.log("RoundNo", RoundNo);
    setTeamApprovedFlag([]);
    console.log("TournamentId", TournamentID);
    const subscription = DataStore.observeQuery(Tournament, (c) =>
      c.id.eq(TournamentID)
    ).subscribe(async (snapshot) => {
      console.log("snapshot", snapshot);
      const tournamentData = snapshot.items;
      console.log("tournamentData", tournamentData);

      setVenueList(tournamentData[0].Venues);
      let Registrationsdata: any = await [tournamentData[0].Registrations];
      let Registrationfinaldata = await Registrationsdata[0].values;
      console.log("Registrationfinaldata", Registrationfinaldata);
      let Roundsdata: any = await [tournamentData[0].Rounds];
      let Rounds = await Roundsdata[0].values;
      let roundsort = Rounds.sort((a, b) => a.No - b.No);
      console.log("roundsort", roundsort);
      let SelectedRound = roundsort[RoundNo];
      console.log("checkformatround", roundsort[RoundNo].Format);
      setRoundObject(roundsort[RoundNo]);
      setCheckedFormat(
        roundsort[RoundNo].Format == "SINGLE_ROUND_ROBIN"
          ? "SRR"
          : roundsort[RoundNo].Format == "DOUBLE_ROUND_ROBIN"
          ? "DRR"
          : "KO"
      );
      AsyncStorage.setItem("SelectedCurrentRound", SelectedRound.id);
      setRoundobj(SelectedRound);
      console.log("SelectedRound", roundsort, SelectedRound);
      setTournamentData(tournamentData[0]);
      await ListRegistration(Registrationfinaldata, tournamentData[0]);
      await getRoundData(roundsort, SelectedRound);
      setActiveTab(RoundNo);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const getMatchList = async (Matchesdata, SelectedRound) => {
    Matchesdata.sort((a, b) => {
      // Convert matchno to numbers for proper numerical comparison
      const matchnoA = parseInt(a.MatchNo);
      const matchnoB = parseInt(b.MatchNo);

      return matchnoA - matchnoB;
    });
    console.log("Matchlist", Matchesdata, SelectedRound);
    // let MatchDataList = [];
    if (SelectedRound.FixturesCreated == true) {
      if (Matchesdata && Matchesdata.length > 0) {
        let arrayOfMatches = Matchesdata.map(async (ele) => {
          let Teamfirst = await ele.Team1;
          let TeamSecond = await ele.Team2;
          let Array = {
            MatchNo: ele.MatchNo > 9 ? `0${ele.MatchNo}` : `00${ele.MatchNo}`,
            GroupName: ele.GroupName,
            Team1: Teamfirst,
            Team2: TeamSecond,
            id: ele.id,
            tournamentID: ele.tournamentID,
            Status: ele.Status,
            Date: ele.Date,
            Time: ele.Time,
            Venue: ele.Venue,
          };
          return Array;
        });
        console.log("GrouppromisesData", arrayOfMatches);
        let final = await Promise.all(arrayOfMatches);
        console.log("Matches2", final);
        console.log("MatchDataList", final[0]);
        setMatchListData(final);
      }
    } else {
      setMatchListData([]);
    }
  };

  const getRoundData = async (Roundsdata, SelectedRound) => {
    const listrounddata = Roundsdata;
    // console.log("listrounddata", Roundsdata, SelectedRound, listrounddata);
    if (SelectedRound.FixturesCreated == true) {
      setFixtureCreateFlag(true);
      let Groups: any = await [SelectedRound.Groups];
      let Groupdata = await Groups[0].values;
      let Matchesdata = [];
      if (Groupdata && Groupdata.length > 0) {
        // console.log("Groupdata", Groupdata);
        let arrayOfMatches = Groupdata.map(async (group) => {
          let matches = await [group.Matches][0].values;
          // group.Matches.toArray();
          // Process matches if needed
          return matches;
        });
        // console.log("arrayOfMatches", arrayOfMatches);
        let final = await Promise.all(arrayOfMatches);
        // console.log("final", final);
        final.forEach((ele, key) => {
          final[key].map((obj) => {
            Matchesdata.push(obj);
          });
        });
        // console.log("Matchesdata", Matchesdata);
        getMatchList(Matchesdata, SelectedRound);
        setGrouplist(Groupdata);
      }
      // Groupdata
    } else {
      setMatchListData([]);
      setFixtureCreateFlag(false);
      setGrouplist([]);
    }
    // Roundsdata.sort((a, b) => a.No - b.No);
    setListOfRound(Roundsdata);
  };

  const ListRegistration = async (data, Tournamentobj) => {
    const initialRegistrationData = data;
    let finalTeamList = [];
    let actualarr = [];
    console.log(
      "initialRegistrationData",
      Tournamentobj,
      initialRegistrationData
    );

    // if (Tournamentobj.Admin1 !== undefined && Tournamentobj.Admin1 !== null) {
    //   console.log("Tournamentobj1", Tournamentobj.Admin1);
    // }
    // if (Tournamentobj.Admin2 !== undefined && Tournamentobj.Admin2 !== null) {
    //   console.log("Tournamentobj2", Tournamentobj.Admin2);
    // }
    // if (Tournamentobj.Admin3 !== undefined && Tournamentobj.Admin3 !== null) {
    //   console.log("Tournamentobj3", Tournamentobj.Admin3);
    // }

    if (initialRegistrationData.length > 0) {
      let data = initialRegistrationData.filter(
        (ele) => ele.Status == "APPROVED"
      );
      console.log("TeamRegistrationFlag", data.length);
      if (data.length > 0) {
        setTeamApprovedFlag(data);
      } else {
        setTeamApprovedFlag([]);
      }

      initialRegistrationData.forEach(async (ele) => {
        if (ele.Status == "APPROVED") {
          let playersDataPromises: any = await [ele.Players];
          let playersData = await playersDataPromises[0].values;
          let Adminpromise: any = await [ele.Admins];
          let Admindata = await Adminpromise[0].values;
          let AdminLists = [];

          Admindata.map(async (obj) => {
            let MasterPlayer = await obj.userMaster;
            AdminLists.push(MasterPlayer);
          });

          let CoachdataPromise = await ele.Coach;
          let ArrayofPlayers = [];
          let playermasterdata = [];
          playersData.map(async (obj) => {
            let MasterPlayer = await obj.playerMaster;
            playermasterdata.push(MasterPlayer);
            let Playerdata = await MasterPlayer.PlayerData;
            if (Playerdata !== undefined) {
              ArrayofPlayers.push(Playerdata);
            }
          });

          finalTeamList.push({
            TeamName: ele.TeamName,
            Tournament: ele.Tournament,
            TeamShortN: ele.TeamShortName == null ? "" : ele.TeamShortName,
            Logo: ele.Logo !== null ? ele.Logo : "",
            ID: ele.id,
            Playerdata: ArrayofPlayers,
            Adminlist: AdminLists,
            playermasterList: playermasterdata,
            Coachdata: CoachdataPromise == undefined ? {} : CoachdataPromise,
          });
          actualarr.push({
            Teamid: ele.id,
            TeamName: ele.TeamName,
            TeamShortName: ele.TeamShortName == null ? "" : ele.TeamShortName,
            Logo: ele.Logo,
          });
        }
      });
      console.log("TeamList", finalTeamList);

      await setTeamList(finalTeamList);
      setActualTeamList(actualarr);
    }
  };

  const handleAddMatch = () => {
    setAddMatchModalVisible(true);
  };

  const handleCancel = () => {
    setAddMatchModalVisible(false);
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      await getTournament(activeTab);
      await getUserdata();
    };
    fetchData();
    setLoading(false);
  }, [TournamentId, searchFlag]);

  const handleShowAddTeamModal = (Groupobj) => {
    setGroupName(Groupobj.GroupName);
    setVisibleAddTeamModal(true);
  };

  const handleCreateGroup = () => {
    console.log("TeamList", TeamList);
    setGroupflag(true);
    if (checkedGroup == "no") {
      setGroupflag(false);
      handleGroup(0);
    }
  };
  const ViewTeamClose = () => {
    setViewTeamModalVisible(false);
  };

  const saveGroups = async (GroupObj) => {
    console.log("GroupObj", GroupObj);
    try {
      const GroupResponse = await DataStore.save(new GroupsMaster(GroupObj));
      console.log("GroupResponse", GroupResponse);
      return GroupResponse;
    } catch (error) {
      console.log("error", error);
    }
  };

  const saveMatch = async (GroupObj, MatchArray, updateTeamArr) => {
    console.log("SaveMatch", GroupObj, MatchArray);
    let FinalMatchArray = MatchArray.filter(
      (obj) => obj.GroupName == GroupObj.GroupName
    );
    console.log("FinalMatchArray", FinalMatchArray);

    await Promise.all(
      FinalMatchArray.map(async (obj, key) => {
        let TeamFirst = {
          TeamName: obj.match.split("Vs")[0],
          LogoURL: obj.Logo.Team1,
          TeamShortName:
            obj.TeamShortName.Team1SN !== null ? obj.TeamShortName.Team1SN : "",
          Score: {
            FirstHalf: 0,
            SecondHalf: 0,
            PenaltyShootout: 0,
            ExtraTime: 0,
            Walkover: false,
          },
          Players:
            TournamentData.SubstitutionType == "ROLLING"
              ? obj.TeamlistAB.Team1Player
              : [],
          TeamSubmitted:
            TournamentData.SubstitutionType == "ROLLING" ? true : false,
        };
        let TeamSecond = {
          TeamName: obj.match.split("Vs")[1],
          LogoURL: obj.Logo.Team2,
          TeamSubmitted:
            TournamentData.SubstitutionType == "ROLLING" ? true : false,
          TeamShortName:
            obj.TeamShortName.Team2SN !== null ? obj.TeamShortName.Team2SN : "",
          Score: {
            FirstHalf: 0,
            SecondHalf: 0,
            PenaltyShootout: 0,
            ExtraTime: 0,
            Walkover: false,
          },
          Players:
            TournamentData.SubstitutionType == "ROLLING"
              ? obj.TeamlistAB.Team2Player
              : [],
        };

        try {
          let team1Resp = await DataStore.save(new MatchTeamMaster(TeamFirst));
          let team2Resp = await DataStore.save(new MatchTeamMaster(TeamSecond));

          console.log(
            "MatchTeamRespData",
            team1Resp.TeamName,
            team2Resp.TeamName
          );
          let MatchObj = {
            MatchNo: obj.SrNo,
            groupsmasterID: GroupObj.id,
            tournamentID: TournamentData.id,
            GroupName: GroupObj.GroupName,
            Team1: team1Resp,
            Team2: team2Resp,
            Status: MatchStatus.CREATED,
            OnlineScoring: false,
          };
          try {
            const MatchResponse = await DataStore.save(
              new MatchMaster(MatchObj)
            );
            console.log("MatchResponse", MatchResponse);

            if (MatchResponse) {
              if (
                TournamentData.Admin1 !== undefined &&
                TournamentData.Admin1 !== null
              ) {
                await DataStore.save(
                  new MatchMasterUserMaster({
                    userMasterUUID: TournamentData.Admin1.UUID,
                    matchMasterId: MatchResponse.id,
                  })
                );
              }
              if (
                TournamentData.Admin2 !== undefined &&
                TournamentData.Admin2 !== null
              ) {
                await DataStore.save(
                  new MatchMasterUserMaster({
                    userMasterUUID: TournamentData.Admin2.UUID,
                    matchMasterId: MatchResponse.id,
                  })
                );
              }
              if (
                TournamentData.Admin3 !== undefined &&
                TournamentData.Admin3 !== null
              ) {
                await DataStore.save(
                  new MatchMasterUserMaster({
                    userMasterUUID: TournamentData.Admin2.UUID,
                    matchMasterId: MatchResponse.id,
                  })
                );
              }

              await DataStore.save(
                new MatchMasterRegistration({
                  teamRegistrationsId: obj.TeamsID.Team1id,
                  matchMasterId: MatchResponse.id,
                })
              );
              await DataStore.save(
                new MatchMasterRegistration({
                  teamRegistrationsId: obj.TeamsID.Team2id,
                  matchMasterId: MatchResponse.id,
                })
              );

              let Teamfirst = await MatchResponse.Team1;
              let TeamSecond = await MatchResponse.Team2;
              let Team1Filtervalue = TeamList.filter(
                (ele) =>
                  ele.TeamName.trim() == Teamfirst.TeamName.trim() ||
                  ele.TeamName.trim() == TeamSecond.TeamName.trim()
              );
              console.log("TeamFilter", Team1Filtervalue);
              let RealtionTabledataTeam1 = [];
              Team1Filtervalue.forEach((obj) => {
                if (obj.Coachdata && Object.keys(obj.Coachdata).length > 0) {
                  if (obj.Coachdata.id !== undefined) {
                    RealtionTabledataTeam1.push({
                      userMasterUUID: obj.Coachdata.id,
                      matchMasterId: MatchResponse,
                    });
                  }
                }

                if (obj.Adminlist.length > 0) {
                  obj.Adminlist.forEach((ele) => {
                    if (ele.UUID !== undefined) {
                      RealtionTabledataTeam1.push({
                        userMasterUUID: ele.UUID,
                        matchMasterId: MatchResponse.id,
                      });
                    }
                  });
                }
                if (obj.Playerdata.length > 0) {
                  obj.Playerdata.forEach((ele) => {
                    if (ele.UUID !== undefined) {
                      RealtionTabledataTeam1.push({
                        userMasterUUID: ele.UUID,
                        matchMasterId: MatchResponse.id,
                      });
                    }
                  });
                }
              });
              console.log("RealtionTabledataTeam1", RealtionTabledataTeam1);
              RealtionTabledataTeam1 &&
                RealtionTabledataTeam1.length > 0 &&
                RealtionTabledataTeam1.forEach(async (obj) => {
                  try {
                    const Response2 = await DataStore.save(
                      new MatchMasterUserMaster(obj)
                    );
                    console.log("responseData", Response2);
                  } catch (error) {
                    console.log("error", error);
                  }
                });
            }
          } catch (error) {
            console.log("error", error);
          }
        } catch (error) {
          console.log("error", error);
        }
      })
    );

    // Executed after all match saving operations
    if (updateTeamArr && updateTeamArr.length > 0) {
      await getTournament(activeTab);
    }
  };

  const SaveGroupStanding = (GroupObj) => {
    console.log("createStanding", Groupflag, GroupObj);
    let GroupStandingArrlist = [];
    if (Groupflag == true) {
      console.log("StandingCreate", GroupObj, Grouplist);
      let value = Grouplist.filter(
        (ele) => ele.GroupName == GroupObj.GroupName
      );
      GroupObj.Teams.forEach((obj) => {
        GroupStandingArrlist.push({
          groupsmasterID: GroupObj.id,
          TeamName: obj.TeamName,
          TeamShortName: obj.TeamShortName !== null ? obj.TeamShortName : "",
          TeamLogo: obj.Logo,
          TeamId: obj.Teamid,
          CreatedBy: UserDetails.Name,
          LastUpdatedBy: UserDetails.Name,
          Played: 0,
          Won: 0,
          Lost: 0,
          Drawn: 0,
          Position: 0,
          For: 0,
          Form: [],
          Against: 0,
          Points: 0,
          Difference: 0,
          ChangeInPosition: "",
        });
      });
    } else {
      GroupObj.Teams.forEach((obj) => {
        GroupStandingArrlist.push({
          groupsmasterID: GroupObj.id,
          TeamName: obj.TeamName,
          TeamLogo: obj.Logo,
          TeamId: obj.Teamid,
          TeamShortName: obj.TeamShortName !== null ? obj.TeamShortName : "",
          CreatedBy: UserDetails.Name,
          LastUpdatedBy: UserDetails.Name,
          Played: 0,
          Won: 0,
          Lost: 0,
          Drawn: 0,
          Position: 0,
          For: 0,
          Form: [],
          Against: 0,
          Points: 0,
          Difference: 0,
          ChangeInPosition: "",
        });
      });
    }
    console.log("GroupStandingArrlist", GroupStandingArrlist);
    GroupStandingArrlist.forEach(async (ele) => {
      try {
        const SaveStandingObj = await DataStore.save(new GroupStanding(ele));
        console.log("SaveStandingObj", SaveStandingObj);
      } catch (error) {
        console.log("error", error);
      }
    });
  };
  const handlegetdataplayer = async (ID) => {
    const data = await DataStore.query(PlayerMaster, (tournament) =>
      tournament.Teams.teamRegistrations.id.eq(ID)
    );
    console.log("handlegetdataplayer", data);
    let finaldata = JSON.parse(JSON.stringify(data));
    console.log("finaldata", finaldata);
    return finaldata;
  };

  const handleFixtureCreated = async () => {
    let arrMatch = [];
    console.log("ActivatedTab", activeTab);
    console.log(
      "Grouplist",
      Groupflag,
      Grouplist,
      activeTab,
      TeamList,
      ListOfRound
    );
    console.log("TournamentData", Grouplist, TotalMatch, TournamentData);
    // getTournament(activeTab);
    let GroupObjList = [];
    let FinalMatchArray = [];
    let RoundId = ListOfRound[activeTab].id;
    function modifyArray(groups, matches) {
      console.log("modifyArray", groups);
      let output = [];
      let seenMatches = new Set();
      let srno = MatchListData.length > 0 ? MatchListData.length + 1 : 1;
      matches.forEach((match) => {
        let matched = false;
        groups.forEach((group) => {
          group.Teams.forEach((team) => {
            if (match.includes(team.TeamName) && !matched) {
              let teamNames = match.split(" Vs ");
              let teamLogos = [];
              let teamId = [];
              let teamshortname = [];
              let TeamABlist = [];
              teamNames.forEach((name) => {
                let foundTeam = group.Teams.find(
                  (t) => t.TeamName.trim() === name.trim()
                );
                if (foundTeam && foundTeam.Logo) {
                  console.log("foundTeam", foundTeam);
                  teamLogos.push(foundTeam.Logo);
                } else {
                  teamLogos.push("");
                }

                if (foundTeam && foundTeam.TeamShortN) {
                  console.log("foundTeam", foundTeam);
                  teamshortname.push(foundTeam.TeamShortN);
                } else {
                  teamshortname.push("");
                }
                if (foundTeam && foundTeam.ID) {
                  console.log("foundTeam", foundTeam);
                  teamId.push(foundTeam.ID);
                } else {
                  teamId.push("");
                }
                console.log(
                  "foundTeamplayermasterList",
                  foundTeam,
                  foundTeam.playermasterList
                  // foundTeam.playermasterList.length > 0
                );
                if (
                  foundTeam.playermasterList &&
                  foundTeam.playermasterList.length > 0
                ) {
                  let arrlist = [];
                  console.log("foundTeamplayermasterList", foundTeam);
                  foundTeam.playermasterList.map((ele) => {
                    arrlist.push({
                      Name: ele.Name,
                      JerseyNo: ele.JerseyNo,
                      PhotoURL: ele.PhotoURL,
                      Position: "",
                      Starting: true,
                      PlayerId: ele.id,
                      Captain: false,
                      Goalkeeper: false,
                      TimePlayed: 0,
                    });
                  });

                  TeamABlist.push(arrlist);
                } else {
                  TeamABlist.push([]);
                }
              });
              console.log("output", output);
              output.push({
                GroupName: group.GroupName,
                SrNo: srno++,
                match: match,

                Logo: { Team1: teamLogos[0], Team2: teamLogos[1] },
                TeamsID: { Team1id: teamId[0], Team2id: teamId[1] },
                TeamShortName: {
                  Team1SN: teamshortname[0],
                  Team2SN: teamshortname[1],
                },
                TeamlistAB: {
                  Team1Player: TeamABlist[0],
                  Team2Player: TeamABlist[1],
                },
              });
              matched = true;
            }
          });
        });
        if (matched) seenMatches.add(match);
      });

      return output;
    }
    console.log("grouplistNewTeamAdd", Grouplist, grouplistNewTeamAdd);

    if (grouplistNewTeamAdd && grouplistNewTeamAdd.length > 0) {
      function compareTeams(group1, group2) {
        const unmatchedTeams = [];
        group1.Teams.forEach((team1) => {
          const matchedTeam = group2.Teams.find(
            (team2) => team2.TeamName === team1.TeamName
          );
          if (!matchedTeam) {
            unmatchedTeams.push(team1);
          }
        });
        return unmatchedTeams;
      }

      let datas = await Promise.all(
        Grouplist.map(async (ele) => {
          let groupdata = await DataStore.query(GroupsMaster, ele.id); // Adjusted to use ele.id
          console.log(
            "unknown value",
            ele.Teams,
            ele.Teams.length,
            groupdata.Teams,
            groupdata.Teams.length
          );
          if (groupdata && ele.Teams.length > groupdata.Teams.length) {
            const unmatchedTeams = compareTeams(ele, groupdata);
            return { ...ele, UnmatchedTeams: unmatchedTeams };
          }
        })
      );

      // Filter out undefined values
      datas = datas.filter((ele) => ele !== undefined);
      let gropusdata = JSON.parse(JSON.stringify(datas));

      console.log("DestinationOutput", datas);

      function generateMatchPairings(teams, targetTeams) {
        const pairings = [];
        targetTeams.forEach((targetTeam) => {
          teams.forEach((team) => {
            if (team.TeamName !== targetTeam.TeamName) {
              pairings.push(`${team.TeamName} Vs ${targetTeam.TeamName}`);
            }
          });
        });
        return pairings;
      }

      datas.map((ele) => {
        const targetTeams = ele.UnmatchedTeams; // Example array of target teams
        const matchPairings = generateMatchPairings(ele.Teams, targetTeams);
        console.log("matchPairings", matchPairings);
        arrMatch.push(matchPairings);
      });
      const flattenedArray = arrMatch.flat();
      console.log("arrMatch", arrMatch);
      // let Grouplistfinal = JSON.parse(JSON.stringify(grouplistNewTeamAdd));
      // console.log("Grouplistfinal", Grouplistfinal);
      await Promise.all(
        datas.map((ele) => {
          ele.Teams.map(async (ele) => {
            const val = TeamList.find((team) => ele.TeamName === team.TeamName);
            if (val) {
              console.log("TeamName", val);
              Object.assign(ele, val);
            }
          });
        })
      );
      console.log("thirdarry", datas);

      FinalMatchArray = modifyArray(datas, flattenedArray);
      console.log("FinalMatchArray", gropusdata, FinalMatchArray);

      let groupdata = await DataStore.query(
        GroupsMaster,
        grouplistNewTeamAdd[0].id
      );

      console.log("groupdata", Grouplist, MatchListData, groupdata);

      await Promise.all(
        gropusdata.map(async (ele) => {
          if (groupdata) {
            const updatedgroupObj = GroupsMaster.copyOf(
              groupdata,
              (updated) => {
                updated.Teams = ele.Teams;
                return updated;
              }
            );
            try {
              const UpdatedGroup = await DataStore.save(updatedgroupObj);
              console.log("UpdatedGroup", UpdatedGroup);
              if (UpdatedGroup) {
                await Promise.all(
                  ele.UnmatchedTeams.map(async (ele) => {
                    let standingobj = {
                      groupsmasterID: grouplistNewTeamAdd[0].id,
                      TeamName: ele.TeamName,
                      TeamLogo: ele.Logo,
                      TeamId: ele.Teamid,
                      TeamShortName:
                        ele.TeamShortName !== null ? ele.TeamShortName : "",
                      CreatedBy: UserDetails.Name,
                      LastUpdatedBy: UserDetails.Name,
                      Played: 0,
                      Won: 0,
                      Lost: 0,
                      Drawn: 0,
                      Position: 0,
                      For: 0,
                      Form: [],
                      Against: 0,
                      Points: 0,
                      Difference: 0,
                      ChangeInPosition: "",
                    };
                    try {
                      const SaveStandingObj = await DataStore.save(
                        new GroupStanding(standingobj)
                      );
                      console.log("SaveStandingObj", SaveStandingObj);
                    } catch (error) {
                      console.log("block1");
                    }
                  })
                );

                await saveMatch(
                  UpdatedGroup,
                  FinalMatchArray,
                  grouplistNewTeamAdd
                );
              }
            } catch (error) {
              console.log("block2");
            }
          }
        })
      );

      // Executed after all executions inside Promise.all
      // await getTournament(activeTab);

      setNoOfgroups("");
      setGrouplistNewTeamAdd([]);
      setAddnewTeamdata({});
      setFixtureCreateFlag(true);
      setSunccessModalMesg("Fixture Created Successfully");
      setSuccessModalVisible(true);
    } else {
      if (Groupflag == true) {
        console.log("TotalMatch", TotalMatch);

        // let finalGroupArray = Grouplist.map((groupObj) => ({
        //   GroupName: groupObj.GroupName,
        //   Teams: groupObj.Teams.map((team) => team.ID),
        // }));

        // console.log("finalGroupArray", finalGroupArray);
        let FirstArr = [...Grouplist];
        let SecondArr = [...ActualTeamList];
        const ResultofGroup = FirstArr.map((group) => ({
          ...group,
          Teams: group.Teams.map((team) => {
            const matchingTeam = SecondArr.find(
              (t) => t.TeamName === team.TeamName
            );
            return matchingTeam ? { ...matchingTeam } : team;
          }),
        }));

        console.log("ResultofGroup", ActualTeamList, ResultofGroup);

        console.log("Grouplist", Grouplist);
        ResultofGroup.map((ele) => {
          GroupObjList.push({
            GroupName: ele.GroupName,
            CreatedBy: UserDetails.Name,
            LastUpdatedBy: UserDetails.Name,
            RoundMasterID: RoundId,
            Teams: ele.Teams,
          });
        });
        console.log("GroupObjList", GroupObjList);

        FinalMatchArray = modifyArray(Grouplist, TotalMatch);
        console.log("FinalMatchArray", GroupObjList, FinalMatchArray);
      } else {
        GroupObjList.push({
          GroupName: "A",
          CreatedBy: UserDetails.Name,
          LastUpdatedBy: UserDetails.Name,
          RoundMasterID: RoundId,
          Teams: ActualTeamList,
        });
        console.log("Grouparr", GroupObjList);
        FinalMatchArray = await modifyArray(GroupObjList, TotalMatch);
        console.log("FinalMatchArray", GroupObjList, FinalMatchArray);
      }

      await GroupObjList.forEach(async (obj) => {
        const ResponseGroup = await saveGroups(obj);
        console.log("ResposeGroup", ResponseGroup);
        await SaveGroupStanding(ResponseGroup);
        await saveMatch(ResponseGroup, FinalMatchArray, []);
      });

      try {
        const Roundmasterdata = await DataStore.query(RoundMaster, Roundobj.id);
        if (Roundmasterdata) {
          const updateRoundmaster = RoundMaster.copyOf(
            Roundmasterdata,
            (updated) => {
              updated.FixturesCreated = true;

              // Assuming FixturesCreated is the correct property name
              return updated;
            }
          );

          const UpdateRoundObj = await DataStore.save(updateRoundmaster);

          let TournamentObj = await DataStore.query(
            Tournament,
            TournamentData.id
          );
          if (Roundmasterdata) {
            const updatedTournament = Tournament.copyOf(
              TournamentObj,
              (updated) => {
                updated.Status = "INPROGRESS";

                // Assuming FixturesCreated is the correct property name
                return updated;
              }
            );

            const UpdateTournamentObj = await DataStore.save(updatedTournament);
          }

          console.log("Updated RoundObj:", UpdateRoundObj);
          if (UpdateRoundObj) {
            setNoOfgroups("");
            await getTournament(activeTab);
            setFixtureCreateFlag(true);
            setSunccessModalMesg("Fixture Created Successfully");

            setSuccessModalVisible(true);
          }
        } else {
          console.error("Tournament not found");
        }
      } catch (error) {
        console.error("Error updating tournament:", error);
      }
    }
  };

  const getUserdata = async () => {
    let userDetails = await AsyncStorage.getItem("UserDetail");
    let userdata = await DataStore.query(UserMaster, userDetails);
    console.log("userDetails", userdata);

    setUserDetails(userdata);
  };

  const MatchTeamMasterPlayerUpdate = async (key, Rounddata) => {
    const RoundsData = await DataStore.query(RoundMaster, Rounddata.id);
    setRoundobj(RoundsData);
    if (RoundsData.FixturesCreated == true) {
      setFixtureCreateFlag(true);
      let Groups: any = await [RoundsData.Groups];
      let Groupdata = await Groups[0].values;
      // let Groups = await RoundsData.Groups.toArray();
      // let Groupdata = await Promise.all(Groups);
      let Matchesdata = [];
      if (Groupdata && Groupdata.length > 0) {
        console.log("Groupdata", Groupdata);
        let arrayOfMatches = Groupdata.map(async (group) => {
          let matches = group.Matches.toArray();
          // Process matches if needed
          return matches;
        });
        let final = await Promise.all(arrayOfMatches);
        console.log("final", final);
        final.forEach((ele, key) => {
          final[key].map((obj) => {
            Matchesdata.push(obj);
          });
        });
        console.log("Matchesdata", Matchesdata);
        handleCloseSuccess3();
        getMatchList(Matchesdata, RoundsData);
        setGrouplist(Groupdata);
      }
      // Groupdata
    } else {
      setMatchListData([]);
      setGrouplist([]);
    }
    // setRoundobj(Rounddata);
    // // AsyncStorage.setItem("SelectedCurrentRound", Rounddata);
    // AsyncStorage.setItem("SelectedCurrentRound", JSON.stringify(Rounddata));
    // const subscription = DataStore.observeQuery(MatchMaster, (c) =>
    //   c.tournamentID.eq(key)
    // ).subscribe(async (snapshot) => {
    //   console.log("snapshot", snapshot);
    //   const MatchTeamGetObj = snapshot.items;
    //   console.log("MatchTeamGetObj", MatchTeamGetObj);
    //   // setUpdatedPlayerMatchTeam(MatchTeamGetObj);
    //   getMatchList(MatchTeamGetObj, Rounddata);
    // });
    // return () => {
    //   subscription.unsubscribe();
    // };
  };

  const handleRedirectStartMatch = async (Matchid) => {
    console.log("hellomatchid", Matchid);
    let matcheobj: any = await DataStore.query(MatchMaster, Matchid);
    console.log("matcheobj", matcheobj);
    if (matcheobj.Status == "INPROGRESS" || matcheobj.Status == "COMPLETED") {
      navigation.navigate("ScoringApp", {
        MatchID: Matchid,
      });
    } else {
      navigation.navigate("StartMatch", {
        MatchID: Matchid,
      });
    }
  };
  const handleSelectRoun = (selecttypeROund) => {
    console.log("selecttypeROund", selecttypeROund);
    setGrouplist([]);
    setTotalMatch([]);
    setCheckedGroup("");
    setGroupflag(false);

    setCheckedFormat(selecttypeROund);
  };

  const handleGroupYesNoSelect = (value) => {
    if (value == "no") {
      handleNoGroup();
    }

    setGroupflag(false);
    setGrouplist([]);
    setTotalMatch([]);
    setCheckedGroup(value);
  };

  const handleTimeset = (selectedTime) => {
    console.log("handleTimeset", selectedTime);
    setSelectedTImeRecord(selectedTime);
  };

  const handleSelectedTime = (event) => {
    console.log("selectTime", event);

    // let localTime = event.toLocaleTimeString("en-US", {
    //   hour: "2-digit",
    //   minute: "2-digit",
    //   hour12: false,
    // });

    // console.log("localTime", localTime);
    // // let timevalue = event.target.value;
    // let finalTime = {
    //   hours: localTime.split(":")[0],
    //   minutes: localTime.split(":")[1],
    // };
    setSelectedTImeRecord(event);
  };

  // const handleTimeDateUpdate = (index, obj) => {
  //   setSelectedDate("");
  //   setSelectedTImeRecord(null);
  //   console.log("we are here", obj);
  //   setMatchDateTimeUpdateflag(true);
  //   setEditIndex(index);
  //   if (obj.Date !== null && obj.Time !== null) {
  //     const date = new Date(obj.Date);

  //     const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  //     const monthsOfYear = [
  //       "Jan",
  //       "Feb",
  //       "Mar",
  //       "Apr",
  //       "May",
  //       "Jun",
  //       "Jul",
  //       "Aug",
  //       "Sep",
  //       "Oct",
  //       "Nov",
  //       "Dec",
  //     ];

  //     const dayOfWeek = daysOfWeek[date.getDay()];
  //     const monthName = monthsOfYear[date.getMonth()];
  //     const dayOfMonth = date.getDate();
  //     const year = date.getFullYear();

  //     const formattedDate: any = `${dayOfWeek} ${monthName} ${dayOfMonth} ${year} 00:00:00 GMT+0530 (India Standard Time)`;
  //     console.log("hellodate", new Date(formattedDate));
  //     setSelectedDate(formattedDate);

  //     const Timevalue: any = new Date(`${obj.Date}T${obj.Time}`);
  //     setSelectedTImeRecord(Timevalue);
  //   }
  //   if (obj && Object.keys(obj).length > 0) {
  //     setdefaultVenue(obj.Venue.Name);
  //   }
  // };
  const handleTimeDateUpdate = (index, obj) => {
    setSelectedDate("");
    setSelectedTImeRecord(null);
    console.log("we are here", obj);
    setMatchDateTimeUpdateflag(true);
    setEditIndex(index);

    if (obj.Date !== null && obj.Time !== null) {
      const date = new Date(obj.Date);

      const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const monthsOfYear = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const dayOfWeek = daysOfWeek[date.getDay()];
      const monthName = monthsOfYear[date.getMonth()];
      const dayOfMonth = date.getDate();
      const year = date.getFullYear();

      const formattedDate =
        date.toLocaleString("en-US", {
          weekday: "short",
          month: "short",
          day: "numeric",
          year: "numeric",
        }) + " 00:00:00 GMT+0530 (India Standard Time)";

      console.log("formattedDate", formattedDate);
      setSelectedDate(formattedDate);

      const Timevalue = new Date(`${obj.Date}T${obj.Time}:00`);
      console.log("Timevalue", Timevalue);
      setSelectedTImeRecord(Timevalue);
    }

    if (obj && Object.keys(obj).length > 0) {
      setdefaultVenue(obj.Venue.Name);
    }
  };
  const handleDeleteMatch = (obj) => {
    setDeleteSelectedObj(obj);
    setComfirmDeletModalVisible(true),
      setComfirmDeletModalMesg("Are you sure do you want to delete??");
  };

  const handleConfirmMatch = () => {
    handleMatchRemove(deleteSelectedObj);
  };

  const handleCancelMatch = () => {
    setComfirmDeletModalVisible(false);
  };

  const handleSubmitDateTime = async (matchObj) => {
    let TimeSend = "00:00";
    console.log("handleSubmitDateTime", matchObj);
    console.log(
      "SelectedTImeRecord",
      SelectedTImeRecord
      // selectedDate,
      // selectedVenue
    );
    // const Time = new Date(SelectedTImeRecord);

    // const hours = ("0" + Time.getHours()).slice(-2);
    // const minutes = ("0" + Time.getMinutes()).slice(-2);

    // const timeString = `${hours}:${minutes}`;
    const timeString: any = SelectedTImeRecord;

    const date = new Date(selectedDate);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);

    const formattedDate = `${year}-${month}-${day}`;
    console.log("formattedDate", formattedDate, timeString);
    try {
      const MatchObjGet = await DataStore.query(MatchMaster, matchObj.id);
      console.log("MatchObjGet", MatchObjGet);
      if (MatchObjGet) {
        const MatchObjUpdate = MatchMaster.copyOf(MatchObjGet, (updated) => {
          updated.Date = formattedDate;
          updated.Time = timeString;
          updated.Venue = selectedVenue;
          updated.Status =
            MatchObjGet.Status == "INPROGRESS"
              ? "INPROGRESS"
              : MatchStatus.SCHEDULED;
          return updated;
        });
        console.log("MatchObjUpdate", MatchObjUpdate);
        const ResultOfMatchUpdated = await DataStore.save(MatchObjUpdate);
        console.log("ResultOfMatchUpdated", ResultOfMatchUpdated);
        if (ResultOfMatchUpdated) {
          setMatchDateTimeUpdateflag(false);
          getTournament(activeTab);
        }
      }
    } catch (error) {
      console.error("Error updating tournament:", error);
    }
  };
  const handleCloseSuccess = () => {
    console.log("helloClose");
    setShuffleTeamVisible(false);
  };
  const handleGroupTeamShuffle = (Groupobj, TeamName) => {
    console.log("handleGroupTeamShuffle", Groupobj, TeamName);
    setGroupName(Groupobj.GroupName);
    setSelectedTeamObjFromGroup(TeamName);
    setShuffleTeamVisible(true);
  };
  const handleCloseSuccess3 = () => {
    setLiveURLVisible(false);
  };
  const handleLiveURL = (MatchObject) => {
    console.log("matchobject", MatchObject);
    setSelectedmatchobjforLiveURl(MatchObject);
    setLiveURLVisible(true);
  };

  const handleViewTeamModal = (obj) => {
    console.log("helloconsole", obj.id);
    setSelectedmatchId(obj.id);
    setViewTeamModalVisible(!viewTeamModalVisible);
  };
  // added handleSearch functionality
  const handleSearch = (text: any) => {
    setSearchFlag(true);

    if (!text) {
      setSearchFlag(false);
      setMatchListData(MatchListData); // Reset to the original data when search text is empty
    } else {
      console.log("filtertext", text);

      const filteredResults = MatchListData.filter((match) => {
        // Check if any attribute of the match matches the search text
        if (
          Object.values(match).some(
            (value: any) =>
              typeof value === "string" &&
              value.toLowerCase().includes(text.toLowerCase())
          )
        ) {
          return true; // If any attribute of the match matches, return true
        }

        // Check nested objects for matches
        for (const key in match) {
          const nestedObject = match[key];
          if (nestedObject && typeof nestedObject === "object") {
            // Check each property of the nested object
            for (const prop in nestedObject) {
              if (
                typeof nestedObject[prop] === "string" &&
                nestedObject[prop].toLowerCase().includes(text.toLowerCase())
              ) {
                return true; // If any attribute of the nested object matches, return true
              }
            }
          }
        }

        return false; // If no match is found, return false
      });

      console.log("filtered results", filteredResults);
      setMatchListData(filteredResults);
    }
  };

  return (
    <View style={{ width: "100%" }}>
      <View style={styles.tabContainer}>
        {ListOfRound &&
          ListOfRound.length > 0 &&
          ListOfRound.map((val, key) => {
            return (
              <Pressable
                style={[
                  styles.tabButton,
                  activeTab === key ? styles.activeTabButton : styles.tabButton,
                ]}
                onPress={() => handleTabChange(key, val)}
              >
                <Text
                  style={[
                    styles.tabButtonText,
                    {
                      color: activeTab === key ? colors.aqua : colors.white,
                      opacity: activeTab === key ? 1 : 0.6,
                    },
                  ]}
                >
                  {val.Name}
                </Text>
              </Pressable>
            );
          })}
      </View>

      {TeamApprovedFlag.length > 0 ? (
        <View style={styles.tabContent}>
          {/* {activeTab ===  && ( */}
          <View style={styles.tabContentWrapper}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                alignItems: "center",
              }}
            >
              {FixtureCreateFlag == false ? (
                <Label props={`No. of  Teams:${TeamApprovedFlag.length}`} />
              ) : null}
              {/* <H4 props={"9"} /> */}
            </View>
            {FixtureCreateFlag == false && RoundObject.Format !== "KNOCKOUT" ? (
              <View style={{ marginTop: 10 }}>
                <Label props={"Select Format"} />
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",

                    gap: Platform.OS == "web" && screenWidth > 760 ? 40 : 5,
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <RadioButton
                      value="yes"
                      status={checkedFormat === "SRR" ? "checked" : "unchecked"}
                      onPress={() => handleSelectRoun("SRR")}
                      color={colors.aqua}
                    />
                    <Text style={{ color: colors.white }}>
                      Single Round Robin
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <RadioButton
                      value="no"
                      status={checkedFormat === "DRR" ? "checked" : "unchecked"}
                      onPress={() => handleSelectRoun("DRR")}
                      color={colors.aqua}
                    />
                    <Text style={{ color: colors.white }}>
                      Double Round Robin
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <RadioButton
                      value="no"
                      status={checkedFormat === "KO" ? "checked" : "unchecked"}
                      onPress={() => handleSelectRoun("KO")}
                      color={colors.aqua}
                    />
                    <Text style={{ color: colors.white }}>Knockout</Text>
                  </View>
                </View>
              </View>
            ) : null}
            {(checkedFormat == "SRR" || checkedFormat == "DRR") &&
            FixtureCreateFlag == false &&
            RoundObject.Format !== "KNOCKOUT" ? (
              <View
                style={{
                  display: "flex",
                  flexDirection:
                    Platform.OS == "web" && screenWidth > 760
                      ? "row"
                      : "column",
                  justifyContent: "space-between",
                  marginTop: 10,
                }}
              >
                <View
                  style={{
                    flex: Platform.OS == "web" && screenWidth > 760 ? 1 : 0,
                    gap: 5,
                  }}
                >
                  <Label props={"Do you want to create group? "} />
                  <View style={{ flexDirection: "row", gap: 40 }}>
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <RadioButton
                        value="yes"
                        status={
                          checkedGroup === "yes" ? "checked" : "unchecked"
                        }
                        onPress={() => handleGroupYesNoSelect("yes")}
                        color={colors.aqua}
                      />
                      <Text style={{ color: colors.white }}>Yes</Text>
                    </View>
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <RadioButton
                        value="no"
                        status={checkedGroup === "no" ? "checked" : "unchecked"}
                        onPress={() => handleGroupYesNoSelect("no")}
                        color={colors.aqua}
                      />
                      <Text style={{ color: colors.white }}>No</Text>
                    </View>
                  </View>
                </View>
                {checkedGroup == "yes" ? (
                  <View
                    style={{
                      flex: Platform.OS == "web" && screenWidth > 760 ? 1 : 0,
                      gap: 5,
                    }}
                  >
                    <Label props={"No. of Groups"} />
                    <TextInput
                      style={styles.input}
                      placeholder=""
                      value={NoOfgroups}
                      onChangeText={handleGroup}
                    />
                  </View>
                ) : null}
                {TotalMatch.length > 0 ? (
                  <View style={{ flex: 1, gap: 5 }}>
                    <Label props={"No. of Matches"} />
                    <Text style={{ color: colors.white }}>
                      {TotalMatch.length}
                    </Text>
                  </View>
                ) : null}
                <View
                  style={{
                    padding: Platform.OS == "web" && screenWidth > 760 ? 0 : 10,
                  }}
                >
                  {checkedGroup == "yes" && Grouplist.length > 0 ? (
                    <Pressable
                      style={{
                        backgroundColor: colors.aqua,
                        paddingVertical: 5,
                        paddingHorizontal: 10,
                        borderRadius: 5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onPress={handleCreateGroup}
                    >
                      <Text
                        style={{
                          color: colors.blue,
                          fontFamily: "PlusJakartaSansMedium",
                          padding:
                            Platform.OS == "web" && screenWidth > 760 ? 0 : 5,
                        }}
                      >
                        Create Group
                      </Text>
                    </Pressable>
                  ) : null}
                </View>
              </View>
            ) : null}

            {(checkedFormat == "KO" && FixtureCreateFlag == false) ||
            (RoundObject.Format == "KNOCKOUT" && FixtureCreateFlag == false) ? (
              <View style={styles.dropdownLabelContainer}>
                <Text style={styles.dropdownLabel}>Teams</Text>
                {/* <CustomDropdown
                  data={formatdata}
                  selectedValue={SelectedTypeOfKnockOut}
                  onValueChange={(selectedValue) =>
                    updateSelectedValue(selectedValue)
                  }
                  style={styles.dropDownContainer}
                ></CustomDropdown> */}

                <Picker
                  style={styles.teamsDropdownContainer}
                  selectedValue={SelectedTypeOfKnockOut}
                  onValueChange={(selectedValue) =>
                    updateSelectedValue(selectedValue)
                  }
                >
                  <Picker.Item label="Select number of team" value="team" />
                  <Picker.Item label="4" value="4" />
                  <Picker.Item label="8" value="8" />
                  <Picker.Item label="16" value="16" />
                  <Picker.Item label="32" value="32" />
                </Picker>
              </View>
            ) : null}
            {/* group container started */}
            <View>
              {(SelectedTypeOfKnockOut !== "" && checkedFormat == "KO") ||
              RoundObject.Format == "KNOCKOUT" ||
              (FixtureCreateFlag == true && checkedFormat == "KNOCKOUT") ? (
                <KnockOut4
                  KnockOutType={SelectedTypeOfKnockOut}
                  TournamentObj={TournamentData}
                  RoundFormatName={checkedFormat}
                  Roundobj={Roundobj}
                  MatchTeamMasterPlayerUpdate={MatchTeamMasterPlayerUpdate}
                />
              ) : null}

              {(Groupflag == true && checkedFormat !== "KO") ||
              (FixtureCreateFlag == true && checkedFormat !== "KO") ? (
                <View
                  style={{
                    marginTop: 30,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: 20,
                    rowGap: 35,
                    flexWrap: "wrap",
                  }}
                >
                  {Grouplist &&
                    Grouplist.length > 0 &&
                    Grouplist.map((val, key) => {
                      const isEvenIndex = key % 2 === 0;
                      return (
                        <View style={styles.groupItemWrapper}>
                          <View
                            style={[
                              styles.groupItemHeading,
                              {
                                backgroundColor: isEvenIndex
                                  ? "#324A8C"
                                  : "#3C7770",
                              },
                            ]}
                          >
                            <H4
                              props={val.GroupName}
                              style={{
                                color: colors.white,
                                textAlign: "center",
                              }}
                            />
                          </View>
                          <View style={{ marginTop: 10 }}>
                            {val.Teams &&
                              val.Teams.length > 0 &&
                              val.Teams.map((ele, key1) => {
                                return (
                                  <View
                                    style={[
                                      styles.groupItemOddRow,
                                      {
                                        backgroundColor:
                                          key1 % 2 === 0
                                            ? colors.royalBlue
                                            : colors.newBlue,
                                      },
                                    ]}
                                  >
                                    <Image
                                      source={ele.Logo ? ele.Logo : defaultLogo}
                                      style={styles.groupItemTeamLogo}
                                      resizeMode="contain"
                                    />
                                    {/* <ZoomableImage imageSource={undefined} imageStyle={undefined} modalStyle={undefined} /> */}
                                    <H5
                                      props={ele.TeamName}
                                      style={{ flex: 1, color: colors.white }}
                                    />
                                    {MatchListData.length == 0 ? (
                                      <Pressable
                                        style={{
                                          width: 40,
                                          alignItems: "center",
                                        }}
                                        onPress={() => {
                                          handleGroupTeamShuffle(val, ele);
                                        }}
                                        // onPress={handleGroupTeamShuffle()}
                                      >
                                        <Icon
                                          name="shuffle"
                                          size={22}
                                          color={colors.aqua}
                                        />
                                      </Pressable>
                                    ) : null}
                                  </View>
                                );
                              })}
                          </View>
                          {FixtureCreateFlag !== false ? (
                            <View
                              style={{
                                flexDirection: "row",
                                gap: 5,
                                padding: 10,
                                alignItems: "center",
                              }}
                            >
                              <Pressable
                                onPress={() => {
                                  handleShowAddTeamModal(val);
                                }}
                                style={{
                                  flexDirection: "row",
                                  gap: 8,
                                  alignItems: "center",
                                }}
                              >
                                <AddIcon
                                  name="pluscircleo"
                                  size={22}
                                  color={colors.aqua}
                                />
                                <Text
                                  style={{
                                    color: colors.aqua,
                                    fontFamily: "PlusJakartaSansMedium",
                                  }}
                                >
                                  Add Team
                                </Text>
                              </Pressable>
                            </View>
                          ) : null}
                        </View>
                      );
                    })}
                </View>
              ) : null}
              <View
                style={{
                  marginTop: 20,
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 20,
                }}
              >
                {(Groupflag == true && FixtureCreateFlag == false) ||
                (checkedGroup == "no" && FixtureCreateFlag == false) ||
                grouplistNewTeamAdd.length > 0 ? (
                  <Pressable
                    style={{
                      backgroundColor: colors.aqua,
                      paddingVertical: 5,
                      paddingHorizontal: 10,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: 250,
                      borderRadius: 5,
                    }}
                    onPress={handleFixtureCreated}
                  >
                    <Text style={{ color: colors.blue }}>
                      Save Group Create Fixture
                    </Text>
                  </Pressable>
                ) : null}
              </View>
            </View>
            {/* group container ended */}
            {/* search bar added */}
            {/* <View>
              <SearchBar
                placeholder="Search by team name"
                onSearch={handleSearch}
                style={styles.searchBar}
              />
            </View> */}

            {/* button for modal start */}
            <View style={{ marginTop: 30, alignItems: "flex-end" }}>
              <Pressable
                onPress={() => {
                  handleAddMatch();
                }}
                style={styles.addTeamBtn}
              >
                <AddIcon2 name="plus-circle" size={18} color={colors.white} />
                <Text
                  style={{
                    color: colors.white,
                    fontSize: 14,
                    fontFamily: "PlusJakartaSansRegular",
                  }}
                >
                  Add Match
                </Text>
              </Pressable>
            </View>
            {/* button for modal end */}

            {/* match table started */}
            {Platform.OS == "web" && screenWidth > 760 ? (
              <View style={{ marginTop: 20 }}>
                {/* table header started */}

                {MatchListData && MatchListData.length > 0 ? (
                  <View style={styles.tableHeaderContainer}>
                    <View style={[styles.tableHeaderTitleSN]}>
                      <Text style={styles.tableHeaderTitleText}>M.No.</Text>
                    </View>
                    <View style={[styles.tableHeaderTitleSN]}>
                      <Text style={styles.tableHeaderTitleText}>Group</Text>
                    </View>
                    <View style={[styles.tableHeaderTitle, { flex: 1 }]}>
                      <Text style={styles.tableHeaderTitleText}>Team 1</Text>
                    </View>
                    <View style={[styles.tableHeaderTitle, { flex: 1 }]}>
                      <Text style={styles.tableHeaderTitleText}>Team 2</Text>
                    </View>
                    <View style={[styles.tableHeaderTitle, { width: 125 }]}>
                      <Text style={styles.tableHeaderTitleText}>Date</Text>
                    </View>
                    <View style={[styles.tableHeaderTitle, { width: 120 }]}>
                      <Text style={styles.tableHeaderTitleText}>Time</Text>
                    </View>
                    <View style={[styles.tableHeaderTitle, { width: 160 }]}>
                      <Text style={styles.tableHeaderTitleText}>Venue</Text>
                    </View>
                    <View style={[styles.tableHeaderTitle, { width: 110 }]}>
                      <Text style={styles.tableHeaderTitleText}>Status</Text>
                    </View>
                    <View style={[styles.tableHeaderActionTitle]}>
                      <Text style={styles.tableHeaderTitleText}>Action</Text>
                    </View>
                  </View>
                ) : null}
                {MatchListData &&
                  MatchListData.length > 0 &&
                  MatchListData.map((obj, index) => {
                    return (
                      <View
                        style={[
                          styles.tableDataContainer,
                          {
                            backgroundColor:
                              index % 2 === 0
                                ? colors.royalBlue
                                : colors.newBlue,
                          },
                        ]}
                      >
                        <View style={styles.tableDataCellSN}>
                          <Text style={styles.tableDataCellText}>
                            {obj.MatchNo}
                          </Text>
                        </View>
                        <View style={styles.tableDataCellSN}>
                          <Text style={styles.tableDataCellText}>
                            {obj.GroupName}
                          </Text>
                        </View>
                        <View
                          style={[
                            styles.tableDataCell,
                            {
                              justifyContent: "space-between",
                              flex: 1,
                            },
                          ]}
                        >
                          <Text
                            style={[styles.tableDataCellText, { flex: 1 }]}
                            numberOfLines={2}
                          >
                            {obj.Team1.TeamName}
                          </Text>
                          {obj.Team1.TeamSubmitted == false &&
                          !obj.Team1.TeamName.includes("Winner") &&
                          !obj.Team1.TeamName.includes("BYE") &&
                          !obj.Team2.TeamName.includes("BYE") ? (
                            <Pressable
                              onPress={() => handleOpen(obj.Team1)}
                              style={{ width: 25 }}
                            >
                              <Icon2
                                name="plussquareo"
                                size={22}
                                color={colors.white}
                              />
                            </Pressable>
                          ) : null}
                        </View>
                        <View
                          style={[
                            styles.tableDataCell,
                            {
                              justifyContent: "space-between",
                              flex: 1,
                            },
                          ]}
                        >
                          <Text
                            style={[styles.tableDataCellText, { flex: 1 }]}
                            numberOfLines={2}
                          >
                            {obj.Team2.TeamName}
                          </Text>
                          {obj.Team2.TeamSubmitted == false &&
                          !obj.Team2.TeamName.includes("Winner") &&
                          !obj.Team1.TeamName.includes("BYE") &&
                          !obj.Team2.TeamName.includes("BYE") ? (
                            <Pressable
                              onPress={() => handleOpen(obj.Team2)}
                              style={{ width: 25 }}
                            >
                              <Icon2
                                name="plussquareo"
                                size={22}
                                color={colors.white}
                              />
                            </Pressable>
                          ) : null}
                        </View>
                        <View style={[styles.tableDataCell, { width: 125 }]}>
                          {MatchDateTimeUpdateflag == false ||
                          editIndex !== index ? (
                            <Text style={styles.tableDataCellText}>
                              {obj.Date !== "" && obj.Date !== null
                                ? obj.Date
                                : "Not Scheduled"}
                            </Text>
                          ) : null}
                          {MatchDateTimeUpdateflag == true &&
                          editIndex == index &&
                          Platform.OS == "web" ? (
                            <CustomDatePicker
                              selected={
                                obj.Date
                                // selectedDate !== "" ? selectedDate : obj.Date
                              }
                              onChange={(e) => {
                                handleDateChange(e);
                              }}
                              // onChange={}
                              containerStyle={{
                                height: 45,
                                width: 140,
                                backgroundColor: colors.bgColor,
                              }}
                              portalId={undefined}
                            />
                          ) : null}
                        </View>
                        <View style={[styles.tableDataCell, { width: 120 }]}>
                          {MatchDateTimeUpdateflag == false ||
                          editIndex !== index ? (
                            <Text style={styles.tableDataCellText}>
                              {obj.Time !== "" && obj.Time !== null
                                ? obj.Time
                                : "Not Scheduled"}
                            </Text>
                          ) : null}
                          {MatchDateTimeUpdateflag == true &&
                          editIndex == index &&
                          Platform.OS == "web" ? (
                            <CustomTimePicker
                              selected={SelectedTImeRecord}
                              onChange={(e) => handleSelectedTime(e)}
                            />
                          ) : null}
                        </View>
                        <View style={[styles.tableDataCell, { width: 160 }]}>
                          {MatchDateTimeUpdateflag == true &&
                          editIndex == index &&
                          venueList &&
                          venueList.length > 0 ? (
                            <Picker
                              style={styles.dropdownContainer1}
                              selectedValue={defaultVenue}
                              onValueChange={(itemValue, itemIndex) =>
                                handleVenuechange(itemValue, itemIndex - 1)
                              }
                            >
                              <Picker.Item
                                label={"Select Venue"}
                                value={"Select Venue"}
                              />
                              {venueList &&
                                venueList.length > 0 &&
                                venueList.map((ele) => {
                                  return (
                                    <>
                                      <Picker.Item
                                        label={ele.Name}
                                        value={ele.Name}
                                        // onChange={(e) =>
                                        //   handleVenuechange(e, ele)
                                        // }
                                      />
                                    </>
                                  );
                                })}
                            </Picker>
                          ) : (
                            <Text style={styles.tableDataCellText}>
                              {obj.Venue?.Name}
                            </Text>
                          )}
                        </View>
                        <View style={[styles.tableDataCell, { width: 110 }]}>
                          <Text style={styles.tableDataCellText}>
                            {obj.Status}
                          </Text>
                        </View>

                        <View style={styles.tableDataBtnCell}>
                          {(obj.Status !== "COMPLETED" &&
                            MatchDateTimeUpdateflag == false) ||
                          (obj.Status !== "COMPLETED" &&
                            editIndex !== index) ? (
                            <Pressable
                              onPress={() => {
                                handleTimeDateUpdate(index, obj);
                              }}
                            >
                              <Image
                                source={require("../../../../assets/Admin/tournament/edit.svg")}
                                style={{ width: 25, height: 25 }}
                                resizeMode="contain"
                              />
                            </Pressable>
                          ) : null}
                          <Pressable
                            style={styles.fixtureDelBtn}
                            // onPress={() => {
                            //   handleMatchRemove(obj, index);
                            // }}
                            onPress={() => {
                              handleDeleteMatch(obj);
                            }}
                          >
                            {/* <Image
                                source={require("../../../../assets/Admin/tournament/check.svg")}
                                style={{ width: 25, height: 25 }}
                                resizeMode="contain"
                              /> */}
                            <DeleteIcon
                              name="trash"
                              size={18}
                              color={colors.red}
                            />
                          </Pressable>
                          {obj.Status !== "COMPLETED" &&
                          MatchDateTimeUpdateflag == true &&
                          editIndex == index ? (
                            <Pressable
                              onPress={() => {
                                handleSubmitDateTime(obj);
                              }}
                            >
                              <Image
                                source={require("../../../../assets/Admin/tournament/check.svg")}
                                style={{ width: 25, height: 25 }}
                                resizeMode="contain"
                              />
                            </Pressable>
                          ) : null}
                          {/* <Pressable>
                    <Image
                      source={require("../../../../assets/Admin/tournament/reset.svg")}
                      style={{ width: 25, height: 25 }}
                      resizeMode="contain"
                    />
                  </Pressable> */}

                          {obj.Status !== "COMPLETED" ? (
                            <Pressable
                              onPress={() => {
                                handleLiveURL(obj);
                              }}
                            >
                              <Image
                                source={require("../../../../assets/Admin/tournament/live.svg")}
                                style={{ width: 25, height: 25 }}
                                resizeMode="contain"
                              />
                            </Pressable>
                          ) : null}
                          {
                            // obj.Status !== "COMPLETED" &&
                            obj.Team1.TeamSubmitted == true &&
                            obj.Team2.TeamSubmitted == true ? (
                              <Pressable
                                onPress={() => handleRedirectStartMatch(obj.id)}
                                // onPress={toggleModal}
                              >
                                <Image
                                  source={require("../../../../assets/Admin/tournament/score.svg")}
                                  style={{ width: 25, height: 25 }}
                                  resizeMode="contain"
                                />
                              </Pressable>
                            ) : null
                          }
                          {/* eye button */}
                          {obj.Status == "COMPLETED" ? (
                            <Pressable
                              // onPress={() => handleRedirectStartMatch(obj.id)}
                              // onPress={toggleModal}
                              onPress={() => {
                                handleViewTeamModal(obj);
                              }}
                            >
                              <Image
                                source={require("../../../../assets/Admin/tournament/view.svg")}
                                style={{ width: 25, height: 25 }}
                                resizeMode="contain"
                              />
                            </Pressable>
                          ) : null}
                        </View>
                      </View>
                    );
                  })}
              </View>
            ) : (
              <View
                style={{
                  paddingBottom: Platform.OS !== "web" ? 100 : undefined,
                }}
              >
                {MatchListData &&
                  MatchListData.length > 0 &&
                  MatchListData.map((obj, index) => {
                    return (
                      <View style={[styles.matchCard, { marginBottom: 10 }]}>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <View style={{ flexDirection: "row" }}>
                            <Text
                              style={{
                                fontFamily: "PlusJakartaSansExtraLight",
                                color: colors.white,
                              }}
                            >
                              Match No.- {obj.MatchNo}
                            </Text>
                            {/* <Text> {obj.MatchNo}</Text> */}
                          </View>

                          <View style={{ flexDirection: "row" }}>
                            <Text
                              style={{
                                fontFamily: "PlusJakartaSansExtraLight",
                                color: colors.white,
                              }}
                            >
                              Status:- {obj.Status}
                            </Text>
                            {/* <Text> {obj.Status}</Text> */}
                          </View>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "space-around",
                            alignItems: "center",
                          }}
                        >
                          <View style={{ flexDirection: "column" }}>
                            <View style={styles.logoContainer}>
                              <Image
                                // source={require("../../../../assets/logo/match_logo1.png")}
                                source={
                                  obj.Team1.LogoURL
                                    ? obj.Team1.LogoURL
                                    : defaultLogo
                                }
                                style={styles.logo}
                              />
                            </View>
                            <View style={{ flexDirection: "row" }}>
                              <Text style={styles.tableDataCellText}>
                                {obj.Team1.TeamName}
                              </Text>
                              {obj.Team1.TeamSubmitted == false &&
                              !obj.Team1.TeamName.includes("Winner") ? (
                                <Pressable
                                  onPress={() => handleOpen(obj.Team1)}
                                >
                                  <Icon2
                                    name="plussquareo"
                                    size={22}
                                    color={colors.white}
                                  />
                                </Pressable>
                              ) : null}
                            </View>
                          </View>

                          <Text style={styles.tableDataCellText}>
                            {obj.GroupName}
                          </Text>

                          <View style={{ flexDirection: "column" }}>
                            <View style={styles.logoContainer}>
                              <Image
                                // source={require("../../../../assets/logo/match_logo1.png")}
                                source={
                                  obj.Team2.LogoURL
                                    ? obj.Team2.LogoURL
                                    : defaultLogo
                                }
                                style={styles.logo}
                              />
                            </View>
                            <View style={{ flexDirection: "row" }}>
                              <Text style={styles.tableDataCellText}>
                                {obj.Team2.TeamName}
                              </Text>
                              {obj.Team2.TeamSubmitted == false &&
                              !obj.Team2.TeamName.includes("Winner") ? (
                                <Pressable
                                  onPress={() => handleOpen(obj.Team2)}
                                >
                                  <Icon2
                                    name="plussquareo"
                                    size={22}
                                    color={colors.white}
                                  />
                                </Pressable>
                              ) : null}
                            </View>
                          </View>
                        </View>

                        <View
                          style={{
                            paddingTop: 5,
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          {MatchDateTimeUpdateflag == false ||
                          editIndex !== index ? (
                            <Text style={styles.fixtureMobCardText}>
                              {obj.Date !== "" && obj.Date !== null
                                ? obj.Date
                                : "Not Scheduled"}
                            </Text>
                          ) : null}
                          {MatchDateTimeUpdateflag &&
                          editIndex === index &&
                          Platform.OS === "web" ? (
                            <CustomDatePicker
                              selected={
                                obj.Date
                                // selectedDate !== "" ? selectedDate : obj.Date
                              }
                              onChange={setSelectedDate}
                              containerStyle={{
                                height: 45,
                                width: 140,
                                backgroundColor: colors.bgColor,
                              }}
                              portalId={undefined}
                            />
                          ) : null}
                          {MatchDateTimeUpdateflag == false ||
                          editIndex !== index ? (
                            <Text style={styles.fixtureMobCardText}>
                              {obj.Time !== "" && obj.Time !== null
                                ? obj.Time
                                : "Not Scheduled"}
                            </Text>
                          ) : null}
                          {MatchDateTimeUpdateflag == true &&
                          editIndex == index &&
                          Platform.OS === "web" ? (
                            <CustomTimePicker
                              selected={
                                Object.keys(SelectedTImeRecord).length !== 0
                                  ? `${SelectedTImeRecord.hours}:${SelectedTImeRecord.minutes}`
                                  : obj.Time
                              }
                              onChange={(e) => handleSelectedTime(e)}
                            />
                          ) : null}
                          <Text style={styles.fixtureMobCardText}>Venue</Text>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "space-between",
                            backgroundColor: colors.royalBlue,
                            padding: 8,
                            // borderWidth: 1,
                            borderRadius: 5,
                          }}
                        >
                          {MatchDateTimeUpdateflag == false ||
                          editIndex !== index ? (
                            <Pressable
                              style={styles.fixtureEditBtn}
                              onPress={() => {
                                handleTimeDateUpdate(index, obj);
                              }}
                            >
                              <EditIcon
                                name="edit"
                                size={20}
                                color={colors.white}
                              />
                            </Pressable>
                          ) : null}
                          {MatchDateTimeUpdateflag == true &&
                          editIndex == index ? (
                            <Pressable
                              style={styles.fixtureSaveBtn}
                              onPress={() => {
                                handleSubmitDateTime(obj);
                              }}
                            >
                              <Checkmark
                                name="check-square"
                                size={20}
                                color={colors.white}
                              />
                            </Pressable>
                          ) : null}
                          <Pressable
                            style={styles.fixtureLiveBtn}
                            onPress={() => {
                              handleLiveURL(obj);
                            }}
                          >
                            <Youtube
                              name="youtube"
                              size={20}
                              color={colors.red}
                            />
                          </Pressable>

                          <Pressable
                            style={styles.fixtureScoreBtn}
                            onPress={() => handleRedirectStartMatch(obj.id)}
                          >
                            <Scoreboard
                              name="scoreboard"
                              size={20}
                              color={colors.white}
                            />
                          </Pressable>

                          <Pressable
                            style={styles.fixtureDelBtn}
                            onPress={() => {
                              handleDeleteMatch(obj);
                            }}
                          >
                            <DeleteIcon
                              name="trash"
                              size={18}
                              color={colors.red}
                            />
                          </Pressable>
                        </View>
                      </View>
                    );
                  })}
              </View>
            )}
          </View>
        </View>
      ) : (
        <View style={{ alignItems: "center", width: "100%" }}>
          <Image
            source={require("../../../../assets/noData/teams.png")}
            style={styles.noDataImage}
            resizeMode="contain"
          />
          <Text style={styles.noDataText}>
            There is no team available to create the fixture.
          </Text>
        </View>
      )}

      <Modal
        animationType="slide"
        transparent={true}
        visible={PopModalVisible}
        // onRequestClose={toggleModal}
      >
        {/* <Pressable onPress={closeModal}>
            <View style={styles.modalContainer}>
          <StartMatch  />
            </View>
          </Pressable> */}
      </Modal>

      {visibleAddTeamModal == true ? (
        <AddTeamInGroup
          visiblemodal={visibleAddTeamModal}
          handleModalClose={handleModalClose}
          GroupandTeamdata={Grouplist}
          AllTeamlist={TeamList}
          Groupname={GroupName}
          handleNewTeamInGroup={handleNewTeamInGroup}
          Tabactive={activeTab}
        />
      ) : null}
      {modalVisible == true ? (
        <AddPlayerModal
          SelectedTeam={SelectedTeamObj}
          TeamDetails={SelectedTeamPlayerList}
          Tournamentdata={TournamentData}
          visibleModal={modalVisible}
          Roundobj={Roundobj}
          MatchTeamMasterPlayerUpdate={MatchTeamMasterPlayerUpdate}
          handleClose={handleClose}
          handleFetchData={undefined}
        />
      ) : null}
      {SuccessModalVisible == true ? (
        <SuccessAutoExitModal
          visibleModal={SuccessModalVisible}
          SuccessMessage={SunccessModalMesg}
          handleOk={handleOk}
        />
      ) : // <SuccessModal
      //   visibleModal={SuccessModalVisible}
      //   handleClose={undefined}
      //   SuccessMessage={SunccessModalMesg}
      //   handleConfirm={undefined}
      //   handleOk={handleOk}
      // />
      null}
      {ShuffleTeamVisible == true ? (
        <ShuffleTeamModal
          handleSuffleTeamCallBack={handleSuffleTeamCallBack}
          Groupname={GroupName}
          SelectedTeamObj={SelectedTeamObjFromGroup}
          GroupandTeamdata={Grouplist}
          visibleModal2={ShuffleTeamVisible}
          handleClose2={handleCloseSuccess}
        />
      ) : null}

      {LiveURLVisible == true ? (
        <LiveURL
          visibleModal3={LiveURLVisible}
          MatchObj={SelectedmatchobjforLiveURl}
          Roundobj={Roundobj}
          MatchTeamMasterPlayerUpdate={MatchTeamMasterPlayerUpdate}
          handleClose3={handleCloseSuccess3}
        />
      ) : null}
      {/* modal ended */}
      {viewTeamModalVisible == true ? (
        <ViewMatchDetails
          visibleModal={viewTeamModalVisible}
          ViewTeamClose={ViewTeamClose}
          MatchID={SelectedmatchId}
        />
      ) : null}
      {errorModalVisible == true ? (
        <ErrorModal
          visibleModal={errorModalVisible}
          SuccessMessage={errorModalMesg}
          onSubmit={handleSubmit}
          header={"Attention"}
        />
      ) : null}
      {loading && (
        <Modal transparent animationType="none">
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <ActivityIndicator size="large" color="#0000ff" />
            <Text>Submitting</Text>
          </View>
        </Modal>
      )}
      {addMatchModalVisible && (
        <AddMatchModal
          handleCancel={handleCancel}
          RoundData={RoundObject}
          MatchCount={MatchListData.length}
          Gorupdata={Grouplist}
          Teamlist={TeamList}
          Tournamentdata={TournamentData}
          visibleModal={addMatchModalVisible}
          Roundtype={activeTab}
          getTournament={getTournament}
        />
      )}
      {comfirmDeleteModalVisible && (
        <AreYouSureOrange
          visibleModal={comfirmDeleteModalVisible}
          Message={comfirmDeletModalMesg}
          ButtonText={"Delete"}
          onCancel={handleCancelMatch}
          onConfirm={handleConfirmMatch}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  tabContainer: {
    flexDirection: "row",
    gap: Platform.OS === "web" && screenWidth > 760 ? 20 : 10,
    overflow: screenWidth > 760 ? undefined : "scroll",
  },
  tabButton: {
    padding: 10,
    backgroundColor: colors.royalBlue,
    opacity: 0.6,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    width: Platform.OS === "web" && screenWidth > 760 ? 180 : undefined,
  },
  activeTabButton: {
    backgroundColor: colors.royalBlue,
    opacity: 1,
    borderBottomColor: colors.aqua,
    borderBottomWidth: 0.2,
  },
  dropdownLabelContainer: {
    flexDirection: "column",
    padding: 12,
  },

  dropDownContainer: {
    width: 190,
    color: colors.white,
  },
  dropdownLabel: {
    paddingBottom: 9,
    // fontWeight: "bold",
    fontFamily: "PlusJakartaSansRegular",
    color: colors.white,
  },
  tabButtonText: {
    color: colors.white,
    textAlign: "center",
    fontFamily: "PlusJakartaSansRegular",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 16 : 14,
  },

  tabContent: {
    alignItems: "center",
  },
  //
  tabContentWrapper: {
    width: "100%",
    backgroundColor: colors.royalBlue,
    padding: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 1,
      height: 8,
    },
    shadowOpacity: 0.29,
    shadowRadius: 6,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  input: {
    width: 100,
    height: 30,
    borderColor: colors.white,
    color: colors.white,
    borderWidth: 1,
    borderRadius: 5,
    paddingLeft: 10,
  },
  groupItemWrapper: {
    flex: 1,
    maxWidth: 300,
    minWidth: 200,
    backgroundColor: colors.royalBlue,
    borderRadius: 5,
    shadowColor: "#00000066",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 10,
  },
  groupItemHeading: {
    backgroundColor: colors.blue,
    height: 30,
    width: "90%",
    borderRadius: 7,
    justifyContent: "center",
    marginHorizontal: "auto",
    marginTop: -9,
  },
  groupItemOddRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 7,
    backgroundColor: "#F3F3F3",
    padding: 4,
    borderRadius: 5,
  },
  groupItemEvenRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 7,
    backgroundColor: colors.white,
    padding: 4,
    borderRadius: 5,
  },
  groupItemTeamLogo: {
    width: 30,
    height: 30,
    borderRadius: 30,
  },
  // match table started
  tableHeaderContainer: {
    backgroundColor: colors.blue,
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
  },
  tableHeaderTitle: {
    paddingVertical: 7,
  },
  tableHeaderTitleSN: {
    width: 70,
    paddingVertical: 7,
  },
  tableHeaderTitleText: {
    fontFamily: "PlusJakartaSansBold",
    fontSize: 14,
    color: colors.white,
    textAlign: "center",
  },
  tableHeaderActionTitle: {
    // flex: 1.3,
    width: 140,
    paddingVertical: 7,
  },
  fixtureDelBtn: {
    backgroundColor: colors.white,
    width: 25,
    height: 25,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },

  fixtureLiveBtn: {
    backgroundColor: colors.white,
    width: 25,
    height: 25,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },

  fixtureEditBtn: {
    backgroundColor: "#FFDE2E",
    width: 25,
    height: 25,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },

  fixtureSaveBtn: {
    backgroundColor: "#4FBA34",
    width: 25,
    height: 25,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },

  fixtureScoreBtn: {
    backgroundColor: "#002DA8",

    width: 25,
    height: 25,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },

  //
  tableDataContainer: {
    backgroundColor: colors.white,
    display: "flex",
    flexDirection: "row",
    borderRadius: 10,
    shadowColor: "#00000021",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    elevation: 5,
    shadowRadius: 10,
    paddingVertical: 4,
  },
  tableDataCell: {
    paddingVertical: 5,
    paddingHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    gap: 3,
  },
  tableDataCellSN: {
    width: 70,
    paddingVertical: 5,
    paddingHorizontal: 5,
    justifyContent: "center",
  },
  tableDataCellLogo: {
    width: 80,
    paddingVertical: 5,
    paddingHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  tableDataCellText: {
    width: Platform.OS === "web" && screenWidth > 760 ? null : 90,
    textAlign: "center",
    fontFamily: "PlusJakartaSansExtraLight",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 14 : 14,
    // fontWeight: Platform.OS === "web" && screenWidth > 760 ? null : "bold",
    color: colors.white,
  },
  tableDataBtnCell: {
    width: 140,
    paddingVertical: 5,
    paddingHorizontal: 5,
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
    gap: 10,
    flexWrap: "wrap",
  },
  tableButton: {
    borderColor: colors.darkGray,
    borderWidth: 1,
    paddingVertical: 3,
    paddingHorizontal: 10,
    borderRadius: 4,
    display: "flex",
    gap: 5,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  tableButtonText: {
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 14,
    color: colors.darkGray,
  },
  matchCard: {
    flex: 1,
    backgroundColor: colors.newBlue,
    padding: Platform.OS === "web" && screenWidth > 760 ? 20 : 10,
    borderRadius: 5,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    display: "flex",
    flexDirection:
      Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
    alignItems: "center",
    justifyContent:
      Platform.OS === "web" && screenWidth > 760 ? "center" : "space-between",
    gap: Platform.OS === "web" && screenWidth > 760 ? 20 : 5,
  },
  logoContainer: {
    width: Platform.OS === "web" && screenWidth > 760 ? 150 : 80,
    height: Platform.OS === "web" && screenWidth > 760 ? 60 : 60,
    paddingVertical: Platform.OS === "web" && screenWidth > 760 ? 5 : 0,
    borderRadius: 100,
  },
  logo: {
    width: "100%",
    height: "100%",
    borderRadius: 100,
    resizeMode: "contain",
  },
  searchBar: {
    width: "100%",
    maxWidth: 300,
    // outlineColor: "transparent",
    // outlineWidth: 0,
    borderColor: colors.grayColour,
    backgroundColor: colors.bgColor,
  },
  noDataImage: {
    width: 270,
    height: 270,
  },
  dropdownContainer1: {
    width: "100%",
    marginBottom: 0,
    height: 34,
    borderRadius: 5,
    backgroundColor: colors.royalBlue,
    color: colors.white,
  },
  noDataText: {
    fontFamily: "PlusJakartaSansRegular",
    color: colors.white,
    fontSize: 20,
    maxWidth: 400,
    textAlign: "center",
  },
  teamsDropdownContainer: {
    width: screenWidth > 760 ? 220 : "100%",
    marginBottom: 0,
    height: 34,
    borderRadius: 5,
    backgroundColor: colors.royalBlue,
    color: colors.white,
  },
  fixtureMobCardText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 12,
  },
  addTeamBtn: {
    flexDirection: "row",
    gap: 5,
    backgroundColor: colors.orangeColour,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    minWidth: 100,
    padding: 5,
    paddingHorizontal: 12,
  },
});

export default Fixture;
