import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  Pressable,
  Platform,
  ScrollView,
  Dimensions,
  Linking,
} from "react-native";
import { CommonHeading } from "../../../../components/reusable/TextComponent";
import Graph from "react-native-vector-icons/Entypo";
import Play from "react-native-vector-icons/Entypo";
import { DataStore } from "aws-amplify/datastore";
import { MatchMaster } from "../../../../models";
import {
  convertTo12HourFormat,
  formatDate,
  getCurrentDate,
} from "../../../../components/FormattedDate";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Layout from "../../../../components/include/Layout";
import SearchBar from "../../../../components/reusable/SearchBar";
import { colors } from "../../../../components/reusable/colour";
import { LinearGradient } from "expo-linear-gradient";
import { ErrorModal } from "../../../../components/reusable/AreYouSure";
// import AllMatches from "./allMatches";
const screenWidth = Dimensions.get("window").width;

const Matches = () => {
  const [filteredCompletedMatches, setFilteredCompletedMatches] = useState([]);
  const [filteredLiveMatches, setFilteredLiveMatches] = useState([]);
  const [filteredUpcomingMatches, setFilteredUpcomingMatches] = useState([]);
  const [tournamentData, setTournamentData] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [completedMatchesFlag, setCompletedMatchesFlag] = useState(false);
  const [liveMatchesFlag, setLiveMatchesFlag] = useState(false);
  const [upcomingMatchesFlag, setUpcomingMatchesFlag] = useState(false);
  const [selectedMatchId, setSelectedMatchId] = useState("");
  const [activeBadge, setActiveBadge] = useState("All");
  const [activeTab, setActiveTab] = useState("All");
  const [filteredData, setFilteredData] = useState([]); // Initialize with the API data
  const [search, setSearch] = useState("");
  const [searchFlag, setSearchFlag] = useState(false);
  const [allMatches, setAllMatches] = useState([]);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorModalMesg, setErrorModalMesg] = useState("");
  const navigation: any = useNavigation();
  const currentDate = getCurrentDate();
  console.log("currentDate", currentDate);

  // const handleSelectMatch = (matchId: any) => {
  //   if (matchId) {
  //     console.log("matchId", matchId);
  //     setSelectedMatchId(matchId);
  //     // If the same match is clicked again, close the details
  //     setShowDetails(true);
  //   } else {
  //     // Otherwise, open details for the selected match
  //     setShowDetails(false);
  //   }
  // };

  const handleMatchesResultDetailsNavigation = async (
    tournamentId: any,
    matchId: any
  ) => {
    navigation.navigate("TournamentDetails", {
      tournamentId: tournamentId,
      matchId: matchId,
      initialTab: 2,
      showResultDetailsFromMatches: true,
      showResultPage: false,
      matchNavigation: true,
    });
    const matchesPropsObject = {
      tournamentId: tournamentId,
      matchId: matchId,
      initialTab: 2,
      showResultDetailsFromMatches: true,
      showResultPage: false,
      matchNavigation: true,
    };
    console.log("matchesPropsObject", matchesPropsObject);

    await AsyncStorage.setItem(
      "matchesResultDetails",
      JSON.stringify(matchesPropsObject)
    );
    if (matchId) {
      console.log("matchId", matchId);
      setSelectedMatchId(matchId);
      // If the same match is clicked again, close the details
      setShowDetails(true);
    } else {
      // Otherwise, open details for the selected match
      setShowDetails(false);
    }
  };

  const handleSubmit = () => {
    setErrorModalVisible(false);
  };

  const handleClickOnWatchMatch = async (LiveURL: string) => {
    if (LiveURL) {
      await Linking.openURL(LiveURL);
    } else {
      setErrorModalVisible(true);
      setErrorModalMesg("No Live URL is provided ");
      // alert("No Live URL is provided ");
    }
  };

  useEffect(() => {
    const subscription = DataStore.observeQuery(MatchMaster).subscribe(
      async (snapshot) => {
        const initialMatches = snapshot.items;
        console.log("initialMatches", initialMatches);

        // create match object with team1 and  team2  data
        const matchesData = await Promise.all(
          initialMatches.map(async (element: any) => ({
            ...element,
            team1: await element.Team1,
            team2: await element.Team2,
            tournament: await element.tournamentData,
          }))
        );

        console.log("matchesData", matchesData);

        // Completed matches
        if (matchesData && matchesData.length > 0) {
          const filteredCompletedMatches = matchesData
            ? matchesData.filter(
                (matchData: any) =>
                  matchData.Date === currentDate &&
                  matchData.Status === "COMPLETED"
              )
            : [];
          console.log("filteredCompletedMatches", filteredCompletedMatches);
          setFilteredCompletedMatches(filteredCompletedMatches);
          if (filteredCompletedMatches.length > 0) {
            console.log(
              "filteredCompletedMatches",
              filteredCompletedMatches.length
            );
            setCompletedMatchesFlag(true);
          } else {
            setCompletedMatchesFlag(false);
          }
        } else {
          console.error("matchesObjData is undefined or empty");
        }

        // Live matches or InProgress matches
        if (matchesData && matchesData.length > 0) {
          const filteredLiveMatches = matchesData
            ? matchesData.filter(
                (matchData: any) =>
                  matchData.Date === currentDate &&
                  matchData.Status === "INPROGRESS"
              )
            : [];
          console.log("filteredLiveMatches", filteredLiveMatches);
          setFilteredLiveMatches(filteredLiveMatches);
          if (filteredLiveMatches.length > 0) {
            console.log("filteredLiveMatches", filteredLiveMatches.length);
            setLiveMatchesFlag(true);
          } else {
            setLiveMatchesFlag(false);
          }
        } else {
          console.error("matchesObjData is undefined or empty");
        }

        // Upcoming or SCHEDULED matches
        if (matchesData && matchesData.length > 0) {
          const filteredUpcomingMatches = matchesData
            ? matchesData.filter(
                (matchData: any) =>
                  matchData.Date === currentDate &&
                  matchData.Status === "SCHEDULED"
              )
            : [];
          console.log("filteredUpcomingMatches", filteredUpcomingMatches);
          setFilteredUpcomingMatches(filteredUpcomingMatches);
          if (filteredUpcomingMatches.length > 0) {
            console.log(
              "filteredUpcomingMatches",
              filteredUpcomingMatches.length
            );
            setUpcomingMatchesFlag(true);
          } else {
            setUpcomingMatchesFlag(false);
          }
        } else {
          console.error("matchesObjData is undefined or empty");
        }

        // tournament Data
        const tournamentDataPromise = initialMatches.map(
          async (data) => data.tournamentData
        );
        const tournamentData = await Promise.all(tournamentDataPromise);
        const flatTournamentData = tournamentData.flat();
        console.log("flatTournamentData", flatTournamentData);
        setTournamentData(flatTournamentData);

        // all matches filters
        if (activeBadge === "All") {
          setAllMatches(initialMatches);
        } else if (activeBadge === "Upcoming") {
          setAllMatches(
            initialMatches.filter(
              (tournament: any) => tournament.Status === "SCHEDULED"
            )
          );
        } else if (activeBadge === "Completed") {
          setAllMatches(
            initialMatches.filter(
              (tournament: any) => tournament.Status === "COMPLETED"
            )
          );
        } else if (activeBadge === "InProgress") {
          setAllMatches(
            initialMatches.filter(
              (tournament: any) => tournament.Status === "INPROGRESS"
            )
          );
        }
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <>
      {/* landing page matches */}

      {(Platform.OS === "web" &&
        window.location.pathname === "/" &&
        filteredCompletedMatches.length > 0) ||
      filteredLiveMatches.length > 0 ||
      filteredUpcomingMatches.length > 0 ? (
        <View style={styles.MatchesBG}>
          {completedMatchesFlag === true ||
          liveMatchesFlag === true ||
          upcomingMatchesFlag === true ? (
            <View style={styles.container}>
              <CommonHeading props={"Today's Matches"} />
              <ScrollView
                horizontal
                style={styles.homeMatchContainer}
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={true}
              >
                {/* <View style={styles.dashboardMatchContainer}> */}
                {/* Completed matches */}

                {/* Live card  item */}
                {
                  filteredLiveMatches && filteredLiveMatches.length > 0
                    ? filteredLiveMatches.map((match, matchIndex) => {
                        return (
                          <LinearGradient
                            style={styles.matchCardColor}
                            key={matchIndex}
                            colors={[colors.orange, colors.royalBlue]}
                            start={{ x: 0, y: 1 }}
                            end={{ x: 1, y: 1 }}
                          >
                            <View style={styles.liveBadge}>
                              <Text style={styles.liveBadgeText}>Live</Text>
                            </View>
                            {/* <View style={styles.matchCardColor} key={matchIndex}> */}
                            <Text
                              style={styles.matchCardHeadingWhite}
                              numberOfLines={1}
                            >
                              {/* tournament name is here */}
                              {match.tournament ? match.tournament.Name : null}
                            </Text>
                            <Text style={styles.matchCardVenueTextWhite}>
                              {match && match.Venue ? match.Venue.Name : null}
                            </Text>
                            <View style={styles.matchDetailsWrapper}>
                              <View style={styles.teamLogoNameWrapper}>
                                <Image
                                  source={{
                                    uri: match?.team1
                                      ? match?.team1?.LogoURL
                                      : null,
                                  }}
                                  style={styles.matchClubLogo}
                                />
                                {/* add css to TeamName */}
                                <Text
                                  style={[
                                    styles.teamNameText,
                                    { color: "#fff" },
                                  ]}
                                >
                                  {match.team1 ? match.team1.TeamName : null}
                                </Text>
                              </View>
                              <View style={styles.matchScoreBadge}>
                                <Text
                                  style={[
                                    styles.matchScoreTextWhite,
                                    { backgroundColor: "#02205F" },
                                  ]}
                                >
                                  {/* team Score */}
                                  {`${
                                    match.team1
                                      ? match.team1.Score
                                        ? match.team1.Score.FirstHalf +
                                          match.team1.Score.SecondHalf +
                                          match.team1.Score.ExtraTime
                                        : "0"
                                      : "0"
                                  }-${
                                    match.team2
                                      ? match.team2.Score
                                        ? match.team2.Score.FirstHalf +
                                          match.team2.Score.SecondHalf +
                                          match.team2.Score.ExtraTime
                                        : "0"
                                      : "0"
                                  }`}
                                </Text>
                              </View>
                              <View style={styles.teamLogoNameWrapper}>
                                <Image
                                  // source={require("../../../../../assets/logo/match_logo3.png")}
                                  source={{
                                    uri: match?.team2
                                      ? match?.team2?.LogoURL
                                      : null,
                                  }}
                                  style={styles.matchClubLogo}
                                />
                                {/* add css to TeamName */}
                                <Text
                                  style={[
                                    styles.teamNameText,
                                    { color: "#fff" },
                                  ]}
                                >
                                  {match.team2 ? match.team2.TeamName : null}
                                </Text>
                              </View>
                            </View>
                            <View style={styles.matchCardDateWrapper}>
                              <Text style={styles.matchCardDateTextWhite}>
                                {match && match.Date
                                  ? formatDate(match.Date)
                                  : null}
                              </Text>
                              <Text style={styles.matchCardDateTextWhite}>
                                {match && match.Time
                                  ? convertTo12HourFormat(match.Time)
                                  : null}
                              </Text>
                              {/* <Text style={styles.matchCardDateTextWhite}>
                                
                                {match && match.Venue ? match.Venue.Name : null}
                              </Text> */}
                            </View>
                            {/* <View style={styles.matchCardDateWrapper}>
                            <Text style={styles.matchCardDateText}>
                              {match && match.Date ? formatDate(match.Date) : null}
                            </Text>
                            <Text style={styles.matchCardDateText}>
                              {match && match.Time
                                ? convertTo12HourFormat(match.Time)
                                : null}
                            </Text>
                            <Text style={styles.matchCardDateText}>
                              {match && match.Venue ? match.Venue.Name : null}
                            </Text>
                          </View> */}
                            <View
                              style={[
                                styles.horizontalLine,
                                { marginVertical: 10 },
                              ]}
                            />
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Pressable
                                style={styles.matchCardRow}
                                onPress={() => {
                                  handleMatchesResultDetailsNavigation(
                                    match.tournament.id,
                                    match.id
                                  );
                                }}
                              >
                                <Graph
                                  name="bar-graph"
                                  size={16}
                                  color="#fff"
                                />
                                <Text style={styles.matchCardBtnText}>
                                  View Details
                                </Text>
                              </Pressable>
                              <Pressable
                                style={styles.matchCardRow}
                                onPress={() =>
                                  handleClickOnWatchMatch(match.LiveURL)
                                }
                              >
                                <Play
                                  name="controller-play"
                                  size={16}
                                  color="#fff"
                                />
                                <Text style={styles.matchCardBtnText}>
                                  Watch Match
                                </Text>
                              </Pressable>
                            </View>
                            {/* </View> */}
                          </LinearGradient>
                        );
                      })
                    : null
                  // <Text>No live matches today</Text>
                }

                {
                  filteredCompletedMatches &&
                  filteredCompletedMatches.length > 0
                    ? filteredCompletedMatches.map((match, matchIndex) => {
                        return (
                          <View style={styles.matchCard} key={matchIndex}>
                            <Text style={styles.matchCardHeading}>
                              {match.tournament ? match.tournament.Name : null}
                            </Text>
                            <Text style={styles.matchCardDateText}>
                              {match && match.Venue ? match.Venue.Name : null}
                            </Text>
                            <View style={styles.matchDetailsWrapper}>
                              <View style={styles.teamLogoNameWrapper}>
                                <Image
                                  // source={require("../../../../../assets/logo/match_logo7.png")}
                                  source={{
                                    uri: match?.team1
                                      ? match?.team1?.LogoURL
                                      : null,
                                  }}
                                  style={styles.matchClubLogo}
                                />
                                {/* add css to TeamName */}
                                <Text style={styles.teamNameText}>
                                  {match.team1 ? match.team1.TeamName : null}
                                </Text>
                              </View>
                              {/* <View style={styles.matchScoreBadge}>
                                <Text style={styles.matchCardVsText}>VS</Text>
                              </View> */}

                              <View style={styles.matchScoreBadge}>
                                <Text
                                  style={[
                                    styles.matchScoreTextWhite,
                                    { backgroundColor: "#02205F" },
                                  ]}
                                >
                                  {/* team Score */}
                                  {`${
                                    match.team1
                                      ? match.team1.Score
                                        ? match.team1.Score.FirstHalf +
                                          match.team1.Score.SecondHalf +
                                          match.team1.Score.ExtraTime
                                        : "0"
                                      : "0"
                                  }-${
                                    match.team2
                                      ? match.team2.Score
                                        ? match.team2.Score.FirstHalf +
                                          match.team2.Score.SecondHalf +
                                          match.team2.Score.ExtraTime
                                        : "0"
                                      : "0"
                                  }`}
                                </Text>
                              </View>

                              <View style={styles.teamLogoNameWrapper}>
                                <Image
                                  // source={require("../../../../../assets/logo/match_logo8.png")}
                                  source={{
                                    uri: match?.team2
                                      ? match?.team2?.LogoURL
                                      : null,
                                  }}
                                  style={styles.matchClubLogo}
                                />
                                {/* add css to TeamName */}
                                <Text style={styles.teamNameText}>
                                  {match.team2 ? match.team2.TeamName : null}
                                </Text>
                              </View>
                            </View>
                            <View style={styles.matchCardDateWrapper}>
                              <Text style={styles.matchCardDateText}>
                                {match && match.Date
                                  ? formatDate(match.Date)
                                  : null}
                              </Text>
                              <Text style={styles.matchCardDateText}>
                                {match && match.Time
                                  ? convertTo12HourFormat(match.Time)
                                  : null}
                              </Text>
                            </View>
                            <View
                              style={[
                                styles.horizontalLine,
                                { marginVertical: 10 },
                              ]}
                            />
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Pressable
                                style={styles.matchCardRow}
                                onPress={() => {
                                  handleMatchesResultDetailsNavigation(
                                    match.tournament.id,
                                    match.id
                                  );
                                }}
                              >
                                <Graph
                                  name="bar-graph"
                                  size={16}
                                  color={colors.white}
                                />
                                <Text style={styles.matchCardBtnTextColor}>
                                  View Details
                                </Text>
                              </Pressable>
                              <Pressable
                                style={styles.matchCardRow}
                                onPress={() =>
                                  handleClickOnWatchMatch(match.LiveURL)
                                }
                              >
                                <Play
                                  name="controller-play"
                                  size={16}
                                  color={colors.white}
                                />
                                <Text style={styles.matchCardBtnTextColor}>
                                  Watch Match
                                </Text>
                              </Pressable>
                            </View>
                          </View>
                        );
                      })
                    : null
                  // <Text>No completed matches today</Text>
                }

                <>
                  {/* Upcoming/scheduled new item */}
                  {
                    filteredUpcomingMatches &&
                    filteredUpcomingMatches.length > 0
                      ? filteredUpcomingMatches.map((match, matchIndex) => {
                          return (
                            <View style={styles.matchCard} key={matchIndex}>
                              <Text style={styles.matchCardHeading}>
                                {match.tournament
                                  ? match.tournament.Name
                                  : null}
                              </Text>
                              <Text style={styles.matchCardDateText}>
                                {match && match.Venue ? match.Venue.Name : null}
                              </Text>
                              <View style={styles.matchDetailsWrapper}>
                                <View style={styles.teamLogoNameWrapper}>
                                  <Image
                                    // source={require("../../../../../assets/logo/match_logo7.png")}
                                    source={{
                                      uri: match?.team1
                                        ? match?.team1?.LogoURL
                                        : null,
                                    }}
                                    style={styles.matchClubLogo}
                                  />
                                  {/* add css to TeamName */}
                                  <Text style={styles.teamNameText}>
                                    {match.team1 ? match.team1.TeamName : null}
                                  </Text>
                                </View>
                                <View style={styles.matchScoreBadge}>
                                  <Text style={styles.matchCardVsText}>VS</Text>
                                </View>
                                <View style={styles.teamLogoNameWrapper}>
                                  <Image
                                    // source={require("../../../../../assets/logo/match_logo8.png")}
                                    source={{
                                      uri: match?.team2
                                        ? match?.team2?.LogoURL
                                        : null,
                                    }}
                                    style={styles.matchClubLogo}
                                  />
                                  {/* add css to TeamName */}
                                  <Text style={styles.teamNameText}>
                                    {match.team2 ? match.team2.TeamName : null}
                                  </Text>
                                </View>
                              </View>
                              <View style={styles.matchCardDateWrapper}>
                                <Text style={styles.matchCardDateText}>
                                  {match && match.Date
                                    ? formatDate(match.Date)
                                    : null}
                                </Text>
                                <Text style={styles.matchCardDateText}>
                                  {match && match.Time
                                    ? convertTo12HourFormat(match.Time)
                                    : null}
                                </Text>
                              </View>
                              <View
                                style={[
                                  styles.horizontalLine,
                                  { marginVertical: 10 },
                                ]}
                              />
                              <View
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Pressable
                                  style={styles.matchCardRow}
                                  onPress={() => {
                                    handleMatchesResultDetailsNavigation(
                                      match.tournament.id,
                                      match.id
                                    );
                                  }}
                                >
                                  <Graph
                                    name="bar-graph"
                                    size={16}
                                    color={colors.white}
                                  />
                                  <Text style={styles.matchCardBtnTextColor}>
                                    View Details
                                  </Text>
                                </Pressable>
                                <Pressable
                                  style={styles.matchCardRow}
                                  onPress={() =>
                                    handleClickOnWatchMatch(match.LiveURL)
                                  }
                                >
                                  <Play
                                    name="controller-play"
                                    size={16}
                                    color={colors.white}
                                  />
                                  <Text style={styles.matchCardBtnTextColor}>
                                    Watch Match
                                  </Text>
                                </Pressable>
                              </View>
                            </View>
                          );
                        })
                      : null
                    // <Text>No upcoming matches today</Text>
                  }
                </>
                {/* </View> */}
              </ScrollView>
            </View>
          ) : null}
        </View>
      ) : null}

      {errorModalVisible == true ? (
        <ErrorModal
          visibleModal={errorModalVisible}
          SuccessMessage={errorModalMesg}
          onSubmit={handleSubmit}
          header={"Attention"}
        />
      ) : null}

      {/* for all matches
      {Platform.OS === "web" && window.location.pathname === "/Matches" ? (
        <View>
          <AllMatches />
        </View>
      ) : null} */}
    </>
  );
};

const styles = StyleSheet.create({
  MatchesBG: {
    paddingVertical: 50,
    backgroundColor: colors.bgColor,
  },
  container: {
    maxWidth: 1320,
    width: "100%",
    // height: "100%",
    marginHorizontal: "auto",
    paddingRight: 50,
    paddingLeft: 50,
  },
  homeMatchContainer: {
    paddingVertical: 10,
    display: "flex",
    flexDirection: "row",
    gap: 30,
    overflow: "scroll",
    rowGap: 25,
    width: "100%",
    marginTop: 15,
  },
  dashboardMatchContainer: {
    width: "100%",
    marginTop: 15,
    display: "flex",
    flexDirection: "row",
    gap: 25,
    flexWrap: "nowrap",
    overflow: "scroll",
    paddingBottom: 20,
  },
  matchCard: {
    backgroundColor: colors.blue,
    padding: 15,
    width: 400,
    borderRadius: 10,
    shadowOffset: {
      width: 3,
      height: 4,
    },
    shadowColor: "#000000",
    shadowOpacity: 0.22,
    shadowRadius: 10,
    elevation: 3,
    marginHorizontal: 15,
  },
  matchCardColor: {
    // backgroundColor: "#02205F",
    padding: 15,
    width: 400,
    borderRadius: 10,
    shadowOffset: {
      width: 3,
      height: 4,
    },
    shadowColor: "#000000",
    shadowOpacity: 0.22,
    shadowRadius: 10,
    elevation: 3,
    marginHorizontal: 15,
  },
  matchCardHeading: {
    height: 45,
    textAlign: "center",
    fontSize: 16,
    marginBottom: 15,
    fontFamily: "PlusJakartaSansSemiBold",
    color: colors.white,
  },
  matchCardHeadingWhite: {
    // height: 45,
    color: colors.white,
    textAlign: "center",
    fontSize: 16,
    marginBottom: 5,
    fontFamily: "PlusJakartaSansSemiBold",
  },
  matchClubLogo: {
    width: 60,
    height: 60,
    borderRadius: 30,
    backgroundColor: colors.newBlue,
  },
  matchDetailsWrapper: {
    flexDirection: "row",
    gap: 10,
    alignItems: "center",
    justifyContent: "space-around",
  },
  matchScoreBadge: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: 85,
    gap: 5,
  },
  matchScoreText: {
    fontSize: 20,
    fontWeight: "600",
  },
  matchScoreTextWhite: {
    fontSize: 26,
    fontWeight: "600",
    color: colors.white,
    paddingHorizontal: 15,
    borderRadius: 10,
  },
  matchScoreStatus: {
    backgroundColor: "#00175b",
    color: colors.white,
    paddingVertical: 2,
    paddingHorizontal: 5,
  },
  matchCardDateWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: 5,
    marginTop: 15,
  },
  matchCardDateText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 12,
  },
  matchCardDateTextWhite: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 12,
  },
  matchCardVenueTextWhite: {
    color: "#fff",
    fontFamily: "PlusJakartaSansMedium",
    textAlign: "center",
    fontSize: 12,
    marginBottom: 15,
  },
  horizontalLine: {
    width: "100%",
    height: 1,
    backgroundColor: "#dbd6d6",
  },
  matchCardRow: {
    flexDirection: "row",
    gap: 10,
  },
  matchCardVsText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansBold",
    fontSize: 22,
  },
  matchCardBtnTextColor: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 12,
  },
  matchCardBtnText: {
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 12,
    color: colors.white,
  },
  teamNameText: {
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 12,
    color: colors.white,
  },
  teamLogoNameWrapper: {
    alignItems: "center",
    gap: 5,
    flex: 1,
  },
  badgeContainer: {
    // padding: 10,
    paddingTop: 20,
    gap: 20,
    paddingBottom: 0,
    flexDirection: "row",
    backgroundColor: "#FAFAFA",
    overflow: Platform.OS === "web" && screenWidth > 760 ? undefined : "scroll",
  },
  filterButton: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 6,
  },
  filterBtnText: {
    fontSize: 14,
    fontFamily: "PlusJakartaSansMedium",
    color: "#fff",
  },
  serchbar: {
    width: Platform.OS === "web" ? "35%" : "90%",
    borderColor: colors.grayColour,
    backgroundColor: "white",
  },
  liveBadge: {
    backgroundColor: colors.red,
    position: "absolute",
    left: -2,
    top: -2,
    borderRadius: 6,
    paddingVertical: 2,
    paddingHorizontal: 10,
  },
  liveBadgeText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 18,
  },
});

export default Matches;
