import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  Dimensions,
  Pressable,
} from "react-native";
import { H1, H3, H4 } from "../../../components/reusable/TextComponent";
import { colors } from "../../../components/reusable/colour";
import Whistle from "react-native-vector-icons/MaterialCommunityIcons";
import { DataStore } from "aws-amplify/datastore";
import { MatchMaster } from "../../../models";
import { formatDate, sortDataByTime } from "../../../components/FormattedDate";
import BackIcon from "react-native-vector-icons/AntDesign";
import { useNavigation } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";
import ZoomableImage from "../../../components/reusable/ZoomableImage";

const screenWidth = Dimensions.get("window").width;

const ResultDetails = ({
  matchId,
  onDataReceived,
  showResultDetailsFromResult,
  showResultFlag,
  // selectedRoundToResultDetails,
}) => {
  const [matchMaster, setMatchMaster] = useState([]);
  const [teams1Data, setTeams1Data]: any = useState([]);
  const [teams2Data, setTeams2Data]: any = useState([]);

  const [selectedMatchId, setSelectedMatchId] = useState("");
  const [selectedMatchData, setSelectedMatchData] = useState([]);
  const [selectedMatchDetailsObj, setSelectedMatchDetailsObj]: any = useState(
    {}
  );

  const [team1GoalsData, setTeam1GoalsData] = useState([]);
  const [team1CardsData, setTeam1CardsData] = useState([]);
  const [team1SubstitutionData, setTeam1SubstitutionData] = useState([]);

  const [team2GoalsData, setTeam2GoalsData] = useState([]);
  const [team2CardsData, setTeam2CardsData] = useState([]);
  const [team2SubstitutionData, setTeam2SubstitutionData] = useState([]);
  const [allSortedData, setAllSortedData]: any = useState();
  const [backButton, setBackButton] = useState(false);

  const navigation: any = useNavigation();

  let footballImage: any = require("../../../../assets/Admin/icons/football.png");
  let substituteImage: any = require("../../../../assets/Admin/icons/substitute.png");
  let homeLogo: any = "../../../../assets/home/logo.png";
  let avatarImage: any = "../../../../assets/Avtar/Avatar.png";

  // This function handles the navigation based on the flags
  const handleNavigation = () => {
    if (matchId && showResultDetailsFromResult) {
      console.log(matchId, showResultDetailsFromResult);
      // If tournamentId and matchID are present, you can handle navigation accordingly
      setBackButton(false);
      // Implement your specific navigation logic here
    } else {
      // If tournamentId and matchID are not present, navigate to the home page
      setBackButton(true);
      navigation.navigate("Home");
    }
  };

  // this below functions is used for goto result page on Back button navigation
  const sendDataToParent = () => {
    // Call the callback function passed from the parent
    setBackButton(onDataReceived);
    console.log("onDataReceived", onDataReceived);
    handleNavigation();
  };
  function findFirstGreaterNumber(array, HalfTime) {
    if (!Array.isArray(array)) {
      console.error("Input is not an array.");
      return null;
    }

    for (let i = 0; i < array.length; i++) {
      if (array[i].Time > HalfTime) {
        console.log("First Time greater than HalfTime:", array[i].Time);
        return array[i].Time;
      }
    }

    return null; // Return null if no Time value is greater than HalfTime
  }

  const getResultDetails = async (matchId: any) => {
    // const matchesDetailsProps = await AsyncStorage.getItem(
    //   "matchesResultDetails"
    // );
    // const matchesDetails = JSON.parse(matchesDetailsProps);
    // console.log("matchesDetails", matchesDetails);

    const subscription = DataStore.observeQuery(MatchMaster, (c) =>
      c.id.eq(matchId)
    ).subscribe(async (snapshot) => {
      const initialMatches = snapshot.items;
      console.log("matchMaster", initialMatches);
      setMatchMaster(initialMatches);

      const selectedMatchDetails = initialMatches;
      console.log("selectedMatchDetails", selectedMatchDetails);
      setSelectedMatchData(selectedMatchDetails);

      // Selected Match Team1 data
      const team1DataPromises = selectedMatchDetails.map(
        async (data: any) => data.Team1
      );
      console.log("team1DataPromises", team1DataPromises);

      const teams1Data = await Promise.all(team1DataPromises);
      console.log("teams1Data", teams1Data);
      setTeams1Data(teams1Data);

      // selected match team1 goals data

      const team1GoalsPromise = teams1Data.map(
        async (data) => data?.Goals?.values
      );
      console.log("team1GoalsPromise", team1GoalsPromise);
      const team1GoalsData = await Promise.all(team1GoalsPromise);
      console.log("team1GoalsData", team1GoalsData);

      const flattenedTeam1GoalsData = team1GoalsData.flat();
      console.log("flattenedTeam1GoalsData", flattenedTeam1GoalsData);

      // added type into an array of objects
      // Now, team1GoalsObj contains the updated array with type and team attributes.
      const team1GoalsObj = flattenedTeam1GoalsData.map((element) => ({
        ...element,
        type: "goals",
        team: "team1",
      }));

      console.log("team1GoalsObj", team1GoalsObj);

      setTeam1GoalsData(flattenedTeam1GoalsData);

      // selected match team1 cards data

      const team1CardsPromise = teams1Data.map(
        async (data) => data?.Cards?.values
      );

      const team1CardsData = await Promise.all(team1CardsPromise);
      const flattenedTeam1CardsData = team1CardsData.flat();
      console.log("flattenedTeam1CardsData", flattenedTeam1CardsData);

      const team1CardsObj = flattenedTeam1CardsData.map((element) => ({
        ...element,
        type: "cards",
        team: "team1",
      }));

      console.log("team1CardsObj", team1CardsObj);

      setTeam1CardsData(flattenedTeam1CardsData);

      //selected match team1 substitution data

      const team1SubstitutionPromise = teams1Data.map(
        async (data) => data?.Substitutions?.values
      );

      const team1SubstitutionData = await Promise.all(team1SubstitutionPromise);

      const flattenedTeam1SubstitutionData = team1SubstitutionData.flat();
      console.log(
        "flattenedTeam1SubstitutionData",
        flattenedTeam1SubstitutionData
      );

      const team1SubstitutionsObj = flattenedTeam1SubstitutionData.map(
        (element) => ({
          ...element,
          type: "substitutes",
          team: "team1",
        })
      );

      console.log("team1SubstitutionsObj", team1SubstitutionsObj);

      setTeam1SubstitutionData(flattenedTeam1SubstitutionData);

      // --------------team1 data ended-------------------

      // team2 data started--------------------------------

      // Selected Match Team2 data
      const team2DataPromises = selectedMatchDetails.map(
        async (data: any) => data.Team2
      );
      console.log("team2DataPromises", team2DataPromises);

      const teams2Data = await Promise.all(team2DataPromises);
      console.log("teams2Data", teams2Data);
      setTeams2Data(teams2Data);

      // selected team2 goals data

      const team2GoalsPromise = teams2Data.map(
        async (data) => data.Goals.values
      );
      const team2GoalsData = await Promise.all(team2GoalsPromise);
      const flattenedTeam2GoalsData = team2GoalsData.flat();
      console.log("flattenedTeam2GoalsData", flattenedTeam2GoalsData);

      const team2GoalsObj = flattenedTeam2GoalsData.map((element) => ({
        ...element,
        type: "goals",
        team: "team2",
      }));
      console.log("team2GoalsObj", team2GoalsObj);

      setTeam2GoalsData(flattenedTeam2GoalsData);

      // selected team2 cards data

      const team2CardsPromise = teams2Data.map(
        async (data) => data.Cards.values
      );
      const team2CardsData = await Promise.all(team2CardsPromise);
      const flattenedTeam2CardsData = team2CardsData.flat();
      console.log("flattenedTeam2CardsData", flattenedTeam2CardsData);

      const team2CardsObj = flattenedTeam2CardsData.map((element) => ({
        ...element,
        type: "cards",
        team: "team2",
      }));
      console.log("team2CardsObj", team2CardsObj);

      setTeam2CardsData(flattenedTeam2CardsData);

      // selected team2 substitution data

      const team2SSubstitutionPromise = teams2Data.map(
        async (data) => data.Substitutions.values
      );

      const team2SubstitutionData = await Promise.all(
        team2SSubstitutionPromise
      );
      const flattenedTeam2SubstitutionsData = team2SubstitutionData.flat();
      console.log(
        "flattenedTeam2Substitutions",
        flattenedTeam2SubstitutionsData
      );

      const team2SubstitutesObj = flattenedTeam2SubstitutionsData.map(
        (element) => ({
          ...element,
          type: "substitutes",
          team: "team2",
        })
      );
      console.log("team2SubstitutesObj", team2SubstitutesObj);

      setTeam2SubstitutionData(flattenedTeam2SubstitutionsData);

      // Organize data into corresponding arrays based on position
      const selectedMatchDetailsObj: any = selectedMatchDetails.reduce(
        (acc, match, index) => {
          const position = index + 1; // Adjust position as needed
          acc.matchMaster = match;
          acc.teams1Data = teams1Data[index];
          acc.teams2Data = teams2Data[index];
          return acc;
        },
        { matchMaster: {}, teams1Data: {}, teams2Data: {} }
      );

      console.log("selectedMatchDetailsObj", selectedMatchDetailsObj);
      setSelectedMatchDetailsObj(selectedMatchDetailsObj);

      const allTimeLineData = [
        ...team1GoalsObj,
        ...team1CardsObj,
        ...team1SubstitutionsObj,
        ...team2GoalsObj,
        ...team2CardsObj,
        ...team2SubstitutesObj,
      ];

      // online scoring

      const halfTimeObj = {
        HalfTime: selectedMatchDetailsObj.matchMaster.FirstHalfEnded,
        Time: selectedMatchDetailsObj.matchMaster.FirstHalfEndMinutes,
      };
      const fullTimeObj = {
        FullTime: selectedMatchDetailsObj.matchMaster.SecondHalfEnded,
        Time: selectedMatchDetailsObj.matchMaster.SecondHalfEndMinutes,
      };
      const extraTimeObj = {
        ExtraTime: selectedMatchDetailsObj.matchMaster.ExtraTimeEnded,
        Time: selectedMatchDetailsObj.matchMaster.ExtraTimeEndMinutes,
      };
      // Combine all data arrays into one
      // For online scoring

      if (selectedMatchDetailsObj.matchMaster.OnlineScoring === true) {
        console.log("its online scoring");
        selectedMatchDetailsObj.matchMaster.FirstHalfEnded == true
          ? allTimeLineData.push(halfTimeObj)
          : null;
        selectedMatchDetailsObj.matchMaster.SecondHalfEnded == true
          ? allTimeLineData.push(fullTimeObj)
          : null;
        selectedMatchDetailsObj.matchMaster.ExtraTimeEnded == true
          ? allTimeLineData.push(extraTimeObj)
          : null;
        console.log("allTimeLineData", allTimeLineData);
        console.log("Online scoring is on");
      }

      if (selectedMatchDetailsObj.matchMaster.OnlineScoring === false) {
        console.log("offline scoring is on");

        const halfTimeObjOffline = {
          HalfTime: true,
          Time: selectedMatchDetailsObj.matchMaster.HalfTime,
        };

        const fullTimeObjOffline = {
          FullTime: true,
          Time: selectedMatchDetailsObj.matchMaster.HalfTime * 2,
        };

        for (let i = 0; i < allTimeLineData.length; i++) {
          if (
            allTimeLineData[i].Time >
            selectedMatchDetailsObj.matchMaster.HalfTime * 2
          ) {
            console.log(
              "First Time greater than HalfTime:",
              allTimeLineData[i].Time
            );

            const extraTimeObjOffline = {
              ExtraTime: true,
              Time: selectedMatchDetailsObj.matchMaster.HalfTime * 3,
            };
            allTimeLineData.push(extraTimeObjOffline);
            break;
          }
        }
        allTimeLineData.push(halfTimeObjOffline, fullTimeObjOffline);
      }

      // Sort the combined array by time
      const sortedData = sortDataByTime(allTimeLineData);
      console.log("sortedData", sortedData);
      setAllSortedData(sortedData);
    });

    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    getResultDetails(matchId);
  }, [
    matchId,
    onDataReceived,
    showResultDetailsFromResult,
    showResultFlag,
    // selectedRoundToResultDetails,
  ]);

  return (
    <View
      style={{
        paddingBottom: Platform.OS !== "web" ? 100 : undefined,
      }}
    >
      {/* match score data  */}
      {selectedMatchDetailsObj &&
      selectedMatchDetailsObj !== null &&
      Object.keys(selectedMatchDetailsObj.matchMaster || {}).length > 0 ? (
        <LinearGradient
          style={styles.resultHeadCard}
          colors={["#FF5733", "#012879"]}
          start={{ x: 0, y: 1 }}
          end={{ x: 1, y: 1 }}
        >
          <Pressable
            onPress={sendDataToParent}
            style={{
              backgroundColor: colors.aqua,
              width: 65,
              height: 30,
              borderRadius: 5,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              gap: 5,
              position: "absolute",
              left: 0,
              top: -35,
            }}
          >
            <BackIcon name="left" size={16} color={colors.royalBlueColour} />
            <Text
              style={{
                color: colors.royalBlueColour,
                fontFamily: "PlusJakartaSansSemiBold",
                fontSize: 12,
              }}
            >
              Back
            </Text>
          </Pressable>

          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <H4
              props={
                selectedMatchDetailsObj.matchMaster
                  ? `M-${selectedMatchDetailsObj.matchMaster.MatchNo}`
                  : "N.A"
              }
              style={{ color: colors.white }}
            />
            <View>
              <Text style={styles.resultDetailsHeadLabel2}>
                {selectedMatchDetailsObj.matchMaster.Venue
                  ? selectedMatchDetailsObj.matchMaster.Venue.Name
                  : "N.A"}
              </Text>
              <Text style={styles.resultDetailsHeadLabel2}>
                {selectedMatchDetailsObj.matchMaster
                  ? formatDate(selectedMatchDetailsObj.matchMaster.Date)
                  : "Invalid Date"}
              </Text>
            </View>
            <H4
              props={
                selectedMatchDetailsObj.matchMaster
                  ? selectedMatchDetailsObj.matchMaster.GroupName
                  : "N.A"
              }
              style={{ color: colors.white }}
            />
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: Platform.OS === "web" && screenWidth > 760 ? 50 : 20,
            }}
          >
            {Object.keys(
              selectedMatchDetailsObj.teams1Data &&
                selectedMatchDetailsObj.teams2Data
            ).length > 0 ? (
              <>
                <View style={styles.resultItemTeamNameLogo}>
                  <ZoomableImage
                    imageSource={{
                      uri:
                        selectedMatchDetailsObj &&
                        selectedMatchDetailsObj.teams1Data &&
                        selectedMatchDetailsObj.teams1Data.LogoURL
                          ? selectedMatchDetailsObj.teams1Data.LogoURL
                          : homeLogo,
                    }}
                    imageStyle={styles.resultHeadingTeamLogo}
                    modalStyle={undefined}
                  />
                  <H4
                    props={
                      selectedMatchDetailsObj.teams1Data
                        ? selectedMatchDetailsObj.teams1Data.TeamName
                        : "N.A"
                    }
                    style={{ color: colors.white }}
                  />
                </View>
                <View style={styles.resultDetailsScoreWrapper}>
                  <H1
                    props={
                      selectedMatchDetailsObj.teams1Data &&
                      selectedMatchDetailsObj.teams1Data.Score
                        ? selectedMatchDetailsObj.teams1Data.Score.FirstHalf +
                          selectedMatchDetailsObj.teams1Data.Score.SecondHalf +
                          selectedMatchDetailsObj.teams1Data.Score.ExtraTime
                        : "0"
                    }
                    style={styles.loserScore}
                  />
                  <Text
                    style={{
                      fontFamily: "PlusJakartaSansSemiBold",
                      fontSize: 17,
                      color: colors.white,
                    }}
                  >
                    FT
                  </Text>
                  <H1
                    props={
                      selectedMatchDetailsObj.teams2Data &&
                      selectedMatchDetailsObj.teams2Data.Score
                        ? selectedMatchDetailsObj.teams2Data.Score.FirstHalf +
                          selectedMatchDetailsObj.teams2Data.Score.SecondHalf +
                          selectedMatchDetailsObj.teams2Data.Score.ExtraTime
                        : "0"
                    }
                    style={styles.winnerScore}
                  />
                </View>
                <View style={styles.resultItemTeamNameLogoRight}>
                  <H4
                    props={
                      selectedMatchDetailsObj.teams2Data
                        ? selectedMatchDetailsObj.teams2Data.TeamName
                        : "N.A"
                    }
                    style={{ color: colors.white }}
                  />
                  <ZoomableImage
                    imageSource={{
                      uri:
                        selectedMatchDetailsObj &&
                        selectedMatchDetailsObj.teams2Data &&
                        selectedMatchDetailsObj.teams2Data.LogoURL
                          ? selectedMatchDetailsObj.teams2Data.LogoURL
                          : homeLogo,
                    }}
                    imageStyle={styles.resultHeadingTeamLogo}
                    modalStyle={undefined}
                  />
                </View>
              </>
            ) : null}
          </View>
        </LinearGradient>
      ) : null}

      {/* ---timeline started--- */}
      <View style={styles.resultTimelineCard}>
        {/* first whistle  */}
        <View>
          <Whistle
            name="whistle"
            size={32}
            color="#ff5733"
            style={{ textAlign: "center" }}
          />
        </View>

        {allSortedData &&
          allSortedData.length > 0 &&
          allSortedData.map((item: any, index: any) => (
            <React.Fragment key={index}>
              {/* team2 goals  */}
              {item.type === "goals" && item.team === "team2" ? (
                <>
                  <View style={styles.resultTimelineItemWrapper} key={index}>
                    <View style={styles.resultMilestoneLeftContainer}></View>
                    <View style={styles.resultTimeLineCenterDividerContainer}>
                      <Text style={styles.resultTimelineMinute}>
                        {item.Time ? item.Time : null}'
                      </Text>
                    </View>
                    <View style={styles.resultMilestoneRightContainer}>
                      <Image
                        source={footballImage}
                        style={styles.timeLineIcon}
                      />
                      <H4
                        props={`${item.Player ? item.Player.Name : null} (${
                          item.Player.JerseyNo
                        })`}
                        style={{ textAlign: "right" }}
                      />
                    </View>
                  </View>
                  <View style={styles.resultTimelineItemWrapper}>
                    <View style={styles.resultMilestoneLeftContainer}></View>
                    <View style={styles.resultTimeLineCenterDividerContainer}>
                      <View style={styles.resultTimelineVerticleLine} />
                    </View>
                    <View style={styles.resultMilestoneRightContainer}></View>
                  </View>
                </>
              ) : null}

              {/* team2 substitution */}
              {item.type === "substitutes" && item.team === "team2" ? (
                <React.Fragment key={index}>
                  <View style={styles.resultTimelineItemWrapper}>
                    <View style={styles.resultMilestoneLeftContainer}></View>
                    <View style={styles.resultTimeLineCenterDividerContainer}>
                      <Text style={styles.resultTimelineMinute}>
                        {item.Time ? item.Time : null}'
                      </Text>
                    </View>
                    <View style={styles.resultMilestoneRightContainer}>
                      <Image
                        source={substituteImage}
                        style={styles.timeLineIcon}
                      />
                      <View>
                        <H4
                          props={`${item.InPlayer.Name}:In (${item.InPlayer.JerseyNo})`}
                          style={{
                            textAlign: "left",
                            color: "#008000",
                          }}
                        />
                        <H4
                          props={`${item.OutPlayer.Name}:Out (${item.OutPlayer.JerseyNo})`}
                          style={{
                            textAlign: "left",
                            color: "#FF0000",
                          }}
                        />
                      </View>
                    </View>
                  </View>
                  <View style={styles.resultTimelineItemWrapper}>
                    <View style={styles.resultMilestoneLeftContainer}></View>
                    <View style={styles.resultTimeLineCenterDividerContainer}>
                      <View style={styles.resultTimelineVerticleLine} />
                    </View>
                    <View style={styles.resultMilestoneRightContainer}></View>
                  </View>
                </React.Fragment>
              ) : null}

              {/* team2 cards */}
              {item.type === "cards" && item.team === "team2" ? (
                <>
                  <View style={styles.resultTimelineItemWrapper} key={index}>
                    <View style={styles.resultMilestoneLeftContainer}></View>
                    <View style={styles.resultTimeLineCenterDividerContainer}>
                      <Text style={styles.resultTimelineMinute}>
                        {item.Time ? item.Time : null}'
                      </Text>
                    </View>
                    <View style={styles.resultMilestoneRightContainer}>
                      {item.CardType === "Red" || item.CardType === "RED" ? (
                        <View style={styles.redCard}></View>
                      ) : item.CardType === "Yellow" ||
                        item.CardType === "YELLOW" ? (
                        <View style={styles.yellowCard}></View>
                      ) : null}
                      <H4
                        props={`${item.Player ? item.Player.Name : null} (${
                          item.Player.JerseyNo
                        })`}
                        style={{ textAlign: "left" }}
                      />
                    </View>
                  </View>
                  <View style={styles.resultTimelineItemWrapper}>
                    <View style={styles.resultMilestoneLeftContainer}></View>
                    <View style={styles.resultTimeLineCenterDividerContainer}>
                      <View style={styles.resultTimelineVerticleLine} />
                    </View>
                    <View style={styles.resultMilestoneRightContainer}></View>
                  </View>
                </>
              ) : null}

              {/* team1 cards  */}
              {item.type === "cards" && item.team === "team1" ? (
                <>
                  <View style={styles.resultTimelineItemWrapper} key={index}>
                    <View style={styles.resultMilestoneLeftContainer}>
                      <H4
                        props={`${item.Player ? item.Player.Name : null}  (${
                          item.Player.JerseyNo
                        })`}
                        style={{ textAlign: "right" }}
                      />

                      {item.CardType === "Red" || item.CardType === "RED" ? (
                        <View style={styles.redCard}></View>
                      ) : item.CardType === "Yellow" ||
                        item.CardType === "YELLOW" ? (
                        <View style={styles.yellowCard}></View>
                      ) : null}
                    </View>
                    <View style={styles.resultTimeLineCenterDividerContainer}>
                      <Text style={styles.resultTimelineMinute}>
                        {item.Time ? item.Time : null}'
                      </Text>
                    </View>
                    <View style={styles.resultMilestoneRightContainer}></View>
                  </View>
                  <View style={styles.resultTimelineItemWrapper}>
                    <View style={styles.resultMilestoneLeftContainer}></View>
                    <View style={styles.resultTimeLineCenterDividerContainer}>
                      <View style={styles.resultTimelineVerticleLine} />
                    </View>
                    <View style={styles.resultMilestoneRightContainer}></View>
                  </View>
                </>
              ) : null}

              {/* team 1 goals  */}
              {item.type === "goals" && item.team === "team1" ? (
                <>
                  <View style={styles.resultTimelineItemWrapper} key={index}>
                    <View style={styles.resultMilestoneLeftContainer}>
                      <H4
                        props={`${item.Player ? item.Player.Name : null}   (${
                          item.Player.JerseyNo
                        })`}
                        style={{ textAlign: "right" }}
                      />

                      <Image
                        source={footballImage}
                        style={styles.timeLineIcon}
                      />
                    </View>
                    <View style={styles.resultTimeLineCenterDividerContainer}>
                      <Text style={styles.resultTimelineMinute}>
                        {item.Time ? item.Time : null}'
                      </Text>
                    </View>

                    <View style={styles.resultMilestoneRightContainer}></View>
                  </View>
                  <View style={styles.resultTimelineItemWrapper}>
                    <View style={styles.resultMilestoneLeftContainer}></View>
                    <View style={styles.resultTimeLineCenterDividerContainer}>
                      <View style={styles.resultTimelineVerticleLine} />
                    </View>
                    <View style={styles.resultMilestoneRightContainer}></View>
                  </View>
                </>
              ) : null}

              {/* team 1 substitution   */}
              {item.type === "substitutes" && item.team === "team1" ? (
                <>
                  <View style={styles.resultTimelineItemWrapper} key={index}>
                    <View style={styles.resultMilestoneLeftContainer}>
                      <View>
                        <H4
                          // props={"Pable Rosario :In"}
                          props={`${item.InPlayer.Name}:In (${item.InPlayer.JerseyNo})`}
                          style={{ textAlign: "right" }}
                        />
                        <H4
                          // props={"Hicham Boudaoui :Out"}
                          props={`${item.OutPlayer.Name}:Out (${item.OutPlayer.JerseyNo})`}
                          style={{
                            textAlign: "right",
                            color: "#A4A4A4",
                          }}
                        />
                      </View>
                      <Image
                        source={substituteImage}
                        style={styles.timeLineIcon}
                      />
                    </View>
                    <View style={styles.resultTimeLineCenterDividerContainer}>
                      <Text style={styles.resultTimelineMinute}>
                        {item ? item.Time : null}'
                      </Text>
                    </View>
                    <View style={styles.resultMilestoneRightContainer}></View>
                  </View>
                  <View style={styles.resultTimelineItemWrapper}>
                    <View style={styles.resultMilestoneLeftContainer}></View>
                    <View style={styles.resultTimeLineCenterDividerContainer}>
                      <View style={styles.resultTimelineVerticleLine} />
                    </View>
                    <View style={styles.resultMilestoneRightContainer}></View>
                  </View>
                </>
              ) : null}

              {/* half time online && offline scoring*/}
              {item.HalfTime ? (
                <View>
                  <Text style={styles.resultDetailsHalfTimeHeading}>
                    {`HT ${
                      selectedMatchDetailsObj.teams1Data.Score
                        ? selectedMatchDetailsObj.teams1Data.Score.FirstHalf
                        : null
                    }-${
                      selectedMatchDetailsObj.teams2Data.Score
                        ? selectedMatchDetailsObj.teams2Data.Score.FirstHalf
                        : null
                    }`}
                  </Text>
                </View>
              ) : null}

              {/* full time online && offline scoring */}
              {item.FullTime ? (
                <View>
                  <Text style={styles.resultDetailsHalfTimeHeading}>
                    {`FT ${
                      selectedMatchDetailsObj.teams1Data.Score
                        ? selectedMatchDetailsObj.teams1Data.Score.FirstHalf +
                          selectedMatchDetailsObj.teams1Data.Score.SecondHalf
                        : null
                    }-${
                      selectedMatchDetailsObj.teams2Data.Score
                        ? selectedMatchDetailsObj.teams2Data.Score.FirstHalf +
                          selectedMatchDetailsObj.teams2Data.Score.SecondHalf
                        : null
                    }`}
                  </Text>

                  <View>
                    <Whistle
                      name="whistle"
                      size={32}
                      color="#ff5733"
                      style={{ textAlign: "center" }}
                    />
                  </View>
                </View>
              ) : null}

              {/* extra time online  && offline scoring */}
              {item.ExtraTime ? (
                <View>
                  <Text style={styles.resultDetailsHalfTimeHeading}>
                    {`ET ${
                      selectedMatchDetailsObj.teams1Data.Score
                        ? selectedMatchDetailsObj.teams1Data.Score.FirstHalf +
                          selectedMatchDetailsObj.teams1Data.Score.SecondHalf +
                          selectedMatchDetailsObj.teams1Data.Score.ExtraTime
                        : null
                    }-${
                      selectedMatchDetailsObj.teams2Data.Score
                        ? selectedMatchDetailsObj.teams2Data.Score.FirstHalf +
                          selectedMatchDetailsObj.teams2Data.Score.SecondHalf +
                          selectedMatchDetailsObj.teams2Data.Score.ExtraTime
                        : null
                    }`}
                  </Text>
                </View>
              ) : null}
            </React.Fragment>
          ))}

        {/* first whistle  */}
        <View>
          <Whistle
            name="whistle"
            size={32}
            color="#ff5733"
            style={{ textAlign: "center" }}
          />
        </View>
      </View>
      {/* timeline ended */}

      {/* Team LineUp */}
      <View style={styles.timeLineUpWrapper}>
        <View style={{ display: "flex", alignItems: "center" }}>
          <H3 props={"Team Line Up"} style={styles.teamLineupHeading} />
        </View>
        <View style={styles.bothTeamLineUp}>
          {/* team1 details */}
          <View style={styles.teamLineUpContainer}>
            <View style={styles.teamLineupItemTeam}>
              <Image
                source={{
                  uri:
                    selectedMatchDetailsObj &&
                    selectedMatchDetailsObj.teams1Data &&
                    selectedMatchDetailsObj.teams1Data.LogoURL
                      ? selectedMatchDetailsObj.teams1Data.LogoURL
                      : homeLogo,
                }}
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 25,
                }}
                resizeMode="contain"
              />
              <H4
                props={
                  selectedMatchDetailsObj && selectedMatchDetailsObj.teams1Data
                    ? selectedMatchDetailsObj.teams1Data.TeamName
                    : "N.A"
                }
                style={{ color: colors.white }}
              />
            </View>

            {selectedMatchDetailsObj &&
              selectedMatchDetailsObj.teams1Data &&
              selectedMatchDetailsObj.teams1Data.Players &&
              selectedMatchDetailsObj.teams1Data.Players.map(
                (player: any, index: any) => (
                  <View style={styles.teamLineupItem} key={index}>
                    <Image
                      source={{
                        uri:
                          player && player.PhotoURL
                            ? player.PhotoURL
                            : avatarImage,
                      }}
                      style={styles.teamLineupPlayerIcon}
                    />
                    <Text style={styles.playerName}>
                      {/* Gurpreet Singh Sandhu */}
                      {player ? player.Name : "N.A"}
                    </Text>
                  </View>
                )
              )}
          </View>

          {/* team2 details */}
          <View style={styles.teamLineUpContainer}>
            <View style={styles.teamLineupItemTeam}>
              <Image
                source={{
                  uri:
                    selectedMatchDetailsObj &&
                    selectedMatchDetailsObj.teams2Data &&
                    selectedMatchDetailsObj.teams2Data.LogoURL
                      ? selectedMatchDetailsObj.teams2Data.LogoURL
                      : homeLogo,
                }}
                style={{
                  width: 50,
                  height: 50,
                  borderRadius: 25,
                }}
                resizeMode="contain"
              />
              <H4
                props={
                  selectedMatchDetailsObj && selectedMatchDetailsObj.teams2Data
                    ? selectedMatchDetailsObj.teams2Data.TeamName
                    : "N.A"
                }
                style={{ color: colors.white }}
              />
            </View>

            {selectedMatchDetailsObj &&
              selectedMatchDetailsObj.teams2Data &&
              selectedMatchDetailsObj.teams2Data.Players &&
              selectedMatchDetailsObj.teams2Data.Players.map(
                (player: any, index: any) => (
                  <View style={styles.teamLineupItem} key={index}>
                    <Image
                      source={{
                        uri:
                          player && player.PhotoURL
                            ? player.PhotoURL
                            : avatarImage,
                      }}
                      style={styles.teamLineupPlayerIcon}
                    />
                    <Text style={styles.playerName}>
                      {player ? player.Name : "N.A"}
                    </Text>
                  </View>
                )
              )}
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  resultHeadCard: {
    backgroundColor: colors.royalBlue,
    marginTop: 30,
    borderRadius: 10,
    padding: 10,
    // boxShadow: "0 0 10px #0003",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.3,
    shadowRadius: 10,
    elevation: 5,
  },
  resultDetailsHeadLabel: {
    textAlign: "center",
    marginBottom: 10,
    fontSize: 17,
    fontFamily: "PlusJakartaSansSemiBold",
  },
  resultItemTeamNameLogo: {
    display: "flex",
    flexDirection:
      Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
    justifyContent: "center",
    alignItems: "center",
    gap: Platform.OS === "web" && screenWidth > 760 ? 10 : 5,
    flex: 1,
  },
  resultItemTeamNameLogoRight: {
    display: "flex",
    flexDirection:
      Platform.OS === "web" && screenWidth > 760 ? "row" : "column-reverse",
    justifyContent: "center",
    alignItems: "center",
    gap: Platform.OS === "web" && screenWidth > 760 ? 10 : 5,
    flex: 1,
  },
  resultDetailsScoreWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 25,
  },
  winnerScore: {
    color: "#28C800",
  },
  loserScore: {
    color: "#FF0909",
  },
  resultTimelineCard: {
    backgroundColor: colors.royalBlue,
    // boxShadow: "0px 5px 10px #0808082b",
    marginTop: 30,
    borderRadius: 10,
    padding: 10,
  },
  resultDetailsHalfTimeHeading: {
    textAlign: "center",
    backgroundColor: "#d9e5ff",
    borderRadius: 7,
    color: colors.royalBlueColour,
    paddingVertical: 5,
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 14,
    marginVertical: 10,
  },
  resultTimelineItemWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: Platform.OS === "web" && screenWidth > 760 ? 10 : 5,
    alignItems: "center",
  },
  resultMilestoneLeftContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 10,
  },
  resultMilestoneRightContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 10,
  },
  resultTimeLineCenterDividerContainer: {
    width: 30,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginVertical: 5,
  },
  resultTimelineVerticleLine: {
    width: 2,
    height: 30,
    backgroundColor: "#cacdd3",
  },
  resultTimelineMinute: {
    color: "#A4A4A4",
    fontFamily: "PlusJakartaSansMedium",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 14 : 12,
  },
  yellowCard: {
    width: Platform.OS === "web" && screenWidth > 760 ? 22 : 15,
    height: Platform.OS === "web" && screenWidth > 760 ? 28 : 20,
    backgroundColor: "#f9db05",
    borderRadius: 3,
  },
  redCard: {
    width: Platform.OS === "web" && screenWidth > 760 ? 22 : 15,
    height: Platform.OS === "web" && screenWidth > 760 ? 28 : 20,
    backgroundColor: "#ff0000",
    borderRadius: 3,
  },

  resultDetailsHeadLabel2: {
    textAlign: "center",
    marginBottom: 5,
    fontSize: 14,
    fontFamily: "PlusJakartaSansSemiBold",
    color: colors.white,
  },
  timeLineUpWrapper: {
    backgroundColor: colors.royalBlue,
    marginTop: 30,
    borderRadius: 10,
    padding: 10,
    // boxShadow: "0 0 10px #0003",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.3,
    shadowRadius: 10,
    elevation: 5,
  },
  teamLineupHeading: {
    textAlign: "center",
    fontFamily: "PlusJakartaSansMedium",
    color: colors.white,
    width: 500,
    maxWidth: "80%",
    paddingBottom: 5,
    borderBottomColor: colors.white,
    borderBottomWidth: 1,
  },
  teamLineupItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  teamLineupItemTeam: {
    display: "flex",
    flexDirection:
      Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
    alignItems:
      Platform.OS === "web" && screenWidth > 760 ? "center" : "flex-start",
    gap: Platform.OS === "web" && screenWidth > 760 ? 8 : 3,
  },

  playerName: {
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 14,
    textAlign: "left",
    color: colors.white,
  },
  playerNameRight: {
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 14,
    textAlign: "right",
  },
  bothTeamLineUp: {
    display: "flex",
    flexDirection: "row",
    paddingHorizontal: Platform.OS === "web" && screenWidth > 760 ? 80 : 5,
    marginTop: 30,
    gap: 20,
  },
  teamLineUpContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    flex: 1,
  },
  teamLineupItemRight: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    justifyContent: "flex-end",
  },

  resultHeadingTeamLogo: {
    width: Platform.OS === "web" && screenWidth > 760 ? 80 : 50,
    height: Platform.OS === "web" && screenWidth > 760 ? 80 : 50,
    resizeMode: "contain",
    borderRadius: 50,
  },
  timeLineIcon: {
    width: Platform.OS === "web" && screenWidth > 760 ? 30 : 20,
    height: Platform.OS === "web" && screenWidth > 760 ? 30 : 20,
    resizeMode: "contain",
  },
  teamLineupPlayerIcon: {
    width: 35,
    height: 35,
    borderRadius: 25,
    resizeMode: "contain",
    // display: Platform.OS === "web" && screenWidth > 760 ? "flex" : "none",
  },
});

export default ResultDetails;
