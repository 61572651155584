import React, { useEffect } from "react";
import { BetaAnalyticsDataClient } from "@google-analytics/data";
import HeroSection from "./SubComp/HeroSection";
import Matches from "./SubComp/Matches";
import HowToUseApp from "./SubComp/HowToUseApp";
import TournamentSetup from "./SubComp/TournamentSetup";
// import TournamentCard from "./SubComp/TournamentCard";
import Layout from "../../../components/include/Layout";
import Counter from "./SubComp/Counter";
import { ScrollView } from "react-native";
// import { H2 } from "../../../components/reusable/TextComponent";
import { colors } from "../../../components/reusable/colour";
import SEO from "../../../components/reusable/SEO";
import HomeTournaments from "./SubComp/HomeTournaments";

const Home = () => {
  // const { BetaAnalyticsDataClient } = require("@google-analytics/data");
  const propertyId = "443196490";
  // const analyticsDataClient = new BetaAnalyticsDataClient();s
  useEffect(() => {
    // const runReport = async () => {
    //   const [response] = await analyticsDataClient.runReport({
    //     property: `properties/${propertyId}`,
    //     dateRanges: [
    //       {
    //         startDate: "2020-03-31",
    //         endDate: "today",
    //       },
    //     ],
    //     dimensions: [
    //       {
    //         name: "city",
    //       },
    //     ],
    //     metrics: [
    //       {
    //         name: "activeUsers",
    //       },
    //     ],
    //   });
    // };
  }, []);
  return (
    <Layout>
      <SEO
        title="TrackMyTournament - Best platform in the world to manage your tournaments!"
        description="Manage tournaments efficiently with our tournament management app. Organize matches, track scores, and engage participants seamlessly for Football, Basketball."
      />
      <ScrollView style={{ backgroundColor: colors.white }}>
        <HeroSection />

        <Matches />

        {/* <TournamentCard
          filteredData={[]}
          searchFlagValue={false}
          activeBadge={""}
          isFilteredDataEmpty={undefined}
        /> */}

        <HomeTournaments />
        <HowToUseApp />
        <Counter />
        <TournamentSetup />
        {/* <HostYourDream /> */}
        {/* <Footer /> */}
        {/* <AppFeature /> */}
      </ScrollView>
    </Layout>
  );
};

export default Home;
