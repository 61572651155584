export const firebaseConfig = {
  apiKey: "AIzaSyClNjqmzwOtWV6NqrjdRuz9lQ8a2Bc5rMQ",
  authDomain: "trackmytournament.firebaseapp.com",
  projectId: "trackmytournament",
  storageBucket: "trackmytournament.appspot.com",
  messagingSenderId: "770903567915",
  appId: "1:770903567915:web:cd3566b87ea462ae28846e",
  measurementId: "G-93HDKJDW11",
};
// export default firebaseConfig;

export const googleAnalyticsConfig = {
  type: "service_account",
  project_id: "trackmytournamen-1717828332694",
  private_key_id: "6958b5a8a8c8f65707a574fa3c98fa04e0b33cfb",
  private_key:
    "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDAhRqftVM5ZtZF\nw1v2sP3jVS1tBL+VIRm6h4JqvWhq3rYDCWnkTc58fCrBgn1arv7VnjACYLhKaxbF\nCWSaxbvhxvezmsv77qZGpUsXBnSC38I0HYLVEB2moilmJ9rNE5+cNsMDm5El7vgX\n8f/B+pa17jaMbfpGzcHVCkwnGfYSJpCgxEKTPgSNL5nY8DcAVLv78FbAMko76sT2\niBSSLFHk6iKri/KqoHhtQLqbgkpzFsP1eTPoIPZ3QaysVJMcmU7W8klbvXuIsben\n5eUvhYXIG1asvPXFdH9ZWW/xlzqkN9K4Ml24GH1IPqMqRRhUEaZEPYp1TQjm5IqD\nyuplTy/nAgMBAAECggEAXlKYWbkwHkaJIsL1UqcirPe1et1BtCH0hhQQTJWiDAIz\nsMcy711DlnALTqXNHtnwFt8a7H9Qq31OOZlBhibsx1CBWai02ijxpFklshFCkkBo\n2LnAuPzdWh/HIrrSE3nS0GlwIFtewPQU7z5srTBGGLquVR0o6vmyRtt1tg6dJgSP\nIEpQHGSWYbXpbrri6wBZSrPaF8qAi9ZXoIfVpyaw1c8ZZsX7Or++LWE+TYuYHsWa\nGjngYPIbDIWiL3U1YVsl9ReF846/wV5ygOauLN3+XPWgpxXvDYoNPVYimwr8ve+G\n+/3KgQ8GTip16c7Ooy6HSbFRahKUSbU6BfAi/o7JwQKBgQDrGlsQ/LlC9kKuIP+P\npFXgBzHbavoq7zv/Pcq3jvK1k3gAvTVp2kQRbzy9kBK0o+UBLIa2COqD8vCY2L3e\nBpsMlLNQs1HpHpWR0zKBy0EdO8Z5QiBIiJ2UTkQ1ixLYx+a8QDZTKiXujylSP5uf\n6GmX6z/l3a8zF/KwDv+n6140IQKBgQDRoctL3F79m1L64LPhK9yChjUadS13xX7+\n0PEKtYTbwQrPkIjTvKLoIJ1G3/gA0WOdtm0uUgB2mA1RjzwAarNPxVTlc18NVLOF\nbCbBMh3fsdgDZr+c02XZWz8yl/fKdwUHCX+pMwVe2j6C2TIa/KQrZh4DBwdy1Mru\nnDn0hTNjBwKBgQCOa6/hEYfVNcNdIuZOCPpwtUsRpbOY0MYisDcejX0gXA5lPDmg\nPzXe7ej42M9MGMEAvg15NtuCRWhU32/dkVjZ/TbHW2nyc7Gd3jgJgqyWXgC0skdG\nSWRUVDljyjUHl8mCHtAJ/01tP9KDXb8T1sIo4r0ZrfqVVBuTZfKfsaimoQKBgCKX\n/5N6P1a8jhoGgMi000Pa3+KI2cL9HXLxigWJZO5a0ezk3h+K7ps93pZ2mVeRNk4t\nb6EIOTlY6EHAUFeFRCfy2Yqv2ky54JIxAcg5/AIROCN/jjlUDWesDibMO0D3GSZL\nI/0JBWbcvxoau5EGhhT/Wg6H12vUv9RWhZ3baVp7AoGAVDvtnudNfl3YmDDCWff1\n9MYx59rYcDwX9mjBTOMHFjk+pj7YRk9zyXG81PJUkd/CtlX67+876WG7av02/TQB\noWKFwJMDSTjN6INuyhXar53MdY972rHVRs+FSWIpJBjXd+7ve07E2qoK6at7Y5M7\nW4hZEFT8loo2nK3GJWBS4po=\n-----END PRIVATE KEY-----\n",
  client_email:
    "starting-account-lg0vdhkz5kvf@trackmytournamen-1717828332694.iam.gserviceaccount.com",
  client_id: "108439596667045437542",
  auth_uri: "https://accounts.google.com/o/oauth2/auth",
  token_uri: "https://oauth2.googleapis.com/token",
  auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
  client_x509_cert_url:
    "https://www.googleapis.com/robot/v1/metadata/x509/starting-account-lg0vdhkz5kvf%40trackmytournamen-1717828332694.iam.gserviceaccount.com",
  universe_domain: "googleapis.com",
};
