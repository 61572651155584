//import liraries
import { DataStore } from "aws-amplify/datastore";
import React, { Component, useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Pressable,
  Platform,
  Dimensions,
  Image,
} from "react-native";
import { TeamRegistrations } from "../../../models";
import BackIcon from "react-native-vector-icons/AntDesign";
import { H1, H4, H5 } from "../../../components/reusable/TextComponent";
import { getIndex } from "@aws-amplify/datastore/lib-esm/util";
import { useNavigation } from "@react-navigation/native";
import { colors } from "../../../components/reusable/colour";
import ZoomableImage from "../../../components/reusable/ZoomableImage";

const screenWidth = Dimensions.get("window").width;

// create a component
const TeamsPlayers = ({ selectedTeamId, onDataReceived }) => {
  const navigation: any = useNavigation();
  const [teamsData, setTeamsData] = useState([]);
  const [backButton, setBackButton] = useState(false);
  const [playersData, setPlayersData] = useState([]);

  const avatarImage: any = require("../../../../assets/Avtar/Avatar.png");

  // const handleNavigation = () => {
  //   if (selectedTeamId) {
  //     console.log("selectedTeamId", selectedTeamId);
  //     // If tournamentId and matchID are present, you can handle navigation accordingly
  //     setBackButton(false);
  //     // Implement your specific navigation logic here
  //   } else {
  //     // If  matchID are not present, navigate to the teams page
  //     setBackButton(true);
  //   }
  // };

  // This function handles the navigation based on the flags
  const handleNavigation = () => {
    if (selectedTeamId) {
      console.log("selectedTeamId", selectedTeamId);
      // If tournamentId and matchID are present, you can handle navigation accordingly
      setBackButton(false);
      // Implement your specific navigation logic here
    } else {
      // If tournamentId and matchID are not present, navigate to the home page
      setBackButton(true);
      // navigation.navigate("Home");
    }
  };

  // this below functions is used for goto result page on Back button navigation
  const sendDataToTeams = () => {
    // Call the callback function passed from the parent
    setBackButton(onDataReceived);
    console.log("onDataReceived", onDataReceived);
    handleNavigation();
  };

  // const handleNavigation = () => {
  //   // Assuming you're using navigation prop for navigation
  //   navigation.goBack("Teams"); // Navigate back to the previous screen
  // };

  // const sendDataToTeams = () => {
  //   // Call the callback function passed from the parent directly
  //   onDataReceived(true); // Pass whatever data you want here, currently passing true
  //   console.log("Data sent to Teams page");
  //   handleNavigation();
  // };
  useEffect(() => {
    console.log("selectedTeamId", selectedTeamId);
    const subscription = DataStore.observeQuery(TeamRegistrations, (c) =>
      c.id.eq(selectedTeamId)
    ).subscribe(async (snapshot) => {
      const teamsData = snapshot.items;
      console.log("teamsData", teamsData);
      setTeamsData(teamsData);

      try {
        const players = teamsData[0]?.Players;
        if (players) {
          const playersDataPromises = [];
          for await (const player of players) {
            playersDataPromises.push(player.playerMaster);
          }
          const playersData = await Promise.all(playersDataPromises);
          console.log("playersData", playersData);
          setPlayersData(playersData);
        }
      } catch (error) {
        console.error("Error fetching teams players data:", error);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [selectedTeamId, onDataReceived]);
  return (
    <>
      <View
        style={{
          flexDirection: "column",
          gap: 25,
          width: "100%",
          padding: 5,
        }}
      >
        <Pressable onPress={sendDataToTeams} style={styles.btnForBack}>
          <BackIcon name="left" size={16} color="#fff" />
          <Text
            style={{
              color: "#fff",
              fontFamily: "PlusJakartaSansSemiBold",
              fontSize: 12,
            }}
          >
            Back
          </Text>
        </Pressable>
        <View
          style={{
            flexDirection: "row",
            gap: 20,
            width: "100%",
            flexWrap: "wrap",
          }}
        >
          {playersData && playersData.length > 0 ? (
            playersData.map((player, index) => (
              <View style={styles.myTeamItemContainer} key={index}>
                {/* <Image
                  source={player.PhotoURL ? player.PhotoURL : avatarImage}
                  style={styles.scoreTeamLogo}
                  resizeMode="contain"
                /> */}
                <View style={{ width: 45 }}>
                  <ZoomableImage
                    imageSource={{
                      uri: player.PhotoURL ? player.PhotoURL : avatarImage,
                    }}
                    imageStyle={styles.scoreTeamLogo}
                    modalStyle={undefined}
                  />
                </View>
                {/* <H4
                    props={`${player.Name ? player.Name : null} ${
                      player.JerseyNo ? player.JerseyNo : "12"
                    }`}
                  /> */}
                <View style={{ gap: 5, flex: 1 }}>
                  <View style={{ gap: 10, flexDirection: "row" }}>
                    <View style={styles.myTeamItemContent}>
                      <H4
                        props={player.Name ? player.Name : null}
                        style={{ color: colors.white }}
                      />
                    </View>
                    <View style={styles.myTeamItemContent}>
                      <H5
                        props={player.JerseyNo ? player.JerseyNo : null}
                        style={{ color: colors.white }}
                      />
                    </View>
                  </View>
                  <View style={styles.myTeamItemContent}>
                    <H5 props={"Position"} style={{ color: colors.white }} />
                  </View>
                </View>
              </View>
            ))
          ) : (
            <Text>No players data found</Text>
          )}
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  btnForBack: {
    backgroundColor: "#ff5733",
    width: 70,
    height: 32,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    gap: 5,
  },
  myTeamItemContainer: {
    width: "100%",
    flexDirection: "row",
    backgroundColor: colors.royalBlue,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    padding: 8,
    flex: 1,
    maxWidth: "49%",
    minWidth: "40%",
    alignItems: "center",
    gap: 10,
    borderRadius: 10,
  },
  scoreTeamLogo: {
    width: 45,
    height: 45,
    resizeMode: "contain",
    borderRadius: 40,
    flex: undefined,
  },
  myTeamItemContent: {
    flexDirection: "column",
    gap: 5,
  },
});

export default TeamsPlayers;
