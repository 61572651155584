import React, { useEffect, useState } from "react";
import { useNavigation } from "@react-navigation/native";

import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  Platform,
  Pressable,
  TextInput,
  Image,
} from "react-native";
import { H1, H2, H3, H4 } from "../../../components/reusable/TextComponent";
import LeftIcon from "react-native-vector-icons/AntDesign";
import Refresh from "react-native-vector-icons/MaterialIcons";
import { colors } from "../../../components/reusable/colour";
import { LinearGradient } from "expo-linear-gradient";
import { DataStore } from "aws-amplify/datastore";
import {
  GroupStanding,
  MatchMaster,
  MatchTeamMaster,
  StatisticsMaster,
  Tournament,
  UserMaster,
} from "../../../models";
import { StackNavigationProp } from "@react-navigation/stack";
import { MatchStatus } from "../../../models";
import SuccessModal from "../../../components/reusable/SuccessModal";
import AsyncStorage from "@react-native-async-storage/async-storage";
import AdminLayout from "../../../components/include/AdminLayout";
import { RadioButton } from "react-native-paper";
import BackIcon from "react-native-vector-icons/AntDesign";
const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

const StartMatch = ({ route }) => {
  const [MatchObj, setMatchObj] = useState<any>();
  const [SunccessModalMesg, setSuccessModalMesg] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [TournamentObj, setTournamentObj] = useState([]);
  const { MatchID }: any = route.params;
  const [Timeforfirsthalf, setTimeforfirsthalf] = useState("");
  const [SelectedTeamName, setSelectedTeamName] = useState("");
  console.log("StartMatchId", MatchID);
  const [checked, setChecked] = React.useState("first");
  // const navigation: any = useNavigation();
  const navigation = useNavigation<StackNavigationProp<any, "StartMatch">>();

  const handleRedirectScoreApp = async (matchobj) => {
    console.log("helloscore", matchobj);
    //  closeModal();
    const Matchobjectdata = await DataStore.query(MatchMaster, matchobj);
    console.log("MatchTeamdata", Matchobjectdata);
    if (Matchobjectdata) {
      const MatchTeamdataUpdate = MatchMaster.copyOf(
        Matchobjectdata,
        (updated) => {
          updated.HalfTime = Number(Timeforfirsthalf);
          updated.Status = MatchStatus.INPROGRESS;
          return updated;
        }
      );
      console.log("MatchTeamdataUpdate", MatchTeamdataUpdate);
      const ResultofMatchTeam = await DataStore.save(MatchTeamdataUpdate);
      if (ResultofMatchTeam) {
        navigation.navigate("ScoringApp", {
          MatchID: ResultofMatchTeam.id,
          Firsthalf: Timeforfirsthalf,
        });
      }
    }
  };

  const handleClose = () => {
    setModalVisible(false);
  };

  const handleConfirm = () => {
    console.log("modalVisible", modalVisible);
    setModalVisible(!modalVisible);
    handleWakOverConfirm();
  };

  const handleWakOverConfirm = async () => {
    let SelectedTeam;
    console.log("MatchObj", SelectedTeamName, MatchObj);
    if ("Team1" in MatchObj) {
      SelectedTeam = MatchObj.Team2;
    }
    if ("Team2" in MatchObj) {
      SelectedTeam = MatchObj.Team1;
    }
    console.log("SelectedTeam", SelectedTeam);
    if (SelectedTeam) {
      const MatchTeamdataUpdate = MatchTeamMaster.copyOf(
        SelectedTeam,
        (updated) => {
          updated.Score.FirstHalf = 3;

          return updated;
        }
      );
      console.log("MatchTeamdataUpdate", MatchTeamdataUpdate);
      const ResultofMatchTeam = await DataStore.save(MatchTeamdataUpdate);
      if (ResultofMatchTeam) {
        getMatchdataforCompletionMatch(MatchObj.id);
      }
    }
  };

  const handleOk = () => {
    if (SunccessModalMesg == "Match Started Successfully") {
      handleRedirectScoreApp(MatchObj.id);
    }
    setModalVisible(false);
  };

  const GetMatchdata = async (Matchkey) => {
    const subscription = DataStore.observeQuery(MatchMaster, (c) =>
      c.id.eq(Matchkey)
    ).subscribe(async (snapshot) => {
      console.log("snapshot", snapshot);
      const getMatchObj = snapshot.items;
      console.log("getMatchobj", getMatchObj);
      // setUpdatedPlayerMatchTeam(MatchTeamGetObj);
      let Teamfirst = await getMatchObj[0].Team1;
      let TeamSecond = await getMatchObj[0].Team2;
      let Obj = {
        MatchNo:
          getMatchObj[0].MatchNo > 9
            ? `0${getMatchObj[0].MatchNo}`
            : `00${getMatchObj[0].MatchNo}`,
        GroupName: getMatchObj[0].GroupName,
        Team1: Teamfirst,
        Team2: TeamSecond,
        id: getMatchObj[0].id,
        tournamentID: getMatchObj[0].tournamentID,
        Venue: getMatchObj[0].Venue,
        Date: getMatchObj[0].Date,
      };
      getTournament(getMatchObj[0].tournamentID);
      console.log("MatchObj", Obj);
      setMatchObj(Obj);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  const getTournament = (Tounamentid) => {
    const subscription = DataStore.observeQuery(Tournament, (c) =>
      c.id.eq(Tounamentid)
    ).subscribe(async (snapshot) => {
      console.log("snapshot", snapshot);
      const getTournamentdata = snapshot.items;
      console.log("getTournamentdata", getTournamentdata);
      setTournamentObj(getTournamentdata);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    GetMatchdata(MatchID);
  }, [MatchID]);

  const handleClickTime = (value) => {
    console.log("handleClickTime", value);
    setTimeforfirsthalf(value);
  };

  const handleRefresh = () => {
    setTimeforfirsthalf("");
  };

  const handleStartmatch = async (Matchobj) => {
    console.log("Matchobj", Matchobj);
    try {
      const MatchObjGet = await DataStore.query(MatchMaster, Matchobj.id);
      console.log("MatchObjGet", MatchObjGet);
      if (MatchObjGet) {
        const MatchObjUpdate = MatchMaster.copyOf(MatchObjGet, (updated) => {
          updated.Status = MatchStatus.INPROGRESS;
          updated.OnlineScoring = checked == "first" ? true : false;
          updated.FirstHalfStarted = checked == "first" ? false : true;
          updated.PauseFlag = false;
          return updated;
        });
        console.log("MatchObjUpdate", MatchObjUpdate);
        const ResultOfMatchUpdated = await DataStore.save(MatchObjUpdate);
        console.log("ResultOfMatchUpdated", ResultOfMatchUpdated);
        if (ResultOfMatchUpdated) {
          setSuccessModalMesg("Match Started Successfully");
          setModalVisible(true);
        }
      }
    } catch (error) {
      console.error("Error updating tournament:", error);
    }
  };
  const handleWakeOver = (Team) => {
    console.log("handleWakeOver", Team);
    setModalVisible(true);
    setSelectedTeamName(Team);
    setSuccessModalMesg("You won't be able to revert this!");
  };

  const getTournamentObj = async (key) => {
    console.log("key", key);
    let Tournamentdata = DataStore.query(Tournament, key);
    return Tournamentdata;
  };

  const getUserdata = async () => {
    let userDetails = await AsyncStorage.getItem("UserDetail");
    // console.log("userDetails", JSON.parse(userDetails));
    let data = await DataStore.query(UserMaster, userDetails);
    // setUserDetails(JSON.parse(userDetails));
    return data;
  };

  const getMatchdataforCompletionMatch = async (MatchKey) => {
    console.log("MatchKey", MatchKey);

    let matchObj = await DataStore.query(MatchMaster, MatchKey);

    console.log("getMatchdataforCompletionMatch", matchObj);
    await handleCompleteMatch(matchObj);
  };

  const handleCompleteMatch = async (getMatchObj) => {
    console.log("handleCompleteMatch");

    let Statisticsarray = [];
    let Standingarr = [];

    let Tournamentdata: any = await getTournamentObj(getMatchObj.tournamentID);
    console.log("Tournamentdata", Tournamentdata);
    let Registrationsdata = await Tournamentdata.Registrations.toArray();
    let Registrationfinaldata = await Promise.all(Registrationsdata);
    console.log("Registrationfinaldata", Registrationfinaldata);

    // setUpdatedPlayerMatchTeam(MatchTeamGetObj);
    let Teamfirst = await getMatchObj.Team1;
    let TeamSecond = await getMatchObj.Team2;
    let RegistrationTeam1 = Registrationfinaldata.filter(
      (ele) => ele.TeamName == Teamfirst.TeamName.trim()
    );
    let RegistrationTeam2 = Registrationfinaldata.filter(
      (ele) => ele.TeamName == TeamSecond.TeamName.trim()
    );
    let Team1GoalPromise = await Teamfirst.Goals.toArray();
    let Team1Goaldata = await Promise.all(Team1GoalPromise);
    let Team2GoalPromise = await TeamSecond.Goals.toArray();
    let Team2Goaldata = await Promise.all(Team2GoalPromise);
    let Team1CardPromise = await Teamfirst.Cards.toArray();
    let Team1Carddata = await Promise.all(Team1CardPromise);
    let Team2CardPromise = await TeamSecond.Cards.toArray();
    let Team2Carddata = await Promise.all(Team2CardPromise);
    let Team1SubsitutePromise = await Teamfirst.Substitutions.toArray();
    let Team1Subsitutedata = await Promise.all(Team1SubsitutePromise);
    let Team2SubsitutePromise = await TeamSecond.Substitutions.toArray();
    let Team2Subsitutedata = await Promise.all(Team2SubsitutePromise);
    console.log("Team1Subsitutedata", Team1Subsitutedata, Team2Subsitutedata);

    let SubsitutedataTeam1 = Team1Subsitutedata.map(async (val) => ({
      ...val,
      Player: val.OutPlayer,
      Penalty: 0,
      YellowCard: 0,
      RedCard: 0,
    }));

    let Team1AllSubsitutedata = await Promise.all(SubsitutedataTeam1);
    let SubsitutedataTeam2 = Team2Subsitutedata.map(async (val) => ({
      ...val,
      Player: val.OutPlayer,
    }));
    let Team2AllSubsitutedata = await Promise.all(SubsitutedataTeam2);
    console.log(
      "SubsitutedataTeam1",
      Team1AllSubsitutedata,
      Team2AllSubsitutedata
    );

    const Team1GoalCardList = [
      ...Team1Goaldata,
      ...Team1Carddata,
      ...Team1AllSubsitutedata,
    ];
    const Team2GoalCardList = [
      ...Team2Goaldata,
      ...Team2Carddata,
      ...Team2AllSubsitutedata,
    ];
    console.log("Teamfirst", Teamfirst, TeamSecond);
    let Team1data = Teamfirst.Players.map((ele) => ({
      ...ele,
      Player: {
        PlayerId: ele.PlayerId,
      },
      Time: "00",
    }));
    let Team2data = TeamSecond.Players.map((ele) => ({
      ...ele,
      Player: {
        PlayerId: ele.PlayerId,
      },
      Time: "00",
    }));

    const filteredArray1 = Team1data.filter(
      (obj1) =>
        !Team1GoalCardList.some(
          (obj2) => obj1.PlayerId === obj2.Player.PlayerId
        )
    );
    const statesdataforTeam1 = [...filteredArray1, ...Team1GoalCardList];
    const filteredArray2 = Team2data.filter(
      (obj1) =>
        !Team2GoalCardList.some(
          (obj2) => obj1.PlayerId === obj2.Player.PlayerId
        )
    );
    const statesdataforTeam2 = [...filteredArray2, ...Team2GoalCardList];

    let FirstTeamGoalCarddata = statesdataforTeam1.map(async (val) => ({
      ...val,
      // Playerdata: await getListofplayer(val.Player),
      Team: Teamfirst,
      IDTournament: getMatchObj.tournamentID,
    }));
    let FirstTeamGoalCard = await Promise.all(FirstTeamGoalCarddata);
    console.log("FirstTeamGoaldata", FirstTeamGoalCard);

    let SecondTeamGoalCarddata = statesdataforTeam2.map(async (val) => ({
      ...val,
      // Playerdata: await getListofplayer(val.Player),
      Team: Teamfirst,
      IDTournament: getMatchObj.tournamentID,
    }));
    let SecondTeamGoalCard = await Promise.all(SecondTeamGoalCarddata);
    console.log("SecondTeamGoalCard", SecondTeamGoalCard);
    const finalstatesarry = [...FirstTeamGoalCard, ...SecondTeamGoalCard];
    console.log("statesdataforTeam2", finalstatesarry);
    let AllTeamGoalCardSublist = finalstatesarry.map((ele) => ({
      ...ele,
      Goal: Object.keys(ele).includes("Penalty")
        ? Object.values(ele).includes(false)
          ? 1
          : 0
        : 0,
      Yellow: Object.keys(ele).includes("CardType")
        ? Object.values(ele).includes("Yellow")
          ? 1
          : 0
        : 0,
      Red: Object.keys(ele).includes("CardType")
        ? Object.values(ele).includes("Red")
          ? 1
          : 0
        : 0,
    }));
    console.log("AllTeamGoalCardSublist", AllTeamGoalCardSublist);

    let Userdata = await getUserdata();
    console.log("Userdata", Userdata);

    AllTeamGoalCardSublist &&
      AllTeamGoalCardSublist.length > 0 &&
      AllTeamGoalCardSublist.map(async (ele, idx) => {
        // let ExistingPlayer = await getStatisticsPlayerdata(ele);

        // console.log("ExistingPlayer", ExistingPlayer);
        if (Statisticsarray && Statisticsarray.length > 0) {
          console.log("Object", ele);
          const index = Statisticsarray.findIndex(
            (val) => val.PlayerId == ele.Player.PlayerId
          );
          if (index >= 0) {
            Statisticsarray[index].Goals =
              Statisticsarray[index].Goals + ele.Goal;
            Statisticsarray[index].RedCards =
              Statisticsarray[index].RedCards + ele.Red;
            Statisticsarray[index].YellowCards =
              Statisticsarray[index].YellowCards + ele.Yellow;
          } else {
            Statisticsarray.push({
              TeamName: ele.Team.TeamName,
              TeamLogo: ele.Team.LogoURL,
              CreatedBy: Userdata.Name,
              LastUpdatedBy: Userdata.Name,
              Goals: ele.Goal,
              RedCards: ele.Red,
              YellowCards: ele.Yellow,
              Games: 1,
              Minutes: Number(ele.Time),
              tournamentID: ele.IDTournament,
              PlayerId: ele.Player.PlayerId,
            });
          }
        } else {
          Statisticsarray.push({
            TeamName: ele.Team.TeamName,
            TeamLogo: ele.Team.LogoURL,
            CreatedBy: Userdata.Name,
            LastUpdatedBy: Userdata.Name,
            Goals: ele.Goal,
            RedCards: ele.Red,
            YellowCards: ele.Yellow,
            Games: 1,
            Minutes: Number(ele.Time),
            tournamentID: ele.IDTournament,
            PlayerId: ele.Player.PlayerId,
          });
        }
      });
    console.log("Statisticsarray", Statisticsarray);
    console.log("RegistrationTeam1", RegistrationTeam1);
    Standingarr.push(Teamfirst, TeamSecond);
    let Team1Result =
      Teamfirst.Score.FirstHalf +
      Teamfirst.Score.SecondHalf +
      Teamfirst.Score.PenaltyShootout +
      Teamfirst.Score.ExtraTime;
    let Team2Result =
      TeamSecond.Score.FirstHalf +
      TeamSecond.Score.SecondHalf +
      TeamSecond.Score.PenaltyShootout +
      TeamSecond.Score.ExtraTime;

    let Team1Standing = {
      Against: Team2Result,
      For: Team1Result,
      Form:
        Team1Result > Team2Result
          ? "W"
          : Team1Result == Team2Result
          ? "D"
          : "L",
      Played: 1,
      Won: Team1Result > Team2Result ? 1 : 0,
      Drawn: Team1Result == Team2Result ? 1 : 0,
      Difference: Team1Result - Team2Result,
      Lost: Team1Result < Team2Result ? 1 : 0,
    };
    let Team2Standing = {
      Against: Team1Result,
      For: Team2Result,
      Form:
        Team2Result > Team1Result
          ? "W"
          : Team2Result == Team1Result
          ? "D"
          : "L",
      Played: 1,
      Won: Team2Result > Team1Result ? 1 : 0,
      Drawn: Team2Result == Team1Result ? 1 : 0,
      Difference: Team2Result - Team1Result,
      Lost: Team2Result < Team1Result ? 1 : 0,
    };

    let finalstanding = await Standingarr.map((ele) => ({
      ...ele,
      ActualTeamdata:
        ele.TeamName.trim() == RegistrationTeam1[0].TeamName.trim()
          ? RegistrationTeam1[0]
          : RegistrationTeam2[0],
      TeamStanding:
        ele.TeamName == Teamfirst.TeamName ? Team1Standing : Team2Standing,
    }));
    console.log("Standingarr", finalstanding, getMatchObj.groupsmasterID);

    // RegistrationTeam1
    finalstanding &&
      finalstanding.length > 0 &&
      finalstanding.forEach(async (obj) => {
        console.log("StandingObj", obj);
        let ExistingTeam = await getStandingData(
          obj.ActualTeamdata.id,
          getMatchObj.groupsmasterID
        );
        console.log("ExistingTeam", ExistingTeam);
        if (ExistingTeam && ExistingTeam.length > 0) {
          try {
            const Groupstandingresult = await GroupStanding.copyOf(
              ExistingTeam[0],
              (updated) => {
                updated.Against = updated.Against + obj.TeamStanding.Against;
                updated.For = updated.For + obj.TeamStanding.For;
                updated.Form.unshift(obj.TeamStanding.Form);
                updated.Played = updated.Played + obj.TeamStanding.Played;
                updated.Points =
                  updated.Points +
                  (obj.TeamStanding.Form == "W"
                    ? Tournamentdata.WinPoints
                    : obj.TeamStanding.Form == "D"
                    ? Tournamentdata.DrawPoints
                    : Tournamentdata.LossPoints);
                updated.Won = updated.Won + obj.TeamStanding.Won;
                updated.Drawn = updated.Drawn + obj.TeamStanding.Drawn;
                updated.Difference =
                  updated.Difference + obj.TeamStanding.Difference;
                updated.Lost = updated.Lost + obj.TeamStanding.Lost;

                return updated;
              }
            );
            console.log("Groupstandingresult", Groupstandingresult);
            const GroupStandingData = await DataStore.save(Groupstandingresult);
            console.log("GroupStandingData", GroupStandingData);
          } catch (error) {
            console.error("Error updating GroupStanding:", error);
          }
        } else {
          try {
            // console.log("NewGroupresult", obj);

            let finalObj: any = {
              TeamName: obj.TeamName,
              TeamLogo: "",
              TeamShortName:
                obj.TeamShortName !== null ? obj.TeamShortName : "",
              Played: obj.TeamStanding.Played,
              Won: obj.TeamStanding.Won,
              Lost: obj.TeamStanding.Lost,
              Drawn: obj.TeamStanding.Drawn,
              Position: 0,
              CreatedBy: Userdata.Name,
              LastUpdatedBy: Userdata.Name,
              For: obj.TeamStanding.For,
              Form: [obj.TeamStanding.Form],
              Against: obj.TeamStanding.Against,
              Points:
                obj.TeamStanding.Form == "W"
                  ? Tournamentdata.WinPoints
                  : obj.TeamStanding.Form == "D"
                  ? Tournamentdata.DrawPoints
                  : Tournamentdata.LossPoints,
              Difference: obj.TeamStanding.Difference,
              ChangeInPosition: "",
              TeamId: obj.id,
              groupsmasterID: getMatchObj.groupsmasterID,
            };
            console.log("NewGroupresult", finalObj);
            const Groupresult = await DataStore.save(
              new GroupStanding(finalObj)
            );
          } catch (error) {
            console.error("Error updating StandingNew:", error);
          }
        }
      });

    Statisticsarray &&
      Statisticsarray.length > 0 &&
      Statisticsarray.forEach(async (ele) => {
        let ExistingPlayer = await getStatisticsPlayerdata(ele);
        console.log("ExistingPlayer", ExistingPlayer);
        if (ExistingPlayer && ExistingPlayer.length > 0) {
          console.log("2ExistingPlayer", ExistingPlayer);
          try {
            const UpdatePlayerstates = StatisticsMaster.copyOf(
              ExistingPlayer[0],
              (updated) => {
                updated.Goals = updated.Goals + ele.Goals;
                updated.RedCards = updated.RedCards + ele.RedCards;
                updated.YellowCards = updated.YellowCards + ele.YellowCards;
                updated.Games = updated.Games + 1;
                return updated;
              }
            );
            console.log("UpdatePlayerstates", UpdatePlayerstates);
            const ResultplayerStates = await DataStore.save(UpdatePlayerstates);
            console.log("ResultplayerStates", ResultplayerStates);
            if (ResultplayerStates) {
            }
          } catch (error) {
            console.error("Error updating Statistics:", error);
          }
        } else {
          console.log("NewPlayer", ele);
          try {
            const StatesResp = await DataStore.save(new StatisticsMaster(ele));
          } catch (error) {}
        }
      });

    try {
      const MastchObjdata = await DataStore.query(MatchMaster, MatchObj.id);
      const UpdatedMatchRecord = MatchMaster.copyOf(
        MastchObjdata,
        (updated) => {
          updated.Status = MatchStatus.COMPLETED;
          return updated;
        }
      );
      console.log("UpdatedMatchRecord", UpdatedMatchRecord);
      const ResultUpdatedMatch = await DataStore.save(UpdatedMatchRecord);
      console.log("ResultUpdatedMatch", ResultUpdatedMatch);
      if (ResultUpdatedMatch) {
        setSuccessModalMesg("Match Completed");
        setModalVisible(true);
        navigation.replace("ManageTournament");
      }
    } catch (error) {
      console.error("Error updating Statistics:", error);
    }
  };

  const getStandingData = async (Teamid, groupid) => {
    const getdata = await DataStore.query(GroupStanding, (standing) =>
      standing.and((standing) => [
        standing.groupsmasterID.eq(groupid),
        standing.TeamId.eq(Teamid),
      ])
    );
    console.log("hellogroupstanding", getdata);
    return getdata;
  };

  const getStatisticsPlayerdata = async (playerobj) => {
    let Playerid = playerobj.PlayerId;
    const getdata = await DataStore.query(StatisticsMaster, (statistics) =>
      statistics.and((statistics) => [
        statistics.tournamentID.eq(playerobj.tournamentID),
        statistics.PlayerId.eq(Playerid),
      ])
    );
    return getdata;
  };

  const handleBack = () => {
    navigation.goBack();
  };

  return (
    <>
      {/* {Platform.OS !== "web" && (
        <AppBar title={""} showBackButton={true} onBackPress={handleBack} />
      )} */}
      <AdminLayout>
        <View
          style={{
            marginTop: Platform.OS === "web" && screenWidth > 760 ? -10 : 0,
          }}
        >
          {MatchObj && Object.keys(MatchObj).length > 0 ? (
            <View
              style={{
                alignItems:
                  Platform.OS === "web" && screenWidth > 760
                    ? "center"
                    : undefined,

                // paddingLeft: Platform.OS === "web" && screenWidth > 760 ? 220 : 0,
              }}
            >
              <View
                style={{
                  backgroundColor: colors.royalBlue,
                  minHeight:
                    Platform.OS === "web" && screenWidth > 760
                      ? screenHeight
                      : "100%",
                  maxWidth:
                    Platform.OS === "web" && screenWidth > 760 ? 1000 : "100%",
                  width: "100%",
                }}
              >
                <LinearGradient
                  style={styles.scoreTopWrapper}
                  colors={["#FF5733", "#012879"]}
                  start={{ x: 0, y: 1 }}
                  end={{ x: 1, y: 1 }}
                >
                  {/* <View style={styles.scoreTopWrapper}> */}
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    {Platform.OS !== "web" && (
                      <BackIcon
                        name="left"
                        size={16}
                        color={colors.white}
                        onPress={handleBack}
                      />
                    )}
                    {TournamentObj && TournamentObj.length > 0 ? (
                      <H2
                        props={TournamentObj[0].Name}
                        style={{ color: "#fff" }}
                      />
                    ) : null}
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: 15,
                      paddingBottom: 5,
                      borderBottomColor: "#fffafa45",
                      borderBottomWidth: 1,
                    }}
                  >
                    <H4 props={"M010"} style={{ color: "#fff" }}></H4>
                    <H4 props={"Group A"} style={{ color: "#fff" }}></H4>
                  </View>
                  <View style={{ paddingVertical: 10 }}>
                    <Text
                      style={{
                        textAlign: "center",
                        color: "#fff",
                        fontFamily: "PlusJakartaSansRegular",
                        fontSize: 14,
                      }}
                    >
                      {MatchObj.Venue &&
                        Object.keys(MatchObj.Venue).length > 0 &&
                        MatchObj.Venue.Address}
                    </Text>
                    <Text
                      style={{
                        textAlign: "center",
                        color: "#fff",
                        fontFamily: "PlusJakartaSansMedium",
                        fontSize: 16,
                      }}
                    >
                      {MatchObj.Date}
                    </Text>
                  </View>
                  <View style={styles.scoreTeamWrapper}>
                    <View style={styles.scoreTeamContainer}>
                      <Image
                        // source={require("../../../../assets/home/logo.png")}
                        source={{ uri: MatchObj.Team1.LogoURL }}
                        style={styles.scoreTeamLogo}
                        resizeMode="contain"
                      />
                      <Text style={styles.scoreTeamText}>
                        {MatchObj.Team1.TeamName}
                      </Text>
                    </View>
                    <H2 props={"VS"} style={{ color: "#fff" }} />
                    <View style={styles.scoreTeamContainer}>
                      <Image
                        // source={require("../../../../assets/home/logo.png")}
                        source={{ uri: MatchObj.Team2.LogoURL }}
                        style={styles.scoreTeamLogo}
                        resizeMode="contain"
                      />
                      <Text style={styles.scoreTeamText}>
                        {MatchObj.Team2.TeamName}
                      </Text>
                    </View>
                  </View>
                  {/* </View> */}
                </LinearGradient>
                <View style={styles.scoreContentWrapper}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",

                      gap: Platform.OS == "web" && screenWidth > 760 ? 40 : 5,
                    }}
                  >
                    <Text style={styles.extRegHeading}>Online Scoring</Text>
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <RadioButton
                        value="first"
                        status={checked === "first" ? "checked" : "unchecked"}
                        onPress={() => setChecked("first")}
                      />
                      <Text style={{ color: colors.white }}>Yes</Text>
                    </View>
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <RadioButton
                        value="second"
                        status={checked === "second" ? "checked" : "unchecked"}
                        onPress={() => setChecked("second")}
                      />
                      <Text style={{ color: colors.white }}>No</Text>
                    </View>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "center",
                        fontFamily: "PlusJakartaSansSemiBold",
                        fontSize: 15,
                        color: colors.white,
                      }}
                    >
                      Please enter time for each half
                    </Text>
                    <Text
                      style={{
                        textAlign: "center",
                        fontFamily: "PlusJakartaSansSemiBold",
                        fontSize: 13,
                        color: colors.white,
                      }}
                    >
                      Minutes
                    </Text>

                    {/* <View style={styles.setTimerWrapper}> */}
                    <Pressable
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: colors.bgColor,
                        borderRadius: 10,
                        padding: 5,
                        width: 100,
                        height: 40,
                        shadowColor: "#000000",
                        shadowOffset: { width: 0, height: 0 },
                        shadowOpacity: 0.2,
                        shadowRadius: 10,
                        elevation: 5,
                      }}
                    >
                      <TextInput
                        style={styles.addTimer}
                        placeholder={"00"}
                        value={Timeforfirsthalf}
                        onChangeText={handleClickTime}
                        placeholderTextColor={colors.darkGray}
                      />
                    </Pressable>
                    <Pressable onPress={() => handleRefresh()}>
                      <View style={styles.timerRefreshButton}>
                        <Refresh name="refresh" size={24} color="#8F8F8F" />
                      </View>
                    </Pressable>
                    {Timeforfirsthalf !== "" ? (
                      <Pressable
                        onPress={() => handleStartmatch(MatchObj)}
                        style={{
                          backgroundColor: colors.aqua,
                          width: 250,
                          height: 50,
                          borderRadius: 5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <H2
                          props={"Start Match"}
                          style={{ color: colors.royalBlue }}
                        />
                      </Pressable>
                    ) : null}
                    <H2 props={"OR"} style={{ color: colors.white }} />
                    <Text
                      style={{
                        textAlign: "center",
                        fontFamily: "PlusJakartaSansSemiBold",
                        fontSize: 15,
                        color: colors.white,
                      }}
                    >
                      If Walkover, Select the Team to Proceed
                    </Text>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 10,
                        width: "100%",
                        marginTop: 10,
                      }}
                    >
                      <Pressable
                        style={
                          SelectedTeamName == "Team1"
                            ? styles.scoreTeamContainer2Color
                            : styles.scoreTeamContainer2
                        }
                        onPress={() => handleWakeOver("Team1")}
                      >
                        <Image
                          // source={require("../../../../assets/home/logo.png")}
                          source={{ uri: MatchObj.Team1.LogoURL }}
                          style={styles.scoreTeamLogo2}
                          resizeMode="contain"
                        />
                        <Text
                          style={
                            SelectedTeamName == "Team1"
                              ? styles.scoreTeamText2White
                              : styles.scoreTeamText2
                          }
                        >
                          {MatchObj.Team1.TeamName}
                        </Text>
                      </Pressable>
                      <Pressable
                        style={
                          SelectedTeamName == "Team2"
                            ? styles.scoreTeamContainer2Color
                            : styles.scoreTeamContainer2
                        }
                        onPress={() => handleWakeOver("Team2")}
                      >
                        <Image
                          // source={require("../../../../assets/home/logo.png")}
                          source={{ uri: MatchObj.Team2.LogoURL }}
                          style={styles.scoreTeamLogo2}
                          resizeMode="contain"
                        />
                        <Text
                          style={
                            SelectedTeamName == "Team2"
                              ? styles.scoreTeamText2White
                              : styles.scoreTeamText2
                          }
                        >
                          {MatchObj.Team2.TeamName}
                        </Text>
                      </Pressable>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          ) : null}
        </View>
        {/* </View> */}
        {/* </View> */}
        {modalVisible == true ? (
          <SuccessModal
            visibleModal={modalVisible}
            handleClose={
              SunccessModalMesg == "Match Started Successfully"
                ? undefined
                : handleClose
            }
            SuccessMessage={SunccessModalMesg}
            handleConfirm={
              SunccessModalMesg == "Match Started Successfully"
                ? undefined
                : handleConfirm
            }
            handleOk={
              SunccessModalMesg == "Match Started Successfully"
                ? handleOk
                : undefined
            }
          />
        ) : null}
      </AdminLayout>
    </>
  );
};

const styles = StyleSheet.create({
  scoreTopWrapper: {
    width: "100%",
    paddingTop: 30,
    paddingBottom: 75,
    paddingHorizontal: 10,
  },
  scoreContentWrapper: {
    width: "100%",
    height: Platform.OS === "web" && screenWidth > 760 ? 0 : 570,
    paddingBottom: Platform.OS === "web" && screenWidth > 760 ? 50 : 0,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    marginTop: -40,
    backgroundColor: colors.royalBlue,
    padding: 10,
  },
  scoreTeamLogo: {
    width: 70,
    height: 70,
    resizeMode: "contain",
    borderRadius: 40,
  },
  scoreTeamLogo2: {
    width: 45,
    height: 45,
    resizeMode: "contain",
    borderRadius: 40,
  },
  scoreTeamText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 14,
  },
  scoreTeamText2: {
    color: colors.white,
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 14,
  },
  scoreTeamText2White: {
    color: colors.white,
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 14,
  },
  scoreTeamWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 20,
    justifyContent: "center",
  },
  extRegHeading: {
    paddingBottom: 10,
    fontFamily: "PlusJakartaSansBold",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 14 : 12,
    color: colors.white,
  },
  scoreTeamContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  scoreTeamContainer2: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    borderRadius: 5,
    backgroundColor: colors.bgColor,
    padding: Platform.OS === "web" && screenWidth > 760 ? 5 : 15,
  },
  scoreTeamContainer2Color: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    borderRadius: 5,
    backgroundColor: colors.royalBlueColour,
    padding: Platform.OS === "web" && screenWidth > 760 ? 5 : 15,
  },
  setTimerWrapper: {
    backgroundColor: "#fff",
    borderRadius: 10,
    padding: 4,
    width: 140,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
  },
  timerRefreshButton: {
    backgroundColor: "#fff",
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    padding: 3,
    width: 30,
    height: 30,
    borderRadius: 3,
    display: "flex",
  },
  addTimer: {
    marginBottom: 0,
    width: "100%",
    height: 40,
    fontSize: 24,
    fontFamily: "PlusJakartaSansBold",
    textAlign: "center",
    color: colors.white,
  },
});

export default StartMatch;
