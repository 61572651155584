import React, { useContext, useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Pressable,
  TextInput,
  Platform,
  Image,
} from "react-native";
import { colors } from "../../../../components/reusable/colour";
import UploadImage from "../../../../components/reusable/UploadImage";
import AdminLayout from "../../../../components/include/AdminLayout";
import { ScrollView } from "react-native-gesture-handler";
import PlayerDetailsModal from "../../../../components/reusable/PlayerDetailsModal";
import { getAuthenticatedUser } from "../../../../api/AmplifyUserAPI";
import { getUserMasterData } from "../../../../api/UserMasterAPI";
import { DataStore } from "aws-amplify/datastore";
import { uploadData } from "aws-amplify/storage";
import { AuthenticationContext } from "../../../../authentication/Authentication";
import AddIcon from "react-native-vector-icons/Feather";
import TrashIcon from "react-native-vector-icons/Ionicons";
import {
  CoachMaster,
  PlayerMaster,
  Squad,
  SquadCoachMaster,
  SquadPlayerMaster,
  SquadUserMaster,
  UserMaster,
} from "../../../../models";
import { getCoachMasterData } from "../../../../api/CoachMasterAPI";
import { useNavigation } from "@react-navigation/native";
import AppBar from "../../../../components/include/AppBar";
import awsmobile from "./../../../../aws-exports";
import { ErrorModal } from "../../../../components/reusable/AreYouSure";
import { handlePlayerSerialNumber } from "../../../../components/reusable/CreatePlayerSerialNumber";

const EditTeamMob = () => {
  const defaultLogo: any = require("../../../../../assets/default/defaultLogo.png");
  const navigation: any = useNavigation();
  const [birthDate, setBirthDate] = useState("");
  const bucketName = awsmobile.aws_user_files_s3_bucket;
  const [PlayerDetailsModalVisible, setPlayerDetailsModalVisible] =
    useState(false);

  const [playersList, setPlayersList]: any = useState([
    {
      Name: "",
      DOB: "",
      JerseyNo: "",
      MobileNo: "",
      PhotoURL: "",
      UUID: "",
      playerSummaryId: "",
      coachMasterId: "",
    },
  ]);

  const [teamName, setTeamName] = useState("");
  const [teamShortName, setTeamShortName] = useState("");
  const [coachName, setCoachName] = useState("");
  const [coachMobileNo, setCoachMobileNo] = useState("");
  const [adminName, setAdminName] = useState("");
  const [adminMobileNo, setAdminMobileNo] = useState("");
  const [admin2UUID, setadmin2UUID] = useState("");
  const [teamLogoURL, setTeamLogoURL] = useState("");
  const [selectedplayerindex, setselectedplayerindex]: any = useState();
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorModalMesg, setErrorModalMesg] = useState("");

  const [profilePicsArray, setProfilePicsArray] = useState([
    {
      Pic: null,
      UploadedPercentage: "",
      Url: "",
    },
  ]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [uuid, setUUID] = useState("");

  let playerSerialNumber: any;

  const handlePlayerDetailsModalVisible = () => {
    // console.log("data", data, index);
    // setselectedplayerindex(index);
    setPlayerDetailsModalVisible(true);
    console.log("sasa");
  };
  const closePlayerDetailsModal = () => {
    setPlayerDetailsModalVisible(false);
  };
  let admin1UserData: any;

  const { createAdminUser, getAdminUser } = useContext(AuthenticationContext);

  async function uploadLogoFileToStorage(uri) {
    try {
      const response = await fetch(uri);
      const blob = await response.blob();
      await uploadData({
        key: "team/" + teamName.replaceAll(" ", "_") + "/logo.png",
        data: blob,
      });
      let url =
        `https://${bucketName}.s3.ap-south-1.amazonaws.com/public/team/` +
        teamName.replaceAll(" ", "_") +
        "/logo.png";
      setTeamLogoURL(url);
    } catch (err) {
      console.log("Error uploading file:", err);
    }
  }
  const handleSetProfilePic = (e, field, index) => {
    console.log("targetvalue");
    console.log("We are in handle set PP");

    if (field === "Pic") {
      const { Pic, value } = e;
      const list = [...profilePicsArray];
      console.log("Pic : ", e);
      list[index].Pic = e;
      console.log("list", list);
      console.log("We are in Pic");
      setProfilePicsArray(list);
    }
    if (field === "UploadedPercentage") {
      console.log("Target", e);
      const list = [...profilePicsArray];

      const { UploadedPercentage, value } = e;
      console.log("Li", list[index]);
      list[index].UploadedPercentage = UploadedPercentage;
      console.log("list", list);
      setProfilePicsArray(list);
    } else {
      const { Url, value } = e;
      const list = [...profilePicsArray];

      list[index].Url = e;
      console.log("list", list);
      console.log("We are in handle set Url");
      setProfilePicsArray(list);
    }
  };

  async function uploadProfilePicToStorage(uri, index) {
    const number = Math.floor(100000 + Math.random() * 900000)
      .toString()
      .substring(0, 4);
    try {
      const response = await fetch(uri);
      const blob = await response.blob();
      await uploadData({
        key: "userPhotos/" + number + ".jpg",
        data: blob,
        options: {
          onProgress: ({ transferredBytes, totalBytes }) => {
            if (totalBytes) {
              console.log(
                `Upload progress ${Math.round(
                  (transferredBytes / totalBytes) * 100
                )} %`
              );
            }
            let percentage = Math.round((transferredBytes / totalBytes) * 100);
            let url =
              `https://${bucketName}.s3.ap-south-1.amazonaws.com/public/userPhotos/` +
              number +
              ".jpg";
            console.log("Percentage : ", percentage);

            if (percentage === 100) {
              console.log("Percentage is 100");
              handleInputChange(url, "PhotoURL", index);
            }
            handleSetProfilePic(percentage, "UploadedPercentage", index);
            handleSetProfilePic(url, "Url", index);
          },
        },
        // contentType: 'image/png' // contentType is optional
      });
    } catch (err) {
      console.log("Error uploading file:", err);
    }
  }
  const handleImageSelection = (uri, kindOfFile, index) => {
    if (kindOfFile == "logo") {
      setTeamLogoURL(uri);
      uploadLogoFileToStorage(uri);
    } else if (kindOfFile == "profilePic") {
      uploadProfilePicToStorage(uri, index);
    }
  };

  const handleInputChange = (e, field, index) => {
    console.log("Field : ", field);
    console.log(e);
    const list = [...playersList];

    if (field === "Name") {
      const { Name, value } = e;

      list[index].Name = e;
      console.log("list", list);
      setPlayersList(list);
    } else if (field === "DOB") {
      const { DOB, value } = e;

      list[index].DOB = e;
      console.log("list", list);
      setPlayersList(list);
    } else if (field === "JerseyNo") {
      const { JerseyNo, value } = e;

      list[index].JerseyNo = e;
      console.log("list", list);
      setPlayersList(list);
    } else if (field === "MobileNo") {
      const { MobileNo, value } = e.target;

      list[index].MobileNo = e.target.value;
      console.log("list", list);
      setPlayersList(list);
    } else if (field === "PhotoURL") {
      const { PhotoURL, value } = e;
      list[index].PhotoURL = e;
      console.log("list", list);
      setPlayersList(list);
    } else if (field === "UUID") {
      const { UUID, value } = e;
      const list = [...playersList];
      list[index].UUID = e;
      console.log("list", list);
      setPlayersList(list);
    }
  };

  const cognitoUserDetails = async (phoneNumber) => {
    const response = await getAdminUser(phoneNumber);
    if (response) {
      // console.log("UserDetails : ", response.statusCode);
    }

    return response;
  };
  const addUser = async (phoneNumber) => {
    let finalResponse;
    const userAttribute = [
      {
        Name: "phone_number" /* required */,
        Value: phoneNumber,
      },
    ];
    const response = await createAdminUser(phoneNumber, userAttribute).then(
      async (data) => {
        console.log("CreateUserResponse : ", data);
        finalResponse = data;
      }
    );
    console.log("Respa", finalResponse);
    return finalResponse;
  };

  const generateSerialNumber = async () => {
    const originLocation = window.location.origin;
    console.log("origin location", originLocation);

    try {
      const serialNumber = await handlePlayerSerialNumber(originLocation);
      // setPlayerSerialNumber(serialNumber);
      playerSerialNumber = serialNumber;
      console.log("Generated Player Serial Number:", serialNumber);
    } catch (error) {
      console.error("Error generating player serial number", error);
    }
  };

  const handleFormSubmit = (formData: any) => {
    // Check if selectedplayerindex is valid
    if (selectedplayerindex >= 0 && selectedplayerindex < playersList.length) {
      // Create a copy of formData
      // let data = { ...formData };
      console.log("data", formData);
      // Extract the necessary fields and update the player object in playersList
      const updatedPlayersList = [...playersList];
      updatedPlayersList[selectedplayerindex] = {
        ...updatedPlayersList[selectedplayerindex],
        Name: formData.Name,
        DOB:
          formData.DOB && typeof formData.DOB === "string"
            ? formData.DOB.slice(0, 10)
            : "",
        JerseyNo: formData.JerseyNo,
        MobileNo: formData.MobileNo,
        PhotoURL: formData.PhotoURL,
      };
      console.log("updatedPlayersList", updatedPlayersList);
      // Update the state with the new playersList
      setPlayersList(updatedPlayersList);
      console.log(
        "Form submitted:",
        formData.DOB && typeof formData.DOB === "string"
          ? formData.DOB.slice(0, 10)
          : "",
        selectedplayerindex,
        formData
      );
    } else {
      console.error("Invalid selected player index or players list.");
    }
  };

  const handleOnSubmit = async () => {
    let coachData,
      admin2UserData,
      coachUserMaster,
      playerUserMaster,
      coachSummary,
      coachUUID,
      adminUUID,
      userMasterCoach,
      userMasterAdmin,
      resCognitoAdmin1,
      resCognitoAdmin2,
      resAdmin1UUID,
      squad,
      squadUserMaster,
      squadCoachMaster,
      squadPlayerMaster,
      resAdmin2UUID,
      teamRegistrations,
      teamRegistrationsPlayerMaster,
      userMasterTournament;
    //addAdminData2

    resCognitoAdmin2 = await cognitoUserDetails("+91" + adminMobileNo);

    if (resCognitoAdmin2.statusCode === 400) {
      const resAddAdmin2 = await addUser("+91" + adminMobileNo);
      console.log("Resp : ", resAddAdmin2);
      resAdmin2UUID = resAddAdmin2.User.Username;
      console.log("Admin UUID ", resAdmin2UUID);
    } else {
      resAdmin2UUID = resCognitoAdmin2.Username;
      console.log("Else user data uuid : ", resAdmin2UUID);
    }
    getUserMasterData(resAdmin2UUID).then(async (response) => {
      if (response) {
        console.log("Admin Data", response);
        admin2UserData = response;
      } else {
        console.log("No admin data found");
        try {
          userMasterAdmin = await DataStore.save(
            new UserMaster({
              UUID: resAdmin2UUID,
              Name: adminName.trim(),
              ContactNo: Number(adminMobileNo),
              EmailId: "",
              PhotoURL: "",
              CreatedBy: admin1UserData.Name,
              LastUpdatedBy: admin1UserData.Name,
              RoleCoach: false,
              RolePlayer: false,
            })
          );
          console.log("UserMaster Admin: ", userMasterAdmin);
        } catch (e) {
          console.log("Error : ", e);
        }
      }
    });
    const admin2Obj = {
      UUID: resAdmin2UUID,
      Name: adminName,
      PhotoURL: "",
    };
    const admin1Obj = {
      UUID: uuid,
      Name: admin1UserData.Name.trim(),
      PhotoURL: admin1UserData.PhotoURL,
    };
    //addCoachData
    const resCoach = await cognitoUserDetails("+91" + coachMobileNo);
    console.log(resCoach.statusCode);
    if (resCoach.statusCode === 400) {
      const resAddCoach = await addUser("+91" + coachMobileNo);
      coachUUID = resAddCoach.User.Username;
    } else {
      console.log("Coach Found data : ", resCoach);
      coachUUID = resCoach.Username;
    }
    getUserMasterData(coachUUID).then(async (response) => {
      if (response) {
        console.log("Coach Data", response);
        userMasterCoach = response;
        if (userMasterCoach.userMasterCoachSummaryId == "") {
          console.log("We are in if Coach Summary");
          try {
            coachSummary = await DataStore.save(
              new CoachMaster({
                Name: coachName,
                Games: 0,
                Won: 0,
                Lost: 0,
                Drawn: 0,
              })
            );
          } catch (err) {
            console.log("Error in saving coach summary : ", coachSummary);
          }
        } else {
          console.log("We are in else of coach summary");
          await getCoachMasterData(
            userMasterCoach.userMasterCoachSummaryId
          ).then((value) => {
            coachSummary = value;
          });
        }
      } else {
        console.log("No coach data found");
        try {
          coachSummary = await DataStore.save(
            new CoachMaster({
              Name: coachName.trim(),
              Games: 0,
              Won: 0,
              Lost: 0,
              Drawn: 0,
            })
          );
          userMasterCoach = await DataStore.save(
            new UserMaster({
              UUID: coachUUID,
              Name: coachName.trim(),
              ContactNo: Number(coachMobileNo),
              EmailId: "",
              PhotoURL: "",
              CreatedBy: admin1UserData.Name,
              LastUpdatedBy: admin1UserData.Name,
              RoleCoach: true,
              RolePlayer: false,
              CoachSummary: coachSummary,
            })
          );
          const updateCoachUserMasterId = {
            coachMasterCoachDataUUID: userMasterCoach.UUID,
          };

          let updatedItem;
          const original = await DataStore.query(
            CoachMaster,
            userMasterCoach.userMasterCoachSummaryId
          );
          try {
            if (updateCoachUserMasterId) {
              console.log("Current Item : ", updateCoachUserMasterId);
              updatedItem = CoachMaster.copyOf(original, (ele) => {
                ele.coachMasterCoachDataUUID =
                  updateCoachUserMasterId.coachMasterCoachDataUUID;
              });
              await DataStore.save(updatedItem);

              console.log("Updated Item:", updatedItem);
            } else {
              console.error("Item not found");
            }
            return updatedItem;
          } catch (error) {
            console.error("Error updating item:", error);
          }

          console.log("UserMaster Coach : ", userMasterCoach);
        } catch (e) {
          console.log("Error : ", e);
        }
      }
    });

    try {
      squad = await DataStore.save(
        new Squad({
          Name: teamName,
          Logo: teamLogoURL,
          TournamentsPlayed: 0,
          CreatedBy: admin1UserData.Name,
          LastUpdatedBy: admin1UserData.Name,
          Admin1: admin1Obj,
          Admin2: admin2Obj,
        })
      );
      console.log("Squad Data : ", squad);
    } catch (err) {
      console.log("Error in creating squad : ", err);
    }
    if (admin1UserData) {
      try {
        squadUserMaster = await DataStore.save(
          new SquadUserMaster({
            userMasterUUID: admin1UserData.UUID,
            squadId: squad.id,
          })
        );
        console.log("Squad Usermaster data for admin1 : ", squadUserMaster);
      } catch (err) {
        console.log("Error in creating squad userMaster for admin 1 : ", err);
      }
    }
    if (userMasterAdmin) {
      try {
        squadUserMaster = await DataStore.save(
          new SquadUserMaster({
            userMasterUUID: userMasterAdmin.UUID,
            squadId: squad.id,
          })
        );
        console.log("Squad Usermaster data for admin2 : ", squadUserMaster);
      } catch (err) {
        console.log("Error in creating squad userMaster for admin 2 : ", err);
      }
    }
    if (coachSummary && userMasterCoach) {
      try {
        squadUserMaster = await DataStore.save(
          new SquadUserMaster({
            userMasterUUID: userMasterCoach.UUID,
            squadId: squad.id,
          })
        );
        console.log("Squad Usermaster data for coach : ", squadUserMaster);
      } catch (err) {
        console.log("Error in creating squad userMaster for coach : ", err);
      }

      try {
        squadCoachMaster = await DataStore.save(
          new SquadCoachMaster({
            coachMasterId: coachSummary.id,
            squadId: squad.id,
          })
        );
        console.log("Squad Usermaster Coach data : ", squadCoachMaster);
      } catch (err) {
        console.log("Error in creating squad userMaster coach : ", err);
      }
    }

    // Call handleFormSubmit with the required formData
    handleFormSubmit(playersList);

    for (let i = 0; i < playersList.length; i++) {
      let playerSummaryData;
      let playerUserMasterData;
      let res = await cognitoUserDetails("+91" + playersList[i].MobileNo);

      if (res.statusCode === 400) {
        console.log("Name : ", playersList[i].Name);
        console.log("PhotoURL : ", playersList[i].PhotoURL);
        console.log("Mobile No : ", playersList[i].MobileNo);
        console.log("No Player found");
        console.log("------------------");
        const resAddPlayer = await addUser("+91" + playersList[i].MobileNo);
        console.log("Resp : ", resAddPlayer);
        playersList[i].UUID = resAddPlayer.User.Username;
        console.log("Player ", i, "UUID ", playersList[i].UUID);
      } else {
        playersList[i].UUID = res.Username;
        getUserMasterData(playersList[i].UUID).then(async (value) => {
          if (value.userMasterPlayerSummaryId == "") {
            await generateSerialNumber();
            try {
              playerSummaryData = await DataStore.save(
                new PlayerMaster({
                  Name: playersList[i].Name,
                  PhotoURL: playersList[i].PhotoURL,
                  JerseyNo: playersList[i].JerseyNo,
                  PlayerData: value,
                  SrNo: playerSerialNumber,
                })
              );
              console.log("Player Summary Data : ", playerSummaryData);
              console.log("Player Summary Data Id : ", playerSummaryData.id);
            } catch (e) {
              console.log("Player Summary Error : ", e);
            }
          }
        });
      }
      await getUserMasterData(playersList[i].UUID).then(async (response) => {
        if (response) {
          console.log("Player ", i, " Data", response);
          playerUserMasterData = response;
          // console.log("Player ID : ", playerData);
          if (playerUserMasterData.userMasterPlayerSummaryId == "") {
            await generateSerialNumber();
            try {
              playerSummaryData = await DataStore.save(
                new PlayerMaster({
                  Name: playersList[i].Name,
                  PhotoURL: playersList[i].PhotoURL,
                  JerseyNo: playersList[i].JerseyNo,
                  PlayerData: playerUserMasterData,
                  SrNo: playerSerialNumber,
                })
              );
              console.log("Player Summary Data : ", playerSummaryData);
              console.log("Player Summary Data Id : ", playerSummaryData.id);
            } catch (e) {
              console.log("Player Summary Error : ", e);
            }
          }
        } else {
          console.log("No player data found");
          await generateSerialNumber();
          try {
            playerSummaryData = await DataStore.save(
              new PlayerMaster({
                Name: playersList[i].Name,
                JerseyNo: playersList[i].JerseyNo,
                PhotoURL: playersList[i].PhotoURL,
                SrNo: playerSerialNumber,
              })
            );
            playerUserMasterData = await DataStore.save(
              new UserMaster({
                UUID: playersList[i].UUID,
                Name: playersList[i].Name,
                ContactNo: Number(playersList[i].MobileNo),
                EmailId: "",
                PhotoURL: playersList[i].PhotoURL,
                CreatedBy: admin1UserData.Name,
                LastUpdatedBy: admin1UserData.Name,
                RoleCoach: false,
                RolePlayer: true,
                PlayerSummary: playerSummaryData,
              })
            );
            console.log("UserMaster Player: ", playerUserMasterData);
            const updatePlayerMasterPlayerDataUUID = {
              playerMasterPlayerDataUUID: playerUserMasterData.UUID,
            };

            let updatedItem;
            console.log(
              "UserMasterPlayerSummary Id : ",
              playerUserMasterData.userMasterPlayerSummaryId
            );
            const original = await DataStore.query(
              PlayerMaster,
              playerUserMasterData.userMasterPlayerSummaryId
            );
            try {
              if (updatePlayerMasterPlayerDataUUID) {
                console.log(
                  "Current Item : ",
                  updatePlayerMasterPlayerDataUUID
                );
                updatedItem = PlayerMaster.copyOf(original, (ele) => {
                  ele.playerMasterPlayerDataUUID =
                    updatePlayerMasterPlayerDataUUID.playerMasterPlayerDataUUID;
                });
                await DataStore.save(updatedItem);

                console.log("Updated Item:", updatedItem);
              } else {
                console.error("Item not found");
              }
              squadPlayerMaster = await DataStore.save(
                new SquadPlayerMaster({
                  squadId: squad.id,
                  playerMasterId: playerSummaryData.id,
                })
              );
              console.log("Squad Playermaster data : ", squadPlayerMaster);
              return updatedItem;
            } catch (error) {
              console.error("Error updating item:", error);
            }
          } catch (e) {
            console.log("Error : ", e);
          }
        }
      });
    }

    setErrorModalVisible(true);
    setErrorModalMesg("Team details submitted successfully");

    // alert("team details submitted successfully");
    // navigation.navigate("MyTeams");
  };

  const handleSubmit = () => {
    navigation.navigate("MyTeams");

    setErrorModalVisible(false);
  };

  useEffect(() => {
    // getPlayersData();
    let list1 = [];
    for (let i = 0; i < 2; i++) {
      list1.push({
        Name: "",
        DOB: "",
        JerseyNo: "",
        MobileNo: "",
        PhotoURL: "",
        UUID: "",
        playerSummaryId: "",
        coachMasterId: "",
      });
    }
    console.log("PlayerList Length : ", list1.length);
    console.log("list1", list1);
    setPlayersList(list1);
    let list2 = [];
    for (let i = 0; i < 2; i++) {
      list2.push({
        Pic: null,
        UploadedPercentage: "",
        Url: "",
      });
    }
    setProfilePicsArray(list2);
    console.log("ProfilePics Length : ", list2.length);

    getAuthenticatedUser().then(async (val) => {
      if (val) {
        console.log("Response useEffect : ", val);
        const { isLoggedIn, attributes } = val;
        console.log("isLogged In : ", isLoggedIn);

        setIsLoggedIn(true);

        if (attributes) {
          let uuid = attributes.sub;
          console.log("UUID : ", uuid);

          setUUID(uuid);

          await getUserMasterData(uuid).then((value) => {
            admin1UserData = value;
            console.log("UserMaster Admin1UserData: ", admin1UserData);
          });
        }
      }
    });
    console.log("playersList", playersList);
  }, []);

  const handleBack = () => {
    navigation.goBack();
  };

  return (
    <>
      {Platform.OS !== "web" && (
        <AppBar title={"Team"} showBackButton={true} onBackPress={handleBack} />
      )}
      <AdminLayout>
        <ScrollView>
          <View style={{ padding: 5, width: "100%", paddingBottom: 50 }}>
            <View
              style={{
                flexDirection: "column",
                alignItems: "center",
                gap: 15,
              }}
            >
              <UploadImage
                onImageSelect={handleImageSelection}
                kindOfFile="logo"
              />
            </View>
            <View
              style={{
                gap: 5,
              }}
            >
              <View style={styles.teamModalHeadingWrapper2}>
                <View style={styles.teamModalHeadingWrapper}>
                  <Text style={styles.teamModalLabel}>Team Name</Text>
                  <TextInput
                    style={styles.TeamModalInput}
                    placeholder={"Enter Team Name"}
                    value={teamName}
                    onChangeText={setTeamName}
                    placeholderTextColor={colors.darkGray}
                  />
                </View>
                <View style={styles.teamModalHeadingWrapper}>
                  <Text style={styles.teamModalLabel}>Team Short Name</Text>
                  <TextInput
                    style={styles.TeamModalInput}
                    placeholder="Enter three characters"
                    value={teamShortName}
                    onChangeText={setTeamShortName}
                    placeholderTextColor={colors.darkGray}
                  />
                </View>
              </View>
              <View style={styles.teamModalHeadingWrapper2}>
                <View style={styles.teamModalHeadingWrapper}>
                  <Text style={styles.teamModalLabel}>Coach Name</Text>
                  <TextInput
                    style={styles.TeamModalInput}
                    placeholder="Enter Coach Name"
                    value={coachName}
                    onChangeText={setCoachName}
                    placeholderTextColor={colors.darkGray}
                  />
                </View>
                <View style={styles.teamModalHeadingWrapper}>
                  <Text style={styles.teamModalLabel}>Coach Mobile No.</Text>
                  <TextInput
                    style={styles.TeamModalInput}
                    placeholder="Enter Mobile No"
                    value={coachMobileNo}
                    onChangeText={setCoachMobileNo}
                    placeholderTextColor={colors.darkGray}
                  />
                </View>
              </View>
              <View style={styles.teamModalHeadingWrapper2}>
                <View style={styles.teamModalHeadingWrapper}>
                  <Text style={styles.teamModalLabel}>Admin Name</Text>
                  <TextInput
                    style={styles.TeamModalInput}
                    placeholder="Enter Admin Name"
                    value={adminName}
                    onChangeText={setAdminName}
                    placeholderTextColor={colors.darkGray}
                  />
                </View>
                <View style={styles.teamModalHeadingWrapper}>
                  <Text style={styles.teamModalLabel}>Admin Mobile No.</Text>
                  <TextInput
                    style={styles.TeamModalInput}
                    placeholder="Enter Mobile No"
                    value={adminMobileNo}
                    onChangeText={setAdminMobileNo}
                    placeholderTextColor={colors.darkGray}
                  />
                </View>
              </View>
            </View>
            {/* <Text style={[styles.teamModalLabel, { paddingVertical: 10 }]}>
            Team Members (0/20)
          </Text> */}
            <View
              style={{ marginTop: 15, alignItems: "center", marginBottom: 15 }}
            >
              <Pressable
                style={styles.addTeamBtn}
                onPress={() => handlePlayerDetailsModalVisible()}
              >
                <AddIcon name="plus-circle" size={18} color={colors.white} />
                <Text
                  style={{
                    color: colors.white,
                    fontSize: 14,
                    fontFamily: "PlusJakartaSansSemiBold",
                  }}
                >
                  Add New Player
                </Text>
              </Pressable>
            </View>

            <View style={{ gap: 10, marginBottom: 10 }}>
              {/* {playersList && playersList.length > 0 ? (
            playersList.map((data: any, index: any) => ( */}
              <Pressable onPress={() => handlePlayerDetailsModalVisible()}>
                <View style={styles.playerCard}>
                  <Pressable
                    // onPress={() => handleDeletePlayer(i)}
                    style={styles.scoreSheetDeleteBtn}
                  >
                    <TrashIcon name="trash-bin-sharp" size={18} color="red" />
                  </Pressable>
                  <Image
                    source={defaultLogo}
                    style={{ width: 80, height: 80, borderRadius: 80 }}
                    resizeMode="contain"
                  />
                  <View
                    style={{
                      flexDirection: "column",
                      gap: 5,
                      flex: 1,
                      padding: 15,
                    }}
                  >
                    <Text style={{ color: colors.white }}>Sunil Chhetri</Text>
                    <Text style={{ color: colors.white }}>DOB- 03/08/1984</Text>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ color: colors.white }}>
                        Mob- 9999999999
                      </Text>
                      <Text style={{ color: colors.white }}>Jersey- 11</Text>
                    </View>
                  </View>
                </View>
              </Pressable>
              {/* ))
          ) : (
            <Text>No data found</Text>
          )} */}
            </View>

            <PlayerDetailsModal
              isVisible={PlayerDetailsModalVisible}
              onClose={closePlayerDetailsModal}
              onSubmit={handleFormSubmit}
              imageSelection={handleImageSelection}
            />

            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                gap: 20,
                marginTop: 15,
                paddingBottom: 40,
              }}
            >
              <Pressable
                onPress={() => navigation.navigate("MyTeams")}
                style={[
                  styles.btnPressable,
                  { borderWidth: 1.5, borderColor: colors.white },
                ]}
              >
                <Text style={styles.btnText}>Cancel</Text>
              </Pressable>
              <Pressable
                onPress={handleOnSubmit}
                style={[
                  styles.btnPressable,
                  {
                    backgroundColor: "#12FFE3",
                    borderWidth: 1.5,
                    borderColor: "#12FFE3",
                  },
                ]}
              >
                <Text style={styles.btnText1}>Submit</Text>
              </Pressable>
            </View>
          </View>
        </ScrollView>
        {errorModalVisible == true ? (
          <ErrorModal
            visibleModal={errorModalVisible}
            SuccessMessage={errorModalMesg}
            onSubmit={handleSubmit}
            header={"Attention"}
          />
        ) : null}
      </AdminLayout>
    </>
  );
};
const styles = StyleSheet.create({
  teamModalHeadingWrapper2: {
    flexDirection: "column",
    justifyContent: "space-between",
    gap: 10,
  },
  teamModalHeadingWrapper: {
    gap: 5,
  },
  teamModalLabel: {
    fontSize: 14,
    fontFamily: "PlusJakartaSansSemiBold",
    color: colors.white,
  },

  btnText: {
    textAlign: "center",
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 14,
    color: colors.white,
  },
  btnText1: {
    textAlign: "center",
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 14,
    color: colors.royalBlue,
  },
  btnPressable: {
    width: 150,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },

  TeamModalInput: {
    borderColor: colors.borderColor,
    borderWidth: 1,
    height: 40,
    width: "100%",
    borderRadius: 5,
    paddingHorizontal: 5,
    fontFamily: "PlusJakartaSansRegular",
    color: colors.white,
    backgroundColor: colors.royalBlue,
  },

  playerCard: {
    flexDirection: "row",
    width: "100%",
    backgroundColor: colors.royalBlue,
    padding: 15,
    borderRadius: 10,
    shadowOffset: {
      width: 3,
      height: 4,
    },
    shadowColor: "#000000",
    shadowOpacity: 0.22,
    shadowRadius: 10,
    elevation: 3,
    alignItems: "center",
    position: "relative",
  },
  addTeamBtn: {
    backgroundColor: colors.orange,
    flexDirection: "row",
    gap: 5,
    alignItems: "center",
    justifyContent: "center",
    // width: 100,
    borderRadius: 5,
    padding: 4,
  },
  scoreSheetDeleteBtn: {
    width: 30,
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    right: 0,
    top: 0,
    backgroundColor: colors.newBlue,
    padding: 5,
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
});
export default EditTeamMob;
