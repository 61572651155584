import {
  View,
  Text,
  StyleSheet,
  Image,
  Pressable,
  Platform,
  Dimensions,
  Modal,
  ActivityIndicator,
} from "react-native";
import React, { useEffect, useState } from "react";
import { colors } from "../../../components/reusable/colour";
import Icon from "react-native-vector-icons/Ionicons";
import {
  convertTo12HourFormat,
  formatDate,
} from "../../../components/FormattedDate";
import { Tournament } from "../../../models";
import { DataStore } from "aws-amplify/datastore";
import AsyncStorage from "@react-native-async-storage/async-storage";
import SearchBar from "../../../components/reusable/SearchBar";

const screenWidth = Dimensions.get("window").width;

const Fixtures = ({ tournamentId }) => {
  const [allRoundsData, setAllRoundsData]: any = useState([]);

  const [searchFlag, setSearchFlag] = useState(false);
  const [loading, setLoading] = useState(false);

  const defaultIcon: any = require("../../../../assets/Avtar/football-club.png");

  // Function to group matches by date

  const groupMatchesByRoundAndDate = (roundsData: any) => {
    return roundsData.map((round: any) => {
      const groupedMatches: any = {};
      round.groups.forEach((group: any) => {
        group.Matches.forEach((matchData: any) => {
          const matchDate =
            matchData.Date !== null
              ? formatDate(matchData.Date)
              : "Not scheduled";
          if (!groupedMatches[matchDate]) {
            groupedMatches[matchDate] = [];
          }
          groupedMatches[matchDate].push(matchData);
        });
      });
      console.log("groupmatches : ", groupedMatches);
      // const sortedByTime: any = Object.values(groupedMatches).sort(
      //   (a: any, b: any) => {
      //     a = a.Time || "";
      //     b = b.Time || "";
      //     return a.localeCompare(b);
      //   }
      // );
      // console.log("Sorted by time : ", sortedByTime);

      const sortedData = {
        roundName: round.Name,
        matchesByDate: Object.entries(groupedMatches).sort((a, b) => {
          const dateA: any =
            a[0] !== "Not scheduled" ? new Date(a[0]) : new Date("2000-01-01");
          const dateB: any =
            b[0] !== "Not scheduled" ? new Date(b[0]) : new Date("2000-01-01");
          return dateB - dateA;
        }),
      };

      console.log("SortedData:", sortedData);

      function sortMatchesByTime(matchesArray) {
        return matchesArray.sort((a, b) => {
          const timeA =
            a.Time === "Not Scheduled" ? 0 : Date.parse(`1970-01-01T${a.Time}`);
          const timeB =
            b.Time === "Not Scheduled" ? 0 : Date.parse(`1970-01-01T${b.Time}`);
          console.log("timeA", timeA, "timeB", timeB);
          return timeA - timeB;
        });
      }

      // Iterate through each date and sort matches within it by time
      sortedData.matchesByDate.forEach((dateMatches) => {
        if (dateMatches[1]) {
          dateMatches[1] = sortMatchesByTime(dateMatches[1]);
        }
      });

      return sortedData;

      // matchesByTime: Object.entries(groupedMatches).sort((a, b) => {
      //   const timeA: any = a[0] ? new Date(a[0]) : new Date(0);
      //   const timeB: any = b[0] ? new Date(b[0]) : new Date(0);
      //   return timeB - timeA;
      // }),
    });
  };

  // const groupMatchesByRoundAndDate = (roundsData: any) => {
  //   return roundsData.map((round: any) => {
  //     const groupedMatches: any = {};
  //     round.groups.forEach((group: any) => {
  //       group.Matches.forEach((matchData: any) => {
  //         const matchDate =
  //           matchData.Date !== null
  //             ? formatDate(matchData.Date)
  //             : "Not scheduled";
  //         if (!groupedMatches[matchDate]) {
  //           groupedMatches[matchDate] = [];
  //         }
  //         groupedMatches[matchDate].push(matchData);
  //       });
  //     });

  //     // Convert object to array of objects
  //     const matchesByDate = Object.entries(groupedMatches).map(
  //       ([date, matches]: [string, any[]]) => ({
  //         date,
  //         matches: matches.sort((a: any, b: any) => {
  //           // Sort matches by time
  //           const timeA = extractTime(a.Time);
  //           const timeB = extractTime(b.Time);
  //           return timeA - timeB;
  //         }),
  //       })
  //     );

  //     // Sort matches by date
  //     matchesByDate.sort((a, b) => {
  //       const dateA: any = new Date(a.date);
  //       const dateB: any = new Date(b.date);
  //       return dateA - dateB;
  //     });

  //     return {
  //       roundName: round.Name,
  //       matchesByDate,
  //     };
  //   });
  // };

  // Function to extract time from HH:MM format
  const extractTime = (timeString: string) => {
    if (!timeString) return 0;
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const getFixturesData = async () => {
    try {
      setLoading(true);

      const tournamentDetailsProps = await AsyncStorage.getItem(
        "tournamentProps"
      );
      // const tournamentDetails = JSON.parse(tournamentDetailsProps);
      console.log("tournamentDetailsProps", tournamentDetailsProps);
      // setTournamentDetailsProps(tournamentDetails);

      const subscription = DataStore.observeQuery(Tournament, (c) =>
        c.id.eq(tournamentId)
      ).subscribe(async (snapshot) => {
        const initialTournaments = snapshot.items;
        console.log("selectedTournament", initialTournaments);

        const getAllFixturesDataByRounds = initialTournaments.map(
          async (data: any) => {
            const rounds = await data.Rounds.values;

            // Extract and set groups data for each round
            const groupsPromise = rounds.map(async (round: any) => {
              const groups = await round.Groups.values;

              // Extract and set group standings data for each group
              const groupStandingPromise = groups.map(async (group: any) => {
                const groupStandings = await group.GroupStandings.values;

                // Fetch matches separately
                const matches = await group.Matches.values;

                const matchesData = await Promise.all(
                  matches.map(async (match: any) => {
                    const team1Promise = match.Team1;
                    const team2Promise = match.Team2;

                    // Await the resolution of both promises concurrently
                    const [team1, team2] = await Promise.all([
                      team1Promise,
                      team2Promise,
                    ]);

                    // Fetch data for Team1 and Team2 if they are not null
                    const team1Data = team1 ? await team1 : null;
                    const team2Data = team2 ? await team2 : null;

                    return { ...match, Team1: team1Data, Team2: team2Data };
                  })
                );

                // Now matchesData is the final array with resolved Team1 and Team2 promises
                console.log(matchesData);

                const matchesDataComplete = await Promise.all(matchesData);

                return {
                  ...group,
                  groupStandings,
                  Matches: matchesDataComplete,
                };
              });

              const groupdStandingData = await Promise.all(
                groupStandingPromise
              );

              return { ...round, groups: groupdStandingData };
            });

            const groupsData = await Promise.all(groupsPromise);

            return { ...data, rounds: groupsData };
          }
        );

        const roundsData = await Promise.all(getAllFixturesDataByRounds);
        console.log("roundsData", roundsData);

        const getRoundsData = roundsData.map((data) => data.rounds);
        console.log("getRoundsData", getRoundsData[0]);

        const fixturesCreatedMatches = getRoundsData[0].filter(
          (roundData: any) => roundData.FixturesCreated === true
        );

        console.log("fixturesCreatedMatches", fixturesCreatedMatches);
        setAllRoundsData(fixturesCreatedMatches);
        setLoading(false);
      });

      return () => {
        subscription.unsubscribe();
      };
    } catch (error) {
      setLoading(false);
      console.log("error occured while fetching the data", error);
    }
  };

  const handleSearch = (text: any) => {
    setSearchFlag(true); //

    if (!text) {
      setSearchFlag(false); // Set search flag to false when there is no active search
      setAllRoundsData(allRoundsData); // Reset to the original data
    } else {
      console.log("filtertext", text);
      const filteredResults = allRoundsData.map((round: any) => ({
        ...round,
        groups: round.groups.map((group: any) => ({
          ...group,
          Matches: group.Matches.filter((match: any) => {
            // Check if any attribute of Team1 or Team2 matches the search text
            return (
              Object.values(match.Team1).some(
                (value: any) =>
                  typeof value === "string" &&
                  value.toLowerCase().includes(text.toLowerCase())
              ) ||
              Object.values(match.Team2).some(
                (value: any) =>
                  typeof value === "string" &&
                  value.toLowerCase().includes(text.toLowerCase())
              )
            );
          }),
        })),
      }));

      console.log("filtered results", filteredResults);
      setAllRoundsData(filteredResults);
    }
  };

  useEffect(() => {
    const getAllData = async () => {
      await getFixturesData();
    };
    getAllData();
    console.log("tournament id in useEffect", tournamentId);
  }, [tournamentId, searchFlag]);

  return (
    <View style={{ flexDirection: "column", gap: 10 }}>
      <SearchBar
        placeholder="Search by team name"
        onSearch={handleSearch}
        style={styles.searchBar}
      />

      {allRoundsData && allRoundsData.length > 0 ? (
        groupMatchesByRoundAndDate(allRoundsData).map(
          (roundData: any, roundIndex: any) => (
            <View key={roundIndex} style={styles.standingsRoundContainer}>
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 10,
                }}
              >
                <Text style={styles.roundNameText}>{roundData.roundName}</Text>
              </View>

              {roundData.matchesByDate.map(
                ([formattedDate, matchesForDate]: any, dateIndex: any) => (
                  <React.Fragment key={dateIndex}>
                    <Text style={styles.dateStyle}>{formattedDate}</Text>
                    {matchesForDate.map((matchData: any, matchIndex: any) => (
                      <View
                        style={[
                          styles.matchCard,
                          {
                            backgroundColor: colors.newBlue,
                          },
                        ]}
                        key={matchIndex}
                      >
                        <View
                          style={{
                            width:
                              Platform.OS === "web" && screenWidth > 760
                                ? 50
                                : "20%",
                            display: "flex",
                            position:
                              Platform.OS === "web" && screenWidth > 760
                                ? "relative"
                                : "absolute",
                            top:
                              Platform.OS === "web" && screenWidth > 760
                                ? 0
                                : 5,
                            left:
                              Platform.OS === "web" && screenWidth > 760
                                ? 0
                                : 10,
                          }}
                        >
                          <Text style={styles.matchName}>
                            M-
                            {matchData ? matchData.MatchNo : "Invalid MatchNo"}
                          </Text>
                        </View>
                        <View
                          style={{
                            width:
                              Platform.OS === "web" && screenWidth > 760
                                ? 30
                                : "20%",
                            display: "flex",
                            justifyContent:
                              Platform.OS === "web" && screenWidth > 760
                                ? "center"
                                : "flex-end",
                            alignItems:
                              Platform.OS === "web" && screenWidth > 760
                                ? "center"
                                : "flex-end",
                            position:
                              Platform.OS === "web" && screenWidth > 760
                                ? "relative"
                                : "absolute",
                            top:
                              Platform.OS === "web" && screenWidth > 760
                                ? 0
                                : 5,
                            right:
                              Platform.OS === "web" && screenWidth > 760
                                ? 0
                                : 5,
                          }}
                        >
                          <Text style={styles.matchName}>
                            {matchData.GroupName.replace(/^Group\s/, "")}
                          </Text>
                        </View>
                        <View
                          style={{
                            width:
                              Platform.OS === "web" && screenWidth > 760
                                ? undefined
                                : "30%",
                            flexDirection: "row",
                            gap: 10,
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            flex: 1,
                          }}
                        >
                          <Text
                            style={[styles.teamName, { textAlign: "right" }]}
                            numberOfLines={2}
                          >
                            {Platform.OS === "web" && screenWidth > 760
                              ? matchData.Team1.TeamName
                                ? matchData.Team1.TeamName
                                : "N/A"
                              : matchData.Team1.TeamShortName
                              ? matchData.Team1.TeamShortName
                              : "N/A"}
                          </Text>
                          <Image
                            source={{
                              uri: matchData.Team1.LogoURL
                                ? matchData.Team1.LogoURL
                                : defaultIcon,
                            }}
                            // defaultSource={{
                            //   uri: defaultIcon,
                            // }}
                            style={{
                              width: 40,
                              borderRadius: 30,
                              height: 40,
                            }}
                            resizeMode="contain"
                          />
                        </View>

                        {Platform.OS === "web" && screenWidth > 760 ? (
                          <View
                            style={{
                              width: 30,
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Text style={styles.Vs}>VS</Text>
                          </View>
                        ) : (
                          <View
                            style={{
                              width: 120,
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Text style={styles.teamName}>
                              {matchData.Time ? matchData.Time : "Invalid Time"}
                            </Text>
                          </View>
                        )}

                        <View
                          style={{
                            width:
                              Platform.OS === "web" && screenWidth > 760
                                ? undefined
                                : "30%",
                            flexDirection: "row",
                            gap: 10,
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            flex: 1,
                          }}
                        >
                          <Image
                            source={{
                              uri: matchData.Team2.LogoURL
                                ? matchData.Team2.LogoURL
                                : require("../../../../assets/logo/testLogo.png"),
                            }}
                            // defaultSource={{
                            //   uri: defaultIcon,
                            // }}
                            style={{
                              width: 40,
                              height: 40,
                              borderRadius: 30,
                            }}
                            resizeMode="contain"
                          />
                          <Text style={styles.teamName} numberOfLines={2}>
                            {Platform.OS === "web" && screenWidth > 760
                              ? matchData.Team2.TeamName
                                ? matchData.Team2.TeamName
                                : "N/A"
                              : matchData.Team2.TeamShortName
                              ? matchData.Team2.TeamShortName
                              : "N/A"}
                          </Text>
                        </View>

                        {Platform.OS === "web" && screenWidth > 760 && (
                          <View
                            style={{
                              width: 120,
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Text style={styles.teamName}>
                              {matchData.Time
                                ? convertTo12HourFormat(matchData.Time)
                                : "Not Scheduled"}
                            </Text>
                          </View>
                        )}
                        {matchData.Venue ? (
                          <View
                            style={{
                              width:
                                Platform.OS === "web" && screenWidth > 760
                                  ? "15%"
                                  : "100%",
                              display: "flex",
                              flexDirection: "row",

                              justifyContent:
                                Platform.OS === "web" && screenWidth > 760
                                  ? "flex-end"
                                  : "center",
                              position:
                                Platform.OS === "web" && screenWidth > 760
                                  ? "relative"
                                  : "absolute",
                              bottom:
                                Platform.OS === "web" && screenWidth > 760
                                  ? 0
                                  : 5,
                            }}
                          >
                            <Text style={styles.teamName}>
                              {matchData.Venue.Name}
                            </Text>
                            <Pressable
                              onPress={() => {
                                const { Lattitude, Longitude } =
                                  matchData.Venue?.Coordinates;
                                const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${Lattitude},${Longitude}`;
                                // Open the link in the browser or a webview
                                window.open(googleMapsLink, "_blank");
                              }}
                            >
                              <Icon
                                name="location"
                                size={
                                  Platform.OS === "web" && screenWidth > 760
                                    ? 20
                                    : 15
                                }
                                color="red"
                              />
                            </Pressable>
                          </View>
                        ) : (
                          <View
                            style={{
                              width:
                                Platform.OS === "web" && screenWidth > 760
                                  ? "15%"
                                  : "100%",
                              display: "flex",
                              flexDirection: "row",

                              justifyContent:
                                Platform.OS === "web" && screenWidth > 760
                                  ? "flex-end"
                                  : "center",
                              position:
                                Platform.OS === "web" && screenWidth > 760
                                  ? "relative"
                                  : "absolute",
                              bottom:
                                Platform.OS === "web" && screenWidth > 760
                                  ? 0
                                  : 5,
                            }}
                          >
                            <Text style={styles.teamName}>N.A</Text>
                            <Icon
                              name="location"
                              size={
                                Platform.OS === "web" && screenWidth > 760
                                  ? 20
                                  : 15
                              }
                              color="red"
                            />
                          </View>
                        )}
                      </View>
                    ))}
                  </React.Fragment>
                )
              )}
            </View>
          )
        )
      ) : (
        <View style={{ alignItems: "center", width: "100%" }}>
          <Image
            source={require("../../../../assets/noData/standings.png")}
            style={styles.noDataImage}
            resizeMode="contain"
          />
          <Text style={styles.noDataText}>
            Hang on! No any matches completed yet.
          </Text>
        </View>
      )}

      {/* loader */}

      {loading ? (
        <Modal transparent animationType="none">
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <ActivityIndicator size="large" color="#0000ff" />
            <Text style={{ color: "white" }}>Loading</Text>
          </View>
        </Modal>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  matchCard: {
    width: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    borderRadius: 10,
    paddingHorizontal: Platform.OS === "web" && screenWidth > 760 ? 5 : 0,
    paddingVertical: Platform.OS === "web" && screenWidth > 760 ? 5 : 30,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    gap: Platform.OS === "web" && screenWidth > 760 ? undefined : 15,
  },
  dateStyle: {
    fontFamily: "PlusJakartaSansRegular",
    color: colors.white,
    padding: Platform.OS === "web" && screenWidth > 760 ? 5 : undefined,
  },
  matchName: {
    textAlign: "left",
    fontFamily: "PlusJakartaSansRegular",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 14 : 12,
    fontWeight: Platform.OS === "web" && screenWidth > 760 ? "bold" : "normal",
    color: colors.white,
  },
  teamName: {
    fontFamily: "PlusJakartaSansRegular",
    color: colors.white,
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 14 : 12,
  },
  Vs: {
    fontFamily: "PlusJakartaSansMedium",
    color: colors.white,
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 16 : 14,
  },
  searchBar: {
    width: Platform.OS === "web" && screenWidth > 760 ? 350 : "100%",
    height: Platform.OS === "web" && screenWidth > 760 ? 40 : 35,
    borderColor: colors.royalBlue,
    backgroundColor: colors.blue,
    marginTop: 20,
    color: colors.white,
  },
  standingsRoundContainer: {
    shadowColor: "#00000021",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    elevation: 5,
    shadowRadius: 10,
    padding: 7,
    borderRadius: 10,
    backgroundColor: colors.royalBlue,
    gap: 10,
  },
  roundNameText: {
    fontFamily: "PlusJakartaSansSemiBold",
    color: colors.white,
    backgroundColor: colors.bgColor,
    paddingVertical: 4,
    paddingHorizontal: 20,
    borderRadius: 20,
  },
  noDataImage: {
    width: 270,
    height: 270,
  },
  noDataText: {
    fontFamily: "PlusJakartaSansRegular",
    color: colors.royalBlueColour,
    fontSize: 20,
    maxWidth: 400,
    textAlign: "center",
  },
});

export default Fixtures;
