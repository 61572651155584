//import liraries
import { DataStore } from "@aws-amplify/datastore";
import React, { Component, useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  Platform,
  ScrollView,
  Image,
  Pressable,
  ActivityIndicator,
  Modal,
} from "react-native";
import { Tournament } from "../../../../models";
import { CommonHeading } from "../../../../components/reusable/TextComponent";
import { colors } from "../../../../components/reusable/colour";
import { useNavigation } from "@react-navigation/native";
import CalendarIcon from "react-native-vector-icons/Feather";
import LocationIcon from "react-native-vector-icons/Octicons";
import NextIcon from "react-native-vector-icons/Feather";
import { LinearGradient } from "expo-linear-gradient";
import { formatDateShort } from "../../../../components/FormattedDate";

const screenWidth = Dimensions.get("window").width;

// create a component
const HomeTournaments = () => {
  const navigation: any = useNavigation();

  const defaultBanner =
    "https://trackmytournament-dev125444-dev.s3.ap-south-1.amazonaws.com/public/tournament/tournamentBanner.png";

  const [sortedTournaments, setSortedTournaments] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [loading, setLoading] = useState(false);

  const handleHoverIn = (id: any) => {
    setSelectedId(id);
  };
  const handleHoverOut = (id: any) => {
    setSelectedId(null);
  };

  const navigateToDetails = async (tournamentId) => {
    navigation.navigate("TournamentDetails", {
      tournamentId: tournamentId,
    });
  };

  const getFilteredTournaments = async () => {
    try {
      setLoading(true);
      const subscription = await DataStore.observeQuery(Tournament).subscribe(
        async (snapshot) => {
          const initialTournaments = snapshot.items;
          console.log("initialTournaments", initialTournaments);

          // Filter tournaments based on status and registrations length
          const filteredAndSortedTournaments = [];

          for (const tournament of initialTournaments) {
            const registrations = await tournament.Registrations.toArray();
            if (
              (tournament.Status === "INPROGRESS" ||
                tournament.Status === "InProgress") &&
              registrations.length > 0
            ) {
              filteredAndSortedTournaments.push({
                tournament,
                registrations,
              });
            }
          }

          // Sort tournaments in descending order based on the number of registrations
          filteredAndSortedTournaments.sort(
            (a, b) => b.registrations.length - a.registrations.length
          );

          console.log(
            "filteredAndSortedTournaments",
            filteredAndSortedTournaments
          );

          // Extract the tournament objects from the filtered and sorted array
          const tournamentsOnly = filteredAndSortedTournaments.map(
            (entry) => entry.tournament
          );
          console.log("tournamentsOnly", tournamentsOnly);

          setSortedTournaments(tournamentsOnly);
          setLoading(false);
        }
      );

      return () => {
        subscription.unsubscribe();
      };
    } catch (error) {
      setLoading(false);
      console.log("Error occured whilte fetching the data", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getFilteredTournaments();
    };
    fetchData();
  }, []);

  return (
    <View style={{ paddingVertical: 20, backgroundColor: colors.bgColor }}>
      <View style={styles.container}>
        <CommonHeading props={"Tournaments"} />
        <ScrollView
          horizontal
          style={styles.homeTournamentContainer}
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={true}
        >
          {sortedTournaments && sortedTournaments.length > 0 ? (
            sortedTournaments.map((data, index) => (
              <Pressable
                style={[
                  styles.homeTournamentCard,
                  {
                    marginTop: selectedId == data.id ? -10 : 0,
                  },
                ]}
                onPress={() => navigateToDetails(data.id)}
                onHoverIn={() => handleHoverIn(data.id)}
                onHoverOut={() => handleHoverOut(data.id)}
                key={index}
              >
                <LinearGradient
                  style={{
                    paddingBottom: 20,
                    borderRadius: 15,
                    height: "100%",
                  }}
                  colors={
                    selectedId == data.id
                      ? [colors.orange, colors.blue]
                      : [colors.royalBlue, colors.royalBlue]
                  }
                  start={{ x: 0, y: 0 }}
                  end={{ x: 0, y: 1 }}
                >
                  <View
                    style={[
                      styles.badgeContainer,
                      {
                        backgroundColor:
                          data.Status === "Created"
                            ? "#140759"
                            : data.Status === "SCHEDULED"
                            ? "#e99400"
                            : data.Status === "INPROGRESS"
                            ? "#21d448"
                            : data.Status === "COMPLETED"
                            ? "#ababab"
                            : null,
                      },
                    ]}
                  >
                    <Text style={styles.badgetText}>
                      {data.Status ? data.Status : null}
                    </Text>
                  </View>
                  <View style={[styles.badgeContainer2]}>
                    <Text style={styles.badgetText}>
                      {data ? (data.Level > "" ? data.Level : "Open") : "Open"}
                    </Text>
                  </View>
                  <Image
                    source={{ uri: data.Banner ? data.Banner : defaultBanner }}
                    // source={require("../../../../../assets/home/tournamentBanner.jpg")}
                    style={styles.TournamentBannerImage}
                  />
                  <View style={styles.TournamentCardContentWrapper}>
                    <View style={{ paddingHorizontal: 10 }}>
                      <Text
                        style={styles.tournamentCardHeading}
                        numberOfLines={2}
                      >
                        {data.Name ? data.Name : null}
                      </Text>
                    </View>
                    <View style={styles.rowContainerWrapper}>
                      <CalendarIcon
                        name="calendar"
                        size={20}
                        color={colors.white}
                      />
                      <Text style={styles.tournamentCardText}>
                        {data.StartDate
                          ? formatDateShort(data.StartDate)
                          : null}
                        -{data.EndDate ? formatDateShort(data.EndDate) : null}
                      </Text>
                    </View>
                    <View style={styles.rowContainerWrapper}>
                      <LocationIcon
                        name="location"
                        size={20}
                        color={colors.white}
                      />
                      <Text style={styles.tournamentCardText}>
                        {/* {data ? data.Venues[0] : null}, */}
                        {data.City ? data.City : null}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.seeDetailsContainer}>
                    <View style={[styles.tournamentCardButton]}>
                      <Text
                        style={[
                          styles.tournamentCardButtonText,
                          {
                            color:
                              selectedId == data.id
                                ? colors.orange
                                : colors.aqua,
                          },
                        ]}
                      >
                        View Details
                      </Text>
                      <NextIcon
                        name="chevrons-right"
                        size={20}
                        color={
                          selectedId == data.id ? colors.orange : colors.aqua
                        }
                      />
                    </View>
                  </View>
                </LinearGradient>
              </Pressable>
            ))
          ) : (
            <Text style={{ color: "white" }}>No tournaments to show</Text>
          )}
          {/* loaader */}
          {loading ? (
            // <Modal transparent animationType="none">
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "transparent",
                width: "100%",
              }}
            >
              <ActivityIndicator size="large" color="#0000ff" />
              <Text style={{ color: "white" }}>Loading</Text>
            </View>
          ) : // </Modal>
          null}
        </ScrollView>
      </View>
    </View>
  );
};

// define your styles
const styles = StyleSheet.create({
  TournamentBannerImage: {
    width: "100%",
    height: 150,
    objectFit: "contain",
    borderRadius: 15,
  },
  organizerLogoStyle: {
    width: 25,
    height: 25,
    objectFit: "contain",
    borderRadius: 15,
  },

  homeTournamentCard: {
    width: 320,
    borderStyle: "solid",
    position: "relative",
    marginHorizontal: screenWidth > 760 ? 15 : 5,
  },
  TournamentCardContentWrapper: {
    padding: 10,
    paddingHorizontal: 0,
    gap: 4,
  },

  TournamentCardHeadingWrapper: {
    backgroundColor: colors.white,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 10,
    shadowColor: "#00000026",
    shadowOpacity: 1,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    marginTop: -20,
    borderRadius: 15,
    marginBottom: 15,
  },

  CardHeadingStyle: {
    textAlign: "center",
    fontSize: 20,
    textTransform: "uppercase",
    fontWeight: "600",
    color: colors.orange,
    marginBottom: 10,
  },
  CardOrganizerName: {
    textAlign: "center",
    fontSize: 15,
    fontWeight: "500",
    color: "#000",
  },
  cardSmallDetailsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 10,
    paddingRight: 0,
    paddingBottom: 10,
    paddingLeft: 0,
    borderTopWidth: 1,
    borderTopColor: "#e3a98d",
    // borderTopStyle: "solid",
  },
  cardDetailsText: {
    fontSize: 14,
    fontWeight: "600",
  },
  buttonContainer: {
    backgroundColor: "#2a4883",
    paddingVertical: 15,
    borderRadius: 10,
    alignItems: "center",
    position: "absolute",
    width: "100%",
    bottom: 0,
  },
  buttonText: {
    color: colors.white,
    fontSize: 18,
    fontWeight: "bold",
  },
  container: {
    maxWidth: 1320,
    width: "100%",
    marginHorizontal: "auto",
    paddingRight: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
    paddingLeft: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
  },
  homeTournamentContainer: {
    paddingVertical: 10,
    display: "flex",
    flexDirection: "row",
    gap: 30,
    overflow: "scroll",
    rowGap: 16,
    marginTop: 25,
    width: "100%",
  },
  container123: {
    flex: 1,
    backgroundColor: colors.white,
  },
  scrollContainer: {
    flexDirection: "row",
  },
  tournamentCardBadge: {
    // backgroundColor: "#FDDCD5",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  tournamentCardBadgeText: {
    fontFamily: "PlusJakartaSansSemiBold",
    backgroundColor: "#FDDCD5",
    paddingVertical: 3,
    paddingHorizontal: 5,
    borderRadius: 5,
    fontSize: 12,
  },
  tournamentCardHeading: {
    fontFamily: "PlusJakartaSansRegular",
    color: colors.white,
    fontSize: 16,
    // marginTop: 8,
  },
  rowContainerWrapperMain: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  rowContainerWrapper: {
    flexDirection: "row",
    gap: 5,
    alignItems: "center",
    marginTop: 5,
    paddingHorizontal: 10,
  },
  tournamentCardText: {
    fontFamily: "PlusJakartaSansExtraLight",
    fontSize: 12,
    color: colors.white,
  },
  tournamentCardButton: {
    // backgroundColor: colors.aqua,
    // height: 36,
    width: "100%",
    borderRadius: 10,
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 6,
    flexDirection: "row",
  },
  tournamentCardButtonText: {
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 17,
  },
  badgeContainer: {
    position: "absolute",
    left: -2,
    top: -2,
    padding: 5,
    borderRadius: 5,
    zIndex: 999,
  },
  badgeContainer2: {
    backgroundColor: colors.orange,
    position: "absolute",
    right: -2,
    top: -2,
    padding: 5,
    borderRadius: 5,
    zIndex: 999,
  },
  badgetText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 14,
  },
  seeDetailsContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    position: "absolute",
    bottom: 10,
    left: 0,
  },
});

const stylesWeb = StyleSheet.create({
  container: {
    maxWidth: 1320,
    width: "100%",
    marginHorizontal:
      Platform.OS === "web" && screenWidth > 760 ? "auto" : null,
    paddingRight: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
    paddingLeft: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
  },
  scrollView: {
    flex: 1,
    marginTop: 10, // Add top margin for spacing
  },
  searchBar: {
    backgroundColor: "#eeeeee",
    width: Platform.OS === "web" && screenWidth > 760 ? 350 : "100%",
    borderColor: "#0000",
    borderBottomWidth: 0,
    borderTopWidth: 0,
  },
  inputContainer: {
    backgroundColor: "#ddd0ec",
    height: 35,
    fontSize: 14,
  },
});

const stylesLazyLoading = StyleSheet.create({
  itemWrapperStyle: {
    flexDirection: "row",
    paddingHorizontal: 16,
    paddingVertical: 16,
    borderBottomWidth: 1,
    borderColor: "#ddd",
  },
  itemImageStyle: {
    width: 50,
    height: 50,
    marginRight: 16,
  },
  contentWrapperStyle: {
    justifyContent: "space-around",
  },
  txtNameStyle: {
    fontSize: 16,
  },
  txtEmailStyle: {
    color: "#777",
  },
  loaderStyle: {
    marginVertical: 16,
    alignItems: "center",
  },
  buttonContainer: {
    backgroundColor: colors.orange,
    paddingVertical: 15,
    borderRadius: 10,
    marginTop: 15,
    alignItems: "center",
    width: 200,
    justifyContent: "center",
    height: 40,
    flexDirection: "row",
    gap: 10,
  },
  buttonText: {
    color: colors.white,
    fontSize: 16,
    fontWeight: "bold",
    justifyContent: "center",
  },
  width100: {
    width: "50%",
  },
});

export default HomeTournaments;
