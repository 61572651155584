import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  Text,
  View,
  StyleSheet,
  Platform,
  Dimensions,
  Pressable,
  Image,
  Animated,
} from "react-native";
import { colors } from "./colour";
import CheckMarkIcon from "react-native-vector-icons/Ionicons";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;
//
// After creation of tournament
const AutoExitModalTrophy = ({ visibleModal, SuccessMessage, handleOk }) => {
  const [modalVisible, setModalVisible] = useState(visibleModal);
  useEffect(() => {
    console.log("visibleModal", visibleModal);
  }, [visibleModal]);
  const lineAnimation = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (modalVisible) {
      Animated.timing(lineAnimation, {
        toValue: 1,
        duration: 5000,
        useNativeDriver: false,
      }).start();
      const timer = setTimeout(() => {
        setModalVisible(false);
      }, 5000); // Auto-exit after 3 seconds

      return () => clearTimeout(timer);
    } else {
      lineAnimation.setValue(0);
    }
  }, [modalVisible]);

  const lineWidth = lineAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: ["0%", "100%"],
  });

  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <Modal
        // animationType="slide"
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#4e4e4e80",
          }}
        >
          <View style={styles.modalView}>
            <View style={styles.progressBarContainer}>
              <Animated.View
                style={[
                  styles.progressBar,
                  { backgroundColor: colors.green, width: lineWidth },
                ]}
              />
            </View>
            <Image
              source={require("../../../assets/icons/trophy.png")}
              style={styles.trophyIcon}
            />
            <Text style={styles.modalHeader}>Success! </Text>
            <Text style={styles.modalSubHeader}>{SuccessMessage}</Text>
            <View style={styles.modalBottomPart}>
              {/* cancel button */}
              <Pressable
                style={[styles.successButton]}
                onPress={() => handleOk()}
              >
                <Text style={[styles.buttonText]}>Ok</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>

      {/* <Button title="Open Modal" onPress={() => setModalVisible(true)} /> */}
    </View>
  );
};

// submitted form success modal
const SuccessAutoExitModal = ({ visibleModal, SuccessMessage, handleOk }) => {
  const [modalVisible, setModalVisible] = useState(visibleModal);
  const lineAnimation = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (modalVisible) {
      Animated.timing(lineAnimation, {
        toValue: 1,
        duration: 5000,
        useNativeDriver: false,
      }).start();
      const timer = setTimeout(() => {
        setModalVisible(false);
      }, 5000); // Auto-exit after 3 seconds

      return () => clearTimeout(timer);
    } else {
      lineAnimation.setValue(0);
    }
  }, [modalVisible]);

  const lineWidth = lineAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: ["0%", "100%"],
  });

  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <Modal
        // animationType="slide"
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#4e4e4e80",
          }}
        >
          <View style={styles.modalView}>
            <View style={styles.progressBarContainer}>
              <Animated.View
                style={[
                  styles.progressBar,
                  { backgroundColor: colors.green, width: lineWidth },
                ]}
              />
            </View>
            {/* <Image
              source={{
                uri: require("../../../assets/icons/trophy.png"),
              }}
              style={styles.trophyIcon}
            /> */}

            <CheckMarkIcon
              name="checkmark-circle-outline"
              size={40}
              color={colors.green}
            />
            <Text style={styles.modalHeader}>Success! </Text>
            <Text style={styles.modalSubHeader}>{SuccessMessage}</Text>
            <View style={styles.modalBottomPart}>
              {/* cancel button */}
              <Pressable
                style={[styles.successButton]}
                onPress={
                  handleOk
                  // setModalVisible(!modalVisible)
                }
              >
                <Text style={[styles.buttonText]}>Ok</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>

      {/* <Button title="Open Modal" onPress={() => setModalVisible(true)} /> */}
    </View>
  );
};

// After login
const WelcomeModal = ({ handleOk }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const lineAnimation = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (modalVisible) {
      Animated.timing(lineAnimation, {
        toValue: 1,
        duration: 5000,
        useNativeDriver: false,
      }).start();
      const timer = setTimeout(() => {
        setModalVisible(false);
      }, 5000); // Auto-exit after 3 seconds

      return () => clearTimeout(timer);
    } else {
      lineAnimation.setValue(0);
    }
  }, [modalVisible]);

  const lineWidth = lineAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: ["0%", "100%"],
  });

  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <Modal
        // animationType="slide"
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#4e4e4e80",
          }}
        >
          <View style={styles.modalView}>
            <View style={styles.progressBarContainer}>
              <Animated.View
                style={[
                  styles.progressBar,
                  { backgroundColor: colors.green, width: lineWidth },
                ]}
              />
            </View>

            <Text style={styles.modalHeaderBig}>Welcome to TMT!</Text>
            <Text style={[styles.modalSubHeader, { textAlign: "center" }]}>
              You are now logged in. <br /> Let's create your first tournament!
            </Text>
            <View style={styles.modalBottomPart}>
              {/* cancel button */}
              <Pressable
                style={[styles.successButton]}
                onPress={handleOk}
                // {() => setModalVisible(!modalVisible)}
              >
                <Text style={[styles.buttonText]}>Ok</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>

      {/* <Button title="Open Modal" onPress={() => setModalVisible(true)} /> */}
    </View>
  );
};

const styles = StyleSheet.create({
  modalView: {
    width: "100%",
    maxHeight: 0.95 * screenHeight,
    maxWidth: 500,
    margin: 20,
    backgroundColor: colors.bgColor,
    borderRadius: 5,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    padding: 15,
    paddingTop: 25,
    position: "relative",
    flexDirection: "column",
    alignItems: "center",
    // borderTopColor: colors.green,
    // borderTopWidth: 5,
  },

  modalHeader: {
    fontFamily: "PlusJakartaSansExtraLight",
    color: colors.white,
    fontSize: 22,
    marginBottom: 10,
  },
  modalSubHeader: {
    fontFamily: "PlusJakartaSansRegular",
    color: colors.white,
    fontSize: 16,
  },
  modalHeaderBig: {
    fontFamily: "PlusJakartaSansSemiBold",
    color: colors.white,
    fontSize: 28,
    marginBottom: 10,
  },
  // modalSubHeader: {
  //   fontFamily: "PlusJakartaSansMedium",
  //   color: colors.white,
  //   fontSize: 16,
  // },

  modalBottomPart: {
    padding: 15,
    flexDirection: "row",
    gap: 10,
    justifyContent: "center",
    marginTop: 20,
  },

  successButton: {
    backgroundColor: colors.green,
    borderWidth: 1,
    borderColor: colors.green,
    borderRadius: 10,
    height: 35,
    minWidth: 120,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 15,
    paddingVertical: 4,
  },
  buttonText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 16,
  },
  trophyIcon: {
    width: 200,
    height: 80,
    resizeMode: "contain",
  },
  progressBarContainer: {
    width: "100%",
    height: 7,
    // backgroundColor: "rgba(255, 255, 255, 0.3)",
    // marginTop: -15,
    position: "absolute",
    top: 0,
    left: 0,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  progressBar: {
    height: "100%",
    backgroundColor: colors.green,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
});

export { AutoExitModalTrophy, SuccessAutoExitModal, WelcomeModal };
