import React, { useContext, useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Pressable,
  TextInput,
  Platform,
  Image,
  Dimensions,
  ActivityIndicator,
} from "react-native";
import { colors } from "../../../../components/reusable/colour";
import UploadImage from "../../../../components/reusable/UploadImage";
import AdminLayout from "../../../../components/include/AdminLayout";
import { ScrollView } from "react-native-gesture-handler";
import PlayerDetailsModal from "../../../../components/reusable/PlayerDetailsModal";
import { getAuthenticatedUser } from "../../../../api/AmplifyUserAPI";
import { getUserMasterData } from "../../../../api/UserMasterAPI";
import { DataStore } from "aws-amplify/datastore";
import { uploadData } from "aws-amplify/storage";
import { AuthenticationContext } from "../../../../authentication/Authentication";
import AddIcon from "react-native-vector-icons/Feather";
import TrashIcon from "react-native-vector-icons/Ionicons";
import {
  CoachMaster,
  PlayerMaster,
  Squad,
  SquadCoachMaster,
  SquadPlayerMaster,
  SquadUserMaster,
  UserMaster,
} from "../../../../models";
import { getCoachMasterData } from "../../../../api/CoachMasterAPI";
import { useNavigation, useRoute } from "@react-navigation/native";
import AppBar from "../../../../components/include/AppBar";
import awsmobile from "./../../../../aws-exports";
import { ErrorModal } from "../../../../components/reusable/AreYouSure";
import { handlePlayerSerialNumber } from "../../../../components/reusable/CreatePlayerSerialNumber";
import { Formik } from "formik";
import * as Yup from "yup";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { SuccessAutoExitModal } from "../../../../components/reusable/AutoExitModal";
import { Modal } from "react-native";
import { flingGestureHandlerProps } from "react-native-gesture-handler/lib/typescript/handlers/FlingGestureHandler";
const screenWidth = Dimensions.get("window").width;

const AddTeamMob = ({ handleClose }) => {
  const defaultLogo: any = require("../../../../../assets/default/defaultLogo.png");
  const navigation: any = useNavigation();
  const [birthDate, setBirthDate] = useState("");
  const bucketName = awsmobile.aws_user_files_s3_bucket;
  const [PlayerDetailsModalVisible, setPlayerDetailsModalVisible] =
    useState(false);

  const [playersList, setPlayersList]: any = useState([
    {
      Name: "",
      DOB: "",
      JerseyNo: "",
      MobileNo: "",
      PhotoURL: "",
      UUID: "",
      playerSummaryId: "",
      coachMasterId: "",
    },
  ]);

  let playerSerialNumber: any;

  const [teamName, setTeamName] = useState("");
  const [teamShortName, setTeamShortName] = useState("");
  const [coachName, setCoachName] = useState("");
  const [coachMobileNo, setCoachMobileNo] = useState("");
  const [adminName, setAdminName] = useState("");
  const [adminMobileNo, setAdminMobileNo] = useState("");
  const [admin2UUID, setadmin2UUID] = useState("");
  const [teamLogoURL, setTeamLogoURL] = useState("");
  const [selectedplayerindex, setselectedplayerindex]: any = useState();

  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [successModalMesg, setSuccessModalMesg] = useState("");
  const [errorModalMesg, setErrorModalMesg] = useState("");
  const [loading, setLoading] = useState(false);

  const route = useRoute();
  const { userUUID, squadIds, currentUserName }: any = route.params || {};

  const [profilePicsArray, setProfilePicsArray] = useState([
    {
      Pic: null,
      UploadedPercentage: "",
      Url: "",
    },
  ]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [uuid, setUUID] = useState("");

  const handlePlayerDetailsModalVisible = () => {
    // console.log("data", data, index);
    // setselectedplayerindex(index);
    setPlayerDetailsModalVisible(true);
    console.log("sasa");
  };
  const closePlayerDetailsModal = () => {
    setPlayerDetailsModalVisible(false);
  };
  let admin1UserData: any;

  const { createAdminUser, getAdminUser } = useContext(AuthenticationContext);

  // async function uploadLogoFileToStorage(uri) {
  //   try {
  //     const response = await fetch(uri);
  //     const blob = await response.blob();
  //     await uploadData({
  //       key: "team/" + teamName.replaceAll(" ", "_") + "/logo.png",
  //       data: blob,
  //     });
  //     let url =
  //       `https://${bucketName}.s3.ap-south-1.amazonaws.com/public/team/` +
  //       teamName.replaceAll(" ", "_") +
  //       "/logo.png";
  //     setTeamLogoURL(url);
  //   } catch (err) {
  //     console.log("Error uploading file:", err);
  //   }
  // }

  async function uploadLogoFileToStorage(uri) {
    try {
      const response = await fetch(uri);
      const blob = await response.blob();
      const randomNumber = (
        Math.floor(Math.random() * 900000) + 100000
      ).toString();
      await uploadData({
        key:
          "team/" +
          teamName.trim().replaceAll(" ", "_") +
          randomNumber +
          "/logo.png",
        data: blob,
      });
      let url =
        `https://${bucketName}.s3.ap-south-1.amazonaws.com/public/team/` +
        teamName.trim().replaceAll(" ", "_") +
        randomNumber +
        "/logo.png";
      setTeamLogoURL(url);

      // contentType: 'image/png' // contentType is optional
    } catch (err) {
      console.log("Error uploading file:", err);
      setLoading(false);
      setErrorModalVisible(true);
      setErrorModalMesg(
        `An error occurred while submitting the form in ${err}`
      );
    }
  }
  const handleSetProfilePic = (e, field, index) => {
    console.log("targetvalue");
    console.log("We are in handle set PP");

    if (field === "Pic") {
      const { Pic, value } = e;
      const list = [...profilePicsArray];
      console.log("Pic : ", e);
      list[index].Pic = e;
      console.log("list", list);
      console.log("We are in Pic");
      setProfilePicsArray(list);
    }
    if (field === "UploadedPercentage") {
      console.log("Target", e);
      const list = [...profilePicsArray];

      const { UploadedPercentage } = e;
      console.log("Li", list[index]);
      list[index].UploadedPercentage = UploadedPercentage;
      console.log("list", list);
      setProfilePicsArray(list);
    } else {
      const { Url, value } = e;
      const list = [...profilePicsArray];

      list[index].Url = e;
      console.log("list", list);
      console.log("We are in handle set Url");
      setProfilePicsArray(list);
    }
  };

  async function uploadProfilePicToStorage(uri, index) {
    const number = Math.floor(100000 + Math.random() * 900000)
      .toString()
      .substring(0, 4);
    try {
      const response = await fetch(uri);
      const blob = await response.blob();
      await uploadData({
        key: "userPhotos/" + number + ".jpg",
        data: blob,
        options: {
          onProgress: ({ transferredBytes, totalBytes }) => {
            if (totalBytes) {
              console.log(
                `Upload progress ${Math.round(
                  (transferredBytes / totalBytes) * 100
                )} %`
              );
            }
            let percentage = Math.round((transferredBytes / totalBytes) * 100);
            let url =
              `https://${bucketName}.s3.ap-south-1.amazonaws.com/public/userPhotos/` +
              number +
              ".jpg";
            console.log("Percentage : ", percentage);

            if (percentage === 100) {
              console.log("Percentage is 100");
              handleInputChange(url, "PhotoURL", index);
            }
            handleSetProfilePic(percentage, "UploadedPercentage", index);
            handleSetProfilePic(url, "Url", index);
          },
        },
        // contentType: 'image/png' // contentType is optional
      });
    } catch (err) {
      console.log("Error uploading file:", err);
    }
  }
  const handleImageSelection = (uri, kindOfFile, index) => {
    if (kindOfFile == "logo") {
      setTeamLogoURL(uri);
      uploadLogoFileToStorage(uri);
    } else if (kindOfFile == "profilePic") {
      uploadProfilePicToStorage(uri, index);
    }
  };

  const handleInputChange = (e, field, index) => {
    console.log("Field : ", field);
    console.log(e);
    const list = [...playersList];

    if (field === "Name") {
      const { Name, value } = e;

      list[index].Name = e;
      console.log("list", list);
      setPlayersList(list);
    } else if (field === "DOB") {
      const { DOB, value } = e;

      list[index].DOB = e;
      console.log("list", list);
      setPlayersList(list);
    } else if (field === "JerseyNo") {
      const { JerseyNo, value } = e;

      list[index].JerseyNo = e;
      console.log("list", list);
      setPlayersList(list);
    } else if (field === "MobileNo") {
      const { MobileNo, value } = e.target;

      list[index].MobileNo = e.target.value;
      console.log("list", list);
      setPlayersList(list);
    } else if (field === "PhotoURL") {
      const { PhotoURL, value } = e;
      list[index].PhotoURL = e;
      console.log("list", list);
      setPlayersList(list);
    } else if (field === "UUID") {
      const { UUID, value } = e;
      const list = [...playersList];
      list[index].UUID = e;
      console.log("list", list);
      setPlayersList(list);
    }
  };

  const cognitoUserDetails = async (phoneNumber) => {
    const response = await getAdminUser(phoneNumber);
    if (response) {
      // console.log("UserDetails : ", response.statusCode);
    }

    return response;
  };
  const addUser = async (phoneNumber) => {
    let finalResponse;
    const userAttribute = [
      {
        Name: "phone_number" /* required */,
        Value: phoneNumber,
      },
    ];
    console.log("Respa", finalResponse);
    return finalResponse;
  };

  const handleSubmitPlayerDetails = (newPlayerData) => {
    console.log("debiug 2");
    // Update the player data in the TeamParticipation component's state
    setPlayersList((prevPlayersList) => [...prevPlayersList, ...newPlayerData]);
    console.log("debiug 4");
    setPlayerDetailsModalVisible(false);
  };

  const handleInputTeamDetailsChange = (fieldName, value) => {
    switch (fieldName) {
      case "teamName":
        console.log("Team Name", value);
        setTeamName(value);
        break;
      case "coachName":
        console.log("Coach Name", value);
        setCoachName(value);
        break;
      case "coachMobileNo":
        console.log("Coach Mobile No", value);
        setCoachMobileNo(value);
        break;
      case "adminName":
        console.log("Admin Name", value);
        setAdminName(value);
        break;
      case "adminMobileNo":
        console.log("Admin Mobile No", value);
        setAdminMobileNo(value);
        break;
      default:
        // Handle default case if needed
        console.log("default case is executed !!");
        break;
    }
  };

  const handleCloseErrorModal = () => {
    setErrorModalVisible(false);
  };

  const handleFormSubmit = (formData: any) => {
    // Check if selectedplayerindex is valid
    if (selectedplayerindex >= 0 && selectedplayerindex < playersList.length) {
      // Create a copy of formData
      // let data = { ...formData };
      console.log("data", formData);
      // Extract the necessary fields and update the player object in playersList
      const updatedPlayersList = [...playersList];
      updatedPlayersList[selectedplayerindex] = {
        ...updatedPlayersList[selectedplayerindex],
        Name: formData.Name,
        DOB:
          formData.DOB && typeof formData.DOB === "string"
            ? formData.DOB.slice(0, 10)
            : "",
        JerseyNo: formData.JerseyNo,
        MobileNo: formData.MobileNo,
        PhotoURL: formData.PhotoURL,
      };
      console.log("updatedPlayersList", updatedPlayersList);
      // Update the state with the new playersList
      setPlayersList(updatedPlayersList);
      console.log(
        "Form submitted:",
        formData.DOB && typeof formData.DOB === "string"
          ? formData.DOB.slice(0, 10)
          : "",
        selectedplayerindex,
        formData
      );
    } else {
      console.error("Invalid selected player index or players list.");
    }
  };

  const generateSerialNumber = async () => {
    const originLocation = window.location.origin;
    console.log("origin location", originLocation);

    try {
      const serialNumber = await handlePlayerSerialNumber(originLocation);
      // setPlayerSerialNumber(serialNumber);
      playerSerialNumber = serialNumber;
      console.log("Generated Player Serial Number:", serialNumber);
    } catch (error) {
      console.error("Error generating player serial number", error);
    }
  };

  const handleOnSubmit = async () => {
    let admin2UserData: any,
      coachSummary: any,
      coachUUID: any,
      userMasterCoach: any,
      userMasterAdmin: any,
      resCognitoAdmin2: any,
      squad: any,
      squadUserMaster: any,
      squadCoachMaster: any,
      squadPlayerMaster: any,
      resAdmin2UUID: any;
    //addAdminData2
    try {
      setLoading(true);
      resCognitoAdmin2 = await cognitoUserDetails("+91" + adminMobileNo);

      if (resCognitoAdmin2.statusCode === 400) {
        const resAddAdmin2 = await addUser("+91" + adminMobileNo);
        console.log("Resp : ", resAddAdmin2);
        resAdmin2UUID = resAddAdmin2.User.Username;
        console.log("Admin UUID ", resAdmin2UUID);
      } else {
        resAdmin2UUID = resCognitoAdmin2.Username;
        console.log("Else user data uuid : ", resAdmin2UUID);
      }
      await getUserMasterData(resAdmin2UUID).then(async (response) => {
        if (response) {
          console.log("Admin Data", response);
          admin2UserData = response;
        } else {
          console.log("No admin data found");
          try {
            userMasterAdmin = await DataStore.save(
              new UserMaster({
                UUID: resAdmin2UUID,
                Name: adminName.trim(),
                ContactNo: Number(adminMobileNo),
                EmailId: "",
                PhotoURL: "",
                CreatedBy: admin1UserData?.Name,
                LastUpdatedBy: admin1UserData?.Name,
                RoleCoach: false,
                RolePlayer: false,
              })
            );
            console.log("UserMaster Admin: ", userMasterAdmin);
          } catch (error) {
            console.log(
              "An error occured while creating the UserMaster record",
              error
            );
            setLoading(false);
            setErrorModalVisible(true);
            setErrorModalMesg(
              `An error occured while creating the UserMaster record${error}`
            );
          }
        }
      });

      const admin2Obj = {
        UUID: resAdmin2UUID,
        Name: adminName,
        PhotoURL: "",
      };
      const admin1Obj = {
        UUID: uuid,
        Name: admin1UserData?.Name.trim(),
        PhotoURL: admin1UserData?.PhotoURL,
      };
      //addCoachData
      const resCoach = await cognitoUserDetails("+91" + coachMobileNo);
      console.log(resCoach.statusCode);
      if (resCoach.statusCode === 400) {
        const resAddCoach = await addUser("+91" + coachMobileNo);
        coachUUID = resAddCoach.User.Username;
      } else {
        console.log("Coach Found data : ", resCoach);
        coachUUID = resCoach.Username;
      }
      await getUserMasterData(coachUUID).then(async (response) => {
        if (response) {
          console.log("Coach Data", response);
          userMasterCoach = response;
          if (userMasterCoach.userMasterCoachSummaryId == "") {
            console.log("We are in if Coach Summary");
            try {
              coachSummary = await DataStore.save(
                new CoachMaster({
                  Name: coachName,
                  Games: 0,
                  Won: 0,
                  Lost: 0,
                  Drawn: 0,
                })
              );
            } catch (err) {
              console.log("Error in saving coach summary : ", coachSummary);
            }
          } else {
            console.log("We are in else of coach summary");
            await getCoachMasterData(
              userMasterCoach.userMasterCoachSummaryId
            ).then((value) => {
              coachSummary = value;
            });
          }
        } else {
          console.log("No coach data found");
          try {
            coachSummary = await DataStore.save(
              new CoachMaster({
                Name: coachName.trim(),
                Games: 0,
                Won: 0,
                Lost: 0,
                Drawn: 0,
              })
            );
            userMasterCoach = await DataStore.save(
              new UserMaster({
                UUID: coachUUID,
                Name: coachName.trim(),
                ContactNo: Number(coachMobileNo),
                EmailId: "",
                PhotoURL: "",
                CreatedBy: admin1UserData?.Name,
                LastUpdatedBy: admin1UserData?.Name,
                RoleCoach: true,
                RolePlayer: false,
                CoachSummary: coachSummary,
              })
            );
            const updateCoachUserMasterId = {
              coachMasterCoachDataUUID: userMasterCoach.UUID,
            };

            let updatedItem;
            const original = await DataStore.query(
              CoachMaster,
              userMasterCoach.userMasterCoachSummaryId
            );
            try {
              if (updateCoachUserMasterId) {
                console.log("Current Item : ", updateCoachUserMasterId);
                updatedItem = CoachMaster.copyOf(original, (ele) => {
                  ele.coachMasterCoachDataUUID =
                    updateCoachUserMasterId.coachMasterCoachDataUUID;
                });
                await DataStore.save(updatedItem);

                console.log("Updated Item:", updatedItem);
              } else {
                console.error("Item not found");
              }
              return updatedItem;
            } catch (error) {
              console.error("Error updating item:", error);
            }

            console.log("UserMaster Coach : ", userMasterCoach);
          } catch (error) {
            console.log("Error : ", error);
            setLoading(false);
            setErrorModalVisible(true);
            setErrorModalMesg(
              `An error occured while creating the coachSummary record${error}`
            );
          }
        }
      });

      try {
        squad = await DataStore.save(
          new Squad({
            Name: teamName,
            Logo: teamLogoURL,
            TournamentsPlayed: 0,
            CreatedBy: admin1UserData?.Name,
            LastUpdatedBy: admin1UserData?.Name,
            Admin1: admin1Obj,
            Admin2: admin2Obj,
          })
        );
        console.log("Squad Data : ", squad);
      } catch (error) {
        console.log(
          "An error occured while creating the UserMaster record",
          error
        );
        setLoading(false);
        setErrorModalVisible(true);
        setErrorModalMesg(
          `An error occured while creating the UserMaster record${error}`
        );
      }
      if (admin1UserData) {
        try {
          squadUserMaster = await DataStore.save(
            new SquadUserMaster({
              userMasterUUID: admin1UserData?.UUID,
              squadId: squad.id,
            })
          );
          console.log("Squad Usermaster data for admin1 : ", squadUserMaster);
        } catch (error) {
          console.log(
            "Error in creating squad userMaster for admin 1 : ",
            error
          );
          setLoading(false);
          setErrorModalVisible(true);
          setErrorModalMesg(
            `An error occured while creating the SquadUserMaster record${error}`
          );
        }
      }
      if (userMasterAdmin) {
        try {
          squadUserMaster = await DataStore.save(
            new SquadUserMaster({
              userMasterUUID: userMasterAdmin.UUID,
              squadId: squad.id,
            })
          );
          console.log("Squad Usermaster data for admin2 : ", squadUserMaster);
        } catch (error) {
          console.log(
            "Error in creating squad userMaster for admin 2 : ",
            error
          );
          setLoading(false);
          setErrorModalVisible(true);
          setErrorModalMesg(
            `An error occured while creating the SquadUserMaster record${error}`
          );
        }
      }
      if (coachSummary && userMasterCoach) {
        try {
          squadUserMaster = await DataStore.save(
            new SquadUserMaster({
              userMasterUUID: userMasterCoach.UUID,
              squadId: squad.id,
            })
          );
          console.log("Squad Usermaster data for coach : ", squadUserMaster);
        } catch (error) {
          console.log("Error in creating squad userMaster for coach : ", error);
          setLoading(false);
          setErrorModalVisible(true);
          setErrorModalMesg(
            `An error occured while creating the SquadUserMaster record${error}`
          );
        }

        try {
          squadCoachMaster = await DataStore.save(
            new SquadCoachMaster({
              coachMasterId: coachSummary.id,
              squadId: squad.id,
            })
          );
          console.log("Squad Usermaster Coach data : ", squadCoachMaster);
        } catch (error) {
          console.log("Error in creating squad userMaster coach : ", error);
          setLoading(false);
          setErrorModalVisible(true);
          setErrorModalMesg(
            `An error occured while creating the SquadCoachMaster record${error}`
          );
        }
      }

      // Call handleFormSubmit with the required formData
      await handleFormSubmit(playersList);

      for (let i = 0; i < playersList.length; i++) {
        let playerSummaryData;
        let playerUserMasterData;
        let res = await cognitoUserDetails("+91" + playersList[i].MobileNo);

        if (res.statusCode === 400) {
          console.log("Name : ", playersList[i].Name);
          console.log("PhotoURL : ", playersList[i].PhotoURL);
          console.log("Mobile No : ", playersList[i].MobileNo);
          console.log("No Player found");
          console.log("------------------");
          const resAddPlayer = await addUser("+91" + playersList[i].MobileNo);
          console.log("Resp : ", resAddPlayer);
          playersList[i].UUID = resAddPlayer.User.Username;
          console.log("Player ", i, "UUID ", playersList[i].UUID);
        } else {
          playersList[i].UUID = res.Username;
          await getUserMasterData(playersList[i].UUID).then(async (value) => {
            if (value.userMasterPlayerSummaryId == "") {
              await generateSerialNumber();
              try {
                playerSummaryData = await DataStore.save(
                  new PlayerMaster({
                    Name: playersList[i].Name,
                    PhotoURL: playersList[i].PhotoURL,
                    JerseyNo: playersList[i].JerseyNo,
                    PlayerData: value,
                    SrNo: playerSerialNumber,
                  })
                );
                console.log("Player Summary Data : ", playerSummaryData);
                console.log("Player Summary Data Id : ", playerSummaryData.id);
              } catch (e) {
                console.log("Player Summary Error : ", e);
              }
            }
          });
        }
        await getUserMasterData(playersList[i].UUID).then(async (response) => {
          if (response) {
            console.log("Player ", i, " Data", response);
            playerUserMasterData = response;
            // console.log("Player ID : ", playerData);
            if (playerUserMasterData.userMasterPlayerSummaryId == "") {
              await generateSerialNumber();
              try {
                playerSummaryData = await DataStore.save(
                  new PlayerMaster({
                    Name: playersList[i].Name,
                    PhotoURL: playersList[i].PhotoURL,
                    JerseyNo: playersList[i].JerseyNo,
                    PlayerData: playerUserMasterData,
                    SrNo: playerSerialNumber,
                  })
                );
                console.log("Player Summary Data : ", playerSummaryData);
                console.log("Player Summary Data Id : ", playerSummaryData.id);
              } catch (e) {
                console.log("Player Summary Error : ", e);
              }
            }
          } else {
            console.log("No player data found");
            await generateSerialNumber();
            try {
              playerSummaryData = await DataStore.save(
                new PlayerMaster({
                  Name: playersList[i].Name,
                  JerseyNo: playersList[i].JerseyNo,
                  PhotoURL: playersList[i].PhotoURL,
                  SrNo: playerSerialNumber,
                })
              );
              playerUserMasterData = await DataStore.save(
                new UserMaster({
                  UUID: playersList[i].UUID,
                  Name: playersList[i].Name,
                  ContactNo: Number(playersList[i].MobileNo),
                  EmailId: "",
                  PhotoURL: playersList[i].PhotoURL,
                  CreatedBy: admin1UserData?.Name,
                  LastUpdatedBy: admin1UserData?.Name,
                  RoleCoach: false,
                  RolePlayer: true,
                  PlayerSummary: playerSummaryData,
                })
              );
              console.log("UserMaster Player: ", playerUserMasterData);
              const updatePlayerMasterPlayerDataUUID = {
                playerMasterPlayerDataUUID: playerUserMasterData.UUID,
              };

              let updatedItem;
              console.log(
                "UserMasterPlayerSummary Id : ",
                playerUserMasterData.userMasterPlayerSummaryId
              );
              const original = await DataStore.query(
                PlayerMaster,
                playerUserMasterData.userMasterPlayerSummaryId
              );
              try {
                if (updatePlayerMasterPlayerDataUUID) {
                  console.log(
                    "Current Item : ",
                    updatePlayerMasterPlayerDataUUID
                  );
                  updatedItem = PlayerMaster.copyOf(original, (ele) => {
                    ele.playerMasterPlayerDataUUID =
                      updatePlayerMasterPlayerDataUUID.playerMasterPlayerDataUUID;
                  });
                  await DataStore.save(updatedItem);

                  console.log("Updated Item:", updatedItem);
                } else {
                  console.error("Item not found");
                }
                squadPlayerMaster = await DataStore.save(
                  new SquadPlayerMaster({
                    squadId: squad.id,
                    playerMasterId: playerSummaryData.id,
                  })
                );
                console.log("Squad Playermaster data : ", squadPlayerMaster);
                return updatedItem;
              } catch (error) {
                console.error("Error updating item:", error);
              }
            } catch (e) {
              console.log("Error : ", e);
            }
          }
        });
      }

      setSuccessModalVisible(true);
      setSuccessModalMesg("Team details submitted successfully");
      navigation.navigate("MyTeams", {
        userUUID: userUUID,
        squadIds: squadIds,
        currentUserName: currentUserName,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorModalVisible(true);
      setErrorModalMesg(`An error occured while submitting the form ${error}`);
      console.log("Error occured while submitting the data", error);
    }

    // alert("team details submitted successfully");
    // navigation.navigate("MyTeams");
  };

  const handleOnSubmitNew = async () => {
    try {
      setLoading(true); // Start loading

      let admin2UserData: any,
        coachSummary: any,
        coachUUID: any,
        userMasterCoach: any,
        userMasterAdmin: any,
        resCognitoAdmin2: any,
        squad: any,
        squadUserMaster: any,
        squadCoachMaster: any,
        resAdmin2UUID: any;

      // Step: 1:Coach functionality

      if (coachMobileNo && coachMobileNo.length > 0) {
        const resCoach = await cognitoUserDetails("+91" + coachMobileNo);
        console.log(resCoach.statusCode);
        if (resCoach.statusCode === 400) {
          const resAddCoach = await addUser("+91" + coachMobileNo);
          coachUUID = resAddCoach?.User?.Username;
        } else {
          console.log("Coach Found data : ", resCoach);
          coachUUID = resCoach?.Username;
        }
        await getUserMasterData(coachUUID).then(async (response) => {
          if (response) {
            console.log("Coach Data", response);
            userMasterCoach = response;
            if (userMasterCoach.userMasterCoachSummaryId == "") {
              console.log("We are in if Coach Summary");
              try {
                coachSummary = await DataStore.save(
                  new CoachMaster({
                    Name: coachName,
                    Games: 0,
                    Won: 0,
                    Lost: 0,
                    Drawn: 0,
                  })
                );
              } catch (err) {
                console.log("Error in saving coach summary : ", coachSummary);
                setLoading(false);
              }
            } else {
              console.log("We are in else of coach summary");
              await getCoachMasterData(
                userMasterCoach.userMasterCoachSummaryId
              ).then((value) => {
                coachSummary = value;
              });
            }
          } else {
            console.log("No coach data found");
            try {
              coachSummary = await DataStore.save(
                new CoachMaster({
                  Name: coachName,
                  Games: 0,
                  Won: 0,
                  Lost: 0,
                  Drawn: 0,
                })
              );
              userMasterCoach = await DataStore.save(
                new UserMaster({
                  UUID: coachUUID,
                  Name: coachName,
                  ContactNo: Number(coachMobileNo),
                  EmailId: "",
                  PhotoURL: "",
                  CreatedBy: admin1UserData.Name,
                  LastUpdatedBy: admin1UserData.Name,
                  RoleCoach: true,
                  RolePlayer: false,
                  CoachSummary: coachSummary,
                })
              );
              const updateCoachUserMasterId = {
                coachMasterCoachDataUUID: userMasterCoach.UUID,
              };
              let updatedItem;
              const original = await DataStore.query(
                CoachMaster,
                userMasterCoach.userMasterCoachSummaryId
              );
              try {
                if (updateCoachUserMasterId) {
                  console.log("Current Item : ", updateCoachUserMasterId);
                  updatedItem = CoachMaster.copyOf(original, (ele) => {
                    ele.coachMasterCoachDataUUID =
                      updateCoachUserMasterId.coachMasterCoachDataUUID;
                  });
                  await DataStore.save(updatedItem);
                  console.log("Updated Item:", updatedItem);
                } else {
                  console.error("Item not found");
                }
                return updatedItem;
              } catch (error) {
                console.error("Error updating item:", error);
                setLoading(false);
              }
              console.log("UserMaster Coach : ", userMasterCoach);
            } catch (e) {
              console.log("Error : ", e);
              setLoading(false);
            }
          }
        });
      } else {
        console.log("CoachMobile number is not provided");
      }

      // Step : 2 :Admin mobile functionality

      if (adminMobileNo && adminMobileNo.length > 0) {
        resCognitoAdmin2 = await cognitoUserDetails("+91" + adminMobileNo);
        if (resCognitoAdmin2.statusCode === 400) {
          const resAddAdmin2 = await addUser("+91" + adminMobileNo);
          console.log("Resp : ", resAddAdmin2);
          resAdmin2UUID = resAddAdmin2?.User?.Username;
          console.log("Admin UUID ", resAdmin2UUID);

          if (resAdmin2UUID) {
            try {
              userMasterAdmin = await DataStore.save(
                new UserMaster({
                  UUID: resAdmin2UUID,
                  Name: adminName,
                  ContactNo: Number(adminMobileNo),
                  EmailId: "",
                  PhotoURL: "",
                  CreatedBy: admin1UserData.Name,
                  LastUpdatedBy: admin1UserData.Name,
                  RoleCoach: false,
                  RolePlayer: false,
                })
              );
              console.log("UserMaster Admin: ", userMasterAdmin);
            } catch (e) {
              console.log("Error : ", e);
              setLoading(false);
            }
          }
        } else {
          resAdmin2UUID = resCognitoAdmin2?.Username;
          console.log("Else user data uuid : ", resAdmin2UUID);

          await getUserMasterData(resAdmin2UUID).then(async (response) => {
            if (response) {
              console.log("Admin Data", response);
              admin2UserData = response;
              console.log("admin2UserData", admin2UserData);
            } else {
              console.log("No admin data found");

              const resAddAdmin2 = await addUser("+91" + adminMobileNo);
              console.log("Resp : ", resAddAdmin2);
              resAdmin2UUID = resAddAdmin2?.User?.Username;
              console.log("Admin UUID ", resAdmin2UUID);

              try {
                userMasterAdmin = await DataStore.save(
                  new UserMaster({
                    UUID: resAdmin2UUID,
                    Name: adminName,
                    ContactNo: Number(adminMobileNo),
                    EmailId: "",
                    PhotoURL: "",
                    CreatedBy: admin1UserData.Name,
                    LastUpdatedBy: admin1UserData.Name,
                    RoleCoach: false,
                    RolePlayer: false,
                  })
                );
                console.log("UserMaster Admin: ", userMasterAdmin);
              } catch (e) {
                console.log("Error : ", e);
                setLoading(false);
              }
            }
          });
        }
      } else {
        console.log("admin mobile number not found");
      }

      // Squad functionality

      const admin2Obj = {
        UUID: resAdmin2UUID ? resAdmin2UUID : "",
        Name: adminName,
        PhotoURL: "",
      };
      const admin1Obj = {
        UUID: uuid,
        Name: admin1UserData.Name,
        PhotoURL: admin1UserData.PhotoURL,
      };

      // Step 3: Squad functionality

      try {
        squad = await DataStore.save(
          new Squad({
            Name: teamName,
            Logo: teamLogoURL,
            TournamentsPlayed: 0,
            CreatedBy: admin1UserData.Name,
            LastUpdatedBy: admin1UserData.Name,
            Admin1: admin1Obj,
            Admin2: admin2Obj,
          })
        );
        console.log("Squad Data : ", squad);
      } catch (err) {
        console.log("Error in creating squad : ", err);
        setLoading(false);
      }

      if (admin1UserData) {
        try {
          squadUserMaster = await DataStore.save(
            new SquadUserMaster({
              userMasterUUID: admin1UserData.UUID,
              squadId: squad.id,
            })
          );
          console.log("Squad Usermaster data for admin1 : ", squadUserMaster);
        } catch (err) {
          console.log("Error in creating squad userMaster for admin 1 : ", err);
          setLoading(false);
        }
      }
      if (userMasterAdmin) {
        try {
          squadUserMaster = await DataStore.save(
            new SquadUserMaster({
              userMasterUUID: userMasterAdmin.UUID,
              squadId: squad.id,
            })
          );
          console.log("Squad Usermaster data for admin2 : ", squadUserMaster);
        } catch (err) {
          console.log("Error in creating squad userMaster for admin 2 : ", err);
          setLoading(false);
        }
      }
      if (coachSummary && userMasterCoach) {
        try {
          squadUserMaster = await DataStore.save(
            new SquadUserMaster({
              userMasterUUID: userMasterCoach.UUID,
              squadId: squad.id,
            })
          );
          console.log("Squad Usermaster data for coach : ", squadUserMaster);
        } catch (err) {
          console.log("Error in creating squad userMaster for coach : ", err);
          setLoading(false);
        }
        try {
          squadCoachMaster = await DataStore.save(
            new SquadCoachMaster({
              coachMasterId: coachSummary.id,
              squadId: squad.id,
            })
          );
          console.log("Squad Usermaster Coach data : ", squadCoachMaster);
        } catch (err) {
          console.log("Error in creating squad userMaster coach : ", err);
          setLoading(false);
        }
      }

      try {
        await handleFormSubmit(playersList);
      } catch (error) {
        console.log("Error occured while adding players in a squad", error);
        setLoading(false);
        setErrorModalVisible(true);
        setErrorModalMesg(
          `An error occurred while adding players in a squad ${error}`
        );
      }
      // Player functionality
    } catch (error) {
      console.error("Error submitting form:", error);
      setLoading(false);
      setErrorModalMesg(
        `An error occurred while submitting the form in ${error}`
      );
      setErrorModalVisible(true);
    } finally {
      setLoading(false); // Stop loading
    }
    // final statement
    setSuccessModalVisible(true);
    setSuccessModalMesg("Team details submitted successfully");
    // setModalVisible(false);
    navigation.navigate("MyTeams", {
      userUUID: userUUID,
      squadIds: squadIds,
      currentUserName: currentUserName,
    });
  };

  const getUserdata = async () => {
    let userDetails = await AsyncStorage.getItem("UserDetail");
    if (userDetails) {
      const userData = await DataStore.query(UserMaster, userDetails);
      console.log("userData", userData);
      admin1UserData = userData;
      setUUID(userData.UUID);
    } else {
      console.log("No user found in temp storage");
    }
  };

  useEffect(() => {
    console.log("");
    getUserdata();
    let list1 = [];
    for (let i = 0; i < 2; i++) {
      list1.push({
        Name: "",
        DOB: "",
        JerseyNo: "",
        MobileNo: "",
        PhotoURL: "",
        UUID: "",
        playerSummaryId: "",
        coachMasterId: "",
      });
    }
    console.log("PlayerList Length : ", list1.length);
    console.log("list1", list1);
    // setPlayersList(list1);
    if (Platform.OS === "web" && screenWidth > 760) {
      setPlayersList(list1);
    } else {
      setPlayersList([]);
    }
    let list2 = [];
    for (let i = 0; i < 2; i++) {
      list2.push({
        Pic: null,
        UploadedPercentage: "",
        Url: "",
      });
    }
    setProfilePicsArray(list2);
    console.log("ProfilePics Length : ", list2.length);

    console.log("playersList", playersList);
  }, []);

  const handleBack = () => {
    navigation.goBack();
  };

  const validationSchema = Yup.object().shape({
    teamName: Yup.string()
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        "Team Name must not contain special characters"
      )
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Team name required"),
    coachName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Coach name required"),
    coachMobileNo: Yup.string()
      .matches(/^[0-9]{10}$/, "Invalid Mobile Number")
      .required("Coach mobile number required"),
    adminName: Yup.string().matches(
      /^[a-zA-Z\s]+$/,
      "Manager Name must contain only letters and spaces"
    ),
    adminMobileNo: Yup.string().matches(/^[0-9]{10}$/, "Invalid Mobile Number"),
  });

  return (
    <>
      {Platform.OS !== "web" && (
        <AppBar
          title={"Add Team"}
          showBackButton={true}
          onBackPress={handleBack}
        />
      )}
      <AdminLayout>
        <ScrollView>
          <Formik
            initialValues={{
              teamName: "",
              coachName: "",
              coachMobileNo: "",
              adminName: "",
              adminMobileNo: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values: any) => {
              console.log("values", values);
              // handleOnSubmit();
              handleOnSubmitNew();
            }}
            enableReinitialize={true}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <View style={{ padding: 5, width: "100%", paddingBottom: 50 }}>
                <View
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 15,
                  }}
                >
                  <UploadImage
                    onImageSelect={handleImageSelection}
                    kindOfFile="logo"
                  />
                </View>
                <View
                  style={{
                    gap: 5,
                  }}
                >
                  <View style={styles.teamModalHeadingWrapper2}>
                    <View style={styles.teamModalHeadingWrapper}>
                      <Text style={styles.teamModalLabel}>Team Name</Text>
                      <TextInput
                        style={styles.TeamModalInput}
                        placeholder={"Enter Team Name"}
                        value={values.teamName}
                        id="teamName"
                        inputMode="text"
                        onChangeText={(text) => {
                          handleChange("teamName")(text);
                          handleInputTeamDetailsChange("teamName", text);
                        }}
                        onBlur={handleBlur("teamName")}
                        placeholderTextColor={colors.darkGray}
                      />
                      {touched.teamName && errors.teamName && (
                        <View>
                          <Text style={{ color: "red" }}>
                            {errors.teamName.toString()}
                          </Text>
                        </View>
                      )}
                    </View>
                    {/* <View style={styles.teamModalHeadingWrapper}>
                      <Text style={styles.teamModalLabel}>Team Short Name</Text>
                      <TextInput
                        style={styles.TeamModalInput}
                        placeholder="Enter three characters"
                        value={teamShortName}
                        onChangeText={setTeamShortName}
                        placeholderTextColor={colors.darkGray}
                      />
                    </View> */}
                  </View>
                  <View style={styles.teamModalHeadingWrapper2}>
                    <View style={styles.teamModalHeadingWrapper}>
                      <Text style={styles.teamModalLabel}>Coach Name</Text>
                      <TextInput
                        style={styles.TeamModalInput}
                        placeholder="Enter Coach Name"
                        value={values.coachName}
                        id="coachName"
                        onChangeText={(text) => {
                          handleChange("coachName")(text);
                          handleInputTeamDetailsChange("coachName", text);
                        }}
                        inputMode="text"
                        onBlur={handleBlur("coachName")}
                        placeholderTextColor={colors.darkGray}
                      />
                      {touched.coachName && errors.coachName && (
                        <View>
                          <Text style={{ color: "red" }}>
                            {errors.coachName.toString()}
                          </Text>
                        </View>
                      )}
                    </View>
                    <View style={styles.teamModalHeadingWrapper}>
                      <Text style={styles.teamModalLabel}>
                        Coach Mobile No.
                      </Text>
                      <TextInput
                        style={styles.TeamModalInput}
                        placeholder="Enter Mobile No"
                        value={values.coachMobileNo}
                        id="coachMobileNo"
                        onChangeText={(text) => {
                          handleChange("coachMobileNo")(text);
                          handleInputTeamDetailsChange("coachMobileNo", text);
                        }}
                        inputMode="numeric"
                        maxLength={10}
                        onBlur={handleBlur("coachMobileNo")}
                        placeholderTextColor={colors.darkGray}
                      />
                      {touched.coachMobileNo && errors.coachMobileNo && (
                        <View>
                          <Text style={{ color: "red" }}>
                            {errors.coachMobileNo.toString()}
                          </Text>
                        </View>
                      )}
                    </View>
                  </View>
                  <View style={styles.teamModalHeadingWrapper2}>
                    <View style={styles.teamModalHeadingWrapper}>
                      <Text style={styles.teamModalLabel}>Manager Name</Text>
                      <TextInput
                        style={styles.TeamModalInput}
                        placeholder="Enter Manager Name"
                        value={values.adminName}
                        id="adminName"
                        onChangeText={(text) => {
                          handleChange("adminName")(text);
                          handleInputTeamDetailsChange("adminName", text);
                        }}
                        // onChange={handleChange}
                        inputMode="text"
                        onBlur={handleBlur("adminName")}
                        placeholderTextColor={colors.darkGray}
                      />
                      {touched.adminName && errors.adminName && (
                        <View>
                          <Text style={{ color: "red" }}>
                            {errors.adminName.toString()}
                          </Text>
                        </View>
                      )}
                    </View>
                    <View style={styles.teamModalHeadingWrapper}>
                      <Text style={styles.teamModalLabel}>
                        Manager Mobile No.
                      </Text>
                      <TextInput
                        style={styles.TeamModalInput}
                        placeholder="Enter Manager No"
                        value={values.adminMobileNo}
                        id="adminMobileNo"
                        onChangeText={(text) => {
                          adminName;
                          handleChange("adminMobileNo")(text);
                          handleInputTeamDetailsChange("adminMobileNo", text);
                        }}
                        onChange={handleChange}
                        inputMode="numeric"
                        maxLength={10}
                        onBlur={handleBlur("adminMobileNo")}
                        placeholderTextColor={colors.darkGray}
                      />
                      {touched.adminMobileNo && errors.adminMobileNo && (
                        <View>
                          <Text style={{ color: "red" }}>
                            {errors.adminMobileNo.toString()}
                          </Text>
                        </View>
                      )}
                    </View>
                  </View>
                </View>
                {/* <Text style={[styles.teamModalLabel, { paddingVertical: 10 }]}>
            Team Members (0/20)
          </Text> */}
                <View
                  style={{
                    marginTop: 15,
                    alignItems: "center",
                    marginBottom: 15,
                  }}
                >
                  <Pressable
                    style={styles.addTeamBtn}
                    onPress={() => handlePlayerDetailsModalVisible()}
                  >
                    <AddIcon
                      name="plus-circle"
                      size={18}
                      color={colors.white}
                    />
                    <Text
                      style={{
                        color: colors.white,
                        fontSize: 14,
                        fontFamily: "PlusJakartaSansSemiBold",
                      }}
                    >
                      Add Player
                    </Text>
                  </Pressable>
                </View>

                <View style={{ gap: 10, marginBottom: 10 }}>
                  {playersList && playersList.length > 0 ? (
                    playersList.map((player: any, index: any) => (
                      <View style={styles.playerCard} key={index}>
                        <Pressable
                          // onPress={() => handleDeletePlayer(i)}
                          style={styles.scoreSheetDeleteBtn}
                        >
                          <TrashIcon
                            name="trash-bin-sharp"
                            size={18}
                            color="red"
                          />
                        </Pressable>
                        <Image
                          source={{
                            uri: player.PhotoURL
                              ? player.PhotoURL
                              : "../../../../../assets/default/defaultLogo.png",
                          }}
                          style={{ width: 80, height: 80, borderRadius: 80 }}
                          resizeMode="contain"
                        />
                        {/* <UploadImage
                          style={{ width: 30, height: 30 }}
                          onImageSelect={handleImageSelection}
                          kindOfFile="profilePic"
                          index={index}
                        /> */}
                        <View
                          style={{
                            flexDirection: "column",
                            gap: 5,
                            flex: 1,
                            padding: 15,
                          }}
                        >
                          <Text style={{ color: colors.white }}>
                            {player.Name ? player.Name : null}
                          </Text>
                          <Text style={{ color: colors.white }}>
                            {player.DOB ? player.DOB : null}
                          </Text>
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Text style={{ color: colors.white }}>
                              {player.MobileNo ? player.MobileNo : null}
                            </Text>
                            <Text style={{ color: colors.white }}>
                              {player.JerseyNo ? player.JerseyNo : null}
                            </Text>
                          </View>
                        </View>
                      </View>
                    ))
                  ) : (
                    <Text style={{ color: "white" }}>
                      No any players added yet !
                    </Text>
                  )}
                </View>

                <PlayerDetailsModal
                  isVisible={PlayerDetailsModalVisible}
                  onClose={closePlayerDetailsModal}
                  onSubmit={handleSubmitPlayerDetails}
                  imageSelection={handleImageSelection}
                />

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: 20,
                    marginTop: 15,
                    paddingBottom: 40,
                  }}
                >
                  <Pressable
                    onPress={() => navigation.navigate("MyTeams")}
                    style={[
                      styles.btnPressable,
                      { borderWidth: 1.5, borderColor: colors.white },
                    ]}
                  >
                    <Text style={styles.btnText}>Cancel</Text>
                  </Pressable>
                  <Pressable
                    onPress={() => handleSubmit()}
                    style={[
                      styles.btnPressable,
                      {
                        backgroundColor: "#12FFE3",
                        borderWidth: 1.5,
                        borderColor: "#12FFE3",
                      },
                    ]}
                  >
                    <Text style={styles.btnText1}>Submit</Text>
                  </Pressable>
                </View>
              </View>
            )}
          </Formik>
        </ScrollView>
        {errorModalVisible == true ? (
          <ErrorModal
            visibleModal={errorModalVisible}
            SuccessMessage={errorModalMesg}
            onSubmit={handleCloseErrorModal}
            header={undefined}
          />
        ) : null}

        {/* modal ended */}

        {successModalVisible == true ? (
          <SuccessAutoExitModal
            visibleModal={successModalVisible}
            SuccessMessage={successModalMesg}
            handleOk={handleClose}
          />
        ) : null}

        {/* Loader */}
        {loading && (
          <Modal transparent animationType="none">
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <ActivityIndicator size="large" color="#0000ff" />
              <Text style={{ color: colors.white }}>Submitting</Text>
            </View>
          </Modal>
        )}
      </AdminLayout>
    </>
  );
};
const styles = StyleSheet.create({
  teamModalHeadingWrapper2: {
    flexDirection: "column",
    justifyContent: "space-between",
    gap: 10,
  },
  teamModalHeadingWrapper: {
    gap: 5,
  },
  teamModalLabel: {
    fontSize: 14,
    fontFamily: "PlusJakartaSansSemiBold",
    color: colors.white,
  },

  btnText: {
    textAlign: "center",
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 14,
    color: colors.white,
  },
  btnText1: {
    textAlign: "center",
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 14,
    color: colors.royalBlue,
  },
  btnPressable: {
    width: 150,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },

  TeamModalInput: {
    borderColor: colors.white,
    borderWidth: 1,
    height: 40,
    width: "100%",
    borderRadius: 5,
    paddingHorizontal: 5,
    fontFamily: "PlusJakartaSansRegular",
    color: colors.white,
    backgroundColor: colors.royalBlue,
  },

  playerCard: {
    flexDirection: "row",
    width: "100%",
    backgroundColor: colors.royalBlue,
    padding: 15,
    borderRadius: 10,
    shadowOffset: {
      width: 3,
      height: 4,
    },
    shadowColor: "#000000",
    shadowOpacity: 0.22,
    shadowRadius: 10,
    elevation: 3,
    alignItems: "center",
    position: "relative",
  },
  addTeamBtn: {
    backgroundColor: colors.orange,
    flexDirection: "row",
    gap: 5,
    alignItems: "center",
    justifyContent: "center",
    // width: 100,
    borderRadius: 5,
    padding: 4,
  },
  scoreSheetDeleteBtn: {
    width: 30,
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    right: 0,
    top: 0,
    backgroundColor: colors.newBlue,
    padding: 5,
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
});
export default AddTeamMob;
