import React from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  Pressable,
  Platform,
  Dimensions,
} from "react-native";
import { colors } from "../../../../components/reusable/colour";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { DataStore } from "@aws-amplify/datastore";
import { UserMaster } from "../../../../models";
import { getAuthenticatedUser } from "../../../../api/AmplifyUserAPI";
import ZoomableImage from "../../../../components/reusable/ZoomableImage";

const screenWidth = Dimensions.get("window").width;
const TournamentSetup = () => {
  const navigation: any = useNavigation();

  const handleCreateTournament = async () => {
    let Response = await getAuthenticatedUser();
    if (Response && Object.keys(Response).length > 0) {
      console.log("ResponseFinal", Response);
      if (Response.isLoggedIn == true) {
        console.log("isLoginTrue", Response.isLoggedIn);

        const id = Response.attributes.sub;
        const userdata = await DataStore.query(UserMaster, id);
        // if (userdata.Name !== null) {
        console.log("userdata", userdata);
        // await AsyncStorage.setItem("Username", userdata.Name);
        try {
          await AsyncStorage.setItem("UserDetail", userdata.UUID);
        } catch (error) {
          console.log("Error in catch userdetails : ", error);
        }
        // }
        navigation.navigate("CreateTournament");
      } else {
        let sendData = {
          prevPage: "",
          nextPage: "",
          userId: "",
        };
        navigation.navigate("SignIn", {
          data: sendData,
        });
      }
    } else {
      let sendData = {
        prevPage: "",
        nextPage: "",
        userId: "",
      };
      navigation.navigate("SignIn", {
        data: sendData,
      });
    }
  };

  return (
    <View style={styles.MainSectionBG}>
      <View style={styles.container}>
        <View style={styles.TournamentSetupMainContainer}>
          <Text style={styles.TournamentSetupHeading}>
            Simplify Tournament Setup with a Few Clicks!
          </Text>
          <View style={styles.TournamentSetupContainer}>
            {Platform.OS === "web" && screenWidth > 760 ? (
              <View style={styles.TournamentSetupLeft}>
                {/* <Image
                  source={require("../../../../../assets/home/TournamentSetup/tournamentSetup.png")}
                  style={styles.tournamentSetImage}
                  resizeMode="contain"
                /> */}
                <ZoomableImage
                  imageSource={require("../../../../../assets/home/TournamentSetup/tournamentSetup.png")}
                  imageStyle={styles.tournamentSetImage}
                  modalStyle={undefined}
                />
              </View>
            ) : null}

            <View style={styles.TournamentSetupRight}>
              <View style={[styles.cardContainer, styles.redBorder]}>
                <View style={styles.CardHeadingContainer}>
                  <View style={styles.CardHeadingNumber}>
                    <Text>1.</Text>
                  </View>
                  <Text style={styles.CardHeading}>Set Up Your Tournament</Text>
                </View>
                <Text style={styles.CardText}>
                  Click on 'Create Tournament' and enter basic details such as
                  tournament name, date, and location. Define your tournament
                  format with just a few clicks.
                </Text>
              </View>
              <View style={[styles.cardContainer, styles.skyBlueBorder]}>
                <View style={styles.CardHeadingContainer}>
                  <View style={styles.CardHeadingNumber}>
                    <Text>2.</Text>
                  </View>
                  <Text style={styles.CardHeading}>Customize Your Event</Text>
                </View>
                <Text style={styles.CardText}>
                  Tailor your tournament with custom branding, including logos
                  and color themes. Specify match rules, participant categories,
                  and registration details effortlessly
                </Text>
              </View>
              <View style={[styles.cardContainer, styles.purpleBorder]}>
                <View style={styles.CardHeadingContainer}>
                  <View style={styles.CardHeadingNumber}>
                    <Text>3.</Text>
                  </View>
                  <Text style={styles.CardHeading}>
                    Invite Participants & Go Live
                  </Text>
                </View>
                <Text style={styles.CardText}>
                  Send invitations to players and fans via our built-in
                  communication tools. Once invited, participants receive
                  instant updates and can engage with the event.
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View style={styles.orangeBoxContainer}>
              <Text style={styles.orangeBoxText}>
                Get Ahead with Our Mobile App: Kickstart Your Tournament with
                Live Scoring, Tracking, and More!
              </Text>
              <Pressable
                style={styles.loginBtn}
                onPress={() => handleCreateTournament()}
              >
                <Text style={styles.loginBtnText}>
                  Create Tournament for Free
                </Text>
              </Pressable>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  MainSectionBG: {
    width: "100%",
    backgroundColor: colors.bgColor,
  },
  container: {
    maxWidth: 1320,
    width: "100%",
    // height: 1150,
    marginHorizontal: "auto",
    paddingRight: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
    paddingLeft: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
  },
  TournamentSetupMainContainer: {
    paddingVertical: 80,
    paddingTop: Platform.OS === "web" && screenWidth > 760 ? 90 : 40,
  },
  TournamentSetupHeading: {
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 40 : 26,
    fontFamily: "PlusJakartaSansBold",
    textAlign: "center",
    color: colors.white,
  },
  TournamentSetupContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
    paddingVertical: Platform.OS === "web" && screenWidth > 760 ? 50 : 0,
    justifyContent: "center",
  },
  TournamentSetupLeft: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  TournamentSetupRight: {
    // flex: Platform.OS === "web" && screenWidth > 760 ? 1 : 0,
    // width: 400,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: Platform.OS === "web" && screenWidth > 760 ? 40 : 10,
    paddingVertical: 30,
  },
  tournamentSetImage: {
    width: 500,
    height: 500,
  },
  CardHeadingContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
    alignItems: "center",
  },
  CardHeadingNumber: {
    backgroundColor: "#CEDAFA",
    width: 30,
    height: 30,
    borderRadius: 25,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  CardText: {
    fontSize: 14,
    fontFamily: "PlusJakartaSansRegular",
    color: colors.white,
  },
  CardHeading: {
    fontSize: 18,
    fontFamily: "PlusJakartaSansSemiBold",
    color: colors.white,
  },
  cardContainer: {
    backgroundColor: colors.royalBlue,
    padding: 15,
    // width: 400,
    width: "100%",
    borderRadius: 10,
    paddingLeft: 35,
    flexDirection: "column",
    justifyContent: "space-between",
    shadowColor: "#000",
    shadowOffset: {
      width: 5,
      height: 7,
    },
    shadowOpacity: 0.61,
    shadowRadius: 10,
    elevation: 10,
    gap: 12,
  },
  redBorder: {
    borderLeftWidth: 10,
    borderLeftColor: colors.orange,
    borderStyle: "solid",
  },
  skyBlueBorder: {
    borderLeftWidth: 10,
    borderLeftColor: colors.aqua,
    borderStyle: "solid",
  },
  purpleBorder: {
    borderLeftWidth: 10,
    borderLeftColor: "#574FB1",
    borderStyle: "solid",
  },
  loginBtn: {
    backgroundColor: colors.aqua,
    borderColor: colors.aqua,
    borderWidth: 1,
    padding: 5,
    borderRadius: 10,
    fontSize: 14,
    width: 240,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    marginTop: Platform.OS === "web" && screenWidth > 760 ? 15 : 5,
  },
  loginBtnText: {
    color: colors.royalBlueColour,
    textAlign: "center",
    fontFamily: "PlusJakartaSansSemiBold",
  },
  orangeBoxContainer: {
    backgroundColor: colors.orangeColour,
    width: "100%",
    padding: Platform.OS === "web" && screenWidth > 760 ? 25 : 10,
    borderRadius: 12,
    flexDirection:
      Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
    alignItems: "center",
    gap: Platform.OS === "web" && screenWidth > 760 ? 70 : 10,
    // maxWidth: 850,
  },
  orangeBoxText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 25 : 18,
    paddingVertical: Platform.OS === "web" && screenWidth > 760 ? 20 : 20,
  },
});

export default TournamentSetup;
