import React, { createContext, useContext, useState } from "react";
// import Pool from "./UserPool";
import AWS from "aws-sdk";
import { ConsoleLogger } from "aws-amplify/utils";
// import { Storage} from "aws-amplify"
import awsmobile from "../aws-exports";
import {
  fetchAuthSession,
  signIn,
  signUp,
  resendSignUpCode,
  confirmSignIn,
  confirmSignUp,
  getCurrentUser,
  autoSignIn,
} from "aws-amplify/auth";

const AuthenticationContext = createContext();
const password = Math.random().toString(10) + "Abc#";
// const logger = new ConsoleLogger("foo");
// ConsoleLogger.LOG_LEVEL = "DEBUG";
const Authentication = (props) => {
  const config1 = {
    region: awsmobile.aws_cognito_region,
  };
  AWS.config.update(config1);

  const userPoolId = awsmobile.aws_user_pools_id;
  const clientID = awsmobile.aws_user_pools_web_client_id;
  const authRole = awsmobile.aws_cognito_auth_role;
  const identityPoolId = awsmobile.aws_cognito_identity_pool_id;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const getSession = async () => {
    try {
      const currentSession = await getAuthenticatedUser();
      console.log("currentSession:", currentSession);
      return currentSession;
    } catch (err) {
      console.log(err);
    }
  };

  const handleSignIn = async (mobileNo) => {
    let signInResult = { status: "", result: {} };
    await signIn({
      username: mobileNo,
      options: {
        authFlowType: "CUSTOM_WITHOUT_SRP",
      },
    })
      .then(async (result) => {
        console.log("Result : ", result);
        signInResult = { status: "Success", result: result };
      })
      .catch((error) => {
        console.log(error);
        signInResult = { status: "Error", result: error };
      });
    console.log("signInResult:", signInResult);
    return signInResult;
  };

  const handleSignUp = async (mobileNo) => {
    let signUpResult = { status: "", result: {} };
    await signUp({
      username: mobileNo,
      password,
      options: {
        userAttributes: {
          phone_number: mobileNo, // E.164 number convention
        },
        // optional
        autoSignIn: true,
      },
    })
      .then(async (result) => {
        console.log("Result : ", result);
        signUpResult = { status: "Success", result: result };
      })
      .catch((error) => {
        console.log(error);
        signUpResult = { status: "Error", result: error };
      });
    console.log("signUpResult:", signUpResult);
    return signUpResult;
  };
  // const signInUser = async (mobileNo) => {
  //   let signInResult = { status: "", result: {} };
  //   console.log("mob no received :", mobileNo);
  //   await signIn({
  //     username: mobileNo,
  //     options: {
  //       authFlowType: "CUSTOM_WITHOUT_SRP",
  //     },
  //   })
  //     .then(async (result) => {
  //       console.log("Result : ", result);
  //       signInResult = { status: "Success", result: result };
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       signInResult = { status: "Error", result: error };
  //     });
  //   console.log("signInResult:", signInResult);
  //   return signInResult;
  // };

  const getAuthenticatedUser = async () => {
    const { tokens: session } = await fetchAuthSession();
    const { username, signInDetails } = await getCurrentUser();
    console.log("username", username);
    console.log("signInDetails", signInDetails);

    // Note that session will no longer contain refreshToken and clockDrift
    return {
      username,
      session,
      // authenticationFlowType: signInDetails.authFlowType,
    };
  };

  const handleConfirmSignIn = async (otp) => {
    let otpResult = { status: "", result: {} };
    // await Auth.sendCustomChallengeAnswer(session, otp)
    await confirmSignIn({ challengeResponse: otp })
      .then(async (result) => {
        console.log("Result : ", result);
        otpResult = { status: "Success", result: result };
      })
      .catch((err) => {
        console.error("Error in verifying OTP:", err);
        otpResult = { status: "Error", result: err };
      });

    console.log("otpResult:", otpResult);
    return otpResult;
  };

  const handleConfirmSignUp = async (userName, otp) => {
    let otpResult = { status: "", result: {} };
    // await Auth.sendCustomChallengeAnswer(session, otp)
    await confirmSignUp({ username: userName, confirmationCode: otp })
      .then(async (result) => {
        console.log("Result : ", result);
        otpResult = { status: "Success", result: result };
        const { isSignedIn } = await autoSignIn();
        if (isSignedIn) {
          setIsLoggedIn(true);
        }
      })
      .catch((err) => {
        console.error("Error in verifying OTP:", err);
        otpResult = { status: "Error", result: err };
      });
    console.log("otpResult:", otpResult);
    return otpResult;
  };

  const handleResendCode = async (userName) => {
    try {
      console.log("Username received", userName);
      const { destination, deliveryMedium, attributeName } =
        await resendSignUpCode({ username: userName });
      return destination, deliveryMedium, attributeName;
    } catch (error) {
      console.log("Error in handleResendCode :", error);
      return error;
    }
  };

  const setCredentials = async () => {
    console.log("in set credentials");
    let idToken;
    await getSession()
      .then((data) => {
        if (data) {
          console.log("getsessionData", data);
          idToken = data.session.idToken.toString();
          console.log("Session : ", data);
          console.log("idToken", idToken);

          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: identityPoolId, // create identity pool with authentication provider as Cognito, also assign two roles generated while creating Identity pool for the first time to "Unauthenticated role & Authenticated role" respectively.
            RoleArn: `arn:aws:iam::640053108432:role/${authRole}`,
            //ROLE_ARN : arn for IAM role assumed by the authenticated users in identity pool,
            // role should have cognito-idp full access to create new user
            Logins: {
              [`cognito-idp.ap-south-1.amazonaws.com/${userPoolId}`]: idToken,
            },
          });
        }
      })
      .catch((error) => {
        console.log("Error in get session", error);
      });

    return idToken;
  };

  const listUsers = async () => {
    let userResponse;
    await setCredentials().then(async (idToken) => {
      let cognitoidentityserviceprovider =
        new AWS.CognitoIdentityServiceProvider();
      userResponse = await new Promise((resolve, reject) => {
        var params = {
          AttributesToGet: ["phone_number", "sub"],
          Limit: 3,
          UserPoolId: userPoolId,
        };
        console.log("params", params);
        cognitoidentityserviceprovider.listUsers(params, function (err, data) {
          if (err) {
            resolve(err, err.stack);
            console.log(err, err.stack); // an error occurred
          } else {
            resolve(data);
            console.log(data);
          }
        });
      });
    });
    console.log("userResponse", userResponse);
    return userResponse;
  };
  const getAdminUser = async (username) => {
    await setCredentials();

    return await new Promise((resolve, reject) => {
      var params = {
        UserPoolId: userPoolId /* required */,
        Username: username /* required */,
        // Region: region,
      };
      var cognitoidentityserviceprovider =
        new AWS.CognitoIdentityServiceProvider();

      cognitoidentityserviceprovider.adminGetUser(params, function (err, data) {
        if (err) resolve(err, err.stack); // an error occurred
        else resolve(data); // successful response
      });
    });
  };
  const createAdminUser = async (username, userAttributesData) => {
    let userResponse;
    await setCredentials().then(async (idToken) => {
      console.log("Aws Credentials", AWS.config.credentials);
      let cognitoidentityserviceprovider =
        new AWS.CognitoIdentityServiceProvider();
      userResponse = await new Promise((resolve, reject) => {
        //
        var params = {
          UserPoolId: userPoolId,
          Username: username,
          ClientMetadata: {
            ClientId: clientID,
          },
          DesiredDeliveryMediums: ["SMS"],
          UserAttributes: userAttributesData,
        };
        console.log("params", params);
        cognitoidentityserviceprovider.adminCreateUser(
          params,
          function (err, data) {
            if (err) {
              resolve(err, err.stack);
              console.log(err, err.stack); // an error occurred
            } else {
              resolve(data);
              console.log(data);
            }
          }
        );
      });
    });
    console.log("userResponse", userResponse);
    return userResponse;
  };

  return (
    <AuthenticationContext.Provider
      value={{
        handleSignIn,
        handleSignUp,
        createAdminUser,
        listUsers,
        getAdminUser,
        handleConfirmSignIn,
        handleConfirmSignUp,
        handleResendCode,
        getAuthenticatedUser,
        isLoggedIn,
        setIsLoggedIn,
      }}
    >
      {props.children}
    </AuthenticationContext.Provider>
  );
};
export { Authentication, AuthenticationContext };
