export const colors = {
  primary: "linear-gradient(rgba(13, 5, 53, 1), rgba(30, 12, 128, 1))  ", // gradinat colour
  secondary: "rgba(18, 255, 227, 1)", // green colour
  grayColour: "rgba(233, 233, 233, 1)", // gray colour
  blueColour: "rgba(50, 123, 237, 1)", //blue colour
  royalBlueColour: "rgba(2, 32, 95, 1)", // royal blue colour
  orangeColour: "rgba(255, 87, 51, 1)", // orange Colour
  darkGray: "#767676", //dark gray color
  success: "#0b9d22", //green success message
  modalBG: "#b6b0b091",
  // red colour

  // for dark mode
  bgColor: "#0A1029",
  royalBlue: "#1B2138",
  blue: "#021D55",
  orange: "#FF5733",
  aqua: "#12FFE3",
  white: "#fff",
  green: "#0BA92D",
  borderColor: "#555",
  red: "#FF2323",
  errorRed: "rgba(182, 2, 2, 1)",
  tableHeader: "#2a3462",
  tableData: "#353a4b",
  newBlue: "#242C4A",
};
//
