import {
  View,
  Text,
  StyleSheet,
  Pressable,
  Image,
  Dimensions,
  Platform,
  Modal,
  ActivityIndicator,
} from "react-native";
import React, { useEffect, useState } from "react";
import { H2, H4 } from "../../../components/reusable/TextComponent";
import Icon from "react-native-vector-icons/AntDesign";
import ResultDetails from "./ResultDetails";
import { DataStore } from "aws-amplify/datastore";
import { Tournament } from "../../../models";
import { formatDate } from "../../../components/FormattedDate";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigationState } from "@react-navigation/native";
import SearchBar from "../../../components/reusable/SearchBar";
import { colors } from "../../../components/reusable/colour";

const defaultLogo: any = require("../../../../assets/default/defaultLogo.png");

const screenWidth = Dimensions.get("window").width;

const Results = ({
  tournamentId,
  showResultFlag,
  showDetailsForResult,
  onDataReceived,
  matchID,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [selectedMatchID, setSelectedMatchID] = useState("");
  const [allRoundsData, setAllRoundsData]: any = useState([]);
  const [roundsData, setRoundsData] = useState([]);
  const [loading, setLoading] = useState(false);
  // for manageTournamentPage and admin view

  const [roundsDataForAdmin, setRoundsDataForAdmin] = useState([]);
  const [searchFlag, setSearchFlag] = useState(false);

  const homeLogo: any = "../../../../assets/home/logo.png";
  const navigationState = useNavigationState((state) => state);
  const currentRoute = navigationState.routes[navigationState.index];
  const currentScreen = currentRoute.name;
  console.log("currentScreen", currentScreen);
  console.log("params", currentRoute.params);
  console.log("tournamentIDProps", tournamentId);

  const handleSelectMatch = (matchId: any) => {
    if (selectedMatchID === matchId) {
      // If the same match is clicked again, close the details
      console.log("selectedMatchID", selectedMatchID);
      setSelectedMatchID(null);
      setShowDetails(false);
    } else {
      // Otherwise, open details for the selected match
      setSelectedMatchID(matchId);
      setShowDetails(true);
    }
  };

  // Callback function to receive data from the child
  const handleDataFromChild = (data: any) => {
    console.log("Data received from child:", data);
    console.log("onDataReceived:", onDataReceived);
    setShowDetails(onDataReceived);
  };

  // Function to group matches by date

  const groupMatchesByRoundAndDate = (roundsData: any) => {
    return roundsData.map((round: any) => {
      const groupedMatches: any = {};
      round.groups.forEach((group: any) => {
        group.Matches.forEach((matchData: any) => {
          const matchDate =
            matchData.Date !== null
              ? formatDate(matchData.Date)
              : "Not Scheduled";
          if (!groupedMatches[matchDate]) {
            groupedMatches[matchDate] = [];
          }
          groupedMatches[matchDate].push(matchData);
        });
      });
      return {
        roundName: round.Name,
        matchesByDate: Object.entries(groupedMatches).sort((a, b) => {
          const dateA: any = a[0] ? new Date(a[0]) : new Date(0);
          const dateB: any = b[0] ? new Date(b[0]) : new Date(0);
          return dateB - dateA;
        }),
      };
    });
  };

  const getResultsData = async (tournamentId: any) => {
    try {
      setLoading(true);
      const tournamentDetailsProps = await AsyncStorage.getItem(
        "tournamentProps"
      );
      const tournamentDetails = JSON.parse(tournamentDetailsProps);
      console.log("tournamentDetails", tournamentDetails);

      const subscription = DataStore.observeQuery(Tournament, (c) =>
        c.id.eq(tournamentId)
      ).subscribe(async (snapshot) => {
        const initialTournaments = snapshot.items;
        console.log("selectedTournament", initialTournaments);

        const getAllResultsDataByRounds = initialTournaments.map(
          async (data: any) => {
            const rounds = await data.Rounds.values;

            // Extract and set groups data for each round
            const groupsPromise = rounds.map(async (round: any) => {
              const groups = await round.Groups.values;

              // Extract and set group standings data for each group
              const groupStandingPromise = groups.map(async (group: any) => {
                const groupStandings = await group.GroupStandings.values;

                // Fetch matches separately
                const matches = await group.Matches.values;
                console.log("matchess", matches);

                // Filter matches based on status
                const filteredMatches =
                  matches &&
                  matches.length > 0 &&
                  matches.filter((match: any) => match.Status !== "CREATED");
                console.log("filteredMatches", filteredMatches);

                // Ensure filteredMatches is an array before mapping over it
                const matchesData =
                  Array.isArray(filteredMatches) && filteredMatches.length > 0
                    ? await Promise.all(
                        filteredMatches.map(async (match: any) => {
                          const team1Promise = match.Team1;
                          const team2Promise = match.Team2;

                          // Await the resolution of both promises concurrently
                          const [team1, team2] = await Promise.all([
                            team1Promise,
                            team2Promise,
                          ]);

                          // Fetch data for Team1 and Team2 if they are not null
                          const team1Data = team1 ? await team1 : null;
                          const team2Data = team2 ? await team2 : null;

                          return {
                            ...match,
                            Team1: team1Data,
                            Team2: team2Data,
                          };
                        })
                      )
                    : [];
                // Now matchesData is the final array with resolved Team1 and Team2 promises
                console.log("matchesData", matchesData);

                const matchesDataComplete = await Promise.all(matchesData);

                return {
                  ...group,
                  groupStandings,
                  Matches: matchesDataComplete,
                };
              });

              const groupdStandingData = await Promise.all(
                groupStandingPromise
              );

              return { ...round, groups: groupdStandingData };
            });

            const groupsData = await Promise.all(groupsPromise);

            return { ...data, rounds: groupsData };
          }
        );

        const roundsData = await Promise.all(getAllResultsDataByRounds);
        console.log("roundsData", roundsData);
        setAllRoundsData(roundsData);

        const getRoundsData = roundsData
          .map((roundData: any) =>
            roundData.rounds.filter(
              (round: any) =>
                round.FixturesCreated &&
                round.groups.some((group: any) =>
                  group.Matches.some(
                    (match: any) =>
                      match.Status === "INPROGRESS" ||
                      match.Status === "COMPLETED"
                  )
                )
            )
          )
          .flat();

        console.log("getRoundsData", getRoundsData);

        setRoundsData(getRoundsData);
        setLoading(false);
      });

      return () => {
        subscription.unsubscribe();
      };
    } catch (error) {
      setLoading(false);
      console.log("error occured while fetching the data", error);
    }
  };

  const handleSearch = (text: any) => {
    setSearchFlag(true); //

    if (!text) {
      setSearchFlag(false); // Set search flag to false when there is no active search
      const originalData = roundsData;
      const originalData2 = roundsDataForAdmin;
      setRoundsData(originalData); // Reset to the original data
      setRoundsDataForAdmin(originalData2);
    } else {
      console.log("filtertext", text);
      const filteredResults = roundsData.map((round: any) => ({
        ...round,
        groups: round.groups.map((group: any) => ({
          ...group,
          Matches: group.Matches.filter((match: any) => {
            // Check if any attribute of Team1 or Team2 matches the search text
            return (
              Object.values(match.Team1).some(
                (value: any) =>
                  typeof value === "string" &&
                  value.toLowerCase().includes(text.toLowerCase())
              ) ||
              Object.values(match.Team2).some(
                (value: any) =>
                  typeof value === "string" &&
                  value.toLowerCase().includes(text.toLowerCase())
              )
            );
          }),
        })),
      }));

      console.log("filtered results", filteredResults);
      setRoundsData(filteredResults);
      setRoundsDataForAdmin(filteredResults);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (tournamentId) {
        console.log("tournamentId", tournamentId);
        await getResultsData(tournamentId);
      } else {
        console.log("tournamentId is not found");
      }
    };
    fetchData();
  }, [
    tournamentId,
    matchID,
    showResultFlag,
    onDataReceived,
    selectedMatchID,
    showDetailsForResult,

    searchFlag,
  ]);

  return (
    <View style={styles.TabContentContainer}>
      {/* searchBar added */}
      <View>
        <SearchBar
          placeholder="Search by team name"
          onSearch={handleSearch}
          style={styles.searchBar}
        />
      </View>

      {Platform.OS === "web" && screenWidth > 760 ? (
        // WEB VIEW SHOULD BE RENDER
        !showDetails ? (
          roundsData && roundsData.length > 0 ? (
            groupMatchesByRoundAndDate(roundsData).map(
              (roundMatches: any, roundIndex: number) => (
                <>
                  <View key={roundIndex} style={styles.standingsRoundContainer}>
                    <View
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: 10,
                      }}
                    >
                      <Text style={styles.roundNameText}>
                        {roundMatches.roundName}
                      </Text>
                    </View>
                    {/* Render round name */}
                    {roundMatches.matchesByDate.map(
                      (
                        [formattedDate, matchesForDate]: any,
                        dateIndex: number
                      ) => (
                        <View key={dateIndex}>
                          <Text style={styles.resultFixtureDate}>
                            {formattedDate !== "null"
                              ? formattedDate
                              : "Invalid Date"}
                          </Text>
                          {matchesForDate.map((match: any, matchIndex: any) => {
                            return (
                              <Pressable
                                key={matchIndex}
                                style={[
                                  styles.resultItemWrapper,
                                  {
                                    backgroundColor: colors.bgColor,
                                  },
                                ]}
                                onPress={() => {
                                  handleSelectMatch(match.id);
                                }}
                              >
                                <H4
                                  props={`M-${match.MatchNo}`}
                                  style={styles.resultItemSN}
                                />
                                {match.Team1 && match.Team2 ? (
                                  <View style={styles.resultItemDetailsWrapper}>
                                    <View style={styles.resultItemTeamNameLogo}>
                                      <H4
                                        props={match.Team1.TeamName}
                                        style={{ color: colors.white }}
                                      />
                                      <Image
                                        source={{
                                          uri: match.Team1.LogoURL
                                            ? match.Team1.LogoURL
                                            : defaultLogo,
                                        }}
                                        style={styles.resultItemTeamLogo}
                                      />
                                    </View>

                                    {/* score data */}

                                    <View style={styles.resultScoreWrapper}>
                                      <H2
                                        props={
                                          match.Team1.Score &&
                                          match.Team1.Score !== null
                                            ? match.Team1.Score.FirstHalf +
                                              match.Team1.Score.SecondHalf +
                                              match.Team1.Score.ExtraTime
                                            : "0"
                                        }
                                        style={{
                                          color:
                                            match.Team1.Score.FirstHalf +
                                              match.Team1.Score.SecondHalf +
                                              match.Team1.Score.ExtraTime >
                                            match.Team2.Score.FirstHalf +
                                              match.Team2.Score.SecondHalf +
                                              match.Team2.Score.ExtraTime
                                              ? colors.green
                                              : match.Team1.Score.FirstHalf +
                                                  match.Team1.Score.SecondHalf +
                                                  match.Team1.Score.ExtraTime <
                                                match.Team2.Score.FirstHalf +
                                                  match.Team2.Score.SecondHalf +
                                                  match.Team2.Score.ExtraTime
                                              ? colors.red
                                              : colors.white,
                                        }}
                                        // style={styles.resultScoreWin}
                                      />
                                      <H2
                                        props={"-"}
                                        style={{ color: colors.white }}
                                      />
                                      <H2
                                        props={
                                          match.Team2.Score &&
                                          match.Team2.Score !== null
                                            ? match.Team2.Score.FirstHalf +
                                              match.Team2.Score.SecondHalf +
                                              match.Team2.Score.ExtraTime
                                            : "0"
                                        }
                                        style={{
                                          color:
                                            match.Team2.Score.FirstHalf +
                                              match.Team2.Score.SecondHalf +
                                              match.Team2.Score.ExtraTime >
                                            match.Team1.Score.FirstHalf +
                                              match.Team1.Score.SecondHalf +
                                              match.Team1.Score.ExtraTime
                                              ? colors.green
                                              : match.Team2.Score.FirstHalf +
                                                  match.Team2.Score.SecondHalf +
                                                  match.Team2.Score.ExtraTime <
                                                match.Team1.Score.FirstHalf +
                                                  match.Team1.Score.SecondHalf +
                                                  match.Team1.Score.ExtraTime
                                              ? colors.red
                                              : colors.white,
                                        }}
                                        // style={styles.resultScoreLose}
                                      />
                                    </View>

                                    <View
                                      style={styles.resultItemTeamNameLogoRight}
                                    >
                                      <Image
                                        source={{
                                          uri: match.Team2.LogoURL
                                            ? match.Team2.LogoURL
                                            : defaultLogo,
                                        }}
                                        style={styles.resultItemTeamLogo}
                                      />
                                      <H4
                                        props={match.Team2.TeamName}
                                        style={{ color: colors.white }}
                                      />
                                    </View>
                                  </View>
                                ) : (
                                  <Text>No data found</Text>
                                )}
                                <Pressable
                                  onPress={() => {
                                    handleSelectMatch(match.id);
                                  }}
                                >
                                  <Icon
                                    name="arrowright"
                                    style={styles.resultItemArrow}
                                  />
                                </Pressable>
                              </Pressable>
                            );
                          })}
                        </View>
                      )
                    )}
                  </View>
                </>
              )
            )
          ) : (
            <View style={{ alignItems: "center", width: "100%" }}>
              <Image
                source={require("../../../../assets/noData/teams.png")}
                style={styles.noDataImage}
                resizeMode="contain"
              />
              <Text style={styles.noDataText}>
                Hang on! No any matches completed yet.
              </Text>
            </View>
          )
        ) : (
          showDetails &&
          selectedMatchID && (
            <ResultDetails
              matchId={selectedMatchID}
              showResultDetailsFromResult={showDetails}
              onDataReceived={handleDataFromChild}
              showResultFlag={false}
            />
          )
        ) // MOBILE VIEW SHOULD BE RENDER
      ) : !showDetails ? (
        roundsData && roundsData.length > 0 ? (
          groupMatchesByRoundAndDate(roundsData).map(
            (roundMatches: any, roundIndex: number) => (
              <View key={roundIndex}>
                <Text style={styles.roundNameText}>
                  Round - {roundMatches.roundName}
                </Text>
                {/* Render round name */}
                {roundMatches.matchesByDate.map(
                  ([formattedDate, matchesForDate]: any, dateIndex: number) => (
                    <View key={dateIndex}>
                      <Text style={styles.resultFixtureDate}>
                        {formattedDate !== "null"
                          ? formattedDate
                          : "Invalid Date"}
                      </Text>
                      {matchesForDate.map((match: any, matchIndex: any) => {
                        return (
                          <Pressable
                            key={matchIndex}
                            style={[
                              styles.resultItemWrapper,
                              { backgroundColor: colors.royalBlue },
                            ]}
                            onPress={() => {
                              handleSelectMatch(match.id);
                            }}
                          >
                            <H4
                              props={`M-${match.MatchNo}`}
                              style={styles.resultItemSN}
                            />
                            {match.Team1 && match.Team2 ? (
                              <View style={styles.resultItemDetailsWrapper}>
                                <View style={styles.resultItemTeamNameLogo}>
                                  <H4
                                    props={
                                      match.Team1.TeamShortName
                                        ? match.Team1.TeamShortName
                                        : "N/A"
                                    }
                                  />
                                  <Image
                                    source={{
                                      uri:
                                        match.Team1.LogoURL !== "" &&
                                        match.Team1.LogoURL !== null &&
                                        match.Team1.LogoURL
                                          ? match.Team1.LogoURL
                                          : defaultLogo,
                                    }}
                                    style={styles.resultItemTeamLogo}
                                  />
                                </View>

                                {/* score data mobile*/}

                                <View style={styles.resultScoreWrapper}>
                                  <H2
                                    props={
                                      match.Team1.Score &&
                                      match.Team1.Score !== null
                                        ? match.Team1.Score.FirstHalf +
                                          match.Team1.Score.SecondHalf +
                                          match.Team1.Score.ExtraTime
                                        : "0"
                                    }
                                    // style={styles.resultScoreWin}
                                    style={[
                                      styles.resultScoreWin,
                                      {
                                        color:
                                          match.Team1.Score.FirstHalf +
                                            match.Team1.Score.SecondHalf +
                                            match.Team1.Score.ExtraTime >
                                          match.Team2.Score.FirstHalf +
                                            match.Team2.Score.SecondHalf +
                                            match.Team2.Score.ExtraTime
                                            ? colors.green
                                            : match.Team1.Score.FirstHalf +
                                                match.Team1.Score.SecondHalf +
                                                match.Team1.Score.ExtraTime <
                                              match.Team2.Score.FirstHalf +
                                                match.Team2.Score.SecondHalf +
                                                match.Team2.Score.ExtraTime
                                            ? colors.red
                                            : colors.white,
                                      },
                                    ]}
                                  />
                                  <H2
                                    props={"-"}
                                    style={{ color: colors.white }}
                                  />
                                  <H2
                                    props={
                                      match.Team2.Score &&
                                      match.Team2.Score !== null
                                        ? match.Team2.Score.FirstHalf +
                                          match.Team2.Score.SecondHalf +
                                          match.Team2.Score.ExtraTime
                                        : "0"
                                    }
                                    // style={styles.resultScoreLose}
                                    style={[
                                      styles.resultScoreLose,
                                      {
                                        color:
                                          match.Team2.Score.FirstHalf +
                                            match.Team2.Score.SecondHalf +
                                            match.Team2.Score.ExtraTime >
                                          match.Team1.Score.FirstHalf +
                                            match.Team1.Score.SecondHalf +
                                            match.Team1.Score.ExtraTime
                                            ? colors.green
                                            : match.Team2.Score.FirstHalf +
                                                match.Team2.Score.SecondHalf +
                                                match.Team2.Score.ExtraTime <
                                              match.Team1.Score.FirstHalf +
                                                match.Team1.Score.SecondHalf +
                                                match.Team1.Score.ExtraTime
                                            ? colors.red
                                            : colors.white,
                                      },
                                    ]}
                                  />
                                </View>

                                <View
                                  style={styles.resultItemTeamNameLogoRight}
                                >
                                  <Image
                                    source={{
                                      uri:
                                        match.Team2.LogoURL !== "" &&
                                        match.Team2.LogoURL !== null &&
                                        match.Team2.LogoURL
                                          ? match.Team2.LogoURL
                                          : defaultLogo,
                                    }}
                                    style={styles.resultItemTeamLogo}
                                  />
                                  <H4
                                    props={
                                      match.Team2.TeamShortName
                                        ? match.Team2.TeamShortName
                                        : "N/A"
                                    }
                                  />
                                </View>
                              </View>
                            ) : (
                              <Text>No data found</Text>
                            )}
                            <Pressable
                              onPress={() => {
                                handleSelectMatch(match.id);
                              }}
                              style={{
                                position: "absolute",
                                right: -10,
                                top: 30,
                              }}
                            >
                              <Icon
                                name="arrowright"
                                style={styles.resultItemArrow}
                              />
                            </Pressable>
                          </Pressable>
                        );
                      })}
                    </View>
                  )
                )}
              </View>
            )
          )
        ) : (
          <View style={{ alignItems: "center", width: "100%" }}>
            <Image
              source={require("../../../../assets/noData/teams.png")}
              style={styles.noDataImage}
              resizeMode="contain"
            />
            <Text style={styles.noDataText}>
              Hang on! No any matches completed yet.
            </Text>
          </View>
        )
      ) : (
        showDetails &&
        selectedMatchID && (
          <ResultDetails
            matchId={selectedMatchID}
            showResultDetailsFromResult={showDetails}
            onDataReceived={handleDataFromChild}
            showResultFlag={false}
          />
        )
      )}

      {/* loader */}

      {loading ? (
        <Modal transparent animationType="none">
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <ActivityIndicator size="large" color="#0000ff" />
            <Text style={{ color: "white" }}>Loading</Text>
          </View>
        </Modal>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  TabContentContainer: {
    width: "100%",
    flexDirection: "column",
    gap: 10,
  },
  registrationNumberCard: {
    flex: 1,
    minWidth: Platform.OS === "web" && screenWidth > 760 ? 100 : "28%",
    height: Platform.OS === "web" && screenWidth > 760 ? 100 : 80,
    backgroundColor: colors.white,
    padding: 10,
    borderRadius: 5,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent:
      Platform.OS === "web" && screenWidth > 760 ? "center" : "space-between",
    gap: 5,
  },
  resultItemWrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 5,
    paddingTop: Platform.OS === "web" && screenWidth > 760 ? 5 : 20,
    paddingHorizontal: 10,
    // boxShadow: "0 0 10px #0003",
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.3,
    shadowRadius: 10,
    elevation: 5,
    marginBottom: 10,
    gap: 10,
  },
  resultItemSN: {
    position:
      Platform.OS === "web" && screenWidth > 760 ? "relative" : "absolute",
    top: Platform.OS === "web" && screenWidth > 760 ? 0 : 5,
    left: Platform.OS === "web" && screenWidth > 760 ? 0 : 10,
    color: colors.white,
  },
  resultItemDetailsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: Platform.OS === "web" && screenWidth > 760 ? 20 : 25,
    flex: 1,
  },
  resultItemTeamNameLogo: {
    display: "flex",
    flexDirection:
      Platform.OS === "web" && screenWidth > 760 ? "row" : "column-reverse",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 10,
    flex: 1,
  },
  resultItemTeamNameLogoRight: {
    display: "flex",
    flexDirection:
      Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 10,
    flex: 1,
  },
  resultScoreWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: colors.newBlue,
    borderRadius: 5,
    // boxShadow: "0px 5px 10px #0808082b",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    width: 115,
    padding: 5,
  },
  resultScoreWin: {
    color: "#0CC61E",
    paddingVertical: 2,
    paddingHorizontal: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 35,
  },
  resultScoreLose: {
    color: "#DE0B0B",
    paddingVertical: 2,
    paddingHorizontal: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 35,
  },
  resultFixtureDate: {
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 14,
    marginBottom: 5,
    color: colors.white,
  },
  resultItemTeamLogo: {
    width: Platform.OS === "web" && screenWidth > 760 ? 60 : 40,
    height: Platform.OS === "web" && screenWidth > 760 ? 60 : 40,
    resizeMode: "contain",
    borderRadius: 30,
    backgroundColor: colors.royalBlue,
  },
  resultItemArrow: {
    position:
      Platform.OS === "web" && screenWidth > 760 ? "relative" : "absolute",
    top: Platform.OS === "web" && screenWidth > 760 ? 0 : 5,
    right:
      Platform.OS !== "web"
        ? -10
        : Platform.OS === "web" && screenWidth > 760
        ? 0
        : 10,
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 24 : 20,
    color: colors.white,
  },

  searchBar: {
    width: Platform.OS === "web" && screenWidth > 760 ? 350 : "100%",
    height: Platform.OS === "web" && screenWidth > 760 ? 40 : 45,
    borderColor: colors.royalBlue,
    backgroundColor: colors.blue,
    marginTop: 20,
    color: colors.white,
  },
  standingsRoundContainer: {
    shadowColor: "#00000021",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    elevation: 5,
    shadowRadius: 10,
    padding: 7,
    borderRadius: 10,
    backgroundColor: colors.royalBlue,
  },
  roundNameText: {
    fontFamily: "PlusJakartaSansSemiBold",
    color: colors.white,
    backgroundColor: colors.bgColor,
    paddingVertical: 4,
    paddingHorizontal: 20,
    borderRadius: 20,
  },
  noDataImage: {
    width: 270,
    height: 270,
  },
  noDataText: {
    fontFamily: "PlusJakartaSansSemiBold",
    color: colors.white,
    fontSize: 20,
    maxWidth: 400,
    textAlign: "center",
  },
});

export default Results;
