import React, { useContext, useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Pressable,
  Dimensions,
  Platform,
  TextInput,
} from "react-native";
import { H3, Label } from "../../../../components/reusable/TextComponent";
import { colors } from "../../../../components/reusable/colour";
import { RadioButton } from "react-native-paper";
import UploadImage from "../../../../components/reusable/UploadImage";
import UploadFile from "../../../../components/reusable/UploadFile";
import {
  RoundMaster,
  Tournament,
  UserMaster,
  UserMasterTournament,
} from "../../../../models";
import { DataStore } from "aws-amplify/datastore";
import { uploadData } from "aws-amplify/storage";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { RoundFormat } from "../../../../models";
import TrashIcon from "react-native-vector-icons/Feather";
import Venue from "../../../view/Home/Venue";
import Icon from "react-native-vector-icons/FontAwesome";
import AddIcon2 from "react-native-vector-icons/Ionicons";
import CustomDatePicker from "../../../../components/reusable/CustomeDatePicker";
import moment from "moment";
import { useNavigation } from "@react-navigation/native";
import awsmobile from "../../../../aws-exports";
import { AuthenticationContext } from "../../../../authentication/Authentication.js";
import {
  AreYouSureOrange,
  ErrorModal,
  ModalTrophy,
} from "../../../../components/reusable/AreYouSure";
import { Substitution } from "../../../../models";
import { color } from "react-native-elements/dist/helpers";
import { Picker } from "@react-native-picker/picker";
const screenWidth = Dimensions.get("window").width;

const Settings = () => {
  const [checkedSubstitute, setCheckedSubstitute] = useState("");
  const [checkedPublish, setCheckedPublish] = useState("no");
  const [selectedDate, setSelectedDate] = useState("");
  const [SuccessModalVisible, setSuccessModalVisible] = useState(false);
  const [SunccessModalMesg, setSunccessModalMesg] = useState("");
  const [CurrentTournament, setCurrentTournament] = useState("");
  const [Logo, setLogo] = useState("");

  const [Banner, setBanner] = useState("");
  const navigation: any = useNavigation();
  const [roundFormat, setroundFormant] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteModalMesg, setDeleteModalMesg] = useState("");
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorModalMesg, setErrorModalMesg] = useState("");
  const [prizeModalVisible, setPrizeModalVisible] = useState(false);
  const { createAdminUser, getAdminUser } = useContext(AuthenticationContext);
  const [prizeModalMesg, setPrizeModalMesg] = useState("");
  const [UserDetails, setUserDetails]: any = useState({});
  const [userData, setUserData] = useState("");
  const [PDFfile, setPDFfile]: any = useState({});
  const [venueIdToDelete, setVenueIdToDelete] = useState("");
  const [prizeIdToDelete, setPrizeIdToDelete] = useState("");
  const [tournamentObj, setTournamentObj]: any = useState({});
  const [adminobj, setAdminobj]: any = useState({});
  const [DeleteAdminArr, setDeleteAdminArr] = useState([]);
  const [selectedLogo, setSelectedLogo]: any = useState({});
  const Defaultlogo =
    "https://trackmytournament-dev125444-dev.s3.ap-south-1.amazonaws.com/public/tournament/football-club.png";
  const Defaultbanner =
    "https://trackmytournament-dev125444-dev.s3.ap-south-1.amazonaws.com/public/tournament/tournamentBanner.png";

  const DefaultRulesDocument =
    "https://trackmytournament-dev125444-QA.s3.ap-south-1.amazonaws.com/public/tournament/RulesDocument.png";
  const [selectedBaneer, setSelectedBaneer]: any = useState({});
  const bucketName = awsmobile.aws_user_files_s3_bucket;
  let data = AsyncStorage.getItem("myTournamentEdit");
  const [urlredirect, seturlredirect] = useState(data);
  const [venues, setVenues]: any = useState([
    {
      Name: "",
      Address: "",
      Coordinates: {},
    },
  ]);
  const [inputText, setinputText]: any = useState({
    TournamentName: "",
    OrganizerName: "",
    Sport: "",
    TournamentStartDate: "",
    TournamentEndDate: "",
    Category: "",
    CuttoffDate: "",
    Playing: "",
    Subsitute: "",
    Type: "",
    SubsitutionType: "",
    ContactNo: "",
    RegistrationFees: "",
    RegistrationEndDate: "",
    RegistrationStartDate: "",
    MaxNoofTeam: "",
    Format: "",
    logo: {},
    banner: {},
    PDfFile: {},
    Venue: "",
    CutOffDate: "",
    Substitution: false,
    RowlingSubstitution: false,
    Level: "",
  });
  const [Admininput, setAdmininput] = useState({
    Admin1Name: "",
    Admin1Contact: "",
    Admin1Email: "",
    Admin2Name: "",
    Admin2Contact: "",
    Admin2Email: "",
    Admin3Name: "",
    Admin3Contact: "",
    Admin3Email: "",
    Admin1UUID: "",
    Admin2UUID: "",
    Admin3UUID: "",
  });
  const [PrizesArr, setPrizesArr] = useState([
    {
      Name: "",
      Description: "",
    },
  ]);
  const [removeRoundList, setRemoveRoundList] = useState([]);
  const [roundSelecteindex, setRoundSelecteindex]: any = useState();
  const handleGroupYesNoSelect = (value) => {
    setCheckedSubstitute(value);
  };

  const handlePublishSelect = (value) => {
    console.log("First round", value, roundFormat[0]);
    if (roundFormat[0].FixturesCreated == true && value == "no") {
      console.log("First round fixture created");
      setErrorModalMesg(
        "You can not change Tournament status once fixtures are already created!"
      );
      setErrorModalVisible(true);
    } else {
      setCheckedPublish(value);
    }
  };
  const handleCancelcheck = () => {
    console.log("errorModalVisible", errorModalVisible);
  };
  const handleinputText = (name, value) => {
    setAdmininput((lastValue) => {
      return {
        ...lastValue,
        [name]: value,
      };
    });
  };

  const handleDateChange = (newDate, field) => {
    console.log("handleDateChange", newDate);
    const dateString = newDate;

    setSelectedDate(dateString);

    const yourMomentObject = moment(dateString);

    const formattedDate = yourMomentObject.format("YYYY-MM-DD");
    console.log("formattedDate", formattedDate);
    handleInput(field, formattedDate);
  };
  const handleInput = (name, value) => {
    console.log("handleInput", name, value);
    setinputText((lastValue) => {
      return {
        ...lastValue,
        [name]: value,
      };
    });
  };

  const formatdata = [
    { label: "Single Round Robin", value: "Single Round Robin" },
    { label: "Double Round Robin", value: "Double Round Robin" },
    { label: "KnockOut", value: "KnockOut" },
  ];

  const handlegetUserData = async (uuid) => {
    let data = await DataStore.query(UserMaster, uuid);
    console.log("handlegetUserMaster", data);
    return data.ContactNo;
  };

  const handlegetEmail = async (uuid) => {
    let data = await DataStore.query(UserMaster, uuid);
    console.log("handlegetUserMaster", data);
    return data.EmailId;
  };
  const getTournamentdata = async () => {
    const TournamentData = await AsyncStorage.getItem("CurrentTournament");
    setCurrentTournament(TournamentData);
    console.log("hello");
    let dropdown = RoundFormat;

    console.log("TournamentData", TournamentData);
    const subscription = DataStore.observeQuery(Tournament, (c) =>
      c.id.eq(TournamentData)
    ).subscribe(async (snapshot) => {
      console.log("snapshot", snapshot);
      const getTournament: any = snapshot.items[0];

      console.log("getTournament", getTournament);

      setAdmininput({
        Admin1Name:
          getTournament.Admin1 !== null &&
          Object.keys(getTournament.Admin1).length > 0
            ? getTournament.Admin1.Name
            : "",
        Admin1Contact:
          getTournament.Admin1 !== null &&
          Object.keys(getTournament.Admin1).length > 0
            ? await handlegetUserData(getTournament.Admin1.UUID)
            : "",
        Admin1Email:
          getTournament.Admin1 !== null &&
          Object.keys(getTournament.Admin1).length > 0
            ? await handlegetEmail(getTournament.Admin1.UUID)
            : "",

        Admin2Name:
          getTournament.Admin2 !== null &&
          Object.keys(getTournament.Admin2).length > 0
            ? getTournament.Admin2.Name
            : "",
        Admin1UUID:
          getTournament.Admin1 !== null &&
          Object.keys(getTournament.Admin1).length > 0
            ? getTournament.Admin1.UUID
            : "",
        Admin2UUID:
          getTournament.Admin2 !== null &&
          Object.keys(getTournament.Admin2).length > 0
            ? getTournament.Admin2.UUID
            : "",
        Admin3UUID:
          getTournament.Admin3 !== null &&
          Object.keys(getTournament.Admin3).length > 0
            ? getTournament.Admin3.UUID
            : "",
        Admin2Contact:
          getTournament.Admin2 !== null &&
          Object.keys(getTournament.Admin2).length > 0
            ? await handlegetUserData(getTournament.Admin2.UUID)
            : "",
        Admin2Email:
          getTournament.Admin2 !== null &&
          Object.keys(getTournament.Admin2).length > 0
            ? await handlegetEmail(getTournament.Admin2.UUID)
            : "",
        Admin3Name:
          getTournament.Admin3 !== null &&
          Object.keys(getTournament.Admin3).length > 0
            ? getTournament.Admin3.Name
            : "",
        Admin3Contact:
          getTournament.Admin3 !== null &&
          Object.keys(getTournament.Admin3).length > 0
            ? await handlegetUserData(getTournament.Admin3.UUID)
            : "",
        Admin3Email:
          getTournament.Admin3 !== null &&
          Object.keys(getTournament.Admin3).length > 0
            ? await handlegetEmail(getTournament.Admin3.UUID)
            : "",
      });
      let AdminObj = {
        Admin1Name:
          getTournament.Admin1 !== null &&
          Object.keys(getTournament.Admin1).length > 0
            ? getTournament.Admin1.Name
            : "",
        Admin1Contact:
          getTournament.Admin1 !== null &&
          Object.keys(getTournament.Admin1).length > 0
            ? await handlegetUserData(getTournament.Admin1.UUID)
            : "",
        Admin1Email:
          getTournament.Admin1 !== null &&
          Object.keys(getTournament.Admin1).length > 0
            ? await handlegetEmail(getTournament.Admin1.UUID)
            : "",
        Admin2Name:
          getTournament.Admin2 !== null &&
          Object.keys(getTournament.Admin2).length > 0
            ? getTournament.Admin2.Name
            : "",
        Admin2Contact:
          getTournament.Admin2 !== null &&
          Object.keys(getTournament.Admin2).length > 0
            ? await handlegetUserData(getTournament.Admin2.UUID)
            : "",
        Admin2Email:
          getTournament.Admin2 !== null &&
          Object.keys(getTournament.Admin2).length > 0
            ? await handlegetEmail(getTournament.Admin2.UUID)
            : "",
        Admin3Name:
          getTournament.Admin3 !== null &&
          Object.keys(getTournament.Admin3).length > 0
            ? getTournament.Admin3.Name
            : "",
        Admin3Contact:
          getTournament.Admin3 !== null &&
          Object.keys(getTournament.Admin3).length > 0
            ? await handlegetUserData(getTournament.Admin3.UUID)
            : "",
        Admin3Email:
          getTournament.Admin3 !== null &&
          Object.keys(getTournament.Admin3).length > 0
            ? await handlegetEmail(getTournament.Admin3.UUID)
            : "",
      };
      setTournamentObj(AdminObj);
      setAdminobj(AdminObj);
      setCheckedSubstitute(getTournament.SubstitutionType);
      let Roundsdata: any = await [getTournament.Rounds];
      let Rounds = await Roundsdata[0].values;
      console.log("RoundsArray", Rounds);
      let arrlist = [];
      Rounds &&
        Rounds.length > 0 &&
        Rounds.map((ele) => {
          arrlist.push({
            Name: ele.Name,
            Format: ele.Format,
            id: ele.id,
            Comp: "hii",
            FixturesCreated: ele.FixturesCreated,
          });
        });
      setroundFormant(arrlist);
      setinputText({
        TournamentName: getTournament.Name,
        OrganizerName: getTournament.Organizer,
        Sport: getTournament.Sport !== null ? getTournament.Sport : "",
        TournamentStartDate: getTournament.StartDate,
        TournamentEndDate: getTournament.EndDate,
        Category: getTournament.AgeGroup,
        Playing: getTournament.Playing,
        Subsitute: getTournament.Substitute,
        Type: getTournament.Type,
        // SubsitutionType:getTournament,
        // ContactNo:getTournament,
        RegistrationFees: getTournament.Fees,
        CutOffDate: getTournament.CutOffDate,
        RegistrationEndDate: getTournament.RegistrationEndDate,
        MaxNoOfParticipants: getTournament.NoOfTeamsMax,
        logo: { URL: getTournament.Logo, FileName: "" },
        PDfFile: { URL: getTournament.RulesDocument, FileName: "" },
        banner: { URL: getTournament.Banner, FileName: "" },
        Venue: getTournament.Venues,
        Level: getTournament.Level,

        // Substitution:getTournament.,
        // RowlingSubstitution:false,

        MaxNoofTeam: getTournament.NoOfTeamsMax,
      });
      setCheckedPublish(
        getTournament.Status == "SCHEDULED" ||
          getTournament.Status == "INPROGRESS" ||
          getTournament.Status == "COMPLETED"
          ? "yes"
          : "no"
      );
      if (getTournament.Venues && getTournament.Venues.length > 0) {
        setVenues(getTournament.Venues);
      }

      if (getTournament.Prizes && getTournament.Prizes.length > 0) {
        setPrizesArr(getTournament.Prizes);
      }

      let fileName = getTournament.RulesDocument.substring(
        getTournament.RulesDocument.lastIndexOf("/") + 1
      );
      console.log("PdfaURl", fileName);

      if (fileName !== "RulesDocument.png") {
        setPDFfile({ URL: getTournament.RulesDocument, FileName: fileName });
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  };
  const handleCallbackforImages = (uri, name, idx, filename) => {
    console.log("hellobanner", uri, name, idx, filename);

    if (name == "logo") {
      console.log("hello1");
      let obj = { URL: uri, FileName: filename };
      setSelectedLogo(obj);
      setinputText({
        ...inputText,
        logo: obj,
      });
      setLogo(uri);
    }
    if (name == "profilePic") {
      console.log("hello2");
      let obj = { URL: uri, FileName: filename };
      setSelectedBaneer(obj);
      setinputText({
        ...inputText,
        banner: obj,
      });
      setBanner(uri);
    }
  };

  const handlePrizeDelete = (index) => {
    setPrizeModalVisible(true);
    setPrizeModalMesg("Are you sure do you want to Delete?");
    setPrizeIdToDelete(index);
  };

  const handleAddNewPrize = () => {
    const newRow = { Name: "", Description: "" };
    setPrizesArr([...PrizesArr, newRow]);
  };

  const handleAddNewRound = () => {
    const newRow = { Name: "", Format: "", id: "", no: "" };
    setroundFormant([...roundFormat, newRow]);
  };

  const PrizesdeleteField = (index) => {
    const updatedData = PrizesArr.filter((row, idx) => idx !== index);
    setPrizesArr(updatedData);
    setPrizeModalVisible(false);
  };

  const handleConfirmRemoveRound = () => {
    let obj = roundFormat[roundSelecteindex];
    const updatedData = roundFormat.filter(
      (row, idx) => idx !== roundSelecteindex
    );

    setRemoveRoundList([...removeRoundList, obj]);
    setroundFormant(updatedData);
    setPrizeModalVisible(false);
  };

  const handleRoundDelete = (index) => {
    let obj = roundFormat[index];
    console.log("helloObj", obj);
    if (obj.FixturesCreated == true) {
      setRoundSelecteindex(index);
      setPrizeModalVisible(true);
      setPrizeModalMesg(
        "If you delete this round, all matches for this round will be deleted. Do you still want to go ahead?"
      );
    } else {
      const updatedData = roundFormat.filter((row, idx) => idx !== index);

      setRemoveRoundList([...removeRoundList, obj]);
      setroundFormant(updatedData);
    }
  };

  const handleIputPrizesText = (field, value, index) => {
    console.log("PrizesArr", PrizesArr, field, value, index);
    let updateddata = JSON.parse(JSON.stringify(PrizesArr));
    updateddata[index][field] = value;
    setPrizesArr(updateddata);
  };
  const uploadLogoFileToStorage = async (folderName, key) => {
    // const contentType = key.split(";")[0].split(":")[1];
    console.log("folder name", folderName, "FileName", key.FileName);

    try {
      const response = await fetch(key.URL);
      const blob = await response.blob();
      let Response;
      await uploadData({
        key: `${folderName}/${key.FileName.replace(/ /g, "_")}`,
        data: blob,
      });
      Response = `https://${bucketName}.s3.ap-south-1.amazonaws.com/public/${folderName}/${key.FileName.replace(
        / /g,
        "_"
      )}`;
      return Response;
    } catch (err) {
      console.log("Error uploading file:", err);
    }
  };

  const addUser = async (phoneNumber) => {
    let finalResponse;
    const userAttribute = [
      {
        Name: "phone_number" /* required */,
        Value: phoneNumber,
      },
    ];
    const response = await createAdminUser(phoneNumber, userAttribute).then(
      async (data) => {
        console.log("CreateUserResponse : ", data);
        finalResponse = data;
      }
    );
    console.log("response", response);
    return finalResponse;
  };

  const cognitoUserDetails = async (phoneNumber) => {
    const response = await getAdminUser(phoneNumber);
    if (response) {
      console.log("response", response);

      // console.log("UserDetails : ", response.statusCode);
    }

    return response;
  };

  const handleAdminSave = async () => {
    console.log("handleAdminSave", Admininput, adminobj);
    let arrayOfObjects = [];
    let errorflag = false;
    let existingdataupdate = [];

    for (let i = 1; i <= 3; i++) {
      console.log("Admininput", Admininput[`Admin${i}Name`]);
      if (
        (Admininput[`Admin${i}Name`] !== adminobj.Admin1Name &&
          Admininput[`Admin${i}Name`] !== adminobj.Admin2Name &&
          Admininput[`Admin${i}Name`] !== adminobj.Admin3Name) ||
        (Admininput[`Admin${i}Contact`] !== adminobj.Admin1Contact &&
          Admininput[`Admin${i}Contact`] !== adminobj.Admin2Contact &&
          Admininput[`Admin${i}Contact`] !== adminobj.Admin3Contact)
      ) {
        arrayOfObjects.push({
          Name: Admininput[`Admin${i}Name`],
          Contact: Number(Admininput[`Admin${i}Contact`]),
          UUID: "",
          Email: Admininput[`Admin${i}Email`],
          AdminType: i,
        });
      } else {
        if (
          Admininput[`Admin${i}Email`] !== adminobj.Admin1Email ||
          Admininput[`Admin${i}Email`] !== adminobj.Admin2Email ||
          Admininput[`Admin${i}Email`] !== adminobj.Admin3Email
        ) {
          existingdataupdate.push({
            Name: Admininput[`Admin${i}Name`],
            Contact: Number(Admininput[`Admin${i}Contact`]),
            UUID: Admininput[`Admin${i}UUID`],
            Email: Admininput[`Admin${i}Email`],
            AdminType: i,
          });
        }
      }
    }
    console.log("existingdataupdate", existingdataupdate);
    console.log(
      "arrayOfObjects",
      adminobj,

      arrayOfObjects
    );

    existingdataupdate &&
      existingdataupdate.length > 0 &&
      existingdataupdate.map(async (ele) => {
        let getUserdata = await DataStore.query(UserMaster, ele.UUID);
        console.log("getUserdata", getUserdata);

        const updateUserMaster = UserMaster.copyOf(getUserdata, (updated) => {
          updated.EmailId = ele.Email;
          return updated;
        });
        console.log("updatedTournament", updateUserMaster);

        const ResponseUsermaster = await DataStore.save(updateUserMaster);
      });

    arrayOfObjects &&
      arrayOfObjects.length > 0 &&
      arrayOfObjects.map(async (ele) => {
        if (
          (ele.Contact !== 0 &&
            adminobj.Admin1Name == "" &&
            ele.AdminType == 1) ||
          (ele.Contact !== 0 &&
            adminobj.Admin2Name == "" &&
            ele.AdminType == 2) ||
          (ele.Contact !== 0 && adminobj.Admin3Name == "" && ele.AdminType == 3)
        ) {
          let res = await cognitoUserDetails("+91" + ele.Contact);
          console.log("cognitoUserDetails", res);
          if (res.statusCode === 400) {
            const resAddPlayer = await addUser("+91" + ele.Contact);
            console.log("Resp : ", resAddPlayer);
            ele.UUID = resAddPlayer.User.Username;
            try {
              let playerUserMasterData = await DataStore.save(
                new UserMaster({
                  UUID: ele.UUID,
                  Name: ele.Name,
                  ContactNo: Number(ele.Contact),
                  EmailId: ele.Email,
                  CreatedBy: UserDetails.Name,
                  LastUpdatedBy: UserDetails.Name,
                  RoleCoach: false,
                  RolePlayer: false,
                })
              );
              await DataStore.save(
                new UserMasterTournament({
                  userMasterUUID: ele.UUID,
                  tournamentId: CurrentTournament,
                })
              );
              let getTOurnamentdata = await DataStore.query(
                Tournament,
                CurrentTournament
              );
              if (getTOurnamentdata) {
                const updatedTournament = Tournament.copyOf(
                  getTOurnamentdata,
                  (updated) => {
                    updated.Admin1 =
                      ele.AdminType == 1
                        ? {
                            Name: ele.Name,
                            UUID: ele.UUID,
                            PhotoURL: Defaultlogo,
                          }
                        : updated.Admin1;
                    updated.Admin2 =
                      ele.AdminType == 2
                        ? {
                            Name: ele.Name,
                            UUID: ele.UUID,
                            PhotoURL: Defaultlogo,
                          }
                        : updated.Admin2;
                    updated.Admin3 =
                      ele.AdminType == 3
                        ? {
                            Name: ele.Name,
                            UUID: ele.UUID,
                            PhotoURL: Defaultlogo,
                          }
                        : updated.Admin3;

                    return updated;
                  }
                );
                console.log("updatedTournament", updatedTournament);

                const ResponseUpdatedTournament = await DataStore.save(
                  updatedTournament
                );
              }
            } catch (error) {}
          } else {
            ele.UUID = res.Username;
            await DataStore.save(
              new UserMasterTournament({
                userMasterUUID: ele.UUID,
                tournamentId: CurrentTournament,
              })
            );

            let getTOurnamentdata = await DataStore.query(
              Tournament,
              CurrentTournament
            );
            if (getTOurnamentdata) {
              const updatedTournament = Tournament.copyOf(
                getTOurnamentdata,
                (updated) => {
                  updated.Admin1 =
                    ele.AdminType == 1
                      ? {
                          Name: ele.Name,
                          UUID: ele.UUID,
                          PhotoURL: Defaultlogo,
                        }
                      : updated.Admin1;
                  updated.Admin2 =
                    ele.AdminType == 2
                      ? {
                          Name: ele.Name,
                          UUID: ele.UUID,
                          PhotoURL: Defaultlogo,
                        }
                      : updated.Admin2;
                  updated.Admin3 =
                    ele.AdminType == 3
                      ? {
                          Name: ele.Name,
                          UUID: ele.UUID,
                          PhotoURL: Defaultlogo,
                        }
                      : updated.Admin3;

                  return updated;
                }
              );
              console.log("updatedTournament", updatedTournament);
              const ResponseUpdatedTournament = await DataStore.save(
                updatedTournament
              );
            }
          }
        } else {
          errorflag = true;
          console.log("Mobile No Manadatory");
        }
      });

    return errorflag;
  };

  const handleRemoveAdmins = async (arrlist) => {
    console.log("arrlist", arrlist);
    for (let i = 0; i < arrlist.length; i++) {
      let res = await cognitoUserDetails("+91" + arrlist[i].contact);
      let UUID = res.Username;

      console.log("handleRemoveAdmins", res, UUID);
      try {
        const Response = await DataStore.delete(
          UserMasterTournament,
          (UserMasterTournament) =>
            UserMasterTournament?.userMasterUUID.eq(UUID)
        );
        console.log("DeleteResponse", Response);
        // if (Response) {
        let getTOurnamentdata = await DataStore.query(
          Tournament,
          CurrentTournament
        );

        console.log("getTOurnamentdata", getTOurnamentdata);
        if (getTOurnamentdata) {
          try {
            const updatedTournament = await Tournament.copyOf(
              getTOurnamentdata,
              (updated: any) => {
                updated.Admin1 =
                  arrlist[i].Admin == "Admin1Name"
                    ? {
                        Name: "",
                        PhotoURL: "",
                        UUID: "",
                      }
                    : updated.Admin1;
                updated.Admin2 =
                  arrlist[i].Admin == "Admin2Name"
                    ? {
                        Name: "",
                        PhotoURL: "",
                        UUID: "",
                      }
                    : updated.Admin2;
                updated.Admin3 =
                  arrlist[i].Admin == "Admin3Name"
                    ? {
                        Name: "",
                        PhotoURL: "",
                        UUID: "",
                      }
                    : updated.Admin3;

                return updated;
              }
            );
            console.log("updatedTournament", updatedTournament);
            const ResponseUpdatedTournament = await DataStore.save(
              updatedTournament
            );
          } catch (error) {
            console.log("error come after updatetournament", error);
          }
        }
        // }
      } catch (error) {
        console.log("Error in deleting TournamentObj", error);
      }
    }
  };

  const handleCreateRound = (data) => {
    console.log("handleCreateRound", data);
    let RoundTable = [];
    data.map((ele, index) => {
      RoundTable.push({
        No: index + 1,
        Name: ele.Name.trim(),
        Format: ele.Format,
        Completed: false,
        TeamsSelected: false,
        FixturesCreated: false,
        CreatedBy: UserDetails.Name,
        LastUpdatedBy: UserDetails.Name,
        Tournament: CurrentTournament,
      });
    });
    console.log("RoundTable", RoundTable);
    RoundTable.forEach(async (obj) => {
      try {
        const Response2 = await DataStore.save(new RoundMaster(obj));
        console.log("responseData", Response2);
      } catch (error) {
        console.log("error", error);
      }
    });
  };

  const handleRemoveRounds = async (Roundlist) => {
    console.log("helloRound", Roundlist);
    Roundlist &&
      Roundlist.length > 0 &&
      Roundlist.map(async (ele) => {
        const modelToDelete = await DataStore.query(RoundMaster, ele.id);
        const Response = await DataStore.delete(modelToDelete);
        if (Response) {
          getTournamentdata();
        }
      });
  };
  const handleCreateUpdateRound = async () => {
    console.log("handleCreateUpdateRound", roundFormat, removeRoundList);
    let createlist =
      roundFormat &&
      roundFormat.length > 0 &&
      roundFormat.filter((ele) => ele.id == "");
    console.log("hellouser", createlist);
    if (createlist && createlist.length > 0) {
      await handleCreateRound(createlist);
    }
    if (removeRoundList && removeRoundList.length > 0) {
      await handleRemoveRounds(removeRoundList);
    }
  };

  // const handleSave = async () => {
  //   await handleCreateUpdateRound();
  // };

  const handleSave = async () => {
    await handleCreateUpdateRound();
    console.log("inputText", inputText, roundFormat, Banner, Logo);
    console.log("helloupdate");
    console.log("adminobj", adminobj);
    let errorFlag = false;
    if (
      adminobj.Admin1Name == "" ||
      adminobj.Admin2Name == "" ||
      adminobj.Admin3Name == "" ||
      Admininput.Admin1Email !== adminobj.Admin1Email ||
      Admininput.Admin2Email !== adminobj.Admin2Email ||
      Admininput.Admin3Email !== adminobj.Admin3Email
    ) {
      errorFlag = await handleAdminSave();
      console.log("errorFlag", errorFlag);
    }

    if (errorFlag == false) {
      if (DeleteAdminArr && DeleteAdminArr.length > 0) {
        await handleRemoveAdmins(DeleteAdminArr);
      }
      let logo = "";
      let banner = "";
      let pdffile = "";
      let finalRules = "";
      console.log("finalRules", inputText.PDfFile, finalRules);

      if (selectedLogo && Object.keys(selectedLogo).length > 0) {
        logo = await uploadLogoFileToStorage(
          `tournament/${inputText.TournamentName.replace(/ /g, "_")}`,
          selectedLogo
        );
        console.log("logo", logo);
        // setLogoURl(logo);
      }
      if (selectedBaneer && Object.keys(selectedBaneer).length > 0) {
        banner = await uploadLogoFileToStorage(
          `tournament/${inputText.TournamentName.replace(/ /g, "_")}`,
          selectedBaneer
        );
        console.log("banner", banner);
        // setBannerURL(banner);
      }

      if (PDFfile && Object.keys(PDFfile).length > 0) {
        pdffile = await uploadLogoFileToStorage(
          `tournament/${inputText.TournamentName.replace(/ /g, "_")}`,
          PDFfile
        );
        console.log("pdffile", pdffile);
        // setBannerURL(banner);
      }

      const tournament = await DataStore.query(Tournament, CurrentTournament);
      const UpdateTournament = Tournament.copyOf(tournament, (updated) => {
        updated.Name = inputText.TournamentName;
        updated.Organizer = inputText.OrganizerName;
        updated.Sport = inputText.Sport;
        updated.RegistrationEndDate = inputText.RegistrationEndDate;
        updated.EndDate = inputText.TournamentEndDate;
        updated.AgeGroup = inputText.Category;
        updated.Type = inputText.Type;

        updated.RulesDocument =
          pdffile !== ""
            ? pdffile
            : inputText.PDfFile.URL !== "" || inputText.PDfFile.URL !== null
            ? inputText.PDfFile.URL
            : Defaultlogo;
        updated.Logo =
          logo !== ""
            ? logo
            : inputText.logo.URL !== "" || inputText.logo.URL !== null
            ? inputText.logo.URL
            : Defaultlogo;
        updated.Banner =
          banner !== ""
            ? banner
            : inputText.banner.URL !== "" || inputText.banner.URL !== null
            ? inputText.banner.URL
            : Defaultbanner;
        updated.StartDate = inputText.TournamentStartDate.toString();
        updated.Fees = inputText.RegistrationFees;
        updated.Playing = Number(inputText.Playing);
        updated.Substitute = Number(inputText.Subsitute);
        updated.NoOfTeamsMax = Number(inputText.MaxNoofTeam);
        updated.CutOffDate = inputText.CutOffDate;
        updated.Venues = venues;
        updated.Prizes = PrizesArr;
        updated.Level = inputText.Level;
        updated.Status =
          checkedPublish == "yes"
            ? "SCHEDULED"
            : checkedPublish == "no"
            ? "CREATED"
            : updated.Status;
        updated.SubstitutionType =
          checkedSubstitute == "yes"
            ? Substitution.REGULAR
            : Substitution.ROLLING;
        // Assuming FixturesCreated is the correct property name
        return updated;
      });
      console.log("UpdateTournament", UpdateTournament);
      const FinalTournamentUpdate = await DataStore.save(UpdateTournament);
      if (FinalTournamentUpdate) {
        console.log("Tournament Updated Sucessfully");
        getTournamentdata();
        setSuccessModalVisible(true);
        setSunccessModalMesg("Setting Updated Sucessfully");

        // setSuccessModalVisible(true);
      }
    } else {
      setErrorModalMesg("Both Name and Mobile Number are mandatory!");
      setErrorModalVisible(true);
    }

    // console.log("Updated RoundObj:", FinalTournamentUpdate);
    // getUserdata();
  };

  function convertToAWSDate(inputDate) {
    // Assuming inputDate is in a format that can be parsed by Date
    // Example: '2024-03-05T12:34:56'
    console.log("Input date : ", inputDate);
    // const dateObject = new Date(inputDate);
    // console.log("Date Object : ", dateObject);
    // Extract components
    // const year = dateObject.getUTCFullYear();
    // const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    // const day = dateObject.getUTCDate().toString().padStart(2, "0");

    // Combine components
    let awsDate = inputDate;
    console.log("AWSDATE : ", awsDate);
    return awsDate;
  }
  const handleDropdown = (value, Id, type) => {
    console.log("handleDropdown", Id, value, type, roundFormat);
    let Arrayformat = roundFormat.map((ele) => ({ ...ele }));
    if (type == "Name") {
      Arrayformat[Id].Name = value;
      setroundFormant(Arrayformat);
    }

    if (type == "Format") {
      let dropdown = RoundFormat;
      console.log("Arrayformat", Arrayformat);
      Arrayformat[Id].Format =
        value == "League"
          ? dropdown.SINGLE_ROUND_ROBIN
          : value == "Double Round Robin"
          ? dropdown.DOUBLE_ROUND_ROBIN
          : dropdown.KNOCKOUT;
      setroundFormant(Arrayformat);
    }
  };
  const getUserDetails = async () => {
    const userDetails = await AsyncStorage.getItem("UserDetail");
    setUserData(userDetails);
    const userdata = await DataStore.query(UserMaster, userDetails);
    console.log("SettingUserdata", userdata);
    setUserDetails(userdata);
  };
  const handleOk = () => {
    setSuccessModalVisible(false);
    setSunccessModalMesg("");
    // navigation.navigate(urlredirect);
  };
  useEffect(() => {
    getTournamentdata();
    getUserDetails();
  }, [CurrentTournament, userData]);

  const handlegetVenuedata = (data) => {
    console.log("handlegetVenuedata", data, venues);
    setVenues(data);
  };

  const venuesAddField = () => {
    const updatedVenues = [
      ...venues,
      {
        Name: "",
        Address: "",
        Coordinates: "",
      },
    ];
    setVenues(updatedVenues);
  };

  const handleVenuDelete = (index) => {
    console.log("index i get", index);

    setDeleteModalVisible(true);
    setDeleteModalMesg("Are you sure do you want to Delete?");
    setVenueIdToDelete(index);
  };

  const handleConfirm = () => {
    if (deleteModalMesg == "Are you sure do you want to Delete?") {
      venuesDeleteField(venueIdToDelete);
    }
  };

  const handleCancel = () => {
    setDeleteModalVisible(false);
  };

  const handleConfirm2 = () => {
    if (prizeModalMesg == "Are you sure do you want to Delete?") {
      PrizesdeleteField(prizeIdToDelete);
    }

    if (
      prizeModalMesg ==
      "If you delete this round, all matches for this round will be deleted. Do you still want to go ahead?"
    ) {
      console.log("hellodleete");
      handleConfirmRemoveRound();
    }
  };
  const handleCancel2 = () => {
    setPrizeModalVisible(false);
  };

  const venuesDeleteField = (index) => {
    const updatedVenues = [...venues];
    updatedVenues.splice(index, 1);
    setVenues(updatedVenues);
    setDeleteModalVisible(false);
  };

  const handleRemoveRegulationFile = () => {
    setPDFfile({});
  };
  const onSubmit = () => {
    setErrorModalVisible(false);
  };

  const handlegetFiledata = (file, Uri) => {
    console.log("hellofile", file, Uri);
    setPDFfile({ URL: Uri, FileName: file });
  };

  const handlechangeAdmindata = (ObjectAdmin, index) => {
    console.log("handleRemoveAdmin", ObjectAdmin, index);
    let newArray = [];
    for (let key in ObjectAdmin) {
      console.log("helloprint", key);
      if (key.includes(`Admin${index}Name`)) {
        let adminNameKey = `Admin${index}Name`;
        let adminContactKey = `Admin${index}Contact`;
        let adminUUIDKey = `Admin${index}UUID`;

        let adminName = ObjectAdmin[adminNameKey];
        let adminContact = ObjectAdmin[adminContactKey];
        let adminUUID = ObjectAdmin[adminUUIDKey];

        newArray.push({
          name: adminName,
          contact: adminContact,
          Admin: `Admin${index}Name`,
          UUID: adminUUID, // Not sure about the purpose of this line, it seems redundant
        });
        if (DeleteAdminArr && DeleteAdminArr.length > 0) {
          console.log(
            "finals",
            !DeleteAdminArr.some((obj) => obj.Admin == `Admin${index}Name`)
          );
          if (!DeleteAdminArr.some((obj) => obj.Admin == `Admin${index}Name`)) {
            setDeleteAdminArr([...DeleteAdminArr, newArray]);
          }
        } else {
          setDeleteAdminArr(newArray);
        }
      }

      setAdmininput({
        Admin1Name:
          `Admin${index}Name` == "Admin1Name" ? "" : Admininput.Admin1Name,
        Admin1Contact:
          `Admin${index}Name` == "Admin1Name" ? "" : Admininput.Admin1Contact,
        Admin1Email:
          `Admin${index}Name` == "Admin1Name" ? "" : Admininput.Admin1Email,
        Admin2Name:
          `Admin${index}Name` == "Admin2Name" ? "" : Admininput.Admin2Name,
        Admin2Contact:
          `Admin${index}Name` == "Admin2Name" ? "" : Admininput.Admin2Contact,
        Admin2Email:
          `Admin${index}Name` == "Admin2Name" ? "" : Admininput.Admin2Email,
        Admin3Name:
          `Admin${index}Name` == "Admin3Name" ? "" : Admininput.Admin3Name,
        Admin3Contact:
          `Admin${index}Name` == "Admin3Name" ? "" : Admininput.Admin3Contact,
        Admin3Email:
          `Admin${index}Name` == "Admin3Name" ? "" : Admininput.Admin3Email,
      });
      let AdminObj = {
        Admin1Name:
          `Admin${index}Name` == "Admin1Name" ? "" : tournamentObj.Admin1Name,
        Admin1Contact:
          `Admin${index}Name` == "Admin1Name" ? "" : Admininput.Admin1Contact,
        Admin2Name:
          `Admin${index}Name` == "Admin2Name" ? "" : tournamentObj.Admin2Name,
        Admin2Contact:
          `Admin${index}Name` == "Admin2Name" ? "" : Admininput.Admin2Contact,
        Admin3Name:
          `Admin${index}Name` == "Admin3Name" ? "" : tournamentObj.Admin3Name,
        Admin3Contact:
          `Admin${index}Name` == "Admin3Name" ? "" : Admininput.Admin3Contact,
      };

      setAdminobj(AdminObj);
      // console.log("newArray", newArray);
    }
  };
  // const handleRoundType = () => {
  //   console.log("Helloconsole");

  // };
  const handleRoundType = (field, event, idx) => {
    console.log("hellovalue", field, event, idx);
    const updatedPlayerList = roundFormat.map((player, index) => {
      if (index === idx) {
        if (field == "Format") {
          return {
            ...player,
            [field]:
              event == "League"
                ? "SINGLE_ROUND_ROBIN"
                : event == "Double Round Robin"
                ? "DOUBLE_ROUND_ROBIN"
                : "KNOCKOUT",
          };
        } else {
          return { ...player, [field]: event };
        }
      }
      return player;
    });
    setroundFormant(updatedPlayerList);
  };

  return (
    <View style={styles.TabContentContainer}>
      <H3 props={"Tournament Info"} style={{ color: colors.orange }} />
      <Label
        props={"Update Tournament Details here"}
        style={{ marginTop: 5, color: colors.white }}
      />
      <View style={[styles.straightLine, { marginVertical: 15 }]}></View>
      <View
        style={{
          flexDirection:
            Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
        }}
      >
        <View
          style={{
            width: Platform.OS === "web" && screenWidth > 760 ? "30%" : "100%",
          }}
        >
          <H3 props={"Tournament Name"} style={styles.settingHeadingText} />
        </View>
        <View style={{ flex: 1 }}>
          <View style={styles.settingFullItem}>
            <Text style={styles.TextInputLabel}>Name</Text>
            <TextInput
              style={styles.inputStyle}
              value={inputText.TournamentName}
              onChangeText={(text) => handleInput("TournamentName", text)}
              placeholder=""
            />
          </View>
        </View>
      </View>
      <View style={[styles.straightLine, { marginVertical: 15 }]}></View>
      <View
        style={{
          flexDirection:
            Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
        }}
      >
        <View
          style={{
            width: Platform.OS === "web" && screenWidth > 760 ? "30%" : "100%",
          }}
        >
          <H3 props={"Organiser’s Details"} style={styles.settingHeadingText} />
        </View>
        <View
          style={[
            styles.settingContainer,
            {
              flex: 1,
            },
          ]}
        >
          <View style={styles.settingHalfItem}>
            <Text style={styles.TextInputLabel}>Name</Text>
            <TextInput
              style={styles.inputStyle}
              value={inputText.OrganizerName}
              onChangeText={(text) => handleInput("OrganizerName", text)}
              placeholder=""
            />
          </View>
          <View style={styles.settingHalfItem}>
            <Text style={styles.TextInputLabel}>Contact No.</Text>
            <TextInput
              style={styles.inputStyle}
              value={UserDetails.ContactNo}
              aria-disabled={true}
              onChangeText={(text) => handleInput("ContactNo", text)}
              placeholder=""
            />
          </View>
        </View>
      </View>
      <View style={[styles.straightLine, { marginVertical: 15 }]}></View>
      {/* admin started here */}
      <View
        style={{
          flexDirection:
            Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
        }}
      >
        <View
          style={{
            width: Platform.OS === "web" && screenWidth > 760 ? "30%" : "100%",
          }}
        >
          <H3 props={"Admin's Details"} style={styles.settingHeadingText} />
        </View>
        <View
          style={[
            styles.settingContainer,
            {
              flex: 1,
              flexDirection: "row",
              gap: 10,
            },
          ]}
        >
          <View style={styles.customAdminRow}>
            <View style={[styles.settingAdmin, { flex: 4 }]}>
              <Text style={styles.TextInputLabel}>Admin 1 Name </Text>
              <TextInput
                style={styles.inputStyle}
                value={Admininput.Admin1Name}
                aria-disabled={adminobj.Admin1Name !== "" ? true : false}
                onChangeText={(text) => handleinputText("Admin1Name", text)}
                placeholder=""
              />
            </View>
            <View style={[styles.settingAdmin, { flex: 2.6 }]}>
              <Text style={styles.TextInputLabel}>Admin 1 Number</Text>
              <TextInput
                style={styles.inputStyle}
                // value={UserDetails.ContactNo}
                aria-disabled={adminobj.Admin1Name !== "" ? true : false}
                value={Admininput.Admin1Contact}
                onChangeText={(text) => handleinputText("Admin1Contact", text)}
                placeholder=""
              />
            </View>
            <View style={[styles.settingAdmin, { flex: 4 }]}>
              <Text style={styles.TextInputLabel}>Admin 1 Email</Text>
              <TextInput
                style={styles.inputStyle}
                // value={UserDetails.ContactNo}
                // aria-disabled={adminobj.Admin1Name !== "" ? true : false}
                value={Admininput.Admin1Email}
                onChangeText={(text) => handleinputText("Admin1Email", text)}
                placeholder=""
              />
            </View>
            <View style={{ width: 34 }}>
              {adminobj.Admin1Name !== "" ? (
                <Pressable
                  style={styles.deleteButton2}
                  onPress={() => handlechangeAdmindata(Admininput, 1)}
                >
                  <Icon name="trash" size={20} color="red" />
                </Pressable>
              ) : null}
            </View>
          </View>

          <View style={styles.customAdminRow}>
            <View style={[styles.settingAdmin, { flex: 4 }]}>
              <Text style={styles.TextInputLabel}>Admin 2 Name</Text>
              <TextInput
                style={styles.inputStyle}
                // value={inputText.OrganizerName}
                value={Admininput.Admin2Name}
                aria-disabled={adminobj.Admin2Name !== "" ? true : false}
                onChangeText={(text) => handleinputText("Admin2Name", text)}
                placeholder=""
              />
            </View>
            <View style={[styles.settingAdmin, { flex: 2.6 }]}>
              <Text style={styles.TextInputLabel}>Admin 2 Contact No.</Text>
              <TextInput
                style={styles.inputStyle}
                // value={UserDetails.ContactNo}
                aria-disabled={adminobj.Admin2Name !== "" ? true : false}
                value={Admininput.Admin2Contact}
                onChangeText={(text) => handleinputText("Admin2Contact", text)}
                placeholder=""
              />
            </View>
            <View style={[styles.settingAdmin, { flex: 4 }]}>
              <Text style={styles.TextInputLabel}>Admin 2 Email</Text>
              <TextInput
                style={styles.inputStyle}
                // value={UserDetails.ContactNo}
                // aria-disabled={adminobj.Admin2Name !== "" ? true : false}
                value={Admininput.Admin2Email}
                onChangeText={(text) => handleinputText("Admin2Email", text)}
                placeholder=""
              />
            </View>
            <View style={{ width: 34 }}>
              {adminobj.Admin2Name !== "" ? (
                <Pressable
                  style={styles.deleteButton2}
                  onPress={() => handlechangeAdmindata(Admininput, 2)}
                >
                  <Icon name="trash" size={20} color="red" />
                </Pressable>
              ) : null}
            </View>
          </View>

          <View style={styles.customAdminRow}>
            <View style={[styles.settingAdmin, { flex: 4 }]}>
              <Text style={styles.TextInputLabel}>Admin 3 Name</Text>
              <TextInput
                style={styles.inputStyle}
                value={Admininput.Admin3Name}
                onChangeText={(text) => handleinputText("Admin3Name", text)}
                aria-disabled={adminobj.Admin3Name !== "" ? true : false}
                placeholder=""
              />
            </View>
            <View style={[styles.settingAdmin, { flex: 2.6 }]}>
              <Text style={styles.TextInputLabel}>Admin 3 Contact No.</Text>
              <TextInput
                style={styles.inputStyle}
                // value={UserDetails.ContactNo}
                aria-disabled={adminobj.Admin3Name !== "" ? true : false}
                value={Admininput.Admin3Contact}
                onChangeText={(text) => handleinputText("Admin3Contact", text)}
                placeholder=""
              />
            </View>
            <View style={[styles.settingAdmin, { flex: 4 }]}>
              <Text style={styles.TextInputLabel}>Admin 3 Email</Text>
              <TextInput
                style={styles.inputStyle}
                // value={UserDetails.ContactNo}
                // aria-disabled={adminobj.Admin3Name !== "" ? true : false}
                value={Admininput.Admin3Email}
                onChangeText={(text) => handleinputText("Admin3Email", text)}
                placeholder=""
              />
            </View>
            <View style={{ width: 34 }}>
              {adminobj.Admin3Name !== "" ? (
                <Pressable
                  style={styles.deleteButton2}
                  onPress={() => handlechangeAdmindata(Admininput, 3)}
                >
                  <Icon name="trash" size={20} color="red" />
                </Pressable>
              ) : null}
            </View>
          </View>
        </View>
      </View>
      {/* admin ended here */}

      <View style={[styles.straightLine, { marginVertical: 15 }]}></View>
      <View
        style={{
          flexDirection:
            Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
        }}
      >
        <View
          style={{
            width: Platform.OS === "web" && screenWidth > 760 ? "30%" : "100%",
          }}
        >
          <H3 props={"Tournament Details"} style={styles.settingHeadingText} />
        </View>
        <View style={[styles.settingContainer, { flex: 1 }]}>
          <View style={{ width: "100%" }}>
            <View style={styles.settingFullItem}>
              {Platform.OS === "web" && screenWidth > 760 && (
                <>
                  <Text style={styles.TextInputLabel}>Venue Name</Text>
                  {venues.map((venue, index) => (
                    <View key={index} style={styles.venueRow}>
                      <Venue
                        Nameflag={true}
                        handlegetVenuedata={handlegetVenuedata}
                        Index={index}
                        VenueObject={venue}
                        VenueList={venues}
                      />

                      <View style={styles.settingTdbtnRow}>
                        {index < venues.length - 1 ||
                        (index == venues.length - 1 && index !== 0) ? (
                          <Pressable
                            style={styles.deleteButton}
                            onPress={
                              () => handleVenuDelete(index)
                              // venuesDeleteField(index)
                            }
                          >
                            <Icon name="trash" size={20} color="red" />
                          </Pressable>
                        ) : null}

                        {!(index < venues.length - 1) ? (
                          <Pressable
                            style={[styles.addButton, { marginTop: 0 }]}
                            onPress={venuesAddField}
                          >
                            <AddIcon2
                              name="add-sharp"
                              size={20}
                              color={colors.white}
                            />
                          </Pressable>
                        ) : null}
                      </View>
                    </View>
                  ))}
                </>
              )}
            </View>
          </View>
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {Platform.OS === "web" ? (
              <View style={styles.webDateWrapper}>
                <View>
                  <Text style={styles.TextInputLabel}>Start Date</Text>
                  <CustomDatePicker
                    selected={inputText.TournamentStartDate}
                    onChange={(date) => {
                      console.log(date);
                      const val = moment(date);
                      console.log(val);
                      handleDateChange(val, "TournamentStartDate");
                    }}
                    containerStyle={{
                      backgroundColor: colors.royalBlue,
                      padding: 10,
                    }}
                    portalId="Settings"
                  />
                </View>
                <View>
                  <Text style={styles.TextInputLabel}> End Date</Text>
                  <CustomDatePicker
                    selected={inputText.TournamentEndDate}
                    onChange={(date) => {
                      console.log(date);
                      const val = moment(date);
                      console.log(val);
                      handleDateChange(val, "TournamentEndDate");
                    }}
                    containerStyle={{
                      backgroundColor: colors.royalBlue,
                      padding: 10,
                    }}
                    portalId={"root-2"}
                  />
                </View>
                <View>
                  <Text style={styles.TextInputLabel}>
                    Select Cut off date (if any)
                  </Text>
                  <CustomDatePicker
                    selected={inputText.CutOffDate}
                    onChange={(date) => {
                      console.log(date);
                      const val = moment(date);
                      console.log(val);
                      handleDateChange(val, "CutOffDate");
                    }}
                    containerStyle={{
                      backgroundColor: colors.royalBlue,
                      padding: 10,
                    }}
                    portalId={"root-3"}
                  />
                </View>
                <View>
                  <Text style={[styles.TextInputLabel]}>
                    Registration End Date
                  </Text>
                  <CustomDatePicker
                    selected={inputText.RegistrationEndDate}
                    onChange={(date) => {
                      console.log(date);
                      const val = moment(date);
                      console.log(val);
                      handleDateChange(val, "RegistrationEndDate");
                    }}
                    containerStyle={{
                      backgroundColor: colors.royalBlue,
                      padding: 10,
                    }}
                    portalId={"root-4"}
                  />
                </View>
              </View>
            ) : null}
          </View>

          <View style={styles.settingHalfItem}>
            <Text style={styles.TextInputLabel}>Game/Sport</Text>
            <TextInput
              style={styles.inputStyle}
              value={inputText.Sport}
              onChangeText={(text) => handleInput("Sport", text)}
              placeholder=""
            />
          </View>
          <View style={styles.settingHalfItem}>
            <Text style={styles.TextInputLabel}>Tournament Type</Text>
            <TextInput
              style={styles.inputStyle}
              value={inputText.Type}
              onChangeText={(text) => handleInput("Type", text)}
              placeholder=""
            />
          </View>

          <View style={[styles.settingHalfItem]}>
            <Text style={styles.TextInputLabel}>Participants Category</Text>
            <TextInput
              style={styles.inputStyle}
              value={inputText.Category}
              onChangeText={(text) => handleInput("Category", text)}
              placeholder=""
            />
          </View>

          <View style={[styles.settingHalfItem]}>
            <Text style={styles.TextInputLabel}>Registration Fee</Text>
            <TextInput
              style={styles.inputStyle}
              value={inputText.RegistrationFees}
              onChangeText={(text) => handleInput("RegistrationFees", text)}
              placeholder=""
            />
          </View>

          <View style={[styles.settingHalfItem]}>
            <Text style={styles.TextInputLabel}>Playing</Text>
            <TextInput
              style={styles.inputStyle}
              value={inputText.Playing}
              onChangeText={(text) => handleInput("Playing", text)}
              placeholder=""
            />
          </View>
          <View style={[styles.settingHalfItem]}>
            <Text style={[styles.TextInputLabel]}>Substitute</Text>
            <TextInput
              style={styles.inputStyle}
              value={inputText.Subsitute}
              onChangeText={(text) => handleInput("Subsitute", text)}
              placeholder=""
            />
          </View>
          <View style={styles.settingHalfItem}>
            <Text style={styles.TextInputLabel}>Level</Text>
            <TextInput
              style={styles.inputStyle}
              value={inputText.Level}
              onChangeText={(text) => handleInput("Level", text)}
              placeholder=""
            />
          </View>
        </View>
      </View>
      <View style={[styles.straightLine, { marginVertical: 15 }]}></View>

      <View style={{ flexDirection: "row" }}>
        <View
          style={{
            width: Platform.OS === "web" && screenWidth > 760 ? "30%" : null,
          }}
        >
          {Platform.OS === "web" && screenWidth > 760 && (
            <H3 props={"Substitution Type"} style={styles.settingHeadingText} />
          )}
        </View>
        <View style={{ flex: 1 }}>
          <View style={[styles.settingFullItem]}>
            <View
              style={{
                flexDirection: "row",
                gap: Platform.OS === "web" && screenWidth > 760 ? 40 : 20,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <RadioButton
                  value="yes"
                  status={
                    checkedSubstitute === "SUBSITUTE" ? "checked" : "unchecked"
                  }
                  onPress={() => handleGroupYesNoSelect("SUBSITUTE")}
                  color={colors.aqua}
                />
                <Text
                  style={{
                    fontFamily: "PlusJakartaSansMedium",
                    color: colors.white,
                  }}
                >
                  Substitution
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <RadioButton
                  value="no"
                  status={
                    checkedSubstitute === "REGULAR" ? "checked" : "unchecked"
                  }
                  onPress={() => handleGroupYesNoSelect("REGULAR")}
                  color={colors.aqua}
                />
                <Text
                  style={{
                    fontFamily: "PlusJakartaSansMedium",
                    color: colors.white,
                  }}
                >
                  Rolling Substitution
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      <View style={[styles.straightLine, { marginVertical: 15 }]}></View>
      <View
        style={{
          flexDirection:
            Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
        }}
      >
        <View
          style={{
            width: Platform.OS === "web" && screenWidth > 760 ? "30%" : "100%",
          }}
        >
          <H3
            props={"Max. No. of Participants"}
            style={styles.settingHeadingText}
          />
        </View>
        <View style={{ flex: 1 }}>
          <View style={styles.settingHalfItem}>
            {Platform.OS === "web" && screenWidth > 760 && (
              <Text style={styles.TextInputLabel}>
                No. of teams participation
              </Text>
            )}

            <TextInput
              style={styles.inputStyle}
              value={inputText.MaxNoofTeam}
              onChangeText={(text) => handleInput("MaxNoofTeam", text)}
              placeholder=""
            />
          </View>
        </View>
      </View>
      <View style={[styles.straightLine, { marginVertical: 15 }]}></View>
      <View
        style={{
          flexDirection:
            Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
        }}
      >
        <View
          style={{
            width: Platform.OS === "web" && screenWidth > 760 ? "30%" : "100%",
          }}
        >
          <H3 props={"Prizes"} style={styles.settingHeadingText} />
        </View>
        <View style={{ flex: 1 }}>
          <View style={styles.settingFullItem}>
            {Platform.OS === "web" && screenWidth > 760 && (
              <Text style={styles.TextInputLabel}>Enter Prizes</Text>
            )}
            {PrizesArr.map((val, index) => {
              return (
                <View style={{ flexDirection: "row", gap: 15 }}>
                  <TextInput
                    style={styles.inputStyle}
                    value={val.Name}
                    onChangeText={(text) => {
                      handleIputPrizesText("Name", text, index);
                    }}
                    placeholder=""
                  />
                  <TextInput
                    style={styles.inputStyle}
                    value={val.Description}
                    onChangeText={(text) => {
                      handleIputPrizesText("Description", text, index);
                    }}
                    // onChangeText={(text) => handleInput("MaxNoofTeam", text)}
                    placeholder=""
                  />

                  {index < PrizesArr.length - 1 ||
                  index == PrizesArr.length - 1 ? (
                    <Pressable
                      style={styles.deleteButton}
                      onPress={
                        () => handlePrizeDelete(index)
                        // PrizesdeleteField(index)
                      }
                    >
                      <Icon name="trash" size={20} color="red" />
                    </Pressable>
                  ) : null}

                  {!(index < PrizesArr.length - 1) ? (
                    <Pressable
                      style={[styles.addButton, { marginTop: 0 }]}
                      onPress={handleAddNewPrize}
                    >
                      <AddIcon2
                        name="add-sharp"
                        size={20}
                        color={colors.white}
                      />
                    </Pressable>
                  ) : null}
                </View>
              );
            })}
          </View>
        </View>
      </View>

      <View style={[styles.straightLine, { marginVertical: 15 }]}></View>
      <View
        style={{
          flexDirection:
            Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
        }}
      >
        <View
          style={{
            width: Platform.OS === "web" && screenWidth > 760 ? "30%" : "100%",
          }}
        >
          <H3 props={"Rounds"} style={styles.settingHeadingText} />
        </View>
        <View style={{ flex: 1 }}>
          <View style={styles.settingFullItem}>
            <>
              <View
                style={{
                  flexDirection: "row",
                  gap: Platform.OS === "web" && screenWidth > 760 ? 325 : 80,
                }}
              >
                <Text style={styles.TextInputLabel}>Name</Text>
                <Text style={styles.TextInputLabel}>Format</Text>
              </View>
            </>
            {/* )} */}
            {roundFormat.map((val, index) => {
              return (
                <View
                  style={{
                    flexDirection: "row",
                    gap: 15,
                    width: Platform.OS !== "web" ? 170 : undefined,
                    paddingBottom: Platform.OS !== "web" ? 20 : undefined,
                  }}
                >
                  <TextInput
                    style={[
                      styles.inputStyle,
                      { width: Platform.OS !== "web" ? 150 : undefined },
                    ]}
                    value={val.Name}
                    onChangeText={(text) =>
                      handleRoundType("Name", text, index)
                    }
                    placeholder=""
                    aria-disabled={val.no !== "" ? true : false}
                  />

                  <View
                    style={[
                      styles.textField,
                      {
                        width: Platform.OS !== "web" ? 180 : undefined,

                        paddingRight: Platform.OS !== "web" ? 150 : undefined,
                      },
                    ]}
                  >
                    <Picker
                      style={[
                        styles.dropdownContainer2,
                        {
                          width: Platform.OS !== "web" ? 150 : undefined,
                        },
                      ]}
                      selectedValue={
                        val.Format == "SINGLE_ROUND_ROBIN"
                          ? "League"
                          : val.Format == "DOUBLE_ROUND_ROBIN"
                          ? "Double Round Robin"
                          : val.Format == "KNOCKOUT"
                          ? "KnockOut"
                          : "Select format"
                      }
                      onValueChange={(itemValue, itemIndex) =>
                        handleRoundType("Format", itemValue, index)
                      }
                      // disabled={val.no !== "" ? true : false}
                    >
                      <Picker.Item
                        label="Select format"
                        value="Select format"
                      />
                      <Picker.Item label="League" value="League" />

                      <Picker.Item label="KnockOut" value="KnockOut" />
                      <Picker.Item
                        label="League+KnockOut"
                        value="League+KnockOut"
                      />
                      <Picker.Item
                        label="Double Round Robin"
                        value="Double Round Robin"
                      />
                      <Picker.Item
                        label="Double Round Robin+KnockOut"
                        value="Double Round Robin+KnockOut"
                      />
                    </Picker>
                  </View>

                  <View style={styles.customRoundDelBtn}>
                    {index < roundFormat.length - 1 ||
                    index == roundFormat.length - 1 ? (
                      <Pressable
                        style={styles.deleteButton}
                        onPress={
                          () => handleRoundDelete(index)
                          // PrizesdeleteField(index)
                        }
                      >
                        <Icon name="trash" size={20} color="red" />
                      </Pressable>
                    ) : null}

                    {!(index < roundFormat.length - 1) ? (
                      <Pressable
                        style={[styles.addButton, { marginTop: 0 }]}
                        onPress={handleAddNewRound}
                      >
                        <AddIcon2
                          name="add-sharp"
                          size={20}
                          color={colors.white}
                        />
                      </Pressable>
                    ) : null}
                  </View>
                </View>
              );
            })}
          </View>
        </View>
      </View>

      <View style={[styles.straightLine, { marginVertical: 15 }]}></View>

      <View
        style={{
          flexDirection:
            Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
        }}
      >
        <View
          style={{
            width: Platform.OS === "web" && screenWidth > 760 ? "30%" : "100%",
          }}
        >
          <H3
            props={
              Platform.OS === "web" && screenWidth > 760
                ? "Tournament Logo & Banner"
                : "Upload a new logo"
            }
            style={styles.settingHeadingText}
          />
        </View>
        <View
          style={{
            flex: 1,
            flexDirection:
              Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
            gap: 10,
          }}
        >
          <View
            style={{
              width: 130,

              justifyContent: "center",

              alignItems: "center",
            }}
          >
            <UploadImage
              imageUrl={inputText?.logo?.URL}
              onImageSelect={handleCallbackforImages}
              kindOfFile={"logo"}
            />
          </View>
          <View style={{ flex: 1 }}>
            <UploadImage
              style={{ width: "100%", borderRadius: 3 }}
              imageStyle={{ borderRadius: 3 }}
              imageUrl={inputText?.banner?.URL}
              onImageSelect={handleCallbackforImages}
              kindOfFile={"profilePic"}
              imageViewStyle={styles.BannerViewImage}
            />
          </View>
        </View>
      </View>
      <View style={[styles.straightLine, { marginVertical: 15 }]}></View>
      <View
        style={{
          flexDirection:
            Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
        }}
      >
        <View
          style={{
            width: Platform.OS === "web" && screenWidth > 760 ? "30%" : "100%",
          }}
        >
          <H3
            props={"Rules & Regulations File"}
            style={styles.settingHeadingText}
          />
        </View>
        {/* <View style={{ flex: 1, flexDirection: "row", gap: 10 }}> */}
        {/* {PDFfile !== "" ? ( */}
        <View style={{ flex: 1, flexDirection: "column", gap: 10 }}>
          <UploadFile
            fileName={PDFfile.FileName}
            handlegetFiledata={handlegetFiledata}
            // handleRemoveRegulationFile={handleRemoveRegulationFile}
          />
          <View>
            {Object.keys(PDFfile).length > 0 && PDFfile.FileName !== "" ? (
              <Text
                style={{
                  fontFamily: "PlusJakartaSansSemiBold",
                  fontSize: 17,
                  marginBottom: 10,
                  color: colors.white,
                }}
              >
                Uploaded
              </Text>
            ) : null}
            {Object.keys(PDFfile).length > 0 && PDFfile.FileName !== "" ? (
              <View style={styles.uploadedPDFContainer}>
                <Text
                  style={{
                    fontFamily: "PlusJakartaSansMedium",
                    color: colors.white,
                  }}
                >
                  {PDFfile.FileName && PDFfile.FileName.split("_").join(" ")}
                </Text>

                <Pressable onPress={handleRemoveRegulationFile}>
                  <TrashIcon name="trash-2" size={25} color="#f13737" />
                </Pressable>
              </View>
            ) : null}
          </View>
        </View>
        {/* ) : null} */}
        {/* </View> */}
      </View>
      <View style={[styles.straightLine, { marginVertical: 15 }]}></View>

      {/*  */}

      <View
        style={{
          flexDirection:
            Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
        }}
      >
        <View
          style={{
            width: Platform.OS === "web" && screenWidth > 760 ? "100%" : "100%",
          }}
        >
          <H3
            props={
              "Are you ready to publish this tournament so that everyone can see it?"
            }
            style={styles.settingHeadingText}
          />
          <View style={[styles.settingFullItem]}>
            <View
              style={{
                flexDirection: "row",
                gap: Platform.OS === "web" && screenWidth > 760 ? 40 : 20,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <RadioButton
                  value="yes"
                  status={checkedPublish === "yes" ? "checked" : "unchecked"}
                  onPress={() => handlePublishSelect("yes")}
                  color={colors.aqua}
                />
                <Text
                  style={{
                    fontFamily: "PlusJakartaSansMedium",
                    color: colors.white,
                  }}
                >
                  Yes
                </Text>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <RadioButton
                  value="no"
                  status={checkedPublish === "no" ? "checked" : "unchecked"}
                  onPress={() => handlePublishSelect("no")}
                  color={colors.aqua}
                />
                <Text
                  style={{
                    fontFamily: "PlusJakartaSansMedium",
                    color: colors.white,
                  }}
                >
                  No
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      <View style={[styles.straightLine, { marginVertical: 15 }]}></View>

      {/*  */}

      <View style={styles.btnWrapper}>
        <Pressable style={styles.cancelBtn} onPress={() => handleCancelcheck()}>
          <Text style={styles.cancelBtnText}>Cancel</Text>
        </Pressable>
        <Pressable
          style={styles.saveChangesBtn}
          onPress={() => {
            handleSave();
          }}
        >
          <Text style={styles.btnText}>Save Changes</Text>
        </Pressable>
      </View>
      {SuccessModalVisible == true ? (
        <ModalTrophy
          visibleModal={SuccessModalVisible}
          SuccessMessage={SunccessModalMesg}
          handleOk={handleOk}
        />
      ) : null}

      {deleteModalVisible == true ? (
        <AreYouSureOrange
          visibleModal={deleteModalVisible}
          Message={deleteModalMesg}
          ButtonText={"Delete"}
          onConfirm={() => handleConfirm()}
          onCancel={() => handleCancel()}
        />
      ) : null}

      {prizeModalVisible == true ? (
        <AreYouSureOrange
          visibleModal={prizeModalVisible}
          Message={prizeModalMesg}
          ButtonText={"Delete"}
          onConfirm={() => handleConfirm2()}
          onCancel={() => handleCancel2()}
        />
      ) : null}

      {errorModalVisible == true ? (
        <ErrorModal
          visibleModal={errorModalVisible}
          SuccessMessage={errorModalMesg}
          onSubmit={onSubmit}
          header={"Attention"}
        />
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  TabContentContainer: {
    width: "100%",
    paddingBottom: Platform.OS === "web" ? undefined : 120,
  },
  textField: {
    flex: 1,
    maxWidth: "50%",
    minWidth: "50%",
    marginBottom: 0,
    color: colors.white,
  },

  dropdownContainer2: {
    width: "100%",
    marginBottom: 0,
    height: Platform.OS === "web" ? 34 : 20,
    borderRadius: 5,
    backgroundColor: colors.royalBlue,
    color: colors.white,
  },

  deleteButton2: {
    width: 34,
    height: 34,
    borderRadius: 30,
    justifyContent: "center",
    alignItems: "center",
    marginTop: screenWidth > 760 ? 28 : undefined,
    backgroundColor: colors.royalBlue,
    position: screenWidth > 760 ? undefined : "absolute",
    top: screenWidth > 760 ? undefined : 0,
    right: screenWidth > 760 ? undefined : 0,
    borderTopRightRadius: screenWidth > 760 ? undefined : 0,
  },

  deleteButton: {
    width: 34,
    height: 34,
    borderRadius: 30,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.royalBlue,
  },
  addButton: {
    width: 34,
    height: 34,
    borderRadius: 30,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.royalBlue,
    marginTop: 32,
  },
  venuRequired: {
    color: "red",
    paddingLeft: 20,
  },
  dropDownContainer: {
    width: 190,
    borderColor: "transparent",
    borderWidth: 0,
  },
  straightLine: {
    width: "100%",
    height: 1,
    backgroundColor: "#BBB9B9",
  },
  inputStyle: {
    width: "100%",
    height: Platform.OS === "web" && screenWidth > 760 ? 35 : 40,
    borderColor: colors.borderColor,
    borderWidth: 1,
    paddingHorizontal: 8,
    fontFamily: "PlusJakartaSansExtraLight",
    // outlineColor: "transparent",
    // outlineWidth: 0,
    borderRadius: 5,
    color: colors.white,
    backgroundColor: colors.royalBlue,
  },
  settingHeadingText: {
    paddingBottom: Platform.OS === "web" && screenWidth > 760 ? null : 5,
    color: colors.white,
    fontFamily: "PlusJakartaSansRegular",
    fontSize: screenWidth > 760 ? undefined : 16,
  },
  settingContainer: {
    flexDirection: "row",
    gap: Platform.OS === "web" && screenWidth > 760 ? 15 : 10,
    flexWrap: "wrap",
  },

  settingHalfItem: {
    minWidth: Platform.OS === "web" && screenWidth > 760 ? "40%" : "100%",
    maxWidth: "50%",
    gap: 5,
    flex: 1,
  },
  settingHalfItem1: {
    minWidth: "40%",
    maxWidth: "50%",
    gap: 5,
    flex: 1,
  },
  settingAdmin: {
    // minWidth: Platform.OS === "web" && screenWidth > 760 ? "30%" : "100%",
    // maxWidth: "40%",
    gap: 5,
    flex: 1,
  },
  settingRoundContainer: {
    gap: 15,
  },
  settingFullItem: {
    width: "100%",
    gap: 5,
  },
  venueRow: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
    gap: 15,
    marginBottom: 10,
  },

  TextInputLabel: {
    fontFamily: "PlusJakartaSansExtraLight",
    fontSize: 14,
    color: colors.white,
    paddingBottom: 5,
  },
  dropdownWrapper: {
    width: "100%",
    height: Platform.OS === "web" && screenWidth > 760 ? 35 : 40,
    // borderColor: "#B4B4B4",
    // borderWidth: 1,
    // paddingHorizontal: 8,
    borderRadius: 5,
  },
  btnWrapper: {
    flexDirection: "row",
    justifyContent:
      Platform.OS === "web" && screenWidth > 760 ? "flex-end" : "center",
    gap: 15,
  },
  cancelBtn: {
    borderColor: colors.white,
    borderWidth: 1,
    width: 110,
    height: Platform.OS === "web" && screenWidth > 760 ? 30 : 40,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  saveChangesBtn: {
    backgroundColor: colors.aqua,
    borderColor: colors.aqua,
    borderWidth: 1,
    width: 110,
    height: Platform.OS === "web" && screenWidth > 760 ? 30 : 40,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  btnText: {
    fontFamily: "PlusJakartaSansMedium",
    color: colors.royalBlue,
  },
  cancelBtnText: {
    fontFamily: "PlusJakartaSansMedium",
    color: colors.white,
  },
  BannerViewImage: {
    borderRadius: 3,
  },
  uploadedPDFContainer: {
    borderColor: colors.aqua,
    borderWidth: 1,
    maxWidth: 420,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 7,
    paddingHorizontal: 10,
    borderRadius: 5,
  },
  customAdminRow: {
    width: "100%",
    flexDirection: screenWidth > 760 ? "row" : "column",
    gap: 10,
    backgroundColor: screenWidth > 760 ? "transparent" : colors.newBlue,
    borderRadius: 10,
    padding: screenWidth > 760 ? undefined : 10,
  },
  settingTdbtnRow: {
    flexDirection: "row",
    gap: 5,
    width: 75,
  },
  webDateWrapper: {
    flexWrap: "wrap",
    flexDirection: "row",
    gap: 10,
    width: "100%",
  },
  customRoundDelBtn: {
    flexDirection: "row",
    gap: 5,
    width: 70,
  },
});

export default Settings;
