//import liraries
import { DataStore } from "aws-amplify/datastore";
import React, { Component, useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  Platform,
  Image,
  Dimensions,
  Modal,
  ActivityIndicator,
} from "react-native";
import { Squad, TeamRegistrations } from "../../../models";
import AdminLayout from "../../../components/include/AdminLayout";
import {
  CommonHeading,
  H4,
  Label,
} from "../../../components/reusable/TextComponent";
import { Pressable } from "react-native";
import { useNavigation } from "@react-navigation/native";
import TeamsMatches from "./TeamsPlayers";
import TeamsPlayers from "./TeamsPlayers";
import { colors } from "../../../components/reusable/colour";
const screenWidth = Dimensions.get("window").width;

// create a component
const Teams = ({ tournamentId }) => {
  const homeLogo: any = require("../../../../assets/home/logo.png");
  const defaultLogo: any = require("../../../../assets/default/defaultLogo.png");
  const navigation: any = useNavigation();
  const [teamsData, setTeamsData] = useState([]);
  const [selectedTeamID, setSelectedTeamID] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSelectTeam = (teamId: any) => {
    if (teamId) {
      // If the same match is clicked again, close the details
      console.log("teamId", teamId);
      setSelectedTeamID(teamId);
      setShowDetails(true);
    } else {
      // Otherwise, open details for the selected match
      setSelectedTeamID(null);
      setShowDetails(false);
    }
  };

  // const handleDataFromTeamsPlayer = (data) => {
  //   console.log("Data received from child:", data);
  //   setShowDetails(data); // Update showDetails state directly
  // };

  // Callback function to receive data from the child
  const handleDataFromTeamsPlayer = (data: any) => {
    console.log("Data received from child:", data);
    // console.log("onDataReceived:", onDataReceived);
    setShowDetails(data);
  };

  useEffect(() => {
    try {
      setLoading(true);
      const subscription = DataStore.observeQuery(TeamRegistrations, (c) =>
        c.Tournament.eq(tournamentId)
      ).subscribe(async (snapshot) => {
        const teamsData = snapshot.items;
        console.log("teamsData", teamsData);
        setTeamsData(teamsData);
        setLoading(false);
      });

      return () => {
        subscription.unsubscribe();
      };
    } catch (error) {
      setLoading(false);
      console.log("error ocuured whilte fetching the data", error);
    }
  }, [tournamentId, selectedTeamID]);
  console.log("tournamentId", tournamentId);
  return (
    <View style={styles.TabContentContainer}>
      <ScrollView>
        {/* <CommonHeading props={"My Teams"} /> */}
        {!showDetails ? (
          <View
            style={{
              flexDirection:
                Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
              gap: 25,
              flexWrap: "wrap",
              padding: 5,
            }}
          >
            {teamsData && teamsData.length > 0 ? (
              teamsData.map((data: any, index: any) => (
                <View style={styles.myTeamItemContainer} key={index}>
                  <Pressable
                    style={styles.myTeamItem}
                    onPress={() => {
                      handleSelectTeam(data.id);
                    }}
                  >
                    <Image
                      source={{
                        uri:
                          data.Logo && data.Logo !== null
                            ? data.Logo
                            : defaultLogo,
                      }}
                      style={styles.scoreTeamLogo}
                      resizeMode="contain"
                    />
                    <H4
                      props={data.TeamName ? data.TeamName : null}
                      style={styles.myTeamItemContent}
                    />
                  </Pressable>
                </View>
              ))
            ) : (
              <View style={{ alignItems: "center", width: "100%" }}>
                <Image
                  source={require("../../../../assets/noData/teams.png")}
                  style={styles.noDataImage}
                  resizeMode="contain"
                />
                <Text style={styles.noDataText}>
                  Hang on! Teams not created yet.
                </Text>
              </View>
            )}
          </View>
        ) : (
          selectedTeamID &&
          showDetails && (
            <TeamsPlayers
              selectedTeamId={selectedTeamID}
              onDataReceived={handleDataFromTeamsPlayer}
            />
          )
        )}

        {/* loader */}

        {loading ? (
          <Modal transparent animationType="none">
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <ActivityIndicator size="large" color="#0000ff" />
              <Text style={{ color: "white" }}>Loading</Text>
            </View>
          </Modal>
        ) : null}
      </ScrollView>
    </View>
  );
};

// define your styles
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    width: "100%",
    flexDirection: "column",
    gap: 10,
  },
  TabContentContainer: {
    width: "100%",
    flexDirection: "column",
    gap: 10,
  },
  myTeamItemContainer: {
    minWidth: Platform.OS === "web" && screenWidth > 760 ? "40%" : "100%",
    maxWidth: Platform.OS === "web" && screenWidth > 760 ? "50%" : "100%",
    flex: 1,
    flexDirection: "row",
    backgroundColor: colors.royalBlue,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    paddingVertical: 8,
    borderRadius: 10,
  },
  myTeamItem: {
    flexDirection: "row",
    gap: 7,
    alignItems: "center",
    flex: 1,
    // borderRadius: 7,
    borderTopLeftRadius: 7,
    borderBottomLeftRadius: 7,
    padding: 5,
    paddingHorizontal: 5,
    paddingVertical: 5,
    paddingRight: 25,
  },
  myTeamItemContent: {
    // flexDirection: "column",
    flex: 1,
    // gap: 5,
    color: colors.white,
  },
  scoreTeamLogo: {
    width: 45,
    height: 45,
    resizeMode: "contain",
    borderRadius: 40,
  },
  noDataImage: {
    width: 270,
    height: 270,
  },
  noDataText: {
    fontFamily: "PlusJakartaSansSemiBold",
    color: colors.white,
    fontSize: 20,
    maxWidth: 400,
    textAlign: "center",
  },
});

//make this component available to the app
export default Teams;
