import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  Dimensions,
  Pressable,
  Platform,
  ImageBackground,
} from "react-native";
import { colors } from "../../../../components/reusable/colour";
import Checkmark from "react-native-vector-icons/Feather";
import Next from "react-native-vector-icons/Feather";
import { useNavigation } from "@react-navigation/native";
import { getAuthenticatedUser } from "../../../../api/AmplifyUserAPI";
import { DataStore } from "aws-amplify/datastore";
import { UserMaster } from "../../../../models";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { H2 } from "../../../../components/reusable/TextComponent";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

const { height: vh } = Dimensions.get("window");

const names = ["ORGANISING", "SCHEDULING", "SCORING", "PROMOTING"];

const HeroSection = () => {
  const navigation: any = useNavigation();
  const [nameIndex, setNameIndex] = useState(0);
  const [hoverIn, setHoverIn] = useState(false);

  const handleHoverIn = () => {
    setHoverIn(true);
  };
  const handleHoverOut = () => {
    setHoverIn(false);
  };

  const handleCreateTournament = async () => {
    let Response = await getAuthenticatedUser();
    if (Response && Object.keys(Response).length > 0) {
      console.log("ResponseFinal", Response);
      if (Response.isLoggedIn == true) {
        console.log("isLoginTrue", Response.isLoggedIn);

        const id = Response.attributes.sub;
        const userdata = await DataStore.query(UserMaster, id);
        // if (userdata.Name !== null) {
        console.log("userdata", userdata);
        // await AsyncStorage.setItem("Username", userdata.Name);
        try {
          await AsyncStorage.setItem("UserDetail", userdata.UUID);
        } catch (error) {
          console.log("Error in catch userdetails : ", error);
        }
        // }
        navigation.navigate("CreateTournament");
      } else {
        let sendData = {
          prevPage: "",
          nextPage: "",
          userId: "",
        };
        navigation.navigate("SignIn", {
          data: sendData,
        });
      }
    } else {
      let sendData = {
        prevPage: "",
        nextPage: "",
        userId: "",
      };
      navigation.navigate("SignIn", {
        data: sendData,
      });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setNameIndex((prevIndex) => (prevIndex + 1) % names.length);
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <View>
      {Platform.OS == "web" ? (
        <View
          style={{
            paddingBottom: 50,
            position: "relative",
            backgroundColor: colors.bgColor,
          }}
        >
          <ImageBackground
            source={require("../../../../../assets/background/backgroundTmt.png")}
            style={styles.heroSectionBG}
          >
            <View style={styles.heroSection}>
              <View style={styles.container}>
                <View style={styles.heroContentWrapper}>
                  <View style={styles.heroTaglinesWrapper}>
                    <Text style={styles.headingOne}>Easy Way To</Text>
                    <Text style={styles.headingSlider}>{names[nameIndex]}</Text>
                    <Text style={styles.headingTwo}>Tournaments</Text>
                  </View>
                  <Text style={styles.heroTextDescription}>
                    A comprehensive platform for all your Tournament Management
                    needs!
                  </Text>
                  <View style={styles.checkmarkTextContainer}>
                    <View style={styles.checkmarkTextWrapper}>
                      <Checkmark
                        name="check-square"
                        size={20}
                        color={colors.orange}
                      />
                      <Text style={styles.checkmarkText}>
                        Real-time Tracking
                      </Text>
                    </View>
                    <View style={styles.checkmarkTextWrapper}>
                      <Checkmark
                        name="check-square"
                        size={20}
                        color={colors.orange}
                      />
                      <Text style={styles.checkmarkText}>Management</Text>
                    </View>
                    <View style={styles.checkmarkTextWrapper}>
                      <Checkmark
                        name="check-square"
                        size={20}
                        color={colors.orange}
                      />
                      <Text style={styles.checkmarkText}>
                        Effortless Creation
                      </Text>
                    </View>
                  </View>
                  <View style={styles.createTournamentBtnContainer}>
                    <Pressable
                      style={[
                        styles.loginBtn,
                        {
                          backgroundColor:
                            hoverIn === true ? colors.orange : colors.aqua,
                          borderColor:
                            hoverIn === true ? colors.orange : colors.aqua,
                        },
                      ]}
                      onPress={() => handleCreateTournament()}
                      onHoverIn={handleHoverIn}
                      onHoverOut={handleHoverOut}
                    >
                      <Text
                        style={[
                          styles.loginBtnText,
                          {
                            color:
                              hoverIn === true
                                ? colors.white
                                : colors.royalBlue,
                          },
                        ]}
                      >
                        Create Tournament for Free
                      </Text>
                    </Pressable>
                  </View>
                </View>
              </View>
              {Platform.OS === "web" && screenWidth > 760 ? (
                <View style={styles.heriFeaturesButtonWrapper}>
                  <View style={styles.heroFeaturesButton}>
                    <Text style={styles.heroSectionFeaturesText}>
                      Explore Our Tournament Tracking Features!
                    </Text>
                    <Pressable
                      onPress={() => {
                        navigation.navigate("Features");
                      }}
                    >
                      <Next
                        name="arrow-right-circle"
                        size={30}
                        color={colors.white}
                      />
                    </Pressable>
                  </View>
                </View>
              ) : null}
            </View>
          </ImageBackground>

          {Platform.OS === "web" && screenWidth > 760 ? (
            <Image
              source={require("../../../../../assets/home/heroSection/footballTmt.png")}
              style={styles.heroRightImage}
            />
          ) : null}
        </View>
      ) : (
        <View style={styles.NUDtopCard}>
          <View style={styles.NUDtopContentWrapper}>
            <H2
              props={"Welcome to TMT"}
              style={{ color: colors.royalBlueColour }}
            />
            <Text style={styles.NUDtopCradText}>
              Where you can effortlessly monitor and manage your events with our
              user-friendly dashboard!
            </Text>
          </View>
          <Image
            source={require("../../../../../assets/Admin/dashboard/new-user-dashboard.png")}
            style={styles.dashboardTopIcon}
            resizeMode="contain"
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  heroSectionBG: {
    width: "100%",
    backgroundColor: colors.bgColor,
    // paddingTop: Platform.OS === "web" ? 100 : 40,
    minHeight: Platform.OS === "web" && screenWidth > 760 ? 0.9 * vh : 0.7 * vh,
  },
  container: {
    maxWidth: 1320,
    width: "100%",
    height: "100%",
    marginHorizontal: "auto",
    paddingRight: Platform.OS === "web" && screenWidth > 760 ? 50 : 15,
    paddingLeft: Platform.OS === "web" && screenWidth > 760 ? 50 : 15,
  },
  heroSection: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  heriFeaturesButtonWrapper: {
    justifyContent: "center",
    alignItems: "flex-start",
    marginTop: -25,
  },
  heroFeaturesButton: {
    backgroundColor: colors.orangeColour,
    paddingLeft: Platform.OS === "web" && screenWidth > 760 ? 50 : 15,
    paddingRight: 20,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    height: 50,
    gap: 10,
    borderBottomRightRadius: 10,
    borderTopRightRadius: 10,
  },
  heroSectionFeaturesText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
    fontSize: Platform.OS === "android" || Platform.OS === "ios" ? 16 : 18,
  },
  heroContentWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  heroTaglinesWrapper: {
    width: "100%",
    flexDirection: "column",
    gap: 5,
    alignItems: screenWidth > 760 ? undefined : "center",
  },
  headingOne: {
    color: colors.white,
    textTransform: "uppercase",
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 26 : 28,
  },
  headingSlider: {
    textTransform: "uppercase",
    color: colors.orangeColour,
    fontFamily: "PlusJakartaSansBold",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 50 : 40,
  },
  headingTwo: {
    textTransform: "uppercase",
    color: colors.white,
    fontFamily: "PlusJakartaSansBold",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 30 : 26,
  },
  heroTextDescription: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 22 : 16,
    maxWidth: screenWidth > 760 ? "70%" : "100%",
    marginTop: 20,
    paddingHorizontal: screenWidth > 760 ? undefined : "5%",
    textAlign: screenWidth > 760 ? undefined : "center",
  },
  checkmarkTextContainer: {
    flexDirection:
      Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
    gap: 10,
    marginTop: 15,
    width: "100%",
    alignItems: "center",
  },
  checkmarkTextWrapper: {
    flexDirection: "row",
    gap: 5,
  },
  checkmarkText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 16,
    alignItems: "center",
  },
  createTournamentBtnContainer: {
    marginTop: 25,
    width: "100%",
    alignItems: screenWidth > 760 ? undefined : "center",
  },
  loginBtn: {
    backgroundColor: colors.aqua,
    borderColor: colors.aqua,
    borderWidth: 1,
    padding: 5,
    borderRadius: 10,
    fontSize: 14,
    width: 280,
    height: 44,
    justifyContent: "center",
    alignItems: "center",
  },
  loginBtnText: {
    color: colors.royalBlueColour,
    textAlign: "center",
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 17,
  },
  heroRightImage: {
    width: Platform.OS === "web" && screenWidth > 760 ? "35%" : "88%",
    height: "100%",
    resizeMode: "contain",
    position: "absolute",
    right: Platform.OS === "web" && screenWidth > 760 ? 0 : -140,
    bottom: 0,
    zIndex: 9999,
  },

  NUDtopCard: {
    backgroundColor: "#E3EBFF",
    width: "100%",
    height: 150,
    flexDirection: "row",
    justifyContent: "space-between",

    borderRadius: 10,
    alignItems: "center",
  },
  NUDtopContentWrapper: {
    flex: 1,
    padding: 20,
  },
  NUDtopCradText: {
    fontFamily: "PlusJakartaSansRegular",
    marginTop: 10,
  },
  dashboardTopIcon: {
    height: 150,
    width: 130,
    resizeMode: "contain",
    marginTop: 0,
  },
});

export default HeroSection;
