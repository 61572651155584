import {
  useNavigation,
  useNavigationState,
  useRoute,
} from "@react-navigation/native";
import React, { useEffect, useState, useContext } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  Dimensions,
  Pressable,
  Modal,
  ActivityIndicator,
  Linking,
} from "react-native";
import { H2, H3 } from "../../../components/reusable/TextComponent";
import { colors } from "../../../components/reusable/colour";
import { DataStore } from "aws-amplify/datastore";
import { Squad, Tournament } from "../../../models";
import AsyncStorage from "@react-native-async-storage/async-storage";
import CalanderIcon from "react-native-vector-icons/Feather";
import LocationIcon from "react-native-vector-icons/Entypo";
import PdfIcon from "react-native-vector-icons/AntDesign";
import DownloadIcon from "react-native-vector-icons/Feather";
// import MapView, { Marker } from "react-native-maps";
// import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Layout from "../../../components/include/Layout";
import { ErrorModal } from "../../../components/reusable/AreYouSure";
import { formatDateShort } from "../../../components/FormattedDate";
import { AuthenticationContext } from "../../../authentication/Authentication";
import ZoomableImage from "../../../components/reusable/ZoomableImage";

const screenWidth = Dimensions.get("window").width;

const UpcomingTournamentDetails = () => {
  const route = useRoute();
  const { tournamentId }: any = route.params || {};
  console.log("tournamentId", tournamentId);
  const navigation: any = useNavigation();

  const [uuid, setUUID] = useState("");
  const { isLoggedIn } = useContext(AuthenticationContext);
  const [upcomingTournamentsData, setUpcomingTournamentsData] = useState([]);

  const [hoverIn, setHoverIn] = useState(false);
  const [hoverIn2, setHoverIn2] = useState(false);
  const [hoverIn3, setHoverIn3] = useState(false);
  const [squadData, setSquadData] = useState([]);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorModalMesg, setErrorModalMesg] = useState("");
  const [loading, setLoading] = useState(false);

  const staticMapUrl = (Latitude: string, Longitude: string) => {
    let mapURL = `https://maps.googleapis.com/maps/api/staticmap?center=${Latitude},${Longitude}&zoom=15&size=600x300&maptype=roadmap&markers=color:red%7Clabel:S%7C${Latitude},${Longitude}&key=AIzaSyBAz20ZT4-ncCs47i92sSAVmDqhCYpB1hY`;
    console.log("mapURL", mapURL);
    return mapURL;
  };

  let userUUID: any;
  let userDetails: any;

  const handleHoverIn = () => {
    setHoverIn(true);
  };
  const handleHoverOut = () => {
    setHoverIn(false);
  };
  const handleHoverIn2 = () => {
    setHoverIn2(true);
  };
  const handleHoverOut2 = () => {
    setHoverIn2(false);
  };
  const handleHoverIn3 = () => {
    setHoverIn3(true);
  };
  const handleHoverOut3 = () => {
    setHoverIn3(false);
  };

  const handlePress = (Latitude: string, Longitude: string) => {
    const url = `https://www.google.com/maps/search/?api=1&query=${Latitude},${Longitude}`;
    console.log("url", url);
    Linking.openURL(url);
  };

  useEffect(() => {
    console.log("tournamentID from routes", tournamentId);
    console.log("errorModalVisible", errorModalVisible);

    const getUserdata = async () => {
      userDetails = await AsyncStorage.getItem("UserDetail");
      console.log("userDetails", userDetails);
      if (userDetails) {
        console.log("userDetails", userDetails);
        setUUID(userDetails);
      } else {
        console.log("No user found in temp storage");
      }
    };
    getUserdata();
    handleAsyncPropsfromTournamentCard();
  }, [errorModalVisible, tournamentId]);

  const handleOk = () => {
    setErrorModalVisible(false);
  };

  const handleParticipateNow = async () => {
    console.log("userSignedIn", isLoggedIn);
    if (isLoggedIn) {
      let squadIdsArr = [];
      // let tournament_Id = tournamentId;
      const pageData = await AsyncStorage.setItem(
        "tournamentIdFromUpcomingTournament",
        tournamentId
      );
      console.log("pageData", pageData);

      if (upcomingTournamentsData[0].ExternalRegistration === true) {
        console.log("Teams count outside : ", squadIdsArr.length);
        setErrorModalVisible(false);
        if (squadData.length > 0) {
          navigation.navigate("SelectTeam", {
            tournamentId: tournamentId,
          });
        } else {
          console.log("In else");
          navigation.navigate("TeamParticipationForm", {
            tournamentId: tournamentId,
            // userId: "",
          });
        }
      } else {
        setErrorModalVisible(true);
        setErrorModalMesg(
          "This tournament does not permit external participation. Kindly contact the tournament administrator for further assistance."
        );
      }

      // });
    } else {
      const sendData = {
        prevPage: window.location.pathname,
        nextPage: "TeamParticipationForm",
        tournamentId: tournamentId,
        userId: "",
      };
      const pageData = await AsyncStorage.setItem(
        "tournamentIdFromUpcomingTournament",
        tournamentId
      );
      console.log("pageData", pageData);
      navigation.navigate("SignIn", { data: sendData });
    }
  };

  const handleAsyncPropsfromTournamentCard = async () => {
    try {
      setLoading(true);
      const upcomingTournamentDetailsProps = await AsyncStorage.getItem(
        "upcomingTournamentProps"
      );
      const upcomingTournamentDetails = JSON.parse(
        upcomingTournamentDetailsProps
      );
      console.log("upcomingTournamentDetails", upcomingTournamentDetails);

      const subscription = DataStore.observeQuery(Tournament, (c) =>
        c.id.eq(tournamentId)
      ).subscribe(async (snapshot) => {
        const upcomingTournament = snapshot.items;
        console.log("upcomingTournament", upcomingTournament);
        setUpcomingTournamentsData(upcomingTournament);

        // const noOfTeamsParticipation = upcomingTournament.map(
        //   async (data: any) => data.Registrations.values
        // );
        // const promiseTeamData = await Promise.all(noOfTeamsParticipation);
        // const flatTeamData = promiseTeamData.flat();
        // console.log("flatTeamData", flatTeamData);
        // console.log("flatTeamData", flatTeamData.length);
        // setNoOfTeamsParticipation(flatTeamData.length);

        // const noOfPlayersInTeam = flatTeamData.map(
        //   async (data) => data.Players.values
        // );
        // const promisePlayersData = await Promise.all(noOfPlayersInTeam);
        // const flatPlayersData = promisePlayersData.flat();
        // console.log("flatPlayersData", flatPlayersData);
        // console.log("flatPlayersData", flatPlayersData.length);
        // setNoOfPlayersInTeam(flatPlayersData.length);
      });

      const myTeamsSubscription = DataStore.observeQuery(Squad).subscribe(
        async (snapshot) => {
          const initialSquads = snapshot.items;
          console.log("initialSquads", initialSquads);

          userUUID = userDetails;
          console.log("userUUID", userUUID);

          const filterSquads = initialSquads.filter(
            (data) =>
              data.Admin1?.UUID === userUUID || data.Admin2?.UUID === userUUID
          );

          console.log("filterSquads", filterSquads);
          setSquadData(filterSquads);
          setLoading(false);
        }
      );
      return () => {
        subscription.unsubscribe();
        myTeamsSubscription.unsubscribe();
      };
    } catch (error) {
      setLoading(false);
      console.log("error occured while fetching the data", error);
    }
  };

  const handleDownloadPDF = (url) => {
    // Specify the URL of the PDF file you want to download
    // const pdfUrl = 'https://example.com/path/to/your/pdf/file.pdf';

    // Use Linking to open the PDF URL, this should prompt the user to download the PDF
    Linking.openURL(url);
  };

  return (
    // <Layout>
    <View>
      {/* {Platform.OS === "web" ? <Navbar /> : null} */}
      <View style={styles.UTDMainContainer}>
        {upcomingTournamentsData && upcomingTournamentsData.length > 0 ? (
          upcomingTournamentsData.map((data, index) => (
            <>
              <View style={styles.container} key={index}>
                <View style={styles.boxContainer}>
                  <Image
                    source={{ uri: data ? data.Banner : null }}
                    style={styles.bannerImage}
                  />
                </View>

                <View style={styles.UTDTopProfileContainer}>
                  <View style={styles.UTDProfileWrapper}>
                    {/* <Image
                      source={data ? data.Logo : null}
                      style={styles.profileImage}
                    /> */}
                    <ZoomableImage
                      imageSource={{ uri: data.Logo }}
                      imageStyle={styles.profileImage}
                      modalStyle={undefined}
                    />
                  </View>
                  <View style={styles.UTDProfileContentWrapper}>
                    <View style={styles.UTDProfileContentLeft}>
                      <H2
                        props={data ? data.Name : "N.A"}
                        style={styles.tournamentName}
                      />
                      <View
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <H3
                          props={"Organised By -"}
                          style={styles.orgHeaderText}
                        />
                        <H3
                          props={data ? data.Organizer : "N.A"}
                          style={styles.orgHeaderText}
                        />
                      </View>
                      <View style={styles.UTDDateWrapper}>
                        <CalanderIcon
                          name="calendar"
                          size={
                            Platform.OS === "web" && screenWidth > 760 ? 20 : 12
                          }
                          color={colors.white}
                        />
                        <Text style={styles.UTDDateText}>
                          {data ? formatDateShort(data.StartDate) : null} &nbsp;
                          - &nbsp;
                          {data ? formatDateShort(data.EndDate) : null}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.UTDProfileContentRight}>
                      <Pressable
                        style={
                          hoverIn === true
                            ? styles.participatedButtonWrapperHover
                            : styles.participatedButtonWrapper
                        }
                        onPress={handleParticipateNow}
                        onHoverIn={handleHoverIn}
                        onHoverOut={handleHoverOut}
                      >
                        <Text
                          style={[
                            styles.participatedButtonText,
                            {
                              fontSize: 14,
                              color:
                                hoverIn === true ? colors.white : "#222222",
                            },
                          ]}
                        >
                          Participate Now
                        </Text>
                      </Pressable>
                    </View>
                  </View>
                </View>

                <View style={styles.UTDSecondWrapper}>
                  <View style={styles.UTDSRight}>
                    {data && data.Description && (
                      <View style={styles.UTDSRightItem}>
                        <View style={{ marginBottom: 15 }}>
                          <Text style={styles.elementSubTitleName}>
                            Description
                          </Text>
                          <Text
                            style={[styles.descriptionText, { marginTop: 5 }]}
                          >
                            {data ? data.Description : null}
                          </Text>
                        </View>
                      </View>
                    )}
                    <View style={styles.UTDSRightItem}>
                      <Text
                        style={[
                          styles.elementSubTitleName,
                          { marginBottom: 10 },
                        ]}
                      >
                        Prizes
                      </Text>
                      {data.Prizes &&
                        data.Prizes.length > 0 &&
                        data.Prizes.map((prize, index) => (
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              gap: 7,
                              marginBottom: 7,
                            }}
                            key={index}
                          >
                            <View
                              style={{
                                width: 6,
                                height: 6,
                                borderRadius: 6,
                                backgroundColor: colors.white,
                              }}
                            ></View>
                            <Text
                              style={[
                                styles.elementSubTitleName,
                                { fontSize: 16 },
                              ]}
                            >
                              {/* Singles Winner - ₹1000 */}
                              {`${prize.Name} - ${prize.Description}`}
                            </Text>
                          </View>
                        ))}
                    </View>
                    <View style={styles.UTDSRightItem}>
                      <Text style={styles.elementSubTitleName}>
                        Tournament Details
                      </Text>
                      <View style={styles.UTDSRightContainer}>
                        <View style={styles.UTDTextContainer}>
                          <Text style={styles.UTDSmallText}>Age Category</Text>
                          <Text style={styles.UTDBigText}>
                            {data ? data.AgeGroup : null}
                          </Text>
                        </View>
                        <View style={styles.UTDTextContainer}>
                          <Text style={styles.UTDSmallText}>
                            Tournament Type
                          </Text>
                          <Text style={styles.UTDBigText}>
                            {data ? data.Type : null}
                          </Text>
                        </View>
                        <View style={styles.UTDTextContainer}>
                          <Text style={styles.UTDSmallText}>Playing</Text>
                          <Text style={styles.UTDBigText}>
                            {data ? data.Playing : null}
                          </Text>
                        </View>
                        <View style={styles.UTDTextContainer}>
                          <Text style={styles.UTDSmallText}>Substitute</Text>
                          <Text style={styles.UTDBigText}>
                            {data ? data.Substitute : null}
                          </Text>
                        </View>
                        <View style={styles.UTDTextContainer}>
                          <Text style={styles.UTDSmallText}>
                            No. of players in a team
                          </Text>
                          <Text style={styles.UTDBigText}>
                            {data.MaxPlayersInSquad
                              ? data.MaxPlayersInSquad
                              : null}
                          </Text>
                        </View>
                        <View style={styles.UTDTextContainer}>
                          <Text style={styles.UTDSmallText}>
                            No. of teams participation
                          </Text>
                          <Text style={styles.UTDBigText}>
                            {data.NoOfTeamsMax ? data.NoOfTeamsMax : null}
                          </Text>
                        </View>
                      </View>
                      <Text style={styles.UTDSmallText}>
                        Rules & Regulations
                      </Text>
                      <View style={styles.pdfButtonContainer}>
                        <Pressable
                          style={styles.pdfDownloadButton}
                          onPress={() => handleDownloadPDF(data.RulesDocument)}
                        >
                          <PdfIcon name="pdffile1" size={22} color="red" />
                          <Text style={styles.downloadButtontext}>
                            Rules & Regulations
                          </Text>
                          <DownloadIcon
                            name="download"
                            size={25}
                            color={colors.white}
                          />
                        </Pressable>
                      </View>
                      <View style={{ alignItems: "center", marginTop: 10 }}>
                        <Pressable
                          style={[
                            hoverIn2 === true
                              ? styles.participatedButtonWrapperHover
                              : styles.participatedButtonWrapper,
                            { height: 35 },
                          ]}
                          onPress={handleParticipateNow}
                          onHoverIn={handleHoverIn2}
                          onHoverOut={handleHoverOut2}
                        >
                          <Text
                            style={[
                              styles.participatedButtonText,
                              {
                                fontSize: 16,
                                color: hoverIn2 === true ? "#fff" : "#222222",
                              },
                            ]}
                          >
                            Participate Now
                          </Text>
                        </Pressable>
                      </View>
                    </View>
                  </View>
                  <View style={styles.UTDSLeft}>
                    <View style={styles.UTDSLeft1}>
                      <View style={{ gap: 7 }}>
                        <Text style={styles.elementTitleName}>
                          Registration End Date
                        </Text>
                        <Text style={styles.elementSubTitleName}>
                          {data
                            ? formatDateShort(data.RegistrationEndDate)
                            : null}
                        </Text>
                      </View>
                      <View style={{ gap: 7 }}>
                        <Text style={styles.elementTitleName}>
                          Registration Fees
                        </Text>
                        <Text style={styles.elementSubTitleName}>
                          {data ? data.Fees : null}
                        </Text>
                      </View>
                    </View>
                    {/* map start here */}
                    <View style={styles.mapContainer}>
                      <Text style={styles.elementTitleName}>Location</Text>
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 5,
                        }}
                      >
                        <LocationIcon
                          name="location-pin"
                          size={
                            Platform.OS === "web" && screenWidth > 760 ? 20 : 12
                          }
                          color={colors.white}
                        />
                        <Text style={styles.UTDSmallText}>
                          {data.Area}, {data.City}, {data.State}, {data.Country}
                        </Text>
                      </View>

                      {/* Venue mapping */}

                      {data.Venues && data.Venues.length > 0
                        ? data.Venues.map((ele, index) => {
                            return (
                              <View key={index}>
                                <Pressable
                                  onPress={() =>
                                    handlePress(
                                      ele.Coordinates?.Lattitude,
                                      ele.Coordinates?.Longitude
                                    )
                                  }
                                >
                                  <Image
                                    source={{
                                      uri: staticMapUrl(
                                        ele.Coordinates?.Lattitude,
                                        ele.Coordinates?.Longitude
                                      ),
                                    }}
                                    style={styles.mapThumbnail}
                                  />
                                </Pressable>
                              </View>
                            );
                          })
                        : null}
                    </View>
                    {/* map ended here */}
                    <View style={styles.UTDSLeft2}>
                      <Text style={styles.elementSubTitleName}>Organiser</Text>
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 10,
                        }}
                      >
                        <Image
                          source={{ uri: data ? data.Logo : null }}
                          resizeMode="cover"
                          style={styles.orgLogo}
                        />
                        <Text style={styles.organizerName}>
                          {data ? data.Organizer : null}
                        </Text>
                      </View>
                      <Pressable
                        style={[
                          styles.orgContactBtn,
                          {
                            backgroundColor: hoverIn3
                              ? colors.orange
                              : "transparent",
                          },
                        ]}
                        onHoverIn={handleHoverIn3}
                        onHoverOut={handleHoverOut3}
                      >
                        <Text
                          style={[
                            styles.orgContactBtnText,
                            { color: hoverIn3 ? "#fff" : colors.orange },
                          ]}
                        >
                          Contact Organiser
                        </Text>
                      </Pressable>
                    </View>
                  </View>
                </View>
              </View>
            </>
          ))
        ) : (
          <Text>No data available</Text>
        )}

        {/* error modal */}

        {errorModalVisible ? (
          <ErrorModal
            visibleModal={errorModalVisible}
            SuccessMessage={errorModalMesg}
            onSubmit={handleOk}
            header={"Attention"}
          />
        ) : null}

        {/* loader */}

        {loading ? (
          <Modal transparent animationType="none">
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <ActivityIndicator size="large" color="#0000ff" />
              <Text>Loading</Text>
            </View>
          </Modal>
        ) : null}
      </View>
    </View>
    // </Layout>
  );
};

const styles = StyleSheet.create({
  UTDMainContainer: {
    width: "100%",
    paddingBottom: 20,
  },
  container: {
    paddingTop: 10,
    maxWidth: 1320,
    width: "100%",
    marginHorizontal: "auto",
    paddingRight: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
    paddingLeft: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
  },
  boxContainer: {
    width: "100%",
    borderRadius: 10,
    borderWidth: 1,
    backgroundColor: colors.royalBlue,
    borderColor: colors.royalBlue,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
  },
  bannerImage: {
    width: "100%",
    height: Platform.OS === "web" && screenWidth > 760 ? 355 : 140,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.royalBlue,
    resizeMode: "contain",
  },
  UTDTopProfileContainer: {
    width: "100%",
    flexDirection: "row",
    gap: 20,
    padding: 10,
    shadowColor: "#000",
    marginTop: 15,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    borderRadius: 10,
    backgroundColor: colors.royalBlue,
  },
  UTDProfileWrapper: {
    width: Platform.OS === "web" && screenWidth > 760 ? 120 : 80,
    height: Platform.OS === "web" && screenWidth > 760 ? 120 : 80,
    borderRadius: 60,
    backgroundColor: colors.newBlue,
    borderColor: colors.royalBlueColour,
    borderWidth: 2,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
  },
  profileImage: {
    width: Platform.OS === "web" && screenWidth > 760 ? 120 : 80,
    height: Platform.OS === "web" && screenWidth > 760 ? 120 : 80,
    borderRadius: 60,
  },
  UTDProfileContentWrapper: {
    flex: 1,
    flexDirection:
      Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
    gap: Platform.OS === "web" && screenWidth > 760 ? 50 : 0,
    paddingTop: Platform.OS === "web" && screenWidth > 760 ? 15 : 0,
  },
  UTDProfileContentLeft: {
    flex: 1,
    gap: Platform.OS === "web" && screenWidth > 760 ? 8 : 5,
  },
  UTDProfileContentRight: {
    width: 180,
  },
  tournamentName: {
    color: colors.white,
    fontSize: Platform.OS === "web" && screenWidth > 760 ? undefined : 16,
    // textTransform: "uppercase",
    fontFamily: "'PlusJakartaSansMedium'",
  },
  UTDDateWrapper: {
    flexDirection: "row",
    gap: 5,
    alignItems: "center",
    marginTop: 7,
  },
  UTDDateText: {
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 18 : 13,
    color: colors.white,
    fontFamily: "'PlusJakartaSansRegular'",
  },
  UTDCashPrizeWrapper: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 7,
  },
  UTDCashPrize: {
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 20,
  },
  participatedButtonWrapper: {
    backgroundColor: colors.aqua,
    borderColor: colors.aqua,
    borderWidth: 1,
    padding: 5,
    borderRadius: 10,
    fontSize: 14,
    width: "100%",
    maxWidth: 380,
    height: 35,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15,
  },
  participatedButtonWrapperHover: {
    backgroundColor: colors.orangeColour,
    borderColor: colors.orangeColour,
    color: colors.white,
    borderWidth: 1,
    padding: 5,
    borderRadius: 10,
    fontSize: 14,
    width: "100%",
    maxWidth: 380,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15,
  },
  participatedButtonText: {
    color: "#222",
    textAlign: "center",
    fontFamily: "PlusJakartaSansRegular",
  },
  UTDSecondWrapper: {
    flexDirection:
      Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
    gap: 15,
    marginTop: 15,
  },
  UTDSLeft: {
    flex: Platform.OS === "web" && screenWidth > 760 ? 1 : undefined,
    width: Platform.OS === "web" && screenWidth > 760 ? undefined : "100%",
    gap: 15,
    maxWidth: 400,
  },
  UTDSLeft1: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    padding: 10,
    backgroundColor: colors.royalBlue,
    borderRadius: 10,
    gap: 15,
    width: "100%",
  },
  UTDSLeft2: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    padding: 10,
    backgroundColor: colors.royalBlue,
    borderRadius: 10,
    gap: 17,
    width: "100%",
  },
  UTDSRight: {
    flex: Platform.OS === "web" && screenWidth > 760 ? 1 : undefined,
    width: Platform.OS === "web" && screenWidth > 760 ? undefined : "100%",
    gap: 10,
  },
  UTDSRightItem: {
    width: "100%",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    padding: 10,
    backgroundColor: colors.royalBlue,
    borderRadius: 10,
  },
  elementTitleName: {
    color: colors.white,
    fontFamily: "PlusJakartaSansRegular",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 16 : 14,
  },
  elementSubTitleName: {
    fontFamily: "PlusJakartaSansRegular",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 18 : 16,
    color: colors.white,
  },
  mapContainer: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    padding: 10,
    backgroundColor: colors.royalBlue,
    borderRadius: 10,
    gap: 15,
    width: "100%",
  },
  UTDSRightContainer: {
    flexWrap: "wrap",
    gap: 15,
    flexDirection: "row",
    marginVertical: 20,
  },
  UTDTextContainer: {
    flex: 1,
    maxWidth: "49%",
    minWidth: "40%",
    gap: 5,
  },
  UTDSmallText: {
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 14,
    color: colors.white,
  },
  UTDBigText: {
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 18,
    color: colors.white,
  },
  pdfButtonContainer: {
    marginTop: 15,
    alignItems: "flex-start",
  },
  pdfDownloadButton: {
    flexDirection: "row",
    gap: 7,
    alignItems: "center",
    borderColor: "#999",
    borderWidth: 1,
    padding: 5,
    borderRadius: 5,
    backgroundColor: "#99999926",
  },
  downloadButtontext: {
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 16,
    color: colors.white,
  },
  descriptionText: {
    fontFamily: "PlusJakartaSansExtraLight",
    fontSize: 14,
    color: colors.white,
  },
  orgLogo: {
    width: 40,
    height: 40,
    borderRadius: 40,
    resizeMode: "contain",
  },
  organizerName: {
    fontFamily: "PlusJakartaSansMedium",
    color: colors.white,
    fontSize: 22,
  },
  orgContactBtn: {
    height: 36,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: colors.orange,
    borderRadius: 10,
  },
  orgContactBtnText: {
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 16,
    textAlign: "center",
  },
  orgHeaderText: {
    color: colors.white,
    fontFamily: "'PlusJakartaSansRegular'",
    fontSize: 18,
  },
  mapThumbnail: {
    width: "100%",
    height: 200,
    marginTop: 10,
    borderRadius: 8,
  },
});

export default UpcomingTournamentDetails;
