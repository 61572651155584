import React, { useEffect, useState } from "react";
import {
  View,
  ScrollView,
  Platform,
  StyleSheet,
  Pressable,
  Text,
  FlatList,
  Image,
  Dimensions,
} from "react-native";

import AdminLayout from "../../../components/include/AdminLayout";
import Icon from "react-native-vector-icons/AntDesign";
import { H1, H4, H3, H2 } from "../../../components/reusable/TextComponent";
import { colors } from "../../../components/reusable/colour";
import Registration from "./SubComp/Registration";
import Fixture from "../Fixture/Fixture";
import Standing from "../../view/competition/Standing";
import Statistics from "../../view/competition/Statistics";
import Results from "../Results/Results";
import Gallery from "../Gallery/Gallery";

import Settings from "./SubComp/Settings";
import { DataStore } from "aws-amplify/datastore";
import { Tournament } from "../../../models";
import { useNavigation, useRoute } from "@react-navigation/native";
import AppBar from "../../../components/include/AppBar";
import Notification from "./SubComp/Notification";
import ZoomableImage from "../../../components/reusable/ZoomableImage";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;
<script src="http://192.168.0.136:8097"></script>;

const ManageTournament = () => {
  const route = useRoute();
  const { tournamentId }: any = route.params;
  const [tournamentsData, setTournamentsData]: any = useState([]);
  const [teamsData, setTeamsData] = useState([]);
  const [teamsPlayersData, setTeamsPlayersData] = useState([]);
  const [matchData, setMatchData] = useState([]);
  const [yellowCardCounts, setYellowCardCounts] = useState([]);
  const [redCardCounts, setRedCardCounts] = useState([]);
  const [cardsData, setCardsData] = useState([]);
  const [goalsData, setGoalsData] = useState([]);
  const [activeTab, setActiveTab]: any = useState<number>(0);
  const navigation: any = useNavigation();
  const matchLogo: any = "../../../../assets/logo/match_logo1.png";

  const handleTabChange = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  const tabs = [
    "Registrations",
    "Fixtures",
    "Standings",
    "Results",
    "Statistics",
    "Gallery",
    "Notifications",
    "Settings",
  ];

  const renderItem = ({ item, index }) => {
    return (
      <Pressable
        style={[
          styles.tabButton,
          activeTab === index && styles.activeTabButton,
        ]}
        onPress={() => handleTabChange(index)}
      >
        <Text
          style={[
            styles.tabButtonText,
            activeTab === index && styles.activeTabButtonText,
          ]}
        >
          {item}
        </Text>
      </Pressable>
    );
  };

  const getSelectedTournamentDetails = async () => {
    // Get the tournamentId from routes for faster rendering
    const subscription = DataStore.observeQuery(Tournament, (c) =>
      c.id.eq(tournamentId)
    ).subscribe(async (snapshot) => {
      console.log("tournaments", snapshot.items);
      const initialTournaments: any = snapshot.items;
      console.log("selectedTournament", initialTournaments);
      setTournamentsData(initialTournaments);
      let teamsDataPromises = [initialTournaments[0]?.Registrations];
      let teamsData = await teamsDataPromises[0]?.values;
      console.log("teamsData", teamsData);
      setTeamsData(teamsData);

      // playerData
      const playersData = [];
      for (const team of teamsData) {
        const playersPromise = team.Players.values;
        const players = await playersPromise;
        playersData.push(players);
      }
      console.log("playersData", playersData);
      const playersCount = playersData.flat();
      console.log("playersCount", playersCount);
      setTeamsPlayersData(playersCount);

      // matchesData
      const matchesData = [];
      for (const match of initialTournaments) {
        const matchesPromise = match.Matches.values;
        const matches = await matchesPromise;
        matchesData.push(matches);
      }
      console.log("matchesData", matchesData);
      const matchesCount = matchesData.flat();
      console.log("matchesCount", matchesCount);
      setMatchData(matchesCount);

      // goalsData
      const goalsData = [];
      for (const goals of initialTournaments) {
        const goalsPromise = goals.Goals.values;
        const goalsDataFromPromsie = await goalsPromise;
        goalsData.push(goalsDataFromPromsie);
      }
      console.log("goalsData", goalsData);
      const goalsCount = goalsData.flat();
      console.log("goalsCount", goalsCount);
      setGoalsData(goalsCount);

      // cards data
      const cardsData = [];
      for (const cards of initialTournaments) {
        const cardsPromise = cards.Cards.values;
        const cardsDataFromPromsie = await cardsPromise;
        cardsData.push(cardsDataFromPromsie);
      }
      console.log("cardsData", cardsData);
      const cardsCount = cardsData.flat();
      console.log("cardsCount", cardsCount);
      setCardsData(cardsCount);

      const cardTypeCounts = {
        Yellow: [],
        Red: [],
      };

      cardsData.forEach((innerArray, outerIndex) => {
        let yellowCount = 0;
        let redCount = 0;

        innerArray.forEach((card) => {
          // Check if CardType is "Yellow"
          if (card.CardType === "Yellow") {
            yellowCount += 1;
          }
          // Check if CardType is "Red"
          if (card.CardType === "Red") {
            redCount += 1;
          }
        });
        cardTypeCounts.Yellow[outerIndex] = yellowCount;
        cardTypeCounts.Red[outerIndex] = redCount;
      });
      console.log("Card Type Counts:", cardTypeCounts);
      setYellowCardCounts(cardTypeCounts.Yellow);
      setRedCardCounts(cardTypeCounts.Red);
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    getSelectedTournamentDetails();
  }, [tournamentId]);

  const handleBack = () => {
    navigation.goBack();
  };

  return (
    <>
      {Platform.OS !== "web" && (
        <AppBar
          // write tournament name in title
          title={"Tournament"}
          showBackButton={true}
          onBackPress={handleBack}
        />
      )}
      <AdminLayout>
        <ScrollView>
          {tournamentsData && tournamentsData.length > 0 ? (
            tournamentsData.map((tournament: any, index: number) => (
              <View
                style={{
                  padding: 5,
                }}
                key={index}
              >
                {Platform.OS == "web" && screenWidth > 760 && (
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",

                      alignItems:
                        Platform.OS == "web" && screenWidth > 760
                          ? "center"
                          : undefined,
                      gap: 10,
                    }}
                  >
                    <Pressable
                      onPress={() => {
                        navigation.navigate("Dashboard");
                      }}
                    >
                      <Icon name="left" size={22} color={colors.white} />
                    </Pressable>
                    <H4
                      props={`Tournament-${index + 1} ${tournament.Name}`}
                      style={{ color: colors.white }}
                    />
                  </View>
                )}

                {Platform.OS === "web" && screenWidth > 760 ? (
                  <View style={styles.tournamentHeaderWrapper}>
                    <View
                      style={{
                        width:
                          Platform.OS === "web" && screenWidth > 760 ? 100 : 80,
                        height:
                          Platform.OS === "web" && screenWidth > 760 ? 100 : 80,
                        borderRadius:
                          Platform.OS === "web" && screenWidth > 760 ? 100 : 80,
                        borderWidth: 2,
                        borderColor: colors.white,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* <Image
                        source={{
                          uri:
                            tournament && tournament.Logo !== null
                              ? tournament.Logo
                              : matchLogo,
                        }}
                        style={{
                          width:
                            Platform.OS === "web" && screenWidth > 760
                              ? 80
                              : 60,
                          height:
                            Platform.OS === "web" && screenWidth > 760
                              ? 80
                              : 60,
                          borderRadius: 80,
                        }}
                        resizeMode="contain"
                      /> */}
                      <ZoomableImage
                        imageSource={{
                          uri:
                            tournament && tournament.Logo !== null
                              ? tournament.Logo
                              : matchLogo,
                        }}
                        imageStyle={{
                          width:
                            Platform.OS === "web" && screenWidth > 760
                              ? 80
                              : 60,
                          height:
                            Platform.OS === "web" && screenWidth > 760
                              ? 80
                              : 60,
                          borderRadius: 80,
                          resizeMode: "contain",
                        }}
                        modalStyle={undefined}
                      />
                    </View>
                    <View style={{ flex: 1 }}>
                      <H2
                        props={tournament ? tournament.Name : null}
                        style={{ color: colors.white }}
                      />
                      <H3
                        props={tournament ? tournament.Organizer : null}
                        style={{ color: colors.white, marginTop: 5 }}
                      />
                    </View>
                  </View>
                ) : (
                  <View style={styles.tournamentHeaderWrapper1}>
                    <Image
                      source={require("../../../../assets/home/tournamentBanner.jpg")}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderTopLeftRadius: 15,
                        borderTopRightRadius: 15,
                        borderBottomLeftRadius: 15,
                        borderBottomRightRadius: 15,
                      }}
                      resizeMode="contain"
                    />
                  </View>
                )}

                {/* Card container starts here */}
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: 10,
                    paddingVertical: 10,
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <View style={styles.registrationNumberCard}>
                    <H1
                      props={teamsData && teamsData.length}
                      style={{ color: colors.white }}
                    />
                    <H4 props={"Teams"} style={{ color: colors.white }} />
                  </View>
                  <View style={styles.registrationNumberCard}>
                    <H1
                      props={teamsPlayersData && teamsPlayersData.length}
                      style={{ color: colors.white }}
                    />
                    <H4 props={"Players"} style={{ color: colors.white }} />
                  </View>
                  <View style={styles.registrationNumberCard}>
                    <H1
                      props={matchData && matchData.length}
                      style={{ color: colors.white }}
                    />
                    <H4 props={"Matches"} style={{ color: colors.white }} />
                  </View>
                  <View style={styles.registrationNumberCard}>
                    <H1
                      props={goalsData && goalsData.length}
                      style={{ color: colors.white }}
                    />
                    <H4 props={"Goals"} style={{ color: colors.white }} />
                  </View>
                  <View style={styles.registrationNumberCard}>
                    <H1 props={redCardCounts} style={{ color: colors.white }} />
                    <H4 props={"Red Cards"} style={{ color: colors.white }} />
                  </View>
                  <View style={styles.registrationNumberCard}>
                    <H1
                      props={yellowCardCounts}
                      style={{ color: colors.white }}
                    />
                    <H4
                      props={"Yellow cards"}
                      style={{ color: colors.white }}
                    />
                  </View>
                </View>
                {/* tab design start here */}
                <View style={[styles.TabMainContainer]}>
                  <ScrollView
                    horizontal={true}
                    showsVerticalScrollIndicator={false}
                    showsHorizontalScrollIndicator={true}
                    style={{
                      width: "100%",
                      marginBottom: 20,
                      backgroundColor: colors.blue,
                      borderRadius: 10,
                    }}
                  >
                    <View style={styles.tabContainer}>
                      <Pressable
                        style={[
                          styles.tabButton,
                          activeTab == 0 && styles.activeTabButton,
                        ]}
                        onPress={() => handleTabChange(0)}
                      >
                        <Text
                          style={[
                            styles.tabButtonText,
                            activeTab == 0 && styles.activeTabButtonText,
                          ]}
                        >
                          Registrations
                        </Text>
                      </Pressable>
                      <Pressable
                        style={[
                          styles.tabButton,
                          activeTab === 1 && styles.activeTabButton,
                        ]}
                        onPress={() => handleTabChange(1)}
                      >
                        <Text
                          style={[
                            styles.tabButtonText,
                            activeTab === 1 && styles.activeTabButtonText,
                          ]}
                        >
                          Fixtures
                        </Text>
                      </Pressable>
                      <Pressable
                        style={[
                          styles.tabButton,
                          activeTab === 2 && styles.activeTabButton,
                        ]}
                        onPress={() => handleTabChange(2)}
                      >
                        <Text
                          style={[
                            styles.tabButtonText,
                            activeTab === 2 && styles.activeTabButtonText,
                          ]}
                        >
                          Standings
                        </Text>
                      </Pressable>
                      <Pressable
                        style={[
                          styles.tabButton,
                          activeTab === 3 && styles.activeTabButton,
                        ]}
                        onPress={() => handleTabChange(3)}
                      >
                        <Text
                          style={[
                            styles.tabButtonText,
                            activeTab === 3 && styles.activeTabButtonText,
                          ]}
                        >
                          Results
                        </Text>
                      </Pressable>
                      <Pressable
                        style={[
                          styles.tabButton,
                          activeTab === 4 && styles.activeTabButton,
                        ]}
                        onPress={() => handleTabChange(4)}
                      >
                        <Text
                          style={[
                            styles.tabButtonText,
                            activeTab === 4 && styles.activeTabButtonText,
                          ]}
                        >
                          Statistics
                        </Text>
                      </Pressable>
                      <Pressable
                        style={[
                          styles.tabButton,
                          activeTab === 5 && styles.activeTabButton,
                        ]}
                        onPress={() => handleTabChange(5)}
                      >
                        <Text
                          style={[
                            styles.tabButtonText,
                            activeTab === 5 && styles.activeTabButtonText,
                          ]}
                        >
                          Gallery
                        </Text>
                      </Pressable>
                      <Pressable
                        style={[
                          styles.tabButton,
                          activeTab === 6 && styles.activeTabButton,
                        ]}
                        onPress={() => handleTabChange(6)}
                      >
                        <Text
                          style={[
                            styles.tabButtonText,
                            activeTab === 6 && styles.activeTabButtonText,
                          ]}
                        >
                          Notifications
                        </Text>
                      </Pressable>
                      <Pressable
                        style={[
                          styles.tabButton,
                          activeTab == 7 && styles.activeTabButton,
                        ]}
                        onPress={() => handleTabChange(7)}
                      >
                        <Text
                          style={[
                            styles.tabButtonText,
                            activeTab == 7 && styles.activeTabButtonText,
                          ]}
                        >
                          Settings
                        </Text>
                      </Pressable>
                    </View>
                  </ScrollView>

                  <View style={styles.tabContent}>
                    {activeTab == 0 && <Registration />}
                    {activeTab === 1 && <Fixture />}
                    {activeTab === 2 && (
                      <Standing tournamentId={tournament.id} />
                    )}
                    {activeTab === 3 && (
                      <Results
                        tournamentId={tournament.id}
                        onDataReceived={null}
                        matchID={null}
                        showResultFlag={undefined}
                        showDetailsForResult={undefined}
                      />
                    )}
                    {activeTab === 4 && (
                      <Statistics tournamentId={tournament.id} />
                    )}
                    {activeTab === 5 && <Gallery />}
                    {activeTab === 6 && (
                      <Notification tournamentId={tournament.id} />
                    )}
                    {activeTab == 7 && <Settings />}
                  </View>
                </View>
                {/* tab design ended here */}
              </View>
            ))
          ) : (
            <Text>No data found </Text>
          )}
        </ScrollView>
      </AdminLayout>
    </>
  );
};

const styles = StyleSheet.create({
  tournamentHeaderWrapper: {
    backgroundColor: colors.royalBlue,
    padding: 15,
    paddingTop: 20,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    position: "relative",
    display: "flex",
    flexDirection: Platform.OS == "web" && screenWidth > 760 ? "row" : "column",
    alignItems: "center",
    gap: 15,
    marginTop: 15,
  },
  tournamentHeaderWrapper1: {
    backgroundColor: colors.royalBlue,
    padding: 15,

    height: 200,
    width: "100%",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    // borderBottomStartRadius: 20,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },

  TabMainContainer: {
    marginTop: 10,
    flex: 1,
  },
  //
  tabContainer: {
    width: "100%",
    flexDirection: "row",
    backgroundColor: colors.blue,
    overflow: Platform.OS === "web" && screenWidth > 760 ? "hidden" : "scroll",
    justifyContent: "space-between",
    borderRadius: 10,
    padding: 7,
    gap: 10,
  },
  tabContent: {
    // alignItems: "center",
  },

  //
  // tabButton: {
  //   padding: 10,
  //   backgroundColor: "transparent",
  // },
  // activeTabButton: {
  //   borderBottomColor: colors.orangeColour,
  //   borderBottomWidth: 4,
  // },
  tabButton: {
    padding: Platform.OS === "web" && screenWidth > 760 ? 10 : undefined,
    backgroundColor: "transparent",
    height: Platform.OS === "web" && screenWidth > 760 ? 35 : 26,
    // flex: screenWidth > 760 ? 1 : undefined,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    paddingHorizontal: Platform.OS === "web" && screenWidth > 760 ? 15 : 7,
  },
  activeTabButton: {
    backgroundColor: colors.orange,
  },
  tabButtonText: {
    color: colors.white,
    textAlign: "center",
    fontFamily: "PlusJakartaSansRegular",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 16 : 14,
  },
  activeTabButtonText: {
    color: colors.white,
  },
  registrationNumberCard: {
    flex: 1,
    minWidth: Platform.OS === "web" && screenWidth > 760 ? 100 : "28%",
    height: Platform.OS === "web" && screenWidth > 760 ? 100 : 85,
    backgroundColor: colors.royalBlue,
    padding: Platform.OS === "web" && screenWidth > 760 ? 10 : 5,
    borderRadius: 5,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent:
      Platform.OS === "web" && screenWidth > 760 ? "center" : "space-between",
    gap: 5,
  },
});
export default ManageTournament;
