import {
  View,
  Text,
  Dimensions,
  Platform,
  Pressable,
  StyleSheet,
  Image,
  ActivityIndicator,
  Modal,
  ScrollView,
  Linking,
} from "react-native";
import React, { useEffect, useState } from "react";
import { DataStore } from "aws-amplify/datastore";
import { MatchMaster } from "../../../../models";

import { CommonHeading } from "../../../../components/reusable/TextComponent";
import SearchBar from "../../../../components/reusable/SearchBar";
import Layout from "../../../../components/include/Layout";
import {
  convertTo12HourFormat,
  formatDate,
  getCurrentDate,
} from "../../../../components/FormattedDate";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { colors } from "../../../../components/reusable/colour";
import Graph from "react-native-vector-icons/Entypo";
import Play from "react-native-vector-icons/Entypo";
import { LinearGradient } from "expo-linear-gradient";
import { ErrorModal } from "../../../../components/reusable/AreYouSure";
import ZoomableImage from "../../../../components/reusable/ZoomableImage";
const screenWidth = Dimensions.get("window").width;
const defaultLogo: any = "../../../../assets/Avtar/football-club.png";

const AllMatches = () => {
  const [activeTab, setActiveTab] = useState("All");

  const [search, setSearch] = useState("");
  const [searchFlag, setSearchFlag] = useState(false);
  const [allMatches, setAllMatches] = useState([]);

  const [scheduledMatches, setScheduledMatches] = useState([]);
  const [completedMatches, setCompletedMatches] = useState([]);
  const [liveMatches, setLiveMatches] = useState([]);

  const [allMatchesFlag, setAllMatchesFlag] = useState(false);
  const [scheduledMatchFlag, setScheduledMatchFlag] = useState(false);
  const [completedMatchFlag, setCompletedMatchFlag] = useState(false);
  const [liveMatchFlag, setLiveMatchFlag] = useState(false);

  const [selectedMatchId, setSelectedMatchId] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [noResultFound, setNoResultFound] = useState(false);

  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorModalMesg, setErrorModalMesg] = useState("");
  const navigation: any = useNavigation();

  const handleFilterChange = (tab: any) => {
    setActiveTab(tab);
  };

  const handleSearch = (text: any) => {
    setSearchFlag(true);

    if (!text) {
      // If the search text is empty, reset to the original data
      setAllMatches([]);
      setScheduledMatches([]);
      setCompletedMatches([]);
      setLiveMatches([]);
      setNoResultFound(false); // Reset the no result found flag
      setSearchFlag(false); // Set search flag to false when there is no active search
      return; // Exit the function early
    }

    // Filter the data based on the search text
    const filteredResults1 = allMatches.filter((item) =>
      searchObject(item, text)
    );
    const filteredResults2 = completedMatches.filter((item) =>
      searchObject(item, text)
    );
    const filteredResults3 = liveMatches.filter((item) =>
      searchObject(item, text)
    );
    const filteredResults4 = scheduledMatches.filter((item) =>
      searchObject(item, text)
    );

    // Check if any of the filtered results arrays are not empty
    if (
      filteredResults1.length === 0 &&
      filteredResults2.length === 0 &&
      filteredResults3.length === 0 &&
      filteredResults4.length === 0
    ) {
      setNoResultFound(true); // Set the no result found flag if all arrays are empty
    } else {
      // Set the filtered results and reset the no result found flag
      setAllMatches(filteredResults1);
      setCompletedMatches(filteredResults2);
      setLiveMatches(filteredResults3);
      setScheduledMatches(filteredResults4);
      setNoResultFound(false);
    }

    // Set the active tab to "All" regardless of the current tab
    setActiveTab("All");
  };

  // Recursive function to search through all attributes of an object and its nested objects
  const searchObject = (obj: any, text: string): boolean => {
    for (const key in obj) {
      const value = obj[key];
      // Check if the attribute value is an object
      if (typeof value === "object" && value !== null) {
        // If it's an object, recursively search through its attributes
        const matchFound = searchObject(value, text);
        if (matchFound) {
          return true; // If a match is found in the nested object, return true
        }
      } else {
        // If it's a string or number, check if it contains the search text
        if (
          (typeof value === "string" || typeof value === "number") &&
          value.toString().toLowerCase().includes(text.toLowerCase())
        ) {
          return true; // If a match is found, return true
        }
      }
    }
    return false; // If no match is found in any attribute, return false
  };

  const handleMatchesResultDetailsNavigation = async (
    tournamentId: any,
    matchId: any
  ) => {
    navigation.navigate("TournamentDetails", {
      tournamentId: tournamentId,
      matchId: matchId,
      initialTab: 2,
      showResultDetailsFromMatches: false,
      showResultPage: true,
      matchNavigation: true,
    });
    const matchesPropsObject = {
      tournamentId: tournamentId,
      matchId: matchId,
      initialTab: 2,
      showResultDetailsFromMatches: false,
      showResultPage: true,
      matchNavigation: true,
    };
    console.log("matchesPropsObject", matchesPropsObject);

    await AsyncStorage.setItem(
      "matchesResultDetails",
      JSON.stringify(matchesPropsObject)
    );
    if (matchId) {
      console.log("matchId", matchId);
      setSelectedMatchId(matchId);
      // If the same match is clicked again, close the details
      setShowDetails(true);
    } else {
      // Otherwise, open details for the selected match
      setShowDetails(false);
    }
  };

  const handleSubmit = () => {
    setErrorModalVisible(false);
  };

  const handleClickOnWatchMatch = async (LiveURL: string) => {
    if (LiveURL) {
      await Linking.openURL(LiveURL);
    } else {
      setErrorModalVisible(true);
      setErrorModalMesg("No Live URL is provided ");
      // alert("No Live URL is provided ");
    }
  };

  const getMatchesData = async () => {
    setIsLoading(false);
    const subscription = DataStore.observeQuery(MatchMaster).subscribe(
      async (snapshot) => {
        const initialMatches = snapshot.items;
        console.log("initialMatches", initialMatches);

        // setAllMatches(initialMatches);
        // create match object with team1 and  team2  data
        const matchesData = await Promise.all(
          initialMatches.map(async (element: any) => ({
            ...element,
            team1: await element.Team1,
            team2: await element.Team2,
            tournament: await element.tournamentData,
          }))
        );
        console.log("matchesData", matchesData);

        // all matches filters
        if (activeTab === "All") {
          console.log("Hello All", matchesData);
          setAllMatches(matchesData);
          // setIsLoading(false);
          setScheduledMatches(
            matchesData.filter(
              (tournament: any) => tournament.Status === "SCHEDULED"
            )
          );
          setCompletedMatches(
            matchesData.filter(
              (tournament: any) => tournament.Status === "COMPLETED"
            )
          );
          setLiveMatches(
            matchesData.filter(
              (tournament: any) => tournament.Status === "INPROGRESS"
            )
          );

          if (matchesData.length > 0) {
            console.log("matchesData", matchesData.length);
            setAllMatchesFlag(true);
          } else {
            setAllMatchesFlag(false);
          }
          console.log("allMatches", matchesData);
        } else if (activeTab === "Upcoming") {
          const scheduledMatches = matchesData.filter(
            (tournament: any) => tournament.Status === "SCHEDULED"
          );
          console.log("scheduledMatches", scheduledMatches);
          setScheduledMatches(scheduledMatches);
          // setIsLoading(false);
          if (scheduledMatches.length > 0) {
            console.log("scheduledMatches", scheduledMatches.length);
            setScheduledMatchFlag(true);
          } else {
            setScheduledMatchFlag(false);
          }
        } else if (activeTab === "Completed") {
          const completedMatches = matchesData.filter(
            (tournament: any) => tournament.Status === "COMPLETED"
          );
          console.log("completedMatches", completedMatches);
          setCompletedMatches(completedMatches);
          // setIsLoading(false);
          if (completedMatches.length > 0) {
            console.log("completedMatches", completedMatches.length);
            setCompletedMatchFlag(true);
          } else {
            setCompletedMatchFlag(false);
          }
        } else if (activeTab === "InProgress") {
          const liveMatches = matchesData.filter(
            (tournament: any) => tournament.Status === "INPROGRESS"
          );
          console.log("liveMatches", liveMatches);
          setLiveMatches(liveMatches);
          // setIsLoading(false);
          if (liveMatches.length > 0) {
            console.log("liveMatches", liveMatches.length);
            setLiveMatchFlag(true);
          } else {
            setLiveMatchFlag(false);
          }
        }
      }
    );
    setIsLoading(false);

    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    console.log("activeTab", activeTab);
    getMatchesData();
  }, [
    activeTab,
    allMatchesFlag,
    scheduledMatchFlag,
    completedMatchFlag,
    liveMatchFlag,
    searchFlag,
    noResultFound,
  ]);

  return (
    <>
      <Layout>
        {/* only mobile */}
        {Platform.OS !== "web" && (
          <View style={{ paddingHorizontal: 15, paddingBottom: 15 }}>
            <SearchBar
              placeholder="Search"
              onSearch={handleSearch}
              // onChangeText={(text) => handleSearch(text)}
              style={styles.serchbar}
            />
            <View style={styles.badgeContainer}>
              <Pressable
                style={[
                  styles.filterButton,
                  {
                    backgroundColor:
                      activeTab === "All" ? colors.orange : colors.royalBlue,
                  },
                ]}
                onPress={() => handleFilterChange("All")}
              >
                <Text style={styles.filterBtnText}>All</Text>
              </Pressable>

              <Pressable
                style={[
                  styles.filterButton,
                  {
                    backgroundColor:
                      activeTab === "Upcoming"
                        ? colors.orange
                        : colors.royalBlue,
                  },
                ]}
                onPress={() => handleFilterChange("Upcoming")}
              >
                <Text style={styles.filterBtnText}>Upcoming</Text>
              </Pressable>

              <Pressable
                style={[
                  styles.filterButton,
                  {
                    backgroundColor:
                      activeTab === "InProgress"
                        ? colors.orange
                        : colors.royalBlue,
                  },
                ]}
                onPress={() => handleFilterChange("InProgress")}
              >
                <Text style={styles.filterBtnText}>In Progress</Text>
              </Pressable>

              <Pressable
                style={[
                  styles.filterButton,
                  {
                    backgroundColor:
                      activeTab === "Completed"
                        ? colors.orange
                        : colors.royalBlue,
                  },
                ]}
                onPress={() => handleFilterChange("Completed")}
              >
                <Text style={styles.filterBtnText}>Completed</Text>
              </Pressable>
            </View>
          </View>
        )}

        {/* web view */}
        <ScrollView>
          <View style={styles.container}>
            {Platform.OS == "web" && <CommonHeading props={"Matches"} />}
            {Platform.OS == "web" && (
              <SearchBar
                placeholder="Search"
                onSearch={handleSearch}
                // onChangeText={(text) => handleSearch(text)}
                style={styles.serchbar}
              />
            )}

            {Platform.OS == "web" && (
              <View style={styles.badgeContainer}>
                <Pressable
                  style={[
                    styles.filterButton,
                    {
                      backgroundColor:
                        activeTab === "All" ? colors.orange : colors.royalBlue,
                    },
                  ]}
                  onPress={() => handleFilterChange("All")}
                >
                  <Text style={styles.filterBtnText}>All</Text>
                </Pressable>

                <Pressable
                  style={[
                    styles.filterButton,
                    {
                      backgroundColor:
                        activeTab === "Upcoming"
                          ? colors.orange
                          : colors.royalBlue,
                    },
                  ]}
                  onPress={() => handleFilterChange("Upcoming")}
                >
                  <Text style={styles.filterBtnText}>Upcoming</Text>
                </Pressable>

                <Pressable
                  style={[
                    styles.filterButton,
                    {
                      backgroundColor:
                        activeTab === "InProgress"
                          ? colors.orange
                          : colors.royalBlue,
                    },
                  ]}
                  onPress={() => handleFilterChange("InProgress")}
                >
                  <Text style={styles.filterBtnText}>In Progress</Text>
                </Pressable>

                <Pressable
                  style={[
                    styles.filterButton,
                    {
                      backgroundColor:
                        activeTab === "Completed"
                          ? colors.orange
                          : colors.royalBlue,
                    },
                  ]}
                  onPress={() => handleFilterChange("Completed")}
                >
                  <Text style={styles.filterBtnText}>Completed</Text>
                </Pressable>
              </View>
            )}
            {!noResultFound ? (
              <>
                <View style={styles.dashboardMatchContainer}>
                  {activeTab === "All" ? (
                    <>
                      {/* live matches */}

                      {liveMatches &&
                        liveMatches.length > 0 &&
                        liveMatches.map((match, matchIndex) => {
                          return (
                            <LinearGradient
                              style={styles.matchCardColor}
                              key={matchIndex}
                              colors={[colors.orange, colors.blue]}
                              start={{ x: 0, y: 1 }}
                              end={{ x: 1, y: 1 }}
                            >
                              <View style={styles.liveBadge}>
                                <Text style={styles.liveBadgeText}>Live</Text>
                              </View>
                              {/* <View style={styles.matchCardColor} key={matchIndex}> */}
                              <Text style={styles.matchCardHeadingWhite}>
                                {/* tournament name is here */}
                                {match.tournament
                                  ? match.tournament.Name
                                  : null}
                              </Text>
                              <View style={styles.matchDetailsWrapper}>
                                <View style={styles.teamLogoNameWrapper}>
                                  <ZoomableImage
                                    // source={require("../../../../../assets/logo/match_logo1.png")}
                                    imageSource={{
                                      uri:
                                        match.team1 &&
                                        match.team1.LogoURL !== null &&
                                        match.team1.LogoURL !== ""
                                          ? match.team1.LogoURL
                                          : defaultLogo,
                                    }}
                                    imageStyle={styles.matchClubLogo}
                                    modalStyle={undefined}
                                  />
                                  {/* add css to TeamName */}
                                  <Text
                                    style={[
                                      styles.teamNameText,
                                      { color: colors.white },
                                    ]}
                                  >
                                    {match.team1 ? match.team1.TeamName : null}
                                  </Text>
                                </View>
                                <View style={styles.matchScoreBadge}>
                                  <Text
                                    style={[
                                      styles.matchScoreTextWhite,
                                      { backgroundColor: "#02205F" },
                                    ]}
                                  >
                                    {/* team Score */}
                                    {`${
                                      match.team1
                                        ? match.team1.Score
                                          ? match.team1.Score.FirstHalf +
                                            match.team1.Score.SecondHalf +
                                            match.team1.Score.ExtraTime
                                          : "0"
                                        : "0"
                                    }-${
                                      match.team2
                                        ? match.team2.Score
                                          ? match.team2.Score.FirstHalf +
                                            match.team2.Score.SecondHalf +
                                            match.team2.Score.ExtraTime
                                          : "0"
                                        : "0"
                                    }`}
                                  </Text>
                                </View>
                                <View style={styles.teamLogoNameWrapper}>
                                  <ZoomableImage
                                    imageSource={{
                                      uri:
                                        match.team2 &&
                                        match.team2.LogoURL !== null &&
                                        match.team2.LogoURL !== ""
                                          ? match.team2.LogoURL
                                          : defaultLogo,
                                    }}
                                    imageStyle={styles.matchClubLogo}
                                    modalStyle={undefined}
                                  />
                                  {/* add css to TeamName */}
                                  <Text
                                    style={[
                                      styles.teamNameText,
                                      { color: colors.white },
                                    ]}
                                  >
                                    {match.team2 ? match.team2.TeamName : null}
                                  </Text>
                                </View>
                              </View>
                              <View style={styles.matchCardDateWrapper}>
                                <Text style={styles.matchCardDateTextWhite}>
                                  {match && match.Date
                                    ? formatDate(match.Date)
                                    : null}
                                </Text>
                                <Text style={styles.matchCardDateTextWhite}>
                                  {match && match.Time
                                    ? convertTo12HourFormat(match.Time)
                                    : null}
                                </Text>
                                <Text style={styles.matchCardDateTextWhite}>
                                  {match && match.Venue
                                    ? match.Venue.Name
                                    : null}
                                </Text>
                              </View>

                              <View
                                style={[
                                  styles.horizontalLine,
                                  { marginVertical: 10 },
                                ]}
                              />
                              <View
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Pressable
                                  style={styles.matchCardRow}
                                  onPress={() => {
                                    handleMatchesResultDetailsNavigation(
                                      match.tournament.id,
                                      match.id
                                    );
                                  }}
                                >
                                  <Graph
                                    name="bar-graph"
                                    size={16}
                                    color={colors.white}
                                  />
                                  <Text style={styles.matchCardBtnText}>
                                    View Details
                                  </Text>
                                </Pressable>
                                <Pressable
                                  style={styles.matchCardRow}
                                  onPress={() =>
                                    handleClickOnWatchMatch(match.LiveURL)
                                  }
                                >
                                  <Play
                                    name="controller-play"
                                    size={16}
                                    color={colors.white}
                                  />

                                  <Text style={styles.matchCardBtnText}>
                                    Watch Match
                                  </Text>
                                </Pressable>
                              </View>
                              {/* </View> */}
                            </LinearGradient>
                          );
                        })}

                      {/* scheduled matches */}

                      {scheduledMatches &&
                        scheduledMatches.length > 0 &&
                        scheduledMatches.map((match, matchIndex) => {
                          return (
                            <View style={styles.matchCard} key={matchIndex}>
                              <Text style={styles.matchCardHeading}>
                                {match.tournament
                                  ? match.tournament.Name
                                  : null}
                              </Text>
                              <View style={styles.matchDetailsWrapper}>
                                <View style={styles.teamLogoNameWrapper}>
                                  <ZoomableImage
                                    imageSource={{
                                      uri: match.team1
                                        ? match.team1.LogoURL
                                        : defaultLogo,
                                    }}
                                    imageStyle={styles.matchClubLogo}
                                    modalStyle={undefined}
                                  />
                                  {/* {/* add css to TeamName  */}
                                  <Text style={styles.teamNameText}>
                                    {match.team1 ? match.team1.TeamName : null}
                                  </Text>
                                </View>
                                <View style={styles.matchScoreBadge}>
                                  <Text style={styles.matchCardVsText}>VS</Text>
                                </View>
                                <View style={styles.teamLogoNameWrapper}>
                                  <ZoomableImage
                                    imageSource={{
                                      uri: match.team2
                                        ? match.team2.LogoURL
                                        : defaultLogo,
                                    }}
                                    imageStyle={styles.matchClubLogo}
                                    modalStyle={undefined}
                                  />
                                  {/* {/* add css to TeamName  */}
                                  <Text style={styles.teamNameText}>
                                    {match.team2 ? match.team2.TeamName : null}
                                  </Text>
                                </View>
                              </View>
                              <View style={styles.matchCardDateWrapper}>
                                <Text style={styles.matchCardDateText}>
                                  {match && match.Date
                                    ? formatDate(match.Date)
                                    : null}
                                </Text>
                                <Text style={styles.matchCardDateText}>
                                  {match && match.Time
                                    ? convertTo12HourFormat(match.Time)
                                    : null}
                                </Text>
                                <Text style={styles.matchCardDateText}>
                                  {match && match.Venue
                                    ? match.Venue.Name
                                    : null}
                                </Text>
                              </View>
                              <View
                                style={[
                                  styles.horizontalLine,
                                  { marginVertical: 10 },
                                ]}
                              />
                              <View
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Pressable
                                  style={styles.matchCardRow}
                                  onPress={() => {
                                    handleMatchesResultDetailsNavigation(
                                      match.tournament.id,
                                      match.id
                                    );
                                  }}
                                >
                                  <Graph
                                    name="bar-graph"
                                    size={16}
                                    color={colors.white}
                                  />
                                  <Text style={styles.matchCardBtnTextColor}>
                                    View Details
                                  </Text>
                                </Pressable>
                                <Pressable
                                  style={styles.matchCardRow}
                                  onPress={() =>
                                    handleClickOnWatchMatch(match.LiveURL)
                                  }
                                >
                                  <Play
                                    name="controller-play"
                                    size={16}
                                    color={colors.white}
                                  />
                                  <Text style={styles.matchCardBtnTextColor}>
                                    Watch Match
                                  </Text>
                                </Pressable>
                              </View>
                            </View>
                          );
                        })}

                      {/* completed matches */}

                      {completedMatches &&
                        completedMatches.length > 0 &&
                        completedMatches.map((match, matchIndex) => {
                          return (
                            <View style={styles.matchCard} key={matchIndex}>
                              <Text style={styles.matchCardHeading}>
                                {match.tournament.Name
                                  ? match.tournament.Name
                                  : null}
                              </Text>
                              <View style={styles.matchDetailsWrapper}>
                                <View style={styles.teamLogoNameWrapper}>
                                  <ZoomableImage
                                    imageSource={{
                                      uri: match.team1
                                        ? match.team1.LogoURL
                                        : defaultLogo,
                                    }}
                                    imageStyle={styles.matchClubLogo}
                                    modalStyle={undefined}
                                  />
                                  {/* add css to TeamName */}
                                  <Text style={styles.teamNameText}>
                                    {match.team1 ? match.team1.TeamName : null}
                                  </Text>
                                </View>

                                <View style={styles.matchScoreBadge}>
                                  <Text
                                    style={[
                                      styles.matchScoreTextWhite,
                                      { backgroundColor: "#02205F" },
                                    ]}
                                  >
                                    {/* team Score */}
                                    {`${
                                      match.team1
                                        ? match.team1.Score
                                          ? match.team1.Score.FirstHalf +
                                            match.team1.Score.SecondHalf +
                                            match.team1.Score.ExtraTime
                                          : "0"
                                        : "0"
                                    }-${
                                      match.team2
                                        ? match.team2.Score
                                          ? match.team2.Score.FirstHalf +
                                            match.team2.Score.SecondHalf +
                                            match.team2.Score.ExtraTime
                                          : "0"
                                        : "0"
                                    }`}
                                  </Text>
                                </View>

                                <View style={styles.teamLogoNameWrapper}>
                                  <ZoomableImage
                                    imageSource={{
                                      uri: match.team2
                                        ? match.team2.LogoURL
                                        : defaultLogo,
                                    }}
                                    imageStyle={styles.matchClubLogo}
                                    modalStyle={undefined}
                                  />
                                  {/* add css to TeamName */}
                                  <Text style={styles.teamNameText}>
                                    {match.team2 ? match.team2.TeamName : null}
                                  </Text>
                                </View>
                              </View>
                              <View style={styles.matchCardDateWrapper}>
                                <Text style={styles.matchCardDateText}>
                                  {match.Date ? formatDate(match.Date) : null}
                                </Text>
                                <Text style={styles.matchCardDateText}>
                                  {match.Time
                                    ? convertTo12HourFormat(match.Time)
                                    : null}
                                </Text>
                                <Text style={styles.matchCardDateText}>
                                  {match?.Venue?.Name ? match.Venue.Name : null}
                                </Text>
                              </View>
                              <View
                                style={[
                                  styles.horizontalLine,
                                  { marginVertical: 10 },
                                ]}
                              />
                              <View
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Pressable
                                  style={styles.matchCardRow}
                                  onPress={() => {
                                    handleMatchesResultDetailsNavigation(
                                      match.tournament.id,
                                      match.id
                                    );
                                  }}
                                >
                                  <Graph
                                    name="bar-graph"
                                    size={16}
                                    color={colors.white}
                                  />
                                  <Text style={styles.matchCardBtnTextColor}>
                                    View Details
                                  </Text>
                                </Pressable>
                                <Pressable
                                  style={styles.matchCardRow}
                                  onPress={() =>
                                    handleClickOnWatchMatch(match.LiveURL)
                                  }
                                >
                                  <Play
                                    name="controller-play"
                                    size={16}
                                    color={colors.white}
                                  />
                                  <Text style={styles.matchCardBtnTextColor}>
                                    Watch Match
                                  </Text>
                                </Pressable>
                              </View>
                            </View>
                          );
                        })}

                      {/* other than above status matches */}
                      {allMatches && allMatches.length > 0
                        ? allMatches.map((match, matchIndex) => {
                            return match.Status === "CREATED" ||
                              match.Status === "RESCHEDULED" ||
                              match.Status === "CANCELLED" ? (
                              <View style={styles.matchCard} key={matchIndex}>
                                <Text style={styles.matchCardHeading}>
                                  {match.tournament
                                    ? match.tournament.Name
                                    : null}
                                </Text>
                                <View style={styles.matchDetailsWrapper}>
                                  <View style={styles.teamLogoNameWrapper}>
                                    <ZoomableImage
                                      imageSource={{
                                        uri: match.team1
                                          ? match.team1.LogoURL
                                          : defaultLogo,
                                      }}
                                      imageStyle={styles.matchClubLogo}
                                      modalStyle={undefined}
                                    />
                                    {/* {/* add css to TeamName  */}
                                    <Text style={styles.teamNameText}>
                                      {match.team1
                                        ? match.team1.TeamName
                                        : null}
                                    </Text>
                                  </View>
                                  <View style={styles.matchScoreBadge}>
                                    <Text style={styles.matchCardVsText}>
                                      VS
                                    </Text>
                                  </View>
                                  <View style={styles.teamLogoNameWrapper}>
                                    <ZoomableImage
                                      imageSource={{
                                        uri: match.team2
                                          ? match.team2.LogoURL
                                          : defaultLogo,
                                      }}
                                      imageStyle={styles.matchClubLogo}
                                      modalStyle={undefined}
                                    />
                                    {/* {/* add css to TeamName  */}
                                    <Text style={styles.teamNameText}>
                                      {match.team2
                                        ? match.team2.TeamName
                                        : null}
                                    </Text>
                                  </View>
                                </View>
                                <View style={styles.matchCardDateWrapper}>
                                  <Text style={styles.matchCardDateText}>
                                    {match && match.Date
                                      ? formatDate(match.Date)
                                      : null}
                                  </Text>
                                  <Text style={styles.matchCardDateText}>
                                    {match && match.Time
                                      ? convertTo12HourFormat(match.Time)
                                      : null}
                                  </Text>
                                  <Text style={styles.matchCardDateText}>
                                    {match && match.Venue
                                      ? match.Venue.Name
                                      : null}
                                  </Text>
                                </View>
                                <View
                                  style={[
                                    styles.horizontalLine,
                                    { marginVertical: 10 },
                                  ]}
                                />
                                <View
                                  style={{
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Pressable
                                    style={styles.matchCardRow}
                                    onPress={() => {
                                      handleMatchesResultDetailsNavigation(
                                        match.tournament.id,
                                        match.id
                                      );
                                    }}
                                  >
                                    <Graph
                                      name="bar-graph"
                                      size={16}
                                      color={colors.white}
                                    />
                                    <Text style={styles.matchCardBtnTextColor}>
                                      View Details
                                    </Text>
                                  </Pressable>
                                  <Pressable
                                    style={styles.matchCardRow}
                                    onPress={() =>
                                      handleClickOnWatchMatch(match.LiveURL)
                                    }
                                  >
                                    <Play
                                      name="controller-play"
                                      size={16}
                                      color={colors.white}
                                    />
                                    <Text style={styles.matchCardBtnTextColor}>
                                      Watch Match
                                    </Text>
                                  </Pressable>
                                </View>
                              </View>
                            ) : null;
                          })
                        : null}
                    </>
                  ) : null}

                  {/* <View> */}
                  {/* Completed matches */}
                  {activeTab === "Completed" ? (
                    completedMatches && completedMatches.length > 0 ? (
                      completedMatches.map((match, matchIndex) => {
                        return (
                          <View style={styles.matchCard} key={matchIndex}>
                            <Text style={styles.matchCardHeading}>
                              {match.tournament.Name
                                ? match.tournament.Name
                                : null}
                            </Text>
                            <View style={styles.matchDetailsWrapper}>
                              <View style={styles.teamLogoNameWrapper}>
                                <ZoomableImage
                                  // source={require("../../../../../assets/logo/match_logo7.png")}
                                  imageSource={{
                                    uri: match.team1
                                      ? match.team1.LogoURL
                                      : defaultLogo,
                                  }}
                                  imageStyle={styles.matchClubLogo}
                                  modalStyle={undefined}
                                />
                                {/* add css to TeamName */}
                                <Text style={styles.teamNameText}>
                                  {match.team1 ? match.team1.TeamName : null}
                                </Text>
                              </View>

                              <View style={styles.matchScoreBadge}>
                                <Text
                                  style={[
                                    styles.matchScoreTextWhite,
                                    { backgroundColor: "#02205F" },
                                  ]}
                                >
                                  {/* team Score */}
                                  {`${
                                    match.team1
                                      ? match.team1.Score
                                        ? match.team1.Score.FirstHalf +
                                          match.team1.Score.SecondHalf +
                                          match.team1.Score.ExtraTime
                                        : "0"
                                      : "0"
                                  }-${
                                    match.team2
                                      ? match.team2.Score
                                        ? match.team2.Score.FirstHalf +
                                          match.team2.Score.SecondHalf +
                                          match.team2.Score.ExtraTime
                                        : "0"
                                      : "0"
                                  }`}
                                </Text>
                              </View>

                              {/* <View style={styles.matchScoreBadge}>
                          <Text style={styles.matchCardVsText}>VS</Text>
                        </View> */}
                              <View style={styles.teamLogoNameWrapper}>
                                <ZoomableImage
                                  // source={require("../../../../../assets/logo/match_logo8.png")}
                                  imageSource={{
                                    uri: match.team2
                                      ? match.team2.LogoURL
                                      : defaultLogo,
                                  }}
                                  imageStyle={styles.matchClubLogo}
                                  modalStyle={undefined}
                                />
                                {/* add css to TeamName */}
                                <Text style={styles.teamNameText}>
                                  {match.team2 ? match.team2.TeamName : null}
                                </Text>
                              </View>
                            </View>
                            <View style={styles.matchCardDateWrapper}>
                              <Text style={styles.matchCardDateText}>
                                {match.Date ? formatDate(match.Date) : null}
                              </Text>
                              <Text style={styles.matchCardDateText}>
                                {match.Time
                                  ? convertTo12HourFormat(match.Time)
                                  : null}
                              </Text>
                              <Text style={styles.matchCardDateText}>
                                {match?.Venue?.Name ? match.Venue.Name : null}
                              </Text>
                            </View>
                            <View
                              style={[
                                styles.horizontalLine,
                                { marginVertical: 10 },
                              ]}
                            />
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Pressable
                                style={styles.matchCardRow}
                                onPress={() => {
                                  handleMatchesResultDetailsNavigation(
                                    match.tournament.id,
                                    match.id
                                  );
                                }}
                              >
                                <Graph
                                  name="bar-graph"
                                  size={16}
                                  color={colors.white}
                                />
                                <Text style={styles.matchCardBtnTextColor}>
                                  View Details
                                </Text>
                              </Pressable>
                              <Pressable
                                style={styles.matchCardRow}
                                onPress={() =>
                                  handleClickOnWatchMatch(match.LiveURL)
                                }
                              >
                                <Play
                                  name="controller-play"
                                  size={16}
                                  color={colors.white}
                                />
                                <Text style={styles.matchCardBtnTextColor}>
                                  Watch Match
                                </Text>
                              </Pressable>
                            </View>
                          </View>
                        );
                      })
                    ) : (
                      <Text
                        style={{
                          color: colors.white,
                          justifyContent: "center",
                        }}
                      >
                        No completed matches found
                      </Text>
                    )
                  ) : null}
                  {/* Live card  item */}
                  {activeTab === "InProgress" ? (
                    liveMatches && liveMatches.length > 0 ? (
                      liveMatches.map((match, matchIndex) => {
                        return (
                          <LinearGradient
                            style={styles.matchCardColor}
                            key={matchIndex}
                            colors={["#FF5733", "#012879"]}
                            start={{ x: 0, y: 1 }}
                            end={{ x: 1, y: 1 }}
                          >
                            {/* <View style={styles.matchCardColor} key={matchIndex}> */}
                            <Text style={styles.matchCardHeadingWhite}>
                              {/* tournament name is here */}
                              {match.tournament ? match.tournament.Name : null}
                            </Text>
                            <View style={styles.matchDetailsWrapper}>
                              <View style={styles.teamLogoNameWrapper}>
                                <ZoomableImage
                                  imageSource={{
                                    uri: match.team1
                                      ? match.team1.LogoURL
                                      : defaultLogo,
                                  }}
                                  imageStyle={styles.matchClubLogo}
                                  modalStyle={undefined}
                                />
                                {/* add css to TeamName */}
                                <Text
                                  style={[
                                    styles.teamNameText,
                                    { color: colors.white },
                                  ]}
                                >
                                  {match.team1 ? match.team1.TeamName : null}
                                </Text>
                              </View>
                              <View style={styles.matchScoreBadge}>
                                <Text
                                  style={[
                                    styles.matchScoreTextWhite,
                                    { backgroundColor: "#02205F" },
                                  ]}
                                >
                                  {/* team Score */}
                                  {`${
                                    match.team1
                                      ? match.team1.Score
                                        ? match.team1.Score.FirstHalf +
                                          match.team1.Score.SecondHalf +
                                          match.team1.Score.ExtraTime
                                        : "0"
                                      : "0"
                                  }-${
                                    match.team2
                                      ? match.team2.Score
                                        ? match.team2.Score.FirstHalf +
                                          match.team2.Score.SecondHalf +
                                          match.team2.Score.ExtraTime
                                        : "0"
                                      : "0"
                                  }`}
                                </Text>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: 3,
                                  }}
                                >
                                  <View
                                    style={{
                                      width: 6,
                                      height: 6,
                                      borderRadius: 3,
                                      backgroundColor: "red",
                                    }}
                                  ></View>
                                  <Text
                                    style={{
                                      color: colors.white,
                                      fontFamily: "PlusJakartaSansMedium",
                                    }}
                                  >
                                    Live
                                  </Text>
                                </View>
                              </View>
                              <View style={styles.teamLogoNameWrapper}>
                                <ZoomableImage
                                  imageSource={{
                                    uri: match.team2
                                      ? match.team2.LogoURL
                                      : defaultLogo,
                                  }}
                                  imageStyle={styles.matchClubLogo}
                                  modalStyle={undefined}
                                />
                                {/* add css to TeamName */}
                                <Text
                                  style={[
                                    styles.teamNameText,
                                    { color: colors.white },
                                  ]}
                                >
                                  {match.team2 ? match.team2.TeamName : null}
                                </Text>
                              </View>
                            </View>
                            <View style={styles.matchCardDateWrapper}>
                              <Text style={styles.matchCardDateTextWhite}>
                                {match && match.Date
                                  ? formatDate(match.Date)
                                  : null}
                              </Text>
                              <Text style={styles.matchCardDateTextWhite}>
                                {match && match.Time
                                  ? convertTo12HourFormat(match.Time)
                                  : null}
                              </Text>
                              <Text style={styles.matchCardDateTextWhite}>
                                {match && match.Venue ? match.Venue.Name : null}
                              </Text>
                            </View>
                            <View
                              style={[
                                styles.horizontalLine,
                                { marginVertical: 10 },
                              ]}
                            />
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Pressable
                                style={styles.matchCardRow}
                                onPress={() => {
                                  handleMatchesResultDetailsNavigation(
                                    match.tournament.id,
                                    match.id
                                  );
                                }}
                              >
                                <Graph
                                  name="bar-graph"
                                  size={16}
                                  color="#fff"
                                />
                                <Text style={styles.matchCardBtnText}>
                                  View Details
                                </Text>
                              </Pressable>
                              <Pressable
                                style={styles.matchCardRow}
                                onPress={() =>
                                  handleClickOnWatchMatch(match.LiveURL)
                                }
                              >
                                <Play
                                  name="controller-play"
                                  size={16}
                                  color={colors.white}
                                />
                                <Text style={styles.matchCardBtnText}>
                                  Watch Match
                                </Text>
                              </Pressable>
                            </View>
                            {/* </View> */}
                          </LinearGradient>
                        );
                      })
                    ) : (
                      <Text
                        style={{
                          color: colors.white,
                          justifyContent: "center",
                        }}
                      >
                        No live matches found
                      </Text>
                    )
                  ) : null}
                  {/* Upcoming/scheduled new item */}
                  {activeTab === "Upcoming" ? (
                    scheduledMatches && scheduledMatches.length > 0 ? (
                      scheduledMatches.map((match, matchIndex) => {
                        return (
                          <View style={styles.matchCard} key={matchIndex}>
                            <Text style={styles.matchCardHeading}>
                              {match.tournament ? match.tournament.Name : null}
                            </Text>
                            <View style={styles.matchDetailsWrapper}>
                              <View style={styles.teamLogoNameWrapper}>
                                <ZoomableImage
                                  // source={require("../../../../../assets/logo/match_logo7.png")}
                                  imageSource={{
                                    uri: match.team1
                                      ? match.team1.LogoURL
                                      : defaultLogo,
                                  }}
                                  imageStyle={styles.matchClubLogo}
                                  modalStyle={undefined}
                                />
                                {/* {/* add css to TeamName  */}
                                <Text style={styles.teamNameText}>
                                  {match.team1 ? match.team1.TeamName : null}
                                </Text>
                              </View>
                              <View style={styles.matchScoreBadge}>
                                <Text style={styles.matchCardVsText}>VS</Text>
                              </View>
                              <View style={styles.teamLogoNameWrapper}>
                                <ZoomableImage
                                  // source={require("../../../../../assets/logo/match_logo8.png")}
                                  imageSource={{
                                    uri: match.team2
                                      ? match.team2.LogoURL
                                      : defaultLogo,
                                  }}
                                  imageStyle={styles.matchClubLogo}
                                  modalStyle={undefined}
                                />
                                {/* {/* add css to TeamName  */}
                                <Text style={styles.teamNameText}>
                                  {match.team2 ? match.team2.TeamName : null}
                                </Text>
                              </View>
                            </View>
                            <View style={styles.matchCardDateWrapper}>
                              <Text style={styles.matchCardDateText}>
                                {match && match.Date
                                  ? formatDate(match.Date)
                                  : null}
                              </Text>
                              <Text style={styles.matchCardDateText}>
                                {match && match.Time
                                  ? convertTo12HourFormat(match.Time)
                                  : null}
                              </Text>
                              <Text style={styles.matchCardDateText}>
                                {match && match.Venue ? match.Venue.Name : null}
                              </Text>
                            </View>
                            <View
                              style={[
                                styles.horizontalLine,
                                { marginVertical: 10 },
                              ]}
                            />
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Pressable
                                style={styles.matchCardRow}
                                onPress={() => {
                                  handleMatchesResultDetailsNavigation(
                                    match.tournament.id,
                                    match.id
                                  );
                                }}
                              >
                                <Graph
                                  name="bar-graph"
                                  size={16}
                                  color={colors.white}
                                />
                                <Text style={styles.matchCardBtnTextColor}>
                                  View Details
                                </Text>
                              </Pressable>
                              <Pressable
                                style={styles.matchCardRow}
                                onPress={() =>
                                  handleClickOnWatchMatch(match.LiveURL)
                                }
                              >
                                <Play
                                  name="controller-play"
                                  size={16}
                                  color={colors.white}
                                />
                                <Text style={styles.matchCardBtnTextColor}>
                                  Watch Match
                                </Text>
                              </Pressable>
                            </View>
                          </View>
                        );
                      })
                    ) : (
                      <Text
                        style={{
                          color: colors.white,
                          justifyContent: "center",
                        }}
                      >
                        No upcoming matches found
                      </Text>
                    )
                  ) : null}
                  {/* </View> */}
                </View>
              </>
            ) : (
              <Text>No results found</Text>
            )}
          </View>

          {/* Loader */}
          {isLoading && (
            <Modal transparent animationType="none">
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}
              >
                <ActivityIndicator size="large" color="#0000ff" />
                <Text style={{ color: "white" }}>Loading</Text>
              </View>
            </Modal>
          )}
        </ScrollView>

        {errorModalVisible == true ? (
          <ErrorModal
            visibleModal={errorModalVisible}
            SuccessMessage={errorModalMesg}
            onSubmit={handleSubmit}
            header={"Attention"}
          />
        ) : null}
      </Layout>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    maxWidth: 1320,
    width: "100%",
    height: "100%",
    marginHorizontal: "auto",
    paddingRight: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
    paddingLeft: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
    paddingTop: Platform.OS === "web" && screenWidth > 760 ? 20 : 10,
  },
  homeMatchContainer: {
    paddingVertical: 10,
    display: "flex",
    flexDirection: "row",
    gap: 30,
    overflow: "scroll",
    rowGap: 25,
    width: "100%",
    marginTop: 15,
  },
  dashboardMatchContainer: {
    width: "100%",
    marginTop: 15,
    display: "flex",
    flexDirection: "row",
    gap: 20,
    flexWrap: "wrap",
    paddingBottom: 20,
  },
  matchCard: {
    backgroundColor: colors.royalBlue,
    padding: 15,
    flex: 1,
    maxWidth: Platform.OS === "web" && screenWidth > 760 ? "33%" : "100%",
    minWidth: Platform.OS === "web" && screenWidth > 760 ? "28%" : "100%",
    borderRadius: 10,
    shadowOffset: {
      width: 3,
      height: 4,
    },
    shadowColor: "#000000",
    shadowOpacity: 0.22,
    shadowRadius: 10,
    elevation: 3,
  },
  matchCardColor: {
    backgroundColor: colors.royalBlue,
    padding: 15,
    flex: 1,
    maxWidth: Platform.OS === "web" && screenWidth > 760 ? "33%" : "100%",
    minWidth: Platform.OS === "web" && screenWidth > 760 ? "28%" : "100%",
    borderRadius: 10,
    shadowOffset: {
      width: 3,
      height: 4,
    },
    shadowColor: "#000000",
    shadowOpacity: 0.22,
    shadowRadius: 10,
    elevation: 3,
    position: "relative",
  },
  matchCardHeading: {
    color: colors.white,
    height: 45,
    textAlign: "center",
    fontSize: 16,
    marginBottom: 15,
    fontFamily: "PlusJakartaSansSemiBold",
  },
  matchCardHeadingWhite: {
    color: colors.white,
    height: 45,
    textAlign: "center",
    fontSize: 16,
    marginBottom: 15,
    fontFamily: "PlusJakartaSansSemiBold",
  },
  matchClubLogo: {
    width: 60,
    height: 60,
    borderRadius: 30,
    backgroundColor: "#f9f3ff",
  },
  matchDetailsWrapper: {
    flexDirection: "row",
    gap: 10,
    alignItems: "center",
    justifyContent: "space-around",
  },
  matchScoreBadge: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: 85,
    gap: 5,
  },
  matchScoreText: {
    fontSize: 20,
    fontWeight: "600",
  },
  matchScoreTextWhite: {
    fontSize: 26,
    fontWeight: "600",
    color: colors.white,
    paddingHorizontal: 15,
    borderRadius: 10,
  },
  matchScoreStatus: {
    backgroundColor: "#00175b",
    color: colors.white,
    paddingVertical: 2,
    paddingHorizontal: 5,
  },
  matchCardDateWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: 5,
    marginTop: 15,
  },
  matchCardDateText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
  },
  matchCardDateTextWhite: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 12,
  },
  matchCardVenueTextWhite: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
    textAlign: "center",
    fontSize: 12,
    marginBottom: 15,
  },
  horizontalLine: {
    width: "100%",
    height: 1,
    backgroundColor: "#dbd6d6",
  },
  matchCardRow: {
    flexDirection: "row",
    gap: 10,
  },
  matchCardVsText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansBold",
    fontSize: 22,
  },
  matchCardBtnTextColor: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 12,
  },
  matchCardBtnText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 12,
  },
  teamNameText: {
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 12,
    color: colors.white,
    textAlign: "center",
  },
  teamLogoNameWrapper: {
    alignItems: "center",
    gap: 5,
    flex: 1,
  },
  badgeContainer: {
    // padding: 10,
    height: Platform.OS === "web" && screenWidth > 760 ? undefined : 60,
    paddingTop: 20,
    gap: 20,
    paddingBottom: 0,
    flexDirection: "row",
    // backgroundColor: "red",
    overflow: Platform.OS === "web" && screenWidth > 760 ? undefined : "scroll",
  },
  filterButton: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 6,
  },
  filterBtnText: {
    fontSize: 14,
    fontFamily: "PlusJakartaSansMedium",
    color: colors.white,
  },
  serchbar: {
    width: Platform.OS === "web" && screenWidth > 760 ? 350 : "100%",
    borderColor: colors.borderColor,
    backgroundColor: colors.blue,
    marginTop: 20,
    color: colors.white,
  },
  liveBadge: {
    backgroundColor: colors.red,
    position: "absolute",
    left: -2,
    top: -2,
    borderRadius: 6,
    paddingVertical: 2,
    paddingHorizontal: 10,
  },
  liveBadgeText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 18,
  },
});
export default AllMatches;
