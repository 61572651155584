// let CompareArray = [];
let values = 8;
export const KnockOutDesignFun = (typeofTeams, CompareArray) => {
  console.log("roundevent", values, CompareArray);
  // setRound1arr([]);
  let assignTeam = Number(typeofTeams);
  console.log("RoundData", assignTeam);
  if (CompareArray && CompareArray.length == 0) {
    console.log("notfixturecreated");
    const numTeams = assignTeam;
    let finalvalue = [];
    const teams = [];
    for (let i = 1; i <= numTeams; i++) {
      teams.push("Team" + i);
    }
    function simulateMatch(team1, team2) {
      const winnerIndex = Math.floor(Math.random() * 2);
      return winnerIndex === 0 ? team1 : team2;
    }
    function generateSchedule(teams) {
      const numRounds = Math.ceil(Math.log2(teams.length));
      const schedule = [];
      let roundTeams = teams;
      for (let round = 1; round <= numRounds; round++) {
        const roundSchedule = [];
        for (let i = 0; i < roundTeams.length; i += 2) {
          const team1 = roundTeams[i];
          const team2 = roundTeams[i + 1];
          const winner = simulateMatch(team1, team2);
          roundSchedule.push([team1, team2, winner]);
        }
        schedule.push(roundSchedule);
        roundTeams = roundSchedule.map((match) => match[2]);
      }
      return schedule;
    }
    const schedule = generateSchedule(teams);
    let allround = [];
    let Roundarr = [];
    schedule.forEach((round, i) => {
      Roundarr.push("Round" + (i + 1));
      round.forEach((match) => {
        allround.push({
          round: "Round" + (i + 1),
          data: match[0] + "vs" + match[1],
        });
      });
    });
    Roundarr.map((ele) => {
      let arrvalue = [];
      allround.map((obj) => {
        if (ele === obj.round) {
          arrvalue.push({
            A: obj.data.split("vs")[0],
            B: obj.data.split("vs")[1],
            FlagA: false,
            FlagB: false,
          });
        }
      });

      finalvalue.push({
        Name: ele,
        value: arrvalue,
      });
    });
    console.log("finalvalue", finalvalue);

    if (finalvalue && finalvalue.length > 0) {
      return finalvalue;
    }
  } else {
    if (CompareArray && CompareArray.length > 0) {
      return CompareArray;
    }
  }
};
