import React, { useContext, useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Pressable,
  Image,
  Dimensions,
  Platform,
  Modal,
  TextInput,
  TouchableWithoutFeedback,
  ActivityIndicator,
} from "react-native";
import { colors } from "../../../../components/reusable/colour";
import { Checkbox } from "react-native-paper";
import {
  CoachMaster,
  PaymentStatus,
  RegistrationStatus,
  Squad,
  TeamRegistrations,
  Tournament,
  UserMaster,
  UserMasterTeamRegistrations,
  UserMasterTournament,
} from "../../../../models";
import awsmobile from "../../../../aws-exports";
import { getPlayersData } from "../../../../api/PlayersAPI";
import { getUserMasterData } from "../../../../api/UserMasterAPI";
import { getCoachMasterData } from "../../../../api/CoachMasterAPI";
import { TeamRegistrationsPlayerMaster } from "../../../../models";
import { useNavigation } from "@react-navigation/native";
import { DataStore } from "aws-amplify/datastore";
import { uploadData } from "aws-amplify/storage";
import UploadImage from "../../../../components/reusable/UploadImage";
import { AuthenticationContext } from "../../../../authentication/Authentication";

import {
  ErrorModal,
  ModalTrophy,
} from "../../../../components/reusable/AreYouSure";
import AsyncStorage from "@react-native-async-storage/async-storage";
const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height; //
// let playersData;

const SelectTeamModal = ({
  visibleModal,
  handleClose,
  squadId,
  userUUID,
  currentUserName,
  tournamentID,
  // teamEditFlagData,
}) => {
  const [checked, setChecked] = React.useState(false);
  const [modalVisible, setModalVisible] = useState();
  const [initialSquadData, setInitialSquadData]: any = useState([]);
  // const [playersData, setPlayersData] = useState([]);
  const [finalPlayerData, setFinalPlayerData] = useState([]);
  const [finalCoachData, setFinalCoachData] = useState([]);
  const [selectedPlayersCount, setselectedPlayersCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingSquadData, setLoadingSquadData] = useState(false);
  const [selectedImageLogo, setSelectedImageLogo] = useState(null);

  const [successModalVisible2, setSuccessModalVisible2] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [successModalMesg2, setSuccessModalMesg2] = useState("");
  const [errorModalMesg, setErrorModalMesg] = useState("");

  const [teamName, setTeamName] = useState("");
  const [teamLogoUrl, setTeamLogoUrl] = useState("");
  const [teamShortName, setTeamShortName] = useState("");
  const [coachName, setCoachName] = useState("");
  const [coachMobileNo, setCoachMobileNo] = useState("");
  const bucketName = awsmobile.aws_user_files_s3_bucket;
  const [profilePicsArray, setProfilePicsArray] = useState([
    {
      Pic: null,
      UploadedPercentage: "",
      Url: "",
    },
  ]);

  const [allTeamsShortName, setAllTeamsShortName] = useState([]);

  const navigation: any = useNavigation();

  const { createAdminUser, getAdminUser } = useContext(AuthenticationContext);
  const defaultAvatar: any = require("../../../../../assets/Avtar/Avatar.png");

  let squadData = [];
  let coachesData = [];
  let coachesUserMaster = [];
  let playerUserMaster = [];
  let userDetails: any;

  const handleOk = () => {
    setSuccessModalVisible2(false);
    handleClose();
    navigation.navigate("TournamentDetails", {
      tournamentId: tournamentID,
    });
  };

  const handleOk2 = () => {
    setErrorModalVisible(false);
  };

  const handleInputPlayerChange = (field: string, event: any, idx: number) => {
    console.log("field:", field, "event:", event, "index:", idx);
    const updatedPlayerList = finalPlayerData.map((player, index) => {
      if (index === idx) {
        return { ...player, [field]: event };
      }
      return player;
    });
    console.log("updatedPlayerList:", updatedPlayerList);
    setFinalPlayerData(updatedPlayerList);
  };

  const handleInputTeamDetailsChange = (fieldName, value) => {
    switch (fieldName) {
      case "teamName":
        console.log("Team Name", value);
        setTeamName(value);
        break;
      case "teamShortName":
        console.log("Team ShortName", value);
        setTeamShortName(value);
        break;
      case "coachName":
        console.log("Coach Name", value);
        setCoachName(value);
        break;
      case "coachMobileNo":
        console.log("Coach Mobile No", value);
        setCoachMobileNo(value);
        break;
      default:
        break;
    }
  };

  const handleSetProfilePic = (e, field, index) => {
    console.log("targetvalue");
    console.log("We are in handle set PP");
    if (field === "Pic") {
      const { Pic, value } = e;
      const list = [...profilePicsArray];
      console.log("Pic : ", e);
      list[index].Pic = e;
      console.log("list", list);
      console.log("We are in Pic");
      setProfilePicsArray(list);
    }
    if (field === "UploadedPercentage") {
      const { UploadedPercentage, value } = e;
      const list = [...profilePicsArray];

      list[index].UploadedPercentage = e;
      console.log("list", list);
      console.log("We are in handle set Percentage");
      setProfilePicsArray(list);
    } else {
      const { Url, value } = e;
      const list = [...profilePicsArray];

      list[index].Url = e;
      console.log("list", list);
      console.log("We are in handle set Url");
      setProfilePicsArray(list);
    }
  };

  const handleImageSelection = (uri, kindOfFile, index) => {
    if (kindOfFile == "logo") {
      setTeamLogoUrl(uri);
      uploadLogoFileToStorage(uri);
    } else if (kindOfFile == "profilePic") {
      uploadProfilePicToStorage(uri, index);
    }
  };

  async function uploadLogoFileToStorage(uri) {
    try {
      const response = await fetch(uri);
      const blob = await response.blob();
      await uploadData({
        key: "team/" + teamName.replaceAll(" ", "_") + "/logo.png",
        data: blob,
      });

      let url =
        "https://trackmytournament-dev125444-dev.s3.ap-south-1.amazonaws.com/public/team/" +
        teamName.replaceAll(" ", "_") +
        "/logo.png";

      setTeamLogoUrl(url);

      // contentType: 'image/png' // contentType is optional
    } catch (err) {
      console.log("Error uploading file:", err);
    }
  }

  async function uploadProfilePicToStorage(uri, index) {
    const number = Math.floor(100000 + Math.random() * 900000)
      .toString()
      .substring(0, 4);
    try {
      const response = await fetch(uri);
      const blob = await response.blob();
      await uploadData({
        key: "userPhotos/" + number + ".jpg",
        data: blob,
        options: {
          onProgress: ({ transferredBytes, totalBytes }) => {
            if (totalBytes) {
              console.log(
                `Upload progress ${Math.round(
                  (transferredBytes / totalBytes) * 100
                )} %`
              );
            }
            let percentage = Math.round((transferredBytes / totalBytes) * 100);
            let url =
              `https://${bucketName}.s3.ap-south-1.amazonaws.com/public/userPhotos/` +
              number +
              ".jpg";
            console.log("Percentage : ", percentage);
            if (percentage === 100) {
              console.log("Percentage is 100");
              handleInputPlayerChange("PhotoURL", url, index);
            }
            handleSetProfilePic(percentage, "UploadedPercentage", index);
            handleSetProfilePic(url, "Url", index);
          },
        },
      });
    } catch (err) {
      console.log("Error uploading file:", err);
    }
  }

  const pickImageForLogo = async () => {
    // console.log("SelectedImage", selectedImage);
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (event) => {
      const inputElement = event.target as HTMLInputElement;
      const file = inputElement.files && inputElement.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const uri = e.target.result;
          const fileName = file.name;

          setSelectedImageLogo({ URL: uri, FileName: fileName });
          uploadLogoFileToStorage(uri);
          console.log("logo name:", fileName);
        };
        reader.readAsDataURL(file);
      } else {
        console.log("You did not select any image.");
        // setSuccessModalMesg("You did not select any image.");
      }
    };

    input.click();
  };

  const getSquadData = async () => {
    try {
      setLoadingSquadData(true);

      const subscription = DataStore.observeQuery(Squad, (c) =>
        c.id.eq(squadId)
      ).subscribe(async (snapshot) => {
        const initialSquads = snapshot.items;
        console.log("InitialSquads ", initialSquads);
        setInitialSquadData(initialSquads);

        if (initialSquads && initialSquads.length > 0) {
          setTeamName(initialSquads[0].Name);
          setTeamLogoUrl(initialSquads[0].Logo);
        }

        let teamPlayersDataPromises = initialSquads.map(async (data) =>
          data.TeamPlayers.toArray()
        );
        console.log("TeamPlayersDataPromises", teamPlayersDataPromises);
        let playersData = await Promise.all(teamPlayersDataPromises);
        console.log("PlayersData", playersData);
        //matchesData

        for (let i = 0; i < squadData.length; i++) {
          squadData[i].pop;
        }

        for (let i = 0; i < playersData.length; i++) {
          for (let j = 0; j < playersData[i].length; j++) {
            await getPlayersData(playersData[i][j].playerMasterId).then(
              (response) => {
                if (response) {
                  console.log("response", response);
                  squadData.push(response);
                }
              }
            );
          }
        }

        console.log("squadData : ", squadData);
        // setFinalPlayerData(squadData); //new code added

        const updatedSquadData = await Promise.all(
          squadData.map(async (data) => {
            // Await the PlayerData promise for each squad data
            const playerData = await data.PlayerData;

            // Extract ContactNo if playerData is defined
            const contactNo = playerData ? playerData.ContactNo : null;

            // Return updated squad data with ContactNo added
            return {
              ...data,
              ContactNo: contactNo,
              isSelected: false,
            };
          })
        );

        console.log("Updated squadData:", updatedSquadData);
        setFinalPlayerData(updatedSquadData);

        for (let i = 0; i < playerUserMaster.length; i++) {
          playerUserMaster[i].pop;
        }
        for (let i = 0; i < squadData.length; i++) {
          console.log("Ind" + i + squadData[i].playerMasterPlayerDataUUID);
          await getUserMasterData(squadData[i].playerMasterPlayerDataUUID).then(
            (response) => {
              if (response) {
                playerUserMaster.push(response);
              }
            }
          );
        }
        console.log("playerUserMaster", playerUserMaster);
        playerUserMaster = playerUserMaster.map((obj) => ({
          ...obj,
          isSelected: false,
        }));

        console.log("Team Data : ", squadData);
        console.log("Player Master : ", playerUserMaster);
        // setFinalPlayerData(playerUserMaster);

        let teamCoachDataPromises = initialSquads.map(async (data) =>
          data.TeamCoaches.toArray()
        );
        console.log("TeamCoachesDataPromises", teamCoachDataPromises);
        let coachData = await Promise.all(teamCoachDataPromises);
        console.log("CoachesData", coachData);

        for (let i = 0; i < coachesData.length; i++) {
          coachesData[i].pop;
        }

        for (let i = 0; i < coachData.length; i++) {
          for (let j = 0; j < coachData[i].length; j++) {
            await getCoachMasterData(coachData[i][j].coachMasterId).then(
              (response) => {
                if (response) {
                  coachesData.push(response);
                }
              }
            );
          }
        }

        console.log("Id : ", coachesData);
        for (let i = 0; i < coachesUserMaster.length; i++) {
          coachesUserMaster[i].pop;
        }
        for (let i = 0; i < coachesData.length; i++) {
          console.log("Ind" + i + coachesData[i].coachMasterCoachDataUUID);
          await getUserMasterData(coachesData[i].coachMasterCoachDataUUID).then(
            (response) => {
              if (response) {
                coachesUserMaster.push(response);
              }
            }
          );
        }
        console.log("Team Data 1: ", finalPlayerData);
        console.log("Coach Master Data : ", coachesUserMaster);
        setFinalCoachData(coachesUserMaster);

        if (coachesUserMaster && coachesUserMaster.length > 0) {
          setCoachName(coachesUserMaster[0].Name);
          setCoachMobileNo(coachesUserMaster[0].ContactNo.toString());
        }
      });
      return () => {
        subscription.unsubscribe();
      };
    } catch (error) {
      console.error("Error while fetching the data:", error);
      setLoadingSquadData(false);
    } finally {
      setLoadingSquadData(false); // Stop loading
    }
  };

  const getTeamsShortNameData = async () => {
    const teamsRegistration = DataStore.observeQuery(
      TeamRegistrations
    ).subscribe(async (snapshot) => {
      const initialTeam = snapshot.items;
      console.log("initialTeam", initialTeam);

      const getAllTeamsShortName = initialTeam.map(
        (data) => data.TeamShortName
      );
      console.log("getAllTeamsShortName", getAllTeamsShortName);
      setAllTeamsShortName(getAllTeamsShortName);
    });

    return () => {
      teamsRegistration.unsubscribe();
    };
  };

  const cognitoUserDetails = async (phoneNumber) => {
    const response = await getAdminUser(phoneNumber);
    if (response) {
      console.log("response", response);
    }

    return response;
  };

  const addUser = async (phoneNumber) => {
    let finalResponse;
    const userAttribute = [
      {
        Name: "phone_number",
        Value: phoneNumber,
      },
    ];

    const response = await createAdminUser(phoneNumber, userAttribute);

    console.log("CreateUserResponse : ", response);
    // finalResponse = data;
    //   }
    // );
    // console.log("Respa", finalResponse, "response", response);
    return response;
  };

  const handleSelectPlayerList = async (index) => {
    const updatedFinalPlayerData = [...finalPlayerData];
    console.log("updatedFinalPlayerData", updatedFinalPlayerData);
    updatedFinalPlayerData[index].isSelected =
      !updatedFinalPlayerData[index].isSelected;
    console.log("updatedFinalPlayerData", updatedFinalPlayerData);
    // Update the state with the new array
    setFinalPlayerData(updatedFinalPlayerData);

    const countSelected = finalPlayerData.filter(
      (item) => item.isSelected
    ).length;
    console.log("Selected ", countSelected);
    console.log("List : ", finalPlayerData);
    setselectedPlayersCount(countSelected);
  };

  useEffect(() => {
    console.log("visibleModal", visibleModal);
    console.log("Tournament Id :", tournamentID);
    console.log("squadId", squadId);
    console.log("userUUID", userUUID);
    console.log("successModalVisible", successModalVisible2);
    console.log("loading", loading);

    const getUserdata = async () => {
      userDetails = await AsyncStorage.getItem("UserDetail");
      console.log("userDetails", userDetails);
      if (userDetails) {
        console.log("userDetails", userDetails);
        // setUUID(userDetails);
      } else {
        console.log("No user found in temp storage");
      }
    };
    getUserdata();

    const fetchData = async () => {
      try {
        setLoading(true);
        await getSquadData();
        await getTeamsShortNameData();
      } catch (error) {
        console.error("Error while fetching the data:", error);
        setLoading(false);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchData();
  }, [squadId, userUUID, currentUserName, tournamentID]);

  const handleOnSubmit = async () => {
    try {
      setLoading(true); // Start loading

      let teamRegistrations,
        // teamName,
        // logo,
        teamRegistrationsPlayerMaster,
        coachUUID,
        userMasterCoach,
        coachSummary,
        admin1UserData;

      const playersSelected = finalPlayerData.filter((item) => item.isSelected);
      console.log("Selected Players : ", playersSelected);

      // for (let i = 0; i < initialSquadData.length; i++) {
      //   teamName = initialSquadData[i].Name;
      //   logo = initialSquadData[i].Logo;
      //   // teamShortName = initialSquadData[i].TeamShortName;
      // }

      //addCoachData

      const resCoach = await cognitoUserDetails("+91" + coachMobileNo);
      console.log(resCoach.statusCode);
      if (resCoach.statusCode === 400) {
        const resAddCoach = await addUser("+91" + coachMobileNo);
        coachUUID = resAddCoach?.User?.Username;
        console.log("coachUUID", coachUUID);
      } else {
        console.log("Coach Found data : ", resCoach);
        coachUUID = resCoach?.Username;
      }
      getUserMasterData(coachUUID).then(async (response) => {
        if (response) {
          console.log("Coach Data", response);
          userMasterCoach = response;
          if (userMasterCoach.userMasterCoachSummaryId == "") {
            console.log("We are in if Coach Summary");
            try {
              coachSummary = await DataStore.save(
                new CoachMaster({
                  Name: coachName.trim(),
                  Games: 0,
                  Won: 0,
                  Lost: 0,
                  Drawn: 0,
                })
              );
            } catch (err) {
              console.log("Error in saving coach summary : ", coachSummary);
              setLoading(false);
              setErrorModalVisible(true);
              setErrorModalMesg(
                `An error occurred while submitting the form ${err}`
              );
            }
          } else {
            console.log("We are in else of coach summary");
            await getCoachMasterData(
              userMasterCoach.userMasterCoachSummaryId
            ).then((value) => {
              coachSummary = value;
            });
          }
        } else {
          console.log("No coach data found");
          try {
            coachSummary = await DataStore.save(
              new CoachMaster({
                Name: coachName.trim(),
                Games: 0,
                Won: 0,
                Lost: 0,
                Drawn: 0,
              })
            );
            userMasterCoach = await DataStore.save(
              new UserMaster({
                UUID: coachUUID,
                Name: coachName.trim(),
                ContactNo: Number(coachMobileNo),
                EmailId: "",
                PhotoURL: "",
                CreatedBy: admin1UserData.Name,
                LastUpdatedBy: admin1UserData.Name,
                RoleCoach: true,
                RolePlayer: false,
                CoachSummary: coachSummary,
              })
            );
            const updateCoachUserMasterId = {
              coachMasterCoachDataUUID: userMasterCoach.UUID,
            };
            let updatedItem;
            const original = await DataStore.query(
              CoachMaster,
              userMasterCoach.userMasterCoachSummaryId
            );
            try {
              if (updateCoachUserMasterId) {
                console.log("Current Item : ", updateCoachUserMasterId);
                updatedItem = CoachMaster.copyOf(original, (ele) => {
                  ele.coachMasterCoachDataUUID =
                    updateCoachUserMasterId.coachMasterCoachDataUUID;
                });
                await DataStore.save(updatedItem);
                console.log("Updated Item:", updatedItem);
              } else {
                console.error("Item not found");
              }
              return updatedItem;
            } catch (error) {
              console.error("Error updating item:", error);
              setLoading(false);
              setErrorModalVisible(true);
              setErrorModalMesg(
                `An error occurred while submitting the form ${error}`
              );
            }
            console.log("UserMaster Coach : ", userMasterCoach);
          } catch (e) {
            console.log("Error : ", e);
            setLoading(false);
            setErrorModalVisible(true);
            setErrorModalMesg(
              `An error occurred while submitting the form ${e}`
            );
          }
        }
      });

      const coachObj = {
        UUID: coachUUID,
        Name: coachName.trim(),
        PhotoURL: "",
      };

      console.log("Coach Obj : ", coachObj);

      try {
        teamRegistrations = await DataStore.save(
          new TeamRegistrations({
            TeamName: teamName.trim(),
            Logo: teamLogoUrl,
            Group: "",
            Coach: coachObj,
            PaymentStatus: PaymentStatus.UNPAID,
            PaidAmount: 0,
            BalanceAmount: 0,
            TeamShortName: teamShortName.trim(),
            LastUpdatedBy: currentUserName,
            Documents: [],
            Tournament: tournamentID,
            Award: "",
            Status: RegistrationStatus.SUBMITTED,
            SquadId: squadId,
          })
        );
        console.log("New Team Reg : ", teamRegistrations);

        if (userDetails && teamRegistrations) {
          await DataStore.save(
            new UserMasterTeamRegistrations({
              userMasterUUID: userDetails,
              teamRegistrationsId: teamRegistrations.id,
            })
          );
        } else {
          console.log("No userDetails && teamRegistrations found");
        }

        for (let i = 0; i < playersSelected.length; i++) {
          try {
            teamRegistrationsPlayerMaster = await DataStore.save(
              new TeamRegistrationsPlayerMaster({
                teamRegistrationsId: teamRegistrations?.id,
                playerMasterId: playersSelected[i].id,
              })
            );
          } catch (error) {
            console.log(`Error occured while submitting the form in ${error}`);
            setLoading(false);
            setErrorModalVisible(true);
            setErrorModalMesg(
              `An error occurred while submitting the form ${error}`
            );
          }

          console.log(
            "TeamRegistrationsPlayerMaster : ",
            teamRegistrationsPlayerMaster
          );

          if (playersSelected[i].playerMasterPlayerDataUUID !== "") {
            try {
              await DataStore.save(
                new UserMasterTournament({
                  userMasterUUID: playersSelected[i].playerMasterPlayerDataUUID,
                  tournamentId: tournamentID,
                })
              );
            } catch (error) {
              console.log(
                `Error occured while submitting the form in ${error}`
              );
              setLoading(false);
              setErrorModalVisible(true);
              setErrorModalMesg(
                `An error occurred while submitting the form ${error}`
              );
            }
          }
        }

        if (allTeamsShortName.includes(teamRegistrations.TeamShortName)) {
          console.log("TeamShortName already existed");
          setErrorModalVisible(true);
          setErrorModalMesg(
            `TeamShortName '${teamRegistrations.TeamShortName}' already been taken by another team! Please enter another TeamShortName`
          );
          setLoading(false); // Stop loading
          return; // Prevent form submission
        } else {
          setErrorModalVisible(false);
        }

        // console.log("TEAM REGISTRATIONS DONE SUCCESSFULLY");
        // console.log("successModalVisible2 before", successModalVisible2);
        // setSuccessModalVisible2(true);
        // console.log("successModalVisible2 after", successModalVisible2);
        // setSuccessModalMesg2("Team submitted successfully");

        // alert("Team submitted successfully");
        // handleClose();
        // navigation.navigate("UpcomingTournamentDetails", {
        //   tournamentId: tournamentID,
        // });
      } catch (err) {
        console.log("Error handling submit: ", err);
        setLoading(false);
        setErrorModalVisible(true);
        setErrorModalMesg("Error occured while submitting the form");
      }

      // after execution

      console.log("TEAM REGISTRATIONS DONE SUCCESSFULLY");
      console.log("successModalVisible2 before", successModalVisible2);
      setSuccessModalVisible2(true);
      console.log("successModalVisible2 after", successModalVisible2);
      setSuccessModalMesg2("Team submitted successfully");
    } catch (error) {
      console.error("Error submitting form:", error);
      setLoading(false);
      setErrorModalVisible(true);
      setErrorModalMesg("An error occurred while submitting the form.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <View>
      {/* modal started */}
      <TouchableWithoutFeedback>
        <Modal
          animationType="slide"
          transparent={true}
          visible={visibleModal}
          onRequestClose={() => {}}
        >
          <View style={[styles.centeredView, { backgroundColor: "#04040491" }]}>
            <View style={styles.modalView}>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 15,
                  borderBottomWidth: 1,
                  borderBottomColor: colors.borderColor,
                  paddingBottom: 35,
                  marginBottom: 10,
                }}
              >
                <View>
                  <Pressable
                    onPress={pickImageForLogo}
                    style={styles.pressableContainer}
                  >
                    <View style={styles.circleContainer}>
                      <View style={styles.circle}>
                        <UploadImage
                          onImageSelect={handleImageSelection}
                          kindOfFile="logo"
                          imageUrl={teamLogoUrl}
                          index={0}
                        />
                      </View>
                    </View>
                    <Text
                      style={{
                        color: "red",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 12,
                        fontStyle: "italic",
                      }}
                    >
                      (Size 200 * 200)
                    </Text>
                  </Pressable>
                </View>

                <View style={{ gap: 10, flex: 1 }}>
                  <View style={styles.teamModalHeadingWrapper2}>
                    <View style={styles.teamModalHeadingWrapper}>
                      <Text style={styles.teamModalLabel}>Team Name</Text>
                      <TextInput
                        editable={true}
                        style={styles.TeamModalInput}
                        placeholder={"Enter Team Name"}
                        id="teamName"
                        value={teamName}
                        onChangeText={(text) => {
                          handleInputTeamDetailsChange("teamName", text);
                        }}
                      />
                    </View>
                    <View style={styles.teamModalHeadingWrapper}>
                      <Text style={styles.teamModalLabel}>Team Short Name</Text>
                      <TextInput
                        id="teamShortName"
                        editable={true}
                        style={styles.TeamModalInput}
                        placeholder={"Enter three characters"}
                        value={
                          teamShortName ? teamShortName.toUpperCase() : null
                        }
                        onChangeText={(text) => {
                          handleInputTeamDetailsChange("teamShortName", text);
                        }}
                        // onChange={handleChange}
                        // onBlur={handleBlur("teamShortName")}
                      />
                      {/* )} */}
                    </View>
                  </View>
                  <View style={styles.teamModalHeadingWrapper2}>
                    <View style={styles.teamModalHeadingWrapper}>
                      <Text style={styles.teamModalLabel}>Coach Name</Text>
                      <TextInput
                        style={styles.TeamModalInput}
                        placeholder={"Enter Coach Name"}
                        value={coachName}
                        editable={true}
                        id="coachName"
                        onChangeText={(text) =>
                          handleInputTeamDetailsChange("coachName", text)
                        }
                        inputMode="text"
                      />
                    </View>
                    <View style={styles.teamModalHeadingWrapper}>
                      <Text style={styles.teamModalLabel}>
                        Coach Mobile No.
                      </Text>
                      <TextInput
                        style={styles.TeamModalInput}
                        placeholder={"Enter coach mobile no"}
                        editable={true}
                        value={coachMobileNo}
                        id="coachMobileNo"
                        onChangeText={(text) =>
                          handleInputTeamDetailsChange("coachMobileNo", text)
                        }
                        inputMode="numeric"
                      />
                    </View>
                  </View>
                </View>
              </View>
              {screenWidth > 760 ? (
                <>
                  {/* table started here (WEB)*/}
                  <View
                    style={{
                      width: "100%",
                      marginTop: 15,
                    }}
                  >
                    <View
                      style={{
                        marginBottom: 15,
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View style={styles.topPlayerCount}>
                        <Text
                          style={[
                            styles.teamModalLabel,
                            { textAlign: "center" },
                          ]}
                        >
                          Players - ({selectedPlayersCount}/
                          {finalPlayerData.length})
                        </Text>
                      </View>
                    </View>
                    {/* table header started */}
                    <View style={styles.tableHeaderContainer}>
                      <View
                        style={{ paddingVertical: 7, padding: 5, width: 70 }}
                      >
                        <Text style={[styles.tableHeaderTitleText]}>
                          Select
                        </Text>
                      </View>
                      <View style={[styles.tableHeaderJTitle]}>
                        <Text style={styles.tableHeaderTitleText}>J No. </Text>
                      </View>
                      <View style={[styles.tableHeaderTitle]}>
                        <Text
                          style={[
                            styles.tableHeaderTitleText,
                            { textAlign: "left" },
                          ]}
                        >
                          Player
                        </Text>
                      </View>
                      <View style={[styles.tableHeaderTitle3]}>
                        <Text style={styles.tableHeaderTitleText}>
                          Date of Birth
                        </Text>
                      </View>
                      <View style={[styles.tableHeaderTitle3]}>
                        <Text style={styles.tableHeaderTitleText}>
                          Mobile No.
                        </Text>
                      </View>
                    </View>
                    {/* table header ended */}
                    <View
                      style={{
                        maxHeight: 0.4 * screenHeight,
                        overflow: "scroll",
                      }}
                    >
                      {/* table row started */}

                      {finalPlayerData && finalPlayerData.length != 0 ? (
                        finalPlayerData.map((data: any, index: any) => (
                          <View key={index} style={styles.tableDataContainer}>
                            <View style={styles.tableDataCellCheck}>
                              <Checkbox
                                status={
                                  data.isSelected ? "checked" : "unchecked"
                                }
                                onPress={() => handleSelectPlayerList(index)}
                                color={colors.aqua}
                                uncheckedColor={colors.aqua}
                              />
                            </View>
                            <View style={styles.tableDataCell2}>
                              <TextInput
                                style={styles.jerseyInput}
                                placeholder={"00"}
                                value={data.JerseyNo ? data.JerseyNo : null}
                              />
                            </View>
                            <View
                              style={[
                                styles.tableDataCell,
                                {
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                  gap: 5,
                                },
                              ]}
                            >
                              <Image
                                source={{
                                  uri: data.PhotoURL
                                    ? data.PhotoURL
                                    : defaultAvatar,
                                }}
                                style={{ width: 30, height: 30 }}
                                resizeMode="contain"
                              />
                              <Text style={styles.tableDataCellText}>
                                {data.Name ? data.Name : null}
                              </Text>
                            </View>
                            <View style={styles.tableDataCell3}>
                              <Text style={styles.tableDataCellText}>
                                {data.DateOfBirth ? data.DateOfBirth : null}
                              </Text>
                            </View>

                            <View style={styles.tableDataCell3}>
                              <Text style={styles.tableDataCellText}>
                                {data.ContactNo ? data.ContactNo : null}
                              </Text>
                            </View>
                          </View>
                        ))
                      ) : (
                        <Text>No data found</Text>
                      )}

                      {/* new row started */}
                    </View>
                  </View>
                  {/* table ended here (WEB)*/}
                </>
              ) : (
                <>
                  {/* mobile started here */}
                  <Text
                    style={{
                      color: colors.white,
                      fontFamily: "PlusJakartaSansRegular",
                      marginBottom: 5,
                    }}
                  >
                    Click on checkbox to select player from list
                  </Text>
                  <View style={{ gap: 10 }}>
                    {/*playerCard 1*/}
                    {finalPlayerData && finalPlayerData.length != 0 ? (
                      finalPlayerData.map((data: any, index: any) => (
                        <>
                          <View key={index} style={styles.playerCard}>
                            <View
                              style={{ position: "absolute", top: 0, left: 0 }}
                            >
                              <Checkbox
                                status={
                                  data.isSelected ? "checked" : "unchecked"
                                }
                                onPress={() => handleSelectPlayerList(index)}
                                color={colors.aqua}
                                uncheckedColor={colors.aqua}
                              />
                            </View>
                            <Image
                              source={{
                                uri: data.PhotoURL
                                  ? data.PhotoURL
                                  : defaultAvatar,
                              }}
                              style={{
                                width: 80,
                                height: 80,
                                borderRadius: 80,
                              }}
                              resizeMode="contain"
                            />

                            <View
                              style={{
                                flexDirection: "column",
                                gap: 5,
                                flex: 1,
                                paddingLeft: 15,
                              }}
                            >
                              <Text style={styles.playerMobText}>
                                {data.Name ? data.Name : null}
                              </Text>
                              <Text style={styles.playerMobText}>
                                DOB-{" "}
                                {data.DateOfBirth ? data.DateOfBirth : null}
                              </Text>
                              <View
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Text style={styles.playerMobText}>
                                  Mob- {data.ContactNo ? data.ContactNo : null}
                                </Text>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <Text style={styles.playerMobText}>
                                    Jersey-
                                  </Text>
                                  <TextInput
                                    style={styles.jerseyInput}
                                    placeholder={"00"}
                                    value={data.JerseyNo ? data.JerseyNo : null}
                                  />
                                </View>
                              </View>
                            </View>
                          </View>
                        </>
                      ))
                    ) : (
                      <Text>No data found</Text>
                    )}
                  </View>
                  {/* mobile ended here */}
                </>
              )}

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: 20,
                  marginTop: 15,
                }}
              >
                <Pressable
                  style={[
                    styles.btnPressable,
                    { borderWidth: 1.5, borderColor: colors.white },
                  ]}
                  onPress={() => handleClose()}
                >
                  <Text style={styles.cancelBtnText}>Cancel</Text>
                </Pressable>
                <Pressable
                  onPress={handleOnSubmit}
                  style={[
                    styles.btnPressable,
                    {
                      backgroundColor: colors.aqua,
                      borderWidth: 1.5,
                      borderColor: colors.aqua,
                    },
                  ]}
                >
                  <Text style={styles.btnText}>Submit</Text>
                </Pressable>
              </View>
            </View>
          </View>
        </Modal>
      </TouchableWithoutFeedback>
      {/* modal ended */}

      {successModalVisible2 === true ? (
        <ModalTrophy
          visibleModal={successModalVisible2}
          SuccessMessage={successModalMesg2}
          handleOk={handleOk}
        />
      ) : null}

      {errorModalVisible == true ? (
        <ErrorModal
          visibleModal={errorModalVisible}
          SuccessMessage={errorModalMesg}
          header={undefined}
          onSubmit={handleOk2}
        />
      ) : null}

      {/* Loader */}
      {loading ||
        (loadingSquadData && (
          <Modal transparent animationType="none">
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <ActivityIndicator size="large" color="#0000ff" />
              {loading ? <Text>Submitting</Text> : <Text>Loading</Text>}
            </View>
          </Modal>
        ))}
    </View>
  );
};
const styles = StyleSheet.create({
  //
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    width: Platform.OS === "web" && screenWidth > 760 ? 700 : "95%",
    overflow: screenWidth > 760 ? undefined : "scroll",
    maxHeight: 0.95 * screenHeight,
    margin: 20,
    backgroundColor: colors.bgColor,
    borderRadius: 5,
    padding: 15,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    position: "relative",
  },
  //
  scoreTeamLogo: {
    width: Platform.OS === "web" && screenWidth > 760 ? 100 : 60,
    height: Platform.OS === "web" && screenWidth > 760 ? 100 : 60,
    borderRadius: 50,
    resizeMode: "contain",
  },
  teamModalHeadingWrapper2: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 10,
    flexWrap: "wrap",
  },
  teamModalHeadingWrapper: {
    flex: 1,
    minWidth: screenWidth > 760 ? "45%" : "100%",
    maxWidth: screenWidth > 760 ? "100%" : "100%",
  },
  teamModalLabel: {
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 16 : 12,
    fontFamily: "PlusJakartaSansSemiBold",
    color: colors.white,
  },
  teamModalHeadingText: {
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 14 : 10,
    fontFamily: "PlusJakartaSansRegular",
    color: colors.white,
  },
  //   table
  tableHeaderContainer: {
    backgroundColor: colors.blue,
    flexDirection: "row",
    paddingRight: 15,
    marginBottom: 5,
  },
  tableHeaderTitle: {
    flex: 1,
    paddingVertical: 7,
    padding: 5,
  },
  tableHeaderTitle3: {
    flex: 1,
    maxWidth: 140,
    paddingVertical: 7,
    padding: 5,
  },

  tableHeaderJTitle: {
    width: 60,
    paddingVertical: 7,
    padding: 5,
  },
  tableHeaderTitleText: {
    fontFamily: "PlusJakartaSansBold",
    fontSize: 14,
    color: colors.white,
    textAlign: "center",
  },
  tableDataContainer: {
    backgroundColor: colors.royalBlue,
    flexDirection: "row",
    marginTop: 5,
    borderRadius: 5,
  },

  tableDataCell: {
    flex: 1,
    paddingVertical: 5,
    paddingHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  tableDataCell3: {
    flex: 1,
    maxWidth: 140,
    paddingVertical: 5,
    paddingHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  tableDataCellCheck: {
    width: 70,
    // paddingVertical: 5,
    // paddingHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  scoreSheetDeleteBtn: {
    width: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  tableDataCell2: {
    width: 60,
    paddingVertical: 5,
    paddingHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  tableDataCellText: {
    textAlign: "center",
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 14,
    color: colors.white,
  },
  //   table ended
  btnText: {
    textAlign: "center",
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 14,
    color: colors.royalBlue,
  },
  cancelBtnText: {
    textAlign: "center",
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 14,
    color: colors.white,
  },
  btnPressable: {
    width: 150,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },
  topPlayerCount: {
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    padding: 5,
    borderRadius: 5,
    width: Platform.OS === "web" && screenWidth > 760 ? 150 : 110,
    alignContent: "center",
    justifyContent: "center",
    backgroundColor: colors.royalBlue,
  },
  mobTeamPlayer: {
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    padding: 5,
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    alignItems: "center",
    borderRadius: 5,
  },
  addTeamBtn: {
    backgroundColor: "#FF5733",
    flexDirection: "row",
    gap: 5,
    alignItems: "center",
    justifyContent: "center",
    width: 100,
    borderRadius: 5,
    padding: 4,
  },
  jerseyInput: {
    width: 50,
    height: 30,
    borderWidth: 1.5,
    borderColor: colors.white,
    color: colors.white,
    borderRadius: 7,
    textAlign: "center",
  },
  TeamModalInput: {
    borderColor: colors.white,
    color: colors.white,
    backgroundColor: colors.royalBlue,
    borderWidth: 1,
    height: 30,
    width: "100%",
    borderRadius: 5,
    paddingHorizontal: 5,
    fontFamily: "PlusJakartaSansRegular",
  },
  pressableContainer: {
    width: 150,
    height: 150,
  },
  circleContainer: {
    width: 150,
    height: 150,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: colors.bgColor,
    padding: 5,
  },
  circle: {
    width: "100%",
    height: "100%",
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.bgColor,
  },
  //
  playerCard: {
    flexDirection: "row",
    width: "100%",
    backgroundColor: colors.royalBlue,
    padding: 15,
    borderRadius: 10,
    shadowOffset: {
      width: 3,
      height: 4,
    },
    shadowColor: "#000000",
    shadowOpacity: 0.22,
    shadowRadius: 10,
    elevation: 3,
    alignItems: "center",
    justifyContent: "flex-start",
    position: "relative",
    paddingLeft: 20,
  },
  playerMobText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 14,
  },
});
export default SelectTeamModal;
