import { DataStore } from "aws-amplify/datastore";
import React, { useState, useRef, useEffect } from "react";
import {
  View,
  ScrollView,
  Image,
  FlatList,
  StyleSheet,
  Pressable,
  Dimensions,
  Platform,
  Text,
  Modal,
  ActivityIndicator,
} from "react-native";
import { PanGestureHandler, State } from "react-native-gesture-handler";
import Icon from "react-native-vector-icons/FontAwesome";
import { Tournament } from "../../../models";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { colors } from "../../../components/reusable/colour";
const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

const { width } = Dimensions.get("window");

const Gallery = ({ tournamentId }) => {
  const [galleryData, setGalleryData]: any = useState();
  const [loading, setLoading] = useState(false);

  const handleAsyncPropsfromTournamentCard = async () => {
    try {
      setLoading(true);
      const tournamentDetailsProps = await AsyncStorage.getItem(
        "tournamentProps"
      );
      const tournamentDetails = JSON.parse(tournamentDetailsProps);
      console.log("tournamentDetails", tournamentDetails);
      // observequery

      const subscription = DataStore.observeQuery(Tournament, (c) =>
        c.id.eq(tournamentId)
      ).subscribe(async (snapshot) => {
        const initialTournament = snapshot.items;
        console.log("initialTournament", initialTournament);

        const galleryData = initialTournament.map(async (data) => data.Gallery);
        const galleryPromise = await Promise.all(galleryData);
        const flatGalleryData = galleryPromise.flat();
        console.log("flatGalleryData", flatGalleryData);
        setGalleryData(flatGalleryData);
        setLoading(false);
      });
      return () => {
        subscription.unsubscribe();
      };
    } catch (error) {
      setLoading(false);
      console.log("error occured while fetching the data", error);
    }
  };

  useEffect(() => {
    handleAsyncPropsfromTournamentCard();
  }, [tournamentId]);

  return (
    <>
      {/* {Platform.OS === "web" && screenWidth > 760 ? ( */}
      <View style={stylesByAkash.galleryContainer}>
        {galleryData && galleryData.length > 0 ? (
          galleryData.map((image: any, index: any) => (
            <View key={index} style={stylesByAkash.galleryItem}>
              <Image
                source={{
                  uri: image ? image : null,
                }}
                style={stylesByAkash.galleryItemImage}
              />
            </View>
          ))
        ) : (
          <View style={{ alignItems: "center", width: "100%" }}>
            <Image
              source={require("../../../../assets/noData/gallery.png")}
              style={stylesByAkash.noDataImage}
              resizeMode="contain"
            />
            <Text style={stylesByAkash.noDataText}>
              Hang on! No images uploaded yet !
            </Text>
          </View>
        )}
      </View>

      {/* loader */}
      {loading ? (
        <Modal transparent animationType="none">
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <ActivityIndicator size="large" color="#0000ff" />
            <Text style={{ color: "white" }}>Loading</Text>
          </View>
        </Modal>
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  imageContainer: {
    width: "100%",
  },

  scrollableImage: {
    width: Platform.OS == "web" ? 1000 : 390,
    height: 400,
    borderRadius: 8,
    resizeMode: "cover",
  },
  gridImageContainer: {
    flex: 1,
    margin: 4,
    width: width / 2 - 8,
    height: 150,
  },
  gridImage: {
    flex: 1,
    borderRadius: 8,
  },
});

const stylesByAkash = StyleSheet.create({
  galleryContainer: {
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 15,
    justifyContent: "space-between",
  },
  galleryItem: {
    flex: 1,
    maxWidth: "50%",
    minWidth: "40%",
    borderColor: colors.white,
    borderWidth: 1,
  },
  galleryItemImage: {
    width: "100%",
    aspectRatio: 3 / 2,
  },
  noDataImage: {
    width: 270,
    height: 270,
  },
  noDataText: {
    fontFamily: "PlusJakartaSansSemiBold",
    color: colors.white,
    fontSize: 20,
    maxWidth: 400,
    textAlign: "center",
  },
});

export default Gallery;
