import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import React, { useState, useEffect, useContext } from "react";
import { Text, View, ActivityIndicator } from "react-native";
import { colors } from "../components/reusable/colour";
import Navbar from "../components/include/Navbar";
import AllTournament from "../screens/view/competition/AllTournament";
import SignIn from "../screens/view/Login/SignIn";
import SignUp from "../screens/view/Login/SignUp";
import OTPVerification from "../screens/view/Login/OTPVerification";
import { firebaseConfig } from "../../src/firebaseDetails";
import { CommonActions, useNavigation } from "@react-navigation/native";
import { Linking } from "react-native";
import { UserMaster, CoachMaster } from "../models";
// import * as Linking from "expo-linking";
import Home from "../screens/view/Home/Home";
import About from "../screens/view/About";
import Matches from "../screens/view/Home/SubComp/Matches";
import { DataStore } from "aws-amplify/datastore";
import Dashboard from "../screens/adminView/Dashboard/Dashboard";
import TournamentCard from "../screens/view/Home/SubComp/TournamentCard";
import ManageTournament from "../screens/adminView/Tournament/ManageTournament";
import TeamParticipationForm from "../screens/adminView/tournamentAdmin/TeamAdmin/TeamParticipationForm";
import UpcomingTournamentDetails from "../screens/view/competition/UpcomingTournamentDetails";
import Standing from "../screens/view/competition/Standing";
import Fixtures from "../screens/view/competition/Fixtures";
import StartMatch from "../screens/adminView/ScoringApp/StartMatch";
import ScoringApp from "../screens/adminView/ScoringApp/ScoringApp";
import TournamentDetails from "../screens/view/competition/TournamentDetails";
import MyTeams from "../screens/adminView/Teams/MyTeams";
import SelectTeam from "../screens/view/competition/SubComp/SelectTeam";
import Results from "../screens/adminView/Results/Results";
import ResultDetails from "../screens/adminView/Results/ResultDetails";
import MyProfile from "../screens/adminView/MyProfile/MyProfile";
import MyTournament from "../screens/adminView/MyTournament/MyTournament";
import MyMatches from "../screens/adminView/MyMatches/MyMatches";
import AddTeamMob from "../screens/adminView/Teams/SubComp/AddTeamMob";
import Features from "../screens/view/Features/Features";
import AllMatches from "../screens/view/Home/SubComp/AllMatches";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Teams from "../screens/view/competition/Teams";
import TeamsPlayers from "../screens/view/competition/TeamsPlayers";
import EditTeamMob from "../screens/adminView/Teams/SubComp/EditTeamMob";

import Fixture from "../screens/adminView/Fixture/Fixture";
import Settings from "../screens/adminView/Tournament/SubComp/Settings";
import CreateTournament from "../screens/view/competition/CreateTournament/CreateTournament";
import PlayerRegistration from "../screens/view/competition/SubComp/PlayerRegistration";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { AuthenticationContext } from "../authentication/Authentication";

const Stack = createStackNavigator();

// Your existing component code
// const prefix = Linking.createURL("/");

// Add a listener for the 'url' event

const AppNavigator = () => {
  const { getAuthenticatedUser, setIsLoggedIn, isLoggedIn } = useContext(
    AuthenticationContext
  );
  const [initialRoute, setInitialRoute] = useState("Home");
  const [isLoading, setIsLoading] = useState(true);
  const [tournamentId, setTournamentId] = useState("");
  const [teamId, setTeamId] = useState("");
  const dynamicHomeRoute = window.location.origin.includes("yuvha")
    ? "AllTournament"
    : "Home";

  const linking = {
    prefixes: [
      // prefix,
      "http://localhost:19006/",
      "https://www.trackmytournament.com/",
      "https://main.d2webtax1x688a.amplifyapp.com/",
    ], // Specify your URL prefixes here if needed
    config: {
      screens: {
        // Define your Home route here
        Home: {
          path: "/",
        },
        AllTournament: {
          path: "AllTournament/:level",
          parse: {
            level: (level) => level,
          },
        },
        About: {
          path: "About",
        },
        Matches: {
          path: "Matches",
        },
        CreateTournament: {
          path: "CreateTournament",
        },
        MyTournament: {
          path: "MyTournament",
        },
        MyMatches: {
          path: "MyMatches",
        },
        SignIn: {
          path: "SignIn",
        },
        CongratulationsPage: {
          path: "CongratulationsPage",
        },
        SignUp: {
          path: "SignUp",
        },
        Features: {
          path: "Features",
        },
        OTPVerification: {
          path: "OTPVerification",
        },
        Dashboard: {
          path: "Dashboard",
        },
        ManageTournament: {
          path: "ManageTournament",
        },
        ScoringApp: {
          path: "ScoringApp",
        },
        MyTeams: {
          path: "MyTeams",
        },
        MyProfile: {
          path: "MyProfile",
        },
        SelectTeam: {
          path: "SelectTeam",
        },
        StartMatch: {
          path: "StartMatch",
        },
        TournamentCard: {
          path: "TournamentCard",
        },
        TeamParticipationForm: {
          path: "TeamParticipationForm",
        },
        TournamentDetails: {
          path: "TournamentDetails/:tournamentId",
          parse: {
            tournamentId: (tournamentId) => tournamentId,
          },
        },
        PlayerRegistration: {
          path: "PlayerRegistration/tournamentId/:tournamentId/teamId/:teamId",
          parse: {
            tournamentId: (tournamentId) => tournamentId,
            teamId: (teamId) => teamId,
          },
        },
        // PlayerRegistration: {
        //   path: "PlayerRegistration",
        // },

        AllMatches: {
          path: "AllMatches",
        },
        Teams: {
          path: "Teams",
        },
        TeamsPlayers: {
          path: "TeamsPlayers",
        },

        Fixture: {
          path: "FixtureAdmin",
        },
        Settings: {
          path: "SettingsAdmin",
        },
        TextField: {
          path: "TextField",
        },

        NotFound: "*",
      },
    },
  };
  const handleDeepLink = async (url) => {
    // Use the linking configuration to match the URL
    console.log("inside handle deep link", url);
    const { state, routeNames, routes } = Linking.parsePath(
      url,
      linking.config
    );
    console.log("url", url);
    console.log("AppNavigator - ", state, routeNames, routes);
    if (state) {
      // If there's a match, construct the navigation state
      const navigationState = {
        index: routeNames.indexOf(state.routeName),
        routes: routes.map((route) => ({
          name: route.routeName,
          params: route.params,
        })),
      };

      // Get the navigation object for the current tab
      const navigation = useNavigation(); // You can use the useNavigation hook here

      // Use the navigation state to navigate within the current tab
      navigation.dispatch({
        ...CommonActions.reset({
          ...navigationState,
        }),
        source: url,
      });
    } else {
      // Handle invalid URLs here
    }
  };
  const firebaseInitialize = async () => {
    console.log("firebaseInitialize", firebaseConfig);
    const myApp = await initializeApp(firebaseConfig);
    const analytics = getAnalytics(myApp);
    console.log("analytics", analytics);
  };

  const saveData = async (user) => {
    const userUUID = user.username;
    const userFirstName = user.session?.idToken?.payload.given_name
      ? user.session?.idToken?.payload.given_name
      : "";
    const userLastName = user.session?.idToken?.payload.family_name
      ? user.session?.idToken?.payload.family_name
      : "";

    let contactNo = 0;
    if (userUUID.includes("google")) {
      contactNo = 9999911111;
    } else if (userUUID.includes("apple")) {
      contactNo = 9999922222;
    }
    const userEmail = user.session?.idToken?.payload.email
      ? user.session?.idToken?.payload.email
      : "";
    const photoUrl = user.session?.idToken?.payload.picture
      ? user.session?.idToken?.payload.picture
      : "";
    try {
      const coachSummary = await DataStore.save(
        new CoachMaster({
          Games: 0,
          Won: 0,
          Lost: 0,
          Drawn: 0,
        })
      );
      if (coachSummary) {
        const userMaster = await DataStore.save(
          new UserMaster({
            UUID: userUUID,
            Name: userFirstName + " " + userLastName,
            ContactNo: contactNo,
            EmailId: userEmail,
            PhotoURL: photoUrl,
            CreatedBy: userFirstName + " " + userLastName,
            LastUpdatedBy: userFirstName + " " + userLastName,
            RoleCoach: true,
            RolePlayer: true,
            CoachSummary: coachSummary,
          })
        );
      }
    } catch (error) {
      console.log("Error occurred", error);
    }
  };

  const checkSocialSignIn = async () => {
    console.log("Inside check social signin");
    try {
      const user = await getAuthenticatedUser();

      console.log("User details on 254", user);
      if (user) {
        // First check if the user with same UUID already exists:
        const userWithSameUUID = await DataStore.query(
          UserMaster,
          user.username
        );
        console.log("UserData after OTP", userWithSameUUID);
        if (userWithSameUUID) {
          console.log("User with Same UUID found:", userWithSameUUID);

          await AsyncStorage.setItem("UserDetail", userWithSameUUID.UUID);
        } else {
          console.log(
            "User with Same UUID not found...checking for same email"
          );
          // Then check if the user with same Email id already exists:
          const userEmail = user.session?.idToken?.payload.email
            ? user.session?.idToken?.payload.email
            : "";
          console.log("User email :", userEmail);
          const userWithSameEmail = await DataStore.query(UserMaster, (user) =>
            user.EmailId.eq(userEmail)
          );
          if (userWithSameEmail && userWithSameEmail.length > 0) {
            console.log("User with Same Email found:", userWithSameEmail[0]);
            //update user picture if not present
            const photoUrl = user.session?.idToken?.payload.picture
              ? user.session?.idToken?.payload.picture
              : "";
            if (!userWithSameEmail[0].PhotoURL > "") {
              console.log("User does not have picture");
              const updatedUser = UserMaster.copyOf(
                userWithSameEmail[0],
                (updated) => {
                  updated.PhotoURL = photoUrl;
                  return updated;
                }
              );
              console.log("updatedUser", updatedUser);
              const updateUserRes = await DataStore.save(updatedUser);
            }
            await AsyncStorage.setItem("UserDetail", userWithSameEmail[0].UUID);
          } else {
            console.log(
              "User with Same Email not found...adding as a new user"
            );
            // Otherwise add user
            await saveData(user);
          }
        }
        setIsLoggedIn(true);
        const url = await AsyncStorage.getItem("pageUrl");
        const tournamentID = await AsyncStorage.getItem("TournamentId");
        setTournamentId(tournamentID);
        const teamID = await AsyncStorage.getItem("TeamId");
        setTeamId(teamID);

        console.log("for player registration: ", url, tournamentId, teamId);
        if (url && tournamentID && teamID) {
          setInitialRoute("PlayerRegistration");
        } else {
          setInitialRoute("Dashboard");
        }
      }

      setIsLoading(false); // Set loading to false if already logged in
    } catch (error) {
      console.log("Some error happened", error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    console.log("AppNavigator isLogged In", isLoggedIn);
    console.log("initialRoute", initialRoute);
    const checkLogins = async () => {
      if (!isLoggedIn) {
        await checkSocialSignIn();
      } else {
        setIsLoading(false); // Set loading to false if already logged in
      }
    };

    checkLogins();
    // setIsSocialSignInChecked(true);
    console.log("before firebaseinitialize");
    firebaseInitialize();
    console.log("after firebaseinitialize");
    const urlListener = Linking.addEventListener("url", ({ url }) => {
      console.log("inside urlListener");
      handleDeepLink(url);
    });

    return () => {
      urlListener.remove();
    };
  }, [isLoggedIn]);

  if (isLoading) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          // backgroundColor: ,
        }}
      >
        <ActivityIndicator size="large" color={colors.blue} />
        <Text style={{ color: colors.white }}>Loading</Text>
      </View>
    ); // Render a loading indicator while checking social sign-in
  }
  return (
    <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
      <Stack.Navigator
        initialRouteName={isLoggedIn == true ? dynamicHomeRoute : initialRoute}
      >
        {/* Screens accessible to everyone */}
        <Stack.Screen
          name="Home"
          component={Home}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="AllTournament"
          component={AllTournament}
          initialParams={{
            level: "All",
          }}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="About"
          component={About}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Matches"
          component={Matches}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="NavBar"
          component={Navbar}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="TournamentDetails"
          component={TournamentDetails}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="TournamentCard"
          component={TournamentCard}
          options={{
            headerShown: false,
          }}
        />

        <Stack.Screen
          name="UpcomingTournamentDetails"
          component={UpcomingTournamentDetails}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Standing"
          component={Standing}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Fixtures"
          component={Fixtures}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Results"
          component={Results}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="ResultDetails"
          component={ResultDetails}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Features"
          component={Features}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="AllMatches"
          component={AllMatches}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Teams"
          component={Teams}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="PlayerRegistration"
          component={PlayerRegistration}
          initialParams={{
            tournamentId: tournamentId,
            teamId: teamId,
          }}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="TeamsPlayers"
          component={TeamsPlayers}
          options={{
            headerShown: false,
          }}
        />

        <Stack.Screen
          name="SignIn"
          component={SignIn}
          initialParams={{
            data: {
              prevPage: "Homepage",
              nextPage: "OTPVerification",
              userId: "",
            },
          }}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="SignUp"
          component={SignUp}
          options={{
            headerShown: false,
          }}
          initialParams={{
            data: {
              prevPage: "Homepage",
              nextPage: "OTPVerification",
              userId: "",
            },
          }}
        />
        <Stack.Screen
          name="OTPVerification"
          component={OTPVerification}
          options={{
            headerShown: false,
          }}
        />

        {/* Screens accessible to logged in users */}
        {isLoggedIn && (
          <>
            <Stack.Screen
              name="Dashboard"
              component={Dashboard}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="MyProfile"
              component={MyProfile}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="MyTournament"
              component={MyTournament}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="MyMatches"
              component={MyMatches}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="CreateTournament"
              component={CreateTournament}
              options={{
                headerShown: false,
              }}
            />

            <Stack.Screen
              name="ManageTournament"
              component={ManageTournament}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="ScoringApp"
              component={ScoringApp}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="MyTeams"
              component={MyTeams}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="SelectTeam"
              component={SelectTeam}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="StartMatch"
              component={StartMatch}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="TeamParticipationForm"
              component={TeamParticipationForm}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="AddTeamMob"
              component={AddTeamMob}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="EditTeamMob"
              component={EditTeamMob}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="FixtureAdmin"
              component={Fixture}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen
              name="SettingsAdmin"
              component={Settings}
              options={{
                headerShown: false,
              }}
            />
          </>
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default AppNavigator;
