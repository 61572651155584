import React, { useState, useEffect, useContext } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { NavigationContainer } from "@react-navigation/native";

import BottomMenu from "../components/include/BottomMenu";
import MyProfile from "../screens/adminView/MyProfile/MyProfile";
import OTPVerification from "../screens/view/Login/OTPVerification";
import SignIn from "../screens/view/Login/SignIn";
import SignUp from "../screens/view/Login/SignUp";
import MyMatches from "../screens/adminView/MyMatches/MyMatches";
import MyTournament from "../screens/adminView/MyTournament/MyTournament";
import MyTeams from "../screens/adminView/Teams/MyTeams";
import Dashboard from "../screens/adminView/Dashboard/Dashboard";
import AllTournament from "../screens/view/competition/AllTournament";
import ManageTournament from "../screens/adminView/Tournament/ManageTournament";
import Matches from "../screens/view/Home/SubComp/Matches";
import AllMatches from "../screens/view/Home/SubComp/AllMatches";
import Teams from "../screens/view/competition/Teams";
import TeamsPlayers from "../screens/view/competition/TeamsPlayers";
import TournamentDetails from "../screens/view/competition/TournamentDetails";
import TournamentCard from "../screens/view/Home/SubComp/TournamentCard";
import ScoringApp from "../screens/adminView/ScoringApp/ScoringApp";
import SelectTeam from "../screens/view/competition/SubComp/SelectTeam";
import StartMatch from "../screens/adminView/ScoringApp/StartMatch";
import TeamParticipationForm from "../screens/adminView/tournamentAdmin/TeamAdmin/TeamParticipationForm";
import UpcomingTournamentDetails from "../screens/view/competition/UpcomingTournamentDetails";
import Standing from "../screens/view/competition/Standing";
import Fixtures from "../screens/view/competition/Fixtures";
import Results from "../screens/adminView/Results/Results";
import ResultDetails from "../screens/adminView/Results/ResultDetails";
import AddTeamMob from "../screens/adminView/Teams/SubComp/AddTeamMob";
import CreateTournament from "../screens/view/competition/CreateTournament/CreateTournament";
import OnboardingScreen from "../screens/view/Login/OnboardingScreens";
import EditTeamMob from "../screens/adminView/Teams/SubComp/EditTeamMob";
import { AuthenticationContext } from "../authentication/Authentication";

const Stack = createStackNavigator();

const MobNavigator = () => {
  const [hasSeenOnboarding, setHasSeenOnboarding] = useState(null);
  const { isLoggedIn } = useContext(AuthenticationContext);

  useEffect(() => {
    const checkOnboardingStatus = async () => {
      try {
        const value = await AsyncStorage.getItem("hasSeenOnboarding");
        setHasSeenOnboarding(value === "true");
      } catch (error) {
        console.error("Error getting onboarding status: ", error);
        setHasSeenOnboarding(false);
      }
    };
    checkOnboardingStatus();
  }, []);

  const handleOnboardingDone = async () => {
    try {
      await AsyncStorage.setItem("hasSeenOnboarding", "true");
      setHasSeenOnboarding(true);
    } catch (error) {
      console.error("Error setting onboarding status: ", error);
    }
  };
  const handleOnboardingSkip = async () => {
    try {
      await AsyncStorage.setItem("hasSeenOnboarding", "true");
      setHasSeenOnboarding(true);
    } catch (error) {
      console.error("Error setting onboarding status: ", error);
    }
  };

  if (hasSeenOnboarding === null) {
    return null;
  }

  return (
    <NavigationContainer>
      <Stack.Navigator
        initialRouteName={
          !hasSeenOnboarding
            ? "OnboardingScreen"
            : isLoggedIn
            ? "BottomMenu"
            : "SignIn"
        }
      >
        {!hasSeenOnboarding && (
          <Stack.Screen
            name="OnboardingScreen"
            component={(props) => (
              <OnboardingScreen
                {...props}
                onDone={handleOnboardingDone}
                onSkip={handleOnboardingSkip}
              />
            )}
            options={{ headerShown: false }}
          />
        )}
        {!isLoggedIn && hasSeenOnboarding && (
          <>
            <Stack.Screen
              name="SignIn"
              component={SignIn}
              options={{ headerShown: false }}
              initialParams={{
                data: {
                  prevPage: "Homepage",
                  nextPage: "OTPVerification",
                  userId: "",
                },
              }}
            />
            <Stack.Screen
              name="SignUp"
              component={SignUp}
              options={{ headerShown: false }}
              initialParams={{
                data: {
                  prevPage: "Homepage",
                  nextPage: "OTPVerification",
                  userId: "",
                },
              }}
            />
            <Stack.Screen
              name="OTPVerification"
              component={OTPVerification}
              options={{ headerShown: false }}
            />
          </>
        )}
        <Stack.Screen
          name="BottomMenu"
          component={BottomMenu}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Dashboard"
          component={Dashboard}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="MyProfile"
          component={MyProfile}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="MyMatches"
          component={MyMatches}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="MyTournament"
          component={MyTournament}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="MyTeams"
          component={MyTeams}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="AllTournament"
          component={AllTournament}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="CreateTournament"
          component={CreateTournament}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="ManageTournament"
          component={ManageTournament}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Matches"
          component={Matches}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="AllMatches"
          component={AllMatches}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Teams"
          component={Teams}
          options={{
            headerShown: false,
          }}
        />

        <Stack.Screen
          name="TeamsPlayers"
          component={TeamsPlayers}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="TournamentDetails"
          component={TournamentDetails}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="TournamentCard"
          component={TournamentCard}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="ScoringApp"
          component={ScoringApp}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="SelectTeam"
          component={SelectTeam}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="StartMatch"
          component={StartMatch}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="TeamParticipationForm"
          component={TeamParticipationForm}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="UpcomingTournamentDetails"
          component={UpcomingTournamentDetails}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Standing"
          component={Standing}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Fixtures"
          component={Fixtures}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Results"
          component={Results}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="ResultDetails"
          component={ResultDetails}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="AddTeamMob"
          component={AddTeamMob}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="EditTeamMob"
          component={EditTeamMob}
          options={{
            headerShown: false,
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default MobNavigator;
