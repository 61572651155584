import React, { useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Pressable,
  Platform,
  Dimensions,
} from "react-native";
import { colors } from "../../../../components/reusable/colour";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import { getAuthenticatedUser } from "../../../../api/AmplifyUserAPI";
import { DataStore } from "@aws-amplify/datastore";
import { UserMaster } from "../../../../models";
const screenWidth = Dimensions.get("window").width;

const FeaturesTopSection = () => {
  const navigation: any = useNavigation();
  const [hoverIn, setHoverIn] = useState(false);

  const handleHoverIn = () => {
    setHoverIn(true);
  };
  const handleHoverOut = () => {
    setHoverIn(false);
  };

  const handleCreateTournament = async () => {
    let Response = await getAuthenticatedUser();
    if (Response && Object.keys(Response).length > 0) {
      console.log("ResponseFinal", Response);
      if (Response.isLoggedIn == true) {
        console.log("isLoginTrue", Response.isLoggedIn);

        const id = Response.attributes.sub;
        const userdata = await DataStore.query(UserMaster, id);
        // if (userdata.Name !== null) {
        console.log("userdata", userdata);
        // await AsyncStorage.setItem("Username", userdata.Name);
        try {
          await AsyncStorage.setItem("UserDetail", userdata.UUID);
        } catch (error) {
          console.log("Error in catch userdetails : ", error);
        }
        // }
        navigation.navigate("CreateTournament");
      } else {
        let sendData = {
          prevPage: "",
          nextPage: "",
          userId: "",
        };
        navigation.navigate("SignIn", {
          data: sendData,
        });
      }
    } else {
      let sendData = {
        prevPage: "",
        nextPage: "",
        userId: "",
      };
      navigation.navigate("SignIn", {
        data: sendData,
      });
    }
  };

  return (
    <View style={styles.featuresTopSectionBG}>
      <Text style={styles.FeaturesTopContainerText}>
        Elevate Your Tournament Experience with TrackMyTournament
      </Text>
      <Pressable
        style={[
          styles.loginBtn,
          {
            backgroundColor: hoverIn === true ? colors.orange : colors.aqua,
            borderColor: hoverIn === true ? colors.orange : colors.aqua,
          },
        ]}
        onPress={() => handleCreateTournament()}
        onHoverIn={handleHoverIn}
        onHoverOut={handleHoverOut}
      >
        <Text
          style={[
            styles.loginBtnText,
            { color: hoverIn === true ? colors.white : colors.royalBlue },
          ]}
        >
          Create Tournament for Free
        </Text>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  featuresTopSectionBG: {
    backgroundColor: colors.royalBlueColour,
    padding: Platform.OS === "web" && screenWidth > 760 ? 80 : 20,
    justifyContent: "center",
    alignItems: "center",
  },
  FeaturesTopContainerText: {
    fontFamily: "PlusJakartaSansBold",
    color: colors.white,
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 45 : 24,
    maxWidth: 540,
    textAlign: "center",
    marginBottom: Platform.OS === "web" && screenWidth > 760 ? 40 : 15,
  },
  loginBtn: {
    backgroundColor: colors.aqua,
    borderColor: colors.aqua,
    borderWidth: 1,
    padding: 5,
    borderRadius: 10,
    fontSize: 14,
    width: Platform.OS === "web" && screenWidth > 760 ? 280 : 230,
    height: Platform.OS === "web" && screenWidth > 760 ? 44 : 35,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15,
  },
  loginBtnText: {
    color: colors.royalBlueColour,
    textAlign: "center",
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 17 : 14,
  },
});

export default FeaturesTopSection;
