import React, { useEffect, useState } from "react";
import {
  View,
  Platform,
  Text,
  StyleSheet,
  Pressable,
  Image,
  Dimensions,
} from "react-native";
import { colors } from "../../../../components/reusable/colour";
import { CommonHeading } from "../../../../components/reusable/TextComponent";
const screenWidth = Dimensions.get("window").width;
const DashBoardTR = ({ ListOfTeam }) => {
  const [ListofTeamTOurnament, setListofTeamTOurnament] = useState([]);
  const tournamentBanner: any =
    "../../../../../assets/home/tournamentBanner.jpg";

  useEffect(() => {
    console.log("ListOfTeam", ListOfTeam);
    if (ListOfTeam && ListOfTeam.length > 0) {
      setListofTeamTOurnament(ListOfTeam);
    }
  }, [ListOfTeam]);
  return (
    <View>
      <CommonHeading props={"My Tournaments"} />
      <View
        style={{
          display: "flex",
          flexDirection:
            Platform.OS === "web" && screenWidth > 760 ? "row" : "row",
          gap: 15,
          flexWrap:
            Platform.OS === "web" && screenWidth > 760 ? "wrap" : "nowrap",
          overflow: screenWidth > 760 ? undefined : "scroll",
          marginTop: 15,
        }}
      >
        {ListofTeamTOurnament &&
          ListofTeamTOurnament.length > 0 &&
          ListofTeamTOurnament.map((obj, index) => {
            return (
              <>
                <View style={styles.homeTournamentCard} key={index}>
                  <View
                    style={[
                      styles.tournamentStatusWrapper,
                      {
                        backgroundColor:
                          obj.Tournament.Status === "INPROGRESS"
                            ? "#21d448"
                            : obj.Tournament.Status === "SCHEDULED"
                            ? "#e99400"
                            : obj.Tournament.Status === "COMPLETED"
                            ? "#ababab"
                            : obj.Tournament.Status === "CANCELLED"
                            ? "red"
                            : obj.Tournament.Status === "Created"
                            ? "#140759"
                            : null,
                      },
                    ]}
                  >
                    <Text style={styles.tournamentCardStatus}>
                      {obj.Tournament.Status ? obj.Tournament.Status : null}
                    </Text>
                  </View>
                  <Image
                    source={{
                      uri:
                        obj?.Tournament && obj?.Tournament?.Banner
                          ? obj.Tournament.Banner
                          : require("../../../../../assets/home/tournamentBanner.jpg"),
                    }}
                    style={styles.TournamentBannerImage}
                  />
                  <View style={styles.TournamentCardContentWrapper}>
                    <View style={styles.TournamentCardHeadingWrapper}>
                      <Text style={styles.CardHeadingStyle}>
                        {obj.Tournament ? obj.Tournament.Name : null}
                      </Text>
                      <Text style={styles.CardOrganizerName}>
                        {obj.Tournament ? obj.Tournament.Organizer : null}
                      </Text>
                    </View>
                    <View style={styles.cardSmallDetailsWrapper}>
                      <View>
                        <Text style={styles.cardDetailsText}>
                          Registration Date
                        </Text>
                        <Text style={styles.cardDetailsText}>
                          {obj.Tournament
                            ? obj.Tournament.RegistrationEndDate
                            : null}
                        </Text>
                      </View>
                      <View>
                        <Text style={styles.cardDetailsText}>Status</Text>
                        <Text
                          style={[styles.cardDetailsText, { color: "#21D448" }]}
                        >
                          {obj.Tournament ? obj.Tournament.Status : null}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </>
            );
          })}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  TournamentBannerImage: {
    width: "100%",
    height: 140,
    objectFit: "contain",
    borderRadius: 15,
  },
  TournamentCardContentWrapper: {
    paddingHorizontal: 20,
  },
  TournamentCardHeadingWrapper: {
    backgroundColor: colors.royalBlue,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 10,
    shadowColor: "#00000026",
    shadowOpacity: 1,
    elevation: 3,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    marginTop: -20,
    borderRadius: 15,
    marginBottom: 15,
  },
  CardHeadingStyle: {
    textAlign: "center",
    fontSize: 20,
    textTransform: "uppercase",
    fontFamily: "PlusJakartaSansSemiBold",
    color: colors.white,
    marginBottom: 10,
  },
  CardOrganizerName: {
    textAlign: "center",
    fontSize: 15,
    fontFamily: "PlusJakartaSansSemiBold",
    color: colors.white,
  },
  cardSmallDetailsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 10,
    paddingRight: 0,
    paddingBottom: 10,
    paddingLeft: 0,
  },
  cardDetailsText: {
    fontSize: 14,
    fontFamily: "PlusJakartaSansSemiBold",
    color: colors.white,
  },
  horizontalLine: {
    width: "100%",
    height: 1,
    backgroundColor: colors.bgColor,
  },

  //
  homeTournamentCard: {
    backgroundColor: colors.royalBlue,
    maxWidth: Platform.OS === "web" && screenWidth > 760 ? 330 : "100%",
    minWidth: screenWidth > 760 ? "28%" : "90%",
    flex: 1,
    borderRadius: 15,
    borderWidth: 1,
    borderColor: colors.royalBlue,
    borderStyle: "solid",
    position: "relative",
  },
  tournamentStatusWrapper: {
    position: "absolute",
    zIndex: 2,
    paddingVertical: 3,
    paddingHorizontal: 7,
    left: -5,
    top: -5,
    borderRadius: 5,
  },
  tournamentCardStatus: {
    color: colors.white,
    fontSize: 14,
    fontFamily: "PlusJakartaSansMedium",
  },
});
export default DashBoardTR;
