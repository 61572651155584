import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useState, useContext } from "react";
import {
  View,
  Platform,
  Text,
  StyleSheet,
  Pressable,
  Image,
  Dimensions,
  TextInput,
} from "react-native";
import AdminTopNavbar from "./AdminTopNavbar";
import { signOut } from "aws-amplify/auth";
import { AuthenticationContext } from "../../authentication/Authentication";
import AsyncStorage from "@react-native-async-storage/async-storage";
import HamburgerIcon from "react-native-vector-icons/MaterialIcons";
import DashboardIcon from "react-native-vector-icons/MaterialIcons";
import ProfileIcon from "react-native-vector-icons/FontAwesome5";
import TrophyIcon from "react-native-vector-icons/FontAwesome5";
import TeamsIcon from "react-native-vector-icons/FontAwesome5";
import FlagIcon from "react-native-vector-icons/MaterialIcons";
import DollorIcon from "react-native-vector-icons/FontAwesome5";
import HelpIcon from "react-native-vector-icons/MaterialIcons";
import LogOutIcon from "react-native-vector-icons/MaterialIcons";
import { colors } from "../reusable/colour";
import { ScrollView } from "react-native-gesture-handler";
import { AreYouSureOrange } from "../reusable/AreYouSure";

const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;
const desiredHeight = screenHeight;

const AdminLayout = (props) => {
  const navigation = useNavigation();
  const [SuccessModalVisible, setSuccessModalVisible] = useState(false);
  const [SunccessModalMesg, setSunccessModalMesg] = useState("");
  const [menuCollapsed, setMenuCollapsed] = useState(true);
  const { setIsLoggedIn } = useContext(AuthenticationContext);
  const handleClose = () => {
    setSuccessModalVisible(false);
  };

  const handleConfirm = async () => {
    setSuccessModalVisible(false);
    // setActiveItem("Logout");
    try {
      await signOut().then(async (res) => {
        console.log("Signed Out", res);
        let sendData = {
          prevPage: "",
          nextPage: "",
          userId: "",
        };
        setIsLoggedIn(false);
        await AsyncStorage.clear();

        navigation.navigate("Home");
      });
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const handleLogout = async () => {
    setSuccessModalVisible(true);
    setSunccessModalMesg("Do you want to logout");
  };

  const handleCollapse = () => {
    setMenuCollapsed(!menuCollapsed);
  };

  return (
    <View style={styles.DashboardMainContainer}>
      <View
        style={[styles.sideBarContainer, { width: menuCollapsed ? 200 : 80 }]}
      >
        <View>
          <Pressable onPress={() => navigation.navigate("Home")}>
            <View
              style={
                menuCollapsed
                  ? styles.logoContainer
                  : styles.collapsedLogoContainer
              }
            >
              {menuCollapsed ? (
                <Image
                  source={require("../../../assets/logo/logo-w.png")}
                  style={styles.logo}
                />
              ) : (
                <Image
                  source={require("../../../assets/logo/tmt-small-logo.png")}
                  style={styles.logo}
                />
              )}
            </View>
          </Pressable>
          <Pressable
            style={styles.HamburgerBtn}
            onPress={() => handleCollapse()}
          >
            <HamburgerIcon name="menu-open" size={35} color={colors.white} />
          </Pressable>
        </View>
        <View style={styles.sidebar}>
          <Pressable
            style={[
              styles.menuItem,
              {
                backgroundColor:
                  Platform.OS == "web"
                    ? location.pathname === "/Dashboard" ||
                      location.pathname === "/ManageTournament"
                      ? "#ffffff26"
                      : "transparent"
                    : undefined,
              },
            ]}
            onPress={() => {
              navigation.navigate("Dashboard");
            }}
          >
            <DashboardIcon name="dashboard" size={22} color={colors.white} />

            <Text style={menuCollapsed ? styles.menuText : styles.hide}>
              Dashboard
            </Text>
          </Pressable>

          <Pressable
            style={[
              styles.menuItem,
              {
                backgroundColor:
                  Platform.OS == "web"
                    ? location.pathname === "/MyProfile"
                      ? "#ffffff26"
                      : "transparent"
                    : undefined,
              },
            ]}
            onPress={() => {
              navigation.navigate("MyProfile");
            }}
          >
            <ProfileIcon name="user-circle" size={22} color={colors.white} />
            <Text style={menuCollapsed ? styles.menuText : styles.hide}>
              Profile
            </Text>
          </Pressable>

          <Pressable
            style={[
              styles.menuItem,
              {
                backgroundColor:
                  Platform.OS == "web"
                    ? location.pathname === "/MyTournament"
                      ? "#ffffff26"
                      : "transparent"
                    : undefined,
              },
            ]}
            onPress={() => {
              navigation.navigate("MyTournament");
            }}
          >
            <TrophyIcon name="trophy" size={20} color={colors.white} />
            <Text style={menuCollapsed ? styles.menuText : styles.hide}>
              My Tournaments
            </Text>
          </Pressable>

          <Pressable
            style={[
              styles.menuItem,
              {
                backgroundColor:
                  Platform.OS == "web"
                    ? location.pathname === "/MyTeams"
                      ? "#ffffff26"
                      : "transparent"
                    : undefined,
              },
            ]}
            onPress={async () => {
              navigation.navigate("MyTeams");
            }}
          >
            <ProfileIcon name="users" size={20} color={colors.white} />
            <Text style={menuCollapsed ? styles.menuText : styles.hide}>
              My Teams
            </Text>
          </Pressable>
          {/*  */}
          <Pressable
            style={[
              styles.menuItem,
              {
                backgroundColor:
                  Platform.OS == "web"
                    ? location.pathname === "/MyMatches"
                      ? "#ffffff26"
                      : "transparent"
                    : undefined,
              },
            ]}
            onPress={() => {
              navigation.navigate("MyMatches");
            }}
          >
            <FlagIcon name="sports" size={24} color={colors.white} />
            <Text style={menuCollapsed ? styles.menuText : styles.hide}>
              My Matches
            </Text>
          </Pressable>

          {/* --- The below 'Transactions' and 'Help 'menu is commented,it will be used in future ! DON'T REMOVE LINES OF CODE BELOW ---- */}

          {/* <Pressable
            style={[
              styles.menuItem,
              {
                backgroundColor:
                  Platform.OS == "web"
                    ? location.pathname === "/transaction"
                      ? "#ffffff26"
                      : "transparent"
                    : undefined,
              },
            ]}
          >
            <DollorIcon name="search-dollar" size={20} color={colors.white} />
            <Text style={menuCollapsed ? styles.menuText : styles.hide}>
              Transactions
            </Text>
          </Pressable> */}

          {/* <Pressable
            style={[
              styles.menuItem,
              {
                backgroundColor:
                  Platform.OS == "web"
                    ? location.pathname === "/help"
                      ? "#ffffff26"
                      : "transparent"
                    : undefined,
              },
            ]}
          >
            <HelpIcon name="help" size={20} color={colors.white} />
            <Text style={menuCollapsed ? styles.menuText : styles.hide}>
              Help
            </Text>
          </Pressable> */}

          <Pressable
            style={[
              styles.menuItem,
              {
                backgroundColor:
                  Platform.OS == "web"
                    ? location.pathname === "/logout"
                      ? "#ffffff26"
                      : "transparent"
                    : undefined,
              },
            ]}
            onPress={async () => {
              handleLogout();
            }}
          >
            <LogOutIcon name="logout" size={20} color={colors.white} />
            <Text style={menuCollapsed ? styles.menuText : styles.hide}>
              Logout
            </Text>
          </Pressable>
        </View>
        {SuccessModalVisible == true ? (
          <AreYouSureOrange
            visibleModal={SuccessModalVisible}
            Message={SunccessModalMesg}
            ButtonText={"Logout"}
            onConfirm={handleConfirm}
            onCancel={handleClose}
          />
        ) : null}
      </View>

      {/*  */}

      <View
        style={
          menuCollapsed
            ? styles.DashboardContentContainer
            : styles.DashboardContentContainerCollapsed
        }
      >
        {Platform.OS === "web" ? <AdminTopNavbar /> : null}
        <ScrollView>{props.children}</ScrollView>

        {/* {Platform.OS === "web" ? null : <BottomMenu />} */}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  DashboardMainContainer: {
    width: "100%",
    display: "flex",
    flexDirection:
      Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
    backgroundColor: colors.bgColor,
    flex: 1,
  },
  DashboardContentContainer: {
    flex: 1,
    padding: Platform.OS === "web" && screenWidth > 760 ? 20 : 0,
    backgroundColor: colors.bgColor,
    paddingTop:
      Platform.OS === "web" && screenWidth > 760
        ? 70
        : Platform.OS === "android" || Platform.OS === "ios"
        ? 0
        : 40,
    position: "relative",
    paddingLeft: Platform.OS === "web" && screenWidth > 760 ? 220 : 0,
    minHeight: screenHeight,
  },
  DashboardContentContainerCollapsed: {
    flex: 1,
    padding: Platform.OS === "web" && screenWidth > 760 ? 20 : 10,
    backgroundColor: colors.bgColor,
    paddingTop: Platform.OS === "web" && screenWidth > 760 ? 70 : 40,
    position: "relative",
    paddingLeft: Platform.OS === "web" && screenWidth > 760 ? 100 : 0,
    minHeight: screenHeight,
  },

  // sidebar
  sideBarContainer: {
    // width: 200,
    height: desiredHeight,
    backgroundColor: colors.royalBlue,
    padding: 10,
    zIndex: 9,
    position: "fixed",
    display: Platform.OS === "android" || screenWidth < 800 ? "none" : "flex",
  },

  sidebar: {
    paddingVertical: 20,
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  menuItem: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 10,
    flexDirection: "row",
    gap: 5,
    flexDirection: "row",
    alignItems: "center",
  },

  adminProfileWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: 5,
    alignItems: "center",
    marginTop: 10,
    marginBottom: 15,
  },
  profileImage: {
    width: 40,
    height: 40,
    borderRadius: 30,
  },
  adminProfileName: {
    fontSize: 16,
    color: "#fff",
    fontFamily: "PlusJakartaSansMedium",
    marginBottom: 3,
  },
  adminRole: {
    fontSize: 12,
    color: "#fff",
    fontFamily: "PlusJakartaSansRegular",
  },
  logoContainer: {
    width: "100%",
    height: 50,
    paddingVertical: 5,
  },
  collapsedLogoContainer: {
    width: 60,
    height: 60,
    paddingVertical: 5,
  },
  logo: {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
  },
  HamburgerBtn: {
    position: "absolute",
    top: 3,
    right: -65,
  },
  menuText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
  },
  hide: {
    display: "none",
  },
});

export default AdminLayout;
