import React from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  Pressable,
  Platform,
  Linking,
  Dimensions,
} from "react-native";
import {
  H4,
  CommonHeading,
} from "../../../../components/reusable/TextComponent";
import { colors } from "../../../../components/reusable/colour";
import Icon from "react-native-vector-icons/MaterialIcons";
import Icon2 from "react-native-vector-icons/AntDesign";
import { useNavigation } from "@react-navigation/native";

const screenWidth = Dimensions.get("window").width;

const Footer = () => {
  const navigation: any = useNavigation();
  return (
    <View style={styles.footerBGContainer}>
      <View style={styles.container}>
        <View style={styles.footerContainer}>
          <View style={styles.footerOne}>
            <Image
              source={require("../../../../../assets/logo/logo.png")}
              style={styles.footerLogo}
              resizeMode="contain"
            />
            <Text style={styles.footerText}>
              Download the app by clicking the link below :
            </Text>
            <View style={{ flexDirection: "row", gap: 15 }}>
              <Pressable>
                <Image
                  source={require("../../../../../assets/home/footer/playstore.svg")}
                  style={styles.playstore}
                  resizeMode="contain"
                />
              </Pressable>
              <Pressable>
                <Image
                  source={require("../../../../../assets/home/footer/appstore.svg")}
                  style={styles.playstore}
                  resizeMode="contain"
                />
              </Pressable>
            </View>
          </View>
          <View style={{ gap: 10 }}>
            <CommonHeading props={"Pages"} style={{ color: "#fff" }} />
            <Pressable
              onPress={() => {
                navigation.navigate("AllTournament");
              }}
            >
              <Text style={styles.footerText}>Tournaments</Text>
            </Pressable>
            <Pressable
              onPress={() => {
                navigation.navigate("AllMatches");
              }}
            >
              <Text style={styles.footerText}>Matches</Text>
            </Pressable>
            <Pressable
              onPress={() => {
                navigation.navigate("Features");
              }}
            >
              <Text style={styles.footerText}>Features</Text>
            </Pressable>
            <Pressable
              onPress={() => {
                navigation.navigate("SignIn");
              }}
            >
              <Text style={styles.footerText}>Login</Text>
            </Pressable>
          </View>
          <View style={{ gap: 10 }}>
            <CommonHeading props={"Contact"} style={{ color: "#fff" }} />
            <View style={styles.footerContactWrapper}>
              <Icon name="call" size={22} color="#fff" />
              <Text style={styles.footerText}>(406)555-0120</Text>
            </View>
            <View style={styles.footerContactWrapper}>
              <Icon name="mail" size={22} color="#fff" />
              <Text style={styles.footerText}>info@trackmytournament.com</Text>
            </View>
            <Pressable
              onPress={() =>
                Linking.openURL("https://maps.app.goo.gl/EaWKE2eRSD6i8SpP6")
              }
            >
              <View style={styles.footerContactWrapper}>
                <Icon name="location-pin" size={22} color={colors.white} />
                <Text style={styles.footerText}>Baner, Pune</Text>
              </View>
            </Pressable>
          </View>
          <View style={{ gap: 20 }}>
            <CommonHeading
              props={"Social Media"}
              style={{ color: colors.white }}
            />
            <View style={{ flexDirection: "row", gap: 15 }}>
              <Pressable>
                <Icon name="facebook" size={22} color={colors.white} />
              </Pressable>
              <Pressable>
                <Icon2 name="twitter" size={22} color={colors.white} />
              </Pressable>
              <Pressable>
                <Icon2 name="linkedin-square" size={22} color={colors.white} />
              </Pressable>
              <Pressable
                onPress={() => {
                  Linking.openURL(
                    "https://www.instagram.com/trackmytournament?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                  );
                }}
              >
                <Icon2 name="instagram" size={22} color={colors.white} />
              </Pressable>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  footerBGContainer: {
    backgroundColor: colors.primary,
    width: "100%",
  },
  container: {
    maxWidth: 1320,
    width: "100%",
    height: "100%",
    marginHorizontal: "auto",
    paddingRight: screenWidth > 760 ? 50 : 10,
    paddingLeft: screenWidth > 760 ? 50 : 10,
  },
  footerLogo: {
    height: 80,
    width: 250,
    resizeMode: "contain",
  },
  playstore: {
    height: 40,
    width: 120,
    resizeMode: "contain",
  },
  footerText: {
    color: colors.white,
    fontSize: 13,
    fontFamily: "PlusJakartaSansRegular",
  },
  footerContainer: {
    paddingVertical: 50,
    flexDirection:
      Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: 20,
  },
  footerOne: {
    gap: 15,
  },
  footerContactWrapper: {
    flexDirection: "row",
    gap: 10,
  },
});

export default Footer;
