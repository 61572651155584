import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Dimensions,
  Platform,
  Pressable,
  TextInput,
  Image,
  ScrollView,
} from "react-native";
import { H1, H2, H3, H4 } from "../../../components/reusable/TextComponent";
import { colors } from "../../../components/reusable/colour";
import PauseIcon from "react-native-vector-icons/Ionicons";
import Football from "react-native-vector-icons/FontAwesome";
import CardIcon from "react-native-vector-icons/MaterialCommunityIcons";
import SwitchIcon from "react-native-vector-icons/Octicons";
import EditIcon from "react-native-vector-icons/AntDesign";
import AddIcon from "react-native-vector-icons/AntDesign";
import DeleteIcon from "react-native-vector-icons/Feather";
import TrashIcon from "react-native-vector-icons/Ionicons";
import SubstituteIcon from "react-native-vector-icons/Ionicons";
import AdminLayout from "../../../components/include/AdminLayout";
import SuccessModal from "../../../components/reusable/SuccessModal";
import TextField from "../../../components/reusable/textFeild";
import { LinearGradient } from "expo-linear-gradient";
import { DataStore } from "aws-amplify/datastore";
import {
  CardMaster,
  GoalMaster,
  GroupStanding,
  GroupsMaster,
  MatchMaster,
  MatchStatus,
  MatchTeamMaster,
  PlayerMaster,
  RoundMaster,
  StatisticsMaster,
  SubstitutionMaster,
  Tournament,
  UserMaster,
} from "../../../models";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Half } from "../../../models";
import { StackNavigationProp } from "@react-navigation/stack";
import { useNavigation } from "@react-navigation/native";
import moment from "moment";
import { MatchResult } from "../../../models";
import AppBar from "../../../components/include/AppBar";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

const ScoringApp = ({ route }) => {
  const [activeTab, setActiveTab] = useState<number>();
  const [activeScoreTab, setActiveScoreTab] = useState<number>();
  const [minutes, setMinutes]: any = useState(0);
  const [seconds, setSeconds]: any = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [MatchObj, setMatchObj]: any = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [SelectedPlayerList, setSelectedPlayerList] = useState([]);
  const [SelectedSubList, setSelectedSubList] = useState([]);
  const [SelectedPlayerobj, setSelectedPlayerobj]: any = useState({});
  const [SunccessModalMesg, setSunccessModalMesg] = useState("");
  const [firsthalfSecondExtraTime, setfirsthalfSecondExtraTime] =
    useState("Start 1st Half");
  const [Allhalfstart, setAllhalfstart] = useState("");
  const [Addgoalcardhiseshowflag, setAddgoalcardhiseshowflag] = useState("");
  const [SelectedSubsitutePlayerObj, setSelectedSubsitutePlayerObj]: any =
    useState();
  const [SelectedRoundObejct, setSelectedRoundObejct]: any = useState();
  const [SelectedPlayerFlag, setSelectedPlayerFlag] = useState(false);

  const [SelectedSubPlayerFlag, setSelectedSubPlayerFlag] = useState(false);
  const [OwnGoalflag, setOwnGoalflag] = useState(false);
  const [Playerlist, setPlayerlist] = useState([]);
  const [GoalTime, setGoalTime] = useState("01");
  const [SelectedTeamObj, setSelectedTeamObj] = useState<any>();
  const [UserDetails, setUserDetails] = useState<any>();
  const [SelectedCard, setSelectedCard] = useState("");
  const [MatchObject, setMatchObject]: any = useState({});
  const [GoalcardArrlist, setGoalcardArrlist] = useState([]);
  const [deletedGoalcardArrlist, setDeletedGoalcardArrlist] = useState([]);
  const [susbituteArryrevertMatch, setSusbituteArryrevertMatch] = useState([]);
  const { MatchID, Firsthalf }: any = route.params;
  const navigation =
    useNavigation<StackNavigationProp<any, "ManageTournament">>();
  // console.log("StartMatchId", MatchID);
  const handleOpen = () => {
    setModalVisible(true);
    console.log("button pressed");
  };
  const handleClose = () => {
    setModalVisible(false);
  };
  const handleConfirm = () => {
    console.log("modalVisible", modalVisible);
    setModalVisible(false);
    getMatchdataforCompletionMatch(MatchObject.id);
  };

  const handleOk = () => {
    console.log("modalVisible", modalVisible);
    setModalVisible(!modalVisible);
  };

  const handleTeamTabChange = (tabIndex: number, SelectedTeamdata) => {
    console.log("SelectedTeamdata", tabIndex, SelectedTeamdata);
    setSelectedTeamObj(SelectedTeamdata);
    setSelectedPlayerobj({});
    setSelectedCard("");
    setSelectedPlayerFlag(false);
    setSelectedSubPlayerFlag(false);

    let Playerlist = SelectedTeamdata.Players.filter(
      (obj) => obj.Starting == true
    );
    let Subsitute = SelectedTeamdata.Players.filter(
      (obj) => obj.Starting == false
    );
    setSelectedSubList(Subsitute);
    setSelectedPlayerList(Playerlist);
    setActiveTab(tabIndex);
  };
  const handleScoreTabChange = (tabIndex: number) => {
    console.log("tabIndex", tabIndex);
    setActiveTab(null);
    setSelectedCard("");
    setSelectedPlayerobj({});
    setActiveScoreTab(tabIndex);
  };

  const calculateDuration = (pauseStart, pauseEnd) => {
    // Parse the time strings into Date objects
    const startTime: any = new Date(`2000-01-01T${pauseStart}`);
    const endTime: any = new Date(`2000-01-01T${pauseEnd}`);

    // Calculate the difference in milliseconds
    const durationMs = endTime - startTime;

    // Convert milliseconds to hours and minutes
    const hours = Math.floor(durationMs / (1000 * 60 * 60));
    const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));

    // Format the duration as HH:MM
    const formattedDuration = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;

    return formattedDuration;
  };

  const handleStart = async () => {
    setIsRunning(true);

    if (MatchObject.PauseFlag == true) {
      let CurrentDateTime = await handleGetDateTime();
      console.log("CurrentDateTime", CurrentDateTime.trim().split(" ")[1]);

      const Matchobj = await DataStore.query(MatchMaster, MatchObject.id);
      let data = JSON.parse(JSON.stringify(Matchobj.PauseTime));
      for (let item of data) {
        if (item.PauseEnd === "") {
          item.PauseDuration = await handledurationofstartendtime(
            item.PauseStart,
            CurrentDateTime.trim().split(" ")[1]
          );
          item.PauseEnd = CurrentDateTime.trim().split(" ")[1];
        }
      }
      console.log("data", data);

      const MatchTeamdataUpdate = MatchMaster.copyOf(Matchobj, (updated) => {
        updated.PauseFlag = false;
        updated.PauseTime = data;
        return updated;
      });
      console.log("MatchTeamdataUpdate", MatchTeamdataUpdate);
      const ResultofMatchTeam = await DataStore.save(MatchTeamdataUpdate);
      console.log("ResultofMatchTeam", ResultofMatchTeam);
      GetMatchdata(ResultofMatchTeam.id);
    }
  };

  const handleStop = async () => {
    setIsRunning(false);
    let CurrentDateTime = await handleGetDateTime();
    console.log("CurrentDateTime", CurrentDateTime.trim().split(" ")[1]);
    let Matchobj = await DataStore.query(MatchMaster, MatchObject.id);
    let matchdata = { ...Matchobj };
    let obj = {
      PauseStart: CurrentDateTime.trim().split(" ")[1],
      PauseEnd: "",
      PauseDuration: "",
    };
    let data = [];
    console.log("PauseFunction", Matchobj);
    if (matchdata.PauseTime && matchdata.PauseTime.length > 0) {
      data = JSON.parse(JSON.stringify(matchdata.PauseTime));
    }

    console.log("PauseData", data);
    data.push(obj);

    const MatchTeamdataUpdate = MatchMaster.copyOf(Matchobj, (updated) => {
      updated.PauseFlag = true;
      updated.PauseTime = data;

      return updated;
    });
    console.log("MatchTeamdataUpdate", MatchTeamdataUpdate);
    const ResultofMatchTeam = await DataStore.save(MatchTeamdataUpdate);
    console.log("ResultofMatchTeam", ResultofMatchTeam);
    GetMatchdata(ResultofMatchTeam.id);
  };

  const handlegetAllPauselist = (pauses) => {
    let totalHours = 0;
    let totalMinutes = 0;
    pauses &&
      pauses.length > 0 &&
      pauses.forEach((obj) => {
        const [hours, minutes] = obj.PauseDuration.split(":").map(Number);
        totalHours += hours;
        totalMinutes += minutes;
      });
    totalHours += Math.floor(totalMinutes / 60);
    totalMinutes %= 60;
    const formattedTotal = `${totalHours
      .toString()
      .padStart(2, "0")}:${totalMinutes.toString().padStart(2, "0")}`;
    return formattedTotal;
  };
  const handleShowTime = (Matchobj) => {
    console.log("handleShowTime", Matchobj);
    if (Matchobj.ExtraTimeEnded == true) {
      setAllhalfstart("");
      setIsRunning(false);
      setMinutes(0);
      setSeconds(0);
      // setfirsthalfSecondExtraTime("Start 1st Half");
    } else if (Matchobj.ExtraTimeStarted == true) {
      setAllhalfstart("Start 1st half");

      if (Matchobj.PauseFlag == false) {
        const startDateTime = new Date(Matchobj.SecondHalfStarTime);
        let halfdata = handlegetSecondMinute(startDateTime);
        const currentDateTime = new Date();
        let halfdata2 = handlegetSecondMinute(currentDateTime);
        console.log("halfdata", halfdata, halfdata2);
        let finalhalf = handledurationofstartendtime(halfdata, halfdata2);
        console.log("Uniquellyfinddata", finalhalf);
        let PauseList = "00:00";
        if (Matchobj.PauseTime && Matchobj.PauseTime.length > 0) {
          PauseList = handlegetAllPauselist(Matchobj.PauseTime);
        }
        console.log("PauseList", PauseList);
        let elapsedMinutes = Number(finalhalf.split(":")[0]);
        let elapsedSeconds = Number(finalhalf.split(":")[1]);
        let minutes = elapsedMinutes - Number(PauseList.split(":")[0]);
        let seconds = elapsedSeconds - Number(PauseList.split(":")[1]);
        if (seconds < 0) {
          seconds -= 1;
          seconds += 60;
        }

        setMinutes(minutes + 2 * Matchobj.HalfTime);
        setGoalTime(String(minutes + 2 * Matchobj.HalfTime));
        setSeconds(seconds);
        setIsRunning(true);
        setfirsthalfSecondExtraTime("End Extra Time");
      } else {
        let PauseDateTime;
        if (Matchobj.PauseTime && Matchobj.PauseTime.length > 1) {
          PauseDateTime =
            Matchobj.PauseTime[Matchobj.PauseTime.length - 1].PauseStart;
        } else {
          PauseDateTime = Matchobj.PauseTime[0].PauseStart;
        }
        console.log("PuaseStartedTime", PauseDateTime);
        const date = new Date(Matchobj.ExtraTimeStartTime);
        console.log("halddata0", date);
        let halfdata = handlegetSecondMinute(date);
        let finaltime = handledurationofstartendtime(halfdata, PauseDateTime);
        console.log("finaltime", finaltime);
        setMinutes(Number(finaltime.split(":")[0]) + 2 * Matchobj.HalfTime);
        setSeconds(finaltime.split(":")[0]);
        setfirsthalfSecondExtraTime("End Extra Time");
      }
    } else if (Matchobj.SecondHalfEnded == true) {
      setAllhalfstart("");
      setIsRunning(false);
      setMinutes(0);
      setSeconds(0);
      setfirsthalfSecondExtraTime("Start Extra Time");
    } else if (Matchobj.SecondHalfStarted == true) {
      setAllhalfstart("Start 1st half");
      if (Matchobj.PauseFlag == false) {
        const startDateTime = new Date(Matchobj.SecondHalfStarTime);
        let halfdata = handlegetSecondMinute(startDateTime);
        const currentDateTime = new Date();
        let halfdata2 = handlegetSecondMinute(currentDateTime);
        console.log("halfdata", halfdata, halfdata2);
        let finalhalf = handledurationofstartendtime(halfdata, halfdata2);
        console.log("Uniquellyfinddata", finalhalf);
        let PauseList = "00:00";
        if (Matchobj.PauseTime && Matchobj.PauseTime.length > 0) {
          PauseList = handlegetAllPauselist(Matchobj.PauseTime);
        }
        console.log("PauseList", PauseList);
        let elapsedMinutes = Number(finalhalf.split(":")[0]);
        let elapsedSeconds = Number(finalhalf.split(":")[1]);
        let minutes = elapsedMinutes - Number(PauseList.split(":")[0]);
        let seconds = elapsedSeconds - Number(PauseList.split(":")[1]);
        console.log("SecondhalfconvertedTime", elapsedMinutes, elapsedSeconds);

        if (seconds < 0) {
          minutes -= 1;
          seconds += 60;
        }
        setMinutes(minutes + Matchobj.HalfTime);
        setGoalTime(String(minutes + Matchobj.HalfTime));
        setSeconds(seconds);
        setIsRunning(true);
        setfirsthalfSecondExtraTime("End 2nd Half");
      } else {
        let PauseDateTime;
        if (Matchobj.PauseTime && Matchobj.PauseTime.length > 1) {
          PauseDateTime =
            Matchobj.PauseTime[Matchobj.PauseTime.length - 1].PauseStart;
        } else {
          PauseDateTime = Matchobj.PauseTime[0].PauseStart;
        }
        console.log("PuaseStartedTime", PauseDateTime);
        const date = new Date(Matchobj.SecondHalfStarTime);
        console.log("halddata0", date);
        let halfdata = handlegetSecondMinute(date);
        let finaltime = handledurationofstartendtime(halfdata, PauseDateTime);
        console.log("finaltime", finaltime);
        setMinutes(Number(finaltime.split(":")[0]) + Matchobj.HalfTime);
        setSeconds(finaltime.split(":")[0]);
        setfirsthalfSecondExtraTime("End 2nd Half");
      }
    } else if (Matchobj.FirstHalfEnded == true) {
      setIsRunning(false);
      setMinutes(0);
      setSeconds(0);
      setfirsthalfSecondExtraTime("Start 2nd Half");
    } else if (Matchobj.FirstHalfStarted == true) {
      if (Matchobj.PauseFlag == false) {
        console.log("MatchobjMatchobj", Matchobj);
        const startDateTime = new Date(Matchobj.FirstHalfStartTime);
        console.log("halddata0", startDateTime);
        let halfdata = handlegetSecondMinute(startDateTime);
        const currentDateTime = new Date();
        let halfdata2 = handlegetSecondMinute(currentDateTime);
        console.log("halfdata", halfdata, halfdata2);
        let finalhalf = handledurationofstartendtime(halfdata, halfdata2);
        console.log("Uniquellyfinddata", finalhalf);
        let PauseList = "00:00";
        if (Matchobj.PauseTime && Matchobj.PauseTime.length > 0) {
          PauseList = handlegetAllPauselist(Matchobj.PauseTime);
        }
        console.log("PauseList", PauseList);
        let elapsedMinutes = Number(finalhalf.split(":")[0]);
        let elapsedSeconds = Number(finalhalf.split(":")[1]);
        let minutes = elapsedMinutes - Number(PauseList.split(":")[0]);
        let seconds = elapsedSeconds - Number(PauseList.split(":")[1]);

        console.log("finalvalue", minutes, seconds);
        if (seconds < 0) {
          seconds -= 1;
          seconds += 60;
        }
        setMinutes(minutes);
        if (minutes > 0) {
          setGoalTime(String(minutes));
        }
        setSeconds(seconds);
        setIsRunning(true);
        setAllhalfstart("Start 1st half");
        setAddgoalcardhiseshowflag("Start 1st Half");
        setfirsthalfSecondExtraTime("End 1st Half");
      } else {
        // const startDateTime = new Date(Matchobj.FirstHalfStartTime);
        let PauseDateTime;

        if (Matchobj.PauseTime && Matchobj.PauseTime.length > 1) {
          PauseDateTime =
            Matchobj.PauseTime[Matchobj.PauseTime.length - 1].PauseStart;
        } else {
          PauseDateTime = Matchobj.PauseTime[0].PauseStart;
        }
        console.log("PuaseStartedTime", PauseDateTime);
        const startDateTime = new Date(Matchobj.FirstHalfStartTime);
        console.log("halddata0", startDateTime);
        let halfdata = handlegetSecondMinute(startDateTime);
        let finaltime = handledurationofstartendtime(halfdata, PauseDateTime);
        console.log("finaltime", finaltime);
        setMinutes(finaltime.split(":")[0]);
        setSeconds(finaltime.split(":")[0]);
        setAllhalfstart("Start 1st half");
        setAddgoalcardhiseshowflag("Start 1st Half");
        setfirsthalfSecondExtraTime("End 1st Half");
      }
    }
  };

  const handledurationofstartendtime = (time1, time2) => {
    const moment1 = moment(time1, "HH:mm:ss");
    const moment2 = moment(time2, "HH:mm:ss");

    // Calculate the difference in seconds
    const differenceInSeconds = moment2.diff(moment1, "seconds");

    // Convert the difference to positive seconds
    const absoluteDifference = Math.abs(differenceInSeconds);

    // Convert seconds to hours, minutes, and seconds
    const hours = Math.floor(absoluteDifference / 3600);
    const minutes = Math.floor((absoluteDifference % 3600) / 60);
    const seconds = absoluteDifference % 60;

    // Format the result
    // const formattedDuration = `${hours}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    const formattedDuration = `${String(minutes).padStart(2, "0")}:${String(
      seconds
    ).padStart(2, "0")}`;

    return formattedDuration;
  };

  const handlegetSecondMinute = (data) => {
    const inputDateString = data;
    const inputDate = new Date(inputDateString);

    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();
    const seconds = inputDate.getSeconds();

    // Format the hours, minutes, and seconds
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    // Create the formatted time string
    const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

    return formattedTime;
  };

  const handleRevertMatchCardSubmit = async (playerdata) => {
    try {
      const GroupResponse = await DataStore.save(new CardMaster(playerdata));
      console.log("GroupResponse", GroupResponse);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleRevertGoalSubmit = async (playerdata) => {
    let AllMatchTeam = [];
    let MatchTeamdataUpdate = {};
    let Playerobj = { ...playerdata };
    AllMatchTeam.push(MatchObj.Team1, MatchObj.Team2);
    console.log("OwnGoalflag", AllMatchTeam, OwnGoalflag);
    console.log(
      "handleGoal",
      SelectedPlayerobj,
      OwnGoalflag,
      GoalTime,
      MatchObj,
      firsthalfSecondExtraTime
    );

    let Halfvalue = Half;
    let selectedHalfTime;

    if (MatchObj.OnlineScoring == false) {
      console.log("Goal Updated Offline");
      selectedHalfTime =
        Number(GoalTime) <= MatchObj.HalfTime
          ? Halfvalue.FIRST
          : Number(GoalTime) <= 2 * MatchObj.HalfTime
          ? Halfvalue.SECOND
          : Halfvalue.EXTRA_TIME;
    } else {
      console.log("Goal Updated Online");
      selectedHalfTime = firsthalfSecondExtraTime.includes("1st Half")
        ? Halfvalue.FIRST
        : firsthalfSecondExtraTime.includes("2nd Half")
        ? Halfvalue.SECOND
        : Halfvalue.EXTRA_TIME;
    }

    try {
      const Goalresponse = await DataStore.save(new GoalMaster(Playerobj));
      console.log("GroupResponse", Goalresponse);
      if (Goalresponse) {
        // handleSuccessModalShow("")
      }
    } catch (error) {
      console.log("error", error);
    }
    try {
      const MatchTeamdata = await DataStore.query(
        MatchTeamMaster,
        playerdata.MatchTeamID
      );
      console.log("MatchTeamdata", MatchTeamdata);
      if (MatchObj.OnlineScoring == false) {
        console.log(
          "Offline Fixture Updated",
          Number(GoalTime),
          MatchObj.HalfTime,
          Number(GoalTime) <= MatchObj.HalfTime
            ? MatchTeamdata.Score.FirstHalf + 1
            : MatchTeamdata.Score.FirstHalf
        );
        MatchTeamdataUpdate = MatchTeamMaster.copyOf(
          MatchTeamdata,
          (updated) => {
            updated.Score.FirstHalf =
              Number(GoalTime) <= MatchObj.HalfTime
                ? updated.Score.FirstHalf + 1
                : updated.Score.FirstHalf;
            updated.Score.SecondHalf =
              Number(GoalTime) <= 2 * MatchObj.HalfTime &&
              Number(GoalTime) > MatchObj.HalfTime
                ? updated.Score.SecondHalf + 1
                : updated.Score.SecondHalf;
            updated.Score.PenaltyShootout = updated.Score.PenaltyShootout;
            updated.Score.ExtraTime =
              Number(GoalTime) > 2 * MatchObj.HalfTime
                ? updated.Score.ExtraTime + 1
                : updated.Score.ExtraTime;
            return updated;
          }
        );
      } else {
        console.log("Online Fixture Update");
        MatchTeamdataUpdate = MatchTeamMaster.copyOf(
          MatchTeamdata,
          (updated) => {
            updated.Score.FirstHalf = firsthalfSecondExtraTime.includes(
              "1st Half"
            )
              ? updated.Score.FirstHalf + 1
              : updated.Score.FirstHalf;
            updated.Score.SecondHalf = firsthalfSecondExtraTime.includes(
              "2nd Half"
            )
              ? updated.Score.SecondHalf + 1
              : updated.Score.SecondHalf;
            updated.Score.PenaltyShootout = updated.Score.PenaltyShootout;
            updated.Score.ExtraTime = firsthalfSecondExtraTime.includes(
              "Extra Time"
            )
              ? updated.Score.ExtraTime + 1
              : updated.Score.ExtraTime;
            return updated;
          }
        );
      }

      // if (MatchTeamdata) {

      console.log("MatchTeamdataUpdate", MatchTeamdataUpdate);
      const ResultofMatchTeam = await DataStore.save(MatchTeamdataUpdate);
      console.log("ResultofMatchTeam", ResultofMatchTeam);
    } catch (error) {
      console.error("Error updating tournament:", error);
    }
  };

  //  const handleRevertDeletedMatchStanding = async(matchobj)=>{
  // const matchObj = await DataStore.query(MatchMaster, matchobj);
  // console.log("handleRevertDeletedMatchStanding",matchObj);

  //  }

  // const handleMatchRevert = async () => {

  //   let Userdata = await getUserdata();
  //   if (susbituteArryrevertMatch && susbituteArryrevertMatch.length > 0) {
  //     const updatePromises = susbituteArryrevertMatch.map(async (ele) => {
  //       handlerevertMatchSubsitute(ele);
  //       const existStatisticInPlayer = await DataStore.query(
  //         StatisticsMaster,
  //         (statistics) =>
  //           statistics.and((statistics) => [
  //             statistics.tournamentID.eq(MatchObj.tournamentID),
  //             statistics.PlayerId.eq(ele.InPlayer.PlayerId),
  //           ])
  //       );
  //       console.log("existStatisticInPlayer", existStatisticInPlayer);
  //       if (existStatisticInPlayer && existStatisticInPlayer.length > 0) {
  //         let GoalCardExistInPlayer = MatchObj.GoalCardList.find(
  //           (ele) => ele.Player.PlayerId == existStatisticInPlayer[0].PlayerId
  //         );
  //         console.log("AllBothdata", GoalCardExistInPlayer);
  //         if (
  //           GoalCardExistInPlayer &&
  //           Object.keys(GoalCardExistInPlayer).length == 0
  //         ) {
  //           try {
  //             const UpdatePlayerstates = StatisticsMaster.copyOf(
  //               existStatisticInPlayer[0],
  //               (updated) => {
  //                 updated.Games = updated.Games - 1;

  //                 return updated;
  //               }
  //             );
  //             console.log("MinusGoalCardvalueFromStates", UpdatePlayerstates);

  //             // const ResultplayerStates = await DataStore.save(UpdatePlayerstates);
  //             // console.log("ResultplayerStates", ResultplayerStates);
  //           } catch (error) {
  //             console.error("Error updating Statistics:", error);
  //           }
  //         }
  //       }

  //       const existStatisticOutPlayer = await DataStore.query(
  //         StatisticsMaster,
  //         (statistics) =>
  //           statistics.and((statistics) => [
  //             statistics.tournamentID.eq(MatchObj.tournamentID),
  //             statistics.PlayerId.eq(ele.OutPlayer.PlayerId),
  //           ])
  //       );
  //       console.log("existStatisticOutPlayer", existStatisticOutPlayer,ele);

  //       if(existStatisticOutPlayer && existStatisticOutPlayer.length>0){
  //         try {
  //           const UpdatePlayerstates = StatisticsMaster.copyOf(
  //             existStatisticOutPlayer[0],
  //             (updated) => {
  //               updated.Games = updated.Games + 1;

  //               return updated;
  //             }
  //           );
  //           console.log("MinusGoalCardvalueFromStates", UpdatePlayerstates);

  //           // const ResultplayerStates = await DataStore.save(UpdatePlayerstates);
  //           // console.log("ResultplayerStates", ResultplayerStates);
  //         } catch (error) {
  //           console.error("Error updating Statistics:", error);
  //         }
  //       }else{

  //         let Matchteamdata = await DataStore.query(MatchTeamMaster,ele.MatchTeamID)
  //         console.log("MatchTeamMaster", Matchteamdata);

  //         let Obj= {
  //           TeamName: Matchteamdata.TeamName,
  //           TeamLogo: Matchteamdata.LogoURL,
  //           CreatedBy: Userdata.Name,
  //           LastUpdatedBy: Userdata.Name,
  //           Goals: 0,
  //           RedCards: 0,
  //           YellowCards: 0,
  //           Games: 1,
  //           Minutes: Number(ele.Time),
  //           tournamentID: MatchObj.tournamentID,
  //           PlayerId: ele.OutPlayer.PlayerId,1138
  //         }
  //         // const StatesResp = await DataStore.save(new StatisticsMaster(Obj));

  //       }
  //     });
  //     await Promise.all(updatePromises);
  //   }
  // };

  const handleMatchRevert = async () => {
    console.log("DeletePlayerlist", deletedGoalcardArrlist);
    let Userdata = await getUserdata();
    console.log("Userdata", Userdata);
    console.log("GoalCardList", MatchObj.GoalCardList);
    console.log(
      "helloMatchrevert",
      susbituteArryrevertMatch,
      MatchObject,
      GoalcardArrlist
    );
    if (susbituteArryrevertMatch && susbituteArryrevertMatch.length > 0) {
      const updatePromises = susbituteArryrevertMatch.map(async (ele) => {
        await handlerevertMatchSubsitute(ele);

        const existStatisticInPlayer = await DataStore.query(
          StatisticsMaster,
          (statistics) =>
            statistics.and((statistics) => [
              statistics.tournamentID.eq(MatchObj.tournamentID),
              statistics.PlayerId.eq(ele.InPlayer.PlayerId),
            ])
        );
        console.log(
          "existStatisticInPlayer",
          MatchObj.GoalCardList,
          existStatisticInPlayer
        );
        if (existStatisticInPlayer && existStatisticInPlayer.length > 0) {
          let arrayOfObjects = MatchObj.GoalCardList.filter(
            (obj) => !obj.hasOwnProperty("InPlayer")
          );
          let GoalCardExistInPlayer = arrayOfObjects.filter(
            (ele) => ele.Player.PlayerId == existStatisticInPlayer[0].PlayerId
          );
          console.log("AllBothdata", GoalCardExistInPlayer);
          if (GoalCardExistInPlayer.length == 0) {
            console.log("hellonotgames");
            try {
              const UpdatePlayerstates = StatisticsMaster.copyOf(
                existStatisticInPlayer[0],
                (updated) => {
                  updated.Games = updated.Games - 1;

                  return updated;
                }
              );
              console.log("MinusGoalCardvalueFromStates", UpdatePlayerstates);

              const ResultplayerStates = await DataStore.save(
                UpdatePlayerstates
              );
              console.log("ResultplayerStates", ResultplayerStates);
            } catch (error) {
              console.error("Error updating Statistics:", error);
            }
          } else {
            console.log("GoalCardExistInPlayer", GoalCardExistInPlayer);
          }
        }

        const existStatisticOutPlayer = await DataStore.query(
          StatisticsMaster,
          (statistics) =>
            statistics.and((statistics) => [
              statistics.tournamentID.eq(MatchObj.tournamentID),
              statistics.PlayerId.eq(ele.OutPlayer.PlayerId),
            ])
        );
        console.log("existStatisticOutPlayer", existStatisticOutPlayer, ele);

        if (existStatisticOutPlayer && existStatisticOutPlayer.length > 0) {
          try {
            const UpdatePlayerstates = StatisticsMaster.copyOf(
              existStatisticOutPlayer[0],
              (updated) => {
                updated.Games = updated.Games + 1;

                return updated;
              }
            );
            console.log("MinusGoalCardvalueFromStates", UpdatePlayerstates);

            const ResultplayerStates = await DataStore.save(UpdatePlayerstates);
            console.log("ResultplayerStates", ResultplayerStates);
          } catch (error) {
            console.error("Error updating Statistics:", error);
          }
        } else {
          let Matchteamdata = await DataStore.query(
            MatchTeamMaster,
            ele.MatchTeamID
          );
          console.log("MatchTeamMaster", Matchteamdata);

          let Obj = {
            TeamName: Matchteamdata.TeamName,
            TeamLogo: Matchteamdata.LogoURL,
            CreatedBy: Userdata.Name,
            LastUpdatedBy: Userdata.Name,
            Goals: 0,
            RedCards: 0,
            YellowCards: 0,
            Games: 1,
            Minutes: Number(ele.Time),
            tournamentID: MatchObj.tournamentID,
            PlayerId: ele.OutPlayer.PlayerId,
          };
          const StatesResp = await DataStore.save(new StatisticsMaster(Obj));
        }
      });
      await Promise.all(updatePromises);
    }

    if (deletedGoalcardArrlist && deletedGoalcardArrlist.length > 0) {
      const updatePromises = deletedGoalcardArrlist.map(async (ele) => {
        await RemoveGoalCardSub(ele, "completed", 0);
        let existStatistic = await getStatisticsPlayerdata(ele, "Revert");
        console.log("existStatistic", ele, existStatistic);
        try {
          const UpdatePlayerstates = StatisticsMaster.copyOf(
            existStatistic[0],
            (updated) => {
              updated.Goals =
                "Penalty" in ele ? updated.Goals - 1 : updated.Goals;
              updated.RedCards =
                "CardType" in ele && ele.CardType == "Red"
                  ? updated.RedCards - 1
                  : updated.RedCards;
              updated.YellowCards =
                "CardType" in ele && ele.CardType == "Yellow"
                  ? updated.YellowCards - 1
                  : updated.YellowCards;
              updated.tournamentID = MatchObj.tournamentID;
              return updated;
            }
          );
          console.log("MinusGoalCardvalueFromStates", UpdatePlayerstates);

          const ResultplayerStates = await DataStore.save(UpdatePlayerstates);
          console.log("ResultplayerStates", ResultplayerStates);
        } catch (error) {
          console.error("Error updating Statistics:", error);
        }
      });

      // Wait for all update promises to complete
      await Promise.all(updatePromises);
    }

    // handleRevertDeletedMatchStanding(MatchObject);
    let Statisticsarray = [];
    let Tournamentdata: any = await getTournamentObj(MatchObject.tournamentID);
    let Registrationsdata = await Tournamentdata.Registrations.toArray();
    let Registrationfinaldata = await Promise.all(Registrationsdata);
    console.log("Registrationfinaldata", Registrationfinaldata);
    console.log("Tournamentdata", Tournamentdata);
    console.log("helloMatchrevert", MatchObject, GoalcardArrlist);
    let GoalRedYellowCardlist = [...GoalcardArrlist];

    GoalRedYellowCardlist &&
      GoalRedYellowCardlist.length > 0 &&
      GoalRedYellowCardlist.map((ele) => {
        console.log("First", ele);
        if ("Penalty" in ele) {
          console.log("element", ele);
          handleRevertGoalSubmit(ele);
        } else if ("CardType" in ele) {
          handleRevertMatchCardSubmit(ele);
          console.log("element", ele);
        }
      });

    let AllTeamGoalCardSublist = GoalRedYellowCardlist.map((ele) => ({
      ...ele,
      Goal: Object.keys(ele).includes("Penalty")
        ? Object.values(ele).includes(false)
          ? 1
          : 0
        : 0,
      Yellow: Object.keys(ele).includes("CardType")
        ? Object.values(ele).includes("Yellow")
          ? 1
          : 0
        : 0,
      Red: Object.keys(ele).includes("CardType")
        ? Object.values(ele).includes("Red")
          ? 1
          : 0
        : 0,
    }));
    console.log("AllTeamGoalCardSublist", AllTeamGoalCardSublist);

    AllTeamGoalCardSublist &&
      AllTeamGoalCardSublist.length > 0 &&
      AllTeamGoalCardSublist.forEach(async (ele, idx) => {
        // let ExistingPlayer = await getStatisticsPlayerdata(ele);

        // console.log("ExistingPlayer", ExistingPlayer);
        if (Statisticsarray && Statisticsarray.length > 0) {
          console.log("Object", ele);
          const index = Statisticsarray.findIndex(
            (val) => val.PlayerId == ele.Player.PlayerId
          );
          if (index >= 0) {
            Statisticsarray[index].Goals =
              Statisticsarray[index].Goals + ele.Goal;
            Statisticsarray[index].RedCards =
              Statisticsarray[index].RedCards + ele.Red;
            Statisticsarray[index].YellowCards =
              Statisticsarray[index].YellowCards + ele.Yellow;
          } else {
            Statisticsarray.push({
              TeamName: ele.Team1.TeamName,
              TeamLogo: ele.Team1.LogoURL,
              CreatedBy: Userdata.Name,
              LastUpdatedBy: Userdata.Name,
              Goals: ele.Goal,
              RedCards: ele.Red,
              YellowCards: ele.Yellow,
              // Games: 1,
              Minutes: Number(ele.Time),
              tournamentID: MatchObj.tournamentID,
              PlayerId: ele.Player.PlayerId,
            });
          }
        } else {
          Statisticsarray.push({
            TeamName: ele.Team1.TeamName,
            TeamLogo: ele.Team1.LogoURL,
            CreatedBy: Userdata.Name,
            LastUpdatedBy: Userdata.Name,
            Goals: ele.Goal,
            RedCards: ele.Red,
            YellowCards: ele.Yellow,
            // Games: 1,
            Minutes: Number(ele.Time),
            tournamentID: MatchObj.tournamentID,
            PlayerId: ele.Player.PlayerId,
          });
        }
      });

    Statisticsarray &&
      Statisticsarray.length > 0 &&
      Statisticsarray.map(async (ele) => {
        let ExistingPlayer = await getStatisticsPlayerdata(ele, "hello");
        console.log("ExistingPlayer", ExistingPlayer);
        if (ExistingPlayer && ExistingPlayer.length > 0) {
          console.log("2ExistingPlayer", ExistingPlayer);
          try {
            const UpdatePlayerstates = StatisticsMaster.copyOf(
              ExistingPlayer[0],
              (updated) => {
                updated.Goals = updated.Goals + ele.Goals;
                updated.RedCards = updated.RedCards + ele.RedCards;
                updated.YellowCards = updated.YellowCards + ele.YellowCards;
                updated.tournamentID = MatchObj.tournamentID;

                // updated.Games = updated.Games + 1;
                return updated;
              }
            );
            console.log("UpdatePlayerstates", UpdatePlayerstates);
            const ResultplayerStates = await DataStore.save(UpdatePlayerstates);
            console.log("ResultplayerStates", ResultplayerStates);
          } catch (error) {
            console.error("Error updating Statistics:", error);
          }
        } else {
          console.log("hellostates", ele);
          try {
            console.log("Statesrcord", ele);
            const StatesResp = await DataStore.save(new StatisticsMaster(ele));
            console.log("StatesResp", StatesResp);
          } catch (error) {
            console.error("Error updating Statistics:", error);
          }
        }
      });

    let OldTeamfirst = await MatchObject.Team1;
    let OldTeamSecond = await MatchObject.Team2;
    let RegistrationTeam1 = Registrationfinaldata.filter(
      (ele) => ele.TeamName.trim() == OldTeamfirst.TeamName.trim()
    );

    let RegistrationTeam2 = Registrationfinaldata.filter(
      (ele) => ele.TeamName.trim() == OldTeamSecond.TeamName.trim()
    );
    let OldTeam1Result =
      OldTeamfirst.Score.FirstHalf +
      OldTeamfirst.Score.SecondHalf +
      // OldTeamfirst.Score.PenaltyShootout +
      OldTeamfirst.Score.ExtraTime;
    let OldTeam2Result =
      OldTeamSecond.Score.FirstHalf +
      OldTeamSecond.Score.SecondHalf +
      // OldTeamSecond.Score.PenaltyShootout +
      OldTeamSecond.Score.ExtraTime;
    let Oldpoints1 =
      OldTeam1Result > OldTeam2Result
        ? "W"
        : OldTeam1Result == OldTeam2Result
        ? "D"
        : "L";
    let OldTeam1Standing = {
      Against: OldTeam2Result,
      For: OldTeam1Result,
      Form:
        OldTeam1Result > OldTeam2Result
          ? "W"
          : OldTeam1Result == OldTeam2Result
          ? "D"
          : "L",
      Played: 1,
      Points:
        Oldpoints1 == "W"
          ? Tournamentdata.WinPoints
          : Oldpoints1 == "D"
          ? Tournamentdata.DrawPoints
          : Tournamentdata.LossPoints,
      Won: OldTeam1Result > OldTeam2Result ? 1 : 0,
      Drawn: OldTeam1Result == OldTeam2Result ? 1 : 0,
      Difference: OldTeam1Result - OldTeam2Result,
      Lost: OldTeam1Result < OldTeam2Result ? 1 : 0,
      id: RegistrationTeam1[0].id,
    };

    let Oldpoints =
      OldTeam2Result > OldTeam1Result
        ? "W"
        : OldTeam2Result == OldTeam1Result
        ? "D"
        : "L";
    let OldTeam2Standing = {
      Against: OldTeam1Result,
      For: OldTeam2Result,
      Form:
        OldTeam2Result > OldTeam1Result
          ? "W"
          : OldTeam2Result == OldTeam1Result
          ? "D"
          : "L",
      Played: 1,
      Points:
        Oldpoints == "W"
          ? Tournamentdata.WinPoints
          : Oldpoints == "D"
          ? Tournamentdata.DrawPoints
          : Tournamentdata.LossPoints,
      Won: OldTeam2Result > OldTeam1Result ? 1 : 0,
      Drawn: OldTeam2Result == OldTeam1Result ? 1 : 0,
      Difference: OldTeam2Result - OldTeam1Result,
      Lost: OldTeam2Result < OldTeam1Result ? 1 : 0,
      id: RegistrationTeam2[0].id,
    };

    let OldStanding = [];
    OldStanding.push(OldTeam1Standing, OldTeam2Standing);

    const getmatchobj = await DataStore.query(MatchMaster, MatchObject.id);

    // console.log("UpdatedMatchObj", getmatchobj);
    let finaldatateam1 = await getmatchobj.Team1;
    let finaldatateam2 = await getmatchobj.Team2;
    let teams1 = await DataStore.query(MatchTeamMaster, finaldatateam1.id);
    let teams2 = await DataStore.query(MatchTeamMaster, finaldatateam2.id);
    console.log("hellovinod", teams2, teams1);
    let NewTeamfirst: any = await getmatchobject(finaldatateam1.id);
    let NewTeamSecond: any = await getmatchobject(finaldatateam2.id);
    console.log("helloTeamsUpdated", NewTeamfirst, NewTeamSecond);
    let NewTeam1Result =
      NewTeamfirst.Score.FirstHalf +
      NewTeamfirst.Score.SecondHalf +
      // NewTeamfirst.Score.PenaltyShootout +
      NewTeamfirst.Score.ExtraTime;
    let NewTeam2Result =
      NewTeamSecond.Score.FirstHalf +
      NewTeamSecond.Score.SecondHalf +
      // NewTeamSecond.Score.PenaltyShootout +
      NewTeamSecond.Score.ExtraTime;

    let points1 =
      NewTeam1Result > NewTeam2Result
        ? "W"
        : NewTeam2Result == NewTeam1Result
        ? "D"
        : "L";
    let Team1Standing = {
      Against: NewTeam2Result,
      For: NewTeam1Result,
      Form:
        NewTeam1Result > NewTeam2Result
          ? "W"
          : NewTeam1Result == NewTeam2Result
          ? "D"
          : "L",
      Played: 1,
      Points:
        points1 == "W"
          ? Tournamentdata.WinPoints
          : points1 == "D"
          ? Tournamentdata.DrawPoints
          : Tournamentdata.LossPoints,

      Won: NewTeam1Result > NewTeam2Result ? 1 : 0,
      Drawn: NewTeam1Result == NewTeam2Result ? 1 : 0,
      Difference: NewTeam1Result - NewTeam2Result,
      Lost: NewTeam1Result < NewTeam2Result ? 1 : 0,
      id: RegistrationTeam1[0].id,
    };

    let points =
      NewTeam2Result > NewTeam1Result
        ? "W"
        : NewTeam2Result == NewTeam1Result
        ? "D"
        : "L";

    let Team2Standing = {
      Against: NewTeam1Result,
      For: NewTeam2Result,
      Form:
        NewTeam2Result > NewTeam1Result
          ? "W"
          : NewTeam2Result == NewTeam1Result
          ? "D"
          : "L",
      Played: 1,
      Points:
        points == "W"
          ? Tournamentdata.WinPoints
          : points == "D"
          ? Tournamentdata.DrawPoints
          : Tournamentdata.LossPoints,

      Won: NewTeam2Result > NewTeam1Result ? 1 : 0,
      Drawn: NewTeam2Result == NewTeam1Result ? 1 : 0,
      Difference: NewTeam2Result - NewTeam1Result,
      Lost: NewTeam2Result < NewTeam1Result ? 1 : 0,
      id: RegistrationTeam2[0].id,
    };

    let Standing = [];
    Standing.push(Team1Standing, Team2Standing);

    console.log("finalstandigndata", Standing, OldStanding);

    if (OldTeam1Result + OldTeam2Result !== NewTeam1Result + NewTeam2Result) {
      Standing &&
        Standing.length > 0 &&
        Standing.map(async (obj) => {
          let ExistingTeam = await getStandingData(
            obj.id,
            MatchObject.groupsmasterID
          );
          console.log("ExistingTeam", ExistingTeam);

          let oldStandingdata = OldStanding.find((ele) => ele.id === obj.id);
          console.log("oldStandingdata", obj, oldStandingdata);

          try {
            const Groupstandingresult = await GroupStanding.copyOf(
              ExistingTeam[0],
              (updated) => {
                updated.Against =
                  updated.Against - oldStandingdata.Against + obj.Against;
                updated.For = updated.For - oldStandingdata.For + obj.For;

                // updated.Form = updated.Form.unshift(obj.Form);
                updated.Played =
                  updated.Played - oldStandingdata.Played + obj.Played;
                updated.Points =
                  updated.Points -
                  oldStandingdata.Points +
                  (obj.Form == "W"
                    ? Tournamentdata.WinPoints
                    : obj.Form == "D"
                    ? Tournamentdata.DrawPoints
                    : Tournamentdata.LossPoints);
                updated.Won = updated.Won - oldStandingdata.Won + obj.Won;
                updated.Drawn =
                  updated.Drawn - oldStandingdata.Drawn + obj.Drawn;
                updated.Difference =
                  updated.Difference -
                  oldStandingdata.Difference +
                  obj.Difference;
                updated.Lost = updated.Lost - oldStandingdata.Lost + obj.Lost;

                return updated;
              }
            );
            console.log("Groupstandingresult", Groupstandingresult);
            const GroupStandingData = await DataStore.save(Groupstandingresult);
            console.log("GroupStandingData", GroupStandingData);
          } catch (error) {
            console.log("error", error);
          }
        });
    }

    setSunccessModalMesg("Match Completed");
    setModalVisible(true);
    GetMatchdata(MatchObj.id);
    await AsyncStorage.setItem("CurrentTournament", MatchObj.tournamentID);
    // navigation.replace("ManageTournament");
    navigation.replace("ManageTournament", {
      tournamentId: MatchObj.tournamentID,
    });
  };

  const GetMatchdata = async (Matchkey) => {
    const subscription = await DataStore.observeQuery(MatchMaster, (c) =>
      c.id.eq(Matchkey)
    ).subscribe(async (snapshot) => {
      console.log("snapshot", snapshot);
      // if (snapshot.isSynced == true) {
      const getMatchObj = snapshot.items;
      console.log("getMatchobj", getMatchObj);
      setMatchObject(getMatchObj[0]);
      if (
        getMatchObj[0].OnlineScoring == true &&
        getMatchObj[0].FirstHalfStarted == true
      ) {
        handleShowTime(getMatchObj[0]);
      }

      // setUpdatedPlayerMatchTeam(MatchTeamGetObj);
      let Teamfirst = await getMatchObj[0].Team1;
      let TeamSecond = await getMatchObj[0].Team2;
      let Team1GoalPromise = await Teamfirst.Goals.toArray();
      let Team1Goaldata = await Promise.all(Team1GoalPromise);
      let Team2GoalPromise = await TeamSecond.Goals.toArray();
      let Team2Goaldata = await Promise.all(Team2GoalPromise);
      let Team1CardPromise = await Teamfirst.Cards.toArray();
      let Team1Carddata = await Promise.all(Team1CardPromise);
      let Team2CardPromise = await TeamSecond.Cards.toArray();
      let Team2Carddata = await Promise.all(Team2CardPromise);
      let Team1SubsitutePromise = await Teamfirst.Substitutions.toArray();
      let Team1Subsitutedata = await Promise.all(Team1SubsitutePromise);
      let Team2SubsitutePromise = await TeamSecond.Substitutions.toArray();
      let Team2Subsitutedata = await Promise.all(Team2SubsitutePromise);
      console.log(
        "Team1Subsitutedata",
        Team1Goaldata,
        Team2Goaldata,
        Team1Subsitutedata,
        Team2Subsitutedata
      );
      const Team1GoalCardList = [
        ...Team1Goaldata,
        ...Team1Carddata,
        ...Team1Subsitutedata,
      ];
      const Team2GoalCardList = [
        ...Team2Goaldata,
        ...Team2Carddata,
        ...Team2Subsitutedata,
      ];
      // const Team1GoalCardList = Team1Goaldata.concat(
      //   Team1Carddata,
      //   Team1Subsitutedata
      // );
      // const Team2GoalCardList = Team2Goaldata.concat(Team2Carddata);

      let FirstTeamGoalCarddata = Team1GoalCardList.map(async (val) => ({
        ...val,
        // Playerdata: await getListofplayer(val.Player),
        Team1: Teamfirst,
      }));
      let FirstTeamGoalCard = await Promise.all(FirstTeamGoalCarddata);
      console.log("FirstTeamGoaldata", FirstTeamGoalCard);

      let SecondTeamGoalCarddata = Team2GoalCardList.map(async (val) => ({
        ...val,
        // Playerdata: await getListofplayer(val.Player),
        Team1: TeamSecond,
      }));
      let SecondTeamGoalCard = await Promise.all(SecondTeamGoalCarddata);
      console.log("SecondTeamGoalCard", SecondTeamGoalCard);
      let MergeTeam1Team2GoalCardList =
        FirstTeamGoalCard.concat(SecondTeamGoalCard);
      console.log("MergeTeam1Team2GoalCardList", MergeTeam1Team2GoalCardList);

      let Obj = {
        MatchNo:
          getMatchObj[0].MatchNo > 9
            ? `0${getMatchObj[0].MatchNo}`
            : `00${getMatchObj[0].MatchNo}`,
        GroupName: getMatchObj[0].GroupName,
        Team1: Teamfirst,
        Team2: TeamSecond,
        Team1Score:
          Teamfirst.Score.FirstHalf +
          Teamfirst.Score.SecondHalf +
          // Teamfirst.Score.PenaltyShootout +
          Teamfirst.Score.ExtraTime,
        Team2Score:
          TeamSecond.Score.FirstHalf +
          TeamSecond.Score.SecondHalf +
          // TeamSecond.Score.PenaltyShootout +
          TeamSecond.Score.ExtraTime,
        FirstHalfStarted: getMatchObj[0].FirstHalfStarted,
        OnlineScoring: getMatchObj[0].OnlineScoring,
        HalfTime: getMatchObj[0].HalfTime,
        Team1GoalCount: Team1Goaldata.length,
        Team2GoalCount: Team2Goaldata.length,
        id: getMatchObj[0].id,
        tournamentID: getMatchObj[0].tournamentID,
        Venue: getMatchObj[0].Venue,
        Date: getMatchObj[0].Date,
        GoalCardList: MergeTeam1Team2GoalCardList,
      };

      console.log("MatchObj", Obj);
      setMatchObj(Obj);
      // }
    });
    return () => {
      subscription.unsubscribe();
    };
  };
  const getUserdata = async () => {
    let userDetails = await AsyncStorage.getItem("UserDetail");

    let datauser = await DataStore.query(UserMaster, userDetails);
    console.log("userDetails", datauser);
    setUserDetails(datauser);
    return datauser;
  };

  const handleGoalTime = (value) => {
    setGoalTime(String(value).padStart(2, "0"));
  };

  useEffect(() => {
    let timer;

    const updateTimer = () => {
      // Compute the next values for minutes and seconds
      let nextMinutes = minutes;
      let nextSeconds = seconds + 1;

      // Adjust minutes if seconds reach 59
      if (nextSeconds === 60) {
        nextMinutes += 1;
        nextSeconds = 0;
      }

      // Update goal time if minutes reach the desired value
      // if (nextMinutes >= Number(Firsthalf)) {
      // }

      if (nextMinutes > minutes) {
        console.log("hellominutes", nextMinutes, minutes);
        handleGoalTime(nextMinutes);
      }
      // Update the state with the new values
      setMinutes(nextMinutes);
      setSeconds(nextSeconds);
    };

    if (isRunning) {
      timer = setInterval(updateTimer, 1000);
    }

    return () => clearInterval(timer);
  }, [isRunning, minutes, seconds]);

  const getRoundsdata = async (SelectedRoundId) => {
    let Roundsdatas = await DataStore.query(RoundMaster, SelectedRoundId);
    console.log("Roundsdatas", Roundsdatas);
    setSelectedRoundObejct(Roundsdatas);
  };

  const handleGetRound = async () => {
    const SelectedRoundid = await AsyncStorage.getItem("SelectedCurrentRound");
    console.log("SelectedRoundid", SelectedRoundid);
    getRoundsdata(SelectedRoundid);
  };
  useEffect(() => {
    handleGetRound();
    getUserdata();
    console.log("ROuteparameter", MatchID, Firsthalf);
    GetMatchdata(MatchID);
    // getMatchdataforCompletionMatch(MatchID);
  }, [MatchID, Firsthalf]);

  const handleGetDateTime = () => {
    const currentDate: Date = new Date();

    // Format the date and time
    const day: string | number = currentDate.getDate();
    const month: string | number = currentDate.getMonth() + 1; // Months are zero-based
    const year: number = currentDate.getFullYear();
    const hours: string | number = currentDate.getHours();
    const minutes: string | number = currentDate.getMinutes();
    const seconds: string | number = currentDate.getSeconds(); // Add seconds

    // Add leading zero if needed
    const formattedDay: string = day < 10 ? "0" + day : String(day);
    const formattedMonth: string = month < 10 ? "0" + month : String(month);
    const formattedHours: string = hours < 10 ? "0" + hours : String(hours);
    const formattedMinutes: string =
      minutes < 10 ? "0" + minutes : String(minutes);
    const formattedSeconds: string =
      seconds < 10 ? "0" + seconds : String(seconds); // Add formatted seconds

    // Create the formatted date and time string
    const formattedDateTime: string = `${formattedDay}-${formattedMonth}-${year} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

    console.log("formattedDateTime", formattedDateTime);
    return formattedDateTime;
  };

  const handleUpdateHalfExtra = async (FirsthalfSecondandExtra) => {
    console.log(
      "FirsthalfSecondandExtra",
      MatchObject,
      FirsthalfSecondandExtra,
      minutes
    );
    let CurrentDateTime = await handleGetDateTime();
    var dateTime = moment(CurrentDateTime, "DD-MM-YYYY HH:mm:ss");
    const formattedDate = dateTime.toISOString();
    console.log("formattedDate", formattedDate);
    // let Matchobj = { ...MatchObject };
    const Matchobj = await DataStore.query(MatchMaster, MatchObject.id);
    console.log("Matchobj", Matchobj);
    if (Matchobj) {
      const MatchTeamdataUpdate = MatchMaster.copyOf(Matchobj, (updated) => {
        updated.FirstHalfStarted =
          FirsthalfSecondandExtra == "Start 1st Half"
            ? true
            : updated.FirstHalfStarted;
        updated.SecondHalfStarted =
          FirsthalfSecondandExtra == "Start 2nd Half"
            ? true
            : updated.SecondHalfStarted;
        updated.ExtraTimeStarted =
          FirsthalfSecondandExtra == "Start Extra Time"
            ? true
            : updated.ExtraTimeStarted;
        updated.FirstHalfEnded =
          FirsthalfSecondandExtra == "End 1st Half"
            ? true
            : updated.FirstHalfEnded;
        updated.SecondHalfEnded =
          FirsthalfSecondandExtra == "End 2nd Half"
            ? true
            : updated.SecondHalfEnded;
        updated.ExtraTimeEnded =
          FirsthalfSecondandExtra == "End Extra Time"
            ? true
            : updated.ExtraTimeEnded;
        updated.FirstHalfEndMinutes =
          FirsthalfSecondandExtra == "End 1st Half"
            ? minutes
            : updated.FirstHalfEndMinutes;
        updated.SecondHalfEndMinutes =
          FirsthalfSecondandExtra == "End 2nd Half"
            ? minutes
            : updated.SecondHalfEndMinutes;
        updated.ExtraTimeEndMinutes =
          FirsthalfSecondandExtra == "End Extra Time"
            ? minutes
            : updated.ExtraTimeEndMinutes;
        updated.FirstHalfStartTime =
          FirsthalfSecondandExtra == "Start 1st Half"
            ? formattedDate
            : updated.FirstHalfStartTime;
        updated.FirstHalfEndTime =
          FirsthalfSecondandExtra == "End 1st Half"
            ? formattedDate
            : updated.FirstHalfEndTime;
        updated.SecondHalfStarTime =
          FirsthalfSecondandExtra == "Start 2nd Half"
            ? formattedDate
            : updated.SecondHalfStarTime;
        updated.SecondHalfEndime =
          FirsthalfSecondandExtra == "End 2nd Half"
            ? formattedDate
            : updated.SecondHalfEndime;
        updated.ExtraTimeStartTime =
          FirsthalfSecondandExtra == "Start Extra Time"
            ? formattedDate
            : updated.ExtraTimeStartTime;
        updated.ExtraTimeEndTime =
          FirsthalfSecondandExtra == "End Extra Time"
            ? formattedDate
            : updated.ExtraTimeEndTime;

        return updated;
      });
      console.log("MatchTeamdataUpdate", MatchTeamdataUpdate);
      const ResultofMatchTeam = await DataStore.save(MatchTeamdataUpdate);
      console.log("ResultofMatchTeam", ResultofMatchTeam);
      GetMatchdata(ResultofMatchTeam.id);
    }
  };

  const handleFirsthalfSecondhalf = () => {
    console.log("hellovalue", MatchObject, seconds, minutes, Firsthalf);

    handleUpdateHalfExtra(firsthalfSecondExtraTime);

    setAddgoalcardhiseshowflag("Start 1st Half");
    if (firsthalfSecondExtraTime == "Start 1st Half") {
      setAllhalfstart("Start 1st half");
      // setGoalTime(String(seconds).padStart(2, "0"));

      setfirsthalfSecondExtraTime("End 1st Half");
      setIsRunning(true);
    }
    if (firsthalfSecondExtraTime == "End 1st Half") {
      setIsRunning(false);
      setAllhalfstart("");
      setfirsthalfSecondExtraTime("Start 2nd Half");
      setMinutes(0);
      setSeconds(0);
    }
    if (firsthalfSecondExtraTime == "Start 2nd Half") {
      setMinutes(Number(MatchObject.HalfTime));
      setGoalTime(MatchObject.HalfTime);
      setIsRunning(true);
      setSeconds(0);
      setfirsthalfSecondExtraTime("End 2nd Half");
    }
    if (firsthalfSecondExtraTime == "End 2nd Half") {
      // setGoalTime("01");
      setAllhalfstart("");
      // setIsRunning(false);
      setMinutes(0);
      // setSeconds(0);
      setfirsthalfSecondExtraTime("Start Extra Time");
      //  setfirsthalfSecondExtraTime("Start Extra Time");
    }

    if (firsthalfSecondExtraTime == "Start Extra Time") {
      setMinutes(Number(Firsthalf));
      setGoalTime(String(Firsthalf).padStart(2, "0"));
      // setIsRunning(true);
      setSeconds(0);
      setfirsthalfSecondExtraTime("End Extra Time");
    }
    if (firsthalfSecondExtraTime == "End Extra Time") {
      setGoalTime("01");
      setAllhalfstart("");
      setIsRunning(false);
      setMinutes(0);
      setSeconds(0);
      setfirsthalfSecondExtraTime("Start 1st Half");
      //  setfirsthalfSecondExtraTime("Start Extra Time");
    }
  };

  const handleSelectPlayer = (playerobj) => {
    console.log("playerobj", playerobj);
    setSelectedPlayerobj(playerobj);
    setSelectedPlayerFlag(true);
  };

  const handleSelectSubPlayer = (playerobj) => {
    console.log("playerobj", playerobj);
    setSelectedSubPlayerFlag(true);
    setSelectedSubsitutePlayerObj(playerobj);
  };
  const EditSelectedPlayer = () => {
    setSelectedPlayerobj({});
    setSelectedPlayerFlag(false);
  };

  const EditSubPlayer = () => {
    setSelectedSubsitutePlayerObj({});
    setSelectedSubPlayerFlag(false);
  };

  const handleOwnGoalClick = (value) => {
    console.log("OwnGoal", value);
    setOwnGoalflag(!OwnGoalflag);
  };

  const handleClickTime = (value) => {
    console.log("handleClickTime", value);
    setGoalTime(value);
  };

  const handleRevertMatchSubsitute = async (playerdata) => {
    console.log(
      "helloSUbsitute",
      SelectedSubsitutePlayerObj,
      SelectedPlayerobj,
      SelectedTeamObj
    );
    let Halfvalue = Half;
    let selectedHalfTime;

    if (MatchObj.OnlineScoring == false) {
      console.log("Goal Updated Offline");
      selectedHalfTime =
        Number(GoalTime) <= MatchObj.HalfTime
          ? Halfvalue.FIRST
          : Number(GoalTime) <= 2 * MatchObj.HalfTime
          ? Halfvalue.SECOND
          : Halfvalue.EXTRA_TIME;
    } else {
      console.log("Goal Updated Online");
      selectedHalfTime = firsthalfSecondExtraTime.includes("1st Half")
        ? Halfvalue.FIRST
        : firsthalfSecondExtraTime.includes("2nd Half")
        ? Halfvalue.SECOND
        : Halfvalue.EXTRA_TIME;
    }
    let CopyofSelectedTeam = SelectedTeamObj.Players;

    console.log("CopyofSelectedTeam", CopyofSelectedTeam);
    let finalPlayerlist = CopyofSelectedTeam.map((ele) => {
      if (ele.PlayerId == SelectedSubsitutePlayerObj.PlayerId) {
        ele = Object.assign({}, ele, { Starting: true });
        console.log("helloworld", ele);
        return ele;
      }
      if (ele.PlayerId == SelectedPlayerobj.PlayerId) {
        ele = Object.assign({}, ele, { Starting: false });
        console.log("helloworld", ele);
        return ele;
      }
      return ele;
    });
    console.log("CopyofSelectedTeam2", SelectedTeamObj, finalPlayerlist);

    try {
      const MatchTeamdata = await DataStore.query(
        MatchTeamMaster,
        SelectedTeamObj.id
      );
      console.log("MatchTeamdata", MatchTeamdata);
      if (MatchTeamdata) {
        const MatchTeamdataUpdate = MatchTeamMaster.copyOf(
          MatchTeamdata,
          (updated) => {
            updated.Players = finalPlayerlist;
            return updated;
          }
        );

        const ResultofMatchTeam = await DataStore.save(MatchTeamdataUpdate);
        console.log("ResultofMatchTeam", ResultofMatchTeam);
        if (ResultofMatchTeam) {
          try {
            const SubsituteResp = await DataStore.save(
              new SubstitutionMaster(playerdata)
            );
            console.log("SubsituteResp", SubsituteResp);
          } catch (error) {
            console.log("error", error);
          }
        }
      } else {
        console.error("Tournament not found");
      }
    } catch (error) {
      console.error("Error updating tournament:", error);
    }
  };

  const handleGoalSubmit = async () => {
    let AllMatchTeam = [];
    let selectteamObj;
    let MatchTeamdataUpdate = {};
    let Playerobj = { ...SelectedPlayerobj };
    let MatcTeamRecord = {
      Team1: MatchObj.Team1,
      Team2: MatchObj.Team2,
    };
    AllMatchTeam.push(MatchObj.Team1, MatchObj.Team2);
    console.log("OwnGoalflag", AllMatchTeam, OwnGoalflag);
    console.log(
      "handleGoal",
      SelectedPlayerobj,
      OwnGoalflag,
      GoalTime,
      MatchObj,
      firsthalfSecondExtraTime
    );

    if (OwnGoalflag == true) {
      let teamfilter = AllMatchTeam.filter(
        (ele) => ele.id !== SelectedTeamObj.id
      );
      console.log("teamfilter", teamfilter);
      selectteamObj = teamfilter[0];
      console.log("selectteamObj", selectteamObj);

      Playerobj = Object.assign({}, Playerobj, {
        Name: `${Playerobj.Name}(OG)`,
      });
      console.log("helloplayers", Playerobj);
    } else {
      selectteamObj = { ...SelectedTeamObj };
      console.log("falseSelectedteamobj", selectteamObj);
    }

    let Halfvalue = Half;
    let selectedHalfTime;

    if (MatchObj.OnlineScoring == false) {
      console.log("Goal Updated Offline");
      selectedHalfTime =
        Number(GoalTime) <= MatchObj.HalfTime
          ? Halfvalue.FIRST
          : Number(GoalTime) <= 2 * MatchObj.HalfTime
          ? Halfvalue.SECOND
          : Halfvalue.EXTRA_TIME;
    } else {
      console.log("Goal Updated Online");
      selectedHalfTime = firsthalfSecondExtraTime.includes("1st Half")
        ? Halfvalue.FIRST
        : firsthalfSecondExtraTime.includes("2nd Half")
        ? Halfvalue.SECOND
        : Halfvalue.EXTRA_TIME;
    }

    let GoalObj: any = {
      Penalty: false,
      CreatedBy: UserDetails.Name,
      LastUpdatedBy: UserDetails.Name,
      Time: GoalTime,
      Half: selectedHalfTime,
      MatchTeamID: selectteamObj.id,
      TournamentID: MatchObj.tournamentID,
      Player: Playerobj,
    };
    console.log("GoalObj", GoalObj);

    if (MatchObject.Status !== "COMPLETED") {
      try {
        const Goalresponse = await DataStore.save(new GoalMaster(GoalObj));
        console.log("GroupResponse", Goalresponse);
        if (Goalresponse) {
          // handleSuccessModalShow("")
        }
      } catch (error) {
        console.log("error", error);
      }
      try {
        const MatchTeamdata = await DataStore.query(
          MatchTeamMaster,
          selectteamObj.id
        );
        console.log("MatchTeamdata", MatchTeamdata);
        if (MatchObj.OnlineScoring == false) {
          console.log(
            "Offline Fixture Updated",
            Number(GoalTime),
            MatchObj.HalfTime,
            Number(GoalTime) <= MatchObj.HalfTime
              ? MatchTeamdata.Score.FirstHalf + 1
              : MatchTeamdata.Score.FirstHalf
          );
          MatchTeamdataUpdate = MatchTeamMaster.copyOf(
            MatchTeamdata,
            (updated) => {
              updated.Score.FirstHalf =
                Number(GoalTime) <= MatchObj.HalfTime
                  ? updated.Score.FirstHalf + 1
                  : updated.Score.FirstHalf;
              updated.Score.SecondHalf =
                Number(GoalTime) <= 2 * MatchObj.HalfTime &&
                Number(GoalTime) > MatchObj.HalfTime
                  ? updated.Score.SecondHalf + 1
                  : updated.Score.SecondHalf;
              updated.Score.PenaltyShootout = updated.Score.PenaltyShootout;
              updated.Score.ExtraTime =
                Number(GoalTime) > 2 * MatchObj.HalfTime
                  ? updated.Score.ExtraTime + 1
                  : updated.Score.ExtraTime;
              return updated;
            }
          );
        } else {
          console.log("Online Fixture Update");
          MatchTeamdataUpdate = MatchTeamMaster.copyOf(
            MatchTeamdata,
            (updated) => {
              updated.Score.FirstHalf = firsthalfSecondExtraTime.includes(
                "1st Half"
              )
                ? updated.Score.FirstHalf + 1
                : updated.Score.FirstHalf;
              updated.Score.SecondHalf = firsthalfSecondExtraTime.includes(
                "2nd Half"
              )
                ? updated.Score.SecondHalf + 1
                : updated.Score.SecondHalf;
              updated.Score.PenaltyShootout = updated.Score.PenaltyShootout;
              updated.Score.ExtraTime = firsthalfSecondExtraTime.includes(
                "Extra Time"
              )
                ? updated.Score.ExtraTime + 1
                : updated.Score.ExtraTime;
              return updated;
            }
          );
        }

        // if (MatchTeamdata) {

        console.log("MatchTeamdataUpdate", MatchTeamdataUpdate);
        const ResultofMatchTeam = await DataStore.save(MatchTeamdataUpdate);
        console.log("ResultofMatchTeam", ResultofMatchTeam);
        if (ResultofMatchTeam) {
          setOwnGoalflag(false);
          setGoalTime("");
          setActiveTab(null);
          setSelectedPlayerobj({});

          GetMatchdata(MatchObj.id);
          setSunccessModalMesg("Score Updated Successfully");
          setModalVisible(true);
        }
        // } else {
        //   console.error("Tournament not found");
        // }
      } catch (error) {
        console.error("Error updating tournament:", error);
      }
    } else {
      setSelectedPlayerobj({});
      setGoalTime("01");
      setActiveTab(null);
      console.log("GoalSubmitMatchobj", MatchObj);
      let teamObj = await DataStore.query(MatchTeamMaster, GoalObj.MatchTeamID);
      console.log("SelectedTeamteamObj", teamObj);
      let copyOfMatchobj = { ...MatchObj };

      if (MatchObj.Team1.TeamName == teamObj.TeamName) {
        copyOfMatchobj.Team1Score = MatchObj.Team1Score + 1;
      }

      if (MatchObj.Team2.TeamName == teamObj.TeamName) {
        copyOfMatchobj.Team2Score = MatchObj.Team2Score + 1;
      }
      console.log("copyOfMatchobj", copyOfMatchobj);
      // function findTeamKey(matchTeamID, teams) {
      //   for (let key in teams) {
      //     if (teams[key].id === matchTeamID) {
      //       return key;
      //     }
      //   }
      //   return null;
      // }

      // let matchingTeamKey = findTeamKey(GoalObj.MatchTeamID, MatcTeamRecord);

      // if (matchingTeamKey) {
      //   GoalObj[matchingTeamKey] = MatcTeamRecord[matchingTeamKey];
      // }
      GoalObj.Team1 = MatcTeamRecord.Team1;
      console.log("GoalObj", GoalObj);

      let Goallist = MatchObj.GoalCardList;
      Goallist.push(GoalObj);
      copyOfMatchobj.GoalCardList = Goallist;
      console.log("copyOfMatchobj", copyOfMatchobj);
      setMatchObj(copyOfMatchobj),
        setGoalcardArrlist([...GoalcardArrlist, GoalObj]);
    }
  };

  const handleClickCards = (cardselect) => {
    console.log("cardselect", cardselect);
    setSelectedCard(cardselect);
  };
  const handleCardSubmit = async () => {
    console.log(
      "CardSubmit",
      SelectedPlayerobj,
      SelectedCard,
      GoalTime,
      MatchObj
    );
    let MatcTeamRecord = {
      Team1: MatchObj.Team1,
      Team2: MatchObj.Team2,
    };
    let Halfvalue = Half;
    let selectedHalfTime;

    if (MatchObj.OnlineScoring == false) {
      console.log("Goal Updated Offline");
      selectedHalfTime =
        Number(GoalTime) <= MatchObj.HalfTime
          ? Halfvalue.FIRST
          : Number(GoalTime) <= 2 * MatchObj.HalfTime
          ? Halfvalue.SECOND
          : Halfvalue.EXTRA_TIME;
    } else {
      console.log("Goal Updated Online");
      selectedHalfTime = firsthalfSecondExtraTime.includes("1st Half")
        ? Halfvalue.FIRST
        : firsthalfSecondExtraTime.includes("2nd Half")
        ? Halfvalue.SECOND
        : Halfvalue.EXTRA_TIME;
    }
    let CardObj: any = {
      CardType: SelectedCard,
      CreatedBy: UserDetails.Name,
      LastUpdatedBy: UserDetails.Name,
      Time: GoalTime,
      Half: selectedHalfTime,
      MatchTeamID: SelectedTeamObj.id,
      TournamentID: MatchObj.tournamentID,
      Player: SelectedPlayerobj,
    };

    if (MatchObject.Status !== "COMPLETED") {
      try {
        const GroupResponse = await DataStore.save(new CardMaster(CardObj));
        console.log("GroupResponse", GroupResponse);
        if (GroupResponse) {
          setSelectedCard("");
          setGoalTime("");
          setActiveTab(null);
          setSelectedPlayerobj({});
          GetMatchdata(MatchObj.id);
          setSunccessModalMesg("Score Updated Successfully");
          setModalVisible(true);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      setSelectedCard("");
      setGoalTime("");
      setActiveTab(null);
      setSelectedPlayerobj({});
      // function findTeamKey(matchTeamID, teams) {
      //   for (let key in teams) {
      //     if (teams[key].id === matchTeamID) {
      //       return key;
      //     }
      //   }
      //   return null;
      // }

      // // Find the matching team key
      // let matchingTeamKey = findTeamKey(CardObj.MatchTeamID, MatcTeamRecord);

      // // Create the new object by merging obj and matching team information
      // if (matchingTeamKey) {
      //   CardObj[matchingTeamKey] = MatcTeamRecord[matchingTeamKey];
      // }

      // console.log("CardObj", CardObj);
      CardObj.Team1 = MatcTeamRecord.Team1;
      let Goallist = MatchObj.GoalCardList;
      Goallist.push(CardObj);
      console.log("GoaList", Goallist);
      setMatchObj({
        ...MatchObj,
        GoalCardList: Goallist,
      }),
        setGoalcardArrlist([...GoalcardArrlist, CardObj]);
    }
  };

  const handleSubsituteSubmit = async () => {
    console.log(
      "helloSUbsitute",
      SelectedSubsitutePlayerObj,
      SelectedPlayerobj,
      SelectedTeamObj
    );
    let Halfvalue = Half;
    let selectedHalfTime;
    let MatcTeamRecord = {
      Team1: MatchObj.Team1,
      Team2: MatchObj.Team2,
    };
    if (MatchObj.OnlineScoring == false) {
      console.log("Goal Updated Offline");
      selectedHalfTime =
        Number(GoalTime) <= MatchObj.HalfTime
          ? Halfvalue.FIRST
          : Number(GoalTime) <= 2 * MatchObj.HalfTime
          ? Halfvalue.SECOND
          : Halfvalue.EXTRA_TIME;
    } else {
      console.log("Goal Updated Online");
      selectedHalfTime = firsthalfSecondExtraTime.includes("1st Half")
        ? Halfvalue.FIRST
        : firsthalfSecondExtraTime.includes("2nd Half")
        ? Halfvalue.SECOND
        : Halfvalue.EXTRA_TIME;
    }

    let SubsituteObj: any = {
      CreatedBy: UserDetails.Name,
      LastUpdatedBy: UserDetails.Name,
      Time: GoalTime,
      Half: selectedHalfTime,
      InPlayer: SelectedPlayerobj,
      OutPlayer: SelectedSubsitutePlayerObj,
      MatchTeamID: SelectedTeamObj.id,
    };
    console.log("SubsituteObj", SubsituteObj);

    let CopyofSelectedTeam = SelectedTeamObj.Players;

    console.log("CopyofSelectedTeam", CopyofSelectedTeam);
    let finalPlayerlist = CopyofSelectedTeam.map((ele) => {
      if (ele.PlayerId == SelectedSubsitutePlayerObj.PlayerId) {
        ele = Object.assign({}, ele, { Starting: true });
        console.log("helloworld", ele);
        SubsituteObj.OutPlayer = Object.assign({}, SubsituteObj.OutPlayer, {
          Starting: true,
        });
        return ele;
      }
      if (ele.PlayerId == SelectedPlayerobj.PlayerId) {
        ele = Object.assign({}, ele, { Starting: false });
        console.log("helloworld", ele);
        SubsituteObj.InPlayer = Object.assign({}, SubsituteObj.InPlayer, {
          Starting: false,
        });
        return ele;
      }
      return ele;
    });
    console.log(
      "CopyofSelectedTeam2",
      SelectedTeamObj,
      SubsituteObj,
      finalPlayerlist
    );
    if (MatchObject.Status !== "COMPLETED") {
      try {
        const MatchTeamdata = await DataStore.query(
          MatchTeamMaster,
          SelectedTeamObj.id
        );
        console.log("MatchTeamdata", MatchTeamdata);
        if (MatchTeamdata) {
          const MatchTeamdataUpdate = MatchTeamMaster.copyOf(
            MatchTeamdata,
            (updated) => {
              updated.Players = finalPlayerlist;
              return updated;
            }
          );
          const ResultofMatchTeam = await DataStore.save(MatchTeamdataUpdate);
          console.log("ResultofMatchTeam", ResultofMatchTeam);
          if (ResultofMatchTeam) {
            try {
              const SubsituteResp = await DataStore.save(
                new SubstitutionMaster(SubsituteObj)
              );
              console.log("SubsituteResp", SubsituteResp);
              if (SubsituteResp) {
                setSelectedSubsitutePlayerObj({});
                setSelectedPlayerobj({});
                setSelectedTeamObj({});
                setGoalTime("");
                setSelectedPlayerList([]);
                setActiveTab(null);
                GetMatchdata(MatchObj.id);
                setSunccessModalMesg("Player Subsitute Successfully");
                setModalVisible(true);
              }
            } catch (error) {
              console.log("error", error);
            }
          }
        } else {
          console.error("Tournament not found");
        }
      } catch (error) {
        console.error("Error updating tournament:", error);
      }
    } else {
      // function findTeamKey(matchTeamID, teams) {
      //   for (let key in teams) {
      //     if (teams[key].id === matchTeamID) {
      //       return key;
      //     }
      //   }
      //   return null;
      // }

      // // Find the matching team key
      // let matchingTeamKey = findTeamKey(
      //   SubsituteObj.MatchTeamID,
      //   MatcTeamRecord
      // );

      // // Create the new object by merging obj and matching team information
      // if (matchingTeamKey) {
      //   SubsituteObj[matchingTeamKey] = MatcTeamRecord[matchingTeamKey];
      // }

      // console.log("GoalObj", GoalObj);
      SubsituteObj.Team1 = MatcTeamRecord.Team1;
      let Goallist = MatchObj.GoalCardList;
      Goallist.push(SubsituteObj);
      console.log("GoaList", Goallist);
      setMatchObj({
        ...MatchObj,
        GoalCardList: Goallist,
      }),
        setSusbituteArryrevertMatch([
          ...susbituteArryrevertMatch,
          SubsituteObj,
        ]);
      console.log("UniqueSubsitute", finalPlayerlist);
      let Playerlist = finalPlayerlist.filter((obj) => obj.Starting == true);
      let Subsitute = finalPlayerlist.filter((obj) => obj.Starting == false);
      console.log("PlayingSubsitute", Playerlist, Subsitute);
      setSelectedSubList(Subsitute);
      setSelectedPlayerList(Playerlist);

      setSelectedSubsitutePlayerObj({});
      setSelectedPlayerobj({});
      setGoalTime("");
      setSelectedPlayerFlag(false);
      setSelectedSubPlayerFlag(false);
    }
  };

  const handlerevertMatchSubsitute = async (data) => {
    console.log("handlerevertMatchSubsitute", data);
    console.log(
      "helloSUbsitute",
      SelectedSubsitutePlayerObj,
      SelectedPlayerobj,
      SelectedTeamObj
    );
    let Halfvalue = Half;
    let selectedHalfTime;

    if (MatchObj.OnlineScoring == false) {
      console.log("Goal Updated Offline");
      selectedHalfTime =
        Number(GoalTime) <= MatchObj.HalfTime
          ? Halfvalue.FIRST
          : Number(GoalTime) <= 2 * MatchObj.HalfTime
          ? Halfvalue.SECOND
          : Halfvalue.EXTRA_TIME;
    } else {
      console.log("Goal Updated Online");
      selectedHalfTime = firsthalfSecondExtraTime.includes("1st Half")
        ? Halfvalue.FIRST
        : firsthalfSecondExtraTime.includes("2nd Half")
        ? Halfvalue.SECOND
        : Halfvalue.EXTRA_TIME;
    }

    // let SubsituteObj: any = {
    //   CreatedBy: UserDetails.Name,
    //   LastUpdatedBy: UserDetails.Name,
    //   Time: GoalTime,
    //   Half: selectedHalfTime,
    //   InPlayer: SelectedPlayerobj,
    //   OutPlayer: SelectedSubsitutePlayerObj,
    //   MatchTeamID: SelectedTeamObj.id,
    // };
    // console.log("SubsituteObj", SubsituteObj);

    let CopyofSelectedTeam = SelectedTeamObj.Players;

    console.log("CopyofSelectedTeam", CopyofSelectedTeam);
    let finalPlayerlist = CopyofSelectedTeam.map((ele) => {
      if (ele.PlayerId == data.OutPlayer.PlayerId) {
        ele = Object.assign({}, ele, { Starting: true });
        console.log("helloworld", ele);
        return ele;
      }
      if (ele.PlayerId == data.InPlayer.PlayerId) {
        ele = Object.assign({}, ele, { Starting: false });
        console.log("helloworld", ele);
        return ele;
      }
      return ele;
    });
    console.log(
      "CopyofSelectedTeam2",
      SelectedTeamObj,
      // SubsituteObj,
      finalPlayerlist
    );
    try {
      const MatchTeamdata = await DataStore.query(
        MatchTeamMaster,
        SelectedTeamObj.id
      );
      console.log("MatchTeamdata", MatchTeamdata);
      if (MatchTeamdata) {
        const MatchTeamdataUpdate = MatchTeamMaster.copyOf(
          MatchTeamdata,
          (updated) => {
            updated.Players = finalPlayerlist;
            return updated;
          }
        );
        console.log("MatchTeamdataUpdate", MatchTeamdataUpdate);
        const ResultofMatchTeam = await DataStore.save(MatchTeamdataUpdate);
        console.log("ResultofMatchTeam", ResultofMatchTeam);
        if (ResultofMatchTeam) {
          try {
            const SubsituteResp = await DataStore.save(
              new SubstitutionMaster(data)
            );
            console.log("SubsituteResp", SubsituteResp);
          } catch (error) {
            console.log("error", error);
          }
        }
      } else {
        console.error("Tournament not found");
      }
    } catch (error) {
      console.error("Error updating tournament:", error);
    }
  };

  const getTournamentObj = async (key) => {
    console.log("key", key);
    let Tournamentdata = DataStore.query(Tournament, key);
    return Tournamentdata;
  };

  const getmatchobject = async (key) => {
    console.log("helloTournament", key);

    return new Promise(async (resolve) => {
      let matchObj;
      const subscription = await DataStore.observeQuery(MatchTeamMaster, (c) =>
        c.id.eq(key)
      ).subscribe((snapshot) => {
        if (snapshot.isSynced) {
          matchObj = snapshot.items;
          console.log("FindPlayerData", matchObj, matchObj[0]);
          resolve(matchObj[0]);
        }
      });

      // Unsubscribe when the promise resolves or the function is complete
      return () => {
        subscription.unsubscribe();
      };
    });
  };

  const handlegetGroupData = (RoundData) => {
    console.log("RoundData", RoundData);
    return new Promise(async (resolve) => {
      let subscription = await DataStore.observeQuery(GroupsMaster, (c) =>
        c.RoundMasterID.eq(RoundData.id)
      ).subscribe(async (snapshot) => {
        if (snapshot.isSynced == true) {
          console.log("snapshot", snapshot);
          let Groupdata = snapshot.items;
          // console.log("Groupdata", Groupdata);
          resolve(Groupdata[0]);
        }
      });
    });
  };

  const handleComplete = () => {
    setSunccessModalMesg("Are you Sure to you want to finish this Match.");
    setModalVisible(true);
  };

  // const CompleteMatch = async (getMatchObj, MatchesPromises) => {
  //   let Tournamentdata: any = await getTournamentObj(getMatchObj.tournamentID);
  //   console.log("Tournamentdata", Tournamentdata);
  //   handlecheckMatchCompletion(Tournamentdata);
  // }

  const handlecheckMatchCompletion = async (tournamentobj) => {
    let Tournamentdata = await DataStore.query(Tournament, tournamentobj.id);
    const matchlist = await DataStore.query(MatchMaster, (match) =>
      match.tournamentData.id.eq(Tournamentdata.id)
    );

    let filteredvalue =
      matchlist &&
      matchlist.length > 0 &&
      matchlist.filter((ele) => ele.Status !== "COMPLETED");
    console.log("checkCompletedmatch", filteredvalue, matchlist);
    if (filteredvalue && filteredvalue.length == 0) {
      const getTournament = await DataStore.query(
        Tournament,
        Tournamentdata.id
      );
      const UpdatedTournament = await Tournament.copyOf(
        getTournament,
        (updated) => {
          updated.Status = "COMPLETED";

          return updated;
        }
      );
      console.log("UpdatedTournament", UpdatedTournament);
      try {
        const ResultTournament = await DataStore.save(UpdatedTournament);
        console.log("ResultTournament", ResultTournament);
      } catch (error) {
        console.log("CompletematchError", error);
      }
    }
  };

  const CompleteMatch = async (getMatchObj, MatchesPromises) => {
    let Statisticsarray = [];
    let Standingarr = [];
    console.log("getMatchobj", getMatchObj);
    let Tournamentdata: any = await getTournamentObj(getMatchObj.tournamentID);
    console.log("Tournamentdata", Tournamentdata);
    let Registrationsdata = await Tournamentdata.Registrations.toArray();
    let Registrationfinaldata = await Promise.all(Registrationsdata);
    console.log("Registrationfinaldata", Registrationfinaldata);

    // setUpdatedPlayerMatchTeam(MatchTeamGetObj);
    let Teamfirst = await getMatchObj.Team1;
    let TeamSecond = await getMatchObj.Team2;
    let RegistrationTeam1 = Registrationfinaldata.filter(
      (ele) => ele.TeamName.trim() == Teamfirst.TeamName.trim()
    );

    let RegistrationTeam2 = Registrationfinaldata.filter(
      (ele) => ele.TeamName.trim() == TeamSecond.TeamName.trim()
    );
    let Team1GoalPromise = await Teamfirst.Goals.toArray();
    let Team1Goaldata = await Promise.all(Team1GoalPromise);
    let Team2GoalPromise = await TeamSecond.Goals.toArray();
    let Team2Goaldata = await Promise.all(Team2GoalPromise);
    let Team1CardPromise = await Teamfirst.Cards.toArray();
    let Team1Carddata = await Promise.all(Team1CardPromise);
    let Team2CardPromise = await TeamSecond.Cards.toArray();
    let Team2Carddata = await Promise.all(Team2CardPromise);
    let Team1SubsitutePromise = await Teamfirst.Substitutions.toArray();
    let Team1Subsitutedata = await Promise.all(Team1SubsitutePromise);
    let Team2SubsitutePromise = await TeamSecond.Substitutions.toArray();
    let Team2Subsitutedata = await Promise.all(Team2SubsitutePromise);
    console.log("Team1Subsitutedata", Team1Subsitutedata, Team2Subsitutedata);

    let SubsitutedataTeam1 = Team1Subsitutedata.map(async (val) => ({
      ...val,
      Player: val.OutPlayer,
      Penalty: 0,
      YellowCard: 0,
      RedCard: 0,
    }));

    let Team1AllSubsitutedata = await Promise.all(SubsitutedataTeam1);
    let SubsitutedataTeam2 = Team2Subsitutedata.map(async (val) => ({
      ...val,
      Player: val.OutPlayer,
    }));
    let Team2AllSubsitutedata = await Promise.all(SubsitutedataTeam2);
    console.log(
      "SubsitutedataTeam1",
      Team1AllSubsitutedata,
      Team2AllSubsitutedata
    );

    const Team1GoalCardList = [
      ...Team1Goaldata,
      ...Team1Carddata,
      ...Team1AllSubsitutedata,
    ];
    const Team2GoalCardList = [
      ...Team2Goaldata,
      ...Team2Carddata,
      ...Team2AllSubsitutedata,
    ];
    console.log("Teamfirst", Teamfirst, TeamSecond);
    let Team1data = Teamfirst.Players.map((ele) => {
      if (ele.Starting == true) {
        return {
          ...ele,
          Player: { PlayerId: ele.PlayerId },
          Time: "00",
        };
      }
      return null; // Use null instead of undefined to make filtering easier
    }).filter((ele) => ele !== null);

    let Team2data = TeamSecond.Players.map((ele) => {
      if (ele.Starting == true) {
        return {
          ...ele,
          Player: { PlayerId: ele.PlayerId },
          Time: "00",
        };
      }
      return null;
      // ...ele,
      // Player: {
      //   PlayerId: ele.PlayerId,
      // },
      // Time: "00",
    }).filter((ele) => ele !== null);
    console.log("Allteamdata", Team1data, Team2data);
    const filteredArray1 = Team1data.filter(
      (obj1) =>
        !Team1GoalCardList.some(
          (obj2) => obj1.PlayerId === obj2.Player.PlayerId
        )
    );
    const statesdataforTeam1 = [...filteredArray1, ...Team1GoalCardList];
    const filteredArray2 = Team2data.filter(
      (obj1) =>
        !Team2GoalCardList.some(
          (obj2) => obj1.PlayerId === obj2.Player.PlayerId
        )
    );
    const statesdataforTeam2 = [...filteredArray2, ...Team2GoalCardList];

    let FirstTeamGoalCarddata = statesdataforTeam1.map(async (val) => ({
      ...val,
      // Playerdata: await getListofplayer(val.Player),
      Team: Teamfirst,
      IDTournament: getMatchObj.tournamentID,
    }));
    let FirstTeamGoalCard = await Promise.all(FirstTeamGoalCarddata);
    console.log("FirstTeamGoaldata", FirstTeamGoalCard);

    let SecondTeamGoalCarddata = statesdataforTeam2.map(async (val) => ({
      ...val,
      // Playerdata: await getListofplayer(val.Player),
      Team: TeamSecond,
      IDTournament: getMatchObj.tournamentID,
    }));
    let SecondTeamGoalCard = await Promise.all(SecondTeamGoalCarddata);
    console.log("SecondTeamGoalCard", SecondTeamGoalCard);
    const finalstatesarry = [...FirstTeamGoalCard, ...SecondTeamGoalCard];
    console.log("statesdataforTeam2", finalstatesarry);
    let AllTeamGoalCardSublist = finalstatesarry.map((ele) => ({
      ...ele,
      Goal: Object.keys(ele).includes("Penalty")
        ? Object.values(ele).includes(false)
          ? 1
          : 0
        : 0,
      Yellow: Object.keys(ele).includes("CardType")
        ? Object.values(ele).includes("Yellow")
          ? 1
          : 0
        : 0,
      Red: Object.keys(ele).includes("CardType")
        ? Object.values(ele).includes("Red")
          ? 1
          : 0
        : 0,
    }));
    console.log("AllTeamGoalCardSublist", AllTeamGoalCardSublist);

    let Userdata = await getUserdata();
    console.log("Userdata", Userdata);

    let Team1Result =
      Teamfirst.Score.FirstHalf +
      Teamfirst.Score.SecondHalf +
      // Teamfirst.Score.PenaltyShootout +
      Teamfirst.Score.ExtraTime;
    let Team2Result =
      TeamSecond.Score.FirstHalf +
      TeamSecond.Score.SecondHalf +
      // TeamSecond.Score.PenaltyShootout +
      TeamSecond.Score.ExtraTime;

    if (
      SelectedRoundObejct.Format == "KNOCKOUT" &&
      getMatchObj.Nextmatch?.NextValue !== 0
    ) {
      let Team1or2: any = {};
      let MatchObject = JSON.parse(JSON.stringify(getMatchObj));
      let MatchObj = MatchObject;

      console.log("MatchObj", MatchesPromises, MatchObj);

      let MatchArrr = MatchesPromises.map((ele) => ({
        ...ele,
        Team1Name: "",
        Team2Name: "",
      }));
      console.log("MatchArrr", MatchObj, Teamfirst, MatchArrr);
      MatchArrr.sort((a, b) => a.MatchNo - b.MatchNo);
      const Knockoutmatchdata = await DataStore.query(
        MatchMaster,
        MatchArrr[MatchObj.Nextmatch.NextValue - 1].id
      );

      console.log("Knockoutmatchdata", Knockoutmatchdata);
      if (MatchObj.Nextmatch.Type == "Team1") {
        Team1or2 = await Knockoutmatchdata.Team1;
      }
      if (MatchObj.Nextmatch.Type == "Team2") {
        Team1or2 = await Knockoutmatchdata.Team2;
      }
      console.log("Team1or2", Team1or2);
      let finalTeam1team2value = { ...Team1or2 };
      if (Team1Result > Team2Result) {
        finalTeam1team2value.TeamName = Teamfirst.TeamName;
        finalTeam1team2value.Players =
          Tournamentdata.SubstitutionType == "ROLLING" ? Teamfirst.Players : [];
        finalTeam1team2value.LogoURL = Teamfirst.LogoURL;
      }
      if (Team1Result < Team2Result) {
        finalTeam1team2value.TeamName = TeamSecond.TeamName;
        finalTeam1team2value.Players =
          Tournamentdata.SubstitutionType == "ROLLING"
            ? TeamSecond.Players
            : [];
        finalTeam1team2value.LogoURL = TeamSecond.LogoURL;
      }
      console.log("finalTeam1team2value", finalTeam1team2value);
      const Teamdata = await DataStore.query(
        MatchTeamMaster,
        finalTeam1team2value
      );
      console.log("NewMatchTeamMaster", Teamdata);
      const UpdatedMatchTeamRecord = await MatchTeamMaster.copyOf(
        Teamdata,
        (updated) => {
          updated.TeamName = finalTeam1team2value.TeamName;
          updated.Players =
            Tournamentdata.SubstitutionType == "ROLLING"
              ? finalTeam1team2value.Players
              : [];
          updated.TeamSubmitted =
            Tournamentdata.SubstitutionType == "ROLLING" ? true : false;
          updated.LogoURL = finalTeam1team2value.LogoURL;
          return updated;
        }
      );
      console.log("UpdatedMatchRecord", UpdatedMatchTeamRecord);
      try {
        const ResultUpdatedMatchTeam = await DataStore.save(
          UpdatedMatchTeamRecord
        );
        console.log("ResultUpdatedMatchTeam", ResultUpdatedMatchTeam);
      } catch (error) {
        console.log("Error", error);
      }
    }

    AllTeamGoalCardSublist &&
      AllTeamGoalCardSublist.length > 0 &&
      AllTeamGoalCardSublist.forEach(async (ele, idx) => {
        // let ExistingPlayer = await getStatisticsPlayerdata(ele);

        // console.log("ExistingPlayer", ExistingPlayer);
        if (Statisticsarray && Statisticsarray.length > 0) {
          console.log("Object", ele);
          const index = Statisticsarray.findIndex(
            (val) => val.PlayerId == ele.Player.PlayerId
          );
          if (index >= 0) {
            Statisticsarray[index].Goals =
              Statisticsarray[index].Goals + ele.Goal;
            Statisticsarray[index].RedCards =
              Statisticsarray[index].RedCards + ele.Red;
            Statisticsarray[index].YellowCards =
              Statisticsarray[index].YellowCards + ele.Yellow;
          } else {
            Statisticsarray.push({
              TeamName: ele.Team.TeamName,
              TeamLogo: ele.Team.LogoURL,
              CreatedBy: Userdata.Name,
              LastUpdatedBy: Userdata.Name,
              Goals: ele.Goal,
              RedCards: ele.Red,
              YellowCards: ele.Yellow,
              Games: 1,
              Minutes: Number(ele.Time),
              tournamentID: ele.IDTournament,
              PlayerId: ele.Player.PlayerId,
            });
          }
        } else {
          Statisticsarray.push({
            TeamName: ele.Team.TeamName,
            TeamLogo: ele.Team.LogoURL,
            CreatedBy: Userdata.Name,
            LastUpdatedBy: Userdata.Name,
            Goals: ele.Goal,
            RedCards: ele.Red,
            YellowCards: ele.Yellow,
            Games: 1,
            Minutes: Number(ele.Time),
            tournamentID: ele.IDTournament,
            PlayerId: ele.Player.PlayerId,
          });
        }
      });
    console.log("Statisticsarray", Statisticsarray);
    console.log("RegistrationTeam1", RegistrationTeam1);

    if (SelectedRoundObejct.Format !== "KNOCKOUT") {
      Standingarr.push(Teamfirst, TeamSecond);
      console.log(
        "Compareresult",
        Team1Result,
        Team2Result,
        Team1Result > Team2Result,
        Tournamentdata
      );
      let Team1Standing = {
        Against: Team2Result,
        For: Team1Result,
        Form:
          Team1Result > Team2Result
            ? "W"
            : Team1Result == Team2Result
            ? "D"
            : "L",
        Played: 1,
        Won: Team1Result > Team2Result ? 1 : 0,
        Drawn: Team1Result == Team2Result ? 1 : 0,
        Difference: Team1Result - Team2Result,
        Lost: Team1Result < Team2Result ? 1 : 0,
      };
      let Team2Standing = {
        Against: Team1Result,
        For: Team2Result,
        Form:
          Team2Result > Team1Result
            ? "W"
            : Team2Result == Team1Result
            ? "D"
            : "L",
        Played: 1,
        Won: Team2Result > Team1Result ? 1 : 0,
        Drawn: Team2Result == Team1Result ? 1 : 0,
        Difference: Team2Result - Team1Result,
        Lost: Team2Result < Team1Result ? 1 : 0,
      };

      let finalstanding = Standingarr.map((ele) => ({
        ...ele,
        ActualTeamdata:
          ele.TeamName.trim() == RegistrationTeam1[0].TeamName.trim()
            ? RegistrationTeam1[0]
            : RegistrationTeam2[0],
        TeamStanding:
          ele.TeamName == Teamfirst.TeamName ? Team1Standing : Team2Standing,
      }));
      console.log("Standingarr", finalstanding, getMatchObj.groupsmasterID);

      RegistrationTeam1;
      finalstanding &&
        finalstanding.length > 0 &&
        finalstanding.map(async (obj) => {
          console.log("StandingObj", obj);
          let ExistingTeam = await getStandingData(
            obj.ActualTeamdata.id,
            getMatchObj.groupsmasterID
          );
          console.log("ExistingTeam", ExistingTeam);
          if (ExistingTeam && ExistingTeam.length > 0) {
            try {
              const Groupstandingresult = await GroupStanding.copyOf(
                ExistingTeam[0],
                (updated) => {
                  updated.Against = updated.Against + obj.TeamStanding.Against;
                  updated.For = updated.For + obj.TeamStanding.For;
                  updated.Form.unshift(obj.TeamStanding.Form);
                  updated.Played = updated.Played + obj.TeamStanding.Played;
                  updated.Points =
                    updated.Points +
                    (obj.TeamStanding.Form == "W"
                      ? Tournamentdata.WinPoints
                      : obj.TeamStanding.Form == "D"
                      ? Tournamentdata.DrawPoints
                      : Tournamentdata.LossPoints);
                  updated.Won = updated.Won + obj.TeamStanding.Won;
                  updated.Drawn = updated.Drawn + obj.TeamStanding.Drawn;
                  updated.Difference =
                    updated.Difference + obj.TeamStanding.Difference;
                  updated.Lost = updated.Lost + obj.TeamStanding.Lost;

                  return updated;
                }
              );
              console.log("Groupstandingresult", Groupstandingresult);
              const GroupStandingData = await DataStore.save(
                Groupstandingresult
              );
              console.log("GroupStandingData", GroupStandingData);
            } catch (error) {
              console.error("Error updating GroupStanding:", error);
            }
          } else {
            try {
              console.log("Groupresult", obj);

              let finalObj: any = {
                TeamName: obj.TeamName,
                TeamLogo: "",
                TeamShortName:
                  obj.TeamShortName !== null ? obj.TeamShortName : "",
                Played: obj.TeamStanding.Played,
                Won: obj.TeamStanding.Won,
                Lost: obj.TeamStanding.Lost,
                Drawn: obj.TeamStanding.Drawn,
                Position: 0,
                CreatedBy: UserDetails.Name,
                LastUpdatedBy: UserDetails.Name,
                For: obj.TeamStanding.For,
                Form: [obj.TeamStanding.Form],
                Against: obj.TeamStanding.Against,
                Points:
                  obj.TeamStanding.Form == "W"
                    ? Tournamentdata.WinPoints
                    : obj.TeamStanding.Form == "D"
                    ? Tournamentdata.DrawPoints
                    : Tournamentdata.LossPoints,
                Difference: obj.TeamStanding.Difference,
                ChangeInPosition: "",
                TeamId: obj.id,
                groupsmasterID: getMatchObj.groupsmasterID,
              };
              console.log("Groupresult", finalObj);
              const Groupresult = await DataStore.save(
                new GroupStanding(finalObj)
              );
              console.log("Groupresult", Groupresult);
            } catch (error) {
              console.error("Error updating StandingNew:", error);
            }
          }
        });
    }

    Statisticsarray &&
      Statisticsarray.length > 0 &&
      Statisticsarray.map(async (ele) => {
        let ExistingPlayer = await getStatisticsPlayerdata(ele, "hello");
        console.log("ExistingPlayer", ExistingPlayer);
        if (ExistingPlayer && ExistingPlayer.length > 0) {
          console.log("2ExistingPlayer", ExistingPlayer);
          try {
            const UpdatePlayerstates = StatisticsMaster.copyOf(
              ExistingPlayer[0],
              (updated) => {
                updated.Goals = updated.Goals + ele.Goals;
                updated.RedCards = updated.RedCards + ele.RedCards;
                updated.YellowCards = updated.YellowCards + ele.YellowCards;
                updated.Games = updated.Games + 1;
                return updated;
              }
            );
            console.log("UpdatePlayerstates", UpdatePlayerstates);
            const ResultplayerStates = await DataStore.save(UpdatePlayerstates);
            console.log("ResultplayerStates", ResultplayerStates);
          } catch (error) {
            console.error("Error updating Statistics:", error);
          }
        } else {
          console.log("hellostates", ele);
          try {
            console.log("Statesrcord", ele);
            const StatesResp = await DataStore.save(new StatisticsMaster(ele));
            console.log("StatesResp", StatesResp);
          } catch (error) {
            console.error("Error updating Statistics:", error);
          }
        }
      });

    try {
      const MastchObjdata = await DataStore.query(MatchMaster, MatchObj.id);
      const UpdatedMatchRecord = MatchMaster.copyOf(
        MastchObjdata,
        (updated) => {
          updated.Status = MatchStatus.COMPLETED;
          updated.Result =
            Team2Result > Team1Result
              ? MatchResult.TEAM2
              : Team2Result < Team1Result
              ? MatchResult.TEAM1
              : MatchResult.DRAW;
          return updated;
        }
      );
      console.log("CompletionMatch", UpdatedMatchRecord);
      const ResultUpdatedMatch = await DataStore.save(UpdatedMatchRecord);
      console.log("ResultUpdatedMatch", ResultUpdatedMatch);
      if (ResultUpdatedMatch) {
        await handlecheckMatchCompletion(Tournamentdata);
        setSunccessModalMesg("Match Completed");
        setModalVisible(true);

        // navigation.replace("ManageTournament");
        navigation.replace("ManageTournament", {
          tournamentId: MatchObj.tournamentID,
        });
        GetMatchdata(ResultUpdatedMatch.id);
      }
    } catch (error) {
      console.error("Error updating Statistics:", error);
    }
  };

  const getMatchdataforCompletionMatch = async (MatchKey) => {
    console.log("MatchKey", MatchKey);
    console.log("SelectedRoundObejct", SelectedRoundObejct);
    let GroupData: any = await handlegetGroupData(SelectedRoundObejct);
    console.log("GroupData", GroupData);
    let MatchesData = await GroupData.Matches.toArray();
    let MatchesPromises = await Promise.all(MatchesData);
    console.log("MatchesPromises", MatchesPromises);

    let matchObj = await DataStore.query(MatchMaster, MatchKey);

    console.log("getMatchdataforCompletionMatch", matchObj);
    await CompleteMatch(matchObj, MatchesPromises);
  };

  const getStandingData = async (Teamid, groupid) => {
    console.log("getStandingData", Teamid, groupid);
    const getdata = await DataStore.query(GroupStanding, (standing) =>
      standing.and((standing) => [
        standing.groupsmasterID.eq(groupid),
        standing.TeamId.eq(Teamid),
      ])
    );
    console.log("hellogroupstanding", getdata);
    return getdata;
  };

  const getStatisticsPlayerdata = async (playerobj, flag) => {
    console.log("getStatisticsPlayerdata", playerobj);
    let getdata;
    if (flag == "Revert") {
      console.log(
        "hellodata",
        playerobj.TournamentID,
        playerobj.Player.PlayerId
      );
      let Playerid = playerobj.Player.PlayerId;
      getdata = await DataStore.query(StatisticsMaster, (statistics) =>
        statistics.and((statistics) => [
          statistics.tournamentID.eq(playerobj.TournamentID),
          statistics.PlayerId.eq(Playerid),
        ])
      );
      console.log("statistics", getdata);
    } else {
      let Playerid = playerobj.PlayerId;
      getdata = await DataStore.query(StatisticsMaster, (statistics) =>
        statistics.and((statistics) => [
          statistics.tournamentID.eq(playerobj.tournamentID),
          statistics.PlayerId.eq(Playerid),
        ])
      );
      console.log("statistics", getdata);
    }

    return getdata;
  };

  const handlePenalty = async (playerobj) => {
    console.log("helloObj", playerobj);
    try {
      const MatchTeamdata = await DataStore.query(
        MatchTeamMaster,
        playerobj.Team1.id
      );
      console.log("MatchTeamdata", MatchTeamdata);
      if (MatchTeamdata) {
        const MatchTeamdataUpdate = MatchTeamMaster.copyOf(
          MatchTeamdata,
          (updated) => {
            updated.Score.FirstHalf = playerobj.Half.includes("FirstHalf")
              ? updated.Score.FirstHalf - 1
              : updated.Score.FirstHalf;
            updated.Score.SecondHalf = playerobj.Half.includes("SecondHalf")
              ? updated.Score.SecondHalf - 1
              : updated.Score.SecondHalf;
            updated.Score.PenaltyShootout = updated.Score.PenaltyShootout + 1;
            updated.Score.ExtraTime = playerobj.Half.includes("ExtraTime")
              ? updated.Score.ExtraTime - 1
              : updated.Score.ExtraTime;
            return updated;
          }
        );
        console.log("MatchTeamdataUpdate", MatchTeamdataUpdate);
        const ResultofMatchTeam = await DataStore.save(MatchTeamdataUpdate);
        console.log("ResultofMatchTeam", ResultofMatchTeam);
        if (ResultofMatchTeam) {
          const getGoalobj = await DataStore.query(GoalMaster, playerobj.id);
          console.log("getGoalobj", getGoalobj);
          if (getGoalobj) {
            const UpdatedGoalObject = GoalMaster.copyOf(
              getGoalobj,
              (updated) => {
                updated.Penalty = true;
              }
            );
            console.log("UpdatedGoalObject", UpdatedGoalObject);
            const ResultOfUpdatedGoal = await DataStore.save(UpdatedGoalObject);
            console.log("ResultOfUpdatedGoal", ResultOfUpdatedGoal);
            if (ResultOfUpdatedGoal) {
              GetMatchdata(MatchObj.id);
            }
          }
        }
      }
    } catch (error) {
      console.error("Error updating tournament:", error);
    }
  };

  const RemoveGoalCardSub = async (PlayerObj, check, index) => {
    console.log("helloPlayerObj", index, MatchObject, PlayerObj);
    setDeletedGoalcardArrlist([...deletedGoalcardArrlist, PlayerObj]);
    let Goallist = MatchObj.GoalCardList.filter((ele, idx) => idx !== index);
    console.log("GoaList", Goallist);
    setMatchObj({
      ...MatchObj,
      GoalCardList: Goallist,
    });
    if (MatchObject.Status !== "COMPLETED" && check !== "completed") {
      if (Object.keys(PlayerObj).includes("CardType")) {
        console.log("RemoveGoalCardSub");
        const modelToDelete = await DataStore.query(CardMaster, PlayerObj.id);
        const Response = await DataStore.delete(modelToDelete);
        if (Response) {
          console.log("Response", Response);
          GetMatchdata(MatchObject.id);
          setSunccessModalMesg("Score Updated Successfully");
          setModalVisible(true);
        } else {
          console.log("No saved data found");
        }
      }
      if (Object.keys(PlayerObj).includes("Penalty")) {
        console.log("Penalty");
        const modelToDelete = await DataStore.query(GoalMaster, PlayerObj.id);

        const Response = await DataStore.delete(modelToDelete);
        if (Response) {
          console.log("Response", Response);
          try {
            const MatchTeamdata = await DataStore.query(
              MatchTeamMaster,
              PlayerObj.Team1.id
            );

            console.log("helloconsole", MatchTeamdata);

            let MatchFinal = MatchTeamMaster.copyOf(
              MatchTeamdata,
              (updated) => {
                updated.Score.FirstHalf = PlayerObj.Half.includes("FIRST")
                  ? updated.Score.FirstHalf - 1
                  : updated.Score.FirstHalf;
                updated.Score.SecondHalf = PlayerObj.Half.includes("SECOND")
                  ? updated.Score.SecondHalf - 1
                  : updated.Score.SecondHalf;
                updated.Score.PenaltyShootout = updated.Score.PenaltyShootout;
                updated.Score.ExtraTime = PlayerObj.Half.includes("EXTRA_TIME")
                  ? updated.Score.ExtraTime - 1
                  : updated.Score.ExtraTime;
                return updated;
              }
            );
            const ResultofMatchTeam = await DataStore.save(MatchFinal);
            console.log("ResultofMatchTeam", ResultofMatchTeam);
            if (ResultofMatchTeam) {
              GetMatchdata(MatchObject.id);
              setSunccessModalMesg("Score Updated Successfully");
              setModalVisible(true);
            }
          } catch (error) {}
        } else {
          console.log("No saved data found");
        }
      }
      if (Object.keys(PlayerObj).includes("InPlayer")) {
        console.log("InPlayer");
        const modelToDelete = await DataStore.query(
          SubstitutionMaster,
          PlayerObj.id
        );
        const Response = await DataStore.delete(modelToDelete);
        if (Response) {
          console.log("Response", Response);
          GetMatchdata(MatchObject.id);
          setSunccessModalMesg("Score Updated Successfully");
          setModalVisible(true);
        } else {
          console.log("No saved data found");
        }
      }
    } else {
      if (check == "completed") {
        if (Object.keys(PlayerObj).includes("CardType")) {
          console.log("RemoveGoalCardSub");
          const modelToDelete = await DataStore.query(CardMaster, PlayerObj.id);
          const Response = await DataStore.delete(modelToDelete);
        }
        if (Object.keys(PlayerObj).includes("Penalty")) {
          console.log("Penalty");
          const modelToDelete = await DataStore.query(GoalMaster, PlayerObj.id);
          console.log("modelToDelete", modelToDelete);
          const Response = await DataStore.delete(modelToDelete);
          if (Response) {
            console.log("Response", Response);
            try {
              const MatchTeamdata = await DataStore.query(
                MatchTeamMaster,
                PlayerObj.Team1.id
              );

              console.log("helloconsole", MatchTeamdata);

              let MatchFinal = MatchTeamMaster.copyOf(
                MatchTeamdata,
                (updated) => {
                  updated.Score.FirstHalf = PlayerObj.Half.includes("FIRST")
                    ? updated.Score.FirstHalf - 1
                    : updated.Score.FirstHalf;
                  updated.Score.SecondHalf = PlayerObj.Half.includes("SECOND")
                    ? updated.Score.SecondHalf - 1
                    : updated.Score.SecondHalf;
                  updated.Score.PenaltyShootout = updated.Score.PenaltyShootout;
                  updated.Score.ExtraTime = PlayerObj.Half.includes(
                    "EXTRA_TIME"
                  )
                    ? updated.Score.ExtraTime - 1
                    : updated.Score.ExtraTime;
                  return updated;
                }
              );
              const ResultofMatchTeam = await DataStore.save(MatchFinal);
              console.log("ResultofMatchTeam", ResultofMatchTeam);
            } catch (error) {}
          } else {
            console.log("No saved data found");
          }
        }
        if (Object.keys(PlayerObj).includes("InPlayer")) {
          console.log("InPlayer");
          const modelToDelete = await DataStore.query(
            SubstitutionMaster,
            PlayerObj.id
          );
          const Response = await DataStore.delete(modelToDelete);
          if (Response) {
          } else {
            console.log("No saved data found");
          }
        }
      }
    }
  };

  const handleBack = () => {
    navigation.goBack();
  };
  return (
    <>
      {Platform.OS !== "web" && (
        <AppBar
          title={"Scoring"}
          showBackButton={true}
          onBackPress={handleBack}
        />
      )}
      <AdminLayout>
        <View
          style={{
            alignItems: "center",
            paddingBottom:
              Platform.OS === "web" && screenWidth > 760 ? undefined : 70,
          }}
        >
          <ScrollView
            style={{
              backgroundColor: colors.royalBlue,
              minHeight: screenHeight,
              maxWidth: 1000,
              width: "100%",
            }}
          >
            {MatchObject.OnlineScoring == true ? (
              <LinearGradient
                style={styles.scoreTopWrapper}
                colors={["#002DA8", "#02205F"]}
                start={{ x: 0, y: 1 }}
                end={{ x: 1, y: 1 }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 5,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {MatchObject.ExtraTimeEnded !== true ? (
                    <View
                      style={{
                        width: 8,
                        height: 8,
                        borderRadius: 8,
                        backgroundColor: "#2EFD43",
                      }}
                    />
                  ) : null}
                  {MatchObject.ExtraTimeEnded !== true ? (
                    <Text
                      style={{
                        color: "#2EFD43",
                        fontSize: 14,
                        fontFamily: "PlusJakartaSansRegular",
                      }}
                    >
                      In Progress
                    </Text>
                  ) : null}
                </View>
                <View
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 10,
                      gap: 8,
                      width: 300,
                      backgroundColor: "#ffffff30",
                      borderColor: "#ffffff30",
                      borderWidth: 1,
                      padding: 5,
                      borderRadius: 10,
                    }}
                  >
                    {MatchObject.ExtraTimeEnded !== true ? (
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          backgroundColor: "#021745",
                          borderRadius: 10,
                          paddingVertical: 6,
                          paddingHorizontal: 20,
                          gap: 5,
                          width: "100%",
                        }}
                      >
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                          // {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
                        >
                          <H1
                            props={String(minutes).padStart(2, "0")}
                            style={styles.timerFontStyle}
                          />
                          <Text style={{ color: "#fff", fontSize: 10 }}>
                            Minutes
                          </Text>
                        </View>
                        <View>
                          <H1 props={":"} style={styles.timerFontStyle} />
                        </View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <H1
                            props={String(seconds).padStart(2, "0")}
                            style={styles.timerFontStyle}
                          />
                          <Text style={{ color: "#fff", fontSize: 10 }}>
                            Seconds
                          </Text>
                        </View>
                      </View>
                    ) : null}
                    <View
                      style={{ flexDirection: "row", gap: 10, width: "100%" }}
                    >
                      {MatchObject.ExtraTimeEnded !== true ? (
                        <Pressable
                          onPress={() => handleFirsthalfSecondhalf()}
                          style={[
                            styles.scoringAppStartTimer,
                            {
                              backgroundColor:
                                firsthalfSecondExtraTime.includes("Start")
                                  ? "#50C878"
                                  : colors.orangeColour,
                              // ?
                              borderColor: firsthalfSecondExtraTime.includes(
                                "Start"
                              )
                                ? "#50C878"
                                : colors.orangeColour,
                              // "#50C878"

                              // :  colors.orangeColour
                            },
                          ]}
                        >
                          <Text style={styles.scoringAppStartTimerText}>
                            {firsthalfSecondExtraTime}
                          </Text>
                        </Pressable>
                      ) : null}
                      {MatchObject.ExtraTimeEnded !== true &&
                      isRunning == false &&
                      Allhalfstart !== "" ? (
                        <Pressable
                          style={styles.scoringAppStartTimer2}
                          onPress={() => handleStart()}
                        >
                          <PauseIcon name="play" size={20} color="#50c878" />
                          <Text style={styles.scoringAppStartTimerText}>
                            Start
                          </Text>
                        </Pressable>
                      ) : null}

                      {MatchObject.ExtraTimeEnded !== true &&
                      isRunning == true &&
                      Allhalfstart !== "" ? (
                        <Pressable
                          style={styles.scoringAppStartTimer2}
                          onPress={() => handleStop()}
                        >
                          <Pressable onPress={() => handleStop()}>
                            <PauseIcon
                              name="pause-circle-outline"
                              size={20}
                              color="red"
                            />
                          </Pressable>
                          <Text style={styles.scoringAppStartTimerText}>
                            Pause
                          </Text>
                        </Pressable>
                      ) : null}
                    </View>

                    {(MatchObject.FirstHalfStarted == true ||
                      MatchObject.SecondHalfStarted == true ||
                      MatchObject.ExtraTimeStarted == true) &&
                    MatchObject.Status !== "COMPLETED" ? (
                      <Pressable
                        style={styles.endMatchStyle}
                        onPress={() => handleComplete()}
                      >
                        <Text style={styles.scoringAppStartTimerText}>
                          End Match
                        </Text>
                      </Pressable>
                    ) : null}
                    {MatchObject.FirstHalfStarted !== true &&
                    MatchObject.SecondHalfStarted !== true &&
                    MatchObject.ExtraTimeStarted !== true &&
                    MatchObject.Status !== "COMPLETED" ? (
                      <Pressable style={styles.endMatchStyle}>
                        <Text style={styles.scoringAppStartTimerText}>
                          End Match
                        </Text>
                      </Pressable>
                    ) : null}
                  </View>
                </View>
                {/* </View> */}
              </LinearGradient>
            ) : null}

            {MatchObject.Status == "COMPLETED" ? (
              <Pressable
                style={styles.endMatchStyle}
                onPress={() => handleMatchRevert()}
              >
                <Text style={styles.scoringAppStartTimerText}>End Match</Text>
              </Pressable>
            ) : null}

            <View style={styles.scoreContentWrapper}>
              {(MatchObject.FirstHalfStarted == true ||
                MatchObject.SecondHalfStarted == true ||
                MatchObject.ExtraTimeStarted == true) &&
              MatchObject.OnlineScoring == false &&
              MatchObject.Status !== "COMPLETED" ? (
                <View
                  style={{
                    width: "100%",
                    alignItems: "flex-end",
                  }}
                >
                  <Pressable
                    style={[styles.endMatchStyle, { width: 140 }]}
                    onPress={() => handleComplete()}
                  >
                    <Text style={[styles.scoringAppStartTimerText]}>
                      End Match
                    </Text>
                  </Pressable>
                </View>
              ) : null}
              {Addgoalcardhiseshowflag !== "" ||
              (MatchObj && MatchObj.FirstHalfStarted == true) ? (
                <Text
                  style={{
                    fontFamily: "PlusJakartaSansSemiBold",
                    fontSize: 15,
                    color: colors.white,
                  }}
                >
                  Please select what you want to update
                </Text>
              ) : null}
              {Addgoalcardhiseshowflag !== "" ||
              (MatchObj && MatchObj.FirstHalfStarted == true) ? (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: 10,
                    marginTop: 10,
                  }}
                >
                  <Pressable
                    onPress={() => handleScoreTabChange(0)}
                    // style={[
                    //   activeScoreTab === 0
                    //     ? styles.scoreUpdateButtonActive
                    //     : styles.scoreUpdateButton,
                    // ]}
                    style={[
                      styles.scoreUpdateButton,
                      {
                        backgroundColor:
                          activeScoreTab === 0 ? colors.orange : colors.aqua,
                      },
                    ]}
                  >
                    <Football
                      name="soccer-ball-o"
                      size={22}
                      color={colors.royalBlue}
                    />
                    <Text style={styles.scoreUpdateButtonText}>Goal</Text>
                  </Pressable>
                  <Pressable
                    onPress={() => handleScoreTabChange(1)}
                    style={[
                      styles.scoreUpdateButton,
                      {
                        backgroundColor:
                          activeScoreTab === 1 ? colors.orange : colors.aqua,
                      },
                    ]}
                  >
                    <CardIcon name="cards" size={22} color={colors.royalBlue} />
                    <Text style={styles.scoreUpdateButtonText}>Card</Text>
                  </Pressable>
                  <Pressable
                    onPress={() => handleScoreTabChange(2)}
                    style={[
                      styles.scoreUpdateButton,
                      {
                        backgroundColor:
                          activeScoreTab === 2 ? colors.orange : colors.aqua,
                      },
                    ]}
                  >
                    <SwitchIcon
                      name="arrow-switch"
                      size={22}
                      color={colors.royalBlue}
                    />
                    <Text style={styles.scoreUpdateButtonText}>Substitute</Text>
                  </Pressable>
                </View>
              ) : null}
              {/* team wrapper started */}
              {((activeScoreTab === 0 && Addgoalcardhiseshowflag !== "") ||
                (MatchObj &&
                  MatchObj.FirstHalfStarted == true &&
                  activeScoreTab === 0)) &&
                (MatchObj && Object.keys(MatchObj).length > 0 ? (
                  <View
                    style={{
                      backgroundColor: colors.bgColor,
                      padding: 10,
                      marginTop: 10,
                      borderRadius: 10,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "PlusJakartaSansSemiBold",
                        fontSize: 15,
                        color: colors.white,
                        marginBottom: 10,
                      }}
                    >
                      Please select the team
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      <Pressable
                        onPress={() => handleTeamTabChange(0, MatchObj.Team1)}
                        style={[
                          activeTab === 0
                            ? styles.activeTabButton
                            : styles.teamPlayerNameBtn,
                        ]}
                      >
                        <Image
                          // source={require("../../../../assets/home/logo.png")}
                          source={{ uri: MatchObj.Team1.LogoURL }}
                          style={styles.scoreTeamLogo}
                          resizeMode="contain"
                        />
                        <Text style={[styles.teamPlayerNameBtnText]}>
                          {MatchObj.Team1.TeamName}
                        </Text>
                      </Pressable>
                      <View
                        style={{
                          width:
                            Platform.OS === "web" && screenWidth > 760
                              ? 130
                              : 70,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <H1
                          props={`${MatchObj.Team1Score}-${MatchObj.Team2Score}`}
                          style={{ color: colors.white }}
                        />
                      </View>
                      <Pressable
                        onPress={() => handleTeamTabChange(1, MatchObj.Team2)}
                        style={[
                          activeTab === 1
                            ? styles.activeTabButton
                            : styles.teamPlayerNameBtn,
                        ]}
                      >
                        <Image
                          // source={require("../../../../assets/home/logo.png")}
                          source={{ uri: MatchObj.Team2.LogoURL }}
                          style={styles.scoreTeamLogo}
                          resizeMode="contain"
                        />
                        <Text
                          style={[
                            activeTab === 1
                              ? styles.activeTeamPlayerNameBtnText
                              : styles.teamPlayerNameBtnText,
                          ]}
                        >
                          {MatchObj.Team2.TeamName}
                        </Text>
                      </Pressable>
                    </View>
                    {SelectedPlayerFlag == false &&
                    SelectedPlayerList &&
                    SelectedPlayerList.length > 0 ? (
                      <Text
                        style={{
                          color: colors.aqua,
                          fontSize: 14,
                          marginTop: 10,
                          fontFamily: "PlusJakartaSansSemiBold",
                        }}
                      >
                        Please select the player who scored a goal
                      </Text>
                    ) : null}
                    {/* {activeTab === 0 && ( */}
                    <>
                      {SelectedPlayerobj &&
                      Object.keys(SelectedPlayerobj).length > 0 ? (
                        <View style={styles.selectedPlayerWrapper}>
                          <View style={styles.teamSelectedPlayer}>
                            <Text style={styles.teamPlayerSNWrapper}>
                              {SelectedPlayerobj.JerseyNo}
                            </Text>
                            <Text style={styles.teamPlayerNameWrapper}>
                              {SelectedPlayerobj.Name}
                            </Text>
                          </View>
                          <Pressable
                            style={{ width: 50 }}
                            onPress={() => EditSelectedPlayer()}
                          >
                            <EditIcon name="edit" size={20} color="#ff2600" />
                          </Pressable>
                        </View>
                      ) : null}
                      {SelectedPlayerFlag == false &&
                      SelectedPlayerList &&
                      SelectedPlayerList.length ? (
                        <View style={styles.teamPlayerList}>
                          {SelectedPlayerList &&
                            SelectedPlayerList.length > 0 &&
                            SelectedPlayerList.map((obj, index) => {
                              return (
                                <Pressable
                                  onPress={() => handleSelectPlayer(obj)}
                                >
                                  <View style={styles.teamPlayerWrapper}>
                                    <Text style={styles.teamPlayerSNWrapper}>
                                      {index + 1}
                                    </Text>
                                    <Text style={styles.teamPlayerNameWrapper}>
                                      {obj.Name}
                                    </Text>
                                  </View>
                                </Pressable>
                              );
                            })}
                        </View>
                      ) : null}
                    </>

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: 10,
                        alignItems: "center",
                      }}
                    >
                      <View style={{ flexDirection: "column", gap: 10 }}>
                        <Text
                          style={{
                            color: colors.white,
                            fontFamily: "PlusJakartaSansSemiBold",
                            fontSize: 14,
                          }}
                        >
                          Time
                        </Text>
                        <View
                          style={{
                            flexDirection: "row",
                            gap: 8,
                            alignItems: "center",
                          }}
                        >
                          <Pressable
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "#fff",
                              borderRadius: 10,
                              padding: 5,
                              width: 80,
                              height: 40,
                              shadowColor: "#000000",
                              shadowOffset: { width: 0, height: 0 },
                              shadowOpacity: 0.2,
                              shadowRadius: 10,
                              elevation: 5,
                            }}
                          >
                            <TextInput
                              style={styles.addTimer}
                              placeholder={"00"}
                              value={GoalTime}
                              onChangeText={handleClickTime}
                            />
                          </Pressable>
                          <Text
                            style={{
                              fontSize: 12,
                              fontFamily: "PlusJakartaSansSemiBold",
                              color: colors.white,
                            }}
                          >
                            Minutes
                          </Text>
                        </View>
                      </View>
                      <View style={{ flexDirection: "column", gap: 10 }}>
                        <Text
                          style={{
                            color: colors.white,
                            fontFamily: "PlusJakartaSansSemiBold",
                            fontSize: 14,
                          }}
                        >
                          Mark own goal?
                        </Text>
                        <Pressable
                          style={
                            OwnGoalflag == true
                              ? styles.goalButton2Active
                              : styles.goalButton2
                          }
                          onPress={(e) => {
                            handleOwnGoalClick(e);
                          }}
                        >
                          <Football
                            name="soccer-ball-o"
                            size={22}
                            color={OwnGoalflag == true ? "white" : "red"}
                          />
                          <Text
                            style={
                              OwnGoalflag == true
                                ? styles.scoreUpdateButtonTextActie
                                : styles.scoreUpdateButtonText2
                            }
                          >
                            Own Goal
                          </Text>
                        </Pressable>
                      </View>
                    </View>

                    <View
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 20,
                      }}
                    >
                      {SelectedPlayerobj &&
                      Object.keys(SelectedPlayerobj).length > 0 &&
                      GoalTime !== "" ? (
                        <Pressable
                          onPress={() => handleGoalSubmit()}
                          style={{
                            backgroundColor: colors.royalBlueColour,
                            borderRadius: 8,
                            justifyContent: "center",
                            alignItems: "center",
                            width: 120,
                            height: 30,
                          }}
                        >
                          <Text
                            style={{
                              color: "#fff",
                              fontFamily: "PlusJakartaSansSemiBold",
                              fontSize: 14,
                            }}
                          >
                            Submit
                          </Text>
                        </Pressable>
                      ) : null}
                    </View>
                  </View>
                ) : null)}

              {((activeScoreTab === 1 && Addgoalcardhiseshowflag !== "") ||
                (MatchObj &&
                  MatchObj.FirstHalfStarted == true &&
                  activeScoreTab === 1)) && (
                <View
                  style={{
                    backgroundColor: colors.bgColor,
                    padding: 10,
                    marginTop: 10,
                    borderRadius: 10,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "PlusJakartaSansSemiBold",
                      fontSize: 15,
                      color: colors.white,
                      marginBottom: 10,
                    }}
                  >
                    Please select the team
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                    }}
                  >
                    <Pressable
                      onPress={() => handleTeamTabChange(0, MatchObj.Team1)}
                      style={[
                        activeTab === 0
                          ? styles.activeTabButton
                          : styles.teamPlayerNameBtn,
                      ]}
                    >
                      <Image
                        // source={require("../../../../assets/home/logo.png")}
                        source={{ uri: MatchObj.Team1.LogoURL }}
                        style={styles.scoreTeamLogo}
                        resizeMode="contain"
                      />
                      <Text
                        style={[
                          activeTab === 0
                            ? styles.activeTeamPlayerNameBtnText
                            : styles.teamPlayerNameBtnText,
                        ]}
                      >
                        {MatchObj.Team1.TeamName}
                      </Text>
                    </Pressable>
                    <View
                      style={{
                        width:
                          Platform.OS === "web" && screenWidth > 760 ? 130 : 70,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <H1
                        props={`${MatchObj.Team1Score}-${MatchObj.Team2Score}`}
                        style={{ color: colors.white }}
                      />
                    </View>
                    <Pressable
                      onPress={() => handleTeamTabChange(1, MatchObj.Team2)}
                      style={[
                        activeTab === 1
                          ? styles.activeTabButton
                          : styles.teamPlayerNameBtn,
                      ]}
                    >
                      <Image
                        // source={require("../../../../assets/home/logo.png")}
                        source={{ uri: MatchObj.Team2.LogoURL }}
                        style={styles.scoreTeamLogo}
                        resizeMode="contain"
                      />
                      <Text
                        style={[
                          activeTab === 1
                            ? styles.activeTeamPlayerNameBtnText
                            : styles.teamPlayerNameBtnText,
                        ]}
                      >
                        {MatchObj.Team2.TeamName}
                      </Text>
                    </Pressable>
                  </View>
                  {SelectedPlayerFlag == false &&
                  SelectedPlayerList &&
                  SelectedPlayerList.length > 0 ? (
                    <Text
                      style={{
                        color: colors.aqua,
                        fontSize: 14,
                        marginTop: 10,
                        fontFamily: "PlusJakartaSansSemiBold",
                      }}
                    >
                      Please select the player who is given a card
                    </Text>
                  ) : null}
                  <>
                    {SelectedPlayerobj &&
                    Object.keys(SelectedPlayerobj).length > 0 ? (
                      <View style={styles.selectedPlayerWrapper}>
                        <View style={styles.teamSelectedPlayer}>
                          <Text style={styles.teamPlayerSNWrapper}>
                            {SelectedPlayerobj.JerseyNo}
                          </Text>
                          <Text style={styles.teamPlayerNameWrapper}>
                            {SelectedPlayerobj.Name}
                          </Text>
                        </View>
                        <Pressable
                          style={{ width: 50 }}
                          onPress={() => EditSelectedPlayer()}
                        >
                          <EditIcon name="edit" size={20} color="#ff2600" />
                        </Pressable>
                      </View>
                    ) : null}
                    {SelectedPlayerFlag == false &&
                    SelectedPlayerList &&
                    SelectedPlayerList.length > 0 ? (
                      <View style={styles.teamPlayerList}>
                        {SelectedPlayerList &&
                          SelectedPlayerList.length > 0 &&
                          SelectedPlayerList.map((obj, index) => {
                            return (
                              <Pressable
                                onPress={() => handleSelectPlayer(obj)}
                              >
                                <View style={styles.teamPlayerWrapper}>
                                  <Text style={styles.teamPlayerSNWrapper}>
                                    {index + 1}
                                  </Text>
                                  <Text style={styles.teamPlayerNameWrapper}>
                                    {obj.Name}
                                  </Text>
                                </View>
                              </Pressable>
                            );
                          })}
                      </View>
                    ) : null}
                  </>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: 10,
                      alignItems: "center",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        gap: 8,
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{
                          color: colors.white,
                          fontFamily: "PlusJakartaSansSemiBold",
                          fontSize: 14,
                        }}
                      >
                        Time
                      </Text>
                      <Pressable
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#fff",
                          borderRadius: 10,
                          padding: 5,
                          width: 80,
                          height: 40,
                          shadowColor: "#000000",
                          shadowOffset: { width: 0, height: 0 },
                          shadowOpacity: 0.2,
                          shadowRadius: 10,
                          elevation: 5,
                        }}
                      >
                        <TextInput
                          style={styles.addTimer}
                          placeholder={"00"}
                          value={GoalTime}
                          onChangeText={handleClickTime}
                        />
                      </Pressable>
                      <Text style={{ fontSize: 10, color: colors.white }}>
                        Minutes
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          color: colors.white,
                          fontFamily: "PlusJakartaSansSemiBold",
                          fontSize: 14,
                          marginBottom: 5,
                        }}
                      >
                        Select Card
                      </Text>
                      <View style={{ flexDirection: "row", gap: 10 }}>
                        <Pressable
                          onPress={() => {
                            handleClickCards("Yellow");
                          }}
                        >
                          <View
                            style={
                              SelectedCard == "Yellow"
                                ? [
                                    styles.yellowCardClick,
                                    { transform: [{ scale: 1.2 }] },
                                  ]
                                : styles.yellowCardClick
                            }
                            // style={

                            //   SelectedCard == "YellowCard" ?
                            // }
                          ></View>
                        </Pressable>
                        <Pressable
                          onPress={() => {
                            handleClickCards("Red");
                          }}
                        >
                          <View
                            style={
                              SelectedCard == "Red"
                                ? [
                                    styles.redCardClick,
                                    { transform: [{ scale: 1.2 }] },
                                  ]
                                : styles.redCardClick
                            }
                            // style={styles.redCardClick}
                          ></View>
                        </Pressable>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 20,
                    }}
                  >
                    {SelectedCard !== "" &&
                    // GoalTime !== "" &&
                    SelectedPlayerobj &&
                    Object.keys(SelectedPlayerobj).length > 0 ? (
                      <Pressable
                        style={{
                          backgroundColor: colors.royalBlueColour,
                          borderRadius: 8,
                          justifyContent: "center",
                          alignItems: "center",
                          width: 120,
                          height: 30,
                        }}
                        onPress={() => {
                          handleCardSubmit();
                        }}
                      >
                        <Text
                          style={{
                            color: "#fff",
                            fontFamily: "PlusJakartaSansSemiBold",
                            fontSize: 14,
                          }}
                        >
                          Submit
                        </Text>
                      </Pressable>
                    ) : null}
                  </View>
                </View>
              )}

              {((activeScoreTab === 2 && Addgoalcardhiseshowflag !== "") ||
                (MatchObj &&
                  MatchObj.FirstHalfStarted == true &&
                  activeScoreTab === 2)) && (
                <View
                  style={{
                    backgroundColor: colors.bgColor,
                    padding: 10,
                    marginTop: 10,
                    borderRadius: 10,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "PlusJakartaSansSemiBold",
                      fontSize: 15,
                      color: colors.white,
                      marginBottom: 10,
                    }}
                  >
                    Please select the team
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                    }}
                  >
                    <Pressable
                      onPress={() => handleTeamTabChange(0, MatchObj.Team1)}
                      style={[
                        activeTab === 0
                          ? styles.activeTabButton
                          : styles.teamPlayerNameBtn,
                      ]}
                    >
                      <Image
                        // source={require("../../../../assets/home/logo.png")}
                        source={{ uri: MatchObj.Team1.LogoURL }}
                        style={styles.scoreTeamLogo}
                        resizeMode="contain"
                      />
                      <Text
                        style={[
                          activeTab === 0
                            ? styles.activeTeamPlayerNameBtnText
                            : styles.teamPlayerNameBtnText,
                        ]}
                      >
                        {MatchObj.Team1.TeamName}
                      </Text>
                    </Pressable>
                    <View
                      style={{
                        width:
                          Platform.OS === "web" && screenWidth > 760 ? 130 : 70,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <H1
                        props={`${MatchObj.Team1Score}-${MatchObj.Team2Score}`}
                        style={{ color: colors.white }}
                      />
                    </View>
                    <Pressable
                      onPress={() => handleTeamTabChange(1, MatchObj.Team2)}
                      style={[
                        activeTab === 1
                          ? styles.activeTabButton
                          : styles.teamPlayerNameBtn,
                      ]}
                    >
                      <Image
                        // source={require("../../../../assets/home/logo.png")}
                        source={{ uri: MatchObj.Team2.LogoURL }}
                        style={styles.scoreTeamLogo}
                        resizeMode="contain"
                      />
                      <Text
                        style={[
                          activeTab === 1
                            ? styles.activeTeamPlayerNameBtnText
                            : styles.teamPlayerNameBtnText,
                        ]}
                      >
                        {MatchObj.Team2.TeamName}
                      </Text>
                    </Pressable>
                  </View>
                  {SelectedPlayerList && SelectedPlayerList.length > 0 ? (
                    <View
                      style={{
                        flexDirection: "row",
                        // justifyContent: "space-between",
                      }}
                    >
                      <View
                        style={{
                          width: 310,
                          marginRight: 35,
                          backgroundColor: colors.royalBlue,
                          padding: 5,
                          marginTop: 17,
                          borderRadius: 10,
                        }}
                      >
                        <Text
                          style={{
                            color: colors.white,
                            fontSize: 20,
                            fontFamily: "PlusJakartaSansMedium",
                            textAlign: "center",
                          }}
                        >
                          Select Out Player
                        </Text>
                        <>
                          {SelectedPlayerobj &&
                          Object.keys(SelectedPlayerobj).length > 0 ? (
                            <View style={styles.selectedPlayerWrapper}>
                              <View style={styles.teamSelectedPlayer}>
                                <Text style={styles.teamPlayerSNWrapper}>
                                  {SelectedPlayerobj.JerseyNo}
                                </Text>
                                <Text style={styles.teamPlayerNameWrapper}>
                                  {SelectedPlayerobj.Name}
                                </Text>
                              </View>
                              <Pressable
                                style={{ width: 50 }}
                                onPress={() => EditSelectedPlayer()}
                              >
                                <EditIcon
                                  name="edit"
                                  size={20}
                                  color="#ff2600"
                                />
                              </Pressable>
                            </View>
                          ) : null}
                          {SelectedPlayerFlag == false ? (
                            <View style={styles.teamPlayerList}>
                              {SelectedPlayerList &&
                                SelectedPlayerList.length > 0 &&
                                SelectedPlayerList.map((obj, index) => {
                                  return obj.Starting == true ? (
                                    <Pressable
                                      onPress={() => handleSelectPlayer(obj)}
                                    >
                                      <View style={styles.teamPlayerWrapper}>
                                        <Text
                                          style={styles.teamPlayerSNWrapper}
                                        >
                                          {index + 1}
                                        </Text>
                                        <Text
                                          style={styles.teamPlayerNameWrapper}
                                        >
                                          {obj.Name}
                                        </Text>
                                      </View>
                                    </Pressable>
                                  ) : null;
                                })}
                            </View>
                          ) : null}
                        </>
                      </View>
                      <View
                        style={{
                          width: 310,
                          backgroundColor: colors.royalBlue,
                          padding: 5,
                          marginTop: 17,
                          borderRadius: 10,
                        }}
                      >
                        <Text
                          style={{
                            color: colors.white,
                            fontSize: 20,
                            fontFamily: "PlusJakartaSansMedium",
                            textAlign: "center",
                          }}
                        >
                          Select In Player
                        </Text>

                        <>
                          {SelectedSubsitutePlayerObj &&
                          Object.keys(SelectedSubsitutePlayerObj).length > 0 ? (
                            <View style={styles.selectedPlayerWrapper}>
                              <View style={styles.teamSelectedPlayer}>
                                <Text style={styles.teamPlayerSNWrapper}>
                                  {SelectedSubsitutePlayerObj.JerseyNo}
                                </Text>
                                <Text style={styles.teamPlayerNameWrapper}>
                                  {SelectedSubsitutePlayerObj.Name}
                                </Text>
                              </View>
                              <Pressable
                                style={{ width: 50 }}
                                onPress={() => EditSubPlayer()}
                              >
                                <EditIcon
                                  name="edit"
                                  size={20}
                                  color="#ff2600"
                                />
                              </Pressable>
                            </View>
                          ) : null}
                          {SelectedSubPlayerFlag == false ? (
                            <View style={styles.teamPlayerList}>
                              {SelectedSubList &&
                                SelectedSubList.length > 0 &&
                                SelectedSubList.map((obj, index) => {
                                  return obj.Starting == false ? (
                                    <Pressable
                                      onPress={() => handleSelectSubPlayer(obj)}
                                    >
                                      <View style={styles.teamPlayerWrapper}>
                                        <Text
                                          style={styles.teamPlayerSNWrapper}
                                        >
                                          {index + 1}
                                        </Text>
                                        <Text
                                          style={styles.teamPlayerNameWrapper}
                                        >
                                          {obj.Name}
                                        </Text>
                                      </View>
                                    </Pressable>
                                  ) : null;
                                })}
                            </View>
                          ) : null}
                        </>
                      </View>
                    </View>
                  ) : null}

                  <View
                    style={{
                      flexDirection: "row",
                      gap: 8,
                      alignItems: "center",
                      marginTop: 10,
                    }}
                  >
                    <Text
                      style={{
                        color: colors.white,
                        fontFamily: "PlusJakartaSansSemiBold",
                        fontSize: 14,
                      }}
                    >
                      Time
                    </Text>
                    <Pressable
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#fff",
                        borderRadius: 10,
                        padding: 5,
                        width: 80,
                        height: 40,
                        shadowColor: "#000000",
                        shadowOffset: { width: 0, height: 0 },
                        shadowOpacity: 0.2,
                        shadowRadius: 10,
                        elevation: 5,
                      }}
                    >
                      <TextInput
                        style={styles.addTimer}
                        placeholder={"00"}
                        value={GoalTime}
                        onChangeText={handleClickTime}
                      />
                    </Pressable>
                    <Text style={{ fontSize: 10, color: colors.white }}>
                      Minutes
                    </Text>
                  </View>
                  {SelectedPlayerobj &&
                  Object.keys(SelectedPlayerobj).length > 0 &&
                  SelectedSubsitutePlayerObj &&
                  Object.keys(SelectedSubsitutePlayerObj).length > 0 &&
                  GoalTime !== "" ? (
                    <View
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 20,
                      }}
                    >
                      <Pressable
                        style={{
                          backgroundColor: colors.royalBlueColour,
                          borderRadius: 8,
                          justifyContent: "center",
                          alignItems: "center",
                          width: 120,
                          height: 30,
                        }}
                        onPress={() => {
                          handleSubsituteSubmit();
                        }}
                      >
                        <Text
                          style={{
                            color: "#fff",
                            fontFamily: "PlusJakartaSansSemiBold",
                            fontSize: 14,
                          }}
                        >
                          Submit
                        </Text>
                      </Pressable>
                    </View>
                  ) : null}
                </View>
              )}
              {/* score sheet started here */}
              <Text
                style={{
                  fontFamily: "PlusJakartaSansSemiBold",
                  fontSize: 15,
                  marginTop: 10,
                  color: colors.white,
                }}
              >
                Score Sheet
              </Text>
              {/* score sheet mob */}
              <View
                style={{
                  display:
                    Platform.OS === "web" && screenWidth > 760
                      ? "none"
                      : "flex",
                  flexDirection: "column",
                  gap: 10,
                  marginTop: 10,
                }}
              >
                {MatchObj &&
                  Object.keys(MatchObj).length > 0 &&
                  MatchObj.GoalCardList &&
                  MatchObj.GoalCardList.length > 0 &&
                  MatchObj.GoalCardList.map((ele, index) => {
                    return (
                      <View style={styles.scoreSheetItem}>
                        <View
                          style={
                            ele.CardType === "Red"
                              ? styles.ssRedCardHeadingWrapper
                              : ele.CardType === "Yellow"
                              ? styles.ssYellowCardHeadingWrapper
                              : styles.ssGoalHeadingWrapper
                          }
                        >
                          <View style={styles.scoreSheetTitle}>
                            {!Object.keys(ele).includes("InPlayer") &&
                              ele.CardType && (
                                <CardIcon name="cards" size={22} color="#fff" />
                              )}
                            {!Object.keys(ele).includes("InPlayer") &&
                              !ele.CardType && (
                                <Football
                                  name="soccer-ball-o"
                                  size={22}
                                  color="#fff"
                                />
                              )}
                            <Text style={styles.scoreUpdateButtonText}>
                              {Object.keys(ele).includes("InPlayer")
                                ? "Subsitute"
                                : ele.CardType
                                ? "Card"
                                : "Goal"}
                            </Text>
                          </View>

                          {!Object.keys(ele).includes("InPlayer") &&
                          !ele.CardType &&
                          ele.Penalty == false ? (
                            <View style={{ flex: 1, alignItems: "center" }}>
                              <Pressable
                                // style={{
                                //   backgroundColor: "#2EFD43",
                                //   borderRadius: 5,
                                //   }}
                                onPress={() => handlePenalty(ele)}
                              >
                                <Text
                                  style={{
                                    color: "white",
                                    paddingVertical: 2,
                                    paddingHorizontal: 8,
                                    fontFamily: "PlusJakartaSansSemiBold",
                                  }}
                                >
                                  P
                                </Text>
                              </Pressable>
                            </View>
                          ) : null}
                          {!Object.keys(ele).includes("InPlayer") &&
                          !ele.CardType &&
                          ele.Penalty == true ? (
                            <View style={{ flex: 1, alignItems: "center" }}>
                              <Pressable
                                style={[styles.PenaltyView, styles.shadowProp]}
                                // onPress={() => handlePenalty(ele)}
                              >
                                <Text
                                  style={{
                                    color: colors.royalBlueColour,
                                    paddingVertical: 2,
                                    paddingHorizontal: 8,
                                    fontSize: 17,
                                    fontFamily: "PlusJakartaSansSemiBold",
                                  }}
                                >
                                  P
                                </Text>
                              </Pressable>
                            </View>
                          ) : null}

                          <View style={styles.scoreSheetTitle}>
                            <Text style={styles.scoreUpdateButtonText}>
                              Time :
                            </Text>
                            <Text style={styles.scoreUpdateButtonTextBig}>
                              {ele.Time}
                            </Text>
                          </View>
                          <Pressable
                            style={styles.scoreSheetDeleteBtn}
                            onPress={() =>
                              RemoveGoalCardSub(ele, "hello", index)
                            }
                          >
                            <TrashIcon
                              name="trash-bin-sharp"
                              size={22}
                              color="red"
                            />
                          </Pressable>
                        </View>
                        <View style={styles.scoreSheetDetailsContainer}>
                          <View
                            style={{
                              flexDirection: "column",
                              alignItems: "center",
                              width: "35%",
                            }}
                          >
                            <Image
                              // source={require("../../../../assets/home/logo.png")}
                              source={{ uri: ele.Team1.LogoURL }}
                              style={styles.scoreTeamLogo2}
                              resizeMode="contain"
                            />
                            <Text style={styles.scoreTeamText2}>
                              {ele.Team1.TeamName}
                            </Text>
                          </View>
                          <View style={{ flex: 1 }}>
                            {!Object.keys(ele).includes("InPlayer") ? (
                              <View
                                style={{
                                  flexDirection: "row",
                                  gap: 10,
                                  alignItems: "center",
                                  flex: 1,
                                }}
                              >
                                <Image
                                  // source={require("../../../../assets/Avtar/Avatar.png")}
                                  source={{ uri: ele.Player.PhotoURL }}
                                  style={styles.scoreTeamLogo2}
                                  resizeMode="contain"
                                />
                                <Text style={styles.scoreTeamText2}>
                                  {ele.Player.Name}
                                </Text>
                              </View>
                            ) : null}

                            {!Object.keys(ele).includes("InPlayer") &&
                            !ele.CardType ? (
                              <View style={{ alignItems: "flex-end" }}>
                                <View style={styles.scoreSheetGoalTag}>
                                  <Football
                                    name="soccer-ball-o"
                                    size={22}
                                    color="#fff"
                                  />
                                  <Text style={styles.scoreUpdateButtonText}>
                                    Goal
                                  </Text>
                                </View>
                              </View>
                            ) : null}
                          </View>
                        </View>
                      </View>
                    );
                  })}
              </View>

              {/* score sheet web */}
              <View
                style={{
                  display:
                    Platform.OS === "web" && screenWidth > 760
                      ? "flex"
                      : "none",
                  flexDirection: "column",
                  gap: 10,
                  marginTop: 10,
                }}
              >
                {MatchObj &&
                  Object.keys(MatchObj).length > 0 &&
                  MatchObj.GoalCardList &&
                  MatchObj.GoalCardList.length > 0 &&
                  MatchObj.GoalCardList.map((ele, index) => {
                    return (
                      <View style={styles.scoreSheetItemWeb}>
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            width: "30%",
                          }}
                        >
                          <Image
                            // source={require("../../../../assets/home/logo.png")}
                            source={{ uri: ele.Team1.LogoURL }}
                            style={styles.scoreTeamLogo2}
                            resizeMode="contain"
                          />
                          <Text style={styles.scoreTeamText2}>
                            {ele.Team1.TeamName}
                          </Text>
                        </View>
                        {!Object.keys(ele).includes("InPlayer") ? (
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              width: "30%",
                              gap: 5,
                            }}
                          >
                            {/* <Image
                                // source={require("../../../../assets/Avtar/Avatar.png")}
                                source={{ uri: ele.Player.PhotoURL }}
                                style={styles.scoreTeamLogo2}
                                resizeMode="contain"
                              /> */}
                            <View style={styles.scoreSheetJerseyNumber}>
                              <Text
                                style={{
                                  fontFamily: "PlusJakartaSansSemiBold",
                                }}
                              >
                                {ele.Player.JerseyNo}
                              </Text>
                            </View>
                            <Text style={styles.scoreTeamText2}>
                              {ele.Player.Name}
                            </Text>
                          </View>
                        ) : null}

                        {Object.keys(ele).includes("InPlayer") ? (
                          <View
                            style={{
                              flexDirection: "column",
                              width: "30%",
                              gap: 5,
                            }}
                          >
                            {Object.keys(ele).includes("InPlayer") ? (
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                  gap: 5,
                                }}
                              >
                                <View
                                  style={[
                                    styles.scoreSheetJerseyNumber,
                                    { backgroundColor: "#8dc78d" },
                                  ]}
                                >
                                  <Text
                                    style={{
                                      fontFamily: "PlusJakartaSansSemiBold",
                                      color: "green",
                                    }}
                                  >
                                    {ele.InPlayer.JerseyNo}
                                  </Text>
                                </View>
                                <Text
                                  style={[
                                    styles.scoreTeamText2,
                                    { color: "green" },
                                  ]}
                                >
                                  {ele.InPlayer.Name}
                                </Text>
                              </View>
                            ) : null}

                            {Object.keys(ele).includes("InPlayer") ? (
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                  gap: 5,
                                }}
                              >
                                <View
                                  style={[
                                    styles.scoreSheetJerseyNumber,
                                    { backgroundColor: "#f1a3a3" },
                                  ]}
                                >
                                  <Text
                                    style={{
                                      fontFamily: "PlusJakartaSansSemiBold",
                                      color: "red",
                                    }}
                                  >
                                    {ele.OutPlayer.JerseyNo}
                                  </Text>
                                </View>
                                <Text
                                  style={[
                                    styles.scoreTeamText2,
                                    { color: "red" },
                                  ]}
                                >
                                  {ele.OutPlayer.Name}
                                </Text>
                              </View>
                            ) : null}
                          </View>
                        ) : null}

                        <View
                          style={{
                            width: "15%",
                            flexDirection: "row",
                            gap: 8,
                            alignItems: "center",
                          }}
                        >
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <H3
                              props={ele.Time ? ele.Time : "00"}
                              style={{ color: colors.white }}
                            />
                          </View>
                          {/* </View> */}
                          <Text style={styles.scoreTeamText2}>Minutes</Text>
                        </View>

                        <View
                          style={{
                            width: "20%",
                            alignItems: "center",
                            flexDirection: "row",
                            gap: 10,
                          }}
                        >
                          {!Object.keys(ele).includes("InPlayer") &&
                          !ele.CardType ? (
                            <View
                              style={[
                                activeScoreTab === 0
                                  ? styles.scoreUpdateButtonActive
                                  : styles.scoreUpdateButton,
                                ,
                                { flex: 1 },
                              ]}
                            >
                              <Football
                                name="soccer-ball-o"
                                size={22}
                                color="#fff"
                              />
                              <Text style={styles.scoreUpdateButtonText}>
                                Goal
                              </Text>
                            </View>
                          ) : null}

                          {!Object.keys(ele).includes("InPlayer") &&
                          ele.CardType ? (
                            <>
                              <View
                                style={
                                  ele.CardType == "Red"
                                    ? styles.redCardClick
                                    : styles.yellowCardClick
                                }
                              ></View>
                              <Text style={styles.scoreTeamText2}>
                                {ele.CardType == "Red"
                                  ? "Red Card"
                                  : "Yellow Card"}
                              </Text>
                            </>
                          ) : null}

                          {Object.keys(ele).includes("InPlayer") ? (
                            <>
                              <View
                                style={{
                                  flex: 1,
                                  flexDirection: "row",
                                  gap: 10,
                                }}
                              >
                                <SubstituteIcon
                                  name="swap-horizontal"
                                  size={25}
                                  color={colors.white}
                                />
                                <Text style={styles.scoreTeamText2}>
                                  Substitute
                                </Text>
                              </View>
                            </>
                          ) : null}

                          {!Object.keys(ele).includes("InPlayer") &&
                          !ele.CardType &&
                          ele.Penalty == false ? (
                            <View style={{ flex: 1, alignItems: "center" }}>
                              <Pressable onPress={() => handlePenalty(ele)}>
                                <Text
                                  style={{
                                    color: colors.white,
                                    paddingVertical: 2,
                                    paddingHorizontal: 8,
                                    fontFamily: "PlusJakartaSansSemiBold",
                                  }}
                                >
                                  P
                                </Text>
                              </Pressable>
                            </View>
                          ) : null}
                          {!Object.keys(ele).includes("InPlayer") &&
                          !ele.CardType &&
                          ele.Penalty == true ? (
                            <View style={{ flex: 1, alignItems: "center" }}>
                              <Pressable
                                style={[styles.PenaltyView, styles.shadowProp]}
                                // onPress={() => handlePenalty(ele)}
                              >
                                <Text
                                  style={{
                                    color: colors.white,
                                    paddingVertical: 2,
                                    paddingHorizontal: 8,
                                    fontSize: 17,
                                    fontFamily: "PlusJakartaSansSemiBold",
                                  }}
                                >
                                  P
                                </Text>
                              </Pressable>
                            </View>
                          ) : null}
                        </View>

                        <View style={{ width: "5%", alignItems: "center" }}>
                          <Pressable
                            onPress={() => RemoveGoalCardSub(ele, "hii", index)}
                          >
                            <TrashIcon
                              name="trash-bin-sharp"
                              size={22}
                              color="red"
                            />
                          </Pressable>
                        </View>
                      </View>
                    );
                  })}
              </View>
              {/* score sheet ended here */}
            </View>
          </ScrollView>
        </View>

        {modalVisible == true ? (
          <SuccessModal
            visibleModal={modalVisible}
            handleClose={
              SunccessModalMesg ==
              "Are you Sure to you want to finish this Match."
                ? handleClose
                : undefined
            }
            SuccessMessage={SunccessModalMesg}
            handleConfirm={
              SunccessModalMesg ==
              "Are you Sure to you want to finish this Match."
                ? handleConfirm
                : undefined
            }
            handleOk={
              SunccessModalMesg ==
              "Are you Sure to you want to finish this Match."
                ? undefined
                : handleOk
            }
          />
        ) : null}
        {/* modal ended */}
      </AdminLayout>
    </>
  );
};

const styles = StyleSheet.create({
  yellowCardClick: {
    width: 25,
    height: 30,
    backgroundColor: "#F9DB05",
    borderRadius: 3,
  },
  redCardClick: {
    width: 25,
    height: 30,
    backgroundColor: "#FF0000",
    borderRadius: 3,
  },

  scoreTopWrapper: {
    width: "100%",
    // height: 180,
    paddingTop: 30,
    paddingBottom: 75,
    paddingHorizontal: 10,
    backgroundColor: colors.royalBlue,
  },
  scoreContentWrapper: {
    width: "100%",
    paddingBottom: 50,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    // marginTop: -40,
    backgroundColor: colors.royalBlue,
    padding: 10,
  },
  scoreUpdateButton: {
    flex: 1,
    backgroundColor: colors.royalBlueColour,
    display: "flex",
    flexDirection: "row",
    borderRadius: 6,
    alignItems: "center",
    gap: 10,
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  scoreUpdateButtonActive: {
    flex: 1,
    backgroundColor: colors.orange,
    display: "flex",
    flexDirection: "row",
    borderRadius: 6,
    alignItems: "center",
    gap: 10,
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  scoreUpdateButtonText: {
    color: colors.royalBlue,
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 14,
  },
  scoreUpdateButtonText2: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 14,
  },
  scoreUpdateButtonTextActie: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 14,
  },
  scoreUpdateButtonTextBig: {
    color: colors.white,
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 18,
  },
  activeTabButton: {
    flex: 1,
    backgroundColor: colors.orange,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 7,
    padding: 7,
  },
  scoreTeamLogo: {
    width: 45,
    height: 45,
    resizeMode: "contain",
    borderRadius: 40,
  },
  scoreTeamLogo2: {
    width: 35,
    height: 35,
    resizeMode: "contain",
    borderRadius: 40,
  },
  teamPlayerNameBtn: {
    flex: 1,
    backgroundColor: colors.royalBlue,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 7,
    padding: 7,
  },
  teamSelectedPlayer: {
    flexDirection: "row",
    flex: 1,
  },
  selectedPlayerWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: colors.green,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    padding: 10,
    borderRadius: 8,
    marginTop: 10,
  },
  teamPlayerNameBtnText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 12,
  },
  activeTeamPlayerNameBtnText: {
    //will delete
    color: "#fff",
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 12,
  },
  teamPlayerList: {
    flexDirection: "column",
    gap: 10,
    marginTop: 10,
    backgroundColor: colors.royalBlue,
    padding: 10,
    borderRadius: 10,
  },
  teamPlayerWrapper: {
    flexDirection: "row",
    backgroundColor: colors.bgColor,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    padding: 10,
    borderRadius: 8,
    position: "relative",
  },
  teamPlayerSNWrapper: {
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 15,
    width: 80,
    textAlign: "center",
    color: colors.white,
  },
  teamPlayerNameWrapper: {
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 15,
    flex: 1,
    textAlign: "left",
    color: colors.white,
  },
  goalButton: {
    backgroundColor: colors.royalBlueColour,
    display: "flex",
    flexDirection: "row",
    borderRadius: 6,
    alignItems: "center",
    gap: 10,
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  goalButton2: {
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "row",
    borderRadius: 6,
    borderColor: colors.white,
    borderWidth: 1,
    alignItems: "center",
    gap: 10,
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  goalButton2Active: {
    backgroundColor: colors.orangeColour,
    display: "flex",
    flexDirection: "row",
    borderRadius: 6,
    borderColor: colors.orangeColour,
    borderWidth: 1,
    alignItems: "center",
    gap: 10,
    paddingVertical: 5,
    paddingHorizontal: 10,
  },
  scoreTeamText2: {
    color: colors.white,
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 14,
    textAlign: "center",
  },
  scoreSheetJerseyNumber: {
    width: 35,
    height: 35,
    borderRadius: 40,
    backgroundColor: "#afbad1",
    justifyContent: "center",
    alignItems: "center",
  },

  scoreSheetItem: {
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    backgroundColor: colors.bgColor,
    borderRadius: 7,
    position: "relative",
  },
  ssGoalHeadingWrapper: {
    backgroundColor: colors.royalBlueColour,
    borderRadius: 10,
    padding: 8,
    flexDirection: "row",
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    position: "relative",
    height: 40,
    justifyContent: "space-between",
    paddingRight: 100,
  },
  ssRedCardHeadingWrapper: {
    backgroundColor: "#FF0000",
    borderRadius: 10,
    padding: 8,
    flexDirection: "row",
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    position: "relative",
    height: 40,
    justifyContent: "space-between",
    paddingRight: 100,
  },
  ssYellowCardHeadingWrapper: {
    backgroundColor: "#FFC700",
    borderRadius: 10,
    padding: 8,
    flexDirection: "row",
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    position: "relative",
    height: 40,
    justifyContent: "space-between",
    paddingRight: 100,
  },
  scoreSheetDeleteBtn: {
    position: "absolute",
    right: 2,
    top: 2,
    backgroundColor: "#fff",
    height: 36,
    width: 36,
    borderRadius: 25,
    justifyContent: "center",
    alignItems: "center",
  },
  scoreSheetTitle: {
    flexDirection: "row",
    gap: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  scoreSheetBtnContainer: {
    flexDirection: "row",
    gap: 15,
    justifyContent: "flex-end",
  },
  scoreSheetBtnWrapper: {
    flexDirection: "row",
    alignItems: "center",
    gap: 5,
  },
  scoreSheetBtnText: {
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 14,
    color: colors.royalBlueColour,
  },
  scoreSheetDetailsContainer: {
    padding: 10,
    flexDirection: "row",
    gap: 20,
    alignItems: "center",
  },
  scoreSheetGoalTag: {
    backgroundColor: "#2EB405",
    flexDirection: "row",
    gap: 10,
    padding: 5,
    borderRadius: 5,
    width: 100,
    justifyContent: "center",
  },
  scoreSheetItemWeb: {
    flexDirection: "row",
    backgroundColor: colors.bgColor,
    justifyContent: "space-between",
    alignItems: "center",
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    padding: 5,
    borderRadius: 8,
  },
  addTimer: {
    marginBottom: 0,
    // marginTop: -20,
    width: "100%",
    height: 40,
    outlineColor: "transparent",
    outlineWidth: 0,
    fontSize: 18,
    fontFamily: "PlusJakartaSansBold",
    textAlign: "center",
  },
  scoringAppStartTimerText: {
    fontFamily: "PlusJakartaSansExtraBold",
    fontSize: 14,
    color: "#fff",
  },
  scoringAppStartTimer: {
    flex: 1,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#50C878",
    borderRadius: 8,
  },
  scoringAppStartTimer2: {
    backgroundColor: "transparent",
    flex: 1,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#fff",
    borderRadius: 8,
    flexDirection: "row",
    gap: 5,
  },
  endMatchStyle: {
    width: "100%",
    height: 30,
    backgroundColor: "#FF0000",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#FF0000",
    borderRadius: 8,
  },
  shadowProp: {
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },

  PenaltyView: {
    backgroundColor: "#2EFD43",
    borderRadius: 14,
  },
  timerFontStyle: {
    color: "#12FFE3",
    fontFamily: "digital4",
    fontSize: 60,
    letterSpacing: 5,
  },
});

export default ScoringApp;
