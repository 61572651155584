import { useNavigation, useRoute } from "@react-navigation/native";
import {
  View,
  Text,
  StyleSheet,
  Pressable,
  Image,
  Dimensions,
  Platform,
  Modal,
  ActivityIndicator,
} from "react-native";
import React, { useState, useEffect } from "react";
import { H1, H4 } from "../../../../components/reusable/TextComponent";
import { colors } from "../../../../components/reusable/colour";
import { List } from "react-native-paper";
import Icon from "react-native-vector-icons/AntDesign";
import ViewTeamModal from "./ViewTeamModal";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { TeamRegistrations, Tournament } from "../../../../models";
import { DataStore } from "aws-amplify/datastore";
import TeamStatusForm from "./TeamStatusForm";
import { RegistrationStatus } from "../../../../models";
// import defaultLogo from "../../../../../assets/Avtar/football-club.png";
import AddIcon from "react-native-vector-icons/Feather";
import RegAddTeamModal from "./RegAddTeamModal";
import { PaymentStatus } from "../../../../models";
import { ScrollView } from "react-native-gesture-handler";
import { ConfirmationModal } from "../../../../components/reusable/AreYouSure";
import TeamMemberPDF from "../../MyTournament/TeamMemberPDF";
import PDFIcon from "react-native-vector-icons/AntDesign";
import ZoomableImage from "../../../../components/reusable/ZoomableImage";
const screenWidth = Dimensions.get("window").width;

const Registration = () => {
  const defaultLogo: any = require("../../../../../assets/Avtar/football-club.png");
  const [modalVisible, setModalVisible] = useState(false);
  const navigation: any = useNavigation();
  const [expanded, setExpanded] = React.useState(true);
  const [loading, setLoading] = useState(false);
  const [Registrationdata, setRegistrationdata] = useState([]);
  const [Playerdata, setPlayerdata] = useState([]);
  const [pdfPlayerData, setPdfPlayerData]: any = useState({});
  const [Modalshowflag, setModalshowflag] = useState(false);
  const [Messagetext, setMessagetext] = useState("");
  const [FisrtMessage, setFisrtMessage] = useState("");
  const [SelectedTeam, setSelectedTeam]: any = useState({});
  const [RegistrationAllCount, setRegistrationAllCount]: any = useState({});
  const [Tournamentobj, setTournamentobj]: any = useState({});
  const [regModalshow, setregModalshow] = useState(false);
  const [paymentModalshow, setPaymentModalshow] = useState(false);
  const [paymentModalmsg, setPaymentModalmsg] = useState("");
  // const [TournamentId, setTournamentId] = useState("");
  const [teamMemberVisible, setTeamMemberVisible] = useState(false);

  const route = useRoute();

  const { tournamentId }: any = route.params;
  // let tournamentID: any;

  const handlePress = () => setExpanded(!expanded);
  const handleOpen = () => {
    setModalVisible(true);
  };
  const handleClose = () => {
    setModalVisible(false);
  };
  const handleClose2 = () => {
    setModalshowflag(false);
  };

  // const getTournamentId = async () => {
  //   tournamentID = await AsyncStorage.getItem("CurrentTournament");
  //   console.log("tournamentID", tournamentID);
  // };

  const handleApproved = async () => {
    console.log("Approved", SelectedTeam);
    let teamdata = await DataStore.query(TeamRegistrations, SelectedTeam.id);
    const TeamregistrationUpdate = TeamRegistrations.copyOf(
      teamdata,
      (updated) => {
        updated.Status = RegistrationStatus.APPROVED;
        return updated;
      }
    );
    console.log("TeamregistrationUpdate", TeamregistrationUpdate);
    const ResultofMatchTeam = await DataStore.save(TeamregistrationUpdate);
    await handleGetRegistrationdata();
    setModalshowflag(false);
  };

  const handleAddTeam = () => {
    // setregModalshow(true);
    navigation.navigate("TeamParticipationForm", {
      tournamentId: tournamentId,
    });
  };
  const handleCancel = () => {
    setTeamMemberVisible(false);
  };

  const getPlayersData = async (data) => {
    try {
      setLoading(true);
      // console.log("helloplayers", data);
      let Playerpromise: any = await [data.Players];
      let Playerdata = await Playerpromise[0].values;
      console.log("UniquePlayerdatalist", Playerdata);
      let finalplayerdata =
        Playerdata &&
        Playerdata.length > 0 &&
        Playerdata.map(async (ele) => {
          let MasterPlayer = await ele.playerMaster;
          // console.log("JersyNo", MasterPlayer);
          let Userdata = await MasterPlayer?.PlayerData;
          let Playerdata = await MasterPlayer;
          let playerobj = {
            playerdata: Playerdata,
            UserData: Userdata,
            // Jersey: MasterPlayer.JerseyNo !== null ? MasterPlayer.JerseyNo : "",
          };
          return playerobj;
        });
      let final = [];
      if (Playerdata && Playerdata.length > 0) {
        final = await Promise.all(finalplayerdata);
      }

      let validPlayerData = [];
      validPlayerData =
        final &&
        final.length > 0 &&
        final.filter((data) => data !== undefined && data !== null);
      console.log("validPlayerData", validPlayerData);
      let values =
        validPlayerData &&
        validPlayerData.length > 0 &&
        validPlayerData !== null &&
        validPlayerData !== undefined
          ? validPlayerData
          : [];
      console.log("values", values);
      setLoading(false);
      return values;
    } catch (error) {
      setLoading(false);
      console.log("Error occured while fetching the players data", error);
    }
  };
  const handleTeamPDF = async (value) => {
    console.log("handle Team PDF", value);
    setTeamMemberVisible(true);
    let getdata = await DataStore.query(TeamRegistrations, value.id);
    let Playersdata = await getPlayersData(getdata);
    let obj = {
      PlayerData: Playersdata,
      RegistrationObj: value,
      tournamentobj: Tournamentobj,
    };
    setPdfPlayerData(obj);
    console.log("Players data after team pdf click", obj);
  };

  const handleReject = async () => {
    console.log("Rejected", SelectedTeam);
    let teamdata = await DataStore.query(TeamRegistrations, SelectedTeam.id);
    const TeamregistrationUpdate = TeamRegistrations.copyOf(
      teamdata,
      (updated) => {
        updated.Status = RegistrationStatus.REJECTED;
        return updated;
      }
    );
    console.log("TeamregistrationUpdate", TeamregistrationUpdate);
    const ResultofMatchTeam = await DataStore.save(TeamregistrationUpdate);
    await handleGetRegistrationdata();
    setModalshowflag(false);
  };

  const handleMarkPaid = async () => {
    console.log("Approved", SelectedTeam);
    let teamdata = await DataStore.query(TeamRegistrations, SelectedTeam.id);
    const TeamregistrationUpdate = TeamRegistrations.copyOf(
      teamdata,
      (updated) => {
        updated.PaymentStatus = PaymentStatus.PAID;
        return updated;
      }
    );
    console.log("TeamregistrationUpdate", TeamregistrationUpdate);
    const ResultofMatchTeam = await DataStore.save(TeamregistrationUpdate);
    paymentHandleClose();
    await handleGetRegistrationdata();
    setModalshowflag(false);
  };

  const handleMarkUnpaid = async () => {
    console.log("Approved", SelectedTeam);
    let teamdata = await DataStore.query(TeamRegistrations, SelectedTeam.id);
    const TeamregistrationUpdate = TeamRegistrations.copyOf(
      teamdata,
      (updated) => {
        updated.PaymentStatus = PaymentStatus.UNPAID;
        return updated;
      }
    );
    console.log("TeamregistrationUpdate", TeamregistrationUpdate);
    const ResultofMatchTeam = await DataStore.save(TeamregistrationUpdate);
    paymentHandleClose();
    await handleGetRegistrationdata();
    setModalshowflag(false);
  };

  const playergetdata = async (data) => {
    // console.log("helloplayers", data);
    let Playerpromise: any = await [data.Players];
    let Playerdata = await Playerpromise[0].values;
    console.log("UniquePlayerdatalist", Playerdata);
    let finalplayerdata =
      Playerdata &&
      Playerdata.length > 0 &&
      Playerdata.map(async (ele) => {
        let MasterPlayer = await ele.playerMaster;
        // console.log("JersyNo", MasterPlayer);
        if (MasterPlayer && Object.keys(MasterPlayer).length > 0) {
          let Userdata = await MasterPlayer.PlayerData;

          let Playerdata = await MasterPlayer;
          let playerobj = {
            playerdata: Playerdata,
            UserData: Userdata,
            // Jersey: MasterPlayer.JerseyNo !== null ? MasterPlayer.JerseyNo : "",
          };
          return playerobj;
        }
        return null;
      }).filter((ele) => ele !== null);
    let final = [];
    // console.log("hellofinal", finalplayerdata);
    if (Playerdata && Playerdata.length > 0) {
      final = await Promise.all(finalplayerdata);
    }

    let validPlayerData = [];
    validPlayerData =
      final && final.length > 0 && final.filter((data) => data !== undefined);
    console.log("validPlayerData", validPlayerData);
    let values =
      validPlayerData && validPlayerData.length > 0 ? validPlayerData : [];
    return values;
  };

  const handleGetRegistrationdata = async () => {
    setLoading(true);
    let getdata = await DataStore.query(Tournament, tournamentId);
    console.log("getdata", getdata);
    setTournamentobj(getdata);
    let registrationPromise: any = await [getdata.Registrations];
    let Registrationdata = await registrationPromise[0].values;
    console.log("Registrationdata", Registrationdata);
    if (Registrationdata && Registrationdata.length > 0) {
      let AllRegdata = Registrationdata.map(async (ele) => ({
        ...ele,
        PlayerData: await playergetdata(ele),
      }));
      let final = await Promise.all(AllRegdata);
      console.log("finaldata", final);
      setRegistrationdata(final);
    }

    let filterdaata = [];
    let UnPaid = [];
    let Submitdata = [];
    if (Registrationdata && Registrationdata.length > 0) {
      Submitdata = Registrationdata.filter((ele) => ele.Status === "SUBMITTED");
      filterdaata = Registrationdata.filter((ele) => ele.Status === "APPROVED");
      UnPaid = Registrationdata.filter((ele) => ele.PaymentStatus === "UNPAID");
    }

    let obj = {
      Submitdata: Submitdata.length,
      Approveddata: filterdaata.length,
      Pendingdata: UnPaid.length,
      Alldata: Registrationdata.length,
    };
    console.log("helloObj", obj);
    setRegistrationAllCount(obj);
    setLoading(false);
  };

  const handleplayerClick = (value) => {
    console.log("handleplayerClick", value);
    setPlayerdata(value);
    handleOpen();
  };
  const RegHandleClose = () => {
    setregModalshow(false);
  };

  const handlePaymentStatus = (value) => {
    console.log("TeamObj", value);
    setSelectedTeam(value);
    setPaymentModalshow(true);
    setPaymentModalmsg("Update payment status for this team?");
  };
  const paymentHandleClose = () => {
    setPaymentModalshow(false);
  };

  const handleOpenPopUp = (values) => {
    console.log("OpenApprovedreject", values);
    // if (values.Status == "APPROVED" || values.Status == "REJECTED") {
    //   alert(
    //     `The team's status has already been set to ${
    //       values.Status == "APPROVED" ? "Approved" : "Rejected"
    //     } and it cannot be modified.`
    //   );
    // } else {
    console.log("Tournamentobj", values, Tournamentobj);
    setSelectedTeam(values);
    setFisrtMessage(
      `Players Registered:${values.PlayerData.length}/${Tournamentobj.Playing}`
    );
    setMessagetext(
      "This team has fewer players than the required minimum.Please select an action to approve or reject the team's participation in the tournament"
    );
    setModalshowflag(true);
    // }
  };
  useEffect(() => {
    // getTournamentId();
    console.log("tournamentId from routes:", tournamentId);
    handleGetRegistrationdata();
  }, []);
  return (
    <View style={styles.TabContentContainer}>
      <View style={styles.regTopContainer}>
        <View style={styles.registrationNumberCard}>
          <H4
            props={"No. of  Registrations:"}
            style={{ color: colors.white, textAlign: "center" }}
          />
          <H1
            props={RegistrationAllCount && RegistrationAllCount.Alldata}
            style={{ color: colors.white }}
          />
        </View>
        <View style={styles.registrationNumberCard}>
          <H4
            props={"New Registrations:"}
            style={{ color: colors.white, textAlign: "center" }}
          />
          <H1
            props={RegistrationAllCount && RegistrationAllCount.Submitdata}
            style={{ color: colors.white }}
          />
        </View>
        <View style={styles.registrationNumberCard}>
          <H4
            props={"No. of Approved Registrations:"}
            style={{ color: colors.white, textAlign: "center" }}
          />
          <H1
            props={RegistrationAllCount && RegistrationAllCount.Approveddata}
            style={{ color: colors.white }}
          />
        </View>
        <View style={styles.registrationNumberCard}>
          <H4
            props={"Payment Pending:"}
            style={{ color: colors.white, textAlign: "center" }}
          />
          <H1
            props={RegistrationAllCount && RegistrationAllCount.Pendingdata}
            style={{ color: colors.white }}
          />
        </View>
      </View>

      <View style={{ marginTop: 30, alignItems: "flex-end" }}>
        <Pressable
          onPress={() => {
            handleAddTeam();
          }}
          style={styles.addTeamBtn}
        >
          <AddIcon name="plus-circle" size={18} color={colors.white} />
          <Text
            style={{
              color: colors.white,
              fontSize: 14,
              fontFamily: "PlusJakartaSansRegular",
            }}
          >
            Add Team
          </Text>
        </Pressable>
      </View>

      <View
        style={{
          marginTop: 10,
        }}
      >
        {/* table started here (only for web) */}
        <View
          style={{
            width: "100%",
            display:
              Platform.OS === "web" && screenWidth > 760 ? "flex" : "none",
          }}
        >
          {/* table header started */}
          <View style={styles.tableHeaderContainer}>
            <View style={[styles.tableHeaderTitleSN]}>
              <Text style={styles.tableHeaderTitleText}>Sr.No.</Text>
            </View>
            <View style={[styles.tableHeaderTitleLoGo]}>
              <Text style={styles.tableHeaderTitleText}>Logo</Text>
            </View>
            <View
              style={[styles.tableHeaderTitle, { alignItems: "flex-start" }]}
            >
              <Text style={styles.tableHeaderTitleText}>Team Name</Text>
            </View>
            <View style={[styles.tableHeaderTitle, { maxWidth: 110 }]}>
              <Text style={styles.tableHeaderTitleText}>Registered Date</Text>
            </View>
            <View style={[styles.tableHeaderTitle, { maxWidth: 110 }]}>
              <Text style={styles.tableHeaderTitleText}>Status</Text>
            </View>
            <View style={[styles.tableHeaderTitle, { maxWidth: 110 }]}>
              <Text style={styles.tableHeaderTitleText}>Payment</Text>
            </View>
            <View style={[styles.tableHeaderTitle, { maxWidth: 110 }]}>
              <Text style={styles.tableHeaderTitleText}>View</Text>
            </View>
          </View>
          {Registrationdata &&
            Registrationdata.length > 0 &&
            Registrationdata.map((ele, idx) => {
              return (
                <View
                  style={[
                    styles.tableDataContainer,
                    {
                      backgroundColor:
                        idx % 2 === 0 ? colors.royalBlue : "transparent",
                    },
                  ]}
                >
                  <View style={styles.tableDataCellSN}>
                    <Text style={styles.tableDataCellText}>{idx + 1}</Text>
                  </View>
                  <View style={[styles.tableDataCellLogo]}>
                    {/* <Image
                      source={{
                        uri: ele.Logo ? ele.Logo : defaultLogo,
                      }}
                      style={{ width: 40, height: 40, borderRadius: 40 }}
                      resizeMode="contain"
                    /> */}
                    <ZoomableImage
                      imageSource={{
                        uri: ele.Logo ? ele.Logo : defaultLogo,
                      }}
                      imageStyle={{
                        width: 40,
                        height: 40,
                        borderRadius: 40,
                        resizeMode: "contain",
                      }}
                      modalStyle={undefined}
                    />
                  </View>
                  <View
                    style={[styles.tableDataCell, { alignItems: "flex-start" }]}
                  >
                    <Text style={styles.tableDataCellText}>{ele.TeamName}</Text>
                  </View>
                  <View style={[styles.tableDataCell, { maxWidth: 110 }]}>
                    <Text style={styles.tableDataCellText}>
                      {ele.createdAt && ele.createdAt.split("T")[0]}
                    </Text>
                  </View>
                  <View style={[styles.tableDataCell, { maxWidth: 110 }]}>
                    <Pressable
                      style={[
                        styles.tableButton,
                        {
                          backgroundColor:
                            ele.Status === "APPROVED"
                              ? "#07b12c"
                              : ele.Status === "REJECTED"
                              ? "#f13737"
                              : colors.grayColour,
                          borderColor:
                            ele.Status === "APPROVED"
                              ? "#07b12c"
                              : ele.Status === "REJECTED"
                              ? "#f13737"
                              : colors.grayColour,
                        },
                      ]}
                      onPress={() => {
                        handleOpenPopUp(ele);
                      }}
                    >
                      <Text
                        style={[
                          styles.tableButtonText,
                          {
                            color:
                              ele.Status === "SUBMITTED"
                                ? colors.darkGray
                                : colors.white,
                          },
                        ]}
                      >
                        {ele.Status}
                      </Text>
                    </Pressable>
                  </View>
                  <View style={[styles.tableDataCell, { maxWidth: 110 }]}>
                    <Pressable
                      onPress={() => {
                        handlePaymentStatus(ele);
                      }}
                      style={[
                        styles.tableButton,
                        {
                          borderColor:
                            ele.PaymentStatus === "PAID"
                              ? "#07b12c"
                              : "#ff6e5e",
                        },
                      ]}
                    >
                      {ele.PaymentStatus === "PAID" ? (
                        <Icon name="checkcircleo" size={18} color="#07b12c" />
                      ) : (
                        <Icon
                          name="exclamationcircleo"
                          size={18}
                          color="#ff6e5e"
                        />
                      )}
                      <Text
                        style={[
                          styles.tableButtonText,
                          {
                            color:
                              ele.PaymentStatus === "PAID"
                                ? "#07b12c"
                                : "#ff6e5e",
                          },
                        ]}
                      >
                        {ele.PaymentStatus}
                      </Text>
                    </Pressable>
                  </View>
                  <View
                    style={[
                      styles.tableDataCell,
                      { maxWidth: 120, flexDirection: "row", gap: 5 },
                    ]}
                  >
                    <Pressable onPress={() => handleTeamPDF(ele)}>
                      <PDFIcon name="pdffile1" size={24} color={colors.red} />
                    </Pressable>
                    <Pressable
                      onPress={() => handleplayerClick(ele)}
                      style={[
                        styles.tableButton,
                        {
                          backgroundColor: colors.grayColour,
                          borderColor: colors.grayColour,
                        },
                      ]}
                    >
                      <Icon name="eye" size={18} color="#666666" />
                      <Text style={styles.tableButtonText}>View</Text>
                    </Pressable>
                  </View>
                </View>
              );
            })}
        </View>
        {teamMemberVisible ? (
          <TeamMemberPDF
            visibleModal={teamMemberVisible}
            handleCancel={handleCancel}
            teamData={pdfPlayerData}
          />
        ) : null}
        {/* only for mobile */}
        <View
          style={{
            display:
              Platform.OS === "web" && screenWidth > 760 ? "none" : "flex",
            gap: 15,
          }}
        >
          {Registrationdata &&
            Registrationdata.length > 0 &&
            Registrationdata.map((ele, idx) => {
              return (
                <>
                  <Pressable
                    style={styles.mobRegContainer}
                    onPress={() => handleplayerClick(ele)}
                  >
                    <View style={styles.mobRegTopRow}>
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 10,
                        }}
                      >
                        {/* <Image
                          source={{
                            uri: ele.Logo !== null ? ele.Logo : defaultLogo,
                          }}
                          style={styles.mobRegTeamLogo}
                        /> */}
                        <ZoomableImage
                          imageSource={{
                            uri: ele.Logo !== null ? ele.Logo : defaultLogo,
                          }}
                          imageStyle={styles.mobRegTeamLogo}
                          modalStyle={undefined}
                        />
                        <Text style={styles.mobRegRowText}>{ele.TeamName}</Text>
                      </View>
                      <Text style={styles.mobRegRowText}>
                        Sr. No. {idx + 1}
                      </Text>
                    </View>
                    <View style={{ gap: 10, padding: 6 }}>
                      <View style={styles.mobRegRow}>
                        <Text style={styles.mobRegRowText}>Status</Text>
                        <Pressable
                          style={[
                            styles.tableButton,
                            {
                              backgroundColor:
                                ele.Status === "APPROVED"
                                  ? "#07b12c"
                                  : ele.Status === "REJECTED"
                                  ? "#f13737"
                                  : colors.grayColour,
                              borderColor:
                                ele.Status === "APPROVED"
                                  ? "#07b12c"
                                  : ele.Status === "REJECTED"
                                  ? "#f13737"
                                  : colors.grayColour,
                            },
                          ]}
                          onPress={() => {
                            handleOpenPopUp(ele);
                          }}
                        >
                          <Text
                            style={[
                              styles.tableButtonText,
                              {
                                color:
                                  ele.Status === "SUBMITTED"
                                    ? colors.darkGray
                                    : colors.white,
                              },
                            ]}
                          >
                            {ele.Status}
                          </Text>
                        </Pressable>
                      </View>
                      <View style={styles.mobRegRow}>
                        <Text style={styles.mobRegRowText}>
                          Registration Date
                        </Text>
                        <Text style={styles.mobRegRowText}>
                          {ele.createdAt && ele.createdAt.split("T")[0]}
                        </Text>
                      </View>
                      <View style={styles.mobRegRow}>
                        <Text style={styles.mobRegRowText}>Payment Status</Text>
                        <Pressable
                          onPress={() => {
                            handlePaymentStatus(ele);
                          }}
                          style={[
                            styles.tableButton,
                            {
                              borderColor:
                                ele.PaymentStatus === "PAID"
                                  ? "#07b12c"
                                  : "#ff6e5e",
                            },
                          ]}
                        >
                          {ele.PaymentStatus === "PAID" ? (
                            <Icon
                              name="checkcircleo"
                              size={18}
                              color="#07b12c"
                            />
                          ) : (
                            <Icon
                              name="exclamationcircleo"
                              size={18}
                              color="#ff6e5e"
                            />
                          )}
                          <Text
                            style={[
                              styles.tableButtonText,
                              {
                                color:
                                  ele.PaymentStatus === "PAID"
                                    ? "#07b12c"
                                    : "#ff6e5e",
                              },
                            ]}
                          >
                            {ele.PaymentStatus}
                          </Text>
                        </Pressable>
                      </View>
                    </View>
                  </Pressable>
                </>
              );
            })}
        </View>
        {/* team card ended */}
      </View>

      {/* modal started */}

      {/* {regModalshow == true ? (
        <RegAddTeamModal
          visibleModal={regModalshow}
          handleClose={RegHandleClose}
          handleGetRegistrationdata={handleGetRegistrationdata}
          // squadId={squadId ? squadId : ""}
          // userUUID={currentUserUuid ? currentUserUuid : ""}
          // currentUserName={""}
        />
      ) : null} */}
      {loading && (
        <Modal transparent animationType="none">
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <ActivityIndicator size="large" color="#0000ff" />
            <Text style={{ color: "white" }}>Loading</Text>
          </View>
        </Modal>
      )}
      {modalVisible == true ? (
        <ViewTeamModal
          visibleModal={modalVisible}
          handleClose={handleClose}
          Playersdata={Playerdata}
        />
      ) : null}

      {Modalshowflag == true ? (
        // <TeamStatusForm
        //   visibleModal={Modalshowflag}
        //   handleOk={handleReject}
        //   SuccessMessage={Messagetext}
        //   handleConfirm={handleApproved}
        //   FirstMessage={FisrtMessage}
        //   handleClose={handleClose2}
        // />
        <ConfirmationModal
          visibleModal={Modalshowflag}
          message={Messagetext}
          handleMarkPaid={handleApproved}
          handleMarkUnpaid={handleReject}
          headermsg={FisrtMessage}
        />
      ) : null}

      {paymentModalshow == true ? (
        <ConfirmationModal
          visibleModal={paymentModalshow}
          message={paymentModalmsg}
          handleMarkPaid={handleMarkPaid}
          handleMarkUnpaid={handleMarkUnpaid}
          headermsg={undefined}
        />
      ) : null}
      {/* modal ended */}
    </View>
  );
};

const styles = StyleSheet.create({
  TabContentContainer: {
    width: "100%",
    paddingBottom: Platform.OS === "web" && screenWidth > 760 ? undefined : 100,
  },
  registrationNumberCard: {
    // flex: Platform.OS === "web" && screenWidth > 760 ? 1 : 0,
    flex: Platform.OS === "web" ? 1 : 1,
    maxWidth:
      Platform.OS !== "web"
        ? 200
        : Platform.OS === "web" && screenWidth > 760
        ? undefined
        : "50%",
    minWidth:
      Platform.OS !== "web"
        ? 180
        : Platform.OS === "web" && screenWidth > 760
        ? undefined
        : "40%",
    backgroundColor: colors.royalBlue,
    padding: Platform.OS === "web" && screenWidth > 760 ? 10 : 5,
    borderRadius: 5,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    display: "flex",
    flexDirection:
      Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
    alignItems: "center",
    justifyContent: "center",
    gap: Platform.OS === "web" && screenWidth > 760 ? 20 : 0,
    width: Platform.OS === "web" && screenWidth > 760 ? null : "40%",
    height: Platform.OS === "web" && screenWidth > 760 ? null : "45%",
  },
  regTopContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: Platform.OS === "web" && screenWidth > 760 ? 20 : 15,
    flexWrap: "wrap",
    paddingHorizontal: 5,
    height: Platform.OS === "web" ? undefined : 200,
  },
  // new table
  tableHeaderContainer: {
    backgroundColor: colors.tableHeader,
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
    borderRadius: 10,
    gap: 10,
  },
  tableHeaderTitle: {
    flex: 1,
    paddingVertical: 7,
  },
  tableHeaderTitleSN: {
    width: 70,
    paddingVertical: 7,
  },
  tableHeaderTitleLoGo: {
    width: 80,
    paddingVertical: 7,
  },
  tableHeaderTitleText: {
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 14,
    color: colors.white,
    textAlign: "center",
  },
  tableDataContainer: {
    backgroundColor: colors.white,
    display: "flex",
    flexDirection: "row",
    // marginTop: 10,
    borderRadius: 10,
    shadowColor: "#00000021",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    elevation: 5,
    shadowRadius: 10,
    gap: 10,
  },
  tableDataCell: {
    flex: 1,
    paddingVertical: 5,
    paddingHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  tableDataCellSN: {
    width: 70,
    paddingVertical: 5,
    paddingHorizontal: 5,
    justifyContent: "center",
  },
  tableDataCellLogo: {
    width: 80,
    paddingVertical: 5,
    paddingHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  tableDataCellText: {
    textAlign: "center",
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 14,
    color: colors.white,
  },
  tableButton: {
    borderColor: colors.darkGray,
    borderWidth: 1,
    paddingVertical: 3,
    paddingHorizontal: 10,
    borderRadius: 4,
    display: "flex",
    gap: 5,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  tableButtonText: {
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 14,
    // color: colors.darkGray,
  },
  accordianTitleWrapper: {
    paddingRight: 10,
    backgroundColor: "#c7bef5",
    marginBottom: 10,
    paddingVertical: 0,
  },
  accordianLogo: {
    width: 40,
    height: 40,
    borderRadius: 40,
  },
  accordianHeaderText: {
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 18,
  },
  mobAccordianViewRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  addTeamBtn: {
    flexDirection: "row",
    gap: 5,
    backgroundColor: colors.orangeColour,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    minWidth: 100,
    padding: 5,
    paddingHorizontal: 12,
  },
  mobRegRow: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  mobRegRowText: {
    fontFamily: "PlusJakartaSansExtraLight",
    color: colors.white,
  },
  mobRegContainer: {
    backgroundColor: colors.royalBlue,
    borderRadius: 10,
  },
  mobRegTopRow: {
    backgroundColor: colors.newBlue,
    borderRadius: 10,
    paddingHorizontal: 10,
    paddingVertical: 3,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mobRegTeamLogo: {
    resizeMode: "contain",
    borderRadius: 30,
    width: 30,
    height: 30,
  },
});

export default Registration;
