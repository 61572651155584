import AsyncStorage from "@react-native-async-storage/async-storage";

export const getAuthenticatedUser = async () => {
  let response = {};

  const user = await AsyncStorage.getItem("UserDetail");
  if (user) {
    response.isLoggedIn = true;
    response.attributes = { sub: user };
    console.log("Response : ", response);
    return response;
  } else {
    response.isLoggedIn = false;
    response.attributes = { sub: "" };
    console.log("No data found", response);
    return response;
  }
};
