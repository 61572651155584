import { useEffect, useState } from "react";
import { useFonts } from "expo-font";

const Font = ({ children }) => {
  const [loaded] = useFonts({
    PlusJakartaSansBold: require("../../assets/fonts/PlusJakartaSans-Bold.ttf"),
    PlusJakartaSansBoldItalic: require("../../assets/fonts/PlusJakartaSans-BoldItalic.ttf"),
    PlusJakartaSansExtraBold: require("../../assets/fonts/PlusJakartaSans-ExtraBold.ttf"),
    PlusJakartaSansExtraBoldItalic: require("../../assets/fonts/PlusJakartaSans-ExtraBoldItalic.ttf"),
    PlusJakartaSansExtraLight: require("../../assets/fonts/PlusJakartaSans-ExtraLight.ttf"),
    PlusJakartaSansExtraLightItalic: require("../../assets/fonts/PlusJakartaSans-ExtraLightItalic.ttf"),
    PlusJakartaSansItalicVariableFontwght: require("../../assets/fonts/PlusJakartaSans-Italic-VariableFont_wght.ttf"),
    PlusJakartaSansItalic: require("../../assets/fonts/PlusJakartaSans-Italic.ttf"),
    PlusJakartaSansLightItalic: require("../../assets/fonts/PlusJakartaSans-LightItalic.ttf"),
    PlusJakartaSansMedium: require("../../assets/fonts/PlusJakartaSans-Medium.ttf"),
    PlusJakartaSansMediumItalic: require("../../assets/fonts/PlusJakartaSans-MediumItalic.ttf"),
    PlusJakartaSansRegular: require("../../assets/fonts/PlusJakartaSans-Regular.ttf"),
    PlusJakartaSansSemiBold: require("../../assets/fonts/PlusJakartaSans-SemiBold.ttf"),
    PlusJakartaSansSemiBoldItalic: require("../../assets/fonts/PlusJakartaSans-SemiBoldItalic.ttf"),
    PlusJakartaSansVariableFont_wght: require("../../assets/fonts/PlusJakartaSans-VariableFont_wght.ttf"),

    // digital font
    digital1: require("../../assets/digitalFont/digital-7-italic.ttf"),
    digital2: require("../../assets/digitalFont/digital-7-mono-italic.ttf"),
    digital3: require("../../assets/digitalFont/digital-7-mono.ttf"),
    digital4: require("../../assets/digitalFont/digital-7.ttf"),
  });

  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    if (loaded) {
      setFontLoaded(true);
    }
  }, [loaded]);

  if (!fontLoaded) {
    console.log("Font loading in progress...");
    return null;
  }

  return children;
};

export default Font;
