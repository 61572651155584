import React from "react";
import { Text, StyleSheet, Dimensions, Platform, View } from "react-native";
import { colors } from "./colour";
const screenWidth = Dimensions.get("window").width;

interface TextProps {
  props: any;
  style?: any; // Make the style prop optional
}

const H1 = ({ props, style }: TextProps) => {
  return <Text style={[styles.h1, style]}>{props}</Text>;
};

const H2 = ({ props, style }: TextProps) => {
  return <Text style={[styles.h2, style]}>{props}</Text>;
};

const H3 = ({ props, style }: TextProps) => {
  return <Text style={[styles.h3, style]}>{props}</Text>;
};

const H4 = ({ props, style }: TextProps) => {
  return <Text style={[styles.h4, style]}>{props}</Text>;
};

const H5 = ({ props, style }: TextProps) => {
  return <Text style={[styles.h5, style]}>{props}</Text>;
};

const P = ({ props, style }: TextProps) => {
  return <Text style={[styles.p, style]}>{props}</Text>;
};

const Label = ({ props, style }: TextProps) => {
  return <Text style={[styles.label, style]}>{props}</Text>;
};

const CommonHeading = ({ props, style }: TextProps) => {
  return (
    <View style={{ flexDirection: "column", gap: 3 }}>
      <Text style={[styles.commonHeading, style]}>{props}</Text>
      <View style={styles.underline}></View>
    </View>
  );
};

const KnockOutTeamName = ({ props, style }: TextProps) => {
  return <Text style={[styles.knockOutTeamName, style]}>{props}</Text>;
};

const styles = StyleSheet.create({
  h1: {
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 40 : 35,
    fontFamily: "PlusJakartaSansBold",
  },
  h2: {
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 25 : 18,
    fontFamily: "PlusJakartaSansBold",
  },
  h3: {
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 20 : 14,
    fontFamily: "PlusJakartaSansSemiBold",
  },
  h4: {
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 16 : 12,
    fontFamily: "PlusJakartaSansRegular",
    color: colors.white,
  },
  h5: {
    fontSize: 14,
    fontFamily: "PlusJakartaSansSemiBold",
  },
  p: {
    fontSize: 16,
  },
  label: {
    fontSize: 14,
    fontFamily: "PlusJakartaSansRegular",
    color: colors.white,
  },
  knockOutTeamName: {
    fontSize: screenWidth > 760 ? 16 : 12,
    fontFamily:
      screenWidth > 760
        ? "PlusJakartaSansSemiBold"
        : "'PlusJakartaSansRegular'",
    color: "#140759",
  },
  commonHeading: {
    fontSize: 20,
    fontFamily: "PlusJakartaSansSemiBold",
    color: colors.white,
  },
  underline: {
    width: 70,
    height: 2,
    backgroundColor: "#FF5733",
  },
});

export { H1, H2, H3, H4, H5, P, Label, KnockOutTeamName, CommonHeading };
