import { DataStore } from "aws-amplify/datastore";
import { UserMaster } from "../models";

export const getUserMasterData = async (key) => {
  const Response = await DataStore.query(UserMaster, key);
  if (Response) {
    console.log("Response", Response);
  } else {
    console.log("No saved data found");
  }
  return Response;
};
