import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Text,
  View,
  StyleSheet,
  Dimensions,
  Pressable,
  ScrollView,
} from "react-native";
import { colors } from "../../../components/reusable/colour";
import TeamMemberPDFDocument from "./TeamMemberPDFDocument";
import { useReactToPrint } from "react-to-print";
import {
  handlePlayerSerialNumber,
  getNextSerialNumber,
} from "../../../components/reusable/CreatePlayerSerialNumber";
import { DataStore } from "@aws-amplify/datastore";
import { PlayerMaster } from "../../../models";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

type PlayerData = {
  PhotoURL?: string;
  Name: string;
  DateOfBirth?: string;
  JerseyNo: string;
  SrNo: string;
  id: string;
};

type TeamsData = {
  tournamentobj: { Name: string; StartDate: string };
  RegistrationObj: { TeamName: string };
  PlayerData: { playerdata: PlayerData }[];
};

type TeamMemberPDFProps = {
  visibleModal: boolean;
  handleCancel: () => void;
  teamData: TeamsData;
};

const TeamMemberPDF = ({
  visibleModal,
  handleCancel,
  teamData,
}: TeamMemberPDFProps) => {
  const [modalVisible, setModalVisible] = useState(visibleModal);
  const [teamsData, setTeamsData] = useState<TeamsData>(teamData);
  const [isLoading, setIsLoading] = useState(true); // New loading state
  const pdfContentRef = useRef<View>(null);

  const originLocation = window.location.origin;
  console.log("origin location", originLocation);
  const prefix = originLocation === "https://league.yuvha.com" ? "YUV" : "TMT";

  const generateSerialNumber = async () => {
    console.log("origin location", originLocation);

    try {
      const currentSerialNumber = await handlePlayerSerialNumber(
        originLocation
      ); // Fetches the current serial number from TMTLookup
      console.log("Current Serial Number from TMTLookup:", currentSerialNumber);
      return currentSerialNumber;
    } catch (error) {
      console.error("Error fetching current serial number", error);
      throw error;
    }
  };

  // const createPlayerSRNoIfEmpty = async () => {
  //   // let getSrNo = teamData?.PlayerData?.map((data) => data?.playerdata?.SrNo);
  //   // console.log("getSrNo", getSrNo);

  //   // Check if any SrNo is null or empty
  //   // if (getSrNo && getSrNo.some((srNo) => srNo === null || srNo === "")) {

  //   let currentSerialNumber;
  //   try {
  //     currentSerialNumber = await generateSerialNumber();
  //   } catch (error) {
  //     console.error("Error generating player serial number", error);
  //     return;
  //   }

  //   let updatedTeamData = { ...teamData };
  //   let teamsData = updatedTeamData.PlayerData?.map((data) => data.playerdata);
  //   console.log("teamsData", teamsData);

  //   for (let i = 0; i < teamsData.length; i++) {
  //     let playerData = teamsData[i];
  //     if (playerData.SrNo === null || playerData.SrNo === "") {
  //       console.log("SrNo not exist!!");
  //       try {
  //         // Generate the next serial number for the player
  //         const newSerialNumber = await getNextSerialNumber(
  //           prefix,
  //           currentSerialNumber
  //         );
  //         currentSerialNumber = newSerialNumber;
  //         // Updating the player master record if the SrNo is null or empty
  //         const updatedPlayerMaster = await DataStore.save(
  //           PlayerMaster.copyOf(
  //             await DataStore.query(PlayerMaster, playerData.id),
  //             (updated) => {
  //               updated.SrNo = currentSerialNumber;
  //             }
  //           )
  //         );
  //         console.log("updatedPlayerMaster", updatedPlayerMaster);
  //         // Update the teamData with the new serial number
  //         // updatedTeamData.PlayerData[i].playerdata.SrNo = currentSerialNumber;
  //       } catch (error) {
  //         console.log(
  //           "Error occurred while updating the player master record",
  //           error
  //         );
  //       }
  //       console.log(
  //         "Updated teamsData:",
  //         teamsData,
  //         "updatedTeamData",
  //         updatedTeamData
  //       );
  //       setTeamsData(updatedTeamData); //updated playerMaster data
  //     } else {
  //       console.log("SrNo exist!!");
  //       setTeamsData(teamData); // set existing data
  //     }
  //   }
  // };

  const createPlayerSRNoIfEmpty = async () => {
    try {
      let currentSerialNumber = await generateSerialNumber();
      // let updatedTeamData = { ...teamData };
      // let teamsData = updatedTeamData.PlayerData?.map(
      //   (data) => data.playerdata
      // );

      let updatedPlayerData: any = [...teamData?.PlayerData]; // Make a copy of the existing PlayerData array
      let newPlayerData: PlayerData[] = []; // Array to hold new player data

      for (let i = 0; i < updatedPlayerData.length; i++) {
        let playerData = updatedPlayerData[i].playerdata;
        if (
          (playerData && !playerData?.SrNo) ||
          playerData === undefined ||
          playerData?.SrNo === null ||
          playerData?.SrNo === ""
        ) {
          console.log("Sr not exist !!!!!!");
          const newSerialNumber = await getNextSerialNumber(
            prefix,
            currentSerialNumber
          );
          currentSerialNumber = newSerialNumber;
          const playerRecord = await DataStore.query(
            PlayerMaster,
            playerData.id
          );
          console.log("playerRecord", playerRecord);
          const updatedPlayerRecord: any = await DataStore.save(
            PlayerMaster.copyOf(playerRecord, (updated) => {
              updated.SrNo = currentSerialNumber;
            })
          );
          console.log("updatedPlayerRecord", updatedPlayerRecord);
          // teamsData[i].SrNo = currentSerialNumber;
          newPlayerData.push(updatedPlayerRecord);

          // Merge the existing and new player data arrays
          updatedPlayerData = [...updatedPlayerData, ...newPlayerData];

          // // Update the state with the updated PlayerData array
          // setTeamsData({ ...teamData, PlayerData: updatedPlayerData });
        } else {
          console.log("Sr exist !!!!!!");
        }
      }
      // Update the state with the updated PlayerData array
      setTeamsData({ ...teamData, PlayerData: updatedPlayerData });

      // setTeamsData(updatedTeamData);
    } catch (error) {
      console.log("Error occurred while processing player data", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await createPlayerSRNoIfEmpty();
      setIsLoading(false);
    };

    fetchData();
    setModalVisible(visibleModal);
  }, [visibleModal, teamData]);

  const generatePDF = useReactToPrint({
    content: () => pdfContentRef.current,
    documentTitle: "TeamMembersList",
  });

  if (isLoading) {
    // Render a loading indicator while data is being fetched
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <Text>Loading...</Text>
      </View>
    );
  }

  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#4e4e4e80",
          }}
        >
          <ScrollView style={styles.modalView}>
            <TeamMemberPDFDocument
              teamsObj={teamsData as TeamsData}
              ref={pdfContentRef}
            />

            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: 20,
              }}
            >
              <Pressable onPress={handleCancel} style={styles.closeButton}>
                <Text style={[styles.buttonText]}>Cancel</Text>
              </Pressable>
              <Pressable
                style={[
                  styles.closeButton,
                  { backgroundColor: colors.aqua, borderColor: colors.aqua },
                ]}
                onPress={generatePDF}
              >
                <Text style={[styles.buttonText, { color: colors.bgColor }]}>
                  Download PDF
                </Text>
              </Pressable>
            </View>
          </ScrollView>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  modalView: {
    width: screenWidth > 760 ? "100%" : "90%",
    maxWidth: 595,
    minHeight: 200,
    margin: 20,
    backgroundColor: colors.white,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    position: "relative",
    gap: 30,
    padding: 10,
  },
  closeButton: {
    borderWidth: 1,
    borderColor: colors.royalBlue,
    borderRadius: 10,
    height: 35,
    minWidth: 120,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 15,
    paddingVertical: 4,
  },
  buttonText: {
    color: colors.royalBlue,
    fontFamily: "PlusJakartaSansBold",
    fontSize: 16,
  },
});

export default TeamMemberPDF;
