import React, { useEffect, useState } from "react";
import {
  View,
  Platform,
  StyleSheet,
  Pressable,
  Image,
  Dimensions,
  Alert, // Added Alert for iOS fallback
} from "react-native";
import { colors } from "../reusable/colour";
import * as ImagePicker from "expo-image-picker";
import { useRoute } from "@react-navigation/native";
import { ErrorModal } from "./AreYouSure";

const screenWidth = Dimensions.get("window").width;

const ImageViewer = ({
  placeholderImageSource,
  selectedImage,
  imageViewStyle,
}) => {
  const imageSource = selectedImage
    ? { uri: selectedImage }
    : placeholderImageSource;

  return <Image source={imageSource} style={[styles.image, imageViewStyle]} />;
};

interface Props {
  style?: any;
  imageStyle?: any;
  imageUrl?: string;
  onImageSelect?: any;
  kindOfFile?: string;
  index?: Number;
  imageViewStyle?: any;
}

const UploadImage = ({
  style,
  imageUrl,
  onImageSelect,
  kindOfFile,
  index,
  imageStyle,
  imageViewStyle,
}: Props) => {
  const route = useRoute();

  const [selectedImage, setSelectedImage] = useState<string | undefined>(
    undefined
  );
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorModalMesg, setErrorModalMesg] = useState("");

  useEffect(() => {
    if (imageUrl) {
      setSelectedImage(imageUrl);
    }
  }, [imageUrl]);

  const pickImageAsync = async () => {
    
    const result = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (result.granted === false) {
      setErrorModalVisible(true);
      setErrorModalMesg("Permission to access gallery is required!");
      return;
    }

    const pickerResult = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      quality: 1,
    });

    if (!pickerResult.canceled) {
      const uri = pickerResult.assets[0].uri;
      const fileName = uri.split("/").pop();

      if (kindOfFile === "logo") {
        setSelectedImage(uri);
        onImageSelect(uri, "logo", index, fileName);
      } else if (kindOfFile === "profilePic") {
        setSelectedImage(uri);
        onImageSelect(uri, "profilePic", index, fileName);
      }
    } else {
      setErrorModalVisible(true);
      setErrorModalMesg("You did not select any image.");
    }
  };

  const pickImageForOldiOS = async () => {
    // Fallback for older iOS versions
    // Alert.alert(
    //   "Compatibility Issue",
    //   "Accessing the gallery might not work as expected on your version of iOS. Consider updating your device for full functionality.",
    //   [{ text: "OK" }]
    // );

    const pickerResult = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      quality: 1,
    });

    if (!pickerResult.canceled) {
      const uri = pickerResult.uri;
      const fileName = uri.split("/").pop();

      if (kindOfFile === "logo") {
        setSelectedImage(uri);
        onImageSelect(uri, "logo", index, fileName);
      } else if (kindOfFile === "profilePic") {
        setSelectedImage(uri);
        onImageSelect(uri, "profilePic", index, fileName);
      }
    } else {
      setErrorModalVisible(true);
      setErrorModalMesg("You did not select any image.");
    }
  };

  const handleImagePick = async () => {
    if (Platform.OS === "ios" && parseInt(Platform.Version, 10) < 17) {
      await pickImageForOldiOS();
    } else {
      await pickImageAsync();
    }
  };

  const handleSubmit = () => {
    setErrorModalVisible(false);
  };

  return (
    <View>
      <Pressable onPress={handleImagePick}>
        <View style={[styles.circleContainer, style]}>
          <View style={[styles.circle, imageStyle]}>
            {selectedImage ? (
              <ImageViewer
                selectedImage={selectedImage}
                placeholderImageSource={undefined}
                imageViewStyle={imageViewStyle}
              />
            ) : (
              <View style={[styles.circle, imageStyle]}>
                <Image
               source={require("../../../assets/icons/Camera.svg")}
                  style={styles.cameraIcon}
                />
              </View>
            )}
          </View>
        </View>
      </Pressable>

      {errorModalVisible && (
        <ErrorModal
          visibleModal={errorModalVisible}
          SuccessMessage={errorModalMesg}
          onSubmit={handleSubmit}
          header={"Attention"}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  image: {
    width: "100%",
    height: "100%",
    borderRadius: 100,
  },
  circleContainer: {
    width: 120,
    height: 120,
    borderRadius: 100,
    borderWidth: 2,
    borderColor: colors.borderColor,
    borderStyle: "dashed",
    margin: "auto",
    justifyContent: "center",
    alignItems: "center",
    padding: 5,
  },
  circle: {
    width: "100%",
    height: "100%",
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.royalBlue,
  },
  cameraIcon: {
    height: Platform.OS === "web" && screenWidth > 760 ? 20 : 30,
    width: Platform.OS === "web" && screenWidth > 760 ? 20 : 30,
  },
});

export default UploadImage;
