import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  Dimensions,
  Modal,
  ActivityIndicator,
} from "react-native";
import CustomSwitch from "../../../components/reusable/CustomSwitch";
import { colors } from "../../../components/reusable/colour";
import { DataStore } from "aws-amplify/datastore";
import { StatisticsMaster } from "../../../models";
import AsyncStorage from "@react-native-async-storage/async-storage";
// import NoDataImage from "../../../../assets/noData/stats.png";

const screenWidth = Dimensions.get("window").width;

const Statistics = ({ tournamentId }) => {
  console.log("tournamentObjOnStatistics", tournamentId);

  const defaultLogo: any = require("../../../../assets/default/defaultLogo.png");
  const defaultPlayer: any = require("../../../../assets/default/player.png");

  const [statisticsData, setStatisticsData]: any = useState([]);
  const [statisticsPlayersData, setStatisticsPlayersData]: any = useState([]);
  const [selectedScreen, setSelectedScreen] = useState("Goals");
  const [loading, setLoading] = useState(false);

  // for manageTournament page

  const [statisticsDataForAdmin, setStatisticsDataForAdmin]: any = useState([]);
  const [statisticsPlayersDataForAdmin, setStatisticsPlayersDataForAdmin]: any =
    useState([]);
  const [selectedScreenForAdmin, setSelectedScreenForAdmin] = useState("Goals");

  const NoDataImage: any = "../../../../assets/noData/stats.png";
  const userImage: any = "../../../../assets/icons/user.png";
  const testLogo: any = "../../../../assets/logo/testLogo.png";
  const yellowCard: any = require("../../../../assets/icons/yellowCard.png");
  const redCard: any = require("../../../../assets/icons/redCard.png");

  const getStatisticsData = async (tournamentId: any) => {
    try {
      setLoading(true);
      const tournamentDetailsProps = await AsyncStorage.getItem(
        "tournamentProps"
      );
      const tournamentDetails = JSON.parse(tournamentDetailsProps);
      console.log("tournamentDetails", tournamentDetails);
      // observequery

      const subscriptionStatistics = DataStore.observeQuery(
        StatisticsMaster,
        (c) => c.tournamentID.eq(tournamentId)
      ).subscribe(async (snapshot) => {
        try {
          const initialStatisticsMaster = snapshot.items;
          console.log(
            "selectedinitialSatisticsMaster",
            initialStatisticsMaster
          );
          let sortedData;
          if (selectedScreen === "Goals") {
            sortedData = initialStatisticsMaster.sort(
              (a, b) => b.Goals - a.Goals || a.Games - b.Games
            );
            console.log("Sorted by Goals", initialStatisticsMaster);
          } else {
            sortedData = initialStatisticsMaster.sort(
              (a, b) => b.RedCards - a.RedCards || b.YellowCards - a.YellowCards
            );
            console.log("Sorted by Cards");
          }
          setStatisticsData(sortedData);

          // Players data
          const playersDataPromises = sortedData.map((data) => data.playerData);

          // Wait for all promises to resolve
          const playersData = await Promise.all(playersDataPromises);
          console.log("Resolved playersData", playersData);

          setStatisticsPlayersData(playersData);
          setLoading(false);
          // Now you can use playersData as an array
        } catch (error) {
          console.error("Error fetching playersData", error);
        }
      });

      return () => {
        subscriptionStatistics.unsubscribe();
      };
    } catch (error) {
      setLoading(false);
      console.log("error occured while fetching the data", error);
    }
  };

  const getStatisticsDataForAdmin = async (tournamentId: any) => {
    try {
      setLoading(true);
      const tournamentDetailsProps = await AsyncStorage.getItem(
        "tournamentProps"
      );
      const tournamentDetails = JSON.parse(tournamentDetailsProps);
      console.log("tournamentDetails", tournamentDetails);
      // observequery

      const subscriptionStatistics = DataStore.observeQuery(
        StatisticsMaster,
        (c) => c.tournamentID.eq(tournamentId)
      ).subscribe(async (snapshot) => {
        try {
          const initialStatisticsMaster = snapshot.items;
          console.log(
            "selectedinitialSatisticsMaster",
            initialStatisticsMaster
          );
          if (selectedScreen === "Goals") {
            initialStatisticsMaster.sort(
              (a, b) => b.Goals - a.Goals || a.Games - b.Games
            );
            console.log("Sorted by Goals");
          } else {
            initialStatisticsMaster.sort(
              (a, b) => b.RedCards - a.RedCards || b.YellowCards - a.YellowCards
            );
            console.log("Sorted by Cards");
          }
          setStatisticsDataForAdmin(initialStatisticsMaster);

          // Players data
          const playersDataPromises = initialStatisticsMaster.map(
            (data) => data.playerData
          );

          // Wait for all promises to resolve
          const playersData = await Promise.all(playersDataPromises);
          console.log("Resolved playersData", playersData);

          setStatisticsPlayersDataForAdmin(playersData);
          setLoading(false);
          // Now you can use playersData as an array
        } catch (error) {
          console.error("Error fetching playersData", error);
        }
      });

      return () => {
        subscriptionStatistics.unsubscribe();
      };
    } catch (error) {
      setLoading(false);
      console.log("error occured while fetching the data", error);
    }
  };

  useEffect(() => {
    if (tournamentId) {
      getStatisticsData(tournamentId);

      getStatisticsDataForAdmin(tournamentId);
    } else {
      console.log("tournamentId not found");
    }
  }, [tournamentId]);

  const onSelectSwitch = (index: number) => {
    console.log("index", index);
    if (index === 1) {
      setSelectedScreen("Goals");
      setSelectedScreenForAdmin("Goals");
    } else if (index === 2) {
      console.log(index);
      setSelectedScreen("Cards");
      setSelectedScreenForAdmin("Cards");
    }
  };

  const GoalsScreen = () => {
    return (
      <View style={goalStyles.container}>
        <View style={goalStyles.headerRow}>
          <Text style={[goalStyles.headerPosn]}>Posn</Text>
          <Text style={goalStyles.headerCell}> Games </Text>
          <Text style={goalStyles.headerCell}>Goals</Text>
        </View>
        {statisticsData && statisticsData.length > 0 ? (
          statisticsData
            .filter((goal) => goal.Goals > 0)
            .map((goalData: any, goalIndex: number, filteredData: any) => {
              // Find the corresponding playerData based on playerId
              // if (goalData.Goals > 0) {
              const playerData = statisticsPlayersData.find(
                (player: any) => player.id === goalData.PlayerId
              );
              let currentPosition = goalIndex + 1;
              if (
                goalIndex > 0 &&
                goalData.Goals === filteredData[goalIndex - 1].Goals &&
                goalData.Games === filteredData[goalIndex - 1].Games
              ) {
                // currentPosition = filteredData[goalIndex - 1].position;
                currentPosition = filteredData[goalIndex - 1].position;
              }
              filteredData[goalIndex] = {
                ...goalData, // Spread existing properties
                position: currentPosition, // Add new property
              };
              // const updatedGoalData = {
              //   ...goalData, // Copy existing properties
              //   position: currentPosition, // Add new property
              // };
              // console.log("Updated Goal data", updatedGoalData);
              console.log("Filtered data", filteredData);
              return (
                <React.Fragment key={goalIndex}>
                  {playerData ? (
                    <View style={goalStyles.dataRow} key={goalData.playerId}>
                      <View style={goalStyles.dataPosn}>
                        <Text style={{ width: 20, color: colors.white }}>
                          {currentPosition}
                        </Text>
                        <Image
                          source={{
                            uri: playerData.PhotoURL
                              ? playerData.PhotoURL
                              : defaultPlayer,
                          }}
                          style={goalStyles.imageCell}
                          resizeMode="contain"
                        />
                        <View
                          style={{
                            flexDirection: "column",
                            gap: 7,
                            flex: 1,
                            paddingLeft:
                              Platform.OS === "web" && screenWidth > 760
                                ? 20
                                : 10,
                          }}
                        >
                          <Text
                            style={{
                              textAlign: "left",
                              fontSize: 16,
                              color: colors.white,
                              fontFamily: "PlusJakartaSansMedium",
                            }}
                          >
                            {playerData.Name}
                          </Text>
                          <View
                            style={{
                              flexDirection: "row",
                              gap: 10,
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <Image
                              source={{
                                uri: goalData.TeamLogo
                                  ? goalData.TeamLogo
                                  : defaultLogo,
                              }}
                              style={{
                                height: 30,
                                width: 30,
                                borderRadius: 30,
                              }}
                              resizeMode="contain"
                            />
                            <Text
                              style={{
                                textAlign: "left",
                                fontSize: 12,
                                fontFamily: "PlusJakartaSansRegular",
                                color: colors.white,
                              }}
                            >
                              {screenWidth > 760
                                ? goalData.TeamName
                                : goalData.TeamName}
                            </Text>
                          </View>
                        </View>
                      </View>

                      <Text style={goalStyles.dataCell}>{goalData.Games}</Text>

                      <Text style={goalStyles.dataCell}>{goalData.Goals}</Text>
                    </View>
                  ) : (
                    <Text key={goalIndex}>
                      No Corresponding Player Data Found
                    </Text>
                  )}
                </React.Fragment>
              );
              // } else {
              //   return null; // Render nothing if neither condition is met
              // }
            })
        ) : (
          <Text>No Data Found</Text>
        )}
      </View>
    );
  };

  const CardsScreen = () => {
    return (
      <View style={cardStyles.container}>
        <View style={cardStyles.headerRow}>
          <Text
            style={[
              cardStyles.headerPosn,
              { width: screenWidth > 760 ? "55%" : undefined, flex: 1 },
            ]}
          >
            Posn
          </Text>
          <Text style={[cardStyles.headerCell]}>Games</Text>
          <Image
            source={yellowCard}
            style={{ width: screenWidth > 760 ? "15%" : 20 }}
            resizeMode="contain"
          />
          <Image
            source={redCard}
            style={{ width: screenWidth > 760 ? "15%" : 20 }}
            resizeMode="contain"
          />
        </View>

        {statisticsData && statisticsData.length > 0 ? (
          statisticsData
            .filter((card) => card.YellowCards > 0 || card.RedCards)
            .map((statistic: any, index: number) => {
              // Find the corresponding playerData based on playerId

              const playerData = statisticsPlayersData.find(
                (player: any) => player.id === statistic.PlayerId
              );

              return (
                <View style={cardStyles.dataRow} key={index}>
                  <View
                    style={[
                      cardStyles.dataPosn,
                      { flex: screenWidth > 760 ? undefined : 1 },
                    ]}
                  >
                    <Text style={{ width: 20, color: colors.white }}>
                      {index + 1}
                    </Text>

                    <Image
                      source={{
                        uri: playerData.PhotoURL
                          ? playerData.PhotoURL
                          : defaultPlayer,
                      }}
                      style={cardStyles.imageCell}
                      resizeMode="contain"
                    />
                    <View
                      style={{
                        flexDirection: "column",
                        gap: 7,
                        flex: 1,
                        paddingLeft: screenWidth > 760 ? 20 : 0,
                      }}
                    >
                      <Text
                        style={{
                          textAlign: "left",
                          fontSize: 16,
                          color: colors.white,
                          fontFamily: "PlusJakartaSansMedium",
                        }}
                      >
                        {playerData.Name}
                      </Text>
                      <View
                        style={{
                          flexDirection: "row",
                          gap: 10,
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Image
                          source={{
                            uri: statistic.TeamLogo
                              ? statistic.TeamLogo
                              : defaultLogo,
                          }}
                          style={{ height: 30, width: 30, borderRadius: 20 }}
                          resizeMode="contain"
                        />
                        <Text
                          style={{
                            textAlign: "left",
                            fontSize: 12,
                            fontFamily: "PlusJakartaSansMedium",
                            color: colors.white,
                          }}
                        >
                          {statistic.TeamName}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={[
                      cardStyles.dataCell,
                      { width: 30, flex: undefined },
                    ]}
                  >
                    <Text style={[cardStyles.dataCell]}>{statistic.Games}</Text>
                  </View>
                  <View
                    style={[
                      cardStyles.dataCell,
                      { width: 30, flex: undefined },
                    ]}
                  >
                    <Text style={[cardStyles.dataCell]}>
                      {statistic.YellowCards}
                    </Text>
                  </View>
                  <View
                    style={[
                      cardStyles.dataCell,
                      { width: 30, flex: undefined },
                    ]}
                  >
                    <Text style={[cardStyles.dataCell]}>
                      {statistic.RedCards}
                    </Text>
                  </View>
                </View>
              );
            })
        ) : (
          <Text>No Data Found</Text>
        )}
      </View>
    );
  };

  // for manageTournamentPage and admin page

  const GoalsScreenForAdmin = () => {
    return (
      <View style={goalStyles.container}>
        <View style={goalStyles.headerRow}>
          <Text style={goalStyles.headerPosn}>Posn</Text>
          <Text style={goalStyles.headerCell}>Games</Text>
          <Text style={goalStyles.headerCell}> Goals</Text>
        </View>
        {statisticsDataForAdmin && statisticsDataForAdmin.length > 0 ? (
          statisticsDataForAdmin
            .filter((goal) => goal.Goals > 0)
            .map((goalData: any, goalIndex: number) => {
              // Find the corresponding playerData based on playerId
              const playerData = statisticsPlayersDataForAdmin.find(
                (player: any) => player.id === goalData.PlayerId
              );

              return (
                <React.Fragment key={goalIndex}>
                  {playerData ? (
                    <View style={goalStyles.dataRow} key={goalData.playerId}>
                      <View style={goalStyles.dataPosn}>
                        <Text style={{ width: 20, color: colors.white }}>
                          {goalIndex + 1}
                        </Text>

                        <Image
                          source={{
                            uri: playerData.PhotoURL
                              ? playerData.PhotoURL
                              : defaultPlayer,
                          }}
                          style={goalStyles.imageCell}
                          resizeMode="contain"
                        />
                        <View
                          style={{
                            flexDirection: "column",
                            gap: 5,
                            flex: 1,
                            paddingLeft: 20,
                          }}
                        >
                          <Text
                            style={{
                              textAlign: "left",
                              fontSize: 16,
                              color: colors.white,
                              fontFamily: "PlusJakartaSansMedium",
                            }}
                          >
                            {playerData.Name}
                          </Text>
                          <View
                            style={{
                              flexDirection: "row",
                              gap: 10,
                              justifyContent: "flex-start",
                              alignItems: "flex-start",
                            }}
                          >
                            <Image
                              source={{
                                uri: goalData.TeamLogo
                                  ? goalData.TeamLogo
                                  : defaultLogo,
                              }}
                              style={{
                                height: 20,
                                width: 20,
                                borderRadius: 20,
                              }}
                              resizeMode="contain"
                            />
                            <Text
                              style={{
                                textAlign: "left",
                                fontSize: 12,
                                fontFamily: "PlusJakartaSansMedium",
                                color: colors.white,
                              }}
                            >
                              {goalData.TeamName}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <Text style={goalStyles.dataCell}>{goalData.Games}</Text>
                      <Text style={goalStyles.dataCell}>{goalData.Goals}</Text>
                    </View>
                  ) : (
                    <Text key={goalIndex}>
                      No Corresponding Player Data Found
                    </Text>
                  )}
                </React.Fragment>
              );
            })
        ) : (
          <Text>No Data Found</Text>
        )}
      </View>
    );
  };

  const CardsScreenForAdmin = () => {
    return (
      <View style={cardStyles.container}>
        <View style={cardStyles.headerRow}>
          <Text style={cardStyles.headerPosn}>Posn</Text>
          <Text style={cardStyles.headerCell}>Games</Text>
          <Image
            source={yellowCard}
            style={{ width: "15%" }}
            resizeMode="contain"
          />
          <Image
            source={redCard}
            style={{ width: "15%" }}
            resizeMode="contain"
          />
        </View>

        {statisticsDataForAdmin && statisticsDataForAdmin.length > 0 ? (
          statisticsDataForAdmin
            .filter((card) => card.YellowCards > 0 || card.RedCards)
            .map((statistic: any, index: number) => {
              // Find the corresponding playerData based on playerId
              const playerData = statisticsPlayersDataForAdmin.find(
                (player: any) => player.id === statistic.PlayerId
              );

              return (
                <View style={cardStyles.dataRow} key={index}>
                  <View style={cardStyles.dataPosn}>
                    <Text style={{ width: 20, color: colors.white }}>
                      {index + 1}
                    </Text>

                    <Image
                      source={{
                        uri: playerData.PhotoURL
                          ? playerData.PhotoURL
                          : defaultPlayer,
                      }}
                      style={cardStyles.imageCell}
                      resizeMode="contain"
                    />
                    <View
                      style={{
                        flexDirection: "column",
                        gap: 5,
                        flex: 1,
                        paddingLeft: 20,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          gap: 10,
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        }}
                      >
                        <Image
                          source={{
                            uri: statistic.TeamLogo
                              ? statistic.TeamLogo
                              : defaultLogo,
                          }}
                          style={{ height: 20, width: 20, borderRadius: 20 }}
                          resizeMode="contain"
                        />
                        <Text
                          style={{
                            textAlign: "left",
                            fontSize: 12,
                            fontFamily: "PlusJakartaSansMedium",
                            color: colors.white,
                          }}
                        >
                          {statistic.TeamName}
                        </Text>
                      </View>
                      <Text
                        style={{
                          textAlign: "left",
                          fontSize: 16,
                          color: colors.white,
                          fontFamily: "PlusJakartaSansMedium",
                        }}
                      >
                        {playerData.Name}
                      </Text>
                    </View>
                  </View>
                  <Text style={cardStyles.dataCell}>{statistic.Games}</Text>

                  <Text style={cardStyles.dataCell}>
                    {statistic.YellowCards}
                  </Text>

                  <Text style={cardStyles.dataCell}>{statistic.RedCards}</Text>
                </View>
              );
            })
        ) : (
          <Text>No Data Found</Text>
        )}
      </View>
    );
  };

  return (
    <View style={styles.container}>
      {statisticsData.length > 0 && statisticsDataForAdmin.length > 0 ? (
        <View style={{ alignItems: "flex-end" }}>
          <CustomSwitch
            selectionMode={1}
            roundCorner={false}
            option1={"Goals"}
            option2={"Cards"}
            onSelectSwitch={onSelectSwitch}
            selectionColor={colors.royalBlue}
          />
        </View>
      ) : (
        <View style={{ alignItems: "center" }}>
          <Image
            source={require("../../../../assets/noData/stats.png")}
            style={styles.noDataImage}
            resizeMode="contain"
          />
          <Text style={styles.noDataText}>
            Hang on! Statistics will be available after someone scores a Goal.
          </Text>
        </View>
      )}

      {/* for web view */}
      {Platform.OS === "web" &&
      window.location.pathname.includes("/TournamentDetails") &&
      statisticsData.length > 0 ? (
        selectedScreen === "Goals" ? (
          <GoalsScreen />
        ) : (
          <CardsScreen />
        )
      ) : null}

      {Platform.OS === "web" &&
      window.location.pathname.includes("/ManageTournament") &&
      statisticsDataForAdmin.length > 0 ? (
        selectedScreenForAdmin === "Goals" ? (
          <GoalsScreenForAdmin />
        ) : (
          <CardsScreenForAdmin />
        )
      ) : null}

      {/* for mob view */}

      {Platform.OS === "android" ||
      (Platform.OS === "ios" && statisticsDataForAdmin.length > 0) ? (
        selectedScreenForAdmin === "Goals" ? (
          <GoalsScreenForAdmin />
        ) : (
          <CardsScreenForAdmin />
        )
      ) : null}

      {/* loader */}

      {loading ? (
        <Modal transparent animationType="none">
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <ActivityIndicator size="large" color="#0000ff" />
            <Text style={{ color: "white" }}>Loading</Text>
          </View>
        </Modal>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    gap: 20,
  },
  noDataImage: {
    width: 270,
    height: 270,
  },
  noDataText: {
    fontFamily: "PlusJakartaSansSemiBold",
    color: colors.white,
    fontSize: 20,
    maxWidth: 400,
    textAlign: "center",
  },
});
const goalStyles = StyleSheet.create({
  container: {
    flexDirection: "column",
    gap: 10,
  },
  headerRow: {
    flexDirection: "row",
    backgroundColor: colors.royalBlue,
    padding: 10,
    // borderBottomWidth: 1,
    // borderBottomColor: "#E9F0FF",
    width: "100%",
  },
  headerPosn: {
    width: Platform.OS === "web" && screenWidth > 760 ? "70%" : "70%",
    fontWeight: "bold",
    textAlign: "left",
    color: colors.white,
  },

  headerCell: {
    width: Platform.OS === "web" && screenWidth > 760 ? "15%" : "15%",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? undefined : 12,
    fontWeight: "bold",
    textAlign: "center",
    color: colors.white,
  },
  dataPosn: {
    width: "70%",
    fontWeight: "bold",
    flexDirection: "row",
    gap: 10,
    alignItems: "center",
  },
  dataRow: {
    alignItems: "center",
    flexDirection: "row",
    padding: 10,
    width: "100%",
    borderBottomWidth: 1,
    borderBottomColor: colors.royalBlue,
    borderRadius: 10,
    borderColor: colors.royalBlue,
    backgroundColor: colors.royalBlue,
    flex: 1,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 3,
  },
  dataCell: {
    flex: 1,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 16,
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
  },
  imageCell: {
    width: Platform.OS === "web" && screenWidth > 760 ? 60 : 45,
    height: Platform.OS === "web" && screenWidth > 760 ? 60 : 45,
    resizeMode: "contain",
    paddingHorizontal: 5,
    borderRadius: 40,
  },
});
const cardStyles = StyleSheet.create({
  container: {
    flexDirection: "column",
    gap: 10,
  },
  headerRow: {
    flexDirection: "row",
    backgroundColor: colors.royalBlue,
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: colors.royalBlue,
    width: "100%",
  },
  headerPosn: {
    width: "55%",
    fontWeight: "bold",
    textAlign: "left",
    color: colors.white,
  },
  headerCell: {
    width: "15%",
    fontWeight: "bold",
    textAlign: "center",
    color: colors.white,
  },
  dataPosn: {
    width: "55%",
    fontWeight: "bold",
    flexDirection: "row",
    gap: Platform.OS === "web" && screenWidth > 760 ? 10 : 5,
    alignItems: "center",
  },
  dataRow: {
    alignItems: "center",
    flexDirection: "row",
    padding: 10,
    width: "100%",
    borderBottomWidth: 1,
    borderBottomColor: colors.royalBlue,
    borderRadius: 10,
    borderColor: colors.royalBlue,
    backgroundColor: colors.royalBlue,
    flex: 1,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 3,
  },
  dataCell: {
    flex: screenWidth > 760 ? 1 : undefined,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 16,
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
  },
  imageCell: {
    width: Platform.OS === "web" && screenWidth > 760 ? 60 : 30,
    height: Platform.OS === "web" && screenWidth > 760 ? 60 : 30,
    resizeMode: "contain",
    paddingHorizontal: 10,
    borderRadius: 40,
  },
});

export default Statistics;
