import moment from "moment";
export const formatedDate = (userDate) => {
  if (userDate) return moment(userDate).format().substring(0, 19);
};

export const ConvertDatetoLocalForm = (userDate) => {
  if (userDate) return moment(userDate).format("Do MMM, YYYY").substring(0, 24);
};

export const ConvertDateAndTimetoLocalForm = (userDateTime) => {
  if (userDateTime) {
    return moment(userDateTime).format("Do MMM, YYYY h:mm:ss A"); // Example format: 2nd Aug, 2023 3:07:35 PM
  }
};

export function findAgeFromeDOB(DOB) {
  const dob = new Date(DOB);
  const year = new Date(Date.now() - dob.getTime()).getUTCFullYear();
  return Math.abs(year - 1970);
}

export function SortArrayOfObjs(array, fieldName, order) {
  function compare(a, b) {
    if (a[fieldName] < b[fieldName]) {
      return -1;
    }
    if (a[fieldName] > b[fieldName]) {
      return 1;
    }
    return 0;
  }
  function Descompare(a, b) {
    if (a[fieldName] > b[fieldName]) {
      return -1;
    }
    if (a[fieldName] < b[fieldName]) {
      return 1;
    }
    return 0;
  }
  return array.sort(order && order === "desc" ? Descompare : compare);
}

export function mapOrder(array, order, key) {
  array.sort(function (a, b) {
    console.log("a map", a, b);
    var A =
        a.PlayerStatus && a.PlayerStatus.length > 0 && a.PlayerStatus[0][key],
      B = b.PlayerStatus && b.PlayerStatus.length > 0 && b.PlayerStatus[0][key];
    console.log("MAP ORDER", A, B);
    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
  });

  return array;
}

// FormatDate function to convert YYYY/MM/DD date format to "Thursday, November 9, 2023" format
export const formatDate = (inputDate: string) => {
  // Attempt to parse the input date string
  const parsedDate = new Date(inputDate);

  if (!inputDate) {
    return "Invalid Date";
  }

  // Check if the parsed date is valid
  if (isNaN(parsedDate.getTime())) {
    return "Invalid date"; // Return an error message if the date is invalid
  }

  // Define options for formatting
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  // Format the date using the options
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    parsedDate
  );

  return formattedDate;
};

export const formatDateShort = (inputDate: string) => {
  // Attempt to parse the input date string
  const parsedDate = new Date(inputDate);
  if (!inputDate) {
    return "Invalid Date";
  }
  // Check if the parsed date is valid
  if (isNaN(parsedDate.getTime())) {
    return "Invalid date"; // Return an error message if the date is invalid
  }
  // Define options for formatting
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "2-digit",
  };
  // Format the date using the options
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    parsedDate
  );
  const newFormat =
    formattedDate.substring(4, 6) +
    " " +
    formattedDate.substring(0, 3) +
    ", " +
    formattedDate.substring(8);
  return newFormat;
};

// convert the 24hrs time to 12 hrs time with AM/PM
export const convertTo12HourFormat = (time24: any) => {
  // Check if the input is a valid time
  if (!/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(time24)) {
    return "Invalid Time";
  }

  // Extract hours and minutes
  const [hours, minutes] = time24.split(":").map(Number);

  // Determine AM or PM
  const period = hours >= 12 ? "PM" : "AM";

  // Convert to 12-hour format
  const hours12 = hours % 12 || 12;

  // Return the formatted time
  return `${hours12}:${minutes < 10 ? "0" : ""}${minutes} ${period}`;
};

// Example usage:
const time24Hour = "14:30";
const time12Hour = convertTo12HourFormat(time24Hour);
console.log(time12Hour); // Output: "2:30 PM"

// Sorting function to compare objects based on the "Time" property

export const sortDataByTime = (dataArray: any) => {
  return dataArray.sort((a: any, b: any) => {
    const timeA = parseInt(a.Time, 10);
    const timeB = parseInt(b.Time, 10);
    return timeB - timeA; // Sort in descending order
  });
};

// FUNCTION FOR GET THE CURRENT DATE

export const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const formatDateNew = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}/${month}/${day}`;
};
