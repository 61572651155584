import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Pressable,
  StyleSheet,
  Platform,
  Dimensions,
} from "react-native";
import * as DocumentPicker from "expo-document-picker";
import { colors } from "../../components/reusable/colour";
import UploadIcon from "react-native-vector-icons/SimpleLineIcons";
import TrashIcon from "react-native-vector-icons/Feather";

const screenWidth = Dimensions.get("window").width;

const UploadFile = ({ fileName, handlegetFiledata }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [PDFData, setPDFData] = useState(null);
  const pickDocument = async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: "application/pdf",
    });
    setSelectedFile(result.output[0].name);
    handlegetFiledata(result.output[0].name, result.assets[0].uri);
    setPDFData(result.assets[0].uri);
    console.log(result);
    console.log(result.output[0].name);
  };
  useEffect(() => {
    console.log("fileName", fileName);
    setSelectedFile(fileName);
  }, [fileName]);
  return (
    <View style={{ gap: 20 }}>
      <Pressable onPress={pickDocument} style={styles.pressableContainer}>
        <View style={styles.PDFboxContainer}>
          <View style={styles.PDFbox}>
            <View style={styles.uploadBox}>
              <>
                <UploadIcon name="cloud-upload" size={40} color={colors.aqua} />
                <Text
                  style={{
                    fontFamily: "PlusJakartaSansMedium",
                    color: colors.white,
                  }}
                >
                  (Supported Formates: PDF (upto 5MB))
                </Text>
                <Text
                  style={{
                    fontFamily: "PlusJakartaSansSemiBold",
                    color: colors.white,
                    fontSize: 20,
                  }}
                >
                  Upload File
                </Text>
              </>
              {/* )} */}
            </View>
          </View>
        </View>
      </Pressable>
      {/* {selectedFile && (
        <View>
          <Text
            style={{
              fontFamily: "PlusJakartaSansSemiBold",
              fontSize: 17,
              marginBottom: 10,
              color: colors.white,
            }}
          >
            Uploaded
          </Text>
          <View style={styles.uploadedPDFContainer}>
            {selectedFile !== "" ? (
              <Text
                style={{
                  fontFamily: "PlusJakartaSansMedium",
                  color: colors.white,
                }}
              >
                {selectedFile}
              </Text>
            ) : null}
            {selectedFile !== "" ? (
              <Pressable onPress={handleRemoveRegulationFile}>
                <TrashIcon name="trash-2" size={25} color="#f13737" />
              </Pressable>
            ) : null}
          </View>
        </View>
      )} */}
    </View>
  );
};

const styles = StyleSheet.create({
  pressableContainer: {
    width: "100%",
    maxWidth: 420,
  },
  PDFboxContainer: {
    width: "100%",
    height: Platform.OS === "web" && screenWidth > 760 ? 150 : 120,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: colors.white,
    padding: 5,
    borderStyle: "dashed",
    backgroundColor: colors.royalBlue,
  },

  PDFbox: {
    width: "100%",
    height: "100%",
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.royalBlue,
  },
  uploadBox: {
    alignItems: "center",
    gap: 7,
  },
  uploadedPDFContainer: {
    borderColor: colors.aqua,
    borderWidth: 1,
    maxWidth: 420,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 7,
    paddingHorizontal: 10,
    borderRadius: 5,
  },
});

export default UploadFile;
