import { useNavigation } from "@react-navigation/native";
import React, { useState, useEffect } from "react";
import {
  View,
  Platform,
  ScrollView,
  Text,
  StyleSheet,
  Pressable,
  Image,
  Dimensions,
  Modal,
  ActivityIndicator,
} from "react-native";

import AdminLayout from "../../../components/include/AdminLayout";
import TeamModal from "./SubComp/TeamModal";
const screenWidth = Dimensions.get("window").width;
import {
  CommonHeading,
  H4,
  Label,
} from "../../../components/reusable/TextComponent";
import AddIcon from "react-native-vector-icons/Feather";
import ThreeDotIcon from "react-native-vector-icons/Entypo";
import EyeIcon from "react-native-vector-icons/AntDesign";
import EditIcon from "react-native-vector-icons/Feather";
import TrashIcon from "react-native-vector-icons/AntDesign";

import { DataStore } from "aws-amplify/datastore";
import { Squad, UserMaster } from "../../../models";
import AddTeamModal from "./SubComp/AddTeamModal";
// import { getAuthenticatedUser } from "../../../api/AmplifyUserAPI";
import { colors } from "../../../components/reusable/colour";
import { getUserMasterData } from "../../../api/UserMasterAPI";
import AppBar from "../../../components/include/AppBar";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AreYouSureOrange } from "../../../components/reusable/AreYouSure";

const MyTeams = ({ navigation }) => {
  const [squadId, setSquadId] = useState("");

  // const navigation: any = useNavigation();
  const [modalVisible, setModalVisible] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);

  const [squadData, setSquadData]: any = useState([]);
  const [playersCount, setPlayersCount] = useState([]);
  const [selectedTeamIndex, setSelectedTeamIndex]: any = useState("");
  const [FlagForTeam, setFlagForTeam] = useState(false);
  const [loading, setLoading] = useState(false);

  const [uuid, setUUID] = useState("");
  const [deleteModalMessage, setDeleteModalMessage] = useState("");
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedSquadID, setSelectedSquadID] = useState("");
  // const [deleteIndex, setDeleteIndex] = useState(null);

  const homeLogo: any = require("../../../../assets/home/logo.png");

  const handleOpen = (squadId) => {
    setModalVisible(true);
    setSquadId(squadId);
  };
  const handleOpenAddTeam = () => {
    setAddModalVisible(true);
  };
  const handleClose = () => {
    setModalVisible(false);
    setSquadId("");
  };
  const handleCloseAddTeam = () => {
    setAddModalVisible(false);
  };

  const handleCloseTeamModal = () => {
    setModalVisible(false);
  };
  const handleThreeDot = (index) => {
    setSelectedTeamIndex(index);
    setFlagForTeam(!FlagForTeam);
  };

  let userUUID: any, userDetails: any;

  const confirmDelete = (squadID: any) => {
    // Open the modal
    setDeleteModalVisible(true);
    setDeleteModalMessage("Are you sure you want to delete this squad?");
    // Set the squad ID in state so it's available when the user confirms
    setSelectedSquadID(squadID);
  };

  const cancelDelete = () => {
    // Close the modal
    setDeleteModalVisible(false);
  };

  const handleDeleteSquad = async (squadID: any) => {
    try {
      const deleteRecord = await DataStore.delete(Squad, (squad) =>
        squad.id.eq(squadID)
      );
      console.log("deleteRecord", deleteRecord);
    } catch (error) {
      console.log("Error in deleting SquadPlayer", error);
    }
  };

  const handleConfirmDelete = async () => {
    if (selectedSquadID) {
      // Call the function to delete the squad
      await handleDeleteSquad(selectedSquadID);
      // Close the modal after deletion
      setDeleteModalVisible(false);
    } else {
      console.log("Squad ID not found");
    }
  };

  const getMySquadData = async () => {
    try {
      setLoading(true);
      const subscription = await DataStore.observeQuery(Squad).subscribe(
        async (snapshot) => {
          const initialSquads = snapshot.items;
          console.log("initialSquads", initialSquads);

          const filterSquads = initialSquads.filter(
            (data) =>
              data.Admin1?.UUID === userDetails ||
              data.Admin2?.UUID === userDetails
          );

          console.log("filterSquads", filterSquads);
          setSquadData(filterSquads);

          // Extract players data from squads
          const squadTeamPlayersDataPromises = filterSquads.map(async (data) =>
            data.TeamPlayers.toArray()
          );

          try {
            const playersData = await Promise.all(squadTeamPlayersDataPromises);
            console.log("PlayersData", playersData);

            const playersCount = playersData.map((data) => data.length);
            console.log("Players Count : ", playersCount);
            setPlayersCount(playersCount);
            setLoading(false);
          } catch (error) {
            setLoading(false);
            console.error("Error fetching players data:", error);
          }
        }
      );
      setLoading(false);

      return () => {
        subscription.unsubscribe();
      };
    } catch (error) {
      console.error("Error while fetching the data:", error);
      setLoading(false);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const getUserdata = async () => {
    userDetails = await AsyncStorage.getItem("UserDetail");
    if (userDetails) {
      console.log("userDetails", userDetails);
      setUUID(userDetails);
    } else {
      console.log("No user found in temp storage");
    }
  };

  useEffect(() => {
    console.log("loading", loading);
    // const fetchData = async () => {
    getUserdata();
    getMySquadData();
    // };
    // fetchData();
  }, []);

  const handleBack = () => {
    navigation.goBack();
  };

  return (
    <>
      {Platform.OS !== "web" && (
        <AppBar
          title={"My Team"}
          showBackButton={true}
          onBackPress={handleBack}
        />
      )}
      <AdminLayout>
        <ScrollView>
          <View style={{ padding: 5 }}>
            {Platform.OS === "web" && <CommonHeading props={"My Teams"} />}

            <View style={{ marginTop: 15, alignItems: "flex-start" }}>
              <Pressable
                onPress={() =>
                  Platform.OS === "web" && screenWidth > 760
                    ? handleOpenAddTeam()
                    : navigation.navigate("AddTeamMob")
                }
                style={styles.addTeamBtn}
              >
                <AddIcon name="plus-circle" size={18} color={colors.white} />
                <Text
                  style={{
                    color: colors.white,
                    fontSize: 14,
                    fontFamily: "PlusJakartaSansSemiBold",
                  }}
                >
                  Add Team
                </Text>
              </Pressable>
            </View>
            <View
              style={{
                flexDirection:
                  Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
                gap: 25,
                flexWrap: "wrap",
                marginTop: 15,
              }}
            >
              {squadData && squadData.length > 0 ? (
                squadData.map((data: any, index: any) => (
                  <View style={styles.myTeamItemContainer} key={index}>
                    <Pressable
                      style={styles.myTeamItem}
                      // onPress={() => handleOpen(data.id)}
                      onPress={() =>
                        Platform.OS === "web" && screenWidth > 760
                          ? handleOpen(data.id)
                          : navigation.navigate("EditTeamMob")
                      }
                    >
                      <Image
                        source={data.Logo ? data.Logo : homeLogo}
                        style={styles.scoreTeamLogo}
                        resizeMode="contain"
                      />
                      <View style={styles.myTeamItemContent}>
                        <H4
                          props={data.Name ? data.Name : null}
                          style={{ color: colors.white }}
                        />
                        <View style={styles.myTeamItemContentBottom}>
                          <Label
                            props={"Players : " + playersCount[index]}
                            style={{ color: colors.white }}
                          />
                          <Label
                            props={
                              "Created : " +
                              data.createdAt?.toString().split("T")[0]
                            }
                            style={{ color: colors.white }}
                          />
                        </View>
                      </View>
                    </Pressable>
                    <Pressable
                      style={styles.threeDotButtonContainer}
                      onPress={() => handleThreeDot(index)}
                    >
                      <ThreeDotIcon
                        name="dots-three-vertical"
                        size={23}
                        color={colors.white}
                      />
                    </Pressable>
                    <View
                      style={[
                        styles.buttonContainerWrapper,
                        {
                          display:
                            selectedTeamIndex === index && FlagForTeam == true
                              ? "flex"
                              : "none",
                        },
                      ]}
                    >
                      <Pressable onPress={() => handleOpen(data.id)}>
                        <View style={styles.buttonWrapper}>
                          <EditIcon name="edit" size={20} color="#616060" />
                          <Text style={styles.buttonText}>Edit</Text>
                        </View>
                      </Pressable>

                      <Pressable onPress={() => confirmDelete(data.id)}>
                        <View style={styles.buttonWrapper}>
                          <TrashIcon name="delete" size={20} color="red" />
                          <Text style={styles.buttonText}>Delete</Text>
                        </View>
                      </Pressable>
                    </View>
                  </View>
                ))
              ) : (
                <View style={{ alignItems: "center", width: "100%" }}>
                  <Image
                    source={require("../../../../assets/noData/stats.png")}
                    style={styles.noDataImage}
                    resizeMode="contain"
                  />
                  <Text style={styles.noDataText}>
                    You have not created any team.
                  </Text>
                </View>
              )}
            </View>
          </View>
        </ScrollView>

        {loading && (
          <Modal transparent animationType="none">
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <ActivityIndicator size="large" color="#0000ff" />
              <Text style={{ color: colors.white }}>Loading</Text>
            </View>
          </Modal>
        )}

        {modalVisible == true ? (
          <TeamModal
            visibleModal={modalVisible}
            handleClose={handleCloseTeamModal}
            squadId={squadId ? squadId : ""}
            userUUID={userUUID ? userUUID : ""}
            currentUserName={""}
          />
        ) : null}
        {addModalVisible == true ? (
          <AddTeamModal
            visibleModal={addModalVisible}
            handleClose={handleCloseAddTeam}
          />
        ) : null}

        {deleteModalVisible == true ? (
          <AreYouSureOrange
            visibleModal={deleteModalVisible}
            Message={deleteModalMessage}
            ButtonText={"Delete Squad"}
            onCancel={cancelDelete}
            onConfirm={handleConfirmDelete}
          />
        ) : null}
      </AdminLayout>
    </>
  );
};

const styles = StyleSheet.create({
  addTeamBtn: {
    flexDirection: "row",
    gap: 5,
    backgroundColor: colors.orangeColour,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    minWidth: 100,
    padding: 5,
    paddingHorizontal: 12,
  },
  scoreTeamLogo: {
    width: 45,
    height: 45,
    resizeMode: "contain",
    borderRadius: 40,
  },
  myTeamItemContainer: {
    minWidth: Platform.OS === "web" && screenWidth > 760 ? "30%" : "100%",
    maxWidth: Platform.OS === "web" && screenWidth > 760 ? "33%" : "100%",
    flex: 1,
    flexDirection: "row",
    backgroundColor: colors.royalBlue,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    paddingVertical: 8,
    borderRadius: 5,
  },
  myTeamItem: {
    flexDirection: "row",
    gap: 7,
    alignItems: "center",
    flex: 1,
    borderTopLeftRadius: 7,
    borderBottomLeftRadius: 7,
    padding: 5,
    paddingHorizontal: 5,
    paddingVertical: 5,
    paddingRight: 25,
  },
  threeDotButtonContainer: {
    width: 30,
    backgroundColor: colors.royalBlue,
    justifyContent: "center",
    alignItems: "center",
    borderTopRightRadius: 7,
    borderBottomRightRadius: 7,
  },
  myTeamItemContent: {
    flexDirection: "column",
    flex: 1,
    gap: 5,
  },
  myTeamItemContentBottom: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  buttonContainerWrapper: {
    backgroundColor: "#f3f1f1",
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    padding: 5,
    gap: 5,
    position: "absolute",
    right: 33,
    top: 0,
  },
  buttonText: {
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 16,
    color: "#222",
  },
  buttonWrapper: {
    flexDirection: "row",
    gap: 5,
  },
  noDataImage: {
    width: 270,
    height: 270,
  },
  noDataText: {
    fontFamily: "PlusJakartaSansSemiBold",
    color: colors.white,
    fontSize: 20,
    maxWidth: 400,
    textAlign: "center",
  },
});

export default MyTeams;
