/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "ap-south-1",
  aws_appsync_graphqlEndpoint:
    "https://todl5fwcazajhkmpkz6ccyhzue.appsync-api.ap-south-1.amazonaws.com/graphql",
  aws_appsync_region: "ap-south-1",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-xbjotuf22rc3ban6zhvgakt5zi",
  aws_cognito_identity_pool_id:
    "ap-south-1:bad079c7-6876-4c7e-8d55-b4b0b62afcb7",
  aws_cognito_region: "ap-south-1",
  aws_user_pools_id: "ap-south-1_AKkMxCdtg",
  aws_user_pools_web_client_id: "46ekm2210lec62ebtejnom59cq",
  oauth: {},
  aws_cognito_username_attributes: ["PHONE_NUMBER"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: [],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: ["SMS"],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS",
      "REQUIRES_UPPERCASE",
    ],
  },
  aws_cognito_verification_mechanisms: ["PHONE_NUMBER"],
  aws_user_files_s3_bucket: "trackmytournament-dev29f24-prod",
  aws_user_files_s3_bucket_region: "ap-south-1",
  aws_cognito_auth_role: "amplify-trackmytournament-prod-29f24-authRole",
};

export default awsmobile;
