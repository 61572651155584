import React from "react";
import { Image } from "react-native";
import Onboarding from "react-native-onboarding-swiper";

const OnboardingScreen = ({ onDone, onSkip }) => {
  const onboardingScreens = [
    {
      backgroundColor: "rgba(18, 7, 75, 0.4)",
      image: (
        <Image
          source={require("../../../../assets/Admin/dashboard/new-user-dashboard.png")}
          style={{ width: 200, height: 200, resizeMode: "contain" }}
        />
      ),
      title: "Easy Streaming",
      subtitle: "Choose your plan to watch live match your favourite club.",
    },
    {
      backgroundColor: "rgba(23, 11, 82, 0.4)",
      image: (
        <Image
          source={require("../../../../assets/Admin/dashboard/new-user-dashboard2.png")}
          style={{ width: 200, height: 200, resizeMode: "contain" }}
        />
      ),
      title: "Easy Streaming",
      subtitle: "Choose your plan to watch live match your favourite club.",
    },
    {
      backgroundColor: "rgba(23, 11, 82, 0.4)",
      image: (
        <Image
          source={require("../../../../assets/Admin/dashboard/new-user-dashboard2.png")}
          style={{ width: 200, height: 200, resizeMode: "contain" }}
        />
      ),
      title: "Easy Streaming",
      subtitle: "Choose your plan to watch live match your favourite club.",
    },
  ];

  return (
    <Onboarding pages={onboardingScreens} onDone={onDone} onSkip={onSkip} />
  );
};

export default OnboardingScreen;
