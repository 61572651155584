import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Pressable,
  Image,
  Dimensions,
  Platform,
  Modal,
  TextInput,
  ActivityIndicator,
} from "react-native";
import {
  H1,
  H3,
  H4,
  Label,
} from "../../../../components/reusable/TextComponent";
import { colors } from "../../../../components/reusable/colour";
import Icon from "react-native-vector-icons/Entypo";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { DataStore } from "@aws-amplify/datastore";
import { UserMaster } from "../../../../models";
const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;
const defaultLogo: any = require("../../../../../assets/default/defaultLogo.png");

const ViewTeamModal = ({ visibleModal, handleClose, Playersdata }) => {
  const [modalVisible, setModalVisible] = useState(visibleModal);
  const [playerList, setPlayerList]: any = useState({});
  const [loading, setLoading] = useState(false);
  const [userMobileNumber, setUserMobileNumber]: any = useState(0);
  const [userData, setUserData]: any = useState({});

  let userDetails: any, admin1UserData: any;

  const getUserdata = async () => {
    userDetails = await AsyncStorage.getItem("UserDetail");
    console.log("userDetails", userDetails);
    if (userDetails) {
      const userData = await DataStore.query(UserMaster, userDetails);
      console.log("userData", userData);
      admin1UserData = userData;
      console.log("admin1UserData", admin1UserData, "userUUID", userData.UUID);
      setUserMobileNumber(userData?.ContactNo);
      setUserData(userData);
    } else {
      console.log("No user found in temp storage");
      // setErrorModalVisible(true);
      // setErrorModalMesg(
      //   "You are not loggedIn ! Please login to your account ! Press 'OK' to redirect to login page"
      // );
    }
  };

  useEffect(() => {
    getUserdata();
    try {
      setLoading(true);
      if (Playersdata && Object.keys(Playersdata).length > 0) {
        console.log("ViewTeamModal", Playersdata);
        setPlayerList(Playersdata);
        setLoading(false);
      }
      setLoading(false);
      console.log("visibleModal", visibleModal);
    } catch (error) {
      setLoading(false);
      console.log("Error occured while fetching the data", error);
    }
  }, [visibleModal, Playersdata]);

  //   const handleClose = () => {
  //     console.log("modalVisible", modalVisible);
  //     setModalVisible(false);
  //   };
  return (
    <View>
      {/* modal started */}
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {}}
      >
        {playerList && (
          <View style={[styles.centeredView, { backgroundColor: "#04040491" }]}>
            <View style={styles.modalView}>
              <H3
                props={"Team Details"}
                style={{
                  marginBottom: 15,
                  color: colors.white,
                  textAlign: "center",
                }}
              />
              <View style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                <View
                  style={{
                    width: 80,
                    height: 80,
                    borderWidth: 2,
                    borderColor: colors.bgColor,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 100,
                  }}
                >
                  <Image
                    source={{
                      uri:
                        playerList?.Logo &&
                        playerList.Logo !== "" &&
                        playerList.Logo !== undefined
                          ? playerList?.Logo
                          : require("../../../../../assets/default/defaultLogo.png"),
                    }}
                    style={{ width: 60, height: 60, borderRadius: 60 }}
                    resizeMode="contain"
                  />
                </View>
                <View style={{ flex: 1, gap: 10 }}>
                  <View>
                    <Label props={"Team Name"} />
                    <H4 props={playerList?.TeamName} />
                  </View>
                  <View
                    style={{ display: "flex", flexDirection: "row", gap: 10 }}
                  >
                    {playerList?.Coach && (
                      <View style={{ flex: 1 }}>
                        <Label props={"Coach Name"} />
                        <H4 props={playerList?.Coach?.Name} />
                      </View>
                    )}
                  </View>
                </View>
              </View>
              {Platform.OS === "web" && screenWidth > 760 ? (
                <View
                  style={{
                    width: "100%",
                    marginTop: 15,
                  }}
                >
                  {/* table header started */}
                  <View style={styles.tableHeaderContainer}>
                    <View style={[styles.tableHeaderTitle, { flex: 3 }]}>
                      <Text
                        style={[
                          styles.tableHeaderTitleText,
                          { textAlign: "left" },
                        ]}
                      >
                        Player Name
                      </Text>
                    </View>
                    <View style={[styles.tableHeaderTitle]}>
                      <Text style={styles.tableHeaderTitleText}>
                        Date of Birth
                      </Text>
                    </View>
                    <View style={[styles.tableHeaderTitle]}>
                      <Text style={styles.tableHeaderTitleText}>
                        Jersey No.
                      </Text>
                    </View>
                    <View style={[styles.tableHeaderTitle]}>
                      <Text style={styles.tableHeaderTitleText}>
                        Mobile No.
                      </Text>
                    </View>
                  </View>
                  {/* table header ended */}
                  <View
                    style={{
                      maxHeight: 0.55 * screenHeight,
                      overflow: "scroll",
                    }}
                  >
                    {playerList &&
                      playerList.PlayerData &&
                      playerList.PlayerData.length > 0 &&
                      playerList.PlayerData.map((ele) => {
                        return (
                          <View style={styles.tableDataContainer}>
                            <View
                              style={[
                                styles.tableDataCell,
                                {
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                  gap: 5,
                                  flex: 3,
                                },
                              ]}
                            >
                              <Image
                                source={{
                                  uri:
                                    ele?.playerdata?.PhotoURL &&
                                    ele?.playerdata?.PhotoURL !== "" &&
                                    ele?.playerdata?.PhotoURL !== undefined &&
                                    ele?.playerdata?.PhotoURL !== null
                                      ? ele?.playerdata?.PhotoURL
                                      : require("../../../../../assets/default/defaultLogo.png"),
                                }}
                                style={{
                                  width: 30,
                                  height: 30,
                                  borderRadius: 30,
                                }}
                                resizeMode="contain"
                              />
                              <Text style={styles.tableDataCellText}>
                                {ele.playerdata?.Name}
                              </Text>
                            </View>
                            <View style={styles.tableDataCell}>
                              <Text style={styles.tableDataCellText}>
                                {ele.playerdata?.DateOfBirth}
                              </Text>
                            </View>
                            <View style={styles.tableDataCell}>
                              <Text style={styles.tableDataCellText}>
                                {ele.playerdata?.JerseyNo}
                              </Text>
                            </View>
                            <View style={styles.tableDataCell}>
                              <Text style={styles.tableDataCellText}>
                                {ele.UserData?.ContactNo
                                  ? ele.UserData?.ContactNo
                                  : userMobileNumber}
                              </Text>
                            </View>
                          </View>
                        );
                      })}
                  </View>
                </View>
              ) : (
                playerList &&
                playerList.PlayerData &&
                playerList.PlayerData.length > 0 &&
                playerList.PlayerData.map((ele) => {
                  return (
                    <View style={styles.playerCard}>
                      <Image
                        source={{
                          uri: ele.playerdata?.PhotoURL
                            ? ele.playerdata?.PhotoURL
                            : require("../../../../../assets/default/defaultLogo.png"),
                        }}
                        style={{
                          width: 60,
                          height: 60,
                          borderRadius: 60,
                        }}
                        resizeMode="contain"
                      />

                      <View
                        style={{
                          flexDirection: "column",
                          gap: 5,
                          flex: 1,
                          paddingLeft: 15,
                        }}
                      >
                        <Text style={styles.playerMobText}>
                          {ele.playerdata?.Name ? ele.playerdata?.Name : null}
                        </Text>
                        <Text style={styles.playerMobText}>
                          DOB-
                          {ele.playerdata?.DateOfBirth
                            ? ele.playerdata?.DateOfBirth
                            : null}
                        </Text>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Text style={styles.playerMobText}>
                            Mob-{" "}
                            {ele.UserData?.ContactNo
                              ? ele.UserData?.ContactNo
                              : userMobileNumber}
                          </Text>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Text style={styles.playerMobText}>Jersey-</Text>
                            <TextInput
                              style={styles.jerseyInput}
                              placeholder={"00"}
                              value={
                                ele.playerdata?.JerseyNo
                                  ? ele.playerdata?.JerseyNo
                                  : null
                              }
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                  );
                })
              )}
              <View
                style={{
                  justifyContent: "center",
                  width: "100%",
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                <Pressable
                  style={{
                    borderWidth: 1,
                    borderColor: colors.orange,
                    padding: 5,
                    width: 120,
                    height: 35,
                    borderRadius: 10,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onPress={() => handleClose()}
                >
                  <Text
                    style={{
                      color: colors.orange,
                      fontFamily: "PlusJakartaSansSemiBold",
                      fontSize: 16,
                    }}
                  >
                    Cancel
                  </Text>
                </Pressable>
              </View>
            </View>
          </View>
        )}
      </Modal>
      {/* modal ended */}

      {/* laoder */}

      {loading && (
        <Modal transparent animationType="none">
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <ActivityIndicator size="large" color="#0000ff" />
            <Text style={{ color: "white" }}>Loading</Text>
          </View>
        </Modal>
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    // marginTop: 22,
  },
  modalView: {
    width: 750,
    maxWidth: "90%",
    margin: 20,
    backgroundColor: colors.royalBlue,
    borderRadius: 5,
    padding: 15,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    position: "relative",
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },

  // new table
  tableHeaderContainer: {
    backgroundColor: colors.blue,
    display: "flex",
    flexDirection: "row",
  },
  tableHeaderTitle: {
    flex: 1,
    paddingVertical: 7,
    padding: 5,
  },
  tableHeaderTitleSN: {
    width: 50,
    paddingVertical: 7,
  },
  tableHeaderTitleLoGo: {
    width: 80,
    paddingVertical: 7,
  },
  tableHeaderTitleText: {
    fontFamily: "PlusJakartaSansBold",
    fontSize: 14,
    color: colors.white,
    textAlign: "center",
  },
  tableDataContainer: {
    backgroundColor: colors.bgColor,
    display: "flex",
    flexDirection: "row",
    marginTop: 5,
    borderRadius: 5,
  },

  tableDataCell: {
    flex: 1,
    paddingVertical: 5,
    paddingHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  tableDataCellSN: {
    width: 50,
    paddingVertical: 5,
    paddingHorizontal: 5,
    justifyContent: "center",
  },
  tableDataCellLogo: {
    width: 80,
    paddingVertical: 5,
    paddingHorizontal: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  tableDataCellText: {
    textAlign: "center",
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 14,
    color: colors.white,
  },
  tableButton: {
    borderColor: colors.darkGray,
    borderWidth: 1,
    paddingVertical: 3,
    paddingHorizontal: 10,
    borderRadius: 4,
    display: "flex",
    gap: 5,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  tableButtonText: {
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 14,
    color: colors.darkGray,
  },
  playerCard: {
    flexDirection: "row",
    width: "100%",
    backgroundColor: colors.bgColor,
    marginTop: 10,
    padding: 15,
    borderRadius: 10,
    shadowOffset: {
      width: 3,
      height: 4,
    },
    shadowColor: "#000000",
    shadowOpacity: 0.22,
    shadowRadius: 10,
    elevation: 3,
    alignItems: "center",
    justifyContent: "flex-start",
    position: "relative",
  },
  playerMobText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 14,
  },
  jerseyInput: {
    width: 50,
    height: 30,
    borderWidth: 1.5,
    borderColor: colors.white,
    color: colors.white,
    borderRadius: 7,
    textAlign: "center",
  },
});
export default ViewTeamModal;
