import { DataStore } from "aws-amplify/datastore";
import { CoachMaster, UserMaster } from "../models";

export const getCoachMasterData = async (key) => {
  const Response = await DataStore.query(CoachMaster, key);
  if (Response) {
    // console.log("Response", Response);
  } else {
    console.log("No saved data found");
  }
  return Response;
};
