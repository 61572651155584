import { useNavigation, useRoute } from "@react-navigation/native";
import React, { useState, useEffect, useContext } from "react";
import { AuthenticationContext } from "../../authentication/Authentication";
import {
  View,
  StyleSheet,
  Image,
  Text,
  Pressable,
  Dimensions,
  Platform,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { colors } from "../reusable/colour";
import MenuIcon from "react-native-vector-icons/Ionicons";
const screenWidth = Dimensions.get("window").width;

const Navbar = () => {
  const [user, setUser] = useState();
  const [mobNavOpen, setMobNavOpen] = useState("false");
  const [hoverIn, setHoverIn] = useState(false);
  const [hoverIn2, setHoverIn2] = useState(false);
  const { isLoggedIn } = useContext(AuthenticationContext);
  const [currentURL, setCurrentURL] = useState("");

  const navigation = useNavigation();
  // const currentURL = window.location.pathname;
  // console.log("currentURL", currentURL);

  const getURL = async () => {
    const currenturl = await AsyncStorage.getItem("CurrentURL");
    console.log("currenturl", currenturl);
    setCurrentURL(currenturl);
    return currenturl;
  };
  const route = useRoute();
  const { level } = route.params || {};
  // console.log("Input Level", level);

  const handleCreateTournament = async () => {
    if (isLoggedIn) {
      navigation.navigate("CreateTournament");
    } else {
      let sendData = {
        prevPage: "",
        nextPage: "",
        userId: "",
      };
      navigation.navigate("SignIn", {
        data: sendData,
      });
    }
  };

  const handleURL = async (value) => {
    const URL = await AsyncStorage.setItem("CurrentURL", value);
    console.log("URL", URL);
    if (value.includes("AllTournament")) {
      navigation.navigate(value, { level: "All" });
    } else {
      navigation.navigate(value);
    }
  };

  const handleMobNav = () => {
    setMobNavOpen(!mobNavOpen);
  };

  const handleHoverIn = () => {
    setHoverIn(true);
  };
  const handleHoverOut = () => {
    setHoverIn(false);
  };
  const handleHoverIn2 = () => {
    setHoverIn2(true);
  };
  const handleHoverOut2 = () => {
    setHoverIn2(false);
  };

  useEffect(() => {
    console.log("isLoggedIn : ", isLoggedIn);
    getURL();
  }, [isLoggedIn, currentURL]);

  return (
    <View style={styles.navbarBgWrapper}>
      <View style={styles.container}>
        <View style={styles.headerContainer}>
          <Pressable onPress={() => navigation.navigate("Home")}>
            <View style={styles.logoContainer}>
              {screenWidth > 760 ? (
                <Image
                  source={require("../../../assets/logo/logo.png")}
                  style={styles.logo}
                  resizeMode="contain"
                />
              ) : (
                <Image
                  source={require("../../../assets/logo/logo-w.png")}
                  style={styles.logo}
                  resizeMode="contain"
                />
              )}
            </View>
          </Pressable>

          {screenWidth < 761 && (
            <View
              style={{ flexDirection: "row", gap: 10, alignItems: "center" }}
            >
              {isLoggedIn ? (
                <Pressable
                  style={{
                    backgroundColor: colors.aqua,
                    paddingVertical: 3,
                    paddingHorizontal: 7,
                    borderRadius: 7,
                  }}
                  onPress={() => {
                    navigation.navigate("Dashboard");
                  }}
                  // onHoverIn={handleHoverIn}
                  // onHoverOut={handleHoverOut}
                >
                  <Text
                    style={{
                      color: colors.royalBlue,
                      fontFamily: "PlusJakartaSansSemiBold",
                      fontSize: 14,
                    }}
                  >
                    Dashboard
                  </Text>
                </Pressable>
              ) : (
                <Pressable
                  style={{
                    backgroundColor: colors.aqua,
                    paddingVertical: 3,
                    paddingHorizontal: 7,
                    borderRadius: 7,
                  }}
                  onPress={() => {
                    let sendData = {
                      prevPage: "",
                      nextPage: "",
                      userId: "",
                    };
                    navigation.navigate("SignIn", { data: sendData });
                  }}
                >
                  <Text
                    style={{
                      color: colors.royalBlue,
                      fontFamily: "PlusJakartaSansSemiBold",
                      fontSize: 14,
                    }}
                  >
                    Login
                  </Text>
                </Pressable>
              )}

              <Pressable onPress={() => handleMobNav()}>
                <MenuIcon
                  name={mobNavOpen ? "menu-outline" : "close"}
                  size={32}
                  color={colors.white}
                />
              </Pressable>
            </View>
          )}

          <View
            style={[
              mobNavOpen
                ? styles.navRightContainer
                : styles.navRightContainerMob,
            ]}
          >
            <View
              style={[
                mobNavOpen ? styles.menuContainer : styles.menuContainerMob,
              ]}
            >
              <Pressable onPress={() => handleURL("Home")}>
                <Text
                  style={
                    currentURL === "Home"
                      ? styles.menuItemActive
                      : styles.menuItem
                  }
                >
                  Home
                </Text>
              </Pressable>

              <Pressable onPress={() => handleURL("Features")}>
                <Text
                  style={
                    currentURL === "Features"
                      ? styles.menuItemActive
                      : styles.menuItem
                  }
                >
                  Features
                </Text>
              </Pressable>
              <Pressable onPress={() => handleURL("AllTournament")}>
                <Text
                  style={
                    currentURL === "AllTournament"
                      ? styles.menuItemActive
                      : styles.menuItem
                  }
                >
                  Tournaments
                </Text>
              </Pressable>

              <Pressable onPress={() => handleURL("AllMatches")}>
                <Text
                  style={
                    currentURL === "AllMatches"
                      ? styles.menuItemActive
                      : styles.menuItem
                  }
                >
                  Matches
                </Text>
              </Pressable>
            </View>
            <View
              style={[
                styles.rightButtonContainer,
                { display: mobNavOpen ? "flex" : "none" },
              ]}
            >
              <Pressable
                style={[
                  styles.bookAdemoBtn,
                  {
                    backgroundColor:
                      hoverIn2 === true ? colors.orange : "transparent",
                    borderColor:
                      hoverIn2 === true ? colors.orange : colors.white,
                  },
                ]}
                onPress={() => handleCreateTournament()}
                onHoverIn={handleHoverIn2}
                onHoverOut={handleHoverOut2}
              >
                <Text style={styles.bookAdemoBtnText}>Create a Tournament</Text>
              </Pressable>
              {isLoggedIn ? (
                <Pressable
                  style={[
                    styles.loginBtn,
                    {
                      backgroundColor:
                        hoverIn === true ? colors.orange : colors.aqua,
                      borderColor:
                        hoverIn === true
                          ? colors.orangeColour
                          : colors.secondary,
                    },
                  ]}
                  onPress={() => {
                    navigation.navigate("Dashboard");
                  }}
                  onHoverIn={handleHoverIn}
                  onHoverOut={handleHoverOut}
                >
                  <Text
                    style={[
                      styles.loginBtnText,
                      {
                        color:
                          hoverIn === true ? colors.white : colors.royalBlue,
                      },
                    ]}
                  >
                    Dashboard
                  </Text>
                </Pressable>
              ) : (
                <Pressable
                  style={[
                    styles.loginBtn,
                    {
                      backgroundColor:
                        hoverIn === true ? colors.orange : colors.aqua,
                      borderColor:
                        hoverIn === true
                          ? colors.orangeColour
                          : colors.secondary,
                    },
                  ]}
                  onPress={() => {
                    let sendData = {
                      prevPage: "",
                      nextPage: "",
                      userId: "",
                    };
                    navigation.navigate("SignIn", { data: sendData });
                  }}
                  onHoverIn={handleHoverIn}
                  onHoverOut={handleHoverOut}
                >
                  <Text
                    style={[
                      styles.loginBtnText,
                      {
                        color:
                          hoverIn === true ? colors.white : colors.royalBlue,
                      },
                    ]}
                  >
                    Login
                  </Text>
                </Pressable>
              )}
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  navbarBgWrapper: {
    width: "100%",
    height: screenWidth > 760 ? 60 : 40,
    top: 0,
    backgroundColor: "#2a3462", //this color will be hard coded
    position: "fixed",
    left: 0,
    zIndex: 9,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.3,
    shadowRadius: 10,
  },
  container: {
    maxWidth: 1320,
    width: "100%",
    height: "100%",
    marginHorizontal: "auto",
    paddingRight: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
    paddingLeft: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
  },
  headerContainer: {
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
    gap: 80,
    alignItems: "center",
  },
  logoContainer: {
    width: screenWidth > 760 ? 235 : 90,
    height: screenWidth > 760 ? 60 : 40,
    paddingVertical: Platform.OS === "web" && screenWidth > 760 ? 5 : 3,
  },
  logo: {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
  },
  menuContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  menuContainerMob: {
    display: "flex",
    gap: 20,
    justifyContent: "flex-end",
    alignItems: "center",
    backgroundColor: colors.newBlue,
    flexDirection: "column",
    position: "absolute",
    width: "100%",
    padding: 10,
    borderBottomEndRadius: 15,
    borderBottomLeftRadius: 15,
    boxShaddow: "0 0 10px #ffffff40",
  },
  menuItem: {
    fontSize: 18,
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
  },
  menuItemActive: {
    fontSize: 18,
    color: colors.orangeColour,
    fontFamily: "PlusJakartaSansExtraBold",
  },
  navRightContainer: {
    display: Platform.OS === "web" && screenWidth > 760 ? "flex" : "none",
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-between",
  },
  navRightContainerMob: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-between",
    position: "absolute",
    width: "100%",
    top: 40,
  },
  rightButtonContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
    alignItems: "center",
  },
  bookAdemoBtn: {
    borderColor: colors.white,
    borderWidth: 1,
    padding: 5,
    borderRadius: 10,
    width: 185,
  },
  bookAdemoBtnText: {
    color: colors.white,
    textAlign: "center",
    fontFamily: "PlusJakartaSansSemiBold",
  },
  loginBtn: {
    backgroundColor: colors.aqua,
    borderColor: colors.aqua,
    borderWidth: 1,
    padding: 5,
    borderRadius: 10,
    fontSize: 14,
    width: 135,
  },
  loginBtnText: {
    color: colors.royalBlue,
    textAlign: "center",
    fontFamily: "PlusJakartaSansSemiBold",
  },
});

export default Navbar;
