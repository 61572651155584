import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Modal,
  StyleSheet,
  TouchableWithoutFeedback,
  Dimensions,
  ScrollView,
} from "react-native";
import { colors } from "./colour";

const { width: screenWidth, height: screenHeight } = Dimensions.get("window");

const CustomTimePicker = ({ selected, onChange }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [selectedTime, setSelectedTime] = useState("");

  useEffect(() => {
    if (selected instanceof Date && !isNaN(selected.getTime())) {
      setSelectedTime(
        `${selected.getHours().toString().padStart(2, "0")}:${selected
          .getMinutes()
          .toString()
          .padStart(2, "0")}`
      );
    } else if (typeof selected === "string") {
      setSelectedTime(selected);
    } else {
      setSelectedTime("");
    }
  }, [selected]);

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const handleConfirm = () => {
    onChange(
      `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`
    );
    toggleModal();
  };

  const handleHourChange = (hour) => {
    setHours(hour);
  };

  const handleMinuteChange = (minute) => {
    setMinutes(minute);
  };

  const renderClockPicker = () => {
    return (
      <ScrollView contentContainerStyle={styles.scrollContainer}>
        <View style={styles.clockContainer}>
          <View style={styles.clock}>
            <Text style={styles.clockText}>
              {hours.toString().padStart(2, "0")}:
            </Text>
            <Text style={styles.clockText}>
              {minutes.toString().padStart(2, "0")}
            </Text>
          </View>
          <View style={styles.pickerContainer}>
            <View style={styles.picker}>
              {[...Array(12).keys()].map((hour) => (
                <TouchableOpacity
                  key={hour}
                  style={[
                    styles.pickerItem,
                    hours === hour && styles.selectedItem,
                  ]}
                  onPress={() => handleHourChange(hour)}
                >
                  <Text
                    style={[
                      styles.pickerText,
                      hours === hour
                        ? { color: colors.white }
                        : { color: "black" },
                    ]}
                  >
                    {hour === 0 ? "12" : hour.toString()}
                  </Text>
                </TouchableOpacity>
              ))}
            </View>
            <View style={styles.picker}>
              {[...Array(60).keys()].map((minute) => (
                <TouchableOpacity
                  key={minute}
                  style={[
                    styles.pickerItem,
                    minutes === minute && styles.selectedItem,
                  ]}
                  onPress={() => handleMinuteChange(minute)}
                >
                  <Text
                    style={[
                      styles.pickerText,
                      minutes === minute
                        ? { color: colors.white }
                        : { color: "black" },
                    ]}
                  >
                    {minute.toString().padStart(2, "0")}
                  </Text>
                </TouchableOpacity>
              ))}
            </View>
          </View>
        </View>
      </ScrollView>
    );
  };

  return (
    <TouchableOpacity onPress={toggleModal} style={styles.timeButton}>
      <Text style={styles.timeText}>{selectedTime}</Text>

      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={toggleModal}
      >
        <TouchableWithoutFeedback onPress={toggleModal}>
          <View style={styles.modalBackground}>
            <TouchableWithoutFeedback onPress={() => {}}>
              <View
                style={[
                  styles.modalContainer,
                  { width: screenWidth * 0.3, height: screenHeight * 0.3 },
                ]}
              >
                {renderClockPicker()}

                <TouchableOpacity
                  onPress={handleConfirm}
                  style={styles.confirmButton}
                >
                  <Text style={styles.confirmButtonText}>Confirm</Text>
                </TouchableOpacity>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  timeButton: {
    padding: 10,
    backgroundColor: colors.bgColor,
    borderRadius: 5,
    alignItems: "center",
  },
  timeText: {
    color: "white",
    fontSize: 16,
  },
  modalBackground: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    backgroundColor: "white",
    padding: 10,
    borderRadius: 10,
    alignItems: "center",
  },
  scrollContainer: {
    flexGrow: 1,
    justifyContent: "center",
  },
  clockContainer: {
    alignItems: "center",
  },
  clock: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  clockText: {
    fontSize: 36,
    paddingHorizontal: 5,
  },
  pickerContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  picker: {
    flex: 1,
  },
  pickerItem: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 5,
    alignItems: "center",
  },
  pickerText: {
    fontSize: 16,
    color: "black",
  },
  selectedItem: {
    backgroundColor: colors.bgColor,
  },
  confirmButton: {
    padding: 8,
    backgroundColor: colors.orange,
    borderRadius: 5,
    marginTop: 10,
  },
  confirmButtonText: {
    color: "white",
    fontSize: 16,
  },
});

export default CustomTimePicker;
