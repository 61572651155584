import React, { useEffect, useState } from "react";
import {
  View,
  ScrollView,
  Platform,
  StyleSheet,
  Pressable,
  Text,
  Image,
  Dimensions,
} from "react-native";
import { colors } from "../../../components/reusable/colour";
import * as ImagePicker from "expo-image-picker";
import { useRoute } from "@react-navigation/native";
import UploadImage from "../../../components/reusable/UploadImage";
import Subgallery from "./Subgallery";
import { ErrorModal } from "../../../components/reusable/AreYouSure";
const ImageViewer = ({ placeholderImageSource, selectedImage }) => {
  const imageSource = selectedImage
    ? { uri: selectedImage }
    : placeholderImageSource;

  return <Image source={imageSource} style={styles.logoImage} />;
};

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorModalMesg, setErrorModalMesg] = useState("");
  const pickImageAsync = async (value) => {
    console.log("logo", value);
    let result = await ImagePicker.launchImageLibraryAsync({
      allowsEditing: true,
      quality: 1,
    });
    if (!result.canceled) {
      const uriParts = result.assets[0].uri;

      // console.log("hellofile", contentType);
      if (value == "Logo") {
        console.log("result", result);
        setSelectedImage(result.assets[0].uri);
      }
    } else {
      setErrorModalVisible(true);
      setErrorModalMesg("You did not select any image.");
      // alert("You did not select any image.");
    }
  };

  const handleSubmit = () => {
    setErrorModalVisible(false);
  };

  return (
    <View style={{ alignItems: "center", justifyContent: "center" }}>
      <Subgallery />

      {errorModalVisible == true ? (
        <ErrorModal
          visibleModal={errorModalVisible}
          SuccessMessage={errorModalMesg}
          onSubmit={handleSubmit}
          header={"Attention"}
        />
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  image: {
    width: "100%",
    height: "100%",
    borderRadius: 5,
  },
  circleContainer: {
    width: "100%",
    height: 220,
    borderRadius: 5,
    borderWidth: 2,
    borderColor: "#e9e9e9",
    borderStyle: "dashed",
    margin: "auto",
    justifyContent: "center",
    alignItems: "center",
    padding: 5,
  },
  circle: {
    width: "100%",
    height: "100%",
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.white,
    gap: 5,
  },

  logoImage: {
    width: "100%",
    height: "100%",
    borderRadius: 100,
  },
  UploadWrapper: {
    backgroundColor: colors.aqua,
    paddingVertical: 5,
    paddingHorizontal: 15,
    borderRadius: 5,
  },
  UploadText: {
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 14,
  },
});
export default Gallery;
