import React, { useEffect, useState } from "react";
import {
  View,
  Platform,
  ScrollView,
  Text,
  StyleSheet,
  Pressable,
  Image,
  Dimensions,
  Modal,
  ActivityIndicator,
} from "react-native";
import { CommonHeading } from "../../../components/reusable/TextComponent";
const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;
import AdminLayout from "../../../components/include/AdminLayout";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { DataStore } from "aws-amplify/datastore";
import { TeamRegistrations, Tournament } from "../../../models";
import { useNavigation } from "@react-navigation/native";
import { colors } from "../../../components/reusable/colour";
import AppBar from "../../../components/include/AppBar";
import FloatingActionButton from "../../../components/reusable/floationActionButton";
import EyeIcon from "react-native-vector-icons/AntDesign";
import LocationIcon from "react-native-vector-icons/Ionicons";
import CalendarIcon from "react-native-vector-icons/Ionicons";
import EditIcon from "react-native-vector-icons/Feather";
import TrashIcon from "react-native-vector-icons/AntDesign";
import ViewTeamModal from "../Tournament/SubComp/ViewTeamModal";
import TeamUpdate from "../Tournament/SubComp/TeamUpdate";
import { formatDateShort } from "../../../components/FormattedDate";
import * as Clipboard from "expo-clipboard";
import TeamMemberPDF from "./TeamMemberPDF";
import PDFIcon from "react-native-vector-icons/AntDesign";
import { AreYouSureOrange } from "../../../components/reusable/AreYouSure";
import SearchBar from "../../../components/reusable/SearchBar";
import DescendingIcon from "react-native-vector-icons/MaterialCommunityIcons";
import AscendingIcon from "react-native-vector-icons/MaterialCommunityIcons";
import ZoomableImage from "../../../components/reusable/ZoomableImage";

// import CropImagePicker from "../../../components/reusable/CropImagePicker";

// import defaultBanner from "../../../../assets/default/tournamentBanner.png";
const defaultBanner: any = require("../../../../assets/default/tournamentBanner.png");
const defaultLogo: any = require("../../../../assets/default/defaultLogo.png");
const MyTournament = () => {
  const [myTournamentsData, setMyTournamentsData] = useState([]);
  const [createdTournaments, setCreatedTournaments] = useState([]);
  const [participatedTournaments, setParticipatedTournaments] = useState([]);
  const [activeTab, setActiveTab] = useState("Created");
  const [modalVisible, setModalVisible] = useState(false);
  const [playerdata, setPlayerdata]: any = useState({});
  const [pdfPlayerData, setPdfPlayerData]: any = useState({});
  const [teamUpdateModal, setTeamUpdateModal] = useState(false);
  const [selectedteamObj, setSelectedteamObj]: any = useState({});
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [errorModalMesg, setErrorModalMesg] = useState("");
  const [authorised, setAuthorised]: any = useState([]);
  const navigation: any = useNavigation();
  const [isLoading, setIsLoading] = useState(false);
  const [showCopied, setShowCopied] = useState(false);
  const [copiedIndex, setCopiedIndex]: any = useState();
  const [comfirmDeleteModalVisible, setComfirmDeletModalVisible] =
    useState(false);

  const [comfirmDeletModalMesg, setComfirmDeletModalMesg] = useState("");
  //
  const [teamMemberVisible, setTeamMemberVisible] = useState(false);
  const [deleteSelectedObj, setDeleteSelectedObj] = useState({});

  const [isDescendingSort, setIsDescendingSort] = useState(true);
  const [isSortedByStatus, setIsSortedByStatus] = useState(true);

  const [searchFlag, setSearchFlag] = useState(false);
  let originLocation = window.location.origin;
  console.log("origin location", originLocation);

  const handleFilterChange = (tab: any) => {
    setActiveTab(tab);
  };

  const getMyTournamentsData = async () => {
    try {
      if (!searchFlag) {
        setIsLoading(true);
      } else {
        setIsLoading(false);
      }

      const userID = await AsyncStorage.getItem("UserDetail");
      console.log("userID", userID);

      const userTournaments = await DataStore.query(Tournament, (tournament) =>
        tournament.Users.userMaster.UUID.eq(userID)
      );
      console.log("userTournaments", userTournaments);
      setMyTournamentsData(userTournaments);
      let createdTournaments = [];
      // Filterout the Created and participated tournaments
      userTournaments &&
        userTournaments.length > 0 &&
        userTournaments.map((tournament) => {
          if (
            tournament.Admin1?.UUID === userID ||
            tournament.Admin2?.UUID === userID ||
            tournament.Admin3?.UUID === userID
          ) {
            createdTournaments.push(tournament);
          }
        });

      console.log("created Tournaments", createdTournaments);

      setCreatedTournaments(createdTournaments);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("Error occured while fetching the data", error);
    }
  };

  const handleGetTournament = async (tournamentKey) => {
    let TournamentObj = await DataStore.query(Tournament, tournamentKey);
    return TournamentObj;
  };

  const handleGetRegistration = async () => {
    try {
      if (!searchFlag) {
        setIsLoading(true);
      } else {
        setIsLoading(false);
      }
      const userID = await AsyncStorage.getItem("UserDetail");
      console.log("userID", userID);

      const userTeamregisration = await DataStore.query(
        TeamRegistrations,
        (tournament) => tournament.Admins.userMaster.UUID.eq(userID)
      );
      console.log("userTeamregisration", userTeamregisration);

      let finalregistration =
        userTeamregisration &&
        userTeamregisration.length > 0 &&
        userTeamregisration.map(async (ele) => ({
          RegistrationObj: ele,
          // PlayerData: await getPlayersData(ele),
          tournamentobj: await handleGetTournament(ele.Tournament),
        }));

      // let Regdata = [];
      // console.log("createdTournaments", createdTournaments);
      if (finalregistration) {
        let Registrationlist = await Promise.all(finalregistration);
        console.log("participatedTournaments", Registrationlist);
        setParticipatedTournaments(Registrationlist);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("Error occured while fetching the data", error);
    }
  };

  const handleBack = () => {
    navigation.goBack();
  };

  const handleEdit = async (obj) => {
    await AsyncStorage.setItem("CurrentTournament", obj.id);
    await AsyncStorage.setItem("myTournamentEdit", "MyTournament");
    navigation.navigate("ManageTournament", {
      tournamentId: obj.id,
    });
  };
  const handleplayerClick = async (value) => {
    console.log("handleplayerClick", value);
    let getdata = await DataStore.query(
      TeamRegistrations,
      value.RegistrationObj.id
    );
    let Playersdata = await getPlayersData(getdata);
    value;
    let obj = Object.assign({}, value.RegistrationObj, {
      PlayerData: Playersdata,
    });

    // {

    //     ...value.RegistrationObj,
    //   },
    // tournamentobj: value.tournamentobj,
    // };
    console.log("Playersdata", obj);

    setPlayerdata(obj);
    handleOpen();
  };

  const handleTeamPDF = async (value) => {
    console.log("handle Team PDF", value);
    setTeamMemberVisible(true);
    let getdata = await DataStore.query(
      TeamRegistrations,
      value.RegistrationObj.id
    );
    let Playersdata = await getPlayersData(getdata);
    let obj = {
      PlayerData: Playersdata,
      RegistrationObj: value.RegistrationObj,
      tournamentobj: value.tournamentobj,
    };
    setPdfPlayerData(obj);
    console.log("Players data after team pdf click", obj);
  };

  const handleCancel = () => {
    setTeamMemberVisible(false);
  };

  const handleOpen = () => {
    setModalVisible(true);
  };
  const handleClose = () => {
    setModalVisible(false);
  };

  const RegHandleClose = () => {
    setTeamUpdateModal(false);
  };
  const handleAddTeam = (TeamObj) => {
    console.log("TeamObj", TeamObj);
    setSelectedteamObj(TeamObj);
    setTeamUpdateModal(true);
  };
  // const handleGetRegistrationdata = async () => {};

  const handleTeamRemove = async (obj, index) => {
    console.log("handleTeamRemove", obj);
    const modelToDelete = await DataStore.query(
      TeamRegistrations,
      obj.RegistrationObj.id
    );
    const Response = await DataStore.delete(modelToDelete);
    if (Response) {
      setActiveTab("Participated");
      await handleGetRegistration();
    }
  };

  const getPlayersData = async (data) => {
    try {
      setIsLoading(true);
      // console.log("helloplayers", data);
      let Playerpromise: any = await [data.Players];
      let Playerdata = await Playerpromise[0].values;
      console.log("UniquePlayerdatalist", Playerdata);
      let finalplayerdata =
        Playerdata &&
        Playerdata.length > 0 &&
        Playerdata.map(async (ele) => {
          let MasterPlayer = await ele.playerMaster;
          // console.log("JersyNo", MasterPlayer);
          let Userdata = await MasterPlayer?.PlayerData;
          let Playerdata = await MasterPlayer;
          let playerobj = {
            playerdata: Playerdata,
            UserData: Userdata,
            // Jersey: MasterPlayer.JerseyNo !== null ? MasterPlayer.JerseyNo : "",
          };
          return playerobj;
        });
      let final = [];
      if (Playerdata && Playerdata.length > 0) {
        final = await Promise.all(finalplayerdata);
      }

      let validPlayerData = [];
      validPlayerData =
        final &&
        final.length > 0 &&
        final.filter((data) => data !== undefined && data !== null);
      console.log("validPlayerData", validPlayerData);
      let values =
        validPlayerData &&
        validPlayerData.length > 0 &&
        validPlayerData !== null &&
        validPlayerData !== undefined
          ? validPlayerData
          : [];
      console.log("values", values);
      setIsLoading(false);
      return values;
    } catch (error) {
      setIsLoading(false);
      console.log("Error occured while fetching the players data", error);
    }
  };

  const copyToClipboard = async (url, index) => {
    await Clipboard.setStringAsync(`${url}?index=${index}`);
    setShowCopied(true);
    setCopiedIndex(index); // Set the copied index
    setTimeout(() => {
      setShowCopied(false);
    }, 3000);
  };

  const fetchData = async () => {
    if (!searchFlag) {
      await getMyTournamentsData();
      await handleGetRegistration();
    } else {
      console.log("searchflag is true");
    }
  };

  useEffect(() => {
    console.log("searchFlag:", searchFlag);
    fetchData();
  }, [searchFlag]);

  const handleConfirm = () => {
    handleRemoveTournament(deleteSelectedObj);
  };

  const handleCancelTournament = () => {
    setComfirmDeletModalVisible(false);
  };

  const handleRemoveTournament = async (obj) => {
    console.log("handleRemoveTournament", obj);
    const modelToDelete = await DataStore.query(Tournament, obj.id);
    const Response = await DataStore.delete(modelToDelete);
    if (Response) {
      setComfirmDeletModalVisible(false);
      fetchData();
    }
  };

  const handleDeleteTournament = (obj) => {
    setDeleteSelectedObj(obj);
    setComfirmDeletModalVisible(true),
      setComfirmDeletModalMesg("Are you sure do you want to delete??");
  };

  const handleSearch = async (text: string) => {
    setSearchFlag(true);

    if (!text) {
      setSearchFlag(false);
      console.log("Search text is empty. Resetting to original data.", text);

      if (activeTab === "Created") {
        setCreatedTournaments([...createdTournaments]); // Reset to the original data
      } else if (activeTab === "Participated") {
        setParticipatedTournaments([...participatedTournaments]); // Reset to the original data
      }

      return;
    }

    const searchText = text.toLowerCase();
    console.log("Search text:", searchText);

    const filterData = async (data: any[]) => {
      return data.filter((tournament: any) => {
        console.log("Checking tournament:", tournament.Name);

        return Object.values(tournament).some((value) => {
          if (typeof value === "object" && value !== null) {
            console.log(
              "Checking nested object in tournament:",
              tournament.Name
            );

            return Object.values(value).some((nestedValue) => {
              if (typeof nestedValue === "string") {
                console.log("Checking nested value:", nestedValue);
                return nestedValue.toLowerCase().includes(searchText);
              }
              return false;
            });
          }

          if (typeof value === "string") {
            console.log("Checking value:", value);
            return value.toLowerCase().includes(searchText);
          }

          return false;
        });
      });
    };

    if (activeTab === "Created") {
      if (text.length >= 3) {
        const filteredResults = await filterData(createdTournaments);
        console.log("Filtered results for Created:", filteredResults);
        if (filteredResults.length > 0) {
          setCreatedTournaments(filteredResults);
        } else {
          setCreatedTournaments([]);
        }
      } else {
        console.log("text length is less than 3 letters");
      }
    } else if (activeTab === "Participated") {
      if (text.length >= 3) {
        const filteredResults = await filterData(participatedTournaments);
        console.log("Filtered results for Participated:", filteredResults);

        if (filteredResults.length > 0) {
          setParticipatedTournaments(filteredResults);
        } else {
          setParticipatedTournaments([]);
        }
      } else {
        console.log("text is less than 3 letters");
      }
    }
  };
  // Sort the created tournaments by StartDate in descending order
  function sortTournamentsByStartDateDescending(data: any) {
    return data.sort((a: any, b: any) => {
      const dateA: any = a.StartDate ? new Date(a.StartDate) : new Date(0); // Treat null/undefined/empty as the earliest date
      const dateB: any = b.StartDate ? new Date(b.StartDate) : new Date(0); // Treat null/undefined/empty as the earliest date
      return dateB - dateA;
    });
  }

  // Sort the created tournaments by StartDate in ascending order
  function sortTournamentsByStartDateAscending(data: any) {
    return data.sort((a: any, b: any) => {
      const dateA: any = a.StartDate ? new Date(a.StartDate) : new Date(0);
      const dateB: any = b.StartDate ? new Date(b.StartDate) : new Date(0);
      return dateA - dateB;
    });
  }

  // Define the custom sort order for tournament statuses
  const statusOrder = ["inprogress", "scheduled", "completed", "created"];

  function sortTournamentsByStatusAscending(data: any) {
    return data.sort((a: any, b: any) => {
      const normalizedStatusA = a.Status.toLowerCase();
      const normalizedStatusB = b.Status.toLowerCase();
      const indexA = statusOrder.indexOf(normalizedStatusA);
      const indexB = statusOrder.indexOf(normalizedStatusB);
      return indexA - indexB;
    });
  }

  function sortTournamentsByStatusDescending(data: any) {
    return data.sort((a: any, b: any) => {
      const normalizedStatusA = a.Status.toLowerCase();
      const normalizedStatusB = b.Status.toLowerCase();
      const indexA = statusOrder.indexOf(normalizedStatusA);
      const indexB = statusOrder.indexOf(normalizedStatusB);
      return indexB - indexA;
    });
  }

  const sortedTournamentsByStartDate = () => {
    let sortedTournaments: any;
    let createdTournamentsData = [...createdTournaments];
    if (isDescendingSort) {
      sortedTournaments = sortTournamentsByStartDateDescending(
        createdTournamentsData
      );
      console.log("Descesing startdate:", sortedTournaments);
    } else {
      sortedTournaments = sortTournamentsByStartDateAscending(
        createdTournamentsData
      );
      console.log("Ascesing startdate:", sortedTournaments);
    }
    setCreatedTournaments(sortedTournaments);
    setIsDescendingSort(!isDescendingSort);
  };

  const sortTournamentsDataByStatus = () => {
    let sortedTournamentsByStatus: any;
    let createdTournamentsData = [...createdTournaments];

    if (isSortedByStatus) {
      sortedTournamentsByStatus = sortTournamentsByStatusAscending(
        createdTournamentsData
      );
      console.log("sortedTournamentsByStatus", sortedTournamentsByStatus);
    } else {
      sortedTournamentsByStatus = sortTournamentsByStatusDescending(
        createdTournamentsData
      );
      console.log("original Data", sortedTournamentsByStatus);
    }
    setCreatedTournaments(sortedTournamentsByStatus);
    setIsSortedByStatus(!isSortedByStatus);
  };

  return (
    <>
      {Platform.OS !== "web" && (
        <AppBar
          title={"My Tournaments"}
          showBackButton={true}
          onBackPress={handleBack}
        />
      )}
      <AdminLayout>
        <ScrollView style={{ padding: Platform.OS == "web" ? 5 : 10 }}>
          {Platform.OS === "web" ? (
            <View style={styles.MTHeadingContainer}>
              <CommonHeading props={"My Tournaments"} />
              <Pressable
                style={styles.loginBtn}
                onPress={() => {
                  navigation.navigate("CreateTournament");
                }}
              >
                <Text style={styles.loginBtnText}>
                  Create Tournament for Free
                </Text>
              </Pressable>
            </View>
          ) : null}
          {/* <CropImagePicker /> */}

          <View
            style={{
              paddingVertical:
                Platform.OS === "web" && screenWidth > 760 ? undefined : 10,
            }}
          >
            <SearchBar
              placeholder="Search by tournament/team name"
              onSearch={handleSearch}
              style={styles.searchBar}
            />
          </View>

          <View style={styles.filterContainer}>
            <Pressable
              style={[
                styles.filterButton,
                {
                  backgroundColor:
                    activeTab == "Created" ? colors.orange : "#ababab",
                },
              ]}
              onPress={() => {
                handleFilterChange("Created");
              }}
            >
              <Text style={styles.filterBtnText}>Created</Text>
            </Pressable>
            <Pressable
              style={[
                styles.filterButton,
                {
                  backgroundColor:
                    activeTab == "Participated" ? colors.orange : "#ababab",
                },
              ]}
              onPress={() => {
                handleFilterChange("Participated");
              }}
            >
              <Text style={styles.filterBtnText}>Participated</Text>
            </Pressable>
          </View>

          <View style={styles.homeTournamentContainer}>
            {/* Created tournaments */}
            {activeTab == "Created" ? (
              screenWidth > 760 ? (
                <View style={stylesByAkash.tableContainer}>
                  <View style={stylesByAkash.tableHeaderRow}>
                    <View
                      style={[stylesByAkash.tableHeaderData, { maxWidth: 60 }]}
                    >
                      <Text
                        style={[
                          stylesByAkash.tableHeaderText,
                          { textAlign: "center" },
                        ]}
                      >
                        SN
                      </Text>
                    </View>
                    <View style={stylesByAkash.tableHeaderData}>
                      <Text style={stylesByAkash.tableHeaderText}>
                        Tournament Name
                      </Text>
                    </View>
                    <View
                      style={[
                        stylesByAkash.tableHeaderData,
                        { maxWidth: 140, flexDirection: "row", gap: 10 },
                      ]}
                    >
                      <Text
                        style={[
                          stylesByAkash.tableHeaderText,
                          { textAlign: "center" },
                        ]}
                      >
                        Start Date
                      </Text>

                      <Pressable onPress={sortedTournamentsByStartDate}>
                        {isDescendingSort ? (
                          <DescendingIcon
                            name="sort-descending"
                            size={20}
                            color={colors.white}
                          />
                        ) : (
                          <AscendingIcon
                            name="sort-ascending"
                            size={20}
                            color={colors.white}
                          />
                        )}
                      </Pressable>
                    </View>
                    <View
                      style={[stylesByAkash.tableHeaderData, { maxWidth: 140 }]}
                    >
                      <Text
                        style={[
                          stylesByAkash.tableHeaderText,
                          { textAlign: "center" },
                        ]}
                      >
                        End Date
                      </Text>
                    </View>
                    <View
                      style={[stylesByAkash.tableHeaderData, { maxWidth: 140 }]}
                    >
                      <Text
                        style={[
                          stylesByAkash.tableHeaderText,
                          { textAlign: "center" },
                        ]}
                      >
                        Registration Date
                      </Text>
                    </View>
                    <View
                      style={[
                        stylesByAkash.tableHeaderData,
                        { maxWidth: 110, flexDirection: "row", gap: 10 },
                      ]}
                    >
                      <Text
                        style={[
                          stylesByAkash.tableHeaderText,
                          { textAlign: "center" },
                        ]}
                      >
                        Status
                      </Text>
                      <Pressable onPress={sortTournamentsDataByStatus}>
                        {isSortedByStatus ? (
                          <DescendingIcon
                            name="sort-descending"
                            size={20}
                            color={colors.white}
                          />
                        ) : (
                          <AscendingIcon
                            name="sort-ascending"
                            size={20}
                            color={colors.white}
                          />
                        )}
                      </Pressable>
                    </View>
                    <View
                      style={[stylesByAkash.tableHeaderData, { maxWidth: 110 }]}
                    >
                      <Text
                        style={[
                          stylesByAkash.tableHeaderText,
                          { textAlign: "center" },
                        ]}
                      >
                        Action
                      </Text>
                    </View>
                  </View>
                  {createdTournaments && createdTournaments.length > 0 ? (
                    createdTournaments.map((ele, index) => {
                      return (
                        <View
                          style={stylesByAkash.tableDataRowContainer}
                          key={index}
                        >
                          <View style={stylesByAkash.tableDataRow}>
                            <View
                              style={[
                                stylesByAkash.tableRowData,
                                { maxWidth: 60, justifyContent: "center" },
                              ]}
                            >
                              <Text
                                style={[
                                  stylesByAkash.tableRowText,
                                  { textAlign: "center" },
                                ]}
                              >
                                {index + 1}
                              </Text>
                            </View>
                            <View
                              style={[
                                stylesByAkash.tableRowData,
                                {
                                  flexDirection: "row",
                                  alignItems: "center",
                                  gap: 7,
                                },
                              ]}
                            >
                              <View style={stylesByAkash.logoImage}>
                                <ZoomableImage
                                  imageSource={{
                                    uri:
                                      ele.Logo &&
                                      ele.Logo !== null &&
                                      ele.Logo !== "" &&
                                      ele.Logo !==
                                        ele.Logo.includes("undefined")
                                        ? ele.Logo
                                        : defaultLogo,
                                  }}
                                  imageStyle={stylesByAkash.logoImage}
                                  modalStyle={undefined}
                                />
                              </View>
                              <Text style={stylesByAkash.tableRowText}>
                                {ele.Name}
                              </Text>
                            </View>
                            <View
                              style={[
                                stylesByAkash.tableRowData,
                                { maxWidth: 140, justifyContent: "center" },
                              ]}
                            >
                              <Text
                                style={[
                                  stylesByAkash.tableRowText,
                                  { textAlign: "center" },
                                ]}
                              >
                                {formatDateShort(ele.StartDate)}
                              </Text>
                            </View>
                            <View
                              style={[
                                stylesByAkash.tableRowData,
                                { maxWidth: 140, justifyContent: "center" },
                              ]}
                            >
                              <Text
                                style={[
                                  stylesByAkash.tableRowText,
                                  { textAlign: "center" },
                                ]}
                              >
                                {formatDateShort(ele.EndDate)}
                              </Text>
                            </View>
                            <View
                              style={[
                                stylesByAkash.tableRowData,
                                { maxWidth: 140, justifyContent: "center" },
                              ]}
                            >
                              <Text
                                style={[
                                  stylesByAkash.tableRowText,
                                  { textAlign: "center" },
                                ]}
                              >
                                {formatDateShort(ele.RegistrationEndDate)}
                              </Text>
                            </View>
                            <View
                              style={[
                                stylesByAkash.tableRowData,
                                { maxWidth: 110, justifyContent: "center" },
                              ]}
                            >
                              <Text
                                style={[
                                  stylesByAkash.tableRowText,
                                  {
                                    textAlign: "center",
                                    color:
                                      ele.Status.toLowerCase() == "created"
                                        ? colors.white
                                        : ele.Status.toLowerCase() ==
                                          "inprogress"
                                        ? colors.orange
                                        : colors.green,
                                  },
                                ]}
                              >
                                {ele.Status}
                              </Text>
                            </View>
                            <View
                              style={[
                                stylesByAkash.tableRowData,
                                {
                                  maxWidth: 110,
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: 7,
                                },
                              ]}
                            >
                              <Pressable
                                onPress={() => {
                                  handleEdit(ele);
                                }}
                              >
                                <EditIcon
                                  name="edit"
                                  size={20}
                                  color={colors.white}
                                />
                              </Pressable>
                              <Pressable
                                onPress={() => handleDeleteTournament(ele)}
                              >
                                <TrashIcon
                                  name="delete"
                                  size={20}
                                  color={colors.red}
                                />
                              </Pressable>
                            </View>
                          </View>
                        </View>
                      );
                    })
                  ) : (
                    <Text
                      style={{ color: colors.white, justifyContent: "center" }}
                    >
                      No results found
                    </Text>
                  )}
                </View>
              ) : createdTournaments && createdTournaments.length > 0 ? (
                createdTournaments.map((ele, index) => {
                  return (
                    <View style={stylesByAkash.mobTournamentCard} key={index}>
                      <Image
                        source={{
                          uri: ele.Banner ? ele.Banner : defaultBanner,
                        }}
                        style={stylesByAkash.mobTournamentBanner}
                      />
                      <View style={stylesByAkash.mobTournamentContentWrapper}>
                        <View style={stylesByAkash.mobTourUpperText}>
                          <View style={{ flex: 1, gap: 10 }}>
                            <Text
                              style={stylesByAkash.mobTournamentHeadingText}
                            >
                              {ele.Name}
                            </Text>
                            <View style={stylesByAkash.mobUpperTIRow}>
                              <LocationIcon
                                name="location-sharp"
                                size={18}
                                color={colors.white}
                              />
                              <Text style={stylesByAkash.mobTournamentText}>
                                {ele.Venues[0]?.Name}
                              </Text>
                            </View>
                            <View style={stylesByAkash.mobUpperTIRow}>
                              <CalendarIcon
                                name="calendar-clear"
                                size={18}
                                color={colors.white}
                              />
                              <Text style={stylesByAkash.mobTournamentText}>
                                {ele.StartDate} - {ele.EndDate}
                              </Text>
                            </View>
                          </View>
                        </View>
                        <View style={stylesByAkash.mobBottomRow}>
                          <View>
                            <Text style={stylesByAkash.mobTournamentText}>
                              Registration Date
                            </Text>
                            <Text style={stylesByAkash.mobTournamentText}>
                              {ele.RegistrationEndDate}
                            </Text>
                          </View>
                          <View>
                            <Text style={stylesByAkash.mobTournamentText}>
                              Status
                            </Text>
                            <Text
                              style={[
                                stylesByAkash.mobTournamentText,
                                { color: colors.green },
                              ]}
                            >
                              {ele.Status}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View style={stylesByAkash.mobTournaBtnRow}>
                        <Pressable
                          style={[
                            stylesByAkash.mobTournaBtn,
                            { backgroundColor: colors.orange, flex: 1 },
                          ]}
                        >
                          <TrashIcon
                            name="delete"
                            size={20}
                            color={colors.white}
                          />
                          <Text
                            style={[
                              stylesByAkash.mobTournaBtnText,
                              { color: colors.white },
                            ]}
                          >
                            Delete
                          </Text>
                        </Pressable>
                        <Pressable
                          style={[
                            stylesByAkash.mobTournaBtn,
                            { backgroundColor: colors.aqua, flex: 1.5 },
                          ]}
                          onPress={() => {
                            handleEdit(ele);
                          }}
                        >
                          <Text
                            style={[
                              stylesByAkash.mobTournaBtnText,
                              { color: colors.bgColor },
                            ]}
                          >
                            Manage
                          </Text>
                        </Pressable>
                      </View>
                    </View>
                  );
                })
              ) : (
                <Text style={{ color: colors.white, justifyContent: "center" }}>
                  No results found
                </Text>
              )
            ) : null}

            {/* Particpated tournaments */}

            {activeTab == "Participated" ? (
              screenWidth > 760 ? (
                <View style={stylesByAkash.tableContainer}>
                  <View style={stylesByAkash.tableHeaderRow}>
                    <View
                      style={[stylesByAkash.tableHeaderData, { maxWidth: 60 }]}
                    >
                      <Text
                        style={[
                          stylesByAkash.tableHeaderText,
                          { textAlign: "center" },
                        ]}
                      >
                        SN
                      </Text>
                    </View>
                    <View style={stylesByAkash.tableHeaderData}>
                      <Text style={stylesByAkash.tableHeaderText}>
                        Tournament Name
                      </Text>
                    </View>
                    <View
                      style={[stylesByAkash.tableHeaderData, { maxWidth: 140 }]}
                    >
                      <Text
                        style={[
                          stylesByAkash.tableHeaderText,
                          { textAlign: "center" },
                        ]}
                      >
                        Registration Date
                      </Text>
                    </View>
                    <View style={stylesByAkash.tableHeaderData}>
                      <Text style={stylesByAkash.tableHeaderText}>
                        Team Name
                      </Text>
                    </View>
                    <View
                      style={[stylesByAkash.tableHeaderData, { maxWidth: 110 }]}
                    >
                      <Text
                        style={[
                          stylesByAkash.tableHeaderText,
                          { textAlign: "center" },
                        ]}
                      >
                        Status
                      </Text>
                    </View>
                    <View
                      style={[stylesByAkash.tableHeaderData, { maxWidth: 110 }]}
                    >
                      <Text
                        style={[
                          stylesByAkash.tableHeaderText,
                          { textAlign: "center" },
                        ]}
                      >
                        Payment
                      </Text>
                    </View>
                    <View
                      style={[stylesByAkash.tableHeaderData, { maxWidth: 110 }]}
                    >
                      <Text
                        style={[
                          stylesByAkash.tableHeaderText,
                          { textAlign: "center" },
                        ]}
                      >
                        Action
                      </Text>
                    </View>
                  </View>
                  {participatedTournaments &&
                  participatedTournaments.length > 0 ? (
                    participatedTournaments.map((ele, index) => {
                      return (
                        <View
                          style={stylesByAkash.tableDataRowContainer}
                          key={index}
                        >
                          <View style={stylesByAkash.tableDataRow}>
                            <View
                              style={[
                                stylesByAkash.tableRowData,
                                { maxWidth: 60, justifyContent: "center" },
                              ]}
                            >
                              <Text
                                style={[
                                  stylesByAkash.tableRowText,
                                  { textAlign: "center" },
                                ]}
                              >
                                {index + 1}
                              </Text>
                            </View>
                            <View
                              style={[
                                stylesByAkash.tableRowData,
                                {
                                  flexDirection: "row",
                                  alignItems: "center",
                                  gap: 7,
                                },
                              ]}
                            >
                              <View style={stylesByAkash.logoImage}>
                                <ZoomableImage
                                  imageSource={{
                                    uri:
                                      ele.tournamentobj?.Logo !== null ||
                                      ele.tournamentobj?.Logo !== "" ||
                                      ele.tournamentobj?.Logo !==
                                        ele.tournamentobj?.Logo.includes(
                                          "undefined"
                                        )
                                        ? ele.tournamentobj?.Logo
                                        : defaultLogo,
                                  }}
                                  imageStyle={stylesByAkash.logoImage}
                                  modalStyle={undefined}
                                />
                              </View>

                              <Text style={stylesByAkash.tableRowText}>
                                {ele.tournamentobj?.Name}
                              </Text>
                            </View>
                            <View
                              style={[
                                stylesByAkash.tableRowData,
                                { maxWidth: 140, justifyContent: "center" },
                              ]}
                            >
                              <Text
                                style={[
                                  stylesByAkash.tableRowText,
                                  { textAlign: "center" },
                                ]}
                              >
                                {formatDateShort(
                                  ele.RegistrationObj?.createdAt.split("T")[0]
                                )}
                              </Text>
                            </View>
                            <View
                              style={[
                                stylesByAkash.tableRowData,
                                {
                                  flexDirection: "row",
                                  alignItems: "center",
                                  gap: 7,
                                },
                              ]}
                            >
                              <View style={stylesByAkash.logoImage}>
                                <ZoomableImage
                                  imageSource={{
                                    uri:
                                      ele.RegistrationObj?.Logo !== null ||
                                      ele.RegistrationObj?.Logo !== ""
                                        ? ele.RegistrationObj?.Logo
                                        : defaultLogo,
                                  }}
                                  imageStyle={stylesByAkash.logoImage}
                                  modalStyle={undefined}
                                />
                              </View>
                              <Text style={stylesByAkash.tableRowText}>
                                {ele.RegistrationObj?.TeamName}
                              </Text>
                            </View>
                            <View
                              style={[
                                stylesByAkash.tableRowData,
                                { maxWidth: 110, justifyContent: "center" },
                              ]}
                            >
                              <Text
                                style={[
                                  stylesByAkash.tableRowText,
                                  {
                                    textAlign: "center",
                                    color:
                                      ele.RegistrationObj?.Status == "APPROVED"
                                        ? colors.green
                                        : colors.white,
                                  },
                                ]}
                              >
                                {ele.RegistrationObj?.Status}
                              </Text>
                            </View>
                            <View
                              style={[
                                stylesByAkash.tableRowData,
                                { maxWidth: 110, justifyContent: "center" },
                              ]}
                            >
                              <Text
                                style={[
                                  stylesByAkash.tableRowText,
                                  {
                                    textAlign: "center",
                                    color:
                                      ele.RegistrationObj?.PaymentStatus ==
                                      "PAID"
                                        ? colors.green
                                        : colors.white,
                                  },
                                ]}
                              >
                                {ele.RegistrationObj?.PaymentStatus}
                              </Text>
                            </View>
                            <View
                              style={[
                                stylesByAkash.tableRowData,
                                {
                                  maxWidth: 110,
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: 7,
                                },
                              ]}
                            >
                              {ele.RegistrationObj?.PaymentStatus == "PAID" && (
                                <Pressable onPress={() => handleTeamPDF(ele)}>
                                  <PDFIcon
                                    name="pdffile1"
                                    size={20}
                                    color={colors.red}
                                  />
                                </Pressable>
                              )}
                              <Pressable onPress={() => handleplayerClick(ele)}>
                                <EyeIcon
                                  name="eyeo"
                                  size={20}
                                  color={colors.white}
                                />
                              </Pressable>
                              <Pressable
                                onPress={() => {
                                  handleAddTeam(ele);
                                }}
                              >
                                <EditIcon
                                  name="edit"
                                  size={20}
                                  color={colors.white}
                                />
                              </Pressable>
                              <Pressable
                                onPress={() => handleTeamRemove(ele, index)}
                              >
                                <TrashIcon
                                  name="delete"
                                  size={20}
                                  color={colors.red}
                                />
                              </Pressable>
                            </View>
                          </View>
                          <Pressable
                            style={styles.copyTextContainer}
                            onPress={() =>
                              copyToClipboard(
                                `${originLocation}/PlayerRegistration/tournamentId/${ele.tournamentobj?.id}/teamId/${ele.RegistrationObj?.id}`,
                                index
                              )
                            }
                          >
                            <Text
                              style={{
                                color: colors.white,
                                fontFamily: "PlusJakartaSansExtraLight",
                                flex: 1,
                              }}
                              numberOfLines={1}
                            >
                              <Text
                                style={{
                                  fontFamily: "PlusJakartaSansMedium",
                                  color: colors.aqua,
                                }}
                              >
                                Link for player self registration -
                              </Text>
                              {`${originLocation}/PlayerRegistration/tournamentId/${ele.tournamentobj?.id}/teamId/${ele.RegistrationObj?.id}`}
                            </Text>
                            <Text style={styles.copyBtn}>Copy Link</Text>
                          </Pressable>
                          <Text
                            style={[
                              styles.linkCopiedText,
                              {
                                display:
                                  showCopied && copiedIndex === index
                                    ? "flex"
                                    : "none",
                              },
                            ]}
                          >
                            Link Copied
                          </Text>
                        </View>
                      );
                    })
                  ) : (
                    <Text
                      style={{ color: colors.white, justifyContent: "center" }}
                    >
                      No results found
                    </Text>
                  )}
                </View>
              ) : participatedTournaments &&
                participatedTournaments.length > 0 ? (
                participatedTournaments.map((ele, index) => {
                  return (
                    <View style={stylesByAkash.mobTournamentCard} key={index}>
                      <Image
                        source={{
                          uri: ele.tournamentobj?.Banner
                            ? ele.tournamentobj?.Banner
                            : defaultBanner,
                        }}
                        style={stylesByAkash.mobTournamentBanner}
                      />
                      <View style={stylesByAkash.mobTournamentContentWrapper}>
                        <View style={stylesByAkash.mobTourUpperText}>
                          <View style={{ flex: 1, gap: 10 }}>
                            <Text
                              style={stylesByAkash.mobTournamentHeadingText}
                            >
                              {ele.tournamentobj?.Name}
                            </Text>
                            <View style={stylesByAkash.mobUpperTIRow}>
                              <LocationIcon
                                name="location-sharp"
                                size={18}
                                color={colors.white}
                              />
                              <Text style={stylesByAkash.mobTournamentText}>
                                {ele.tournamentobj?.Venues[0]?.Name}
                              </Text>
                            </View>
                          </View>
                          <View style={{ gap: 10, flexDirection: "row" }}>
                            <Pressable
                              onPress={() => {
                                handleAddTeam(ele);
                              }}
                            >
                              <EditIcon
                                name="edit"
                                size={20}
                                color={colors.white}
                              />
                            </Pressable>
                            <Pressable
                              onPress={() => handleTeamRemove(ele, index)}
                            >
                              <TrashIcon
                                name="delete"
                                size={20}
                                color={colors.red}
                              />
                            </Pressable>
                          </View>
                        </View>
                        <Text style={[stylesByAkash.mobTournamentText]}>
                          Team Registered: {ele.RegistrationObj?.TeamName}
                        </Text>
                        <View style={stylesByAkash.mobBottomRow}>
                          <View>
                            <Text style={stylesByAkash.mobTournamentText}>
                              Registration Date
                            </Text>
                            <Text style={stylesByAkash.mobTournamentText}>
                              {formatDateShort(
                                ele.RegistrationObj?.createdAt.split("T")[0]
                              )}
                            </Text>
                          </View>
                          <View>
                            <Text style={stylesByAkash.mobTournamentText}>
                              Status
                            </Text>
                            <Text
                              style={[
                                stylesByAkash.mobTournamentText,
                                { color: colors.green },
                              ]}
                            >
                              {ele.RegistrationObj?.Status}
                            </Text>
                          </View>
                          <View>
                            <Text style={stylesByAkash.mobTournamentText}>
                              Payment
                            </Text>
                            <Text
                              style={[
                                stylesByAkash.mobTournamentText,
                                { color: colors.green },
                              ]}
                            >
                              {ele.RegistrationObj?.PaymentStatus}
                            </Text>
                          </View>
                        </View>
                        <Pressable
                          style={[styles.copyTextContainer, { marginTop: 10 }]}
                          onPress={() =>
                            copyToClipboard(
                              `${originLocation}/PlayerRegistration/tournamentId/${ele.tournamentobj?.id}/teamId/${ele.RegistrationObj?.id}`,
                              index
                            )
                          }
                        >
                          <Text
                            style={{
                              color: colors.white,
                              fontFamily: "PlusJakartaSansExtraLight",
                              flex: 1,
                            }}
                            numberOfLines={1}
                          >
                            <Text
                              style={{
                                fontFamily: "PlusJakartaSansMedium",
                                color: colors.aqua,
                              }}
                            >
                              Link for player self registration -
                            </Text>

                            {`${originLocation}/PlayerRegistration/tournamentId/${ele.tournamentobj?.id}/teamId/${ele.RegistrationObj?.id}`}
                          </Text>
                          <Text style={[styles.copyBtn, { width: 72 }]}>
                            Copy Link
                          </Text>
                        </Pressable>
                        <Text
                          style={[
                            styles.linkCopiedText,
                            {
                              display:
                                showCopied && copiedIndex === index
                                  ? "flex"
                                  : "none",
                            },
                          ]}
                        >
                          Link Copied
                        </Text>
                      </View>
                      <View style={stylesByAkash.mobTournaBtnRow}>
                        <Pressable
                          onPress={() => handleplayerClick(ele)}
                          style={[
                            stylesByAkash.mobTournaBtn,
                            { backgroundColor: colors.aqua, width: "100%" },
                          ]}
                        >
                          <Text
                            style={[
                              stylesByAkash.mobTournaBtnText,
                              { color: colors.bgColor },
                            ]}
                          >
                            View Details
                          </Text>
                        </Pressable>
                      </View>
                    </View>
                  );
                })
              ) : (
                <Text style={{ color: colors.white, justifyContent: "center" }}>
                  No results found
                </Text>
              )
            ) : null}
          </View>
          {modalVisible == true ? (
            <ViewTeamModal
              visibleModal={modalVisible}
              handleClose={handleClose}
              Playersdata={playerdata}
            />
          ) : null}

          {teamUpdateModal == true ? (
            <TeamUpdate
              visibleModal={teamUpdateModal}
              TeamUpdateObj={selectedteamObj}
              RegHandleClose={RegHandleClose}
              // handleGetRegistrationdata={handleGetRegistrationdata}
              // squadId={squadId ? squadId : ""}
              // userUUID={currentUserUuid ? currentUserUuid : ""}
              // currentUserName={""}
            />
          ) : null}
        </ScrollView>

        {/* Loader */}
        {isLoading && (
          <Modal transparent animationType="none">
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <ActivityIndicator size="large" color={colors.blue} />
              <Text style={{ color: colors.white }}>Loading</Text>
            </View>
          </Modal>
        )}
      </AdminLayout>
      {teamMemberVisible ? (
        <TeamMemberPDF
          visibleModal={teamMemberVisible}
          handleCancel={handleCancel}
          teamData={pdfPlayerData}
        />
      ) : null}

      {comfirmDeleteModalVisible && (
        <AreYouSureOrange
          visibleModal={comfirmDeleteModalVisible}
          Message={comfirmDeletModalMesg}
          ButtonText={"Delete"}
          onCancel={handleCancelTournament}
          onConfirm={handleConfirm}
        />
      )}
      {Platform.OS !== "web" && (
        <View style={{ bottom: 10, right: 10, position: "absolute" }}>
          <FloatingActionButton
            onPress={() => {
              navigation.navigate("CreateTournament");
            }}
          />
        </View>
      )}
    </>
  );
};

const stylesByAkash = StyleSheet.create({
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    width: "100%",
  },
  tableHeaderRow: {
    flexDirection: "row",
    gap: 10,
    padding: 7,
    backgroundColor: colors.blue,
  },
  tableDataRowContainer: {
    gap: 10,
    padding: 7,
    backgroundColor: colors.royalBlue,
    borderRadius: 8,
  },
  tableDataRow: {
    flexDirection: "row",
    marginTop: 10,
    borderRadius: 8,
  },
  tableHeaderData: {
    flex: 1,
  },
  tableHeaderText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 14,
  },
  tableRowData: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  tableRowText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansRegular",
  },
  logoImage: {
    width: 50,
    height: 50,
    borderRadius: 50,
  },
  // for mobile
  mobTournamentCard: {
    width: "100%",
    backgroundColor: colors.royalBlue,
    borderRadius: 15,
  },
  mobTournamentBanner: {
    width: "100%",
    height: 160,
    borderRadius: 15,
    resizeMode: "cover",
  },
  mobTournamentContentWrapper: {
    width: "100%",
    backgroundColor: colors.royalBlue,
    borderRadius: 15,
    marginTop: -10,
    padding: 10,
  },
  mobTourUpperText: {
    flexDirection: "row",
    borderBottomColor: colors.tableHeader,
    borderBottomWidth: 1,
    gap: 20,
    paddingBottom: 10,
  },
  mobUpperTIRow: {
    flexDirection: "row",
    gap: 5,
    alignItems: "center",
  },
  mobTournamentHeadingText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 16,
  },
  mobTournamentText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansExtraLight",
    fontSize: 14,
  },
  mobBottomRow: {
    flexDirection: "row",
    paddingTop: 10,
    justifyContent: "space-between",
  },
  mobTournaBtnRow: {
    flexDirection: "row",
    gap: 20,
  },
  mobTournaBtn: {
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    gap: 10,
    padding: 4,
    height: 35,
    marginTop: 5,
  },
  mobTournaBtnText: {
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 14,
  },
});

const styles = StyleSheet.create({
  MTHeadingContainer: {
    flexDirection: screenWidth > 760 ? "row" : "column",
    justifyContent: "space-between",
    paddingBottom: screenWidth > 760 ? 0 : 10,
  },
  homeTournamentContainer: {
    // backgroundColor: "red",
    paddingVertical: 10,
    display: "flex",
    flexDirection:
      Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
    flexWrap: Platform.OS === "web" && screenWidth > 760 ? "wrap" : "nowrap",
    gap: 30,
    marginTop: 15,
    paddingBottom: Platform.OS !== "web" ? 200 : undefined,
  },
  filterContainer: {
    marginTop: Platform.OS === "web" && screenWidth > 760 ? 20 : 0,
    flexDirection: "row",
    gap: 15,
  },
  filterBtnText: {
    fontSize: 14,
    fontFamily: "PlusJakartaSansMedium",
    color: colors.white,
  },
  filterButton: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 6,
  },
  manageBtnWrapper: {
    backgroundColor: colors.aqua,
    paddingVertical: 4,
    paddingHorizontal: 10,
    borderRadius: 5,
  },
  manageBtnText: {
    fontFamily: "PlusJakartaSansRegular",
    color: colors.bgColor,
  },
  loginBtn: {
    backgroundColor: colors.aqua,
    borderColor: colors.aqua,
    borderWidth: 1,
    padding: 5,
    borderRadius: 10,
    fontSize: 14,
    width: 240,
    height: 32,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15,
  },
  loginBtnText: {
    color: colors.royalBlue,
    textAlign: "center",
    fontFamily: "PlusJakartaSansRegular",
  },
  noDataImage: {
    width: 270,
    height: 270,
  },
  noDataText: {
    fontFamily: "PlusJakartaSansSemiBold",
    color: colors.white,
    fontSize: 20,
    maxWidth: 400,
    textAlign: "center",
  },
  copyTextContainer: {
    backgroundColor: colors.bgColor,
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "hidden",
  },
  copyBtn: {
    color: colors.bgColor,
    backgroundColor: colors.aqua,
    borderRadius: 10,
    fontSize: 12,
    paddingHorizontal: 7,
    paddingVertical: 3,
    fontFamily: "PlusJakartaSansBold",
    textAlign: "center",
  },
  linkCopiedText: {
    position: "absolute",
    right: 10,
    top: screenWidth > 760 ? 35 : 105,
    color: colors.white,
    fontFamily: "PlusJakartaSansRegular",
    padding: 10,
    backgroundColor: colors.newBlue,
    borderRadius: 15,
  },
  searchBar: {
    width: Platform.OS === "web" && screenWidth > 760 ? 350 : "100%",
    height: Platform.OS === "web" && screenWidth > 760 ? 40 : 45,
    borderColor: colors.royalBlue,
    backgroundColor: colors.blue,
    marginTop: 20,
    color: colors.white,
  },
});

export default MyTournament;
