import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useState, useContext } from "react";
import {
  View,
  StyleSheet,
  Image,
  Text,
  Pressable,
  Dimensions,
  Platform,
} from "react-native";
// import { Button } from "react-native-elements";
import SearchBar from "../reusable/SearchBar";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { DataStore } from "aws-amplify/datastore";
import { UserMaster } from "../../models";
import { colors } from "../reusable/colour";
import { AuthenticationContext } from "../../authentication/Authentication";
import MenuIcon from "react-native-vector-icons/Ionicons";
// for mobile menu
import DashboardIcon from "react-native-vector-icons/MaterialIcons";
import ProfileIcon from "react-native-vector-icons/FontAwesome5";
import TrophyIcon from "react-native-vector-icons/FontAwesome5";
import TeamsIcon from "react-native-vector-icons/FontAwesome5";
import FlagIcon from "react-native-vector-icons/MaterialIcons";
import DollorIcon from "react-native-vector-icons/FontAwesome5";
import HelpIcon from "react-native-vector-icons/MaterialIcons";
import LogOutIcon from "react-native-vector-icons/MaterialIcons";
import { AreYouSureOrange } from "../reusable/AreYouSure";
import ZoomableImage from "../reusable/ZoomableImage";
//
const screenWidth = Dimensions.get("window").width;

const AdminTopNavbar = () => {
  const navigation = useNavigation();
  const [UserDetaildata, setUserDetaildata] = useState({});
  const [mobNavOpen, setMobNavOpen] = useState("false");
  const [SuccessModalVisible, setSuccessModalVisible] = useState(false);
  const [SunccessModalMesg, setSunccessModalMesg] = useState("");
  const { setIsLoggedIn } = useContext(AuthenticationContext);
  const handleMobNav = () => {
    setMobNavOpen(!mobNavOpen);
  };

  const getUserdata = async () => {
    let userDetails = await AsyncStorage.getItem("UserDetail");
    if (userDetails) {
      const userData = await DataStore.query(UserMaster, userDetails);
      console.log("userData", userData);
      setUserDetaildata(userData);
    } else {
      console.log("No user found in temp storage");
    }
  };
  const handleClose = () => {
    setSuccessModalVisible(false);
  };

  const handleConfirm = async () => {
    setSuccessModalVisible(false);
    // setActiveItem("Logout");
    try {
      await signOut().then(async (res) => {
        console.log("Signed Out", res);
        let sendData = {
          prevPage: "",
          nextPage: "",
          userId: "",
        };
        setIsLoggedIn(false);
        await AsyncStorage.clear();

        navigation.navigate("Home");
      });
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const handleLogout = async () => {
    setSuccessModalVisible(true);
    setSunccessModalMesg("Do you want to logout");
  };

  useEffect(() => {
    getUserdata();
  }, []);
  return (
    <View style={styles.navbarBgWrapper}>
      {UserDetaildata && (
        <View style={styles.container}>
          {screenWidth > 760 ? (
            <View style={styles.headerContainer}>
              <View>
                <SearchBar
                  placeholder={"Search"}
                  onSearch={undefined}
                  style={styles.topSearchBar}
                />
              </View>
              <Pressable style={styles.adminProfileWrapper}>
                {/* <Image
                  source={{ uri: UserDetaildata?.PhotoURL }}
                  style={styles.profileImage}
                /> */}

                <ZoomableImage
                  imageSource={{ uri: UserDetaildata?.PhotoURL }}
                  imageStyle={styles.profileImage}
                  modalStyle={undefined}
                />
                <View>
                  <Text style={styles.adminProfileName}>
                    {UserDetaildata.Name}
                  </Text>
                  <Text style={styles.adminRole}>Tournament Admin</Text>
                </View>
              </Pressable>
            </View>
          ) : (
            //
            <View style={{ position: "relative" }}>
              <View style={styles.mobNavbarButtonContainer}>
                <Pressable onPress={() => navigation.navigate("Home")}>
                  <View style={styles.logoContainerMob}>
                    <Image
                      source={require("../../../assets/logo/logo-w.png")}
                      style={styles.logo}
                      resizeMode="contain"
                    />
                  </View>
                </Pressable>
                <View
                  style={{
                    flexDirection: "row",
                    gap: 10,
                    alignItems: "center",
                  }}
                >
                  <ZoomableImage
                    imageSource={{ uri: UserDetaildata?.PhotoURL }}
                    imageStyle={styles.profileImage}
                    modalStyle={undefined}
                  />
                  <Pressable onPress={() => handleMobNav()}>
                    <MenuIcon
                      name={mobNavOpen ? "menu-outline" : "close"}
                      size={32}
                      color={colors.white}
                    />
                  </Pressable>
                </View>
              </View>
              <View
                style={[
                  styles.mobileNavList,
                  { display: mobNavOpen ? "none" : "flex" },
                ]}
              >
                <View style={styles.sidebar}>
                  <Pressable
                    style={[
                      styles.menuItem,
                      {
                        backgroundColor:
                          Platform.OS == "web"
                            ? location.pathname === "/Dashboard" ||
                              location.pathname === "/ManageTournament"
                              ? "#ffffff26"
                              : "transparent"
                            : undefined,
                      },
                    ]}
                    onPress={() => {
                      navigation.navigate("Dashboard");
                    }}
                  >
                    <DashboardIcon
                      name="dashboard"
                      size={22}
                      color={colors.white}
                    />

                    <Text style={styles.menuText}>Dashboard</Text>
                  </Pressable>

                  <Pressable
                    style={[
                      styles.menuItem,
                      {
                        backgroundColor:
                          Platform.OS == "web"
                            ? location.pathname === "/MyProfile"
                              ? "#ffffff26"
                              : "transparent"
                            : undefined,
                      },
                    ]}
                    onPress={() => {
                      navigation.navigate("MyProfile");
                    }}
                  >
                    <ProfileIcon
                      name="user-circle"
                      size={22}
                      color={colors.white}
                    />
                    <Text style={styles.menuText}>Profile</Text>
                  </Pressable>

                  <Pressable
                    style={[
                      styles.menuItem,
                      {
                        backgroundColor:
                          Platform.OS == "web"
                            ? location.pathname === "/MyTournament"
                              ? "#ffffff26"
                              : "transparent"
                            : undefined,
                      },
                    ]}
                    onPress={() => {
                      navigation.navigate("MyTournament");
                    }}
                  >
                    <TrophyIcon name="trophy" size={20} color={colors.white} />
                    <Text style={styles.menuText}>My Tournaments</Text>
                  </Pressable>

                  <Pressable
                    style={[
                      styles.menuItem,
                      {
                        backgroundColor:
                          Platform.OS == "web"
                            ? location.pathname === "/MyTeams"
                              ? "#ffffff26"
                              : "transparent"
                            : undefined,
                      },
                    ]}
                    onPress={async () => {
                      navigation.navigate("MyTeams");
                    }}
                  >
                    <ProfileIcon name="users" size={20} color={colors.white} />
                    <Text style={styles.menuText}>My Teams</Text>
                  </Pressable>
                  {/*  */}
                  <Pressable
                    style={[
                      styles.menuItem,
                      {
                        backgroundColor:
                          Platform.OS == "web"
                            ? location.pathname === "/MyMatches"
                              ? "#ffffff26"
                              : "transparent"
                            : undefined,
                      },
                    ]}
                    onPress={() => {
                      navigation.navigate("MyMatches");
                    }}
                  >
                    <FlagIcon name="sports" size={24} color={colors.white} />
                    <Text style={styles.menuText}>My Matches</Text>
                  </Pressable>

                  <Pressable
                    style={[
                      styles.menuItem,
                      {
                        backgroundColor:
                          Platform.OS == "web"
                            ? location.pathname === "/transaction"
                              ? "#ffffff26"
                              : "transparent"
                            : undefined,
                      },
                    ]}
                  >
                    <DollorIcon
                      name="search-dollar"
                      size={20}
                      color={colors.white}
                    />
                    <Text style={styles.menuText}>Transactions</Text>
                  </Pressable>

                  <Pressable
                    style={[
                      styles.menuItem,
                      {
                        backgroundColor:
                          Platform.OS == "web"
                            ? location.pathname === "/help"
                              ? "#ffffff26"
                              : "transparent"
                            : undefined,
                      },
                    ]}
                  >
                    <HelpIcon name="help" size={20} color={colors.white} />
                    <Text style={styles.menuText}>Help</Text>
                  </Pressable>

                  <Pressable
                    style={[
                      styles.menuItem,
                      {
                        backgroundColor:
                          Platform.OS == "web"
                            ? location.pathname === "/logout"
                              ? "#ffffff26"
                              : "transparent"
                            : undefined,
                      },
                    ]}
                    onPress={async () => {
                      handleLogout();
                    }}
                  >
                    <LogOutIcon name="logout" size={20} color={colors.white} />
                    <Text style={styles.menuText}>Logout</Text>
                  </Pressable>
                </View>
              </View>
            </View>
            //
          )}
        </View>
      )}
      {SuccessModalVisible == true ? (
        <AreYouSureOrange
          visibleModal={SuccessModalVisible}
          Message={SunccessModalMesg}
          ButtonText={"Logout"}
          onConfirm={handleConfirm}
          onCancel={handleClose}
        />
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  navbarBgWrapper: {
    width: "100%",
    height: screenWidth > 760 ? 60 : 40,
    top: 0,
    backgroundColor: colors.blue,
    position: "fixed",
    left: 0,
    zIndex: 9,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.3,
    shadowRadius: 10,
    paddingLeft: screenWidth > 760 ? 170 : 0,
  },
  container: {
    // maxWidth: 1320,
    width: "100%",
    height: "100%",
    marginHorizontal: "auto",
    paddingRight: screenWidth > 760 ? 50 : 10,
    paddingLeft: screenWidth > 760 ? 120 : 10,
  },
  headerContainer: {
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 80,
  },
  logoContainer: {
    width: 150,
    height: 60,
    paddingVertical: 5,
  },
  logoContainerMob: {
    width: screenWidth > 760 ? 235 : 90,
    height: screenWidth > 760 ? 60 : 40,
    paddingVertical: Platform.OS === "web" && screenWidth > 760 ? 5 : 3,
  },
  logo: {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
  },

  navRightContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-between",
  },
  rightButtonContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
    alignItems: "center",
  },
  bookAdemoBtn: {
    borderColor: "#000",
    borderWidth: 1,
    padding: 5,
    borderRadius: 10,
    width: 185,
  },
  bookAdemoBtnText: {
    color: "#000",
    textAlign: "center",
    fontFamily: "PlusJakartaSansSemiBold",
  },
  mobNavbarButtonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  adminProfileWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: 5,
    alignItems: "center",
    marginTop: 10,
    marginBottom: 15,
  },
  profileImage: {
    width: 40,
    height: 40,
    borderRadius: 30,
    resizeMode: "contain",
  },
  adminProfileName: {
    fontSize: 16,
    fontFamily: "PlusJakartaSansMedium",
    marginBottom: 3,
    color: colors.white,
  },
  adminRole: {
    fontSize: 12,
    fontFamily: "PlusJakartaSansRegular",
    color: colors.white,
  },
  topSearchBar: {
    width: 350,
    height: 40,
    outlineColor: "transparent",
    outlineWidth: 0,
    borderColor: colors.white,
    backgroundColor: colors.bgColor,
    color: colors.white,
  },

  // additional code for mobile menu
  mobileNavList: {
    position: "absolute",
    backgroundColor: colors.newBlue,
    left: 0,
    top: 40,
    width: "100%",
    boxShaddow: "0 0 10px #ffffff40",
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
    padding: 15,
  },
  menuItem: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 10,
    flexDirection: "row",
    gap: 5,
    flexDirection: "row",
    alignItems: "center",
  },
  menuText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
  },
});

export default AdminTopNavbar;
