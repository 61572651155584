import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Text,
  View,
  Image,
  StyleSheet,
  Platform,
  Dimensions,
  Pressable,
  Linking,
  Picker,
} from "react-native";
import { colors } from "../../../../components/reusable/colour";
import { DataStore } from "@aws-amplify/datastore";
import {
  MatchMaster,
  MatchMasterRegistration,
  MatchMasterUserMaster,
  MatchTeamMaster,
} from "../../../../models";
import { MatchStatus } from "../../../../models";
const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

const AddMatchModal = ({
  handleCancel,
  visibleModal,
  Gorupdata,
  RoundData,
  MatchCount,
  Teamlist,
  Tournamentdata,
  getTournament,
  Roundtype,
}) => {
  const [modalVisible, setModalVisible] = useState(visibleModal);
  const [grouplist, setGrouplist] = useState([]); //
  const [selectedgroup, setSelectedgroup]: any = useState({});
  const [team1, setTeam1] = useState([]);
  const [team2, setTeam2] = useState([]);
  const [selectedvalueTeam1, setSelectedvalueTeam1]: any = useState({});
  const [selectedvalueTeam2, setSelectedvalueTeam2]: any = useState({});
  const [allTeam, setAllTeam] = useState([]);
  const [tournamentdata, setTournamentdata]: any = useState({});

  useEffect(() => {
    console.log(
      "AddMatchModal",
      Tournamentdata,
      Teamlist,
      RoundData,
      Gorupdata,
      MatchCount
    );
    setAllTeam(Teamlist);
    setGrouplist(Gorupdata);
    setTournamentdata(Tournamentdata);
  }, [Gorupdata, RoundData, MatchCount, Tournamentdata, Teamlist, Roundtype]);

  const handleSelectGroup = (e, idx) => {
    setSelectedvalueTeam1({});
    setSelectedvalueTeam2({});
    console.log("handleSelectGroup", e, idx);
    let selectGroup = grouplist[idx];
    setSelectedgroup(selectGroup);
    let finaldata = grouplist.find((ele, index) => index == idx);
    console.log("finaldata", finaldata);
    setTeam1(finaldata.Teams);
    setTeam2(finaldata.Teams);
  };

  const handleSelectTeam1 = (e, idx) => {
    console.log("handleAddMatch");
    let selectTeam = team1[idx];
    setSelectedvalueTeam1(selectTeam);
    if (selectedvalueTeam2 && Object.keys(selectedvalueTeam2).length == 0) {
      let data = team2.filter((ele, index) => index !== idx);
      console.log("finaldata", data);
      setTeam2(data);
    }
  };
  const handleSelectTeam2 = (e, idx) => {
    let selectTeam = team2[idx];
    setSelectedvalueTeam2(selectTeam);
    if (selectedvalueTeam1 && Object.keys(selectedvalueTeam1).length == 0) {
      let data = team1.filter((ele, index) => index !== idx);
      console.log("finaldata", data);
      setTeam1(data);
    }
  };
  const handleSubmit = async () => {
    console.log(
      "handleSubmit",
      tournamentdata,
      allTeam,
      selectedvalueTeam1,
      selectedvalueTeam2,
      selectedgroup
    );

    let Team1 = allTeam.find((ele) => ele.ID === selectedvalueTeam1.Teamid);
    let Team2 = allTeam.find((ele) => ele.ID === selectedvalueTeam2.Teamid);
    console.log("Team1", Team1, "Team2", Team2);
    let TeamFirst = {
      TeamName: Team1.TeamName,
      LogoURL: Team1.Logo,
      TeamShortName: Team1.TeamShortN !== null ? Team1.TeamShortN : "",
      Score: {
        FirstHalf: 0,
        SecondHalf: 0,
        PenaltyShootout: 0,
        ExtraTime: 0,
        Walkover: false,
      },
      Players:
        tournamentdata.SubstitutionType == "ROLLING"
          ? Team1.playermasterList
          : [],
      TeamSubmitted:
        tournamentdata.SubstitutionType == "ROLLING" ? true : false,
    };
    let TeamSecond = {
      TeamName: Team2.TeamName,
      LogoURL: Team2.Logo.Team2,
      TeamSubmitted:
        tournamentdata.SubstitutionType == "ROLLING" ? true : false,
      TeamShortName: Team2.TeamShortN !== null ? Team2.TeamShortN : "",
      Score: {
        FirstHalf: 0,
        SecondHalf: 0,
        PenaltyShootout: 0,
        ExtraTime: 0,
        Walkover: false,
      },
      Players:
        tournamentdata.SubstitutionType == "ROLLING"
          ? Team2.playermasterList
          : [],
    };

    try {
      let team1Resp = await DataStore.save(new MatchTeamMaster(TeamFirst));
      let team2Resp = await DataStore.save(new MatchTeamMaster(TeamSecond));

      let MatchObj = {
        MatchNo: MatchCount + 1,
        groupsmasterID: selectedgroup.id,
        tournamentID: tournamentdata.id,
        GroupName: selectedgroup.GroupName,
        Team1: team1Resp,
        Team2: team2Resp,
        Status: MatchStatus.CREATED,
        OnlineScoring: false,
      };
      console.log("MatchObj", MatchObj);

      try {
        const MatchResponse = await DataStore.save(new MatchMaster(MatchObj));
        console.log("MatchResponse", MatchResponse);

        if (
          tournamentdata.Admin1 !== undefined &&
          tournamentdata.Admin1 !== null
        ) {
          await DataStore.save(
            new MatchMasterUserMaster({
              userMasterUUID: tournamentdata.Admin1.UUID,
              matchMasterId: MatchResponse.id,
            })
          );
        }
        if (
          tournamentdata.Admin2 !== undefined &&
          tournamentdata.Admin2 !== null
        ) {
          await DataStore.save(
            new MatchMasterUserMaster({
              userMasterUUID: tournamentdata.Admin2.UUID,
              matchMasterId: MatchResponse.id,
            })
          );
        }
        if (
          tournamentdata.Admin3 !== undefined &&
          tournamentdata.Admin3 !== null
        ) {
          await DataStore.save(
            new MatchMasterUserMaster({
              userMasterUUID: tournamentdata.Admin2.UUID,
              matchMasterId: MatchResponse.id,
            })
          );
        }

        await DataStore.save(
          new MatchMasterRegistration({
            teamRegistrationsId: Team1.ID,
            matchMasterId: MatchResponse.id,
          })
        );
        await DataStore.save(
          new MatchMasterRegistration({
            teamRegistrationsId: Team2.ID,
            matchMasterId: MatchResponse.id,
          })
        );

        let Teamfirst = await MatchResponse.Team1;
        let Teamsecond = await MatchResponse.Team2;
        let Team1Filtervalue = allTeam.filter(
          (ele) =>
            ele.TeamName.trim() == Teamfirst.TeamName.trim() ||
            ele.TeamName.trim() == Teamsecond.TeamName.trim()
        );
        console.log("TeamFilter", Team1Filtervalue);
        let RealtionTabledataTeam1 = [];
        Team1Filtervalue.forEach((obj) => {
          if (obj.Coachdata && Object.keys(obj.Coachdata).length > 0) {
            if (obj.Coachdata.id !== undefined) {
              RealtionTabledataTeam1.push({
                userMasterUUID: obj.Coachdata.id,
                matchMasterId: MatchResponse,
              });
            }
          }

          if (obj.Adminlist.length > 0) {
            obj.Adminlist.forEach((ele) => {
              if (ele.UUID !== undefined) {
                RealtionTabledataTeam1.push({
                  userMasterUUID: ele.UUID,
                  matchMasterId: MatchResponse.id,
                });
              }
            });
          }
          if (obj.Playerdata.length > 0) {
            obj.Playerdata.forEach((ele) => {
              if (ele.UUID !== undefined) {
                RealtionTabledataTeam1.push({
                  userMasterUUID: ele.UUID,
                  matchMasterId: MatchResponse.id,
                });
              }
            });
          }
        });
        console.log("RealtionTabledataTeam1", RealtionTabledataTeam1);
        RealtionTabledataTeam1 &&
          RealtionTabledataTeam1.length > 0 &&
          RealtionTabledataTeam1.forEach(async (obj) => {
            try {
              const Response2 = await DataStore.save(
                new MatchMasterUserMaster(obj)
              );
              console.log("responseData", Response2);

              getTournament(Roundtype);
            } catch (error) {
              console.log("error", error);
            }
          });
      } catch (error) {
        console.log("MatchMasterCreate", error);
      }
    } catch (error) {
      console.log("MatchTeamMasterCreate", error);
    }
  };

  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <Modal
        // animationType="slide"
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#4e4e4e80",
          }}
        >
          <View style={styles.modalView}>
            <View style={{ gap: 20 }}>
              <View>
                <Text style={styles.modalHeading}>Add Match</Text>
              </View>
              <View>
                <Text style={[styles.dropdownLabel, { fontSize: 18 }]}>
                  Select Group
                </Text>
                <Picker
                  style={styles.teamsDropdownContainer}
                  selectedValue={
                    selectedgroup && Object.keys(selectedgroup).length > 0
                      ? selectedgroup.GroupName
                      : ""
                  }
                  onValueChange={(itemValue, itemIndex) =>
                    handleSelectGroup(itemValue, itemIndex - 1)
                  }
                >
                  <Picker.Item label={"Select Group"} value={"Select Group"} />
                  {grouplist &&
                    grouplist.length > 0 &&
                    grouplist.map((ele) => {
                      return (
                        <Picker.Item
                          label={ele.GroupName}
                          value={ele.GroupName}
                          key={ele.id}
                        />
                      );
                    })}
                </Picker>
              </View>
              <View>
                <Text style={[styles.dropdownLabel, { fontSize: 18 }]}>
                  Select Team
                </Text>
                <View style={{ flexDirection: "row", gap: 15 }}>
                  <View>
                    <Text style={styles.dropdownLabel}>Team A</Text>
                    <Picker
                      style={styles.teamsDropdownContainer}
                      selectedValue={
                        selectedvalueTeam1 &&
                        Object.keys(selectedvalueTeam1).length > 0
                          ? selectedvalueTeam1.TeamName
                          : ""
                      }
                      onValueChange={(itemValue, itemIndex) =>
                        handleSelectTeam1(itemValue, itemIndex - 1)
                      }
                    >
                      <Picker.Item label="Select Team" value="team" />
                      {team1 &&
                        team1.length > 0 &&
                        team1.map((ele) => {
                          return (
                            <Picker.Item
                              label={ele.TeamName}
                              value={ele.TeamName}
                              key={ele.id}
                            />
                          );
                        })}
                    </Picker>
                  </View>
                  <View>
                    <Text style={styles.dropdownLabel}>Team B</Text>
                    <Picker
                      style={styles.teamsDropdownContainer}
                      selectedValue={
                        selectedvalueTeam2 &&
                        Object.keys(selectedvalueTeam2).length > 0
                          ? selectedvalueTeam2.TeamName
                          : ""
                      }
                      onValueChange={(itemValue, itemIndex) =>
                        handleSelectTeam2(itemValue, itemIndex - 1)
                      }
                    >
                      <Picker.Item label="Select Team" value="team" />
                      {team2 &&
                        team2.length > 0 &&
                        team2.map((ele) => {
                          return (
                            <Picker.Item
                              label={ele.TeamName}
                              value={ele.TeamName}
                              key={ele.id}
                            />
                          );
                        })}
                    </Picker>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.modalBottomPart}>
              {/* cancel button */}
              <Pressable style={styles.outlineButton} onPress={handleCancel}>
                <Text style={styles.buttonText}>Cancel</Text>
              </Pressable>
              <Pressable
                style={[
                  styles.orangeButton,
                  { backgroundColor: colors.aqua, borderColor: colors.aqua },
                ]}
                onPress={() => handleSubmit()}
              >
                <Text style={[styles.buttonText, { color: colors.royalBlue }]}>
                  Submit
                </Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  modalView: {
    width: screenWidth > 760 ? "100%" : "90%",
    maxHeight: 0.95 * screenHeight,
    maxWidth: 500,
    // minHeight: 200,
    margin: 10,
    backgroundColor: colors.bgColor,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    position: "relative",
    justifyContent: "center",
    gap: 30,
    padding: 25,
  },
  modalBottomPart: {
    // padding: 15,
    flexDirection: "row",
    gap: 10,
    justifyContent: "center",
  },
  outlineButton: {
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: colors.white,
    borderRadius: 10,
    height: 35,
    minWidth: 120,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 15,
    paddingVertical: 4,
  },
  buttonText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 16,
  },
  orangeButton: {
    backgroundColor: colors.orange,
    borderWidth: 1,
    borderColor: colors.orange,
    borderRadius: 10,
    height: 35,
    minWidth: 120,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 15,
    paddingVertical: 4,
  },
  teamsDropdownContainer: {
    width: screenWidth > 760 ? 220 : "100%",
    marginBottom: 0,
    height: 34,
    borderRadius: 5,
    backgroundColor: colors.royalBlue,
    color: colors.white,
  },
  dropdownLabel: {
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 14,
    color: colors.white,
    marginBottom: 5,
  },
  modalHeading: {
    color: colors.white,
    fontFamily: "PlusJakartaSansRegular",
    textAlign: "center",
    marginBottom: 15,
    fontSize: 18,
  },
});

export default AddMatchModal;
