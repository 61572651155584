import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  Pressable,
  Platform,
  ActivityIndicator,
  FlatList,
  ScrollView,
  Dimensions,
  Modal,
} from "react-native";

import CalendarIcon from "react-native-vector-icons/Feather";
import NextIcon from "react-native-vector-icons/Feather";
import LocationIcon from "react-native-vector-icons/Octicons";
import { useNavigation, useRoute } from "@react-navigation/native";
import { DataStore } from "aws-amplify/datastore";
import { Tournament } from "../../../../models";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { colors } from "../../../../components/reusable/colour";
import { formatDateShort } from "../../../../components/FormattedDate";
import { LinearGradient } from "expo-linear-gradient";
const OrgLogo: any = require("../../../../../assets/home/digitrack-cup.png");

const screenWidth = Dimensions.get("window").width;
const TournamentCard = ({
  filteredData,
  searchFlagValue,
  activeBadge,
  isFilteredDataEmpty,
  // level,
  pageReceived,
}) => {
  const [tournaments, setTournaments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tournamentsToShow, setTournamentsToShow] = useState([]);
  const [moreItemsToShow, setMoreItemsToShow] = useState(false);
  const [page, setPage] = useState(0);
  const [selectedId, setSelectedId] = useState();
  const [loading, setLoading] = useState(false);
  const [allTabTournaments, setAllTabTournaments] = useState([]); //ONLY USED FOR TOURNAMENTS STATUS IS NOT EUALS TO CREATED

  let tournamentID: any;
  // setPage(pageReceived);
  const defaultBanner: any = require("../../../../../assets/default/tournamentBanner.png");

  const handleHoverIn = (id: any) => {
    setSelectedId(id);
  };
  const handleHoverOut = (id: any) => {
    setSelectedId(null);
  };
  const route = useRoute();
  const navigation: any = useNavigation();
  const { level }: any = route.params || {};
  console.log("Input Level", level);

  const handleTournamentDetailsNavigation = async (tournamentId: any) => {
    // Use navigation.navigate without assigning its result to tournamentPropsObject
    navigation.navigate("TournamentDetails", {
      tournamentId: tournamentId,
      // initialTab: 5,
      // showResultPage: true,
      // showResultDetailsPage: false,
      // tournamentCardNavigation: true,
    });

    // Since navigation.navigate doesn't return anything, tournamentPropsObject will be undefined
    // You can create a separate object to store the values you need
    const tournamentPropsObject = {
      tournamentId: tournamentId,
      initialTab: 5,
      showResultPage: true,
      showResultDetailsPage: false,
      tournamentCardNavigation: true,
    };

    console.log("tournamentPropsObject", tournamentPropsObject);

    // Stringify and store the separate object
    await AsyncStorage.setItem(
      "tournamentProps",
      JSON.stringify(tournamentPropsObject)
    );
  };

  const handleUpcomingTournamentNavigation = async (tournamentId: any) => {
    // console.log("tournamentObj.id", tournamentObj.id);
    navigation.navigate("TournamentDetails", {
      tournamentId: tournamentId,
    });

    const upcomingTournamentPropsObject = {
      tournamentId: tournamentId,
    };
    console.log("upcomingTournamentPropsObject", upcomingTournamentPropsObject);

    await AsyncStorage.setItem(
      "upcomingTournamentProps",
      JSON.stringify(upcomingTournamentPropsObject)
    );
  };

  const navigateToDetails = async (index: number) => {
    console.log(
      "index",
      index,
      "filteredData",
      filteredData,
      "searchFlagValue",
      searchFlagValue
    );

    let tournamentDetailsObj = tournamentsToShow[index];
    console.log("tournamentDetailsObj", tournamentDetailsObj);
    tournamentID = tournamentDetailsObj.id;
    console.log("tournamentID", tournamentID);
    // setSelectedTournamentID(tournamentID);

    const tournamentDetails = await tournamentDetailsObj;
    const roundsPromise = await tournamentDetails.Rounds;
    const roundsDataPromise = roundsPromise.values;
    const roundsData = await roundsDataPromise;
    const roundsDataIndexValue = roundsData[0];
    const fixturesCreatedFlag = roundsDataIndexValue
      ? roundsDataIndexValue.FixturesCreated
      : null;
    // setFixturesCreated(fixturesCreatedFlag);
    console.log(
      "Resolved Rounds Data:",
      roundsDataIndexValue,
      "fixturesCreatedFlag",
      fixturesCreatedFlag
    );

    // Check if matchMasterData is empty
    if (fixturesCreatedFlag === true) {
      console.log("fixturesCreated is true");
      await handleTournamentDetailsNavigation(tournamentID);
    } else {
      console.log("matchMasterData length is greater than 0");
      await handleUpcomingTournamentNavigation(tournamentID);
    }
  };

  const loadMoreItems = () => {
    try {
      setPage(page + 1);
      const noOfTournaments = 15 + (page + 1) * 15;
      if (activeBadge === "All") {
        // allTabTournaments is used for tournaments which are excluding created status in 'All' activeTab

        if (level && level.toLowerCase() !== "all") {
          const tournamentsByLevel = allTabTournaments.filter(
            (tournament: Tournament) => {
              if (!tournament.Level) return false;

              const regex = new RegExp(`\\b${level.toLowerCase()}\\b`, "i");
              return regex.test(tournament.Level.toLowerCase());
            }
          );
          console.log("tournamentsByLevel", tournamentsByLevel, "level", level);
          setTournamentsToShow(tournamentsByLevel.slice(0, noOfTournaments));

          if (noOfTournaments < tournamentsByLevel.length) {
            setMoreItemsToShow(true);
          } else {
            setMoreItemsToShow(false);
          }
        } else {
          setTournamentsToShow(allTabTournaments.slice(0, noOfTournaments));

          if (noOfTournaments < allTabTournaments.length) {
            setMoreItemsToShow(true);
          } else {
            setMoreItemsToShow(false);
          }
        }
      } else if (activeBadge === "Upcoming") {
        const upcomingTournaments = tournaments.filter(
          (tournament: any) => tournament.Status === "SCHEDULED"
        );
        const upcomingCount = upcomingTournaments.length;
        console.log("UpcomingCount : ", upcomingCount);
        setTournamentsToShow(
          upcomingTournaments.slice(
            0,
            upcomingCount <= noOfTournaments ? upcomingCount : noOfTournaments
          )
        );

        if (noOfTournaments < upcomingCount) {
          setMoreItemsToShow(true);
        } else {
          setMoreItemsToShow(false);
        }
      } else if (activeBadge === "Completed") {
        const completedTournaments = tournaments.filter(
          (tournament: any) => tournament.Status === "COMPLETED"
        );
        const completedCount = completedTournaments.length;
        console.log("completedCount : ", completedCount);
        setTournamentsToShow(
          completedTournaments.slice(
            0,
            completedCount <= noOfTournaments ? completedCount : noOfTournaments
          )
        );

        if (noOfTournaments < completedCount) {
          setMoreItemsToShow(true);
        } else {
          setMoreItemsToShow(false);
        }
      } else if (activeBadge === "InProgress") {
        const inProgressTournaments = tournaments.filter(
          (tournament: any) => tournament.Status === "INPROGRESS"
        );
        const inProgressCount = inProgressTournaments.length;
        console.log("inProgressCount : ", inProgressCount);
        setTournamentsToShow(
          inProgressTournaments.slice(
            0,
            inProgressCount <= noOfTournaments
              ? inProgressCount
              : noOfTournaments
          )
        );

        if (noOfTournaments < inProgressCount) {
          setMoreItemsToShow(true);
        } else {
          setMoreItemsToShow(false);
        }
      }
    } catch (error) {}
  };
  const filterByLevel = (filteredArray, noOfTournaments) => {
    if (level && level.toLowerCase() !== "all") {
      const tournamentsByLevel = filteredArray.filter(
        (tournament: Tournament) => {
          if (!tournament.Level) return false;

          const regex = new RegExp(`\\b${level.toLowerCase()}\\b`, "i");
          return regex.test(tournament.Level.toLowerCase());
        }
      );
      console.log("tournamentsByLevel", tournamentsByLevel, "level", level);
      setTournamentsToShow(tournamentsByLevel.slice(0, noOfTournaments));

      if (noOfTournaments < tournamentsByLevel.length) {
        setMoreItemsToShow(true);
      } else {
        setMoreItemsToShow(false);
      }
    } else {
      setTournamentsToShow(filteredArray.slice(0, noOfTournaments));
      if (noOfTournaments < filteredArray.length) {
        setMoreItemsToShow(true);
      } else {
        setMoreItemsToShow(false);
      }
    }
  };
  const renderTournamentsData = async () => {
    try {
      setLoading(true);
      console.log("searchFlagValue", searchFlagValue);
      console.log("isFilteredDataEmpty", isFilteredDataEmpty);

      if (searchFlagValue) {
        if (filteredData && filteredData.length > 0) {
          setTournaments(filteredData);
          console.log("filteredData", filteredData);
          const noOfTournaments = 15 + page * 15;

          setTournamentsToShow(filteredData.slice(0, noOfTournaments));

          if (filteredData.length > 15) {
            setMoreItemsToShow(true);
          } else {
            setMoreItemsToShow(false);
          }
          console.log("All done");
          setLoading(false);
        }
      } else {
        setLoading(true);
        const subscription = DataStore.observeQuery(Tournament).subscribe(
          async (snapshot) => {
            const initialTournaments = snapshot.items;

            const filteredTournaments = initialTournaments.filter(
              (data) =>
                data.Status !== null &&
                data.Status !== "Created" &&
                data.Status !== "CREATED"
            );
            console.log("filteredTournaments", filteredTournaments);
            setAllTabTournaments(filteredTournaments);
            let noOfTournaments;
            // pagination
            console.log("pageReceived", pageReceived);
            if (pageReceived == 0) {
              noOfTournaments = 15 + pageReceived * 15;
              console.log(
                "no of tournaments in page received",
                noOfTournaments
              );
            } else {
              noOfTournaments = 15 + page * 15;
              console.log("no of tournaments", noOfTournaments);
            }
            // setTournamentsToShow(snapshot.items.slice(0, noOfTournaments));
            setTournaments(initialTournaments);
            if (activeBadge === "All") {
              // filteredTournaments is used for tournaments which are excluding created status in 'All' activeTab
              filterByLevel(filteredTournaments, noOfTournaments);
              // Upcoming tab started
            } else if (activeBadge === "Upcoming") {
              const upcomingTournaments = filteredTournaments.filter(
                (tournament: any) => tournament.Status === "SCHEDULED"
              );
              const upcomingCount = upcomingTournaments.length;
              console.log("UpcomingCount : ", upcomingCount);
              filterByLevel(upcomingTournaments, noOfTournaments);

              // Completed tab
            } else if (activeBadge === "Completed") {
              const completedTournaments = filteredTournaments.filter(
                (tournament: any) => tournament.Status === "COMPLETED"
              );
              const completedCount = completedTournaments.length;
              console.log("completedCount : ", completedCount);
              filterByLevel(completedTournaments, noOfTournaments);

              // InProgress tab
            } else if (activeBadge === "InProgress") {
              const inProgressTournaments = filteredTournaments.filter(
                (tournament: any) => tournament.Status === "INPROGRESS"
              );
              const inProgressCount = inProgressTournaments.length;
              console.log("inProgressCount : ", inProgressCount);
              filterByLevel(inProgressTournaments, noOfTournaments);
            }
          }
        );
        setLoading(false);

        return () => {
          subscription.unsubscribe();
        };
      }
    } catch (error) {
      setLoading(false);
      console.log("errror occured while fetching the data", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log("level", level);
    renderTournamentsData();
  }, [filteredData, searchFlagValue, activeBadge, isFilteredDataEmpty, level]);

  return (
    <>
      {/* WEB VIEW  */}
      {Platform.OS === "web" && screenWidth > 760 ? (
        <View style={{ paddingVertical: 20, backgroundColor: colors.bgColor }}>
          {!isFilteredDataEmpty ? (
            <View style={stylesWeb.container}>
              <View
                style={[
                  stylesWeb.homeTournamentContainer,
                  {
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    gap: 20,
                  },
                ]}
              >
                {tournamentsToShow && tournamentsToShow.length > 0 ? (
                  tournamentsToShow.map((data, index) => (
                    <>
                      <Pressable
                        style={[
                          styles.homeTournamentCard,
                          {
                            marginHorizontal: 0,
                            marginTop: selectedId == data.id ? -10 : 0,
                          },
                        ]}
                        onPress={() => navigateToDetails(index)}
                        onHoverIn={() => handleHoverIn(data.id)}
                        onHoverOut={() => handleHoverOut(data.id)}
                        key={index}
                      >
                        <LinearGradient
                          style={{ paddingBottom: 20, borderRadius: 15 }}
                          colors={
                            selectedId == data.id
                              ? [colors.orange, colors.blue]
                              : [colors.royalBlue, colors.royalBlue]
                          }
                          start={{ x: 0, y: 0 }}
                          end={{ x: 0, y: 1 }}
                        >
                          <View
                            style={[
                              styles.badgeContainer,
                              {
                                backgroundColor:
                                  data.Status === "Created"
                                    ? "#140759"
                                    : data.Status === "SCHEDULED"
                                    ? "#e99400"
                                    : data.Status === "INPROGRESS"
                                    ? "#21d448"
                                    : data.Status === "COMPLETED"
                                    ? "#ababab"
                                    : null,
                              },
                            ]}
                          >
                            <Text style={styles.badgetText}>
                              {data.Status ? data.Status : null}
                            </Text>
                          </View>
                          <View style={[styles.badgeContainer2]}>
                            <Text style={styles.badgetText}>
                              {data
                                ? data.Level > ""
                                  ? data.Level
                                  : "Open"
                                : "Open"}
                            </Text>
                          </View>
                          <Image
                            source={{
                              uri: data.Banner ? data.Banner : defaultBanner,
                            }}
                            // source={require("../../../../../assets/home/tournamentBanner.jpg")}
                            style={styles.TournamentBannerImage}
                          />
                          <View style={styles.TournamentCardContentWrapper}>
                            <View style={{ paddingHorizontal: 10 }}>
                              <Text
                                style={styles.tournamentCardHeading}
                                numberOfLines={2}
                              >
                                {data ? data.Name : null}
                              </Text>
                            </View>
                            <View style={styles.rowContainerWrapper}>
                              <CalendarIcon
                                name="calendar"
                                size={16}
                                color={colors.white}
                              />
                              <Text style={styles.tournamentCardText}>
                                {`${
                                  data ? formatDateShort(data.StartDate) : null
                                } - ${
                                  data ? formatDateShort(data.EndDate) : null
                                }`}
                              </Text>
                            </View>
                            <View style={styles.rowContainerWrapper}>
                              <LocationIcon
                                name="location"
                                size={16}
                                color={colors.white}
                              />
                              <Text style={styles.tournamentCardText}>
                                {/* {data ? data.Venues[0] : null}, */}
                                {data ? data.City : null}
                              </Text>
                            </View>
                          </View>
                          <View style={styles.seeDetailsContainer}>
                            <View style={[styles.tournamentCardButton]}>
                              <Text
                                style={[
                                  styles.tournamentCardButtonText,
                                  {
                                    color:
                                      selectedId == data.id
                                        ? colors.orange
                                        : colors.aqua,
                                  },
                                ]}
                              >
                                View Details
                              </Text>
                              <NextIcon
                                name="chevrons-right"
                                size={20}
                                color={
                                  selectedId == data.id
                                    ? colors.orange
                                    : colors.aqua
                                }
                              />
                            </View>
                          </View>
                        </LinearGradient>
                      </Pressable>
                    </>
                  ))
                ) : (
                  <Text
                    style={{
                      justifyContent: "center",
                      textAlign: "center",
                      fontFamily: "PlusJakartaSansSemiBold",
                      fontSize: 20,
                      color: colors.white,
                    }}
                  >
                    Oops! There are no tournaments to show...
                  </Text>
                )}

                <View style={{ width: "100%", alignItems: "center" }}>
                  {isLoading ? (
                    <ActivityIndicator size="large" color="#aaa" />
                  ) : moreItemsToShow ? (
                    <Pressable
                      style={stylesLazyLoading.buttonContainer}
                      onPress={loadMoreItems}
                    >
                      <Text
                        style={{
                          color: colors.white,
                          fontFamily: "PlusJakartaSansSemiBold",
                          fontSize: 16,
                        }}
                      >
                        View More
                      </Text>
                      {/* <Icon2 name="arrowdown" size={30} color="white" /> */}
                    </Pressable>
                  ) : null}
                  {!moreItemsToShow && tournamentsToShow.length > 0 && (
                    <View style={{ width: "100%" }}>
                      <Text
                        style={{
                          justifyContent: "center",
                          textAlign: "center",
                          fontFamily: "PlusJakartaSansSemiBold",
                          fontSize: 16,
                          color: colors.white,
                        }}
                      >
                        This is all we have for now! Keep checking...
                      </Text>
                    </View>
                  )}
                </View>
              </View>
            </View>
          ) : (
            <Text style={{ color: "white", textAlign: "center" }}>
              No results found
            </Text>
          )}
        </View>
      ) : (
        // MOBILE VIEW
        <View style={{ paddingBottom: 20, backgroundColor: colors.bgColor }}>
          {!isFilteredDataEmpty ? (
            <View style={stylesWeb.container}>
              <ScrollView>
                <View
                  style={[
                    stylesWeb.homeTournamentContainer,
                    {
                      // backgroundColor: "white",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      gap: 15,
                      // height: 600,
                    },
                  ]}
                >
                  {tournamentsToShow && tournamentsToShow.length > 0 ? (
                    tournamentsToShow.map((data, index) => (
                      <>
                        {/* <Pressable
                        style={[
                          styles.homeTournamentCard,
                          {
                            marginHorizontal: 0,
                            marginTop: selectedId == data.id ? -10 : 0,
                            backgroundColor:
                              selectedId == data.id ? "#135082" : colors.blue,
                          },
                        ]}
                        onPress={() => navigateToDetails(index)}
                        onHoverIn={() => handleHoverIn(data.id)}
                        onHoverOut={() => handleHoverOut(data.id)}
                        key={index}
                      > */}

                        <Pressable
                          style={[
                            styles.homeTournamentCard,
                            {
                              marginHorizontal: 0,
                              marginTop: selectedId == data.id ? -10 : 0,
                            },
                          ]}
                          onPress={() => navigateToDetails(index)}
                          onHoverIn={() => handleHoverIn(data.id)}
                          onHoverOut={() => handleHoverOut(data.id)}
                          key={index}
                        >
                          <LinearGradient
                            style={{ paddingBottom: 20, borderRadius: 15 }}
                            colors={
                              selectedId == data.id
                                ? [colors.orange, colors.blue]
                                : [colors.royalBlue, colors.royalBlue]
                            }
                            start={{ x: 0, y: 0 }}
                            end={{ x: 0, y: 1 }}
                          >
                            <View
                              style={[
                                styles.badgeContainer,
                                {
                                  backgroundColor:
                                    data.Status === "Created"
                                      ? "#140759"
                                      : data.Status === "SCHEDULED"
                                      ? "#e99400"
                                      : data.Status === "INPROGRESS"
                                      ? "#21d448"
                                      : data.Status === "COMPLETED"
                                      ? "#ababab"
                                      : null,
                                },
                              ]}
                            >
                              <Text style={styles.badgetText}>
                                {data.Status ? data.Status : null}
                              </Text>
                            </View>
                            <View style={[styles.badgeContainer2]}>
                              <Text style={styles.badgetText}>
                                {data
                                  ? data.Level > ""
                                    ? data.Level
                                    : "Open"
                                  : "Open"}
                              </Text>
                            </View>
                            <Image
                              source={{
                                uri: data ? data.Banner : defaultBanner,
                              }}
                              // source={require("../../../../../assets/home/tournamentBanner.jpg")}
                              style={styles.TournamentBannerImage}
                            />
                            <View style={styles.TournamentCardContentWrapper}>
                              <View style={{ paddingHorizontal: 10 }}>
                                <Text
                                  style={styles.tournamentCardHeading}
                                  numberOfLines={2}
                                >
                                  {data ? data.Name : null}
                                </Text>
                              </View>
                              <View style={styles.rowContainerWrapper}>
                                <CalendarIcon
                                  name="calendar"
                                  size={20}
                                  color={colors.white}
                                />
                                <Text style={styles.tournamentCardText}>
                                  {`${
                                    data
                                      ? formatDateShort(data.StartDate)
                                      : null
                                  } - ${
                                    data ? formatDateShort(data.EndDate) : null
                                  }`}
                                </Text>
                              </View>
                              <View style={styles.rowContainerWrapper}>
                                <LocationIcon
                                  name="location"
                                  size={20}
                                  color={colors.white}
                                />
                                <Text style={styles.tournamentCardText}>
                                  {/* {data ? data.Venues[0] : null}, */}
                                  {data ? data.City : null}
                                </Text>
                              </View>
                            </View>
                            <View style={styles.seeDetailsContainer}>
                              <View style={[styles.tournamentCardButton]}>
                                <Text
                                  style={[
                                    styles.tournamentCardButtonText,
                                    {
                                      color:
                                        selectedId == data.id
                                          ? colors.orange
                                          : colors.aqua,
                                    },
                                  ]}
                                >
                                  View Details
                                </Text>
                                <NextIcon
                                  name="chevrons-right"
                                  size={20}
                                  color={
                                    selectedId == data.id
                                      ? colors.orange
                                      : colors.aqua
                                  }
                                />
                              </View>
                            </View>
                          </LinearGradient>
                        </Pressable>
                      </>
                    ))
                  ) : (
                    <Text style={{ color: "white" }}>
                      No tournaments to show
                    </Text>
                  )}

                  <View style={{ width: "100%", alignItems: "center" }}>
                    {isLoading ? (
                      <ActivityIndicator size="large" color="#aaa" />
                    ) : moreItemsToShow ? (
                      <Pressable
                        style={stylesLazyLoading.buttonContainer}
                        onPress={loadMoreItems}
                      >
                        <Text
                          style={{
                            justifyContent: "center",
                            textAlign: "center",
                            fontFamily: "PlusJakartaSansSemiBold",
                            fontSize: 16,
                            color: colors.royalBlue,
                          }}
                        >
                          View More
                        </Text>
                        {/* <Icon2 name="arrowdown" size={20} color="white" /> */}
                      </Pressable>
                    ) : null}
                  </View>
                  {!moreItemsToShow && tournamentsToShow.length > 0 && (
                    <View style={{ width: "100%" }}>
                      <Text
                        style={{
                          justifyContent: "center",
                          textAlign: "center",
                          fontFamily: "PlusJakartaSansExtraLight",
                          fontSize: 16,
                          color: colors.white,
                        }}
                      >
                        There are no more tournaments.
                      </Text>
                    </View>
                  )}

                  {/* loader */}

                  {loading ? (
                    <Modal transparent animationType="none">
                      <View
                        style={{
                          flex: 1,
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        <ActivityIndicator size="large" color="#0000ff" />
                        <Text style={{ color: "white" }}>Loading</Text>
                      </View>
                    </Modal>
                  ) : null}
                </View>
              </ScrollView>
            </View>
          ) : (
            <Text style={{ color: "white", textAlign: "center" }}>
              No results found
            </Text>
          )}
        </View>
      )}
    </>
  );
};

export default TournamentCard;

const styles = StyleSheet.create({
  TournamentBannerImage: {
    width: "100%",
    height: 150,
    objectFit: "contain",
    borderRadius: 15,
  },
  organizerLogoStyle: {
    width: 25,
    height: 25,
    objectFit: "contain",
    borderRadius: 15,
  },

  homeTournamentCard: {
    flex: 1,
    maxWidth: screenWidth > 760 ? "25%" : "100%",
    minWidth: screenWidth > 760 ? "23%" : "100%",
    borderStyle: "solid",
    position: "relative",
    marginHorizontal: 15,
  },
  TournamentCardContentWrapper: {
    padding: 10,
    paddingHorizontal: 0,
    gap: 4,
  },

  TournamentCardHeadingWrapper: {
    backgroundColor: colors.white,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 10,
    shadowColor: "#00000026",
    shadowOpacity: 1,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    marginTop: -20,
    borderRadius: 15,
    marginBottom: 15,
  },

  CardHeadingStyle: {
    textAlign: "center",
    fontSize: 20,
    textTransform: "uppercase",
    fontWeight: "600",
    color: "#f26722",
    marginBottom: 10,
  },
  CardOrganizerName: {
    textAlign: "center",
    fontSize: 15,
    fontWeight: "500",
    color: "#000",
  },
  cardSmallDetailsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 10,
    paddingRight: 0,
    paddingBottom: 10,
    paddingLeft: 0,
    borderTopWidth: 1,
    borderTopColor: "#e3a98d",
    // borderTopStyle: "solid",
  },
  cardDetailsText: {
    fontSize: 14,
    fontWeight: "600",
  },
  buttonContainer: {
    backgroundColor: "#2a4883",
    paddingVertical: 15,
    borderRadius: 10,
    alignItems: "center",
    position: "absolute",
    width: "100%",
    bottom: 0,
  },
  buttonText: {
    color: colors.white,
    fontSize: 18,
    fontWeight: "bold",
  },
  container: {
    maxWidth: 1320,
    width: "100%",
    marginHorizontal: "auto",
    paddingRight: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
    paddingLeft: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
  },
  homeTournamentContainer: {
    paddingVertical: 10,
    display: "flex",
    flexDirection: "row",
    gap: 15,
    overflow: "scroll",
    rowGap: 16,
    marginTop: 25,
  },
  container123: {
    flex: 1,
    backgroundColor: colors.white,
  },
  scrollContainer: {
    flexDirection: "row",
  },
  tournamentCardBadge: {
    // backgroundColor: "#FDDCD5",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  tournamentCardBadgeText: {
    fontFamily: "PlusJakartaSansSemiBold",
    backgroundColor: "#FDDCD5",
    paddingVertical: 3,
    paddingHorizontal: 5,
    borderRadius: 5,
    fontSize: 12,
  },
  tournamentCardHeading: {
    fontFamily: "PlusJakartaSansRegular",
    color: colors.white,
    fontSize: 16,
    // marginTop: 8,
  },
  rowContainerWrapperMain: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  rowContainerWrapper: {
    flexDirection: "row",
    gap: 5,
    alignItems: "center",
    marginTop: 5,
    paddingHorizontal: 10,
  },
  tournamentCardText: {
    fontFamily: "PlusJakartaSansExtraLight",
    fontSize: 12,
    color: colors.white,
  },
  tournamentCardButton: {
    width: "100%",
    borderRadius: 10,
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 6,
    flexDirection: "row",
  },
  tournamentCardButtonText: {
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 15,
  },
  badgeContainer: {
    position: "absolute",
    left: -2,
    top: -2,
    padding: 5,
    borderRadius: 5,
    zIndex: 999,
  },
  badgeContainer2: {
    backgroundColor: colors.orange,
    position: "absolute",
    right: -2,
    top: -2,
    padding: 5,
    borderRadius: 5,
    zIndex: 999,
  },
  badgetText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 14,
  },
  seeDetailsContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    position: "absolute",
    bottom: 10,
    left: 0,
  },
});

const stylesWeb = StyleSheet.create({
  container: {
    maxWidth: 1320,
    width: "100%",
    marginHorizontal:
      Platform.OS === "web" && screenWidth > 760 ? "auto" : null,
    paddingRight: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
    paddingLeft: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
  },
  scrollView: {
    flex: 1,
    marginTop: 10, // Add top margin for spacing
  },
  searchBar: {
    backgroundColor: "#eeeeee",
    width: Platform.OS === "web" && screenWidth > 760 ? 350 : "100%",
    borderColor: "#0000",
    borderBottomWidth: 0,
    borderTopWidth: 0,
  },
  inputContainer: {
    backgroundColor: "#ddd0ec",
    height: 35,
    fontSize: 14,
  },
  homeTournamentContainer: {
    paddingVertical: 0,
    display: "flex",
    flexDirection: "column",
  },
});

const stylesLazyLoading = StyleSheet.create({
  itemWrapperStyle: {
    flexDirection: "row",
    paddingHorizontal: 16,
    paddingVertical: 16,
    borderBottomWidth: 1,
    borderColor: "#ddd",
  },
  itemImageStyle: {
    width: 50,
    height: 50,
    marginRight: 16,
  },
  contentWrapperStyle: {
    justifyContent: "space-around",
  },
  txtNameStyle: {
    fontSize: 16,
  },
  txtEmailStyle: {
    color: "#777",
  },
  loaderStyle: {
    marginVertical: 16,
    alignItems: "center",
  },
  buttonContainer: {
    backgroundColor: colors.orange,
    paddingVertical: 15,
    borderRadius: 10,
    marginTop: 15,
    alignItems: "center",
    width: 200,
    justifyContent: "center",
    height: 40,
    flexDirection: "row",
    gap: 10,
  },
  buttonText: {
    color: colors.white,
    fontSize: 16,
    fontWeight: "bold",
    justifyContent: "center",
  },
  width100: {
    width: "50%",
  },
});
