import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Platform,
  Pressable,
  Dimensions,
  ScrollView,
  Modal,
  ActivityIndicator,
} from "react-native";
import {
  useNavigation,
  useNavigationState,
  useRoute,
} from "@react-navigation/native";
import { CommonHeading } from "../../../components/reusable/TextComponent";
import { colors } from "../../../components/reusable/colour";
import SearchBar from "../../../components/reusable/SearchBar";
import TournamentCard from "../Home/SubComp/TournamentCard";
import Layout from "../../../components/include/Layout";
import { DataStore } from "aws-amplify/datastore";
import { Tournament } from "../../../models";
import { Picker } from "@react-native-picker/picker";
import { white } from "react-native-paper/lib/typescript/styles/themes/v2/colors";
import FilterIcon from "react-native-vector-icons/Feather";

const screenWidth = Dimensions.get("window").width;

const AllTournament = () => {
  const [filteredData, setFilteredData] = useState([]); // Initialize with the API data
  const [search, setSearch] = useState("");
  const [tournamentData, setTournamentData] = useState([]);
  const [searchFlag, setSearchFlag] = useState(false);
  const [activeBadge, setActiveBadge] = useState("All");
  const [activeTab, setActiveTab] = useState("All");
  const [page, setPage] = useState(0);
  const [activeTabLevel, setActiveTabLevel] = useState("");
  const [searchBarText, setSearchBarText] = useState("");
  const [noResultsFoundFlag, setNoResultsFoundFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [filterShow, setFilterShow] = useState(false);

  const route = useRoute();
  const navigation: any = useNavigation();
  console.log("ROuter parms", route.params);
  const { level }: any = route.params || {};
  console.log("Input Level", level);

  const handleSearch = (text: any) => {
    if (!text) {
      setSearchBarText("");
      setFilteredData(tournamentData); // Reset to the original data
      setSearchFlag(false); // Set search flag to false when there is no active search
    } else {
      console.log("filtertext", text);
      const filteredResults = tournamentData.filter((item) => {
        // Loop through all attributes of the item and check for a match
        for (const key in item) {
          // console.log("key", key, "value", item[key]);
          if (
            (typeof item[key] === "string" || typeof item[key] === "number") &&
            item[key].toString().toLowerCase().includes(text.toLowerCase())
          ) {
            return true; // If any attribute matches, return true
          }
        }
        return false; // If none of the attributes match, return false
      });
      console.log("filtered results", filteredResults);
      if (filteredResults.length === 0) {
        setNoResultsFoundFlag(true);
      } else {
        setNoResultsFoundFlag(false);
      }

      setFilteredData(filteredResults);
      setSearchFlag(true);
      setPage(0);
      setActiveBadge("All");
      setActiveTab("All");
      setActiveTabLevel("all");
    }
    setSearch(text); // Set the search text in all cases
  };

  const handleFilterChange = (tab: any) => {
    setActiveBadge(tab);
    setActiveTab(tab);
    setPage(0);
    setFilterShow(false);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const subscription = await DataStore.observeQuery(Tournament).subscribe(
        async (snapshot) => {
          console.log("snapshot", snapshot);
          if (snapshot.isSynced === true) {
            const initialTournaments: any = snapshot.items;
            console.log("initialTournaments", initialTournaments);

            const filteredTournaments = initialTournaments.filter(
              (data: any) =>
                data.Status !== null && data.Status.toLowerCase() !== "created"
            );
            if (level !== "" || level !== null) {
              const filteredTournamentsbyLevel = filteredTournaments.filter(
                (tournament: Tournament) => {
                  if (!tournament.Level) return false;

                  const regex = new RegExp(
                    `\\b${
                      selectedValue ? selectedValue : level.toLowerCase()
                    }\\b`,
                    "i"
                  );
                  return regex.test(tournament.Level.toLowerCase());
                }
              );
              console.log(
                "filteredTournamentsbyLevel",
                filteredTournamentsbyLevel
              );
              setTournamentData(filteredTournamentsbyLevel);
            } else {
              console.log("filteredTournaments", filteredTournaments);
              setTournamentData(filteredTournaments);
            }
          }
        }
      );
      setLoading(false);
      return () => {
        subscription.unsubscribe();
      };
    } catch (error) {
      console.log("error occured while fetching the data", error);
    }
  };

  const handleLevelChange = (value: any) => {
    console.log("selectedFilterByLevel:", value);
    setSelectedValue(value);
    setActiveTabLevel(value);
    setFilterShow(false);
    setPage(0);
    navigation.navigate("AllTournament", { level: value });
  };

  const handleFilterShow = () => {
    setFilterShow(!filterShow);
    console.log(filterShow);
  };

  useEffect(() => {
    // setSelectedValue(level);
    if (level) {
      setSelectedValue(level);
      setActiveTabLevel(level);
    } else {
      console.log("No level found");
    }

    fetchData();
  }, []);

  return (
    <Layout>
      <View
        style={{
          backgroundColor: colors.bgColor,
          padding: Platform.OS === "web" && screenWidth > 760 ? 10 : undefined,
          paddingBottom:
            Platform.OS === "web" && screenWidth > 760 ? undefined : 10,
        }}
      >
        <View style={styles.container}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {Platform.OS === "web" && screenWidth > 760 && (
              <CommonHeading props={"Tournaments"}></CommonHeading>
            )}
            <SearchBar
              placeholder="Search"
              onSearch={handleSearch}
              style={styles.searchBar}
            />
          </View>

          {screenWidth < 760 ? (
            <View style={{ alignItems: "flex-start", marginTop: 10 }}>
              <Pressable
                onPress={() => handleFilterShow()}
                style={{
                  flexDirection: "row",
                  gap: 10,
                  backgroundColor: colors.aqua,
                  paddingVertical: 5,
                  paddingHorizontal: 10,
                  borderRadius: 7,
                }}
              >
                <Text
                  style={{
                    color: colors.royalBlue,
                    fontSize: 16,
                    fontFamily: "'PlusJakartaSansSemiBold'",
                  }}
                >
                  Filter
                </Text>
                <FilterIcon name="filter" size={18} color={colors.royalBlue} />
              </Pressable>
            </View>
          ) : null}

          <View
            style={{
              flexDirection: screenWidth > 760 ? "row" : "column",
              justifyContent: "space-between",
              alignItems: screenWidth > 760 ? "center" : undefined,
              marginTop: 10,
              display:
                screenWidth > 760
                  ? "flex"
                  : screenWidth < 760 && filterShow == true
                  ? "flex"
                  : "none",
            }}
          >
            <View
              style={{
                flexDirection:
                  Platform.OS === "web" && screenWidth > 760 ? "row" : "column",

                gap:
                  Platform.OS === "web" && screenWidth > 760 ? 180 : undefined,
              }}
            >
              <View style={styles.badgeContainer}>
                <Text style={styles.uploadLogoText}>Filter by Level</Text>
                <View style={{ flexDirection: "row", gap: 6 }}>
                  {!location.host.includes("yuvha") && (
                    <Pressable
                      style={[
                        styles.filterButton,
                        {
                          backgroundColor:
                            activeTabLevel.toLowerCase() === "all"
                              ? colors.aqua
                              : colors.darkGray,
                        },
                      ]}
                      onPress={() => handleLevelChange("all")}
                    >
                      <Text
                        style={
                          activeTabLevel.toLowerCase() === "all"
                            ? styles.levelFilterBtnText
                            : styles.filterBtnText
                        }
                      >
                        All
                      </Text>
                    </Pressable>
                  )}

                  <Pressable
                    style={[
                      styles.filterButton,
                      {
                        backgroundColor:
                          activeTabLevel.toLowerCase() === "city"
                            ? colors.aqua
                            : colors.darkGray,
                      },
                    ]}
                    onPress={() => handleLevelChange("city")}
                  >
                    <Text
                      style={
                        activeTabLevel.toLowerCase() === "city"
                          ? styles.levelFilterBtnText
                          : styles.filterBtnText
                      }
                    >
                      City
                    </Text>
                  </Pressable>
                  {!location.host.includes("yuvha") && (
                    <Pressable
                      style={[
                        styles.filterButton,
                        {
                          backgroundColor:
                            activeTabLevel === "state"
                              ? colors.aqua
                              : colors.darkGray,
                        },
                      ]}
                      onPress={() => handleLevelChange("state")}
                    >
                      <Text
                        style={
                          activeTabLevel.toLowerCase() === "state"
                            ? styles.levelFilterBtnText
                            : styles.filterBtnText
                        }
                      >
                        State
                      </Text>
                    </Pressable>
                  )}
                  <Pressable
                    style={[
                      styles.filterButton,
                      {
                        backgroundColor:
                          activeTabLevel.toLowerCase() === "national"
                            ? colors.aqua
                            : colors.darkGray,
                      },
                    ]}
                    onPress={() => handleLevelChange("national")}
                  >
                    <Text
                      style={
                        activeTabLevel === "national"
                          ? styles.levelFilterBtnText
                          : styles.filterBtnText
                      }
                    >
                      National
                    </Text>
                  </Pressable>

                  <Pressable
                    style={[
                      styles.filterButton,
                      {
                        backgroundColor:
                          activeTabLevel.toLowerCase() === "international"
                            ? colors.aqua
                            : colors.darkGray,
                      },
                    ]}
                    onPress={() => handleLevelChange("international")}
                  >
                    <Text
                      style={
                        activeTabLevel.toLowerCase() === "international"
                          ? styles.levelFilterBtnText
                          : styles.filterBtnText
                      }
                    >
                      International
                    </Text>
                  </Pressable>
                </View>
              </View>
            </View>
            <View
              style={[
                styles.badgeContainer,
                { marginTop: screenWidth > 760 ? undefined : 15 },
              ]}
            >
              <Text style={styles.uploadLogoText}>Filter by Status</Text>
              <View style={{ flexDirection: "row", gap: 6 }}>
                <Pressable
                  style={[
                    styles.filterButton,
                    {
                      backgroundColor:
                        activeTab === "All" ? colors.orange : colors.darkGray,
                    },
                  ]}
                  onPress={() => handleFilterChange("All")}
                >
                  <Text style={styles.filterBtnText}>All</Text>
                </Pressable>

                <Pressable
                  style={[
                    styles.filterButton,
                    {
                      backgroundColor:
                        activeTab === "Upcoming"
                          ? colors.orange
                          : colors.darkGray,
                    },
                  ]}
                  onPress={() => handleFilterChange("Upcoming")}
                >
                  <Text style={styles.filterBtnText}>Upcoming</Text>
                </Pressable>

                <Pressable
                  style={[
                    styles.filterButton,
                    {
                      backgroundColor:
                        activeTab === "InProgress"
                          ? colors.orange
                          : colors.darkGray,
                    },
                  ]}
                  onPress={() => handleFilterChange("InProgress")}
                >
                  <Text style={styles.filterBtnText}>In Progress</Text>
                </Pressable>

                <Pressable
                  style={[
                    styles.filterButton,
                    {
                      backgroundColor:
                        activeTab === "Completed"
                          ? colors.orange
                          : colors.darkGray,
                    },
                  ]}
                  onPress={() => handleFilterChange("Completed")}
                >
                  <Text style={styles.filterBtnText}>Completed</Text>
                </Pressable>
              </View>
            </View>
          </View>

          <View
            style={{
              backgroundColor: colors.white,
              width: "100%",
              height: screenWidth > 760 ? 1 : 0,
              // height: "0.5%",
            }}
          ></View>
        </View>
      </View>

      {/* {filteredData && filteredData.length > 0 ? ( */}
      <View
        style={{
          flex: 1,
          paddingTop: Platform.OS === "web" && screenWidth > 760 ? 0 : 10,
        }}
      >
        <TournamentCard
          filteredData={filteredData}
          searchFlagValue={searchFlag}
          activeBadge={activeBadge}
          isFilteredDataEmpty={noResultsFoundFlag}
          // selectedValue={selectedValue}
          pageReceived={page}
        />
      </View>
      {/* ) : null} */}
      {loading && (
        <Modal transparent animationType="none">
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <ActivityIndicator size="large" color="#0000ff" />
            <Text style={{ color: "white" }}>Loading</Text>
          </View>
        </Modal>
      )}
      {/* ) : (
        <Text>No tournaments found</Text>
      )} */}

      {/* <View>{Platform.OS === "android" && "ios" ? <BottomMenu /> : null}</View> */}
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    gap: Platform.OS === "web" && screenWidth > 760 ? 10 : 2,
    maxWidth: Platform.OS === "web" && screenWidth > 760 ? 1320 : undefined,
    width: "100%",
    // height: Platform.OS === "web" && screenWidth > 760 ? undefined : 110,

    marginHorizontal:
      Platform.OS === "web" && screenWidth > 760 ? "auto" : undefined,
    paddingRight: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
    paddingLeft: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
    paddingTop: Platform.OS === "web" && screenWidth > 760 ? 20 : 10,
  },
  badgeContainer: {
    paddingTop: 0,
    gap: screenWidth > 760 ? 20 : 3,
    paddingBottom: 0,
    flexDirection: screenWidth > 760 ? "row" : "column",
    overflow: Platform.OS === "web" && screenWidth > 760 ? undefined : "scroll",
  },

  activeBadge: {
    backgroundColor: "white",
    borderColor: "#fff",
  },

  searchBar: {
    width: Platform.OS === "web" && screenWidth > 760 ? 350 : "100%",
    borderColor: colors.borderColor,
    backgroundColor: colors.royalBlue,
    marginTop: 0,
    color: colors.white,
  },

  filterButton: {
    paddingVertical: Platform.OS === "web" ? 5 : 8,
    paddingHorizontal: Platform.OS === "web" ? 10 : 20,
    borderRadius: 6,
    // width: Platform.OS === "web" ? undefined : 110,
  },
  filterBtnText: {
    fontSize: 14,
    fontFamily: "PlusJakartaSansRegular",
    color: "#fff",
  },
  levelFilterBtnText: {
    fontSize: 14,
    fontFamily: "PlusJakartaSansRegular",
    color: colors.royalBlue,
  },
  textField: {
    flex: Platform.OS === "web" && screenWidth > 760 ? 1 : 0,
    maxWidth: Platform.OS === "web" && screenWidth > 760 ? "50%" : "100%",
    minWidth: Platform.OS === "web" && screenWidth > 760 ? "46%" : "100%",
    marginBottom: 0,
    color: colors.white,
    marginLeft: "33%",
    // justifyContent:
    //   Platform.OS === "web" && screenWidth > 760 ? "flex-end" : undefined,
    // alignItems:
    //   Platform.OS === "web" && screenWidth > 760 ? "flex-end" : undefined,
    // justifyContent: "flex-end",
    // alignSelf: "flex-end",
  },
  uploadLogoText: {
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 16,
    color: colors.white,
    paddingVertical: 5,
  },
  requiredAsterisk: {
    color: "red",
    marginLeft: 4,
  },
  dropdownContainer: {
    width: "50%",
    marginBottom: 0,
    height: 34,
    borderRadius: 5,
    backgroundColor: colors.royalBlue,
    color: colors.white,
    borderColor: colors.borderColor,
  },
});

export default AllTournament;
