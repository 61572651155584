import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Text,
  View,
  Image,
  StyleSheet,
  Platform,
  Dimensions,
  Pressable,
  ScrollView,
} from "react-native";
import { colors } from "../../../../components/reusable/colour";
import ShieldIcon from "react-native-vector-icons/Octicons";
import CheckMarkIcon from "react-native-vector-icons/Ionicons";
import { LinearGradient } from "expo-linear-gradient";
import { H1, H4 } from "../../../../components/reusable/TextComponent";
import Football from "react-native-vector-icons/FontAwesome";
import SubstituteIcon from "react-native-vector-icons/Ionicons";
import { DataStore } from "@aws-amplify/datastore";
import { MatchMaster } from "../../../../models";
// import { ScrollView } from "react-native-gesture-handler";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

const ViewMatchDetails = ({ visibleModal, ViewTeamClose, MatchID }) => {
  const DefaultLogo: any = require("../../../../../assets/default/defaultLogo.png");
  const [modalVisible, setModalVisible] = useState(visibleModal);
  const [matchObj, seMatchObj]: any = useState({});

  const GetMatchdata = async (Matchkey) => {
    const subscription = await DataStore.observeQuery(MatchMaster, (c) =>
      c.id.eq(Matchkey)
    ).subscribe(async (snapshot) => {
      console.log("snapshot", snapshot);
      if (snapshot.isSynced == true) {
        const getMatchObj = snapshot.items;
        console.log("getMatchobj", getMatchObj);
        // setMatchObject(getMatchObj[0]);
        if (
          getMatchObj[0].OnlineScoring == true &&
          getMatchObj[0].FirstHalfStarted == true
        ) {
          // handleShowTime(getMatchObj[0]);
        }

        // setUpdatedPlayerMatchTeam(MatchTeamGetObj);
        let Teamfirst = await getMatchObj[0].Team1;
        let TeamSecond = await getMatchObj[0].Team2;
        let Team1GoalPromise = await Teamfirst.Goals.toArray();
        let Team1Goaldata = await Promise.all(Team1GoalPromise);
        let Team2GoalPromise = await TeamSecond.Goals.toArray();
        let Team2Goaldata = await Promise.all(Team2GoalPromise);
        let Team1CardPromise = await Teamfirst.Cards.toArray();
        let Team1Carddata = await Promise.all(Team1CardPromise);
        let Team2CardPromise = await TeamSecond.Cards.toArray();
        let Team2Carddata = await Promise.all(Team2CardPromise);
        let Team1SubsitutePromise = await Teamfirst.Substitutions.toArray();
        let Team1Subsitutedata = await Promise.all(Team1SubsitutePromise);
        let Team2SubsitutePromise = await TeamSecond.Substitutions.toArray();
        let Team2Subsitutedata = await Promise.all(Team2SubsitutePromise);
        console.log(
          "Team1Subsitutedata",
          Team1Goaldata,
          Team2Goaldata,
          Team1Subsitutedata,
          Team2Subsitutedata
        );
        const Team1GoalCardList = [
          ...Team1Goaldata,
          ...Team1Carddata,
          ...Team1Subsitutedata,
        ];
        const Team2GoalCardList = [
          ...Team2Goaldata,
          ...Team2Carddata,
          ...Team2Subsitutedata,
        ];
        // const Team1GoalCardList = Team1Goaldata.concat(
        //   Team1Carddata,
        //   Team1Subsitutedata
        // );
        // const Team2GoalCardList = Team2Goaldata.concat(Team2Carddata);

        let FirstTeamGoalCarddata = Team1GoalCardList.map(async (val) => ({
          ...val,
          // Playerdata: await getListofplayer(val.Player),
          Team1: Teamfirst,
        }));
        let FirstTeamGoalCard = await Promise.all(FirstTeamGoalCarddata);
        console.log("FirstTeamGoaldata", FirstTeamGoalCard);

        let SecondTeamGoalCarddata = Team2GoalCardList.map(async (val) => ({
          ...val,
          // Playerdata: await getListofplayer(val.Player),
          Team1: TeamSecond,
        }));
        let SecondTeamGoalCard = await Promise.all(SecondTeamGoalCarddata);
        console.log("SecondTeamGoalCard", SecondTeamGoalCard);
        let MergeTeam1Team2GoalCardList =
          FirstTeamGoalCard.concat(SecondTeamGoalCard);
        console.log("MergeTeam1Team2GoalCardList", MergeTeam1Team2GoalCardList);

        let Obj = {
          MatchNo:
            getMatchObj[0].MatchNo > 9
              ? `0${getMatchObj[0].MatchNo}`
              : `00${getMatchObj[0].MatchNo}`,
          GroupName: getMatchObj[0].GroupName,
          Team1: Teamfirst,
          Team2: TeamSecond,
          Team1Score:
            Teamfirst.Score.FirstHalf +
            Teamfirst.Score.SecondHalf +
            // Teamfirst.Score.PenaltyShootout +
            Teamfirst.Score.ExtraTime,
          Team2Score:
            TeamSecond.Score.FirstHalf +
            TeamSecond.Score.SecondHalf +
            // TeamSecond.Score.PenaltyShootout +
            TeamSecond.Score.ExtraTime,
          FirstHalfStarted: getMatchObj[0].FirstHalfStarted,
          OnlineScoring: getMatchObj[0].OnlineScoring,
          HalfTime: getMatchObj[0].HalfTime,
          Team1GoalCount: Team1Goaldata.length,
          Team2GoalCount: Team2Goaldata.length,
          id: getMatchObj[0].id,
          tournamentID: getMatchObj[0].tournamentID,
          Venue: getMatchObj[0].Venue,
          Date: getMatchObj[0].Date,
          GoalCardList: MergeTeam1Team2GoalCardList,
        };

        console.log("MatchObj", Obj);
        seMatchObj(Obj);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  useEffect(() => {
    GetMatchdata(MatchID);
  }, [MatchID]);
  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <Modal
        // animationType="slide"
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {}}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#4e4e4e80",
          }}
        >
          {matchObj && Object.keys(matchObj).length > 0 ? (
            <View style={styles.modalView}>
              <LinearGradient
                style={styles.resultHeadCard}
                colors={["#FF5733", "#012879"]}
                start={{ x: 0, y: 1 }}
                end={{ x: 1, y: 1 }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <H4
                    props={matchObj.MatchNo}
                    style={{ color: colors.white }}
                  />
                  <View>
                    <Text style={styles.resultDetailsHeadLabel2}>
                      {matchObj.Venue?.Name}
                    </Text>
                    <Text style={styles.resultDetailsHeadLabel2}>
                      {matchObj.Date}
                    </Text>
                  </View>
                  <H4
                    props={matchObj.GroupName}
                    style={{ color: colors.white }}
                  />
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: Platform.OS === "web" && screenWidth > 760 ? 50 : 20,
                  }}
                >
                  <>
                    <View style={styles.resultItemTeamNameLogo}>
                      <Image
                        source={
                          matchObj.Team1.LogoURL !== "" &&
                          matchObj.Team1.LogoURL !== null
                            ? {
                                uri: matchObj.Team1.LogoURL,
                              }
                            : DefaultLogo
                        }
                        // source={require("../../../../../assets/default/defaultLogo.png")}
                        style={styles.resultHeadingTeamLogo}
                      />
                      <H4
                        props={matchObj.Team1.TeamName}
                        style={{ color: colors.white }}
                      />
                    </View>
                    <View style={styles.resultDetailsScoreWrapper}>
                      <H1
                        props={matchObj.Team1Score}
                        style={styles.loserScore}
                      />
                      <Text
                        style={{
                          fontFamily: "PlusJakartaSansSemiBold",
                          fontSize: 17,
                          color: colors.white,
                        }}
                      >
                        FT
                      </Text>
                      <H1
                        props={matchObj.Team2Score}
                        style={styles.winnerScore}
                      />
                    </View>
                    <View style={styles.resultItemTeamNameLogoRight}>
                      <H4
                        props={matchObj.Team2.TeamName}
                        style={{ color: colors.white }}
                      />
                      <Image
                        source={{
                          uri:
                            matchObj.Team2.LogoURL !== "" &&
                            matchObj.Team2.LogoURL !== null
                              ? matchObj.Team2.LogoURL
                              : DefaultLogo,
                        }}
                        style={styles.resultHeadingTeamLogo}
                      />
                    </View>
                  </>
                </View>
              </LinearGradient>
              {/* score sheet started */}
              <ScrollView
                contentContainerStyle={styles.scrollContent}
                showsVerticalScrollIndicator={true}
                // showsHorizontalScrollIndicator={false}
              >
                <Text
                  style={{
                    fontFamily: "PlusJakartaSansRegular",
                    fontSize: 16,
                    marginTop: 10,
                    color: colors.white,
                    backgroundColor: colors.tableHeader,
                    padding: 4,
                  }}
                >
                  Score Sheet
                </Text>
                <View>
                  {matchObj.GoalCardList &&
                    matchObj.GoalCardList.length > 0 &&
                    matchObj.GoalCardList.map((ele) => {
                      return (
                        <View
                          style={{
                            flexDirection: "column",
                            gap: 5,
                            marginTop: 10,
                          }}
                        >
                          {!Object.keys(ele).includes("InPlayer") &&
                          !ele.CardType &&
                          ele.Penalty == false ? (
                            <View style={styles.scoreSheetRow}>
                              <View
                                style={{
                                  flexDirection: "row",
                                  gap: 5,
                                  alignItems: "center",
                                  flex: 1,
                                }}
                              >
                                <Image
                                  source={{
                                    uri:
                                      ele.Team1.LogoURL !== "" &&
                                      ele.Team1.LogoURL !== null
                                        ? ele.Team1.LogoURL
                                        : DefaultLogo,
                                  }}
                                  style={{
                                    width: 30,
                                    height: 30,
                                    borderRadius: 30,
                                    resizeMode: "contain",
                                  }}
                                />
                                <Text style={styles.scoreSHeetDefText}>
                                  {ele.Team1.TeamName}
                                </Text>
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  gap: 5,
                                  alignItems: "center",
                                  flex: 1,
                                }}
                              >
                                <View
                                  style={{
                                    width: 30,
                                    height: 30,
                                    borderRadius: 30,
                                    backgroundColor: "#afbad1",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontFamily: "PlusJakartaSansSemiBold",
                                      color: colors.royalBlue,
                                    }}
                                  >
                                    {ele.Player.JerseyNo}
                                  </Text>
                                </View>
                                <Text style={styles.scoreSHeetDefText}>
                                  {ele.Player.Name}
                                </Text>
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  gap: 5,
                                  alignItems: "center",
                                  width: 85,
                                }}
                              >
                                <Text
                                  style={[
                                    styles.scoreSHeetDefText,
                                    { fontSize: 18 },
                                  ]}
                                >
                                  {ele.Time}
                                </Text>
                                <Text style={styles.scoreSHeetDefText}>
                                  Minutes
                                </Text>
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  gap: 5,
                                  alignItems: "center",
                                  width: 75,
                                }}
                              >
                                <Football
                                  name="soccer-ball-o"
                                  size={22}
                                  color={colors.white}
                                />
                                <Text style={styles.scoreSHeetDefText}>
                                  Goal
                                </Text>
                              </View>

                              <View
                                style={{
                                  flexDirection: "row",
                                  gap: 5,
                                  alignItems: "center",
                                  borderColor:
                                    ele.Penalty == true
                                      ? colors.red
                                      : "transparent",
                                  borderWidth: 1,
                                  paddingHorizontal: 10,
                                  width: 100,
                                }}
                              >
                                {ele.Penalty == true ? (
                                  <>
                                    <Football
                                      name="soccer-ball-o"
                                      size={22}
                                      color={colors.white}
                                    />
                                    <Text
                                      style={[
                                        styles.scoreSHeetDefText,
                                        { color: colors.red },
                                      ]}
                                    >
                                      Penaluty
                                    </Text>
                                  </>
                                ) : null}
                              </View>
                            </View>
                          ) : null}

                          {!Object.keys(ele).includes("InPlayer") &&
                          ele.CardType === "Yellow" ? (
                            <View style={styles.scoreSheetRow}>
                              <View
                                style={{
                                  flexDirection: "row",
                                  gap: 5,
                                  alignItems: "center",
                                  flex: 1,
                                }}
                              >
                                <Image
                                  source={{
                                    uri:
                                      ele.Team1.LogoURL !== "" &&
                                      ele.Team1.LogoURL !== null
                                        ? ele.Team1.LogoURL
                                        : DefaultLogo,
                                  }}
                                  style={{
                                    width: 30,
                                    height: 30,
                                    borderRadius: 30,
                                    resizeMode: "contain",
                                  }}
                                />
                                <Text style={styles.scoreSHeetDefText}>
                                  {ele.Team1.TeamName}
                                </Text>
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  gap: 5,
                                  alignItems: "center",
                                  flex: 1,
                                }}
                              >
                                <View
                                  style={{
                                    width: 30,
                                    height: 30,
                                    borderRadius: 30,
                                    backgroundColor: "#afbad1",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontFamily: "PlusJakartaSansSemiBold",
                                      color: colors.royalBlue,
                                    }}
                                  >
                                    {ele.Player.JerseyNo}
                                  </Text>
                                </View>
                                <Text style={styles.scoreSHeetDefText}>
                                  {ele.Player.Name}
                                </Text>
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  gap: 5,
                                  alignItems: "center",
                                  width: 85,
                                }}
                              >
                                <Text
                                  style={[
                                    styles.scoreSHeetDefText,
                                    { fontSize: 18 },
                                  ]}
                                >
                                  {ele.Time}
                                </Text>
                                <Text style={styles.scoreSHeetDefText}>
                                  Minutes
                                </Text>
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  gap: 5,
                                  alignItems: "center",
                                  width: 185,
                                }}
                              >
                                <View style={styles.yellowCardClick}></View>
                                <Text style={styles.scoreSHeetDefText}>
                                  Yellow
                                </Text>
                              </View>
                            </View>
                          ) : null}
                          {!Object.keys(ele).includes("InPlayer") &&
                          ele.CardType === "Red" ? (
                            <View style={styles.scoreSheetRow}>
                              <View
                                style={{
                                  flexDirection: "row",
                                  gap: 5,
                                  alignItems: "center",
                                  flex: 1,
                                }}
                              >
                                <Image
                                  source={{
                                    uri:
                                      ele.Team1.LogoURL !== "" &&
                                      ele.Team1.LogoURL !== null
                                        ? ele.Team1.LogoURL
                                        : DefaultLogo,
                                  }}
                                  style={{
                                    width: 30,
                                    height: 30,
                                    borderRadius: 30,
                                    resizeMode: "contain",
                                  }}
                                />
                                <Text style={styles.scoreSHeetDefText}>
                                  {ele.Team1.TeamName}
                                </Text>
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  gap: 5,
                                  alignItems: "center",
                                  flex: 1,
                                }}
                              >
                                <View
                                  style={{
                                    width: 30,
                                    height: 30,
                                    borderRadius: 30,
                                    backgroundColor: "#afbad1",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontFamily: "PlusJakartaSansSemiBold",
                                      color: colors.royalBlue,
                                    }}
                                  >
                                    {ele.Player.JerseyNo}
                                  </Text>
                                </View>
                                <Text style={styles.scoreSHeetDefText}>
                                  {ele.Player.Name}
                                </Text>
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  gap: 5,
                                  alignItems: "center",
                                  width: 85,
                                }}
                              >
                                <Text
                                  style={[
                                    styles.scoreSHeetDefText,
                                    { fontSize: 18 },
                                  ]}
                                >
                                  {ele.Time}
                                </Text>
                                <Text style={styles.scoreSHeetDefText}>
                                  Minute
                                </Text>
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  gap: 5,
                                  alignItems: "center",
                                  width: 185,
                                }}
                              >
                                <View style={styles.redCardClick}></View>
                                <Text style={styles.scoreSHeetDefText}>
                                  Red Card
                                </Text>
                              </View>
                            </View>
                          ) : null}
                          {Object.keys(ele).includes("InPlayer") ? (
                            <View style={styles.scoreSheetRow}>
                              <View
                                style={{
                                  flexDirection: "row",
                                  gap: 5,
                                  alignItems: "center",
                                  flex: 1,
                                }}
                              >
                                <Image
                                  source={{
                                    uri:
                                      ele.Team1.LogoURL !== "" &&
                                      ele.Team1.LogoURL !== null
                                        ? ele.Team1.LogoURL
                                        : DefaultLogo,
                                  }}
                                  style={{
                                    width: 30,
                                    height: 30,
                                    borderRadius: 30,
                                    resizeMode: "contain",
                                  }}
                                />
                                <Text style={styles.scoreSHeetDefText}>
                                  {ele.Team1.TeamName}
                                </Text>
                              </View>
                              <View style={{ flex: 1, gap: 8 }}>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    gap: 5,
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <View
                                    style={{
                                      width: 30,
                                      height: 30,
                                      borderRadius: 30,
                                      backgroundColor: "#8dc78d",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontFamily: "PlusJakartaSansSemiBold",
                                        color: colors.green,
                                      }}
                                    >
                                      {ele.InPlayer.JerseyNo}
                                    </Text>
                                  </View>
                                  <Text
                                    style={[
                                      styles.scoreSHeetDefText,
                                      { color: colors.green },
                                    ]}
                                  >
                                    {ele.InPlayer.Name}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    gap: 5,
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <View
                                    style={{
                                      width: 30,
                                      height: 30,
                                      borderRadius: 30,
                                      backgroundColor: "#f1a3a3",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Text
                                      style={{
                                        fontFamily: "PlusJakartaSansSemiBold",
                                        color: colors.red,
                                      }}
                                    >
                                      {ele.OutPlayer.JerseyNo}
                                    </Text>
                                  </View>
                                  <Text
                                    style={[
                                      styles.scoreSHeetDefText,
                                      { color: colors.red },
                                    ]}
                                  >
                                    {ele.OutPlayer.Name}
                                  </Text>
                                </View>
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  gap: 5,
                                  alignItems: "center",
                                  width: 85,
                                }}
                              >
                                <Text
                                  style={[
                                    styles.scoreSHeetDefText,
                                    { fontSize: 18 },
                                  ]}
                                >
                                  {ele.Time}
                                </Text>
                                <Text style={styles.scoreSHeetDefText}>
                                  Minutes
                                </Text>
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  gap: 5,
                                  alignItems: "center",
                                  width: 185,
                                }}
                              >
                                <SubstituteIcon
                                  name="swap-horizontal"
                                  size={25}
                                  color={colors.white}
                                />
                                <Text style={styles.scoreSHeetDefText}>
                                  Substitute
                                </Text>
                              </View>
                            </View>
                          ) : null}
                        </View>
                      );
                    })}
                </View>
              </ScrollView>
              {/* scoresheet ended */}
              <View style={styles.modalBottomPart}>
                {/* cancel button */}
                <Pressable
                  style={styles.outlineButton}
                  onPress={() => ViewTeamClose()}
                >
                  <Text style={styles.buttonText}>Close</Text>
                </Pressable>
              </View>
            </View>
          ) : null}
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  modalView: {
    width: "100%",
    maxHeight: 0.95 * screenHeight,
    maxWidth: 900,
    margin: 20,
    backgroundColor: colors.bgColor,
    borderRadius: 5,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    position: "relative",
    padding: 10,
  },
  modalBottomPart: {
    marginTop: 15,
    justifyContent: "center",
    alignItems: "center",
  },
  outlineButton: {
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: colors.white,
    borderRadius: 10,
    height: 35,
    minWidth: 120,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 15,
    paddingVertical: 4,
  },
  orangeButton: {
    backgroundColor: colors.orange,
    borderWidth: 1,
    borderColor: colors.orange,
    borderRadius: 10,
    height: 35,
    minWidth: 120,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 15,
    paddingVertical: 4,
  },
  buttonText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 16,
  },
  resultHeadCard: {
    backgroundColor: colors.royalBlue,
    borderRadius: 10,
    padding: 10,
    // boxShadow: "0 0 10px #0003",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.3,
    shadowRadius: 10,
    elevation: 5,
  },
  resultDetailsHeadLabel2: {
    textAlign: "center",
    marginBottom: 5,
    fontSize: 14,
    fontFamily: "PlusJakartaSansSemiBold",
    color: colors.white,
  },
  resultItemTeamNameLogo: {
    display: "flex",
    flexDirection:
      Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
    justifyContent: "center",
    alignItems: "center",
    gap: Platform.OS === "web" && screenWidth > 760 ? 10 : 5,
    flex: 1,
  },
  resultItemTeamNameLogoRight: {
    display: "flex",
    flexDirection:
      Platform.OS === "web" && screenWidth > 760 ? "row" : "column-reverse",
    justifyContent: "center",
    alignItems: "center",
    gap: Platform.OS === "web" && screenWidth > 760 ? 10 : 5,
    flex: 1,
  },
  resultDetailsScoreWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 25,
  },
  resultHeadingTeamLogo: {
    width: Platform.OS === "web" && screenWidth > 760 ? 80 : 50,
    height: Platform.OS === "web" && screenWidth > 760 ? 80 : 50,
    resizeMode: "contain",
    borderRadius: 50,
  },
  winnerScore: {
    color: colors.green,
  },
  loserScore: {
    color: colors.red,
  },
  scoreSHeetDefText: {
    fontFamily: "PlusJakartaSansRegular",
    color: colors.white,
  },
  scoreSheetRow: {
    flexDirection: "row",
    gap: 15,
    backgroundColor: colors.royalBlue,
    borderRadius: 7,
    padding: 4,
  },
  yellowCardClick: {
    width: 25,
    height: 30,
    backgroundColor: "#F9DB05",
    borderRadius: 3,
  },
  redCardClick: {
    width: 25,
    height: 30,
    backgroundColor: colors.red,
    borderRadius: 3,
  },
  scrollContent: {
    flexGrow: 1,
  },
});

export default ViewMatchDetails;
