const shuffleArray = (arr) => {
  const shuffledArr = [...arr]; // Create a shallow copy of the original array
  for (let i = shuffledArr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1)); // Generate a random index

    [shuffledArr[i], shuffledArr[j]] = [shuffledArr[j], shuffledArr[i]];
  }
  return shuffledArr;
};
export const createGroups = async (NoOfGroups, finalteamlistarry1) => {
  console.log("finalteamlistarry1", finalteamlistarry1);

  let Teamtotal = Math.floor(finalteamlistarry1.length / 2);

  console.log("helloteamtotal", Teamtotal, NoOfGroups);

  let GroupName = [
    "Group A",
    "Group B",
    "Group C",
    "Group D",
    "Group E",
    "Group F",
    "Group G",
    "Group H",
    "Group I",
    "Group J",
    "Group K",
    "Group L",
    "Group M",
    "Group N",
    "Group O",
    "Group P",
    "Group Q",
    "Group R",
    "Group S",
    "Group T",
    "Group U",
    "Group V",
    "Group W",
    "Group X",
    "Group Y",
    "Group Z",
  ];

  function distributeArray(arr, size) {
    let result = [];
    for (let i = 0; i < arr.length; i += size) {
      let chunk = arr.slice(i, i + size);
      result.push(chunk);
    }
    return result.sort((a, b) => b.length - a.length);
  }

  let arr = shuffleArray(finalteamlistarry1);
  let size = Math.floor(finalteamlistarry1.length / NoOfGroups);
  let remainder = finalteamlistarry1.length % NoOfGroups;
  let distributedArr = distributeArray(arr, size);

  // Distribute remaining teams
  for (let i = 0; i < remainder; i++) {
    distributedArr[i].push(arr[size * NoOfGroups + i]);
  }

  let finalarr = [];

  if (Teamtotal >= NoOfGroups) {
    GroupName.map((obj, index) => {
      if (NoOfGroups > index) {
        finalarr.push({
          GroupName: obj,
          Teams: distributedArr[index],
        });
      }
    });

    console.log("finalarr", finalarr);
  } else {
    console.log(
      "Number of Groups can not be more than half of participating team"
    );
  }

  return finalarr;
};

export const handleSaveGroup = async (
  finalarr,
  finalteamlistarry1,
  Groupflag
) => {
  const createFixtures = (teams) => {
    console.log("teams", teams);
    const fixtures = [];
    let numberOfTeams = teams.length;

    if (numberOfTeams % 2 !== 0) {
      // Add a "bye" team if the number of teams is odd
      teams.push("Bye");
      numberOfTeams++;
    }

    const noOfRounds = numberOfTeams - 1;
    const matchesPerRound = numberOfTeams / 2;
    console.log("Rounds", noOfRounds);

    for (let round = 0; round < noOfRounds; round++) {
      const roundFixtures = [];

      for (let match = 0; match < matchesPerRound; match++) {
        const team1Index = match;
        let team2Index = numberOfTeams - 1 - match;
        //   console.log("Team1Index", team1Index);
        //   console.log("Team2Index", team2Index);
        // Don't include fixture if any team is Bye or both teams are the same
        if (
          teams[team1Index] !== "Bye" &&
          teams[team2Index] !== "Bye" &&
          teams[team1Index] !== teams[team2Index]
        ) {
          roundFixtures.push(`${teams[team1Index]} Vs ${teams[team2Index]}`);
        }
      }

      const checkForConsecutiveMatch = () => {
        let changeSequence = false;
        if (fixtures.length > 0 && roundFixtures.length > 0) {
          const lastMatch = fixtures.slice(-1)[0].split(" Vs ");
          const nextMatch = roundFixtures[0].split(" Vs ");
          console.log("Matches :", lastMatch, nextMatch);

          lastMatch.forEach((team1) => {
            nextMatch.forEach((team2) => {
              if (team1 == team2) {
                changeSequence = true;
              }
            });
          });
          //   console.log("Consecutive matches :", changeSequence);
          if (changeSequence) {
            roundFixtures.splice(0, 0, roundFixtures[roundFixtures.length - 1]);
            roundFixtures.pop();
            // console.log("Updated Matches", roundFixtures);
          }
        }
      };

      if (round > 0) {
        console.log("Round #", round);
        checkForConsecutiveMatch();
      }

      teams.splice(1, 0, teams[numberOfTeams - 1]);
      teams.pop();

      fixtures.push(...roundFixtures);
    }

    return fixtures;
  };

  let GroupFlag = true;
  // console.log("GroupList", SelectedRound, CompetitionSelctedObj, GroupList);
  let finalMatches = [];
  let finalteamlistarry = [];
  if (Groupflag == true) {
    finalarr.forEach((obj) => {
      let groupTeams = [];

      // for(let i; i<obj.Teams.length; i++){
      //   groupTeams.push(obj.Teams[i].TeamName);
      // }
      obj.Teams.map((ele) => {
        groupTeams.push(ele.TeamName);
      });
      // console.log("groupTeams", groupTeams);
      if (groupTeams && groupTeams.length > 0) {
        let groupMatches = createFixtures(groupTeams);
        console.log("groupMatches", groupMatches);
        finalMatches.push(...groupMatches);
      }
      // console.log("CompfinalMatches", finalMatches);
      finalteamlistarry1.forEach((obj, index) => {
        finalarr.map((ele) => {
          ele.Teams.forEach((val) => {
            if (obj.TeamName == val.TeamName) {
              console.log("hellobug", obj.TeamName, val.TeamName);
              obj.GroupName = ele.GroupName;
            }
          });
          // console.log("CompetitionTeams", finalteamlistarry1);
        });
      });
    });
  } else {
    // setGroupFlag(false);
    // if (Roundtab !== 0) {
    //   const encounteredValues = new Set();
    //   for (const obj1 of finalteamlistarry1) {
    //     const matchingObj2 = SelectedTeamList.find(
    //       (obj2) => obj2.value === obj1.TeamName
    //     );
    //     if (matchingObj2 && !encounteredValues.has(matchingObj2.value)) {
    //       if (matchingObj2) {
    //         finalteamlistarry.push(obj1);
    //         encounteredValues.add(matchingObj2.value);
    //       }
    //     }
    //   }
    // } else {
    finalteamlistarry = finalteamlistarry1;
    // }
    if (finalteamlistarry && finalteamlistarry.length > 1) {
      let groupTeams = [];
      finalteamlistarry.map((team) => {
        groupTeams.push(team.TeamName);
      });
      // console.log("UniqueCompfind", groupTeams);
      let finalArr = shuffleArray(groupTeams);
      // console.log("GroupfinalArr", finalArr);
      let groupMatches = createFixtures(finalArr);
      finalMatches = groupMatches;
    }
  }

  let FinalMatchesDataarr = [];

  // console.log("hellofinalMatches", finalMatches);
  const adjustedMatches = [...finalMatches];
  console.log("adjustedMatches", adjustedMatches);
  return adjustedMatches;
};
