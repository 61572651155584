import React, { useEffect } from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { Ionicons } from "@expo/vector-icons";
import TrophyIcon from "react-native-vector-icons/FontAwesome5";
import AllMatches from "../../screens/view/Home/SubComp/AllMatches";
import AllTournament from "../../screens/view/competition/AllTournament";
import FlagIcon from "react-native-vector-icons/MaterialIcons";
import DashboardIcon from "react-native-vector-icons/MaterialIcons";
import MobMenu from "./Menu/mobMenu";
import Dashboard from "../../screens/adminView/Dashboard/Dashboard";
import { colors } from "../reusable/colour";
import NavigationBarColor, {
  hideNavigationBar,
} from "react-native-navigation-bar-color";

const Tab = createBottomTabNavigator();

const BottomMenu = () => {
  // useEffect(() => {
  //   // NavigationBarColor(colors.royalBlue);
  //   // // Cleanup
  //   // return () => {
  //   //   NavigationBarColor(colors.royalBlue);
  //   // };
  //   // hide();
  // }, []);
  // const hide = () => {
  //   hideNavigationBar();
  // };
  return (
    <React.Fragment>
      <Tab.Navigator
        initialRouteName="Dashboard"
        screenOptions={() => ({
          tabBarInactiveTintColor: "white",
          tabBarAllowFontScaling: true,
        })}
      >
        <Tab.Screen
          name="Dashboard"
          component={Dashboard}
          options={{
            tabBarIcon: ({ color, size }) => (
              <DashboardIcon name="dashboard" size={26} color={color} />
            ),
            headerStyle: {
              backgroundColor: colors.royalBlue,
            },
            tabBarActiveTintColor: colors.orange,
            headerTintColor: colors.white,
            tabBarStyle: { backgroundColor: colors.royalBlue },
          }}
        />
        <Tab.Screen
          name="Tournament"
          component={AllTournament}
          options={{
            tabBarIcon: ({ color, size }) => (
              <TrophyIcon name="trophy" size={26} color={color} />
            ),
            headerStyle: {
              backgroundColor: colors.royalBlue,
            },
            tabBarActiveTintColor: colors.orange,
            headerTintColor: colors.white,
            tabBarStyle: { backgroundColor: colors.royalBlue },
          }}
        />
        <Tab.Screen
          name="Matches"
          component={AllMatches}
          options={{
            tabBarIcon: ({ color, size }) => (
              <FlagIcon name="sports" size={38} color={color} />
            ),
            headerStyle: {
              backgroundColor: colors.royalBlue,
            },
            tabBarActiveTintColor: colors.orange,
            headerTintColor: colors.white,
            tabBarStyle: { backgroundColor: colors.royalBlue },
          }}
        />
        <Tab.Screen
          name="More"
          component={MobMenu}
          options={{
            tabBarIcon: ({ color, size }) => (
              <Ionicons name="menu" size={26} color={color} />
            ),
            headerStyle: {
              backgroundColor: colors.royalBlue,
            },
            tabBarActiveTintColor: colors.orange,
            headerTintColor: colors.white,
            tabBarStyle: {
              backgroundColor: colors.royalBlue,
            },
            headerTransparent: true,
            headerTitle: "",
          }}
        />
      </Tab.Navigator>
    </React.Fragment>
  );
};

export default BottomMenu;
