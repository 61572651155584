import React, { useEffect, useState, forwardRef } from "react";
import { formatDateShort } from "../../../components/FormattedDate";
import { colors } from "../../../components/reusable/colour";
import { Dimensions, Image, StyleSheet, Text, View } from "react-native";

const defaultPlayer: any = require("../../../../assets/default/player.png");
const screenWidth = Dimensions.get("window").width;
// const tmtLogo: any = require("../../../../assets/logo/TMT_Horizontal_Logo.png");
const tmtLogo: any = require("../../../../assets/logo/tmt-tma-combo.png");
const YuvhaLogo: any = require("../../../../assets/logo/YUVHAGAMES_BLACK.png");

// type PlayerData = {
//   PhotoURL?: string;
//   Name: string;
//   DateOfBirth?: string;
//   JerseyNo: string;
// };
type PlayerData = {
  PhotoURL?: string;
  Name: string;
  DateOfBirth?: string;
  JerseyNo: string;
  SrNo: string;
  id: string;
};

type TeamsData = {
  tournamentobj: { Name: string; StartDate: string };
  RegistrationObj: { TeamName: string };
  PlayerData: { playerdata: PlayerData }[];
};

type TeamMemberPDFDocumentProps = {
  teamsObj: TeamsData;
};

const chunkArray = <T,>(arr: T[] | undefined, chunkSize: number): T[][] => {
  if (!arr) return [];
  const chunks = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize));
  }
  return chunks;
};

const TeamMemberPDFDocument = forwardRef<View, TeamMemberPDFDocumentProps>(
  (props, ref) => {
    const { teamsObj } = props;
    // const [teamsData, setTeamsData] = useState<TeamsData | {}>({});

    useEffect(() => {
      console.log("teamsObj", teamsObj);
      // setTeamsData(teamsObj);
    }, [teamsObj]);

    const renderPlayer = (ele, index) => (
      <View style={styles.playerItem} key={index}>
        <View style={styles.otherDetails}>
          <Image source={YuvhaLogo} style={styles.logo} />
          <Text style={styles.playerBoldText}>
            {teamsObj?.tournamentobj?.Name}
          </Text>
          <Text style={styles.playerDetailsText}>
            <Text style={styles.playerBoldText}>Season: </Text>
            {teamsObj?.tournamentobj?.StartDate?.split("-")[0]}
          </Text>
          <Text style={styles.playerDetailsText}>
            <Text style={styles.playerBoldText}>Team : </Text>
            {teamsObj?.RegistrationObj?.TeamName}
          </Text>
        </View>
        <View style={styles.playerDetails}>
          <Image
            source={{
              uri: ele.playerdata.PhotoURL
                ? ele.playerdata.PhotoURL
                : defaultPlayer,
            }}
            style={styles.playerImage}
          />
          <View style={styles.playerDetailsContent}>
            <Text style={styles.playerNameText}>{ele.playerdata?.Name}</Text>
            <Text style={styles.playerDetailsText}>
              <Text style={styles.playerBoldText}>Birth Date: </Text>
              {ele.playerdata.DateOfBirth
                ? formatDateShort(ele.playerdata.DateOfBirth)
                : null}
            </Text>
            <Text style={styles.playerDetailsText}>
              <Text style={styles.playerBoldText}>Regn No: </Text>
              {ele.playerdata?.SrNo}
            </Text>
            <View style={styles.pdtBottom}>
              <Text style={styles.poweredByText}>Powered by</Text>
              <Image source={tmtLogo} style={styles.logo} />
            </View>
          </View>
        </View>
      </View>
    );

    const playerChunks = chunkArray(teamsObj?.PlayerData, 9);

    return (
      <View ref={ref} style={styles.modalView}>
        {teamsObj && Object.keys(teamsObj).length > 0 && (
          <View>
            {playerChunks.map((chunk, chunkIndex) => (
              <View key={chunkIndex} style={styles.chunkContainer}>
                <View style={styles.playerItemContainer}>
                  {chunk.map((ele, index) => renderPlayer(ele, index))}
                </View>
                {chunkIndex < playerChunks.length - 1 && (
                  <View style={styles.pageBreak} />
                )}
              </View>
            ))}
          </View>
        )}
      </View>
    );
  }
);

const styles = StyleSheet.create({
  modalView: {
    width: screenWidth > 760 ? "100%" : "90%",
    maxWidth: 800, // A4 width in points
    minHeight: 200,
    margin: 20,
    backgroundColor: colors.white,
    borderRadius: 10,
    position: "relative",
    padding: 10,
  },
  playerImage: {
    width: 80,
    height: 80,
    borderRadius: 80,
    resizeMode: "contain",
  },
  playerDetailsText: {
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 12,
    color: colors.royalBlue,
    textAlign: "center",
  },
  playerBoldText: {
    fontFamily: "PlusJakartaSansBold",
    color: colors.darkGray,
    textAlign: "center",
  },
  playerNameText: {
    fontFamily: "PlusJakartaSansBold",
    color: colors.royalBlue,
    textAlign: "center",
    fontSize: 14,
  },
  playerDetails: {
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
  },
  playerDetailsContent: {
    width: "100%",
    gap: 3,
    alignItems: "center",
  },
  playerItem: {
    borderColor: "#44a9f9",
    borderWidth: 2,
    width: "31%",
    padding: 7,
    borderRadius: 10,
  },
  playerItemContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 15,
    flexWrap: "wrap",
    marginVertical: 15,
  },
  otherDetails: {
    borderBottomColor: "#44a9f9",
    alignItems: "center",
    borderBottomWidth: 1,
    paddingBottom: 10,
    marginBottom: 10,
    gap: 3,
  },
  pdtBottom: {
    borderTopColor: "#44a9f9",
    borderTopWidth: 1,
    width: "100%",
    alignItems: "center",
    marginTop: 5,
  },
  chunkContainer: {
    marginBottom: 10,
  },
  pageBreak: {
    height: 0,
    pageBreakAfter: "always",
  },
  poweredByText: {
    alignContent: "center",
    fontWeight: "300",
  },
  logo: {
    width: 100, // Adjust as needed
    height: 30, // Adjust as needed
    resizeMode: "contain",
    opacity: 1,
  },
});

export default TeamMemberPDFDocument;
