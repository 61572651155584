import React from "react";
import { Appbar } from "react-native-paper";
import { colors } from "../reusable/colour";

const AppBar = ({ title, showBackButton, onBackPress }) => {
  return (
    <Appbar.Header style={{ backgroundColor: colors.blue }}>
      {showBackButton && (
        <Appbar.BackAction onPress={onBackPress} color="white" />
      )}
      <Appbar.Content
        title={title}
        titleStyle={{
          fontSize: 20,
          fontFamily: "PlusJakartaSansBold",
          color: "white",
        }}
      />
    </Appbar.Header>
  );
};

export default AppBar;
