import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Pressable,
  Image,
  Dimensions,
  Platform,
  TextInput,
} from "react-native";
import { colors } from "../../../../components/reusable/colour";
import SendIcon from "react-native-vector-icons/MaterialIcons";
import EditIcon from "react-native-vector-icons/MaterialIcons";
import TrashIcon from "react-native-vector-icons/Ionicons";
import { CommonHeading } from "../../../../components/reusable/TextComponent";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { DataStore, SortDirection } from "aws-amplify/datastore";
import { Announcement, Tournament } from "../../../../models";
import { getAuthenticatedUser } from "../../../../api/AmplifyUserAPI";
import { getUserMasterData } from "../../../../api/UserMasterAPI";
import { SuccessAutoExitModal } from "../../../../components/reusable/AutoExitModal";
import { AreYouSureOrange } from "../../../../components/reusable/AreYouSure";

const Notification = ({ tournamentId }) => {
  const [notificationIdToUpdate, setNotificationIdToUpdate] = useState("");
  const [notificationsData, setNotificationsData] = useState([]);
  const [messageDescription, setMessageDescription] = useState("");
  const [byUserName, setByUserName] = useState("");
  const [byPhotoURL, setByPhotoURL] = useState("");
  const [editNotificationBool, setEditNotificationBool] = useState(false);
  const [UpdateSuccessModalVisible, setUpdateSuccessModalVisible] =
    useState(false);
  const [UpdateSunccessModalMesg, setUpdateSunccessModalMesg] = useState("");
  const [AddSuccessModalVisible, setAddSuccessModalVisible] = useState(false);
  const [AddSunccessModalMesg, setAddSunccessModalMesg] = useState("");
  const [notificationIdToDelete, setNotificationIdToDelete] = useState("");
  const [DeleteSuccessModalVisible, setDeleteSuccessModalVisible] =
    useState(false);
  const [DeleteSunccessModalMesg, setDeleteSunccessModalMesg] = useState("");
  const getNotificationDetails = async () => {
    let tournamentId = await AsyncStorage.getItem("CurrentTournament");
    const notifications = DataStore.observeQuery(
      Announcement,
      (c) => c.tournamentID.eq(tournamentId),
      { sort: (s) => s.TimeStamp(SortDirection.DESCENDING) }
    ).subscribe(async (snapshot) => {
      console.log("Notifications", snapshot.items);
      setNotificationsData(snapshot.items);
    });
    return () => {
      notifications.unsubscribe();
    };
  };

  useEffect(() => {
    console.log("Tournament Id in Notifications : ", tournamentId);

    const fetchData = async () => {
      await authenticatedUser();

      await getNotificationDetails();
    };
    fetchData();
  }, [
    AddSuccessModalVisible,
    UpdateSuccessModalVisible,
    DeleteSuccessModalVisible,
  ]);
  const inputEvent = (message) => {
    console.log("hellotext", message);
    setMessageDescription(message);
  };
  const authenticatedUser = async () => {
    await getAuthenticatedUser().then(async (val) => {
      if (val) {
        console.log("Response useEffect : ", val);
        const { isLoggedIn, attributes } = val;
        console.log("isLogged In : ", isLoggedIn);

        if (attributes) {
          let uuid = attributes.sub;
          console.log("UUID : ", uuid);

          await getUserMasterData(uuid).then((response) => {
            console.log("response", response);
            setByUserName(response.Name);
            setByPhotoURL(response.PhotoURL);
          });
        }
      }
    });
  };
  const submitNotification = async () => {
    if (editNotificationBool) {
      await handleUpdate(notificationIdToUpdate);
    } else {
      const date = new Date();
      const awsDateTime = date.toISOString();
      const announcement = new Announcement({
        ShortDescription: "",
        Description: messageDescription,
        ByUserName: byUserName,
        ByUserPhoto: byPhotoURL,
        TimeStamp: awsDateTime,
        tournamentID: tournamentId,
      });
      console.log("add announcement", announcement);

      setAddSuccessModalVisible(true);
      setAddSunccessModalMesg("Announcement added Sucessfully!");
      await DataStore.save(announcement);

      setMessageDescription("");
    }
  };

  const handleDelete = async (id) => {
    setDeleteSuccessModalVisible(true);
    console.log("deletesucessmodal value after", DeleteSuccessModalVisible);
    setDeleteSunccessModalMesg("Are you sure do you want to Delete?");
    console.log("id get in handledelete", id);
    setNotificationIdToDelete(id);
  };
  const deleteNotification = async (id) => {
    console.log("id get :", id);
    const modelToDelete = await DataStore.query(Announcement, id);
    const response = await DataStore.delete(modelToDelete);
    setDeleteSuccessModalVisible(false);
  };

  const editNotification = (desc, id) => {
    setEditNotificationBool(true);
    setNotificationIdToUpdate(id);
    setMessageDescription(desc);
  };
  const handleUpdate = async (id) => {
    console.log("helloupdate");

    const UpdateNotification = await DataStore.query(Announcement, id);

    const UpdateNoti = Announcement.copyOf(UpdateNotification, (updated) => {
      updated.Description = messageDescription;
      // Assuming FixturesCreated is the correct property name
      return updated;
    });
    console.log("Update Noti", UpdateNoti);
    const UpdateNotificationData = await DataStore.save(UpdateNoti);
    console.log("Updated Notification:", UpdateNotificationData);
    if (UpdateNotificationData) {
      setUpdateSuccessModalVisible(true);
      setUpdateSunccessModalMesg("Notification Updated Sucessfully!");
      setMessageDescription("");
      setEditNotificationBool(false);
      setNotificationIdToUpdate("");
    } else {
      setUpdateSuccessModalVisible(false);
    }
  };

  const handleOk = () => {
    setUpdateSuccessModalVisible(false);
  };
  const handleConfirm = () => {
    console.log("notificationIdToDelete", notificationIdToDelete);
    deleteNotification(notificationIdToDelete);
  };

  const handleCancel = () => {
    setDeleteSuccessModalVisible(false);
  };

  const handleOk2 = () => {
    setAddSuccessModalVisible(false);
  };
  return (
    <View style={styles.TabContentContainer}>
      <View>
        <View>
          <CommonHeading props={"Notification Messages"} />
          <View style={[styles.WriteNotificationWrapper, { marginTop: 15 }]}>
            <View style={{ flex: 1 }}>
              <TextInput
                style={styles.inputStyle}
                value={messageDescription}
                onChangeText={(text) => inputEvent(text)}
                placeholder="Enter Notification messages here"
              />
            </View>
            <Pressable style={styles.submitBtn} onPress={submitNotification}>
              <Text style={styles.submitBtnText}>Submit</Text>
            </Pressable>
          </View>
        </View>

        <View style={styles.notificationsContainer}>
          {notificationsData.map((e, i) => {
            return (
              <View style={styles.notificationItem} key={i}>
                <View style={styles.dateANdBtnWrapper}>
                  <Text style={styles.notificationTimeText}>
                    {/* 05 February 2024, 14:20 P.M. */}
                    {e.TimeStamp.split("T")[0]}
                    {e.TimeStamp.split("T")[1].split(".")[0]}
                  </Text>
                  <View style={{ gap: 5, flexDirection: "row" }}>
                    <Pressable
                      onPress={() => editNotification(e.Description, e.id)}
                    >
                      <EditIcon name="edit" size={20} color="#069519" />
                    </Pressable>
                    <Pressable onPress={() => handleDelete(e.id)}>
                      <TrashIcon name="trash-sharp" size={20} color="red" />
                    </Pressable>
                  </View>
                </View>
                <View style={{ gap: 2 }}>
                  <Text style={styles.notificationMessage}>
                    {e.Description}
                  </Text>
                </View>
                <View style={styles.userNamePhotoContainer}>
                  <Image source={e.PhotoURL} style={styles.userImage} />
                  <Text style={styles.notificationSender}>{e.ByUserName}</Text>
                </View>
              </View>
            );
          })}

          {UpdateSuccessModalVisible == true ? (
            <SuccessAutoExitModal
              visibleModal={UpdateSuccessModalVisible}
              SuccessMessage={UpdateSunccessModalMesg}
              handleOk={handleOk}
            />
          ) : null}

          {AddSuccessModalVisible == true ? (
            <SuccessAutoExitModal
              visibleModal={AddSuccessModalVisible}
              SuccessMessage={AddSunccessModalMesg}
              handleOk={handleOk2}
            />
          ) : null}

          {DeleteSuccessModalVisible == true ? (
            <AreYouSureOrange
              visibleModal={DeleteSuccessModalVisible}
              Message={DeleteSunccessModalMesg}
              ButtonText={"Delete"}
              onConfirm={() => handleConfirm()}
              onCancel={() => handleCancel()}
            />
          ) : null}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  TabContentContainer: {
    width: "100%",
  },
  notificationsContainer: {
    gap: 20,
    alignItems: "center",
    width: "100%",
    marginTop: 20,
  },
  userImage: {
    width: 20,
    height: 20,
    resizeMode: "contain",
    borderRadius: 20,
    backgroundColor: colors.bgColor,
  },
  notificationItem: {
    flexDirection: "column",
    gap: 5,
    backgroundColor: colors.royalBlue,
    padding: 10,
    borderRadius: 10,
    position: "relative",
    width: "100%",
  },
  notificationSender: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 14,
  },
  notificationMessage: {
    color: colors.white,
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 14,
  },
  notificationTimeText: {
    color: colors.orangeColour,
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 14,
  },

  WriteNotificationWrapper: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: colors.royalBlue,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    borderColor: colors.white,
    borderWidth: 1,
  },
  inputStyle: {
    width: "100%",
    height: 50,
    borderColor: "transparent",
    borderWidth: 0,
    paddingHorizontal: 8,
    fontFamily: "PlusJakartaSansExtraLight",
    color: colors.white,
    // outlineColor: "transparent",
    // outlineWidth: 0,
  },
  submitBtn: {
    backgroundColor: colors.orange,
    borderRadius: 5,
    padding: 5,
    paddingHorizontal: 10,
    marginRight: 10,
  },
  submitBtnText: {
    fontFamily: "PlusJakartaSansMedium",
    color: colors.white,
  },
  dateANdBtnWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  userNamePhotoContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 5,
  },
});
export default Notification;
