import React, { useState } from "react";
import {
  View,
  TextInput,
  StyleSheet,
  Platform,
  Dimensions,
} from "react-native";
import { colors } from "./colour";
import { Ionicons } from "@expo/vector-icons";

const screenWidth = Dimensions.get("window").width;

const SearchBar = ({ placeholder, onSearch, style }) => {
  const [searchText, setSearchText] = useState("");

  const handleSearch = (text: any) => {
    console.log("text", text);
    onSearch(text);
  };

  return (
    <View style={[styles.container, style]}>
      <Ionicons
        name="search"
        size={20}
        color={colors.grayColour}
        style={styles.searchIcon}
      />
      <TextInput
        style={styles.input}
        placeholder={placeholder}
        placeholderTextColor={colors.white}
        value={searchText}
        // onChangeText={setSearchText}
        onChangeText={(text) => {
          setSearchText(text);
          handleSearch(text);
        }}
      />
      {/* <TouchableOpacity onPress={handleSearch} style={styles.searchIcon}>
        <Ionicons name="search" size={24} color="gray" />
      </TouchableOpacity> */}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#f0f0f0",
    borderWidth: 1,
    borderColor: colors.grayColour,
    borderRadius: 5,
    paddingHorizontal: 10,
  },
  input: {
    flex: 1,
    paddingVertical: 5,
    fontFamily: "PlusJakartaSansExtraLight",
    // outlineColor: "transparent",
    outlineWidth: 0,
    height: Platform.OS === "web" && screenWidth > 760 ? 40 : 35,
    color: colors.white,
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 16 : 14,
  },
  searchIcon: {
    padding: 10,
  },
});

export default SearchBar;
