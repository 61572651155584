import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Pressable,
  Image,
  Dimensions,
  Platform,
  TouchableWithoutFeedback,
  Modal,
  TextInput,
} from "react-native";
import {
  H1,
  H3,
  H4,
  Label,
} from "../../../../components/reusable/TextComponent";
import { colors } from "../../../../components/reusable/colour";
import Icon from "react-native-vector-icons/Entypo";
import DropdownComponent from "../../../../components/reusable/DropdownComponent";
import SendIcon from "react-native-vector-icons/MaterialIcons";
import { DataStore } from "aws-amplify/datastore";
import { MatchMaster } from "../../../../models";
const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

const LiveURL = ({
  visibleModal3,
  handleClose3,
  MatchObj,
  Roundobj,
  MatchTeamMasterPlayerUpdate,
}) => {
  const [modalVisible, setModalVisible] = useState(visibleModal3);
  const [MatchObject, setMatchobject]: any = useState({});
  const [RoundObject, setRoundObject] = useState({});
  const [liveurl, setliveurl] = useState("");
  useEffect(() => {
    console.log("visibleModal3", MatchObj, visibleModal3);
    setMatchobject(MatchObj);
    setRoundObject(Roundobj);
  }, [visibleModal3, Roundobj, MatchObj]);

  const handleLiveURL = (e) => {
    console.log("handleLiveURL", e.target.value);
    setliveurl(e.target.value);
  };

  const handleUpdateLiveURL = async () => {
    console.log("MatchObject", MatchObject);
    try {
      const Matchgetdata = await DataStore.query(MatchMaster, MatchObject.id);
      if (Matchgetdata) {
        const updateMatchmaster = MatchMaster.copyOf(
          Matchgetdata,
          (updated) => {
            updated.LiveURL = liveurl !== "" ? liveurl : updated.LiveURL;

            // Assuming FixturesCreated is the correct property name
            return updated;
          }
        );

        const UpdatedMatchObj = await DataStore.save(updateMatchmaster);
        if (UpdatedMatchObj) {
          console.log("Updated RoundObj:", UpdatedMatchObj);
          MatchTeamMasterPlayerUpdate(
            UpdatedMatchObj.tournamentID,
            RoundObject
          );
        }
      }
    } catch (error) {}
  };

  return (
    <View>
      {/* modal started */}
      <TouchableWithoutFeedback>
        <Modal
          animationType="slide"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {}}
        >
          <View style={[styles.centeredView, { backgroundColor: "#04040491" }]}>
            <View style={styles.modalView}>
              <View style={{ padding: 10 }}>
                <Pressable
                  style={{ position: "absolute", right: 3, top: 3, zIndex: 2 }}
                  onPress={() => handleClose3()}
                >
                  <Icon name="cross" size={30} color={colors.red} />
                </Pressable>
                <H3
                  props={"Live Match URL"}
                  style={{
                    marginBottom: 15,
                    color: colors.white,
                  }}
                />
                <View style={styles.ShuffleItemContainer}>
                  <TextInput
                    style={styles.inputStyle}
                    // value={}
                    onChange={(e) => {
                      handleLiveURL(e);
                    }}
                    placeholder="Enter URL"
                  />
                  <Pressable
                    style={{ width: 50 }}
                    onPress={handleUpdateLiveURL}
                  >
                    <SendIcon name="send" size={40} color={colors.orange} />
                  </Pressable>
                </View>
              </View>
            </View>
          </View>
        </Modal>
      </TouchableWithoutFeedback>
      {/* modal ended */}
    </View>
  );
};
const styles = StyleSheet.create({
  //
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    // marginTop: 22,
  },
  modalView: {
    width: 550,
    maxWidth: "90%",
    margin: 20,
    backgroundColor: colors.royalBlue,
    borderRadius: 7,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    position: "relative",
  },

  ShuffleItemContainer: {
    padding: 10,
    backgroundColor: colors.bgColor,
    borderRadius: 5,
    // gap: 15,
    flexDirection: "row",
    alignItems: "center",
  },
  inputStyle: {
    flex: 1,
    height: 35,
    borderColor: colors.white,
    borderWidth: 1,
    paddingHorizontal: 8,
    fontFamily: "PlusJakartaSansExtraLight",
    outlineColor: "transparent",
    outlineWidth: 0,
    borderRadius: 5,
    color: colors.white,
  },
});
export default LiveURL;
