import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Text,
  View,
  Image,
  StyleSheet,
  Platform,
  Dimensions,
  Pressable,
  Linking,
} from "react-native";
import { colors } from "./colour";
import ShieldIcon from "react-native-vector-icons/Octicons";
import CheckMarkIcon from "react-native-vector-icons/Ionicons";
import CopyIcon from "react-native-vector-icons/Feather";
import * as Clipboard from "expo-clipboard";
import { useRoute } from "@react-navigation/native";
const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

const AreYouSureAqua = ({
  visibleModal,
  message,
  handleCancel,
  buttonText,
}) => {
  const [modalVisible, setModalVisible] = useState(visibleModal);

  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <Modal
        // animationType="slide"
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#4e4e4e80",
          }}
        >
          <View style={styles.modalView}>
            <View style={styles.modalTopPart}>
              <View style={styles.modalIconWrapper}>
                <ShieldIcon name="shield" size={22} color={colors.red} />
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.modalHeader}>Are you sure?</Text>
                <Text style={styles.modalSubHeader}>
                  {message}
                  {/* You want to create a new tournament? This action cannot be
                  undone. */}
                </Text>
              </View>
            </View>
            <View style={styles.modalBottomPart}>
              {/* cancel button */}
              <Pressable
                style={styles.outlineButton}
                onPress={handleCancel}
                // {() => setModalVisible(!modalVisible)}
              >
                <Text style={styles.buttonText}>Cancel</Text>
              </Pressable>

              {/* aqua button */}
              <Pressable
                style={[
                  styles.orangeButton,
                  { backgroundColor: colors.aqua, borderColor: colors.aqua },
                ]}
              >
                <Text style={[styles.buttonText, { color: colors.royalBlue }]}>
                  {buttonText}
                  {/* Yes, Create Tournament */}
                </Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>

      {/* <Button title="Open Modal" onPress={() => setModalVisible(true)} /> */}
    </View>
  );
};

// confirmation
const AreYouSureOrange = ({
  visibleModal,
  Message,
  ButtonText,
  onCancel,
  onConfirm,
}) => {
  const [modalVisible, setModalVisible] = useState(visibleModal);

  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <Modal
        // animationType="slide"
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#4e4e4e80",
          }}
        >
          <View style={styles.modalView}>
            <View style={styles.modalTopPart}>
              <View style={styles.modalIconWrapper}>
                <ShieldIcon name="shield" size={22} color={colors.red} />
              </View>
              <View style={{ flex: 1 }}>
                <Text style={styles.modalHeader}>Are you sure?</Text>
                <Text style={styles.modalSubHeader}>{Message}</Text>
              </View>
            </View>
            <View style={styles.modalBottomPart}>
              {/* cancel button */}
              <Pressable
                style={styles.outlineButton}
                onPress={() => onCancel()}
              >
                <Text style={styles.buttonText}>Cancel</Text>
              </Pressable>

              {/* orange button */}
              <Pressable
                style={[styles.orangeButton]}
                onPress={() => onConfirm()}
              >
                <Text style={[styles.buttonText]}>{ButtonText}</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>

      {/* <Button title="Open Modal" onPress={() => setModalVisible(true)} /> */}
    </View>
  );
};

// Confirmation box
const CommonModal = ({ visibleModal, message, handleCancel, onSubmit }) => {
  const [modalVisible, setModalVisible] = useState(visibleModal);

  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <Modal
        // animationType="slide"
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#4e4e4e80",
          }}
        >
          <View style={styles.modalView}>
            <View style={styles.modalTopPart}>
              {/* <View style={styles.modalIconWrapper}>
                <ShieldIcon name="shield" size={22} color={colors.red} />
              </View> */}
              <View style={{ flex: 1 }}>
                {/* <Text style={styles.modalHeader}>Are you sure?</Text> */}
                <Text style={styles.modalSubHeader}>
                  {message}
                  {/* You want to create a new tournament? This action cannot be
                  undone. */}
                </Text>
              </View>
            </View>
            <View style={styles.modalBottomPart}>
              {/* cancel button */}
              <Pressable
                style={styles.outlineButton}
                onPress={handleCancel}
                // {() => setModalVisible(!modalVisible)}
              >
                <Text style={styles.buttonText}>Cancel</Text>
              </Pressable>

              {/* aqua button */}
              <Pressable
                style={[
                  styles.orangeButton,
                  { backgroundColor: colors.aqua, borderColor: colors.aqua },
                ]}
                onPress={onSubmit}
              >
                <Text style={[styles.buttonText, { color: colors.royalBlue }]}>
                  Yes
                </Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>

      {/* <Button title="Open Modal" onPress={() => setModalVisible(true)} /> */}
    </View>
  );
};

// Error message
const ErrorModal = ({ visibleModal, header, SuccessMessage, onSubmit }) => {
  const [modalVisible, setModalVisible] = useState(false);
  useEffect(() => {
    console.log("visibleModal", visibleModal);
    setModalVisible(visibleModal);
  }, [visibleModal]);

  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <Modal
        // animationType="slide"
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {}}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#4e4e4e80",
          }}
        >
          <View style={styles.modalView}>
            <View
              style={[
                styles.modalTopPart,
                {
                  flexDirection: "column",
                  justifyContent: "center",
                  borderBottomWidth: 0,
                },
              ]}
            >
              <View style={styles.modalIconWrapper}>
                <ShieldIcon name="shield" size={22} color={colors.red} />
              </View>
              <View style={{}}>
                <Text style={[styles.modalHeader]}>{header}</Text>
              </View>
              <View style={{}}>
                <Text
                  style={[
                    styles.modalHeader,
                    { textAlign: "center", fontSize: 16 },
                  ]}
                >
                  {SuccessMessage}
                </Text>
              </View>
            </View>
            <View
              style={[styles.modalBottomPart, { justifyContent: "center" }]}
            >
              {/* aqua button */}
              <Pressable
                onPress={onSubmit}
                // {() => setModalVisible(!modalVisible)}
                style={[
                  styles.orangeButton,
                  {
                    backgroundColor: colors.royalBlue,
                    borderColor: colors.royalBlue,
                  },
                ]}
              >
                <Text style={[styles.buttonText, { color: colors.white }]}>
                  Okay
                </Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>

      {/* <Button title="Open Modal" onPress={() => setModalVisible(true)} /> */}
    </View>
  );
};

const ModalTrophy = ({ visibleModal, SuccessMessage, handleOk }) => {
  const [modalVisible, setModalVisible] = useState(visibleModal);

  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <Modal
        // animationType="slide"
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#4e4e4e80",
          }}
        >
          <View
            style={[
              styles.modalView,
              {
                padding: 15,
                paddingTop: 25,
                position: "relative",
                justifyContent: "center",
                alignItems: "center",
              },
            ]}
          >
            <Image
              source={{
                uri: require("../../../assets/icons/trophy.png"),
              }}
              style={styles.trophyIcon}
            />
            <Text style={styles.modalHeader}>Success! </Text>
            <Text style={styles.modalSubHeader}>{SuccessMessage}</Text>
            <View style={styles.modalBottomPart}>
              {/* cancel button */}
              <Pressable
                style={[styles.successButton]}
                onPress={() => handleOk()}
              >
                <Text style={[styles.buttonText]}>Ok</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>

      {/* <Button title="Open Modal" onPress={() => setModalVisible(true)} /> */}
    </View>
  );
};

// submitted form success modal
const SuccessModalNew = ({
  visibleModal,
  SuccessMessage,
  handleOk,
  handleClose,
  tournamentId,
  teamId,
}) => {
  const [modalVisible, setModalVisible] = useState(visibleModal);
  const [copiedText, setCopiedText] = React.useState("");

  const routePath = window.location.pathname;
  console.log("routePath", routePath);

  const originLocation = window.location.origin;
  console.log("origin location", originLocation);

  // const route = useRoute();
  // const { tournamentId, teamId }: any = route.params || {};

  const [tournamentID, setTournamentID] = useState("");
  const [teamID, setTeamID] = useState("");
  const [showCopied, setShowCopied] = useState(false);

  useEffect(() => {
    console.log("tournamentId", tournamentId, "teamId", teamId);
    setTournamentID(tournamentId);
    setTeamID(teamId);
  }, [tournamentID, teamID]);

  const copyToClipboard = async () => {
    console.log("tournamentID", tournamentID, "teamID", teamID);
    await Clipboard.setStringAsync(
      `${originLocation}/PlayerRegistration/tournamentId/${tournamentID}/teamId/${teamID}`
    );
    setShowCopied(true);

    setTimeout(() => {
      setShowCopied(false);
    }, 3000);
  };

  const fetchCopiedText = async () => {
    const text = await Clipboard.getStringAsync();
    console.log("text", text);
    setCopiedText(text);
  };

  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <Modal
        // animationType="slide"
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#4e4e4e80",
          }}
        >
          <View
            style={[
              styles.modalView,
              {
                padding: 15,
                paddingTop: 25,
                position: "relative",
                justifyContent: "center",
                alignItems: "center",
                gap: 10,
              },
            ]}
          >
            <CheckMarkIcon
              name="checkmark-circle-outline"
              size={40}
              color={colors.green}
            />
            <Text style={styles.modalHeader}>Success! </Text>
            <Text style={styles.modalSubHeader}>{SuccessMessage}</Text>

            {tournamentId && teamId ? (
              <>
                <Text style={styles.copyLinkText}>
                  Please send below link to your team so that players can
                  register themselves{" "}
                </Text>
                <Pressable
                  onPress={copyToClipboard}
                  style={styles.copyLinkContainer}
                >
                  <View style={styles.copyClipBoardContainer}>
                    <Text
                      numberOfLines={1}
                      style={styles.modalSubHeader}
                    >{`${originLocation}/PlayerRegistration/tournamentId/${tournamentID}/teamId/${teamID}`}</Text>
                    <CopyIcon name="copy" size={25} color={colors.green} />
                  </View>
                  <Text
                    style={[
                      styles.linkCopiedText,
                      { display: showCopied == true ? "flex" : "none" },
                    ]}
                  >
                    Link Copied
                  </Text>
                </Pressable>
              </>
            ) : null}

            <View style={styles.modalBottomPart}>
              {/* cancel button */}
              <Pressable
                style={[styles.successButton]}
                onPress={
                  handleOk
                  // setModalVisible(!modalVisible)
                }
              >
                <Text style={[styles.buttonText]}>Ok</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>

      {/* <Button title="Open Modal" onPress={() => setModalVisible(true)} /> */}
    </View>
  );
};

const ConfirmationModal = ({
  visibleModal,
  headermsg,
  message,

  handleMarkPaid,
  handleMarkUnpaid,
}) => {
  const [modalVisible, setModalVisible] = useState(visibleModal);

  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <Modal
        // animationType="slide"
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#4e4e4e80",
          }}
        >
          <View style={styles.modalView}>
            <View style={styles.modalTopPart}>
              {/* <View style={styles.modalIconWrapper}>
                <ShieldIcon name="shield" size={22} color={colors.red} />
              </View> */}
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text style={styles.modalHeader}>{headermsg}</Text>
                <Text style={styles.modalSubHeader}>
                  {message}
                  {/* You want to create a new tournament? This action cannot be
                  undone. */}
                </Text>
              </View>
            </View>
            <View style={styles.modalBottomPart}>
              <Pressable
                style={[
                  styles.orangeButton,
                  { backgroundColor: colors.red, borderColor: colors.red },
                ]}
                onPress={handleMarkUnpaid}
              >
                <Text style={[styles.buttonText, { color: colors.white }]}>
                  Reject
                </Text>
              </Pressable>

              {/* aqua button */}
              <Pressable
                style={[
                  styles.orangeButton,
                  { backgroundColor: colors.green, borderColor: colors.green },
                ]}
                onPress={handleMarkPaid}
              >
                <Text style={[styles.buttonText, { color: colors.white }]}>
                  Approve
                </Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>

      {/* <Button title="Open Modal" onPress={() => setModalVisible(true)} /> */}
    </View>
  );
};

const styles = StyleSheet.create({
  modalView: {
    width: screenWidth > 760 ? "100%" : "90%",
    maxHeight: 0.95 * screenHeight,
    maxWidth: 500,
    minHeight: 200,
    margin: 20,
    backgroundColor: colors.bgColor,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    position: "relative",
    justifyContent: "center",
    gap: 30,
  },
  modalTopPart: {
    borderBottomColor: colors.royalBlue,
    borderBottomWidth: 1,
    flexDirection: "row",
    alignItems: "center",
    gap: 20,
    padding: 15,
  },
  modalHeader: {
    fontFamily: "PlusJakartaSansExtraLight",
    color: colors.white,
    fontSize: 22,
    marginBottom: 10,
  },
  modalSubHeader: {
    fontFamily: "PlusJakartaSansRegular",
    color: colors.white,
    fontSize: 16,
    textAlign: "center",
  },
  modalIconWrapper: {
    width: 50,
    height: 50,
    backgroundColor: colors.royalBlue,
    borderRadius: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  modalBottomPart: {
    padding: 15,
    flexDirection: "row",
    gap: 10,
    justifyContent: "center",
  },
  outlineButton: {
    backgroundColor: "transparent",
    borderWidth: 1,
    borderColor: colors.white,
    borderRadius: 10,
    height: 35,
    minWidth: 120,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 15,
    paddingVertical: 4,
  },
  orangeButton: {
    backgroundColor: colors.orange,
    borderWidth: 1,
    borderColor: colors.orange,
    borderRadius: 10,
    height: 35,
    minWidth: 120,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 15,
    paddingVertical: 4,
  },
  buttonText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 16,
  },
  trophyIcon: {
    width: 200,
    height: 80,
    resizeMode: "contain",
  },
  successButton: {
    backgroundColor: colors.green,
    borderWidth: 1,
    borderColor: colors.green,
    borderRadius: 10,
    height: 35,
    minWidth: 120,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 15,
    paddingVertical: 4,
  },
  modalHeaderBig: {
    fontFamily: "PlusJakartaSansSemiBold",
    color: colors.white,
    fontSize: 28,
    marginBottom: 10,
  },
  copyClipBoardContainer: {
    flexDirection: "row",
    gap: 10,
    alignItems: "center",
  },
  copyLinkText: {
    color: colors.aqua,
    fontFamily: "PlusJakartaSansRegular",
    marginTop: 25,
    textAlign: "center",
  },
  copyLinkContainer: {
    backgroundColor: colors.newBlue,
    width: "100%",
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderRadius: 30,
    position: "relative",
  },
  linkCopiedText: {
    position: "absolute",
    right: 10,
    bottom: -45,
    color: colors.white,
    fontFamily: "PlusJakartaSansRegular",
    padding: 10,
    backgroundColor: colors.newBlue,
    borderRadius: 15,
  },
});

export {
  AreYouSureAqua,
  AreYouSureOrange,
  CommonModal,
  ErrorModal,
  ModalTrophy,
  SuccessModalNew,
  ConfirmationModal,
};
