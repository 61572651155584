import { useNavigation } from "@react-navigation/native";
import React, { useState, useEffect } from "react";
import {
  View,
  Platform,
  ScrollView,
  Text,
  StyleSheet,
  Pressable,
  Image,
  Dimensions,
  Modal,
  ActivityIndicator,
} from "react-native";
import SelectTeamModal from "./SelectTeamModal";
import { useRoute } from "@react-navigation/native";
import Layout from "../../../../components/include/Layout";

const screenWidth = Dimensions.get("window").width;
import {
  CommonHeading,
  H4,
  Label,
} from "../../../../components/reusable/TextComponent";
import { DataStore } from "aws-amplify/datastore";

import AddIcon from "react-native-vector-icons/Feather";
import {
  Squad,
  TeamRegistrations,
  Tournament,
  Venue,
} from "../../../../models";
import { colors } from "../../../../components/reusable/colour";
import { getUserMasterData } from "../../../../api/UserMasterAPI";
import { getAuthenticatedUser } from "../../../../api/AmplifyUserAPI";
import SuccessModal from "../../../../components/reusable/SuccessModal";
import { SuccessAutoExitModal } from "../../../../components/reusable/AutoExitModal";
import AsyncStorage from "@react-native-async-storage/async-storage";
import AppBar from "../../../../components/include/AppBar";

const SelectTeam = () => {
  const route = useRoute();
  const [playersData, setPlayersData]: any = useState([]);

  const [squadData, setSquadData]: any = useState([]);
  const { userUUID, squadIds, currentUserName, tournamentId }: any =
    route.params || {};

  const navigation: any = useNavigation();
  const [modalVisible, setModalVisible] = useState(false);
  const [squadId, setSquadId] = useState("");
  const [playersCount, setPlayersCount] = useState([]);
  // const [teamAlreadySubmittedFlag, setTeamAlreadySubmittedFlag] =
  //   useState(false);
  // const [teamsRegistrationsData, setTeamsRegistrationsData] = useState([]);
  const [selectedTournamentName, setSelectedTournamentName] = useState("");
  // const [filteredSquadName, setFilteredSquadName] = useState([]);
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [uuid, setUUID] = useState("");
  const [currentUserNameData, setCurrentUserNameData] = useState("");
  // const [filterSquadIds, setFilterSquadIds] = useState("");
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [successModalMesg, setSuccessModalMesg] = useState("");
  const [loading, setLoading] = useState(false);
  // const [teamEditFlag, setTeamEditFlag] = useState(false);
  let uuidtemp, userDetails;
  const defaultTeamLogo: any = require("../../../../../assets/home/logo.png");

  const handleOk = () => {
    setSuccessModalVisible(false);
  };

  useEffect(() => {
    // fetching all the observeQuery data

    console.log(
      "userUUID",
      userUUID,
      "squadIds",
      squadIds,
      "currentUserName",
      currentUserName,
      "tournamentId",
      tournamentId
    );
    const fetchDataToRender = async () => {
      let userArr = [];
      let squadsCount = 0;
      let squadIdsArr = [];

      const getUserdata = async () => {
        userDetails = await AsyncStorage.getItem("UserDetail");
        console.log("userDetails", userDetails);
        if (userDetails) {
          console.log("userDetails", userDetails);
          setUUID(userDetails);
        } else {
          console.log("No user found in temp storage");
        }
      };
      await getUserdata();

      try {
        setLoading(true); // Set loading state to true before fetching data
        // await getCurrentUser();
        await fetchData();

        setLoading(false); // Set loading state to false after data is fetched
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Ensure loading state is set to false even if an error occurs
      }
    };

    fetchDataToRender();
  }, [userUUID, selectedTournamentName, tournamentId]);

  const handleOpen = async (squadId: any) => {
    console.log(
      "selected Squad Id",
      squadId,
      "SELECTED tOURNAMENT",
      tournamentId
    );
    setSquadId(squadId);

    // Check if the squad has already been submitted
    const teamsRegistrations = DataStore.observeQuery(
      TeamRegistrations
    ).subscribe(async (snapshot) => {
      const initialTeamsRegistartionsBySquad = snapshot.items;
      console.log(
        "initialTeamsRegistartionsBySquad",
        initialTeamsRegistartionsBySquad
      );

      const filterData = initialTeamsRegistartionsBySquad.filter(
        (data) => data.Tournament === tournamentId && data.SquadId === squadId
      );
      console.log("filterData", filterData);

      // Check if the team has already been submitted and if modal should be opened
      if (filterData.length > 0) {
        setModalVisible(false);
        setSuccessModalVisible(true);
        setSuccessModalMesg(
          `This team has already been submitted in ${selectedTournamentName} tournament`
        );
      } else {
        setModalVisible(true);
        setSuccessModalVisible(false);
      }
    });
    return () => {
      teamsRegistrations.unsubscribe();
    };
  };

  const handleClose = () => {
    setModalVisible(false);
    setSquadId("");
  };

  const fetchData = async () => {
    const subscriptionForTournamanet = DataStore.observeQuery(Tournament, (c) =>
      c.id.eq(tournamentId)
    ).subscribe(async (snapshot) => {
      const initialTournaments = snapshot.items;
      console.log("initialTournaments", initialTournaments);
      const getTournamentName = initialTournaments[0].Name;
      setSelectedTournamentName(getTournamentName);
    });

    const squadSubscription = DataStore.observeQuery(Squad).subscribe(
      async (snapshot) => {
        const initialSquads = snapshot.items;
        console.log("initialSquads", initialSquads);

        // UserUUID= userDetails;
        // userDetails contain userUUID

        const filterSquads = initialSquads.filter(
          (data) =>
            data?.Admin1?.UUID === userDetails ||
            data?.Admin2?.UUID === userDetails
        );
        console.log("filterSquads", filterSquads);
        setSquadData(filterSquads);

        let squadTeamPlayersDataPromises = filterSquads.map(async (data) =>
          data.TeamPlayers.toArray()
        );
        console.log(
          "Squad Team Players Data Promises",
          squadTeamPlayersDataPromises
        );
        let playersData = await Promise.all(squadTeamPlayersDataPromises);
        console.log("PlayersData", playersData);

        const playersCountData = playersData.map((data) => data.length);
        console.log("playersCountData", playersCountData);
        setPlayersCount(playersCountData);
      }
    );

    return () => {
      subscriptionForTournamanet.unsubscribe();
      squadSubscription.unsubscribe();
    };
  };

  const handleAddTeam = () => {
    navigation.navigate("TeamParticipationForm", {
      tournamentId: tournamentId,
    });
  };

  const handleBack = () => {
    navigation.goBack();
  };
  return (
    <Layout>
      {Platform.OS !== "web" && (
        <AppBar
          title={"Select Team for the tournament"}
          showBackButton={true}
          onBackPress={handleBack}
        />
      )}
      <ScrollView>
        <View style={styles.container}>
          <View style={{ paddingVertical: 20 }}>
            {Platform.OS === "web" && (
              <CommonHeading props={"Select Team for the tournament"} />
            )}
            <View style={{ marginTop: 20, alignItems: "flex-start" }}>
              <Pressable style={styles.addTeamBtn} onPress={handleAddTeam}>
                <AddIcon name="plus-circle" size={18} color="#fff" />
                <Text
                  style={{
                    color: "#fff",
                    fontSize: 14,
                    fontFamily: "PlusJakartaSansSemiBold",
                  }}
                >
                  Add Team
                </Text>
              </Pressable>
            </View>
            <View
              style={{
                flexDirection:
                  Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
                gap: 25,
                flexWrap: "wrap",
                paddingVertical: 20,
              }}
            >
              {squadData && squadData.length > 0 ? (
                squadData.map((data: any, index: any) => (
                  <Pressable
                    key={index}
                    style={styles.myTeamItem}
                    onPress={async () => await handleOpen(data.id)}
                  >
                    <Image
                      source={data.Logo ? data.Logo : defaultTeamLogo}
                      style={styles.scoreTeamLogo}
                      resizeMode="contain"
                    />
                    <View style={styles.myTeamItemContent}>
                      <H4 props={data.Name} />
                      <View style={styles.myTeamItemContentBottom}>
                        <Label props={"Players : " + playersCount[index]} />
                        {data.createdAt != null ? (
                          <Label
                            props={
                              "Created : " +
                              data.createdAt.toString().split("T")[0]
                            }
                          />
                        ) : (
                          <Label props={"Created : "} />
                        )}
                      </View>
                    </View>
                  </Pressable>
                ))
              ) : (
                <Text>No teams found</Text>
              )}
            </View>
          </View>

          {modalVisible === true ? (
            <SelectTeamModal
              visibleModal={modalVisible}
              handleClose={handleClose}
              squadId={squadId}
              userUUID={userDetails}
              currentUserName={currentUserNameData}
              tournamentID={tournamentId}
              // teamEditFlagData={teamEditFlag}
            />
          ) : null}

          {/* {modalVisible === true ? (
            <SelectTeamModal
              visibleModal={modalVisible}
              handleClose={handleClose}
              squadId={squadId}
              userUUID={userUUID}
              currentUserName={currentUserName}
              tournamentID={tournamentId}
            />
          ) : null} */}

          {successModalVisible === true ? (
            <SuccessAutoExitModal
              visibleModal={successModalVisible}
              SuccessMessage={successModalMesg}
              handleOk={handleOk}
            />
          ) : null}

          {/* Loader */}
          {loading && (
            <Modal transparent animationType="none">
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}
              >
                <ActivityIndicator size="large" color="#0000ff" />
                <Text>Loading</Text>
              </View>
            </Modal>
          )}
        </View>
      </ScrollView>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    maxWidth: 1320,
    width: "100%",
    height: "100%",
    marginHorizontal: "auto",
    paddingRight: screenWidth > 760 ? 50 : 10,
    paddingLeft: screenWidth > 760 ? 50 : 10,
  },
  DashboardMainContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  addTeamBtn: {
    flexDirection: "row",
    gap: 5,
    backgroundColor: colors.orangeColour,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    minWidth: 100,
    padding: 5,
    paddingHorizontal: 12,
  },
  scoreTeamLogo: {
    width: 45,
    height: 45,
    resizeMode: "contain",
    borderRadius: 40,
  },
  myTeamItem: {
    flexDirection: "row",
    gap: 7,
    alignItems: "center",
    minWidth: Platform.OS === "web" && screenWidth > 760 ? "30%" : "100%",
    maxWidth: Platform.OS === "web" && screenWidth > 760 ? "33%" : "100%",
    flex: 1,
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    borderRadius: 7,
    padding: 5,
    paddingHorizontal: 5,
    paddingVertical: 5,
    backgroundColor: colors.royalBlue,
  },
  myTeamItemContent: {
    flexDirection: "column",
    flex: 1,
    gap: 5,
  },
  myTeamItemContentBottom: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

export default SelectTeam;
