import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Pressable,
  Image,
  Dimensions,
  Platform,
  Modal,
  TextInput,
  TouchableWithoutFeedback,
  Picker,
} from "react-native";
import { colors } from "../../../../components/reusable/colour";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

function AddTeamInGroup({
  visiblemodal,
  handleModalClose,
  GroupandTeamdata,
  AllTeamlist,
  Groupname,
  handleNewTeamInGroup,
  Tabactive,
}) {
  const [modalVisible, setModalVisible] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [defaultVenue, setdefaultVenue] = useState("");
  const [selectedGroup, setSelectedGroup] = useState();
  const [groupTeamData, setGroupTeamData] = useState([]);
  const [tabActive, settabActive] = useState();
  const [indexvalue, setindexvalue]: any = useState();
  const handlegetTeamData = (value, AllTeam) => {
    console.log("hellofinalvalue", value);
    let Arrlist = [];
    value.map((ele) => {
      Arrlist.push(ele.Teams);
    });
    const flatArr = Arrlist.flat();
    console.log("flatArr", flatArr, AllTeam);

    function compareAndPush(array1, array2) {
      const unmatchedValues = [];

      array2.forEach((obj2) => {
        const matchingObject = array1.find((obj1) => obj1.Teamid === obj2.ID);
        if (!matchingObject) {
          unmatchedValues.push(obj2);
        }
      });

      return unmatchedValues;
    }

    const unmatched = compareAndPush(flatArr, AllTeam);
    console.log("compareArrays", unmatched);
    let TeamsArr = [];

    setTeamList(unmatched);
  };
  useEffect(() => {
    console.log("GroupandTeamdata", GroupandTeamdata);
    setGroupTeamData(GroupandTeamdata);
    handlegetTeamData(GroupandTeamdata, AllTeamlist);
    setSelectedGroup(Groupname);
    settabActive(Tabactive);
    setModalVisible(visiblemodal);
  }, [visiblemodal, GroupandTeamdata, AllTeamlist, Groupname, Tabactive]);

  const handleTeamchange = (ele, index) => {
    console.log("handleTeamchange", selectedGroup, ele, teamList[index]);
    setindexvalue(index);
    setdefaultVenue(ele);
  };
  const handleSubmit = () => {
    let grouplist = JSON.parse(JSON.stringify(groupTeamData));
    console.log("grouplist", grouplist);
    grouplist.forEach((ele) => {
      if (selectedGroup == ele.GroupName) {
        ele.Teams.push({
          Logo: teamList[indexvalue].Logo,
          TeamName: teamList[indexvalue].TeamName,
          Teamid: teamList[indexvalue].ID,
          TeamShortName: teamList[indexvalue].TeamShortN,
        });
      }
    });

    let data = grouplist.find((ele) => selectedGroup == ele.GroupName);
    const arr = [data];
    console.log("UpdatedGrouplist", arr, data, grouplist);

    handleNewTeamInGroup(grouplist, arr, teamList[indexvalue], tabActive);
    handleModalClose();
  };
  return (
    <>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {}}
      >
        <View style={[styles.centeredView, { backgroundColor: "#04040491" }]}>
          <View style={styles.modalView}>
            <Text
              style={{
                color: colors.white,
                fontFamily: "PlusJakartaSansRegular",
              }}
            >
              Add Team
            </Text>
            <Picker
              style={styles.dropdownContainer}
              selectedValue={defaultVenue}
              onValueChange={(itemValue, itemIndex) =>
                handleTeamchange(itemValue, itemIndex - 1)
              }
            >
              <Picker.Item label="Select Team" value="Select Team" />
              {teamList &&
                teamList.length > 0 &&
                teamList.map((ele) => {
                  return (
                    <>
                      <Picker.Item label={ele.TeamName} value={ele.TeamName} />
                    </>
                  );
                })}
            </Picker>
            <View
              style={{
                marginTop: 40,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: 20,
              }}
            >
              <Pressable style={styles.cancelBtn} onPress={handleModalClose}>
                <Text style={styles.cancelBtnText}>Cancel</Text>
              </Pressable>
              <Pressable
                style={[styles.submitBtn]}
                onPress={() => handleSubmit()}
              >
                <Text style={[styles.submitButtonText]}>Submit</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
    </>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    // marginTop: 22,
  },
  modalView: {
    width: Platform.OS === "web" && screenWidth > 760 ? 450 : "90%",
    margin: 20,
    backgroundColor: colors.royalBlue,
    borderRadius: 5,
    padding: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    position: "relative",
  },
  dropdownContainer: {
    width: "100%",
    marginBottom: 0,
    height: 34,
    borderRadius: 5,
    backgroundColor: colors.royalBlue,
    color: colors.white,
    marginTop: 15,
  },
  cancelBtn: {
    borderWidth: 1,
    borderColor: colors.white,
    padding: 5,
    borderRadius: 10,
    fontSize: 14,
    width: 120,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
  },
  cancelBtnText: {
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 16,
    textAlign: "center",
    color: colors.aqua,
  },
  submitBtn: {
    borderWidth: 1,
    padding: 5,
    borderRadius: 10,
    fontSize: 14,
    width: 120,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    borderColor: colors.aqua,
    backgroundColor: colors.aqua,
  },
  submitButtonText: {
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 16,
    textAlign: "center",
    color: colors.royalBlue,
  },
});

export default AddTeamInGroup;
