import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Pressable,
  Image,
  Dimensions,
  Platform,
  TouchableWithoutFeedback,
  Modal,
} from "react-native";
import {
  H1,
  H3,
  H4,
  Label,
} from "../../../../components/reusable/TextComponent";
import { colors } from "../../../../components/reusable//colour";
import Icon from "react-native-vector-icons/Entypo";
import DropdownComponent from "../../../../components/reusable/DropdownComponent";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

const ShuffleTeamModal = ({
  Groupname,
  SelectedTeamObj,
  visibleModal2,
  handleClose2,
  GroupandTeamdata,
  handleSuffleTeamCallBack,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [Grouplist, setGrouplist] = useState([]);
  const [Data, setData] = useState([]);
  const [SelectedGroupTeamList, setSelectedGroupTeamList] = useState([]);
  const [SelectedTeamoj, setSelectedTeamoj]: any = useState({});
  const [Selectedgroup, setSelectedgroup]: any = useState({});
  const GetGroupTeamList = async (data, SelectedGroupname, SelecteTeamobj) => {
    console.log("GetGroupTeamList", data, SelectedGroupname, SelecteTeamobj);
    let Group = [];
    setData(data);
    data &&
      data.length > 0 &&
      data.map((ele) => {
        if (ele.GroupName !== SelectedGroupname) {
          Group.push({
            label: ele.GroupName,
            value: ele.GroupName,
          });
        }
      });
    console.log("Group", Group);
    setGrouplist(Group);
  };
  useEffect(() => {
    console.log("visibleModal2", GroupandTeamdata, visibleModal2);
    GetGroupTeamList(GroupandTeamdata, Groupname, SelectedTeamObj);
    setModalVisible(visibleModal2);
  }, [visibleModal2, GroupandTeamdata, Groupname, SelectedTeamObj]);

  const inputEvent = (value) => {
    console.log("inputEvent", value);
    setSelectedgroup(value);
    let Teamsdata;
    Data &&
      Data.length > 0 &&
      Data.map((ele) => {
        if (ele.GroupName == value.label) {
          console.log("TeamsData", ele.Teams);
          Teamsdata = ele.Teams;
        }
      });
    let TeamsArr = [];
    Teamsdata.map((val) => {
      TeamsArr.push({
        label: val.TeamName,
        value: val.TeamName,
      });
    });
    TeamsArr.push({
      label: "None",
      value: "None",
    });
    console.log("TeamsArr", TeamsArr);
    setSelectedGroupTeamList(TeamsArr);
  };
  const handleTeamSelect = (SelectedTeam) => {
    console.log("SelectedTeam", SelectedTeam);
    setSelectedTeamoj(SelectedTeam);
  };

  const handleSubmit = () => {
    console.log(
      "hellosubmit",
      Data,
      SelectedTeamObj,
      Groupname,
      Selectedgroup,
      SelectedTeamoj
    );
    let TeamTwoObj;
    let TeamOneList = [];
    let TeamTwoList = [];
    Data.map((ele) => {
      if (ele.GroupName == Selectedgroup.label) {
        TeamTwoList = ele.Teams.filter(
          (val) => val.TeamName !== SelectedTeamoj.label
        );
        TeamTwoObj = ele.Teams.filter(
          (val) => val.TeamName == SelectedTeamoj.label
        );
      }
      if (ele.GroupName == Groupname) {
        TeamOneList = ele.Teams.filter(
          (val) => val.TeamName !== SelectedTeamObj.TeamName
        );
      }
    });
    console.log("hellofinalTeam", TeamTwoObj[0], SelectedTeamObj);
    if (TeamTwoObj[0] !== undefined) {
      TeamOneList.push(TeamTwoObj[0]);
    }
    if (SelectedTeamObj !== undefined) {
      TeamTwoList.push(SelectedTeamObj);
    }

    console.log(
      "TeamOneObj",
      TeamOneList,
      TeamTwoList,
      SelectedTeamObj,
      TeamTwoObj
    );

    let Groupsdata = [...Data];
    console.log("finalGroupsdata", Groupsdata);
    const updatedArray = Groupsdata.map((ele) => {
      if (ele.GroupName == Selectedgroup.label) {
        return { GroupName: ele.GroupName, Teams: TeamTwoList };
      }
      if (ele.GroupName == Groupname) {
        return { GroupName: ele.GroupName, Teams: TeamOneList };
      }
      return ele;
    });

    console.log("updateArray", Data, updatedArray);
    handleSuffleTeamCallBack(updatedArray, []);
  };
  return (
    <View>
      {/* modal started */}
      <TouchableWithoutFeedback onPress={handleClose2}>
        <Modal
          animationType="slide"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {}}
        >
          <View style={[styles.centeredView, { backgroundColor: "#04040491" }]}>
            <View style={styles.modalView}>
              <View style={{ padding: 10 }}>
                <Pressable
                  style={{ position: "absolute", right: 3, top: 3, zIndex: 2 }}
                  onPress={() => handleClose2()}
                >
                  <Icon name="cross" size={30} color="red" />
                </Pressable>
                <H3
                  props={"Swap Team"}
                  style={{
                    marginBottom: 15,
                    color: colors.white,
                  }}
                />
                <View style={styles.ShuffleItemContainer}>
                  <View>
                    <Text style={styles.ShuffleDropdownLabel}>Group</Text>
                    <View style={styles.dropdownContainer}>
                      <DropdownComponent
                        data={Grouplist}
                        labelField={"label"}
                        valueField={"label"}
                        onChange={(e) => {
                          inputEvent(e);
                        }}
                        value={undefined}
                      />
                    </View>
                  </View>
                  {SelectedGroupTeamList && SelectedGroupTeamList.length > 0 ? (
                    <View>
                      <Text style={styles.ShuffleDropdownLabel}>Team</Text>
                      <View style={styles.dropdownContainer}>
                        <DropdownComponent
                          labelField={"label"}
                          valueField={"label"}
                          data={SelectedGroupTeamList}
                          onChange={(e) => {
                            handleTeamSelect(e);
                          }}
                          value={undefined}
                        />
                      </View>
                    </View>
                  ) : null}
                  <View style={{ alignItems: "center" }}>
                    <Pressable
                      style={{
                        backgroundColor: colors.aqua,
                        borderRadius: 7,
                        paddingHorizontal: 12,
                        paddingVertical: 5,
                      }}
                      onPress={handleSubmit}
                    >
                      <Text style={styles.ShuffleModalButton}>Swap Team</Text>
                    </Pressable>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </Modal>
      </TouchableWithoutFeedback>
      {/* modal ended */}
    </View>
  );
};
const styles = StyleSheet.create({
  //
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    // marginTop: 22,
  },
  modalView: {
    width: 550,
    maxWidth: "90%",
    margin: 20,
    backgroundColor: colors.royalBlue,
    borderRadius: 7,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    position: "relative",
  },
  dropdownContainer: {
    borderColor: "gray",
    borderWidth: 0.5,
    borderRadius: 8,
    paddingHorizontal: 5,
    color: colors.white,
  },
  ShuffleDropdownLabel: {
    fontSize: 16,
    fontFamily: "PlusJakartaSansSemiBold",
    color: colors.white,
    marginBottom: 5,
  },
  ShuffleItemContainer: {
    padding: 10,
    backgroundColor: colors.bgColor,
    borderRadius: 5,
    gap: 15,
  },
  ShuffleModalButton: {
    fontSize: 15,
    fontFamily: "PlusJakartaSansMedium",
    color: colors.royalBlue,
  },
});
export default ShuffleTeamModal;
