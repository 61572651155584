import React, { useState, useRef, useEffect, useContext } from "react";
import { Ionicons } from "@expo/vector-icons";
import {
  View,
  Text,
  Image,
  StyleSheet,
  Platform,
  Dimensions,
  Pressable,
  ActivityIndicator,
} from "react-native";
// import { signUp } from "aws-amplify/auth";
import TextField, {
  MobileNumberField,
} from "../../../components/reusable/textFeild";
import { useNavigation } from "@react-navigation/native";
import { H1, H2, H3 } from "../../../components/reusable/TextComponent";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AuthenticationContext } from "../../../authentication/Authentication.js";
import { LinearGradient } from "expo-linear-gradient";
import Layout from "../../../components/include/Layout";
import { colors } from "../../../components/reusable/colour";
import { ErrorModal } from "../../../components/reusable/AreYouSure";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

const SignUp = ({ route }) => {
  const { prevPage, nextPage } = route.params.data;
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorModalMesg, setErrorModalMesg] = useState("");
  const cardRef = useRef();
  const [error, setError] = useState({ showError: false, message: "" });
  const navigation = useNavigation();
  const [loading, setLoading] = useState(false);

  const [isSignUp, setIsSignUp] = useState(false);
  const [hoverIn, setHoverIn] = useState(false);
  const { handleSignUp } = useContext(AuthenticationContext);
  const handleHoverIn = () => {
    setHoverIn(true);
  };
  const handleHoverOut = () => {
    setHoverIn(false);
  };

  const setShowError = (show, message) => {
    setError({ showError: show, message: message });
  };

  const handleSubmit = () => {
    setErrorModalVisible(!errorModalVisible);
    navigation.navigate("SignIn");
  };

  let data;
  useEffect(() => {
    // verifyAuth();
  }, []);

  // const signIn = async (mobileNo) => {
  //   setMessage(VERIFYNUMBER);
  //   console.log("Mobile No : ", mobileNo);

  //   await Auth.signIn(mobileNo)
  //     .then((result) => {
  //       console.log("Result : ", result);
  //       setSession(result);
  //       setMessage(WAITINGFOROTP);
  //     })
  //     .catch((e) => {
  //       if (e.code === "UserNotFoundException") {
  //         signUp("+91" + mobile);
  //       } else if (e.code === "UsernameExistsException") {
  //         setMessage(WAITINGFOROTP);
  //         signIn("+91" + mobile);
  //       } else {
  //         console.log(e.code);
  //         console.error(e);
  //       }
  //     });
  // };

  const signUp = async (mobileNo) => {
    setIsSignUp(true);
    console.log(mobileNo);
    const signUpResult = await handleSignUp(mobileNo);
    if (signUpResult.status == "Success") {
      // await saveData();
      console.log("SignUp Response", signUpResult.result);
      return signUpResult.result.userId;
    } else {
      console.log("SignUp Response", signUpResult.result);
      if (signUpResult.result.toString().includes("UsernameExistsException")) {
        setErrorModalMesg(
          "You have already signed up for Track My Tournament, please sign in to continue!"
        );
        setLoading(false);
        setErrorModalVisible(true);
      } else {
        setErrorModalMesg(
          "Looks like aliens have invaded our datacenter. Please try another time while our team of Avengers get rid of them!"
        );
        setLoading(false);
        setErrorModalVisible(true);
      }
      return "error";
    }
  };

  let sendData;
  const handleFormSubmit = async () => {
    if (mobile === "") {
      setShowError(
        true,
        "Please fill out all required fields to complete sign up"
      );
      return;
    } else if (!/^\d{10}$/.test(mobile.trim())) {
      setShowError(
        true,
        "There was a problem logging in. Check your Mobile Number or create an account."
      );
      return;
    } else if (name === "") {
      setShowError(
        true,
        "Please fill out all required fields to complete sign up"
      );
      return;
    } else if (email === "") {
      setShowError(
        true,
        "Please fill out all required fields to complete sign up"
      );
      return;
    }
    setLoading(true);
    const id = await signUp("+91" + mobile);
    setShowOTP(true);
    sendData = {
      mobile: mobile.trim(),
      name: name.trim(),
      isSignUp: true,
      session: "",
      email: email.trim(),
      userId: id,
      prevPage: prevPage,
      nextPage: nextPage,
    };
    console.log("Focused on SigIn screen");
    console.log("showOTP:", showOTP);
    setLoading(false);
    navigation.navigate("OTPVerification", {
      data: sendData,
    });
  };

  return (
    <View>
      {Platform.OS === "web" && screenWidth > 760 ? (
        <Layout>
          <View style={styleByAkash.signInMainContainer}>
            <LinearGradient
              style={styleByAkash.signInLeft}
              colors={["#0D0535", "#1E0C80"]}
              start={{ x: 1, y: 0 }}
              end={{ x: 1, y: 1 }}
            >
              <View style={styleByAkash.signInLeftInner}>
                <Text style={styleByAkash.signInHeading}>Welcome Back</Text>
                <Text style={styleByAkash.signInSmallHeading}>
                  One Platform,
                </Text>
                <Text style={styleByAkash.signInSmallHeading}>
                  Infinite Possibilities.
                </Text>
                <View style={{ width: "100%", alignItems: "flex-end" }}>
                  <Image
                    source={require("../../../../assets/login/LoginImage.png")}
                    style={styleByAkash.logoMainImage}
                  />
                </View>
              </View>
              <Image
                source={require("../../../../assets/login/LoginElement.png")}
                style={styleByAkash.absoluteIcon}
              />
            </LinearGradient>
            <View style={styleByAkash.signInRight}>
              <View style={styleByAkash.SignInContainer}>
                <Text style={styleByAkash.signupStyle}>Sign Up</Text>
                <Text style={styleByAkash.createAccountText}>
                  Please enter your Name & Mobile Number.
                </Text>
                {error.showError && (
                  <View style={styleByAkash.errorContainer}>
                    <Ionicons
                      name="information-circle"
                      size={18}
                      color="white"
                    />
                    <Text style={styleByAkash.errorText}>{error.message}</Text>
                  </View>
                )}
                <TextField
                  label="Name"
                  placeholder="Enter your name"
                  value={name}
                  onChangeText={setName}
                  keyboardType={undefined}
                  validation={undefined}
                  containerStyle={styles.textFeild}
                />
                <MobileNumberField
                  label="Mobile No"
                  placeholder="Enter your mobile number"
                  value={mobile}
                  onChangeText={setMobile}
                  validation={/^\d{9}$/}
                  containerStyle={styles.mobTextFeild}
                  onSubmitEditing={handleFormSubmit}
                />
                <TextField
                  label="Email"
                  placeholder="Enter your email"
                  value={email}
                  onChangeText={setEmail}
                  keyboardType={undefined}
                  validation={undefined}
                  containerStyle={styles.textFeild}
                />
                <Text style={styleByAkash.bySignIn}>
                  By signing in you are agreeing to our{" "}
                  <Text style={styleByAkash.termCondition}>
                    Terms and Privacy Policy
                  </Text>
                </Text>
                <Pressable
                  onPress={handleFormSubmit}
                  onHoverIn={handleHoverIn}
                  onHoverOut={handleHoverOut}
                  style={[
                    styleByAkash.button,
                    {
                      backgroundColor:
                        hoverIn === true ? colors.orange : colors.aqua,
                    },
                  ]}
                >
                  <Text
                    style={[
                      styleByAkash.buttonText,
                      {
                        color:
                          hoverIn === true ? colors.white : colors.royalBlue,
                      },
                    ]}
                  >
                    Sign Up
                  </Text>
                  {loading && (
                    <ActivityIndicator
                      size="small"
                      color="#0000ff"
                      // style={{ position: "absolute", right: 50 }}
                    />
                  )}
                </Pressable>

                <View style={styleByAkash.alreadyHaveContainer}>
                  <Text style={[styleByAkash.areYouNewText]}>
                    Already have an account?{" "}
                  </Text>
                  <Pressable
                    onPress={() => {
                      let sendData = {
                        prevPage: "",
                        nextPage: "",
                        userId: "",
                      };
                      navigation.navigate("SignIn", {
                        data: sendData,
                      });
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "PlusJakartaSansSemiBold",
                        fontSize: 14,
                        color: colors.orange,
                      }}
                    >
                      Sign In
                    </Text>
                  </Pressable>
                </View>
              </View>
            </View>
            {errorModalVisible == true ? (
              <ErrorModal
                visibleModal={errorModalVisible}
                SuccessMessage={errorModalMesg}
                onSubmit={handleSubmit}
                header={"Error!"}
              />
            ) : null}
          </View>
        </Layout>
      ) : (
        <View style={styleByAkash.signInMainContainer}>
          <View style={styleByAkash.signInRight}>
            <View style={styleByAkash.SignInContainer}>
              <H1 props={"Sign Up"} style={styleByAkash.signupStyle}></H1>

              <Text style={styleByAkash.createAccountText}>
                Please enter your Name & Mobile Number.
              </Text>
              {error.showError && (
                <View style={styleByAkash.errorContainer}>
                  <Ionicons name="information-circle" size={18} color="white" />
                  <Text style={styleByAkash.errorText}>{error.message}</Text>
                </View>
              )}
              <TextField
                label="Name"
                placeholder="Enter your name"
                value={name}
                onChangeText={setName}
                keyboardType={undefined}
                validation={undefined}
                containerStyle={styles.textFeild}
              />
              <MobileNumberField
                label="Mobile No"
                placeholder="Enter your mobile number"
                value={mobile}
                onChangeText={setMobile}
                validation={/^\d{9}$/}
                onSubmitEditing={handleFormSubmit}
                containerStyle={styles.mobTextFeild}
              />
              <TextField
                label="Email"
                placeholder="Enter your email"
                value={email}
                onChangeText={setEmail}
                keyboardType={undefined}
                validation={undefined}
                containerStyle={styles.textFeild}
              />
              <Text style={styleByAkash.bySignIn}>
                By signing in you are agreeing to our{" "}
                <Text style={styleByAkash.termCondition}>
                  Terms and Privacy Policy
                </Text>
              </Text>
              <Pressable
                onPress={handleFormSubmit}
                onHoverIn={handleHoverIn}
                onHoverOut={handleHoverOut}
                style={[
                  styleByAkash.button,
                  {
                    backgroundColor:
                      hoverIn === true ? colors.orange : colors.aqua,
                  },
                ]}
              >
                <Text
                  style={[
                    styleByAkash.buttonText,
                    {
                      color: hoverIn === true ? colors.white : colors.royalBlue,
                    },
                  ]}
                >
                  Sign Up
                </Text>
                {loading && (
                  <ActivityIndicator
                    size="small"
                    color="#0000ff"
                    // style={{ position: "absolute", right: 50 }}
                  />
                )}
              </Pressable>

              <View style={styleByAkash.alreadyHaveContainer}>
                <Text style={[styleByAkash.areYouNewText]}>
                  Already have an account?{" "}
                </Text>
                <Pressable
                  onPress={() => {
                    let sendData = {
                      prevPage: "",
                      nextPage: "",
                      userId: "",
                    };
                    navigation.navigate("SignIn", {
                      data: sendData,
                    });
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "PlusJakartaSansSemiBold",
                      fontSize:
                        Platform.OS === "web" && screenWidth > 760 ? 14 : 16,
                      color: colors.orange,
                    }}
                  >
                    Sign In
                  </Text>
                </Pressable>
              </View>
            </View>
          </View>
        </View>
      )}
    </View>
  );
};

const styleByAkash = StyleSheet.create({
  signInMainContainer: {
    width: "100%",
    height:
      Platform.OS === "web" && screenWidth > 760
        ? screenHeight * 0.9
        : screenHeight,
    backgroundColor: colors.bgColor,
    flexDirection: "row",
  },
  signInLeft: {
    flex: 1,
    padding: 50,
    position: "relative",
  },
  signInLeftInner: {
    backgroundColor: colors.blue,
    borderRadius: 20,
    padding: 30,
    paddingBottom: 0,
  },
  signInHeading: {
    color: colors.white,
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 35,
    marginBottom: 10,
  },
  signInSmallHeading: {
    color: colors.white,
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 20,
    marginTop: 5,
  },
  logoMainImage: {
    width: 235,
    height: 300,
    resizeMode: "contain",
  },
  absoluteIcon: {
    width: 195,
    height: 300,
    resizeMode: "contain",
    position: "absolute",
    left: 0,
    bottom: 0,
  },
  signInRight: {
    flex: 1,
    padding: Platform.OS === "web" && screenWidth > 760 ? 50 : 25,
    alignItems: "center",
  },
  SignInContainer: {
    width: "100%",
    height: "100%",
    maxWidth: 420,
    justifyContent: "center",
  },
  signupStyle: {
    textAlign: "center",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 20 : 30,
    fontFamily: "PlusJakartaSansMedium",
    color: colors.orange,
    marginBottom: 10,
  },
  createAccountText: {
    textAlign: "center",
    fontSize: 16,
    fontFamily: "PlusJakartaSansRegular",
    color: colors.white,
    marginBottom: 40,
  },
  button: {
    backgroundColor: colors.aqua,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 15,
    height: Platform.OS === "web" && screenWidth > 760 ? 38 : 45,
    flexDirection: "row",
    gap: 30,
  },

  buttonText: {
    color: colors.royalBlue,
    fontSize: 18,
    textAlign: "center",
    fontFamily: "PlusJakartaSansSemiBold",
  },
  bySignIn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    gap: 4,
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 11 : 13,
    fontFamily: "PlusJakartaSansRegular",
    color: colors.white,
    marginTop: 25,
    marginBottom: 35,
  },
  termCondition: {
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 10 : 12,
    color: colors.orange,
  },
  alreadyHaveContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 5,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: 10,
  },
  areYouNewText: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: 2,
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 11 : 14,
    fontFamily: "PlusJakartaSansRegular",
    color: colors.white,
  },
  mobTextFeild: {
    width: "100%",
    marginTop: Platform.OS === "web" && screenWidth > 760 ? -10 : -25,
    borderColor: colors.white,
  },

  errorContainer: {
    backgroundColor: colors.errorRed,
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    marginBottom: 10,
    gap: 10,
    borderWidth: 1,
    borderRadius: 15,
  },
  errorText: {
    color: "white",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 14 : 12,
    fontFamily: "PlusJakartaSansRegular",
  },
});

const styles = StyleSheet.create({
  loginForm: {
    // flex: Platform.OS === "web" && screenWidth > 760 ? 1 : undefined,
    padding: Platform.OS === "web" && screenWidth > 760 ? 70 : 30,
    backgroundColor: "white",
    borderRadius: 10,
    height: Platform.OS === "web" && screenWidth > 760 ? undefined : 500,
    width: Platform.OS === "web" && screenWidth > 760 ? 500 : 390,
    justifyContent: "center",
  },
  leftContent: {
    display: "flex",
    position: "absolute",
    left: 150,
    bottom: 70,
  },
  headingText: {
    color: "#fff",
    fontSize: 60,
    bottom: 80,
  },
  subHeadingText: {
    color: "#fff",
    fontSize: 30,
    bottom: 60,
  },
  signupStyle: {
    textAlign: "center",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 20 : 22,
    fontWeight: "bold",
    fontFamily: "PlusJakartaSansMedium",
    color: "rgba(104, 104, 104, 1)",
    marginBottom: 10,
  },

  textFeild: {
    width: "100%",
    outlineColor: "transparent",
    outlineWidth: 0,
  },
  mobTextFeild: {
    width: "100%",
    // marginTop: Platform.OS === "web" && screenWidth > 760 ? -10 : -25,
    borderColor: colors.white,
  },
  bySignIn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    gap: 2,
    fontSize: 10,
    fontFamily: "PlusJakartaSansRegular",
    color: "rgba(104, 104, 104, 1)",
    // paddingTop: 15,
    paddingBottom: 25,
  },
  termCondition: {
    fontSize: 10,
    color: "rgba(50, 123, 237, 1)",
  },
  alreadyHaveContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 5,
    justifyContent: "center",
    position: "absolute",

    bottom: 20,
    left: Platform.OS === "web" && screenWidth > 760 ? 0 : 25,
    width: "100%",
    color: "Grey",
    fontFamily: "PlusJakartaSansRegular",
  },
  signInButton: {
    textAlign: "center",
    color: "rgba(27, 133, 243, 1)",

    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 12 : 14,
  },
  createAccountText: {
    textAlign: "center",
    fontSize: 14,
    fontFamily: "PlusJakartaSansRegular",
    color: "rgba(104, 104, 104, 1)",
    marginBottom: Platform.OS === "web" && screenWidth > 760 ? 20 : 5,
  },
  button: {
    backgroundColor: "rgba(18, 255, 227, 1)",
    padding: 15,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 15,
  },
  buttonText: {
    color: "rgba(2, 32, 95, 1)",
    fontSize: 16,
    textAlign: "center",
  },
  backgroundImage: {
    resizeMode: "cover",
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems:
      Platform.OS === "web" && screenWidth > 760 ? "flex-end" : "center",
    paddingHorizontal:
      Platform.OS === "web" && screenWidth > 760 ? 80 : undefined,
    backgroundImage:
      "linear-gradient(rgba(13, 5, 53, 1), rgba(30, 12, 128, 1))",
    position: "relative",
  },
});

export default SignUp;
