import React from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  Dimensions,
} from "react-native";
import { colors } from "../../../../components/reusable/colour";
import ClockIcon from "react-native-vector-icons/AntDesign";
import BarIcon from "react-native-vector-icons/Ionicons";
import StarIcon from "react-native-vector-icons/AntDesign";
const screenWidth = Dimensions.get("window").width;

const PlayerPerformance = () => {
  return (
    <View style={styles.PPSection}>
      <View style={styles.container}>
        <View style={styles.PPContainer}>
          <View style={styles.PPLeft}>
            <Image
              source={require("../../../../../assets/features/leftSide.png")}
              style={styles.PPLeftImage}
            />
          </View>
          <View style={styles.PPRight}>
            <Text style={styles.orangeHeading}>Player performance</Text>
            <Text style={styles.FeaturesHeading}>
              Track Player Performance with Precision and Insight
            </Text>
            <View style={styles.PPRightContentContainer}>
              <View style={styles.PPRightItem}>
                <View style={styles.TMItemHeadingWrapper}>
                  <ClockIcon name="clockcircleo" size={20} color="#ff5733" />
                  <Text style={styles.TMItemHeading}>
                    Stay Informed Instantly:
                  </Text>
                </View>
                <Text style={styles.PPText}>
                  Keep up with real-time scores, rankings, and match schedules.
                  No more waiting for manual updates—everything you need is at
                  your fingertips.
                </Text>
              </View>
              <View style={styles.PPRightItem}>
                <View style={styles.TMItemHeadingWrapper}>
                  <BarIcon name="bar-chart-sharp" size={20} color="#ff5733" />
                  <Text style={styles.TMItemHeading}>
                    Comprehensive Records:
                  </Text>
                </View>
                <Text style={styles.PPText}>
                  Effortlessly record and organize match results, goals, and
                  cards, providing a comprehensive record of past performances
                  for teams and players.
                </Text>
              </View>
              <View style={styles.PPRightItem}>
                <View style={styles.TMItemHeadingWrapper}>
                  <StarIcon name="star" size={20} color="#ff5733" />
                  <Text style={styles.TMItemHeading}>Detailed Insights: </Text>
                </View>
                <Text style={styles.PPText}>
                  Explore detailed player profiles to track individual
                  performance, match history, and achievements.
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  PPSection: {
    width: "100%",
    paddingVertical: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
  },
  container: {
    maxWidth: 1320,
    width: "100%",
    height: "100%",
    marginHorizontal: "auto",
    paddingRight: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
    paddingLeft: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
  },
  PPContainer: {
    width: "100%",
    padding: 25,
    borderRadius: 15,
    backgroundColor: colors.blue,
    flexDirection: "row",
    gap: 50,
  },
  PPLeft: {
    width: "45%",
    display: Platform.OS === "web" && screenWidth > 760 ? "flex" : "none",
  },
  PPRight: {
    flex: 1,
  },
  orangeHeading: {
    color: colors.orangeColour,
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 18 : 16,
    fontFamily:
      Platform.OS === "web" && screenWidth > 760
        ? "PlusJakartaSansSemiBold"
        : "PlusJakartaSansSemiBold",
    textTransform: "uppercase",
    marginBottom: 10,
  },
  FeaturesHeading: {
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 30 : 20,
    fontFamily:
      Platform.OS === "web" && screenWidth > 760
        ? "PlusJakartaSansSemiBold"
        : "PlusJakartaSansSemiBold",
    color: colors.white,
  },
  PPRightContentContainer: {
    flexDirection: "column",
    gap: 20,
    marginTop: 25,
  },
  PPRightItem: {
    flexDirection: "column",
    gap: 10,
  },
  TMItemHeadingWrapper: {
    flexDirection: "row",
    gap: 5,
    alignItems: "center",
    marginBottom: 5,
  },
  TMItemHeading: {
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 20,
    color: colors.white,
  },
  PPText: {
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 14,
    color: colors.white,
  },
  PPLeftImage: {
    width: "100%",
    height: "100%",
    marginLeft: -150,
    resizeMode: "contain",
  },
});
export default PlayerPerformance;
