import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Pressable,
  Image,
  Dimensions,
  Platform,
  TouchableWithoutFeedback,
  Modal,
} from "react-native";
import { H1, H3, H4, Label } from "./TextComponent";
import { colors } from "./colour";
import Icon from "react-native-vector-icons/Entypo";
import CheckMark from "react-native-vector-icons/AntDesign";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

const SuccessModal = ({
  visibleModal,
  handleClose,
  handleConfirm,
  handleOk,
  SuccessMessage,
}) => {
  const [modalVisible, setModalVisible] = useState(visibleModal);
  useEffect(() => {
    console.log("visibleModal", visibleModal);
  }, [visibleModal]);

  return (
    <View>
      {/* modal started */}
      <TouchableWithoutFeedback onPress={handleClose}>
        <Modal
          animationType="slide"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {}}
        >
          <View style={[styles.centeredView, { backgroundColor: "#04040491" }]}>
            <View style={styles.modalView}>
              <View>
                <View
                  style={{
                    backgroundColor: "#2EB405",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 8,
                    borderRadius: 7,
                  }}
                >
                  <CheckMark name="checkcircle" size={30} color="#fff" />
                </View>
                <View
                  style={{
                    gap: Platform.OS == "web" && screenWidth > 760 ? 35 : 25,
                    padding: 20,
                    paddingTop:
                      Platform.OS == "web" && screenWidth > 760 ? 60 : 20,
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize:
                        Platform.OS == "web" && screenWidth > 760 ? 22 : 20,
                      fontFamily: "PlusJakartaSansSemiBold",
                      textAlign: "center",
                      color: colors.white,
                    }}
                  >
                    {SuccessMessage}
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      gap: 15,
                      justifyContent: "center",
                    }}
                  >
                    {handleClose != undefined && (
                      <Pressable
                        onPress={() => handleClose()}
                        style={{
                          backgroundColor: "#a9a9a9",
                          width: 180,
                          height: 40,
                          borderRadius: 7,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 20,
                            fontFamily: "PlusJakartaSansSemiBold",
                            textAlign: "center",
                            color: "#fff",
                          }}
                        >
                          Cancel
                        </Text>
                      </Pressable>
                    )}
                    {handleOk != undefined && (
                      <Pressable
                        onPress={() => handleOk()}
                        style={{
                          backgroundColor: "#2EB405",
                          width:
                            Platform.OS == "web" && screenWidth > 760
                              ? 180
                              : 120,
                          height: 40,
                          borderRadius: 7,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Text
                          style={{
                            fontSize:
                              Platform.OS == "web" && screenWidth > 760
                                ? 20
                                : 18,
                            fontFamily: "PlusJakartaSansSemiBold",
                            textAlign: "center",
                            color: "#fff",
                          }}
                        >
                          Ok
                        </Text>
                      </Pressable>
                    )}

                    {handleConfirm != undefined && (
                      <Pressable
                        onPress={() => handleConfirm()}
                        style={{
                          backgroundColor: "#2EB405",
                          width:
                            Platform.OS == "web" && screenWidth > 760
                              ? 180
                              : 120,
                          height: 40,
                          borderRadius: 7,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Text
                          style={{
                            fontSize:
                              Platform.OS == "web" && screenWidth > 760
                                ? 20
                                : 18,
                            fontFamily: "PlusJakartaSansSemiBold",
                            textAlign: "center",
                            color: "#fff",
                          }}
                        >
                          Confirm
                        </Text>
                      </Pressable>
                    )}
                  </View>
                </View>
              </View>
            </View>
          </View>
        </Modal>
      </TouchableWithoutFeedback>
      {/* modal ended */}
    </View>
  );
};
const styles = StyleSheet.create({
  //
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    // marginTop: 22,
  },
  modalView: {
    width: 550,
    maxWidth: "90%",
    margin: 20,
    backgroundColor: colors.royalBlue,
    borderRadius: 7,
    shadowColor: colors.white,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    position: "relative",
  },
});
export default SuccessModal;
