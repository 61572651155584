import React, { useState, useEffect, useContext } from "react";
import {
  View,
  StyleSheet,
  Text,
  Platform,
  Dimensions,
  // Image,
  Pressable,
  TextInput,
  ActivityIndicator,
  Modal,
  Image,
} from "react-native";
import { DataStore } from "aws-amplify/datastore";
import { uploadData } from "aws-amplify/storage";
import awsmobile from "../../../../aws-exports.js";
import DeleteIcon from "react-native-vector-icons/Feather";
import Navbar from "../../../../components/include/Navbar.jsx";
import { H2 } from "../../../../components/reusable/TextComponent";
import { AuthenticationContext } from "../../../../authentication/Authentication.js";
import UploadImage from "../../../../components/reusable/UploadImage";
import { CustomDateField } from "../../../../components/reusable/textFeild";
import { useRoute } from "@react-navigation/native";
import * as Yup from "yup";
import { Button } from "react-native-elements";
import { getUserMasterData } from "../../../../api/UserMasterAPI.js";
import { colors } from "../../../../components/reusable/colour";
import {
  UserMaster,
  TeamRegistrations,
  CoachMaster,
  PlayerMaster,
  PaymentStatus,
  Squad,
  SquadUserMaster,
  UserMasterTournament,
  SquadPlayerMaster,
  RegistrationStatus,
  UserMasterTeamRegistrations,
  TeamRegistrationsPlayerMaster,
  SquadCoachMaster,
  Tournament,
  TMTLoookup,
} from "../../../../models";
import { getCoachMasterData } from "../../../../api/CoachMasterAPI.js";
import PlayerDetailsModal from "../../../../components/reusable/PlayerDetailsModal";
import { useNavigation } from "@react-navigation/native";
import { Formik } from "formik";
import AddIcon from "react-native-vector-icons/Feather";
import {
  AreYouSureOrange,
  ErrorModal,
  SuccessModalNew,
} from "../../../../components/reusable/AreYouSure";
import AsyncStorage from "@react-native-async-storage/async-storage";
import AppBar from "../../../../components/include/AppBar";
import { ScrollView } from "react-native-gesture-handler";
import { handlePlayerSerialNumber } from "../../../../components/reusable/CreatePlayerSerialNumber";
import CustomDatePicker from "../../../../components/reusable/CustomeDatePicker.jsx";

const screenWidth = Dimensions.get("window").width;

let admin1UserData: any;

const TeamParticipationForm = () => {
  const route = useRoute();
  const navigation: any = useNavigation();
  const bucketName = awsmobile.aws_user_files_s3_bucket;
  const { createAdminUser, getAdminUser } = useContext(AuthenticationContext);
  const { userUUID, squadIds, currentUserName, tournamentId }: any =
    route.params || {};
  const [TeamId, setTeamId] = useState("");
  const [TournamentId, setTournamentId] = useState("");
  const [uuid, setUUID] = useState("");
  const [selectedImageLogo, setSelectedImageLogo] = useState(null);
  const [teamLogoURL, setTeamLogoURL] = useState("");
  const [PlayerDetailsModalVisible, setPlayerDetailsModalVisible] =
    useState(false);
  const [profilePicsArray, setProfilePicsArray] = useState([
    {
      Pic: null,
      UploadedPercentage: "",
      Url: "",
    },
  ]);
  const [teamName, setTeamName] = useState("");
  const [teamShortName, setTeamShortName] = useState("");
  const [coachName, setCoachName]: any = useState("");
  const [coachMobileNo, setCoachMobileNo] = useState("");
  const [adminName, setAdminName] = useState("");
  const [adminMobileNo, setAdminMobileNo] = useState("");
  const [playersList, setPlayersList] = useState([
    {
      Name: "",
      DOB: "",
      JerseyNo: "",
      MobileNo: "",
      PhotoURL: "",
      UUID: "",
      playerSummaryId: "",
      coachMasterId: "",
    },
  ]);

  // const [playersListForMobile, setPlayersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tournamentData, setTournamentData]: any = useState({});
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [successModalMesg, setSuccessModalMesg] = useState("");
  const [errorModalMesg, setErrorModalMesg] = useState("");
  const [deleteModalMessage, setDeleteModalMessage] = useState("");
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [tournamentTeamsdata, setTournamentTeamsdata] = useState([]);

  const [selectedLogoSize, setSelectedLogoSize]: any = useState(Number);
  const [previousPath, setPreviousPath] = useState("");
  const [routesFromManageTournament, setRoutesFromManageTournament] =
    useState(false);
  const [isPlayerDetailsModalVisible, setIsPlayerDetailsModalVisible] =
    useState(false);

  let getTournamentId: any,
    isDuplicateTeamName: any,
    isDuplicateTeamShortName: any,
    playerSerialNumber: any,
    hasErrors = false;

  const handleInputTeamDetailsChange = (fieldName, value) => {
    switch (fieldName) {
      case "teamName":
        console.log("Team Name", value);
        setTeamName(value);
        break;
      case "teamShortName":
        console.log("teamShortName", value);
        setTeamShortName(value.trim().toUpperCase());
        break;
      case "coachName":
        console.log("Coach Name", value);
        setCoachName(value);
        break;
      case "coachMobileNo":
        console.log("Coach Mobile No", value);
        setCoachMobileNo(value);
        break;
      case "adminName":
        console.log("Admin Name", value);
        setAdminName(value);
        break;
      case "adminMobileNo":
        console.log("Admin Mobile No", value);
        setAdminMobileNo(value);
        break;
      default:
        // Handle default case if needed
        break;
    }
  };

  const handleOk = () => {
    setSuccessModalVisible(false);
    navigation.navigate("Dashboard");
  };

  const handleSubmitError = () => {
    setErrorModalVisible(false);
  };

  const getUserdata = async () => {
    let userDetails = await AsyncStorage.getItem("UserDetail");
    if (userDetails) {
      const userData = await DataStore.query(UserMaster, userDetails);
      console.log("userData", userData);
      admin1UserData = userData;
      setUUID(userData?.UUID);
    } else {
      console.log("No user found in temp storage");
    }
  };

  const getTournamentIdFromAsyncStorage = async () => {
    getTournamentId = await AsyncStorage.getItem(
      "tournamentIdFromUpcomingTournament"
    );
    console.log("getTournamentId", getTournamentId);
  };

  const previousNavigation = () => {
    const routes = navigation.getState()?.routes;
    console.log("routes", routes);

    // Check if routes exist and there are at least two routes in the stack
    if (routes && routes.length >= 2) {
      const prevRoute = routes[routes.length - 2]; // -2 because -1 is the current route
      console.log("prevRoute", prevRoute);
      const prevRoutePath = prevRoute.name ? prevRoute.name : null;
      console.log("Previous route path:", prevRoutePath);
      setPreviousPath(prevRoutePath);
      if (
        prevRoutePath &&
        prevRoutePath !== null &&
        prevRoutePath !== undefined &&
        prevRoutePath?.includes("ManageTournament")
      ) {
        setRoutesFromManageTournament(true);
      } else {
        setRoutesFromManageTournament(false);
      }
      return prevRoutePath;
    } else {
      console.log("No previous route found");
      // Return null or handle the case where there is no previous route
      return null;
    }
  };

  const handleOpenPlayerDetailsModal = () => {
    // Reset the player data state to its initial state
    console.log("debiug 1");
    setIsPlayerDetailsModalVisible(true);
  };

  const handleClosePlayerDetailsModal = () => {
    console.log("debiug 3");
    setIsPlayerDetailsModalVisible(false);
  };

  const handleSubmitPlayerDetails = (newPlayerData) => {
    console.log("debiug 2");
    // Update the player data in the TeamParticipation component's state
    setPlayersList((prevPlayersList) => [...prevPlayersList, ...newPlayerData]);
    console.log("debiug 4");
    setIsPlayerDetailsModalVisible(false);
  };

  const generateSerialNumber = async () => {
    const originLocation = window.location.origin;
    console.log("origin location", originLocation);

    try {
      const serialNumber = await handlePlayerSerialNumber(originLocation);
      playerSerialNumber = serialNumber;
      // setPlayerSerialNumber(serialNumber);

      console.log("Generated Player Serial Number:", serialNumber);
    } catch (error) {
      console.error("Error generating player serial number", error);
    }
  };

  useEffect(() => {
    // setLoading(true);
    // console.log("playersListForMobile", playersListForMobile);

    const subscriptionPlayers = DataStore.observeQuery(TMTLoookup).subscribe(
      async (snapshot) => {
        const getFieldsData = snapshot.items;
        console.log("getFieldsData", getFieldsData);

        const getPlayerSerialNumber = getFieldsData.filter(
          (data) =>
            data.FieldName === "PlayerSerialNumber" &&
            data.ValueType === "String"
        );

        console.log("getPlayerSerialNumber", getPlayerSerialNumber);
      }
    );

    const routePath = window.location.pathname;
    console.log("routePath", routePath);

    // const originLocation = window.location.origin;
    // console.log("origin location", originLocation);

    // console.log("teamRegistrations?.id", teamRegistrations.id);

    getUserdata();
    getTournamentIdFromAsyncStorage();
    previousNavigation();
    // navigation const is passed to the screen component or from useNavigation()

    console.log("selectedLogoSize", selectedLogoSize);
    console.log(
      "isDuplicateTeamName",
      isDuplicateTeamName,
      "isDuplicateTeamShortName",
      isDuplicateTeamShortName
    );

    console.log(
      "userUUID",
      userUUID,
      "squadIds",
      squadIds,
      "currentUserName",
      currentUserName,
      "tournamentId",
      tournamentId
    );
    let initialTournaments = [];
    let playing = 0;
    const subscription = DataStore.observeQuery(Tournament, (c) =>
      c.id.eq(tournamentId ? tournamentId : getTournamentId)
    ).subscribe(async (snapshot) => {
      initialTournaments = snapshot.items;

      console.log("initialTournaments : ", initialTournaments);
      setTournamentData(initialTournaments[0]);
      console.log("Tournament", initialTournaments[0]);
      // playing = initialTournaments[0]?.MaxPlayersInSquad;
      console.log("playing", playing);

      let list1 = [];
      for (let i = 0; i < playing; i++) {
        list1.push({
          Name: "",
          DOB: "",
          JerseyNo: "",
          MobileNo: "",
          PhotoURL: "",
          UUID: "",
          playerSummaryId: "",
          coachMasterId: "",
        });
      }
      console.log("PlayerList Length : ", list1.length);

      if (Platform.OS === "web" && screenWidth > 760) {
        setPlayersList(list1);
      } else {
        setPlayersList([]);
      }

      let list2 = [];
      for (let i = 0; i < playing; i++) {
        list2.push({
          Pic: null,
          UploadedPercentage: "",
          Url: "",
        });
      }
      setProfilePicsArray(list2);
      console.log("ProfilePics Length : ", list2.length);
    });

    const teamsSubscription = DataStore.observeQuery(TeamRegistrations, (c) =>
      c.Tournament.eq(tournamentId ? tournamentId : getTournamentId)
    ).subscribe(async (snapshot) => {
      const initialTeams = snapshot.items;
      console.log("initialTeams : ", initialTeams);
      setTournamentTeamsdata(initialTeams);
    });

    return () => {
      subscription.unsubscribe();
      teamsSubscription.unsubscribe();
      subscriptionPlayers.unsubscribe();
    };
    // setLoading(false);
  }, [isDuplicateTeamName, isDuplicateTeamShortName, hasErrors]);

  const validationSchema = Yup.object().shape({
    teamName: Yup.string()
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        "Team Name must not contain special characters"
      )
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Team name required"),

    teamShortName: Yup.string()
      .matches(
        /^[a-zA-Z ]*$/,
        "Team Short Name must not contain special characters"
      )
      .min(1, "Too Short!")
      .max(3, "TeamShortName must contain only three letters")
      .required("Team short name required"),
    coachName: Yup.string()
      .matches(
        /^[a-zA-Z0-9 ]*$/,
        "Coach Name must contain only letters and spaces"
      )
      .required("Coach name required"),
    coachMobileNo: Yup.string()
      .matches(/^[0-9]{10}$/, "Invalid Mobile Number")
      .required("Coach mobile no required"),
    adminName: Yup.string().matches(
      /^[a-zA-Z0-9 ]*$/,
      "Manager Name must contain only letters and spaces"
    ),
    // .required("Manager name required"),
    adminMobileNo: Yup.string().matches(/^[0-9]{10}$/, "Invalid Mobile Number"),

    // teamLogoURL: Yup.string().required("Team logo is required !"),
    // .required("Manager mobile no required"),
    // teamLogoURL: Yup.string().required("Team logo is required"),
  });

  const handleInputPlayerChange = (field: string, event: any, idx: number) => {
    console.log("field:", field, "event:", event, "index:", idx);
    const updatedPlayerList = playersList.map((player, index) => {
      if (index === idx) {
        return { ...player, [field]: event };
      }
      return player;
    });
    console.log("updatedPlayerList:", updatedPlayerList);
    setPlayersList(updatedPlayerList);
  };

  // Function to handle the delete action
  const handleDeletePlayer = (indexToDelete) => {
    // Set the delete index
    setDeleteIndex(indexToDelete);
    // Set the message for the delete modal
    setDeleteModalMessage("Do you want to delete this player?");
    // Show the delete modal
    setDeleteModalVisible(true);
  };

  // Function to confirm the delete action
  const confirmDelete = () => {
    if (deleteIndex !== null) {
      // Filter out the player to delete based on the given index
      const updatedData = playersList.filter(
        (_, index) => index !== deleteIndex
      );
      setPlayersList(updatedData);
      // Reset the delete index
      setDeleteIndex(null);
      // Close the delete modal
      setDeleteModalVisible(false);
    }
  };

  // Function to cancel the delete action
  const cancelDelete = () => {
    // Reset the delete index
    setDeleteIndex(null);
    // Close the delete modal
    setDeleteModalVisible(false);
  };

  const handleAddPlayer = () => {
    const newPlayer: any = {
      Name: "",
      DOB: "",
      JerseyNo: "",
      MobileNo: "",
      PhotoURL: "",
      UUID: "",
      playerSummaryId: "",
      coachMasterId: "",
    };
    setPlayersList((prevData) => [...prevData, newPlayer]);
  };

  // const handleSetProfilePic = (e, field, index) => {
  //   console.log("targetvalue");
  //   console.log("We are in handle set PP");
  //   if (field === "Pic") {
  //     const { Pic, value } = e;
  //     const list = [...profilePicsArray];
  //     console.log("Pic : ", e);
  //     list[index].Pic = e;
  //     console.log("list", list);
  //     console.log("We are in Pic");
  //     setProfilePicsArray(list);
  //   }
  //   if (field === "UploadedPercentage") {
  //     const { UploadedPercentage, value } = e;
  //     const list = [...profilePicsArray];

  //     list[index].UploadedPercentage = e;
  //     console.log("list", list);
  //     console.log("We are in handle set Percentage");
  //     setProfilePicsArray(list);
  //   } else {
  //     const { Url, value } = e;
  //     const list = [...profilePicsArray];

  //     list[index].Url = e;
  //     console.log("list", list);
  //     console.log("We are in handle set Url");
  //     setProfilePicsArray(list);
  //   }
  // };

  const handleSetProfilePic = (e, field, index) => {
    console.log("We are in handleSetProfilePic");
    const list = [...profilePicsArray];
    if (field === "Pic") {
      list[index].Pic = e;
      console.log("Setting Pic", e);
    } else if (field === "UploadedPercentage") {
      list[index].UploadedPercentage = e;
      console.log("Setting UploadedPercentage", e);
    } else {
      list[index].Url = e;
      console.log("Setting Url", e);
    }
    console.log("Updated list", list);
    setProfilePicsArray(list);
  };

  const pickImageForLogo = async () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (event) => {
      const inputElement = event.target as HTMLInputElement;
      const file = inputElement.files && inputElement.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const uri = e.target.result;
          const fileName = file.name;

          setSelectedImageLogo({ URL: uri, FileName: fileName });
          uploadLogoFileToStorage(uri);
          console.log("logo name:", fileName);
        };
        reader.readAsDataURL(file);
      } else {
        setSuccessModalVisible(true);
        setSuccessModalMesg("You did not select any image.");
      }
    };

    input.click();
  };

  const handleImageSelection = (uri, kindOfFile, index) => {
    console.log("debiug 4");
    if (kindOfFile == "logo") {
      setTeamLogoURL(uri);
      uploadLogoFileToStorage(uri);
      console.log("uri.length", uri.length);
      setSelectedLogoSize(uri.length);

      console.log("selectedLogoSize", selectedLogoSize);
    } else if (kindOfFile == "profilePic") {
      uploadProfilePicToStorage(uri, index);
    } else {
      setSelectedLogoSize(0);

      console.log("selectedLogoSize", selectedLogoSize);
    }
  };

  async function uploadLogoFileToStorage(uri) {
    try {
      const response = await fetch(uri);
      const blob = await response.blob();
      const randomNumber = (
        Math.floor(Math.random() * 900000) + 100000
      ).toString();
      await uploadData({
        key:
          "team/" +
          teamName.trim().replaceAll(" ", "_") +
          randomNumber +
          "/logo.png",
        data: blob,
      });
      let url =
        `https://${bucketName}.s3.ap-south-1.amazonaws.com/public/team/` +
        teamName.trim().replaceAll(" ", "_") +
        randomNumber +
        "/logo.png";
      setTeamLogoURL(url);

      // contentType: 'image/png' // contentType is optional
    } catch (err) {
      console.log("Error uploading file:", err);
      // setLoading(false);
      // setErrorModalVisible(true);
      // setErrorModalMesg(
      //   `An error occurred while submitting the form in ${err}`
      // );
    }
  }

  async function uploadProfilePicToStorage(uri, index) {
    const number = Math.floor(100000 + Math.random() * 900000)
      .toString()
      .substring(0, 4);
    try {
      const response = await fetch(uri);
      const blob = await response.blob();
      await uploadData({
        key: "userPhotos/" + number + ".jpg",
        data: blob,
        options: {
          onProgress: ({ transferredBytes, totalBytes }) => {
            if (totalBytes) {
              console.log(
                `Upload progress ${Math.round(
                  (transferredBytes / totalBytes) * 100
                )} %`
              );
            }
            let percentage = Math.round((transferredBytes / totalBytes) * 100);
            let url =
              `https://${bucketName}.s3.ap-south-1.amazonaws.com/public/userPhotos/` +
              number +
              ".jpg";
            console.log("Percentage : ", percentage);
            if (percentage === 100) {
              console.log("Percentage is 100");
              handleInputPlayerChange("PhotoURL", url, index);
            }
            handleSetProfilePic(percentage, "UploadedPercentage", index);
            handleSetProfilePic(url, "Url", index);
          },
        },
        // contentType: 'image/png' // contentType is optional
      });
    } catch (err) {
      console.log("Error uploading file:", err);
      // setLoading(false);
      // setErrorModalVisible(true);
      // setErrorModalMesg(
      //   `An error occurred while submitting the form in ${err}`
      // );
    }
  }

  const addUser = async (phoneNumber) => {
    let finalResponse;
    const userAttribute = [
      {
        Name: "phone_number" /* required */,
        Value: phoneNumber,
      },
    ];
    const response = await createAdminUser(phoneNumber, userAttribute).then(
      async (data) => {
        console.log("CreateUserResponse : ", data);
        finalResponse = data;
      }
    );
    console.log("response", response, "finalResponse", finalResponse);

    return finalResponse;
  };

  const cognitoUserDetails = async (phoneNumber) => {
    const response = await getAdminUser(phoneNumber);
    if (response) {
      console.log("UserDetails : ", response, response.statusCode);
    }

    return response;
  };

  const checkDuplicates = async () => {
    // Step 1: To check the duplicates records for TeamName and TeamShortName
    if (
      teamName &&
      teamName.length > 0 &&
      teamShortName &&
      teamShortName.length > 0
    ) {
      // Function to check for duplicate TeamName
      const checkDuplicateTeamName = (
        tournamentTeamsdata: any,
        teamName: any
      ) => {
        return tournamentTeamsdata.some(
          (data: any) => data.TeamName === teamName
        );
      };

      // Function to check for duplicate TeamShortName
      const checkDuplicateTeamShortName = (
        tournamentTeamsdata: any,
        teamShortName: any
      ) => {
        return tournamentTeamsdata.some(
          (data: any) => data.TeamShortName === teamShortName
        );
      };

      // Step 1: Check team name duplicates
      isDuplicateTeamName = await checkDuplicateTeamName(
        tournamentTeamsdata,
        teamName
      );
      console.log("Is TeamName duplicate?", isDuplicateTeamName);
      // setIsDuplicateTeamName(isDuplicateTeamName);

      // Step 2: Check team short name duplicates
      isDuplicateTeamShortName = await checkDuplicateTeamShortName(
        tournamentTeamsdata,
        teamShortName
      );
      console.log("Is TeamShortName duplicate?", isDuplicateTeamShortName);
      // setIsDuplicateTeamShortName(isDuplicateTeamShortName);
    } else {
      console.log("No teamName and teamShortName ");
    }
  };

  const areTeamAndPlayersFieldsFilled = async () => {
    console.log("teamLogo", teamLogoURL);
    if (teamLogoURL === "") {
      setErrorModalMesg("Please upload team logo!");
      setErrorModalVisible(true);
      hasErrors = true;
    } else {
      setErrorModalVisible(false);
    }

    for (const player of playersList) {
      if (!player.Name) {
        console.log("isPlayerNameEmpty", true);
        setErrorModalMesg("Please provide the player's name!");
        setErrorModalVisible(true);
        hasErrors = true;
        break;
      }
      if (!player.DOB) {
        console.log("isDOBEmpty", true);
        setErrorModalMesg("Please provide the date of birth!");
        setErrorModalVisible(true);
        hasErrors = true;
        break;
      }
      if (!player.JerseyNo) {
        console.log("isPlayerJerseyNoEmpty", true);
        setErrorModalMesg("Please provide the player's jersey number!");
        setErrorModalVisible(true);
        hasErrors = true;
        break;
      }
    }
  };

  let coachData,
    admin2UserData,
    coachUserMaster,
    playerUserMaster,
    coachSummary,
    coachUUID,
    adminUUID,
    userMasterCoach,
    userMasterAdmin,
    resCognitoAdmin1,
    resCognitoAdmin2,
    resAdmin1UUID,
    squad,
    squadUserMaster,
    squadCoachMaster,
    squadPlayerMaster,
    resAdmin2UUID,
    teamRegistrations,
    userMasterTeamRegistrations,
    teamRegistrationsPlayerMaster,
    userMasterTournament;
  let playerSummaryData, playerUserMasterData;
  //addAdminData2
  let playerSummaryDataArray = [];
  let coachObj: any, admin1Obj: any, admin2Obj: any;

  const handleOnSubmit = async () => {
    await checkDuplicates();
    await areTeamAndPlayersFieldsFilled();

    if (!isDuplicateTeamName && !isDuplicateTeamShortName) {
      if (!hasErrors) {
        setErrorModalVisible(false);
        try {
          setLoading(true); // Start loading

          console.log("Tournament Id : ", tournamentId);

          // Step 2: Check coach mobile number exist or otherwise create new record in user and coach master

          // coach mobile no
          if (coachMobileNo && coachMobileNo.length > 0) {
            const resCoach = await cognitoUserDetails("+91" + coachMobileNo);
            console.log("Coach Response", resCoach.statusCode);
            if (resCoach.statusCode === 400) {
              const resAddCoach = await addUser("+91" + coachMobileNo);
              coachUUID = resAddCoach?.User?.Username;
              console.log("coachUUID", coachUUID);

              if (coachUUID) {
                await getUserMasterData(coachUUID).then(async (response) => {
                  if (response) {
                    console.log("Coach Data", response);
                    userMasterCoach = response;
                    if (userMasterCoach.userMasterCoachSummaryId == "") {
                      console.log("We are in if Coach Summary");
                      try {
                        coachSummary = await DataStore.save(
                          new CoachMaster({
                            Name: coachName.trim(),
                            Games: 0,
                            Won: 0,
                            Lost: 0,
                            Drawn: 0,
                          })
                        );
                      } catch (err) {
                        console.log(
                          "Error in saving coach summary : ",
                          coachSummary
                        );
                        // setLoading(false);
                        // setErrorModalVisible(true);
                        // setErrorModalMesg(
                        //   `An error occurred while submitting the form in ${err}`
                        // );
                      }
                    } else {
                      console.log("We are in else of coach summary");
                      getCoachMasterData(
                        userMasterCoach.userMasterCoachSummaryId
                      ).then((value) => {
                        coachSummary = value;
                      });
                    }
                  } else {
                    console.log("No coach data found");
                    try {
                      coachSummary = await DataStore.save(
                        new CoachMaster({
                          Name: coachName.trim(),
                          Games: 0,
                          Won: 0,
                          Lost: 0,
                          Drawn: 0,
                        })
                      );
                      userMasterCoach = await DataStore.save(
                        new UserMaster({
                          UUID: coachUUID,
                          Name: coachName.trim(),
                          ContactNo: Number(coachMobileNo),
                          EmailId: "",
                          PhotoURL: "",
                          CreatedBy: admin1UserData?.Name,
                          LastUpdatedBy: admin1UserData?.Name,
                          RoleCoach: true,
                          RolePlayer: false,
                          CoachSummary: coachSummary,
                        })
                      );

                      console.log("UserMaster Coach : ", userMasterCoach);
                      const updateCoachUserMasterId = {
                        coachMasterCoachDataUUID: userMasterCoach.UUID,
                      };

                      let updatedItem;
                      const original = await DataStore.query(
                        CoachMaster,
                        userMasterCoach.userMasterCoachSummaryId
                      );
                      try {
                        if (updateCoachUserMasterId) {
                          console.log(
                            "Current Item : ",
                            updateCoachUserMasterId
                          );
                          updatedItem = CoachMaster.copyOf(original, (ele) => {
                            ele.coachMasterCoachDataUUID =
                              updateCoachUserMasterId.coachMasterCoachDataUUID;
                          });
                          await DataStore.save(updatedItem);

                          console.log("Updated Item:", updatedItem);
                        } else {
                          console.error("Item not found");
                        }
                        return updatedItem;
                      } catch (error) {
                        console.error("Error updating item:", error);
                        // setLoading(false);
                        // setErrorModalVisible(true);
                        // setErrorModalMesg(
                        //   `An error occurred while submitting the form in ${error}`
                        // );
                      }

                      console.log("UserMaster Coach : ", userMasterCoach);
                    } catch (e) {
                      console.log("Error : ", e);
                      // setLoading(false);
                      // setErrorModalVisible(true);
                      // setErrorModalMesg(
                      //   `An error occurred while submitting the form in ${e}`
                      // );
                    }
                  }
                });
              } else {
                console.log("coachUUID not found");
              }
            } else {
              coachUUID = resCoach.Username;
              console.log(
                "Coach data found  : ",
                resCoach,
                "coachUUID",
                coachUUID
              );
            }
          } else {
            console.log("CoachMobile not found");
          }

          // Step 3: Check admin(manager) mobile number exist or not

          if (adminMobileNo && adminMobileNo.length > 0) {
            // admin mobile no
            resCognitoAdmin2 = await cognitoUserDetails("+91" + adminMobileNo);

            if (resCognitoAdmin2.statusCode === 400) {
              const resAddAdmin2 = await addUser("+91" + adminMobileNo);
              console.log("Resp : ", resAddAdmin2);
              resAdmin2UUID = resAddAdmin2?.User?.Username;
              console.log("Admin UUID ", resAdmin2UUID);

              if (resAdmin2UUID) {
                await getUserMasterData(resAdmin2UUID).then(
                  async (response) => {
                    if (response) {
                      console.log("Admin Data", response);
                      admin2UserData = response;
                      console.log("admin2UserData", admin2UserData);
                    } else {
                      console.log("No admin data found");
                      try {
                        userMasterAdmin = await DataStore.save(
                          new UserMaster({
                            UUID: resAdmin2UUID,
                            Name: adminName,
                            ContactNo: Number(adminMobileNo),
                            EmailId: "",
                            PhotoURL: "",
                            CreatedBy: admin1UserData.Name,
                            LastUpdatedBy: admin1UserData.Name,
                            RoleCoach: false,
                            RolePlayer: false,
                          })
                        );
                        console.log("UserMaster Admin: ", userMasterAdmin);
                      } catch (e) {
                        console.log("Error : ", e);
                        // setLoading(false);
                        // setErrorModalVisible(true);
                        // setErrorModalMesg(
                        //   `An error occurred while submitting the form in ${e}`
                        // );
                      }
                    }
                  }
                );
              } else {
                console.log("Admin2 UUID not found");
              }
            } else {
              resAdmin2UUID = resCognitoAdmin2.Username;
              console.log("Else user data uuid : ", resAdmin2UUID);
            }
          } else {
            console.log("Admin mobile number not found ");
          }
          // coach object
          coachObj = {
            UUID: coachUUID,
            Name: coachName,
            PhotoURL: "",
          };
          // admin 2 object
          admin2Obj = {
            UUID: resAdmin2UUID ? resAdmin2UUID : "",
            Name: adminName,
            PhotoURL: "",
          };
          // aadmin 1 object
          admin1Obj = {
            UUID: uuid,
            Name: admin1UserData?.Name,
            PhotoURL: admin1UserData?.PhotoURL,
          };

          // create playermaster functionality

          // Step 4 : Check the player mobile number is already exist or not otherwise create new user and follow the next process

          for (let i = 0; i < playersList.length; i++) {
            console.log("PlayerList count", playersList.length);

            // if playerMaster is not empty then create playerMaster and usermaster creation

            if (playersList[i].MobileNo !== "") {
              let res = await cognitoUserDetails(
                "+91" + playersList[i].MobileNo
              );
              console.log("res", res);

              if (res.statusCode === 400) {
                console.log("No player found ", res.statusCode);
                console.log(
                  "Name : ",
                  playersList[i].Name,
                  "PhotoURL : ",
                  playersList[i].PhotoURL,
                  "Mobile No : ",
                  playersList[i].MobileNo
                );

                const resAddPlayer = await addUser(
                  "+91" + playersList[i].MobileNo
                );
                console.log("resAddPlayer : ", resAddPlayer);
                playersList[i].UUID = resAddPlayer?.User?.Username;
                console.log("Player ", i, "UUID ", playersList[i].UUID);
              } else {
                // WHEN NEW MOBILE NO IS FOUND THEN THE BELOW BLOCK OF CODE WILL NOT EXECUTE
                playersList[i].UUID = res.Username;
                console.log(" playersList[i].UUID", playersList[i].UUID);

                // checking if user uuisd is already exist or not
                await getUserMasterData(playersList[i].UUID).then(
                  async (value) => {
                    console.log("value", value);
                    if (value?.userMasterPlayerSummaryId == "") {
                      console.log(
                        "value",
                        value,
                        "value.userMasterPlayerSummaryId",
                        value.userMasterPlayerSummaryId
                      );
                      await generateSerialNumber();
                      try {
                        playerSummaryData = await DataStore.save(
                          new PlayerMaster({
                            Name: playersList[i].Name,
                            PhotoURL: playersList[i].PhotoURL,
                            JerseyNo: playersList[i].JerseyNo,
                            DateOfBirth: playersList[i].DOB,
                            PlayerData: value,
                            SrNo: playerSerialNumber,
                          })
                        );
                        playerSummaryDataArray.push(playerSummaryData);
                        console.log(
                          "playerSummaryDataArray",
                          playerSummaryDataArray
                        );
                        console.log(
                          "Player Summary Data : ",
                          playerSummaryData
                        );
                        console.log(
                          "Player Summary Data Id : ",
                          playerSummaryData?.id
                        );
                      } catch (e) {
                        console.log("Player Summary Error : ", e);
                        // setLoading(false);
                        // setErrorModalVisible(true);
                        // setErrorModalMesg(
                        //   `An error occurred while submitting the form in ${e}`
                        // );
                      }
                    }
                  }
                );
              }
              await getUserMasterData(playersList[i].UUID).then(
                async (response) => {
                  if (response) {
                    console.log("Player ", i, " Data", response);
                    playerUserMasterData = response;
                    // console.log("Player ID : ", playerData);
                    console.log("playerUserMasterData", playerUserMasterData);
                    if (
                      playerUserMasterData.userMasterPlayerSummaryId == "" ||
                      playerUserMasterData.userMasterPlayerSummaryId == null
                    ) {
                      await generateSerialNumber();
                      try {
                        playerSummaryData = await DataStore.save(
                          new PlayerMaster({
                            Name: playersList[i].Name,
                            PhotoURL: playersList[i].PhotoURL,
                            JerseyNo: playersList[i].JerseyNo,
                            DateOfBirth: playersList[i].DOB,
                            PlayerData: playerUserMasterData,
                            SrNo: playerSerialNumber,
                          })
                        );
                        playerSummaryDataArray.push(playerSummaryData);
                        console.log(
                          "playerSummaryDataArray",
                          playerSummaryDataArray
                        );
                        console.log(
                          "Player Summary Data : ",
                          playerSummaryData
                        );
                        console.log(
                          "Player Summary Data Id : ",
                          playerSummaryData?.id
                        );
                      } catch (e) {
                        console.log("Player Summary Error : ", e);
                        // setLoading(false);
                        // setErrorModalVisible(true);
                        // setErrorModalMesg(
                        //   `An error occurred while submitting the form in ${e}`
                        // );
                      }
                    } else {
                      playerSummaryDataArray.push(
                        playerUserMasterData.userMasterPlayerSummaryId
                      );
                      console.log(
                        "playerSummaryDataArray",
                        playerSummaryDataArray
                      );
                      console.log("Player Summary Data : ", playerSummaryData);
                      console.log(
                        "Player Summary Data Id : ",
                        playerSummaryData?.id
                      );
                    }
                  } else {
                    console.log("Response not found");
                    await generateSerialNumber();
                    try {
                      playerSummaryData = await DataStore.save(
                        new PlayerMaster({
                          Name: playersList[i].Name,
                          PhotoURL: playersList[i].PhotoURL,
                          DateOfBirth: playersList[i].DOB,
                          JerseyNo: playersList[i].JerseyNo,
                          SrNo: playerSerialNumber,
                        })
                      );
                      playerSummaryDataArray.push(playerSummaryData);
                      console.log("playerSummaryData", playerSummaryData);
                      console.log(
                        "playerSummaryDataArray",
                        playerSummaryDataArray
                      );

                      // handlePlayerSerialNumber function

                      playerUserMasterData = await DataStore.save(
                        new UserMaster({
                          UUID: playersList[i].UUID,
                          Name: playersList[i].Name,
                          ContactNo: Number(playersList[i].MobileNo),
                          EmailId: "",
                          PhotoURL: playersList[i].PhotoURL,
                          CreatedBy: admin1UserData.Name,
                          LastUpdatedBy: admin1UserData.Name,
                          RoleCoach: false,
                          RolePlayer: true,
                          PlayerSummary: playerSummaryData,
                        })
                      );
                      console.log("UserMaster Player: ", playerUserMasterData);

                      const updatePlayerMasterPlayerDataUUID = {
                        playerMasterPlayerDataUUID: playerUserMasterData.UUID,
                      };

                      let updatedItem;
                      const original = await DataStore.query(
                        PlayerMaster,
                        playerUserMasterData.userMasterPlayerSummaryId
                      );
                      try {
                        if (updatePlayerMasterPlayerDataUUID) {
                          console.log(
                            "Current Item : ",
                            updatePlayerMasterPlayerDataUUID
                          );
                          updatedItem = PlayerMaster.copyOf(original, (ele) => {
                            ele.playerMasterPlayerDataUUID =
                              updatePlayerMasterPlayerDataUUID.playerMasterPlayerDataUUID;
                          });
                          await DataStore.save(updatedItem);

                          console.log("Updated Item:", updatedItem);
                        } else {
                          console.error("Item not found");
                        }
                      } catch (error) {
                        console.error("Error updating item:", error);
                        // setLoading(false);
                        // setErrorModalVisible(true);
                        // setErrorModalMesg(
                        //   `An error occurred while submitting the form in ${error}`
                        // );
                      }
                    } catch (e) {
                      console.log("Error : ", e);
                      // setLoading(false);
                      // setErrorModalVisible(true);
                      // setErrorModalMesg(
                      //   `An error occurred while submitting the form in ${e}`
                      // );
                    }

                    try {
                      userMasterTournament = await DataStore.save(
                        new UserMasterTournament({
                          userMasterUUID: playerUserMasterData?.UUID,
                          tournamentId: tournamentId
                            ? tournamentId
                            : getTournamentId,
                        })
                      );
                      console.log("userMasterTournament", userMasterTournament);
                    } catch (err) {
                      console.log("Error in UserMasterTournament : ", err);
                      // setLoading(false);
                      // setErrorModalVisible(true);
                      // setErrorModalMesg(
                      //   `An error occurred while submitting the form in ${err}`
                      // );
                    }
                  }
                }
              );
            } else {
              // else condition if mobile number is empty
              // handlePlayerSerialNumber function
              try {
                await generateSerialNumber();
                try {
                  playerSummaryData = await DataStore.save(
                    new PlayerMaster({
                      Name: playersList[i].Name,
                      PhotoURL: playersList[i].PhotoURL,
                      JerseyNo: playersList[i].JerseyNo,
                      DateOfBirth: playersList[i].DOB,
                      SrNo: playerSerialNumber,
                    })
                  );
                  playerSummaryDataArray.push(playerSummaryData);
                  console.log("Player Summary Data : ", playerSummaryData);
                  console.log(
                    "Player Summary Data Id : ",
                    playerSummaryData?.id
                  );
                } catch (e) {
                  console.log("Player Summary Error : ", e);
                  // setLoading(false);
                  // setErrorModalVisible(true);
                  // setErrorModalMesg(
                  //   `An error occurred while submitting the form in ${e}`
                  // );
                }
              } catch (error) {
                console.log("Error in playerSummaryData ", error);
                // setLoading(false);
                // setErrorModalVisible(true);
                // setErrorModalMesg(
                //   `An error occurred while submitting the form in ${error}`
                // );
              }
            }
          }

          // Step 5 : Create squad functionality

          // squad functionality
          try {
            squad = await DataStore.save(
              new Squad({
                Name: teamName,
                Logo: teamLogoURL,
                TournamentsPlayed: 0,
                CreatedBy: admin1Obj.Name,
                LastUpdatedBy: admin1Obj.Name,
                Admin1:
                  routesFromManageTournament === true ? coachObj : admin1Obj,
                Admin2: admin2Obj,
              })
            );
            console.log("Squad Data : ", squad);
          } catch (err) {
            console.log("Error in creating squad : ", err);
            // setLoading(false);
            // setErrorModalVisible(true);
            // setErrorModalMesg(
            //   `An error occurred while submitting the form in ${err}`
            // );
          }

          if (squad && playerSummaryDataArray.length > 0) {
            try {
              for (let i = 0; i < playerSummaryDataArray.length; i++) {
                console.log(
                  "playerSummaryDataArray Ids",
                  playerSummaryDataArray[i].id
                );
                squadPlayerMaster = await DataStore.save(
                  new SquadPlayerMaster({
                    squadId: squad.id,
                    playerMasterId: playerSummaryDataArray[i].id,
                  })
                );
              }

              console.log(
                "playerSummaryData",
                playerSummaryData,
                "squad",
                squad,
                "playerSummaryDataArray",
                playerSummaryDataArray
              );
              console.log("Squad Playermaster data : ", squadPlayerMaster);
            } catch (error) {
              console.log(
                "Error in Squad Playermaster data : ",
                squadPlayerMaster
              );
              // setLoading(false);
              // setErrorModalVisible(true);
              // setErrorModalMesg(
              //   `An error occurred while submitting the form in ${error}`
              // );
            }
          }

          if (admin1UserData) {
            try {
              squadUserMaster = await DataStore.save(
                new SquadUserMaster({
                  userMasterUUID:
                    routesFromManageTournament === true
                      ? coachUUID
                      : admin1UserData.UUID,
                  squadId: squad.id,
                })
              );
              console.log(
                "Squad Usermaster data for admin1 : ",
                squadUserMaster
              );
            } catch (err) {
              console.log(
                "Error in creating squad userMaster for admin 1 : ",
                err
              );
              // setLoading(false);
              // setErrorModalVisible(true);
              // setErrorModalMesg(
              //   `An error occurred while submitting the form in ${err}`
              // );
            }

            try {
              userMasterTournament = await DataStore.save(
                new UserMasterTournament({
                  userMasterUUID:
                    routesFromManageTournament === true
                      ? coachUUID
                      : admin1UserData.UUID,
                  tournamentId: tournamentId ? tournamentId : getTournamentId,
                })
              );
            } catch (error) {
              console.log(
                "Error in creating TournamentuserMaster for coach : ",
                error
              );
              // setLoading(false);
              // setErrorModalVisible(true);
              // setErrorModalMesg(
              //   `An error occurred while submitting the form in ${error}`
              // );
            }
          }

          if (userMasterAdmin) {
            try {
              squadUserMaster = await DataStore.save(
                new SquadUserMaster({
                  userMasterUUID: userMasterAdmin.UUID,
                  squadId: squad.id,
                })
              );
              console.log(
                "Squad Usermaster data for admin2 : ",
                squadUserMaster
              );
            } catch (err) {
              console.log(
                "Error in creating squad userMaster for admin 2 : ",
                err
              );
              // setLoading(false);
              // setErrorModalVisible(true);
              // setErrorModalMesg(
              //   `An error occurred while submitting the form in ${err}`
              // );
            }
          }

          if (coachSummary && userMasterCoach) {
            try {
              squadUserMaster = await DataStore.save(
                new SquadUserMaster({
                  userMasterUUID: userMasterCoach.UUID,
                  squadId: squad.id,
                })
              );
              console.log(
                "Squad Usermaster data for coach : ",
                squadUserMaster
              );
            } catch (err) {
              console.log(
                "Error in creating squad userMaster for coach : ",
                err
              );
              // setLoading(false);
              // setErrorModalVisible(true);
              // setErrorModalMesg(
              //   `An error occurred while submitting the form in ${err}`
              // );
            }

            try {
              userMasterTournament = await DataStore.save(
                new UserMasterTournament({
                  userMasterUUID: userMasterCoach.UUID,
                  tournamentId: tournamentId ? tournamentId : getTournamentId,
                })
              );
            } catch (error) {
              console.log(
                "Error in creating TournamentuserMaster for coach : ",
                error
              );
              // setLoading(false);
              // setErrorModalVisible(true);
              // setErrorModalMesg(
              //   `An error occurred while submitting the form in ${error}`
              // );
            }

            try {
              squadCoachMaster = await DataStore.save(
                new SquadCoachMaster({
                  coachMasterId: coachSummary.id,
                  squadId: squad.id,
                })
              );
              console.log("Squad Usermaster Coach data : ", squadCoachMaster);
            } catch (err) {
              console.log("Error in creating squad userMaster coach : ", err);
              // setLoading(false);
              // setErrorModalVisible(true);
              // setErrorModalMesg(
              //   `An error occurred while submitting the form in ${err}`
              // );
            }
          }

          // Step 6: Create TeamsRegistration funtionality

          // TeamRegistration Functionality
          if (squad) {
            try {
              teamRegistrations = await DataStore.save(
                new TeamRegistrations({
                  TeamName: teamName,
                  Logo: teamLogoURL,
                  Group: "",
                  Coach: coachObj,
                  PaymentStatus: PaymentStatus.UNPAID,
                  PaidAmount: 0,
                  BalanceAmount: 0,
                  Documents: [],
                  Tournament: tournamentId ? tournamentId : getTournamentId,
                  Award: "",
                  Status: RegistrationStatus.SUBMITTED,
                  TeamShortName: teamShortName,
                  SquadId: squad.id,
                })
              );
              console.log("Team Reg : ", teamRegistrations);
            } catch (error) {
              console.log("Error occured while teamRegistartion", error);
              // setLoading(false);
              // setErrorModalVisible(true);
              // setErrorModalMesg(
              //   `An error occurred while submitting the form in ${error}`
              // );
            }
          } else {
            console.log("no squad data found");
          }

          if (teamRegistrations && playerSummaryDataArray) {
            try {
              if (playerSummaryDataArray && playerSummaryDataArray.length > 0) {
                for (let i = 0; i < playerSummaryDataArray.length; i++) {
                  teamRegistrationsPlayerMaster = await DataStore.save(
                    new TeamRegistrationsPlayerMaster({
                      teamRegistrationsId: teamRegistrations?.id,
                      playerMasterId: playerSummaryDataArray[i]?.id,
                    })
                  );
                  console.log(
                    "teamRegistrationsPlayerMaster",
                    teamRegistrationsPlayerMaster
                  );
                }
              } else {
                console.log("error no players data");
              }
            } catch (error) {
              console.log("Error in TeamRegistrationsPlayerMaster : ", error);
              // setLoading(false);
              // setErrorModalVisible(true);
              // setErrorModalMesg(
              //   `An error occurred while submitting the form in ${error}`
              // );
            }
          } else {
            console.log(
              "teamRegistrations and  playerSummaryDataArray is not found"
            );
          }

          if (resAdmin2UUID && teamRegistrations) {
            try {
              userMasterTeamRegistrations = await DataStore.save(
                new UserMasterTeamRegistrations({
                  userMasterUUID: resAdmin2UUID ? resAdmin2UUID : "",
                  teamRegistrationsId: teamRegistrations.id,
                })
              );
              console.log(
                "UserMasterTeamRegistrations 1 : ",
                userMasterTeamRegistrations
              );
            } catch (err) {
              console.log("Error in UserMasterTeamRegistrations 1 : ", err);
              // setLoading(false);
              // setErrorModalVisible(true);
              // setErrorModalMesg(
              //   `An error occurred while submitting the form in ${err}`
              // );
            }
          } else {
            console.log("AdminUUID and teamRegistrations data is not found");
          }

          if (uuid && teamRegistrations) {
            try {
              userMasterTeamRegistrations = await DataStore.save(
                new UserMasterTeamRegistrations({
                  userMasterUUID:
                    routesFromManageTournament === true ? coachUUID : uuid,
                  teamRegistrationsId: teamRegistrations.id,
                })
              );
              console.log(
                "UserMasterTeamRegistrations 2 : ",
                userMasterTeamRegistrations
              );
            } catch (err) {
              console.log("Error in UserMasterTeamRegistrations 2 : ", err);
              // setLoading(false);
              // setErrorModalVisible(true);
              // setErrorModalMesg(
              //   `An error occurred while submitting the form in ${err}`
              // );
            }
          } else {
            console.log(
              "LoggedIn user uuid and teamRegistrations is not found"
            );
          }
        } catch (error) {
          console.error("Error submitting form:", error);
          setLoading(false); // Stop loading
          // Show error message
          setErrorModalVisible(true);
          setErrorModalMesg("An error occurred while submitting the form.");
        } finally {
          setLoading(false); // Stop loading
        }

        // process complete

        // form submission completed
        setLoading(false);
        setSuccessModalVisible(true);
        setSuccessModalMesg("Team submitted successfully");
        setTeamId(teamRegistrations?.id);
        setTournamentId(tournamentId);
      } else {
        setLoading(false);
        // setErrorModalVisible(true);
        // setErrorModalMesg("Please fillup all players required details");
      }

      if (routesFromManageTournament === true) {
        navigation.navigate("ManageTournament", {
          tournamentId: tournamentId ? tournamentId : getTournamentId,
        });
      } else {
        console.log("no routes from manage tournament");
      }
    } else {
      const duplicateTeamName = isDuplicateTeamName ? teamName : null;
      const duplicateTeamShortName = isDuplicateTeamShortName
        ? teamShortName
        : null;

      console.log(
        "Duplicate teamName&TeamShortName",
        duplicateTeamName,
        duplicateTeamShortName
      );

      setErrorModalVisible(true);

      if (isDuplicateTeamName && isDuplicateTeamShortName) {
        // Both team name and team short name are duplicates
        setErrorModalMesg(
          `Team Name '${duplicateTeamName}' and Team short name '${duplicateTeamShortName}' already exist! Please try different team name and team short name!`
        );
      } else if (isDuplicateTeamName) {
        // Only team name is a duplicate
        setErrorModalMesg(
          `Team Name '${duplicateTeamName}' already exists! Please try a different team name!`
        );
      } else if (isDuplicateTeamShortName) {
        // Only team short name is a duplicate
        setErrorModalMesg(
          `Team Short Name '${duplicateTeamShortName}' already exists! Please try a different team short name!`
        );
      } else {
        // Neither team name nor team short name is a duplicate
        // This block may not be necessary depending on your requirements
        setErrorModalMesg(
          "No duplicates of teamName and teamShoerName found !"
        );
      }
    }
  };

  const handleDateChange = (index, date) => {
    const formattedDate = formatDate(date);
    const newList = [...playersList];
    newList[index].DOB = formattedDate;
    setPlayersList(newList);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleBack = () => {
    navigation.goBack();
  };

  return (
    <ScrollView style={{ backgroundColor: colors.bgColor }}>
      {Platform.OS === "web" ? (
        <Navbar />
      ) : (
        <AppBar
          title={"Team Participation Form"}
          showBackButton={true}
          onBackPress={handleBack}
        />
      )}
      <Formik
        initialValues={{
          teamName: "",
          teamShortName: "",
          coachName: "",
          coachMobileNo: "",
          adminName: "",
          adminMobileNo: "",
          // teamLogoURL: "",
        }}
        validationSchema={validationSchema}
        onSubmit={() => {
          handleOnSubmit();
        }}
        enableReinitialize={true}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          isSubmitting,
          touched,
          isValid,
          errors,
        }) => (
          <View
            style={
              Platform.OS === "web" && screenWidth > 760
                ? styles.container
                : null
            }
          >
            <View style={styles.card}>
              <View style={[styles.firstRow, { gap: 10 }]}>
                <H2
                  props={`${
                    tournamentData && tournamentData.Name
                      ? tournamentData.Name
                      : null
                  } - Team Participation Form`}
                  style={[styles.tournamentTitle]}
                />
              </View>
              <View style={styles.secondRow}>
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    width: screenWidth > 760 ? 180 : "100%",
                  }}
                >
                  <Pressable
                    onPress={pickImageForLogo}
                    style={styles.pressableContainer}
                  >
                    <View style={styles.circleContainer}>
                      <View style={styles.circle}>
                        <UploadImage
                          onImageSelect={handleImageSelection}
                          kindOfFile="logo"
                        />
                      </View>
                    </View>
                  </Pressable>
                  <View>
                    <Text
                      style={{
                        color: "red",
                        textAlign: "center",
                        padding: 5,
                        fontSize: 12,
                        fontStyle: "italic",
                      }}
                    >
                      (Size 200 * 200, Max size 500 KB Only)
                    </Text>
                    {(teamLogoURL === "" && teamLogoURL === null) ||
                      (selectedLogoSize === 0 && (
                        <Text style={{ color: "red", textAlign: "center" }}>
                          Team logo is required
                        </Text>
                      ))}
                  </View>
                </View>

                <View
                  style={{
                    flexDirection: "column",
                    flex: Platform.OS === "web" && screenWidth > 760 ? 1 : 0,
                  }}
                >
                  <View
                    style={{
                      flexDirection:
                        Platform.OS === "web" && screenWidth > 760
                          ? "row"
                          : "column",
                      gap: Platform.OS === "web" && screenWidth > 760 ? 25 : 5,
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "column",
                        width: screenWidth > 760 ? "45%" : "100%",
                      }}
                    >
                      <View style={styles.textcontainer}>
                        <Text style={styles.label}>
                          Team Name <Text style={{ color: colors.red }}>*</Text>
                        </Text>

                        <TextInput
                          // label="Team Name"
                          id="teamName"
                          placeholder="Enter Team Name"
                          style={styles.input}
                          placeholderTextColor={colors.white}
                          value={values.teamName}
                          onChangeText={(text) => {
                            handleChange("teamName")(text);
                            handleInputTeamDetailsChange("teamName", text);
                          }}
                          // onChangeText={(text) => {
                          //   // handleChange("teamName");
                          //   handleInputTeamDetailsChange("teamName", text);
                          // }}
                          // onChange={handleChange}
                          onBlur={handleBlur("teamName")}
                          // inputMode={undefined}
                        />
                      </View>

                      {touched.teamName && errors.teamName && (
                        <View>
                          <Text style={{ color: "red" }}>
                            {errors.teamName}
                          </Text>
                        </View>
                      )}
                      {isDuplicateTeamName === true ? (
                        <View>
                          <Text style={{ color: "red" }}>
                            Team name already exists
                          </Text>
                        </View>
                      ) : null}
                    </View>
                    <View
                      style={{
                        flexDirection: "column",
                        width: screenWidth > 760 ? "45%" : "100%",
                      }}
                    >
                      <View style={styles.textcontainer}>
                        <Text style={styles.label}>
                          Team Short Name
                          <Text style={{ color: colors.red }}>*</Text>
                        </Text>
                        <TextInput
                          id="teamShortName"
                          placeholder="Enter three characters"
                          style={styles.input}
                          placeholderTextColor={colors.white}
                          value={values.teamShortName.trim().toUpperCase()}
                          onChangeText={(text) => {
                            handleChange("teamShortName")(text);
                            handleInputTeamDetailsChange("teamShortName", text);
                          }}
                          // onChange={handleChange}
                          onBlur={handleBlur("teamShortName")}
                        />
                      </View>
                      {touched.teamShortName && errors.teamShortName && (
                        <View>
                          <Text style={{ color: "red" }}>
                            {errors.teamShortName}
                          </Text>
                        </View>
                      )}
                      {isDuplicateTeamShortName === true ? (
                        <View>
                          <Text style={{ color: "red" }}>
                            TeamShortName already exists
                          </Text>
                        </View>
                      ) : null}
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection:
                        Platform.OS === "web" && screenWidth > 760
                          ? "row"
                          : "column",
                      gap: Platform.OS === "web" && screenWidth > 760 ? 25 : 5,
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "column",
                        width: screenWidth > 760 ? "45%" : "100%",
                      }}
                    >
                      <Text style={styles.label}>
                        Coach Name <Text style={{ color: colors.red }}>*</Text>
                      </Text>

                      <View style={styles.textcontainer}>
                        <TextInput
                          id="coachName"
                          // label="Coach Name"
                          placeholder="Enter Coach Name"
                          value={values.coachName}
                          style={styles.input}
                          placeholderTextColor={colors.white}
                          onChangeText={(text) => {
                            // handleChange("teamName");
                            handleChange("coachName")(text);
                            handleInputTeamDetailsChange("coachName", text);
                          }}
                          // inputMode={undefined}
                          // validation={undefined}
                          // containerStyle={styles.coachNameTextField}
                          // onChange={handleChange}
                          onBlur={handleBlur("coachName")}
                        />
                      </View>
                      {touched.coachName && errors.coachName && (
                        <View>
                          <Text style={{ color: "red" }}>
                            {errors.coachName}
                          </Text>
                        </View>
                      )}
                    </View>

                    <View
                      style={{
                        flexDirection: "column",
                        width: screenWidth > 760 ? "45%" : "100%",
                      }}
                    >
                      <Text style={styles.label}>
                        Coach Mobile No{" "}
                        <Text style={{ color: colors.red }}>*</Text>
                      </Text>

                      <View style={styles.textcontainer}>
                        <TextInput
                          id="coachMobileNo"
                          placeholder="Enter Mobile No"
                          value={values.coachMobileNo}
                          placeholderTextColor={colors.white}
                          onChangeText={(text) => {
                            handleChange("coachMobileNo")(text);
                            handleInputTeamDetailsChange("coachMobileNo", text);
                          }}
                          style={styles.input}
                          inputMode="numeric"
                          maxLength={10}
                          // onChange={handleChange}
                          onBlur={handleBlur("coachMobileNo")}
                        />
                      </View>
                      {touched.coachMobileNo && errors.coachMobileNo && (
                        <View>
                          <Text style={{ color: "red" }}>
                            {errors.coachMobileNo}
                          </Text>
                        </View>
                      )}
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection:
                        Platform.OS === "web" && screenWidth > 760
                          ? "row"
                          : "column",
                      gap: Platform.OS === "web" && screenWidth > 760 ? 25 : 5,
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "column",
                        width: screenWidth > 760 ? "45%" : "100%",
                      }}
                    >
                      <View style={styles.textcontainer}>
                        <Text style={styles.label}>Manager Name</Text>
                        <TextInput
                          id="adminName"
                          placeholder="Enter Manager Name"
                          value={values.adminName}
                          onChangeText={(text) => {
                            handleChange("adminName")(text);
                            handleInputTeamDetailsChange("adminName", text);
                          }}
                          // inputMode={undefined}
                          style={styles.input}
                          placeholderTextColor={colors.white}
                          // onChange={handleChange}
                          onBlur={handleBlur("adminName")}
                        />
                      </View>
                      {touched.adminName && errors.adminName && (
                        <View>
                          <Text style={{ color: "red" }}>
                            {errors.adminName}
                          </Text>
                        </View>
                      )}
                    </View>
                    <View
                      style={{
                        flexDirection: "column",
                        width: screenWidth > 760 ? "45%" : "100%",
                      }}
                    >
                      <View style={styles.textcontainer}>
                        <Text style={styles.label}>Manager Mobile No</Text>
                        <TextInput
                          placeholder="Enter Mobile No"
                          value={values.adminMobileNo}
                          id="adminMobileNo"
                          onChangeText={(text) => {
                            handleChange("adminMobileNo")(text);
                            handleInputTeamDetailsChange("adminMobileNo", text);
                          }}
                          style={styles.input}
                          inputMode="numeric"
                          maxLength={10}
                          placeholderTextColor={colors.white}
                          // onChange={handleChange}
                          onBlur={handleBlur("adminMobileNo")}
                        />
                      </View>
                      {touched.adminMobileNo && errors.adminMobileNo && (
                        <View>
                          <Text style={{ color: "red" }}>
                            {errors.adminMobileNo}
                          </Text>
                        </View>
                      )}
                    </View>
                  </View>
                </View>
              </View>

              {Platform.OS === "web" && screenWidth > 760 ? (
                <>
                  {/* table started here */}

                  <View
                    style={{
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      paddingBottom: 6,
                      width: "100%",
                    }}
                  >
                    <Pressable
                      onPress={handleAddPlayer}
                      style={styles.addTeamBtn}
                    >
                      <AddIcon name="plus-circle" size={16} color="#fff" />
                      <Text
                        style={{
                          color: "#fff",
                          fontSize: 12,
                          fontFamily: "PlusJakartaSansSemiBold",
                        }}
                      >
                        Add Player
                      </Text>
                    </Pressable>
                  </View>
                  {/* <View style={{ marginBottom: 5 }}>
                  <Text style={styles.tableHeaderText}>
                    Note: Delete the player records you do not wish to add
                  </Text>
                </View> */}
                  <View
                    style={{
                      width: "100%",
                      display:
                        Platform.OS === "web" && screenWidth > 760
                          ? "flex"
                          : "none",
                    }}
                  >
                    <View style={styles.tableHeader}>
                      <View style={{ maxWidth: 90, flex: 1 }}>
                        <Text style={styles.tableHeaderText}>Sr. No.</Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={styles.tableHeaderText}>
                          Player Name
                          <Text style={{ color: colors.red }}>*</Text>
                        </Text>
                      </View>
                      <View style={{ maxWidth: 140, flex: 1 }}>
                        <Text style={styles.tableHeaderText}>
                          Date of Birth
                          <Text style={{ color: colors.red }}>*</Text>
                        </Text>
                      </View>
                      <View style={{ maxWidth: 60, flex: 1 }}>
                        <Text style={styles.tableHeaderText}>
                          J No.
                          <Text style={{ color: colors.red }}>*</Text>
                        </Text>
                      </View>
                      <View
                        style={{
                          maxWidth: 170,
                          flex: 1,
                          marginLeft: 10,
                        }}
                      >
                        <Text style={styles.tableHeaderText}>
                          Player Mobile No
                        </Text>
                      </View>
                    </View>

                    {playersList && playersList.length > 0 ? (
                      playersList.map((item, i) => (
                        <View
                          style={[
                            styles.tableDataRow,
                            {
                              // backgroundColor:
                              //   i % 2 === 0 ? colors.bgColor : colors.blue,
                              borderBottomWidth: 1,
                              borderBottomColor: colors.bgColor,
                            },
                          ]}
                        >
                          <View style={[styles.dataTableIndex]}>
                            <Text style={{ color: colors.white, fontSize: 16 }}>
                              {i + 1}
                            </Text>
                          </View>
                          <View style={styles.dataTableProfile}>
                            {/* <Pressable onPress={() => pickImageAsync(i)}>
                            <View style={styles.profilePicCircle}>
                              <Image
                                source={{
                                  uri:
                                    profilePicsArray && profilePicsArray[i]?.Pic
                                      ? profilePicsArray[i]?.Pic
                                      : userImage,
                                }}
                                style={{
                                  width: 30,
                                  height: 30,
                                  paddingLeft: 10,
                                }}
                              />
                            </View>
                          </Pressable> */}

                            <UploadImage
                              style={{ width: 30, height: 30 }}
                              onImageSelect={handleImageSelection}
                              kindOfFile="profilePic"
                              index={i}
                            />
                          </View>
                          <View style={styles.dataTableNameInput}>
                            <TextInput
                              id="Name"
                              style={{
                                height: 30,
                                width: "100%",
                                padding: 10,
                                borderColor: colors.borderColor,
                                borderWidth: 1,
                                borderRadius: 5,
                                color: colors.white,
                              }}
                              onChangeText={(text) =>
                                handleInputPlayerChange("Name", text, i)
                              }
                              value={item.Name}
                              placeholder="Enter Name"
                              placeholderTextColor={colors.white}
                            />

                            {item.Name == "" ? (
                              <Text style={{ color: "red" }}>required</Text>
                            ) : null}
                          </View>
                          <View style={styles.dataTableDateInput}>
                            {/* <CustomDateField
                              containerStyle={undefined}
                              placeholder={"Select Date"}
                              isRequired={true}
                              value={item.DOB}
                              onChangeText={(e) => {
                                const newList = [...playersList];
                                newList[i].DOB = e.target.value;
                                setPlayersList(newList);
                              }}
                              label={undefined}
                              onBlur={undefined}
                              id={undefined}
                            /> */}
                            <CustomDatePicker
                              selected={item.DOB}
                              onChange={(date) => handleDateChange(i, date)}
                              // onChange={}
                              containerStyle={{
                                height: 30,
                                width: 140,
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: colors.royalBlue,
                                borderColor: colors.borderColor,
                                borderWidth: 1,
                                borderRadius: 5,
                              }}
                              portalId={undefined}
                            />

                            {item.DOB == "" ? (
                              <Text style={{ color: "red" }}>required</Text>
                            ) : null}
                          </View>
                          <View style={styles.dataTableJersyInput}>
                            <TextInput
                              id="JerseyNo"
                              style={{
                                height: 30,
                                width: "100%",
                                padding: 10,
                                borderColor: colors.borderColor,
                                borderWidth: 1,
                                borderRadius: 5,
                                color: colors.white,
                              }}
                              onChangeText={(text) =>
                                handleInputPlayerChange("JerseyNo", text, i)
                              }
                              value={item.JerseyNo}
                              placeholder="JNo"
                              placeholderTextColor={colors.white}
                            />

                            {item.JerseyNo == "" || null ? (
                              <Text style={{ color: "red" }}>required</Text>
                            ) : null}
                          </View>
                          <View style={styles.dataTableMobNoInput}>
                            <TextInput
                              id="MobileNo"
                              style={{
                                height: 30,
                                width: "100%",
                                padding: 10,
                                borderColor: colors.borderColor,
                                borderWidth: 1,
                                borderRadius: 5,
                                color: colors.white,
                              }}
                              onChangeText={(text) =>
                                handleInputPlayerChange("MobileNo", text, i)
                              }
                              value={item.MobileNo}
                              inputMode="numeric"
                              placeholder="Enter Mobile No"
                              maxLength={10}
                              placeholderTextColor={colors.white}
                            />

                            {(item.MobileNo && item.MobileNo.length < 10) ||
                            item.MobileNo.length > 10 ? (
                              <Text style={{ color: "red" }}>
                                Invalid Number
                              </Text>
                            ) : null}
                          </View>
                          <View
                            style={[styles.dataTableIndex, { paddingTop: 2 }]}
                          >
                            <Pressable
                              onPress={() => handleDeletePlayer(i)}
                              style={styles.scoreSheetDeleteBtn}
                            >
                              {/* <TrashIcon
                                name="trash-bin-sharp"
                                size={18}
                                color="red"
                              /> */}
                              <DeleteIcon name="trash" size={22} color="red" />
                            </Pressable>
                          </View>
                        </View>
                      ))
                    ) : (
                      <Text
                        // props={
                        //   "Max Players In Squad value is not entered while creating a tournament form ! Please contact to tournament admin !"
                        // }
                        style={{
                          color: colors.white,
                          fontFamily: "PlusJakartaSansExtraLight",
                          fontSize: 16,
                          paddingVertical: 10,
                        }}
                      >
                        No any players added yet ! Please click on add player
                        button !
                      </Text>
                    )}
                  </View>
                </>
              ) : (
                // mobile view
                <View style={{ gap: 10 }}>
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 15,
                    }}
                  >
                    <Pressable
                      onPress={handleOpenPlayerDetailsModal}
                      style={styles.addTeamBtn}
                    >
                      <AddIcon
                        name="plus-circle"
                        size={16}
                        color={colors.white}
                      />
                      <Text
                        style={{
                          color: colors.white,
                          fontSize: 12,
                          fontFamily: "PlusJakartaSansSemiBold",
                        }}
                      >
                        Add Player
                      </Text>
                    </Pressable>
                  </View>

                  {/*playerCard 1*/}
                  {playersList && playersList.length > 0 ? (
                    playersList.map((data, index) => (
                      <View
                        style={{ flexDirection: "column", gap: 10 }}
                        key={index}
                      >
                        <View style={styles.playerCard}>
                          {/* <UploadImage
                            style={{ width: 80, height: 80 }}
                            onImageSelect={handleImageSelection}
                            kindOfFile="profilePic"
                          /> */}

                          <Image
                            source={{
                              uri: data.PhotoURL ? data.PhotoURL : null,
                            }}
                            style={{ width: 80, height: 80, borderRadius: 40 }}
                          />

                          <View
                            style={{
                              flexDirection: "column",
                              gap: 5,
                              flex: 1,
                              padding: 15,
                            }}
                          >
                            <Text style={styles.playerMobText}>
                              {data.Name ? data.Name : null}
                            </Text>
                            <Text style={styles.playerMobText}>
                              {data.DOB ? data.DOB : null}
                            </Text>
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Text style={styles.playerMobText}>
                                {data.MobileNo ? data.MobileNo : null}
                              </Text>
                              <Text style={styles.playerMobText}>
                                {`Jersey No.${
                                  data.JerseyNo ? data.JerseyNo : null
                                }`}
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    ))
                  ) : (
                    <Text style={{ color: "white" }}>
                      No any players added yet !
                    </Text>
                  )}

                  <PlayerDetailsModal
                    isVisible={isPlayerDetailsModalVisible}
                    onClose={handleClosePlayerDetailsModal}
                    imageSelection={handleImageSelection}
                    onSubmit={handleSubmitPlayerDetails}
                  />
                </View>
              )}

              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: 10,
                }}
              >
                <Pressable
                  onPress={() => {
                    navigation.navigate("SelectTeam", {
                      userUUID: userUUID,
                      squadIds: squadIds,
                      currentUserName: currentUserName,
                      tournamentId: tournamentId
                        ? tournamentId
                        : getTournamentId,
                    });
                  }}
                  style={[
                    styles.cancelBtn,
                    {
                      backgroundColor: "transparent",
                    },
                  ]}
                >
                  <Text style={[styles.cancelBtnText]}>Cancel</Text>
                </Pressable>
                <Button
                  buttonStyle={{
                    backgroundColor: "#12FFE3",
                    borderRadius: 10,
                    borderColor: "transparent",
                    width: 150,
                    height:
                      Platform.OS === "web" && screenWidth > 760 ? 30 : 40,
                  }}
                  onPress={() => handleSubmit()}
                  titleStyle={{
                    textAlign: "center",
                    fontFamily: "PlusJakartaSansMedium",
                    fontSize: 14,
                    color: colors.royalBlueColour,
                  }}
                  containerStyle={{
                    // width: 200,
                    // height: 30,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  title="Submit"
                />
              </View>

              {successModalVisible == true ? (
                <SuccessModalNew
                  visibleModal={successModalVisible}
                  SuccessMessage={successModalMesg}
                  handleOk={handleOk}
                  handleClose={undefined}
                  tournamentId={TournamentId}
                  teamId={TeamId}
                />
              ) : null}

              {errorModalVisible == true ? (
                <ErrorModal
                  visibleModal={errorModalVisible}
                  SuccessMessage={errorModalMesg}
                  header={"Attention"}
                  onSubmit={handleSubmitError}
                />
              ) : null}

              {deleteModalVisible == true ? (
                <AreYouSureOrange
                  visibleModal={deleteModalVisible}
                  Message={deleteModalMessage}
                  ButtonText={"Delete Player"}
                  onCancel={cancelDelete}
                  onConfirm={confirmDelete}
                />
              ) : null}

              {/* Loader */}
              {loading && (
                <Modal transparent animationType="none">
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    <ActivityIndicator size="large" color="#0000ff" />
                    <Text style={{ color: "white" }}>Submitting</Text>
                  </View>
                </Modal>
              )}
            </View>
          </View>
        )}
      </Formik>
    </ScrollView>
  );
};

const styles: any = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 50,
    backgroundColor: colors.bgColor,
    paddingTop: 80,
  },

  textcontainer: {
    marginBottom: 5,
  },
  card: {
    padding: Platform.OS === "web" && screenWidth > 760 ? 10 : 10,
    backgroundColor: colors.royalBlue,
    borderRadius: Platform.OS === "web" && screenWidth > 760 ? 10 : 0,
    height: "100%",
    width: Platform.OS === "web" && screenWidth > 760 ? 750 : "100%",
    position: "relative",
    gap: 5,
    paddingTop: Platform.OS === "web" && screenWidth > 760 ? undefined : 20,
  },
  input: {
    height: 30,
    fontSize: 15,
    fontFamily: "PlusJakartaSansRegular",
    borderColor: colors.borderColor,
    borderWidth: 1,
    borderRadius: 5,
    paddingLeft: 10,
    // outlineColor: "transparent",
    // outlineWidth: 0,
    color: colors.white,
  },

  label: {
    paddingBottom: 10,
    fontFamily: "PlusJakartaSansRegular",
    paddingTop: 10,
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 14 : 12,
    color: colors.white,
  },
  blueStrip: {
    width: "100%",
    height: 36,
    backgroundColor: colors.royalBlue,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    color: "white",
    fontFamily: "PlusJakartaSansRegular",
  },
  firstRow: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  secondRow: {
    width: "100%",
    // justifyContent: "space-between",
    flexDirection:
      Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
    gap: Platform.OS === "web" && screenWidth > 760 ? 30 : 10,
  },

  tournamentTitle: {
    color: colors.white,
    fontFamily: "PlusJakartaSansBold",
    textAlign: "center",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 24 : 20,
  },
  bannerViewContainer: {
    width: "100%",
    backgroundColor: colors.grayColour,
    height: 250,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.borderColor,
    justifyContent: "center",
  },
  banerStyle: {
    width: "100%",
    height: 250,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.borderColor,
    justifyContent: "center",
  },
  subTitle: {
    fontSize: 18,
    color: colors.white,
    fontFamily: "PlusJakartaSansBold",
  },
  logoImage: {
    // width: "100%",
    // height: "100%",
    width: 150,
    height: 150,
    borderRadius: 100,
  },
  pressableContainer: {
    width: 150,
    height: 150,
  },
  circleContainer: {
    width: 150,
    height: 150,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: colors.borderColor,
    padding: 5,
  },
  circle: {
    width: "100%",
    height: "100%",
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.bgColor,
  },

  profilePicCircle: {
    width: 30,
    height: 30,
    borderRadius: 100,
    justifyContent: "center",
    alignItems: "center",
  },
  cameraIcon: {
    height: 30,
    width: 30,
  },
  teamTextField: {
    width: Platform.OS === "web" && screenWidth > 760 ? 245 : "100%",
  },
  shortNameTextField: {
    width: Platform.OS === "web" && screenWidth > 760 ? 235 : "100%",
  },
  coachNameTextField: {
    width: Platform.OS === "web" && screenWidth > 760 ? 245 : "100%",
  },
  coachMobTextField: {
    width: Platform.OS === "web" && screenWidth > 760 ? 235 : "100%",
  },

  tableTitleStyle: {
    color: "white",
  },
  tableHeader: {
    width: "100%",
    backgroundColor: colors.blue,
    flexDirection: "row",
    padding: 5,
    paddingVertical: 7,
  },
  nameTextinput: {
    borderColor: colors.borderColor,
    borderWidth: 1,
    borderRadius: 5,
    alignItems: "center",
    height: 30,
    width: "100%",
    padding: 10,
    fontFamily: "PlusJakartaSansRegular",
    color: colors.white,
  },

  tableRowStyle: {
    justifyContent: "space-between",
  },
  dataTableIndex: {
    maxWidth: 20,
    flex: 1,
    paddingHorizontal: 0,
    color: colors.white,
  },
  dataTableProfile: {
    maxWidth: 50,
    flex: 1,
    paddingHorizontal: 0,
  },
  dataTableNameInput: {
    flex: 1,
    paddingHorizontal: 0,
    justifyContent: "flex-start",
  },
  dataTableDateInput: {
    maxWidth: 140,
    flex: 1,
    paddingHorizontal: 0,
    justifyContent: "flex-start",
  },
  dataTableJersyInput: {
    flex: 1,
    maxWidth: 60,
    alignItems: "center",
    paddingHorizontal: 0,
    justifyContent: "flex-start",
  },
  dataTableMobNoInput: {
    maxWidth: 140,
    flex: 1,
    paddingHorizontal: 0,
  },
  submitBtn: {
    backgroundColor: colors.aqua,
    fontFamily: "PlusJakartaSansSemiBold",
    color: "#000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    fontSize: 14,
    width: 185,
    height: 50,
  },
  // tableTitle: {,
  //   width: 200,
  //   flex: "unset",
  //   paddingVertical: 7,
  // },

  webContainer: {
    // Style for web layout
  },
  mobileContainer: {
    borderWidth: 1,
    borderColor: colors.borderColor,
    marginBottom: 10,
    borderRadius: 5,
    padding: 10,
  },
  playerCard: {
    flexDirection: "row",
    width: "100%",
    backgroundColor: colors.bgColor,
    padding: 15,
    borderRadius: 10,
    shadowOffset: {
      width: 3,
      height: 4,
    },
    shadowColor: "#000000",
    shadowOpacity: 0.22,
    shadowRadius: 10,
    elevation: 3,
  },
  playerMobText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 14,
  },
  cancelBtn: {
    borderWidth: 1,
    borderColor: colors.white,
    padding: 5,
    borderRadius: 10,
    fontSize: 14,
    width: 120,
    height: Platform.OS === "web" && screenWidth > 760 ? 30 : 40,
    justifyContent: "center",
    alignItems: "center",
  },
  cancelBtnText: {
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 16,
    textAlign: "center",
    color: colors.white,
  },
  addTeamBtn: {
    backgroundColor: colors.orange,
    flexDirection: "row",
    gap: 5,
    alignItems: "center",
    justifyContent: "center",
    width: 100,
    borderRadius: 5,
    padding: 4,
  },
  scoreSheetDeleteBtn: {
    width: 15,
    justifyContent: "flex-start",
    alignItems: "center",
  },
  tableHeaderText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansExtraLight",
    fontSize: 12,
  },
  tableDataRow: {
    width: "100%",
    flexDirection: "row",
    padding: 5,
    paddingVertical: 10,
    gap: 10,
  },
});

export default TeamParticipationForm;
