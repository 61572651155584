import React, { useState, useRef, useContext, useEffect } from "react";
import {
  View,
  Text,
  Image,
  StyleSheet,
  TextInput,
  Platform,
  Dimensions,
  Pressable,
  ImageBackground,
  ActivityIndicator,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { DataStore } from "aws-amplify/datastore";
import { H1, H2, H3 } from "../../../components/reusable/TextComponent";
import { CoachMaster, UserMaster } from "../../../models";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AuthenticationContext } from "../../../authentication/Authentication.js";
import { LinearGradient } from "expo-linear-gradient";
import Layout from "../../../components/include/Layout";
import { colors } from "../../../components/reusable/colour";
import { ErrorModal } from "../../../components/reusable/AreYouSure";
import { SuccessAutoExitModal } from "../../../components/reusable/AutoExitModal";

import App from "../../../../App";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

const OTPVerification = ({ route, navigation }) => {
  const {
    handleConfirmSignIn,
    getAuthenticatedUser,
    handleConfirmSignUp,
    handleResendCode,
    handleSignIn,
    isLoggedIn,
    setIsLoggedIn,
  } = useContext(AuthenticationContext);
  // console.log("RouteParams", route.params);
  // console.log("navigation", navigation);

  const { mobile, name, email, isSignUp, session, userId, prevPage, nextPage } =
    route.params.data;
  const cardRef = useRef();
  const [mobileNo, setMobileNo] = useState("+91" + mobile);
  const [loading, setLoading] = useState(false);
  const navigationPath: any = useNavigation();
  const password = Math.random().toString(10) + "Abc#";
  const [message, setMessage] = useState("Welcome to Demo");
  const [signedIn, setSignedIn] = useState("false");
  const [user, setUser] = useState("");
  const [isSignUpTrue, setIsSignUp] = useState(isSignUp);
  // const [userSession, setUserSession] = useState(session);
  const [userDetails, setUserDetails] = useState({});

  const NOTSIGNIN = "You are NOT logged in";
  const SIGNEDIN = "You have logged in successfully";
  const SIGNEDOUT = "You have logged out successfully";
  const WAITINGFOROTP = "Enter OTP number";
  const VERIFYNUMBER = "Verifying number (Country code +XX needed)";
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible]: any = useState(false);
  const [successModalMesg, setSuccessModalMesg] = useState("");
  const [errorModalMesg, setErrorModalMesg] = useState("");
  let data = "";
  const [uuid, setUUID] = useState(userId);
  const [otp, setOTP] = useState(["", "", "", "", "", ""]);
  const [bottomflag, setbottomflag] = useState(false);
  const [hoverIn, setHoverIn] = useState(false);

  const [previousURL, setPreviousURL] = useState("");
  const [tournamentId, setTournamentId] = useState("");
  const [teamId, setTeamId] = useState("");

  const otpRefs = Array(6)
    .fill(null)
    .map(() => useRef<TextInput>(null));

  const handleHoverIn = () => {
    setHoverIn(true);
  };
  const handleHoverOut = () => {
    setHoverIn(false);
  };

  console.log("isSignUp", isSignUp);

  useEffect(() => {
    console.log("previousPage", prevPage);
    // console.log("window", window.history.length);

    const fetchData = async () => {
      const previousPageUrl = await AsyncStorage.getItem("pageUrl");
      console.log("previousPageUrl", previousPageUrl);
      setPreviousURL(previousPageUrl);
      console.log("after saving previous url", previousPageUrl);

      if (previousPageUrl) {
        // Create a URL object
        const urlObject = new URL(previousPageUrl);

        // Get the pathname from the URL (e.g., "/PlayerRegistration/tournamentId/bbbcf975-3ecd-475e-a8fd-9530f7a60c25/teamId/ac0343b3-4cde-4a14-ad05-af3196a2bdab")
        const pathname = urlObject.pathname;

        // Split the pathname into segments
        const segments = pathname.split("/");

        // Find the indices of "tournamentId" and "teamId" in the segments array
        const tournamentIdIndex = segments.indexOf("tournamentId") + 1;
        const teamIdIndex = segments.indexOf("teamId") + 1;

        // Extract the tournamentId and teamId from the segments
        const tournamentId = segments[tournamentIdIndex];
        setTournamentId(tournamentId);
        const teamId = segments[teamIdIndex];
        setTeamId(teamId);

        console.log("Tournament ID:", tournamentId);
        console.log("Team ID:", teamId);
      } else {
        console.log("No previous page url found");
      }
    };
    fetchData();
  }, []);

  const verifySignUp = async (mobile) => {
    console.log(mobile);
    try {
      const signUpResult = await handleConfirmSignUp(
        "+91" + mobile,
        otp.join("")
      );
      if (signUpResult.status == "Success") {
        console.log("In confirm sign up");
        // console.log("User : ", signUpResult.result.userId);
        // data = signUpResult.result.userId;

        setUser(userId);
        setMessage(SIGNEDIN);
        await AsyncStorage.setItem("UserDetail", userId);
        setIsLoggedIn(true);

        // setUserSession(user);
        return userId;
      } else {
        if (
          signUpResult.status == "Error" &&
          signUpResult.result.name == "AutoSignInException"
        ) {
          setSuccessModalMesg(
            "Congratulations! You have been registerd successfully! Please Sign in to continue."
          );
          setSuccessModalVisible(true);
          navigation.navigate("SignIn");
        }
        console.error("Error in verifying OTP:", signUpResult.result);
        setErrorModalMesg(`Error! ${signUpResult.result}`);
        setErrorModalVisible(true);
      }
    } catch (error) {
      console.log("error confirming sign up", error);
      setErrorModalMesg(error);
      setErrorModalVisible(true);
    }
  };

  const saveData = async (user) => {
    const userUUID = user;
    const coachSummary = await DataStore.save(
      new CoachMaster({
        Games: 0,
        Won: 0,
        Lost: 0,
        Drawn: 0,
      })
    );

    const userMaster = await DataStore.save(
      new UserMaster({
        UUID: userUUID,
        Name: name,
        ContactNo: Number(mobile),
        EmailId: email,
        PhotoURL: "",
        CreatedBy: name,
        LastUpdatedBy: name,
        RoleCoach: true,
        RolePlayer: true,
        CoachSummary: coachSummary,
      })
    );
    if (userMaster) {
      console.log("SUCESSFULLY ADDED USER");

      console.log("previousPageURL", previousURL);
      if (prevPage?.includes("/TournamentDetails")) {
        console.log("Navigating to next page:", nextPage);
        setLoading(false);
        navigation.navigate(nextPage);
        console.log("previousPageURL", previousURL);
      } else if (previousURL) {
        console.log("previousURl block executed!!!");
        navigation.navigate("PlayerRegistration", {
          tournamentId: tournamentId,
          teamId: teamId,
        });
        // navigation.navigate(previousPageURL.toString());
      } else if (Platform.OS === "web" && !previousURL) {
        console.log("Navigating to Dashboard");
        navigation.navigate("Dashboard");
      } else if (Platform.OS === "android" || Platform.OS === "ios") {
        console.log("Navigating to BottomMenu");
        navigation.navigate("BottomMenu");
      } else {
        console.log("prevPage is not found", prevPage);
      }
    }
    setLoading(false);
  };

  const resendOtp = async () => {
    if (isSignUp) {
      try {
        const { destination, deliveryMedium, attributeName } =
          await handleResendCode(mobile);
        console.log(
          "code resent successfully",
          destination,
          deliveryMedium,
          attributeName
        );
      } catch (err) {
        setErrorModalMesg(`Error in resending OTP!`);
        setErrorModalVisible(true);
      }
    } else {
      setErrorModalMesg(`Error in resending OTP!`);
      setErrorModalVisible(true);
      navigation.navigate("SignIn");
    }
  };

  const verifyOtp = async () => {
    // console.log("User Session : ", userSession);

    const otpResult = await handleConfirmSignIn(otp.join(""));
    if (otpResult.status == "Success") {
      const { username, session } = await getAuthenticatedUser();
      console.log("OtpSession", session);
      setUser(username);
      setMessage(SIGNEDIN);
      try {
        console.log("User is signed in.");

        const id = session.accessToken.payload.sub;
        console.log("User ID:", id);
        console.log("UserMater", UserMaster);
        await AsyncStorage.setItem("UserDetail", id);
        setIsLoggedIn(true);
        console.log("Sign-in data stored successfully.");
        const userdata = await DataStore.query(UserMaster, id);
        console.log("UserData after OTP", userdata);
        if (userdata) {
          console.log("User data found:", userdata);
          console.log("previousPageURL", previousURL);
          if (prevPage?.includes("/TournamentDetails")) {
            console.log("Navigating to next page:", nextPage);
            setLoading(false);
            navigation.navigate(nextPage);
            console.log("previousPageURL", previousURL);
          } else if (previousURL) {
            console.log("previousURl block executed!!!", previousURL);
            navigation.navigate("PlayerRegistration", {
              tournamentId: tournamentId,
              teamId: teamId,
            });
          } else if (Platform.OS === "web" && !previousURL) {
            console.log("Navigating to Dashboard");
            navigation.navigate("Dashboard");
          } else if (Platform.OS === "android" || Platform.OS === "ios") {
            console.log("Navigating to BottomMenu");
            navigation.navigate("BottomMenu");
          }
        } else {
          console.log("User data not found:");
          await saveData(id);
        }
      } catch (error) {
        console.error("Error setting AsyncStorage item:", error);
        setLoading(false);
      }
    } else {
      console.error("Error in verifying OTP:", otpResult.result);
      setLoading(false);
      handleChangeModal();
    }
  };
  const handleChangeModal = () => {
    console.log("handleChangeModal");
    setErrorModalMesg("Error! Occured");
    setErrorModalVisible(true);
  };
  const handleOTPSubmit = async () => {
    setLoading(true);
    const enteredOTP = otp.join("");

    console.log("Entered OTP:", enteredOTP);
    console.log("IsSignUp : ", isSignUpTrue);
    if (isSignUp) {
      const userId = await verifySignUp(mobile);
      // await getCurrentUser();
      await saveData(userId);
    } else {
      await verifyOtp();
    }
  };

  const updateOTPDigit = (index: number, value: string) => {
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);
  };

  return (
    <View style={{ height: "100%" }}>
      {Platform.OS == "web" && screenWidth > 760 ? (
        <>
          <Layout>
            <View style={styleByAkash.signInMainContainer}>
              <LinearGradient
                style={styleByAkash.signInLeft}
                colors={["#0D0535", "#1E0C80"]}
                start={{ x: 1, y: 0 }}
                end={{ x: 1, y: 1 }}
              >
                <View style={styleByAkash.signInLeftInner}>
                  <Text style={styleByAkash.signInHeading}>Welcome Back</Text>
                  <Text style={styleByAkash.signInSmallHeading}>
                    One Platform,
                  </Text>
                  <Text style={styleByAkash.signInSmallHeading}>
                    Infinite Possibilities.
                  </Text>
                  <View style={{ width: "100%", alignItems: "flex-end" }}>
                    <Image
                      source={require("../../../../assets/login/LoginImage.png")}
                      style={styleByAkash.logoMainImage}
                    />
                  </View>
                </View>
                <Image
                  source={require("../../../../assets/login/LoginElement.png")}
                  style={styleByAkash.absoluteIcon}
                />
              </LinearGradient>
              <View style={styleByAkash.signInRight}>
                <View style={styleByAkash.SignInContainer}>
                  <Text style={styleByAkash.signupStyle}>Code validation</Text>
                  <Text style={styleByAkash.createAccountText}>
                    Please enter the 6 digit code sent to your mobile number:{" "}
                    {mobile}
                  </Text>
                  <View style={styles.otpInputContainer}>
                    {otp.map((digit, index) => (
                      <TextInput
                        key={index}
                        ref={otpRefs[index]}
                        style={styles.otpDigit}
                        value={digit}
                        onChangeText={(value) => {
                          updateOTPDigit(index, value);
                          if (value && index < otpRefs.length - 1) {
                            otpRefs[index + 1].current?.focus();
                          }
                        }}
                        keyboardType="numeric"
                        maxLength={1}
                        onSubmitEditing={handleOTPSubmit}
                        onKeyPress={({ nativeEvent }) => {
                          if (nativeEvent.key === "Backspace") {
                            updateOTPDigit(index, "");
                            if (index > 0) {
                              otpRefs[index - 1].current?.focus();
                            }
                          }
                        }}
                      />
                    ))}
                  </View>
                  <Text
                    style={[
                      styleByAkash.areYouNewText,
                      { marginBottom: 30, marginTop: 7 },
                    ]}
                  >
                    Didn’t get the code?
                    <Pressable onPress={resendOtp}>
                      <Text style={styleByAkash.termCondition}>
                        Resend code
                      </Text>
                    </Pressable>
                  </Text>
                  <Pressable
                    onPress={handleOTPSubmit}
                    onHoverIn={handleHoverIn}
                    onHoverOut={handleHoverOut}
                    style={[
                      styleByAkash.button,
                      {
                        backgroundColor:
                          hoverIn === true ? colors.orange : colors.aqua,
                      },
                    ]}
                  >
                    <Text
                      style={[
                        styleByAkash.buttonText,
                        {
                          color:
                            hoverIn === true ? colors.white : colors.royalBlue,
                        },
                      ]}
                    >
                      Verify OTP
                    </Text>
                    {loading && (
                      <ActivityIndicator
                        size="small"
                        color="#0000ff"
                        // style={{ position: "absolute", right: 50 }}
                      />
                    )}
                  </Pressable>
                  <View
                    style={{
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      marginTop: 20,
                    }}
                  >
                    <Text style={styleByAkash.areYouNewText}>
                      By signing in you are agreeing to our
                    </Text>
                    <Text style={styleByAkash.termCondition}>
                      Terms and Privacy Policy
                    </Text>
                  </View>
                  <View>{bottomflag == true ? <App /> : null}</View>
                </View>
              </View>
              {errorModalVisible == true ? (
                <ErrorModal
                  visibleModal={errorModalVisible}
                  SuccessMessage={errorModalMesg}
                  onSubmit={setErrorModalVisible(!errorModalVisible)}
                  header={undefined}
                />
              ) : null}
              {successModalVisible == true ? (
                <SuccessAutoExitModal
                  visibleModal={successModalVisible}
                  SuccessMessage={successModalMesg}
                  handleOk={setSuccessModalVisible(!successModalVisible)}
                />
              ) : null}
            </View>
          </Layout>
        </>
      ) : (
        <View style={styleByAkash.signInMainContainer}>
          <View style={styleByAkash.signInRight}>
            <View style={styleByAkash.SignInContainer}>
              <H1
                props={"Code validation"}
                style={styleByAkash.signupStyle}
              ></H1>

              <Text style={styleByAkash.createAccountText}>
                Please enter the 6 digit code sent to your mobile number:{" "}
                {mobile}
              </Text>
              <View style={styles.otpInputContainer}>
                {otp.map((digit, index) => (
                  <TextInput
                    key={index}
                    ref={otpRefs[index]}
                    style={styles.otpDigit}
                    onSubmitEditing={handleOTPSubmit}
                    value={digit}
                    onChangeText={(value) => {
                      updateOTPDigit(index, value);
                      if (value && index < otpRefs.length - 1) {
                        otpRefs[index + 1].current?.focus();
                      }
                    }}
                    keyboardType="numeric"
                    maxLength={1}
                    onKeyPress={({ nativeEvent }) => {
                      if (nativeEvent.key === "Backspace") {
                        updateOTPDigit(index, "");
                        if (index > 0) {
                          otpRefs[index - 1].current?.focus();
                        }
                      }
                    }}
                  />
                ))}
              </View>
              <Text
                style={[
                  styleByAkash.areYouNewText,
                  {
                    marginBottom: 30,
                    marginTop:
                      Platform.OS === "web" && screenWidth > 760 ? 7 : 20,
                  },
                ]}
              >
                Didn’t get the code?
                <Pressable onPress={resendOtp}>
                  <Text style={styleByAkash.termCondition}>Resend code</Text>
                </Pressable>
              </Text>
              <Pressable
                onPress={handleOTPSubmit}
                onHoverIn={handleHoverIn}
                onHoverOut={handleHoverOut}
                style={[
                  styleByAkash.button,
                  {
                    backgroundColor:
                      hoverIn === true ? colors.orange : colors.aqua,
                  },
                ]}
              >
                <Text
                  style={[
                    styleByAkash.buttonText,
                    {
                      color: hoverIn === true ? colors.white : colors.royalBlue,
                    },
                  ]}
                >
                  Verify OTP
                </Text>
                {loading && (
                  <ActivityIndicator
                    size="small"
                    color="#0000ff"
                    // style={{ position: "absolute", right: 50 }}
                  />
                )}
              </Pressable>
              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  marginTop: 20,
                }}
              >
                <Text style={styleByAkash.areYouNewText}>
                  By signing in you are agreeing to our
                </Text>
                <Text style={styleByAkash.termCondition}>
                  Terms and Privacy Policy
                </Text>
              </View>
            </View>
          </View>
        </View>
      )}
    </View>
  );
};

const styleByAkash = StyleSheet.create({
  signInMainContainer: {
    width: "100%",
    height:
      Platform.OS === "web" && screenWidth > 760
        ? screenHeight * 0.9
        : screenHeight,
    backgroundColor: colors.bgColor,
    flexDirection: "row",
  },
  signInLeft: {
    flex: 1,
    padding: 50,
    position: "relative",
  },
  signInLeftInner: {
    backgroundColor: colors.blue,
    borderRadius: 20,
    padding: 30,
    paddingBottom: 0,
  },
  signInHeading: {
    color: colors.white,
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 35,
    marginBottom: 10,
  },
  signInSmallHeading: {
    color: colors.white,
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 20,
    marginTop: 5,
  },
  logoMainImage: {
    width: 235,
    height: 300,
    resizeMode: "contain",
  },
  absoluteIcon: {
    width: 195,
    height: 300,
    resizeMode: "contain",
    position: "absolute",
    left: 0,
    bottom: 0,
  },
  signInRight: {
    flex: 1,
    padding: Platform.OS === "web" && screenWidth > 760 ? 50 : 25,
    alignItems: "center",
  },
  SignInContainer: {
    width: "100%",
    height: "100%",
    maxWidth: 420,
    justifyContent: "center",
  },
  signupStyle: {
    textAlign: "center",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 20 : 30,
    fontFamily: "PlusJakartaSansMedium",
    color: colors.orange,
    marginBottom: 10,
  },
  createAccountText: {
    textAlign: "center",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 16 : 14,
    fontFamily: "PlusJakartaSansRegular",
    color: colors.white,
    marginBottom: 40,
  },
  button: {
    backgroundColor: colors.aqua,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 15,
    height: Platform.OS === "web" && screenWidth > 760 ? 38 : 45,
    flexDirection: "row",
    gap: 30,
  },

  buttonText: {
    color: colors.royalBlue,
    fontSize: 18,
    textAlign: "center",
    fontFamily: "PlusJakartaSansSemiBold",
  },
  bySignIn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    gap: 2,
    fontSize: 11,
    fontFamily: "PlusJakartaSansRegular",
    color: colors.white,
    marginTop: 25,
    marginBottom: 35,
  },
  termCondition: {
    fontSize: 11,
    color: colors.orange,
  },
  alreadyHaveContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 5,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  areYouNewText: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: 2,
    fontSize: 11,
    fontFamily: "PlusJakartaSansRegular",
    color: colors.white,
  },
});

const styles = StyleSheet.create({
  otpContainer2: {
    // flex: 1,
    padding: Platform.OS === "web" && screenWidth > 760 ? 70 : 30,
    backgroundColor: "white",
    borderRadius: 10,
    height: Platform.OS === "web" && screenWidth > 760 ? 520 : 500,
    width: Platform.OS === "web" && screenWidth > 760 ? 500 : 390,
    justifyContent: "center",
  },
  leftContent: {
    display: "flex",
    position: "absolute",
    left: 150,
    bottom: 70,
  },
  headingText: {
    color: "#fff",
    fontSize: 60,
    bottom: 80,
  },
  subHeadingText: {
    color: "#fff",
    fontSize: 30,
    bottom: 60,
  },

  backgroundImage: {
    resizeMode: "cover",
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems:
      Platform.OS === "web" && screenWidth > 760 ? "flex-end" : "center",
    paddingHorizontal:
      Platform.OS === "web" && screenWidth > 760 ? 80 : undefined,
    // backgroundImage:
    //   "linear-gradient(rgba(13, 5, 53, 1), rgba(30, 12, 128, 1))",
    backgroundColor: "#0d0535",
    position: "relative",
  },
  signupStyle: {
    textAlign: "center",
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "PlusJakartaSansMedium",
    color: "rgba(104, 104, 104, 1)",
    marginBottom: 10,
  },
  OtpSubText: {
    textAlign: "center",
    fontSize: 14,
    fontFamily: "PlusJakartaSansRegular",
    color: "rgba(104, 104, 104, 1)",
    marginBottom: 20,
  },
  otpInputContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  centerButton: {
    alignItems: "center",
    marginTop: 20,
  },
  OtpcodeSubTitle: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    left: Platform.OS === "web" && screenWidth > 760 ? undefined : 60,
    width: "100%",
    gap: 2,
    fontSize: 14,
    fontFamily: "PlusJakartaSansRegular",
    color: "rgba(104, 104, 104, 1)",
    padding: 20,
  },
  otpDigit: {
    width: 40,
    height: 40,
    fontSize: 20,
    borderWidth: 1,
    borderRadius: 7,
    borderColor: "gray",
    textAlign: "center",
    marginLeft: 10,
    color: colors.white,
  },
  bySignIn: {
    display: "flex",
    justifyContent: "center",
    left: Platform.OS === "web" && screenWidth > 760 ? undefined : 90,
    width: "100%",
    flexDirection: "row",
    fontSize: 10,
    fontFamily: "PlusJakartaSansRegular",
    color: "rgba(104, 104, 104, 1)",
    paddingTop: 25,
    // paddingBottom: 35,
  },
  termCondition: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    width: "100%",
    fontSize: 12,
    left: Platform.OS === "web" && screenWidth > 760 ? undefined : 110,
    color: "rgba(50, 123, 237, 1)",
  },
  resend: {
    fontSize: 12,
    color: "rgba(50, 123, 237, 1)",
  },
  button: {
    backgroundColor: "rgba(18, 255, 227, 1)",
    padding: 15,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 15,
  },
  buttonText: {
    color: "rgba(2, 32, 95, 1)",
    fontSize: 16,
    textAlign: "center",
  },
});

export default OTPVerification;
