import React, { useState, useRef, useContext, useEffect } from "react";
import { Ionicons } from "@expo/vector-icons";
import { signInWithRedirect } from "aws-amplify/auth";
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  Dimensions,
  Pressable,
  Linking,
  ActivityIndicator,
} from "react-native";
import { Amplify } from "aws-amplify";
import { MobileNumberField } from "../../../components/reusable/textFeild";
import { H1 } from "../../../components/reusable/TextComponent";
import { useNavigation } from "@react-navigation/native";
import awsmobile from "../../../aws-exports.js";
import { AuthenticationContext } from "../../../authentication/Authentication.js";
import { LinearGradient } from "expo-linear-gradient";
import Layout from "../../../components/include/Layout";
import { colors } from "../../../components/reusable/colour";
import { signOut } from "aws-amplify/auth";

Amplify.configure(awsmobile);

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;
// const cardRef = useRef();
const SignIn = ({ route }) => {
  const { prevPage, nextPage, userId } = route.params.data;
  const navigation: any = useNavigation();
  const [loading, setLoading] = useState(false);
  // const navigation2 =
  //   useNavigation<StackNavigationProp<any, "OTPVerification">>();

  useEffect(() => {
    console.log("PrevPage : ", prevPage);
  }, []);

  const { handleSignIn, getAuthenticatedUser, handleSignUp, handleResendCode } =
    useContext(AuthenticationContext);
  const [mobile, setMobile] = useState("");
  const [error, setError] = useState({ showError: false, message: "" });
  const [hoverIn, setHoverIn] = useState(false);
  const [hoverInGoogle, setHoverInGoogle] = useState(false);
  const [hoverInApple, setHoverInApple] = useState(false);
  let sessionData;
  const handleHoverIn = () => {
    setHoverIn(true);
  };
  const handleHoverOut = () => {
    setHoverIn(false);
  };

  const handleHoverInGoogle = () => {
    setHoverInGoogle(true);
  };
  const handleHoverOutGoogle = () => {
    setHoverInGoogle(false);
  };

  const handleHoverInApple = () => {
    setHoverInApple(true);
  };
  const handleHoverOutApple = () => {
    setHoverInApple(false);
  };

  const setShowError = (show, message) => {
    setError({ showError: show, message: message });
  };

  const handleAppleSignIn = async () => {
    console.log("in Apple Sign in");
    signInWithRedirect({ provider: "Apple" });
  };

  const handleGoogleSignIn = async () => {
    console.log("in Google Sign in");
    signInWithRedirect({ provider: "Google" });
  };

  const signIn = async (mobileNo) => {
    setLoading(true);
    console.log("Mobile No : ", mobileNo);
    const signInResult = await handleSignIn(mobileNo);
    console.log("Result : ", signInResult);
    if (signInResult.status == "Success") {
      if (signInResult.result.isSignedIn == false) {
        if (
          signInResult.result.nextStep &&
          signInResult.result.nextStep.signInStep &&
          signInResult.result.nextStep.signInStep ==
            "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE"
        ) {
          const sendData: any = {
            mobile: mobileNo,
            name: "",
            isSignUp: false,
            session: undefined,
            userId: signInResult.result.nextStep.additionalInfo.USERNAME,
            prevPage: prevPage,
            nextPage: nextPage,
          };
          setLoading(false);
          await navigation.navigate("OTPVerification", {
            data: sendData,
          });
        }
        if (
          signInResult.result.nextStep &&
          signInResult.result.nextStep.signInStep &&
          signInResult.result.nextStep.signInStep == "CONFIRM_SIGN_UP"
        ) {
          // const response = await handleSignUp("+91"+ mobile);
          // console.log("response",response);
          await handleResendCode("+91" + mobile);
          const sendData = {
            mobile: "+91" + mobile,
            name: "",
            isSignUp: true,
            session: undefined,
            userId: "+91" + mobile,
            prevPage: prevPage,
            nextPage: nextPage,
          };
          setLoading(false);
          navigation.navigate("OTPVerification", {
            data: sendData,
          });
        }
      }
    } else {
      if (signInResult.result.name === "UserNotFoundException") {
        let sendData = {
          prevPage: prevPage,
          nextPage: nextPage,
          userId: "",
        };
        setLoading(false);
        navigation.navigate("SignUp", { data: sendData });
      } else {
        if (signInResult.result.name === "UserAlreadyAuthenticatedException") {
          console.log(signInResult.result.name, signInResult.result.message);
          const { username, session } = await getAuthenticatedUser();
          console.log(username, session);
          signOut();
          console.log("Signed Out");
          setLoading(false);
          //to do - add logic for getting user info and sending him to dashboard screen.
        }
      }
    }
    setLoading(false);
  };

  const handleShowOTP = async () => {
    if (mobile === "") {
      setShowError(
        true,
        "Please enter your registered mobile number to log in"
      );
      return;
    } else if (!/^\d{10}$/.test(mobile.trim())) {
      setShowError(true, "Mobile number should consist of 10 digits");
      return;
    }
    await signIn("+91" + mobile);
  };

  return (
    <View style={{ height: "100%" }}>
      {Platform.OS === "web" && screenWidth > 760 ? (
        <Layout>
          <View style={styleByAkash.signInMainContainer}>
            <LinearGradient
              style={styleByAkash.signInLeft}
              colors={["#0D0535", "#1E0C80"]}
              start={{ x: 1, y: 0 }}
              end={{ x: 1, y: 1 }}
            >
              <View style={styleByAkash.signInLeftInner}>
                <Text style={styleByAkash.signInHeading}>Welcome Back</Text>
                <Text style={styleByAkash.signInSmallHeading}>
                  One Platform,
                </Text>
                <Text style={styleByAkash.signInSmallHeading}>
                  Infinite Possibilities.
                </Text>
                <View style={{ width: "100%", alignItems: "flex-end" }}>
                  <Image
                    source={require("../../../../assets/login/LoginImage.png")}
                    style={styleByAkash.logoMainImage}
                  />
                </View>
              </View>
              <Image
                source={require("../../../../assets/login/LoginElement.png")}
                style={styleByAkash.absoluteIcon}
              />
            </LinearGradient>
            <View style={styleByAkash.signInRight}>
              <View style={styleByAkash.SignInContainer}>
                <Text style={styleByAkash.signupStyle}>Sign In</Text>
                <Text style={styleByAkash.createAccountText}>
                  Please enter your Mobile Number.
                </Text>
                {error.showError && (
                  <View style={styleByAkash.errorContainer}>
                    <Ionicons
                      name="information-circle"
                      size={18}
                      color="white"
                    />
                    <Text style={styleByAkash.errorText}>{error.message}</Text>
                  </View>
                )}
                <MobileNumberField
                  label={undefined}
                  placeholder="Enter your mobile number"
                  value={mobile}
                  onChangeText={setMobile}
                  validation={/^\d{9}$/}
                  containerStyle={styles.textFeild}
                  isRequired={false}
                  onSubmitEditing={handleShowOTP}
                />
                <Pressable
                  onPress={handleShowOTP}
                  onHoverIn={handleHoverIn}
                  onHoverOut={handleHoverOut}
                  style={[
                    styleByAkash.button,
                    {
                      backgroundColor:
                        hoverIn === true ? colors.orange : colors.aqua,
                    },
                  ]}
                >
                  <Text
                    style={[
                      styleByAkash.buttonText,
                      {
                        color:
                          hoverIn === true ? colors.white : colors.royalBlue,
                      },
                    ]}
                  >
                    Sign In
                  </Text>
                  {loading && (
                    <ActivityIndicator
                      size="small"
                      color="#0000ff"
                      // style={{ position: "absolute", right: 50 }}
                    />
                  )}
                </Pressable>
                <Text style={styleByAkash.bySignIn}>
                  By signing in you are agreeing to our{" "}
                  <Text style={styleByAkash.termCondition}>
                    Terms and Privacy Policy
                  </Text>
                </Text>

                <View style={styleByAkash.alreadyHaveContainer}>
                  <Text style={[styleByAkash.areYouNewText, { fontSize: 14 }]}>
                    Are you a new user?
                  </Text>
                  <Pressable
                    onPress={() => {
                      let sendData = {
                        prevPage: prevPage,
                        nextPage: nextPage,
                        userId: "",
                      };
                      navigation.navigate("SignUp", { data: sendData });
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "PlusJakartaSansSemiBold",
                        fontSize: 14,
                        color: colors.orange,
                      }}
                    >
                      Sign up
                    </Text>
                  </Pressable>
                </View>
                <Text
                  style={{
                    color: colors.white,
                    textAlign: "center",
                    fontSize: 20,
                    fontFamily: "'PlusJakartaSansMedium'",
                    marginBottom: 10,
                  }}
                >
                  OR
                </Text>
                <View style={styleByAkash.alreadyHaveContainer2}>
                  <Pressable
                    onPress={handleGoogleSignIn}
                    style={[
                      styleByAkash.button2,
                      {
                        backgroundColor:
                          hoverInGoogle === true ? "#ffffff3b" : "transparent",
                      },
                    ]}
                    onHoverIn={handleHoverInGoogle}
                    onHoverOut={handleHoverOutGoogle}
                  >
                    <Image
                      source={require("../../../../assets/icons/google.png")}
                      style={styleByAkash.loginIcon}
                    />
                    <Text style={[styleByAkash.buttonText2]}>
                      Sign In with Google
                    </Text>
                  </Pressable>
                  <Pressable
                    onPress={handleAppleSignIn}
                    style={[
                      styleByAkash.button2,
                      {
                        backgroundColor:
                          hoverInApple === true ? "#ffffff3b" : "transparent",
                      },
                    ]}
                    onHoverIn={handleHoverInApple}
                    onHoverOut={handleHoverOutApple}
                  >
                    <Image
                      source={require("../../../../assets/icons/apple.png")}
                      style={styleByAkash.loginIcon}
                    />
                    <Text style={[styleByAkash.buttonText2]}>
                      Sign In with Apple
                    </Text>
                  </Pressable>
                </View>
              </View>
            </View>
          </View>
        </Layout>
      ) : (
        <View style={styleByAkash.signInMainContainer}>
          <View style={styleByAkash.SignInContainer}>
            <H1 props={"Sign In"} style={styleByAkash.signupStyle} />

            <Text style={styleByAkash.createAccountText}>
              Please enter your Mobile Number.
            </Text>
            {error.showError && (
              <View style={styleByAkash.errorContainer}>
                <Ionicons name="information-circle" size={18} color="white" />
                <Text style={styleByAkash.errorText}>{error.message}</Text>
              </View>
            )}
            <MobileNumberField
              label={undefined}
              placeholder="Enter your mobile number"
              value={mobile}
              onChangeText={setMobile}
              validation={/^\d{9}$/}
              containerStyle={styles.textFeild}
              isRequired={undefined}
              onSubmitEditing={handleShowOTP}
            />
            <Pressable
              onPress={handleShowOTP}
              onHoverIn={handleHoverIn}
              onHoverOut={handleHoverOut}
              style={[
                styleByAkash.button,
                {
                  backgroundColor:
                    hoverIn === true ? colors.orange : colors.aqua,
                },
              ]}
            >
              <Text
                style={[
                  styleByAkash.buttonText,
                  {
                    color: hoverIn === true ? colors.white : colors.royalBlue,
                  },
                ]}
              >
                Sign In
              </Text>
              {loading && (
                <ActivityIndicator
                  size="small"
                  color="#0000ff"
                  // style={{ position: "absolute", right: 50 }}
                />
              )}
            </Pressable>

            <Text style={styleByAkash.bySignIn}>
              By signing in you are agreeing to our{" "}
              <Text style={styleByAkash.termCondition}>
                Terms and Privacy Policy
              </Text>
            </Text>

            <View style={styleByAkash.alreadyHaveContainer}>
              <Text style={[styleByAkash.areYouNewText]}>
                Are you a new user?{" "}
              </Text>
              <Pressable
                onPress={() => {
                  let sendData = {
                    prevPage: prevPage,
                    nextPage: nextPage,
                    userId: "",
                  };
                  navigation.navigate("SignUp", { data: sendData });
                }}
              >
                <Text
                  style={{
                    fontFamily: "PlusJakartaSansSemiBold",
                    fontSize:
                      Platform.OS === "web" && screenWidth > 760 ? 14 : 16,
                    color: colors.orange,
                  }}
                >
                  Sign up
                </Text>
              </Pressable>
            </View>
            <Text
              style={{
                color: colors.white,
                textAlign: "center",
                fontSize: 20,
                fontFamily: "'PlusJakartaSansMedium'",
                marginBottom: 10,
              }}
            >
              OR
            </Text>
            <View style={styleByAkash.alreadyHaveContainer2}>
              <Pressable
                onPress={handleGoogleSignIn}
                style={[
                  styleByAkash.button2,
                  {
                    backgroundColor:
                      hoverInGoogle === true ? "#ffffff3b" : "transparent",
                  },
                ]}
                onHoverIn={handleHoverInGoogle}
                onHoverOut={handleHoverOutGoogle}
              >
                <Image
                  source={require("../../../../assets/icons/google.png")}
                  style={styleByAkash.loginIcon}
                />
                <Text style={[styleByAkash.buttonText2]}>
                  Sign In with Google
                </Text>
              </Pressable>
              <Pressable
                onPress={handleAppleSignIn}
                style={[
                  styleByAkash.button2,
                  {
                    backgroundColor:
                      hoverInApple === true ? "#ffffff3b" : "transparent",
                  },
                ]}
                onHoverIn={handleHoverInApple}
                onHoverOut={handleHoverOutApple}
              >
                <Image
                  source={require("../../../../assets/icons/apple.png")}
                  style={styleByAkash.loginIcon}
                />
                <Text style={[styleByAkash.buttonText2]}>
                  Sign In with Apple
                </Text>
              </Pressable>
            </View>
          </View>
        </View>
      )}
    </View>
  );
};

const styleByAkash = StyleSheet.create({
  signInMainContainer: {
    width: "100%",
    height:
      Platform.OS === "web" && screenWidth > 760
        ? screenHeight * 0.9
        : screenHeight,
    backgroundColor: colors.bgColor,
    flexDirection: "row",
  },
  loginIcon: {
    width: 23,
    height: 23,
    position: "absolute",
    left: 15,
  },
  signInLeft: {
    flex: 1,
    padding: 50,
    position: "relative",
    justifyContent: "center",
  },
  signInLeftInner: {
    backgroundColor: colors.blue,
    borderRadius: 20,
    padding: 30,
    paddingBottom: 0,
  },
  signInHeading: {
    color: colors.white,
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 35,
    marginBottom: 10,
  },
  signInSmallHeading: {
    color: colors.white,
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 20,
    marginTop: 5,
  },
  logoMainImage: {
    width: 235,
    height: 300,
    resizeMode: "contain",
  },
  absoluteIcon: {
    width: 195,
    height: 300,
    resizeMode: "contain",
    position: "absolute",
    left: 0,
    bottom: 0,
  },
  signInRight: {
    flex: 1,
    padding: 50,
    alignItems: "center",
  },
  SignInContainer: {
    width: "100%",
    height: "100%",
    maxWidth: 420,
    justifyContent: "center",
    padding: Platform.OS === "web" && screenWidth > 760 ? undefined : 25,
  },
  signupStyle: {
    textAlign: "center",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 20 : 30,
    fontFamily: "PlusJakartaSansMedium",
    color: colors.orange,
    marginBottom: 20,
  },
  createAccountText: {
    // textAlign: "center",
    fontSize: 16,
    fontFamily: "PlusJakartaSansRegular",
    color: colors.white,
    marginBottom: 10,
  },
  button: {
    backgroundColor: colors.aqua,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 15,
    height: Platform.OS === "web" && screenWidth > 760 ? 38 : 45,
    flexDirection: "row",
    gap: 30,
  },
  button2: {
    backgroundColor: "transparent",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 15,
    height: Platform.OS === "web" && screenWidth > 760 ? 38 : 45,
    flexDirection: "row",
    gap: 30,
    width: "100%",
    borderColor: "#ffffff3b",
    borderWidth: 1,
  },
  buttonText2: {
    color: colors.white,
    fontSize: 18,
    textAlign: "center",
    fontFamily: "PlusJakartaSansRegular",
  },
  buttonText: {
    color: colors.royalBlue,
    fontSize: 18,
    textAlign: "center",
    fontFamily: "PlusJakartaSansSemiBold",
  },
  bySignIn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    gap: 4,
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 11 : 13,
    fontFamily: "PlusJakartaSansRegular",
    color: colors.white,
    marginTop: 25,
    marginBottom: 15,
  },
  termCondition: {
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 10 : 12,
    color: colors.orange,
  },
  alreadyHaveContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 5,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginBottom: 15,
  },
  alreadyHaveContainer2: {
    display: "flex",
    flexDirection: "column",
    gap: 15,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: 10,
  },
  areYouNewText: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: 2,
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 11 : 16,
    fontFamily: "PlusJakartaSansRegular",
    color: colors.white,
  },
  errorContainer: {
    backgroundColor: colors.errorRed,
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    marginBottom: 10,
    gap: 10,
    borderWidth: 1,
    borderRadius: 15,
  },
  errorText: {
    color: "white",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 14 : 12,
    fontFamily: "PlusJakartaSansRegular",
  },
});

const styles = StyleSheet.create({
  textFeild: {
    width: "100%",
    marginBottom: 30,
    color: colors.white,
  },
});

export default SignIn;
