import React from "react";
// import { Provider } from "react-redux";
import { Platform, StatusBar } from "react-native";
import { PaperProvider } from "react-native-paper";
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import MyStore from "./src/redux/MyStore";
import Font from "./src/styles/font";
import { HelmetProvider } from "react-helmet-async";
import { Authentication } from "./src/authentication/Authentication";
import AppNavigator from "./src/navigation/AppNavigator";

import "@azure/core-asynciterator-polyfill";
import MobNavigator from "./src/navigation/MobNavigator";
import { colors } from "./src/components/reusable/colour";
// import { ConsoleLogger } from "@aws-amplify/core";

const App = () => {
  // console.log("showOnboarding value", showOnboarding);
  // ConsoleLogger.LOG_LEVEL = "DEBUG";
  // const logger = new ConsoleLogger();

  return (
    <HelmetProvider>
      <StatusBar backgroundColor={colors.royalBlue} />
      <Authentication>
        {/* <Provider store={MyStore}> */}
        <Font>
          <PaperProvider>
            {/* {Platform.OS !== "web" && <OnboardingScreen />} */}
            {Platform.OS !== "web" && <MobNavigator />}
            {Platform.OS === "web" && <AppNavigator />}
          </PaperProvider>
        </Font>
        {/* </Provider> */}
      </Authentication>
    </HelmetProvider>
  );
};

export default App;
