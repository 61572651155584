import React, { useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  Pressable,
  Platform,
  Dimensions,
} from "react-native";
import { DataStore } from "aws-amplify/datastore";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { colors } from "../../../../components/reusable/colour";
import { useNavigation } from "@react-navigation/native";
import { ScrollView } from "react-native-gesture-handler";
import { getAuthenticatedUser } from "../../../../api/AmplifyUserAPI";
import { UserMaster } from "../../../../models";

const screenWidth = Dimensions.get("window").width;
const HowToUseApp = () => {
  const navigation: any = useNavigation();
  const [hoverIn, setHoverIn] = useState(false);

  const handleHoverIn = () => {
    setHoverIn(true);
  };
  const handleHoverOut = () => {
    setHoverIn(false);
  };

  const handleCreateTournament = async () => {
    let Response = await getAuthenticatedUser();
    if (Response && Object.keys(Response).length > 0) {
      console.log("ResponseFinal", Response);
      if (Response.isLoggedIn == true) {
        console.log("isLoginTrue", Response.isLoggedIn);

        const id = Response.attributes.sub;
        const userdata = await DataStore.query(UserMaster, id);
        // if (userdata.Name !== null) {
        console.log("userdata", userdata);
        // await AsyncStorage.setItem("Username", userdata.Name);
        try {
          await AsyncStorage.setItem("UserDetail", userdata.UUID);
        } catch (error) {
          console.log("Error in catch userdetails : ", error);
        }
        // }
        navigation.navigate("CreateTournament");
      } else {
        let sendData = {
          prevPage: "",
          nextPage: "",
          userId: "",
        };
        navigation.navigate("SignIn", {
          data: sendData,
        });
      }
    } else {
      let sendData = {
        prevPage: "",
        nextPage: "",
        userId: "",
      };
      navigation.navigate("SignIn", {
        data: sendData,
      });
    }
  };
  return (
    <View style={styles.MainSectionBG}>
      <View style={styles.container}>
        <View style={styles.howToUseMainContainer}>
          <View style={styles.howToUseLeft}>
            <Text style={styles.howToUseTextHeading}>
              Upgrade Your Tournaments, Say Goodbye to Manual Hassles!
            </Text>
            <Text style={styles.howToUseLeftText}>
              Experience the future of tournament management with our
              cutting-edge system.
            </Text>
            <Pressable
              style={[
                styles.loginBtn,
                {
                  backgroundColor:
                    hoverIn === true ? colors.orange : colors.aqua,
                  borderColor: hoverIn === true ? colors.orange : colors.aqua,
                },
              ]}
              onPress={() => handleCreateTournament()}
              onHoverIn={handleHoverIn}
              onHoverOut={handleHoverOut}
            >
              <Text
                style={[
                  styles.loginBtnText,
                  {
                    color: hoverIn === true ? colors.white : colors.royalBlue,
                  },
                ]}
              >
                Create Tournament for Free
              </Text>
            </Pressable>
          </View>

          {/* {Platform.OS == "web" ? ( */}
          <View style={styles.howToUseRight}>
            <View style={styles.howToUseRightItem}>
              <Image
                source={require("../../../../../assets/icons/effortlessSetup.svg")}
                style={styles.howToUseIcon}
                resizeMode="contain"
              />
              <Text style={styles.CardHeading}>Effortless Setup</Text>
              <Text style={styles.cardText}>
                With our intuitive interface,you can easily configure tournament
                formats, rules, and scheduling to suit any sport or activity.
              </Text>
            </View>
            <View style={styles.howToUseRightItem}>
              <Image
                source={require("../../../../../assets/icons/registration.svg")}
                style={styles.howToUseIcon}
                resizeMode="contain"
              />
              <Text style={styles.CardHeading}>Online Registration</Text>
              <Text style={styles.cardText}>
                Streamline the registration process with our user-friendly
                online platform, eliminating paperwork and reducing manual
                errors. Participate anywhere with just a click.
              </Text>
            </View>
            <View style={styles.howToUseRightItem}>
              <Image
                source={require("../../../../../assets/icons/effortless.svg")}
                style={styles.howToUseIcon}
                resizeMode="contain"
              />
              <Text style={styles.CardHeading}>Go Real-Time</Text>
              <Text style={styles.cardText}>
                Stay up-to-date with real-time scores, rankings, and match
                schedules, eliminating the need for manual updates.
              </Text>
            </View>
            <View style={styles.howToUseRightItem}>
              <Image
                source={require("../../../../../assets/icons/performance.svg")}
                style={styles.howToUseIcon}
                resizeMode="contain"
              />
              <Text style={styles.CardHeading}>Performance Tracking</Text>
              <Text style={styles.cardText}>
                Effortlessly record and organize match results,goals, Cards
                providing a comprehensive record of past performances.
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  MainSectionBG: {
    width: "100%",
    backgroundColor: colors.bgColor,
  },
  container: {
    maxWidth: Platform.OS === "web" && screenWidth > 760 ? 1320 : undefined,
    width: "100%",
    // height: Platform.OS === "web" && screenWidth > 760 ? "100%" : "27%",
    marginHorizontal:
      Platform.OS === "web" && screenWidth > 760 ? "auto" : undefined,
    paddingRight: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
    paddingLeft: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
  },
  howToUseMainContainer: {
    display: Platform.OS === "web" && screenWidth > 760 ? "flex" : undefined,

    flexDirection:
      Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
    gap: Platform.OS === "web" && screenWidth > 760 ? 50 : 20,
    paddingVertical: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
  },
  howToUseLeft: {
    width: Platform.OS === "web" && screenWidth > 760 ? "45%" : "100%",
    paddingTop: Platform.OS === "web" && screenWidth > 760 ? 60 : 0,
  },
  howToUseRight: {
    flex: Platform.OS === "web" && screenWidth > 760 ? 1 : 0,
    display: Platform.OS === "web" && screenWidth > 760 ? "flex" : undefined,
    flexDirection: "row",
    // flexWrap: Platform.OS === "web" && screenWidth > 760 ? "wrap" : undefined,
    flexWrap: "wrap",
    gap: Platform.OS === "web" && screenWidth > 760 ? 40 : 10,
  },
  howToUseTextHeading: {
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 40 : 30,
    fontFamily: "PlusJakartaSansBold",
    color: "#fff",
  },
  howToUseLeftText: {
    fontSize: 20,
    fontFamily: "PlusJakartaSansRegular",
    marginTop: 20,
    marginBottom: 20,
    color: "#fff",
  },
  howToUseIcon: {
    height: 80,
    width: 80,
  },
  howToUseRightItem: {
    // width: Platform.OS === "web" && screenWidth > 760 ? "46%" : "46%",
    // height: Platform.OS === "web" && screenWidth > 760 ? undefined : 240,
    flex: 1,
    maxWidth: "50%",
    minWidth: "45%",
    backgroundColor: colors.royalBlue,
    elevation: 5,
    shadowColor: "#000",
    shadowOffset: { width: 5, height: 4 },
    shadowOpacity: 0.3,
    shadowRadius: 10,
    borderRadius: 12,
    padding: screenWidth > 760 ? 15 : 5,
  },
  CardHeading: {
    fontSize: 18,
    fontFamily: "PlusJakartaSansSemiBold",
    marginBottom: 10,
    marginTop: 10,
    color: colors.white,
  },
  cardText: {
    fontFamily: "PlusJakartaSansRegular",
    color: colors.white,
  },
  loginBtn: {
    backgroundColor: "#12FFE3",
    borderColor: "#12FFE3",
    borderWidth: 1,
    padding: 5,
    borderRadius: 10,
    fontSize: 14,
    width: 240,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15,
    // right: 50,
    // left: 50,
  },
  loginBtnText: {
    color: colors.royalBlueColour,
    textAlign: "center",
    fontFamily: "PlusJakartaSansSemiBold",
  },
});
export default HowToUseApp;
