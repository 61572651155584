import React, { useState, useEffect } from "react";
import { View, Platform, StyleSheet, Dimensions } from "react-native";
import Footer from "../../screens/view/Home/SubComp/footer";
import Navbar from "./Navbar";
import { colors } from "../reusable/colour";

const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;

const Layout = (props) => {
  return (
    <View style={styles.MainSectionBG}>
      {Platform.OS === "web" ? <Navbar /> : null}

      {props.children}
      {Platform.OS === "web" ? <Footer /> : null}
    </View>
  );
};

const styles = StyleSheet.create({
  MainSectionBG: {
    flex: 1,
    backgroundColor: colors.bgColor,
    paddingTop:
      Platform.OS === "android" || Platform.OS === "ios"
        ? 20
        : Platform.OS === "web" && screenWidth > 760
        ? 60
        : 40,
  },
});
export default Layout;
