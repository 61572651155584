import React, { useEffect } from "react";
import {
  View,
  Platform,
  Text,
  StyleSheet,
  Pressable,
  Image,
  Dimensions,
} from "react-native";
import {
  H2,
  H3,
  H4,
  CommonHeading,
} from "../../../../components/reusable/TextComponent";
import { colors } from "../../../../components/reusable/colour";
import KnowMoreIcon from "react-native-vector-icons/Entypo";
import { useNavigation } from "@react-navigation/native";
const screenWidth = Dimensions.get("window").width;

const NewUserDashboard = () => {
  const navigation: any = useNavigation();
  const handleNavigation = () => {
    navigation.navigate("CreateTournament");
  };
  const handleCreateSquad = () => {
    navigation.navigate("MyTeams");
  };

  return (
    <View
      style={{
        flexDirection:
          Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
        gap: 20,
        width: "100%",
      }}
    >
      <View
        style={{
          flex: Platform.OS === "web" && screenWidth > 760 ? 3 : undefined,
          width:
            Platform.OS === "web" && screenWidth > 760 ? undefined : "100%",
        }}
      >
        <View style={styles.NUDtopCard}>
          <View style={styles.NUDtopContentWrapper}>
            <H2 props={"Welcome to TMT"} style={{ color: colors.orange }} />
            <Text style={styles.NUDtopCradText}>
              Where you can effortlessly monitor and manage your events with our
              user-friendly dashboard!
            </Text>
          </View>
          <Image
            source={{
              uri: require("../../../../../assets/Admin/dashboard/new-user-dashboard.png"),
            }}
            style={styles.dashboardTopIcon}
            resizeMode="contain"
          />
        </View>
        <View style={styles.NUDbottomContainer}>
          <Pressable
            style={styles.NUDsmallCard}
            onPress={() => navigation.navigate("AllTournament")}
          >
            <Image
              source={{
                uri: require("../../../../../assets/Admin/dashboard/new-user-dashboard2.png"),
              }}
              style={styles.dashboardTopIcon2}
              resizeMode="contain"
            />
            <View style={styles.NUDbottomContentWrapper}>
              <Text
                style={[
                  styles.NUDBTextHeading,
                  { backgroundColor: colors.bgColor },
                ]}
              >
                Participate in Tournament
              </Text>
              <Text style={styles.NUDBText}>
                Its good to have a great team! And you have done a lot of
                practice. It's time show your game to the world!
              </Text>
              <View
                style={{ flexDirection: "row", gap: 5, alignItems: "center" }}
              >
                <Text style={styles.NUDBText}>Know More</Text>
                <KnowMoreIcon
                  name="arrow-long-right"
                  size={16}
                  color={colors.orange}
                />
              </View>
            </View>
          </Pressable>
          <Pressable style={styles.NUDsmallCard} onPress={handleCreateSquad}>
            <Image
              source={{
                uri: require("../../../../../assets/Admin/dashboard/new-user-dashboard3.png"),
              }}
              style={styles.dashboardTopIcon2}
              resizeMode="contain"
            />
            <View style={styles.NUDbottomContentWrapper}>
              <Text
                style={[
                  styles.NUDBTextHeading,
                  { backgroundColor: colors.bgColor },
                ]}
              >
                Create your Squad
              </Text>
              <Text style={styles.NUDBText}>
                Have done a lot of practice with your team and waiting for any
                tournament to be organized? Let's get your team onboard in the
                meantime!
              </Text>
              <View
                style={{ flexDirection: "row", gap: 5, alignItems: "center" }}
              >
                <Text style={styles.NUDBText}>Know More</Text>
                <KnowMoreIcon
                  name="arrow-long-right"
                  size={16}
                  color={colors.orange}
                />
              </View>
            </View>
          </Pressable>
        </View>
      </View>
      <View style={styles.NUDrightSideContainer}>
        <View style={styles.NUDorangeCard}>
          <Text style={styles.NUDorangeText}>
            Let's give the opportunity to all the players here to show their
            skills and win some prizes!
          </Text>
          <View style={{ alignItems: "flex-end" }}>
            <Pressable style={styles.createBtn} onPress={handleNavigation}>
              <Text style={styles.createBtnText}>Create Tournament </Text>
            </Pressable>
          </View>
        </View>
        <Text style={styles.TournamentSetupHeading}>
          Simplify Tournament Setup with a Few Clicks!
        </Text>
        <View style={styles.TournamentSetupRight}>
          <View style={[styles.cardContainer, styles.redBorder]}>
            <View style={styles.CardHeadingContainer}>
              <View style={styles.CardHeadingWrapper}>
                <Text style={styles.CardHeadingNumber}>1.</Text>
              </View>
              <Text style={styles.CardHeading}>Set Up Your Tournament</Text>
            </View>
          </View>
          <View style={[styles.cardContainer, styles.skyBlueBorder]}>
            <View style={styles.CardHeadingContainer}>
              <View style={styles.CardHeadingWrapper}>
                <Text style={styles.CardHeadingNumber}>2.</Text>
              </View>
              <Text style={styles.CardHeading}>Customize Your Event</Text>
            </View>
          </View>
          <View style={[styles.cardContainer, styles.purpleBorder]}>
            <View style={styles.CardHeadingContainer}>
              <View style={styles.CardHeadingWrapper}>
                <Text style={styles.CardHeadingNumber}>3.</Text>
              </View>
              <Text style={styles.CardHeading}>
                Invite Participants & Go Live
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  NUDtopCard: {
    backgroundColor: colors.royalBlue,
    width: "100%",
    height: 150,
    flexDirection: "row",
    justifyContent: "space-between",
    // paddingEnd: 30,
    gap: Platform.OS === "web" && screenWidth > 760 ? 50 : 0,
    borderRadius: 10,
    alignItems: "center",
  },
  dashboardTopIcon: {
    height: Platform.OS === "web" && screenWidth > 760 ? 150 : 150,
    width: Platform.OS === "web" && screenWidth > 760 ? 200 : 130,
    resizeMode: "contain",
    marginTop: Platform.OS === "web" && screenWidth > 760 ? -15 : 0,
  },
  dashboardTopIcon2: {
    height: 120,
    width: 120,
    resizeMode: "contain",
  },
  NUDtopContentWrapper: {
    flex: 1,
    padding: 10,
  },
  NUDtopCradText: {
    fontFamily: "PlusJakartaSansRegular",
    marginTop: 10,
    color: colors.white,
  },
  NUDbottomContainer: {
    flexDirection:
      Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
    gap: 10,
    marginTop: 15,
  },
  NUDsmallCard: {
    flex: 1,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.royalBlue,
    borderStyle: "solid",
    shadowOffset: { width: 0, height: 0 },
    shadowColor: "#0000003d",
    shadowOpacity: 0.4,
    shadowRadius: 10,
    padding: 10,
    gap: 5,
    flexDirection: "row",
    backgroundColor: colors.royalBlue,
  },
  NUDbottomContentWrapper: {
    flex: 1,
    gap: 10,
  },
  NUDBTextHeading: {
    fontFamily: "PlusJakartaSansBold",
    fontSize: 12,
    padding: 7,
    borderRadius: 14,
    textAlign: "center",
    color: colors.white,
  },
  NUDBText: {
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 12,
    color: colors.white,
  },
  NUDorangeCard: {
    backgroundColor: colors.orange,
    padding: 10,
    borderRadius: 7,
    width: "100%",
    gap: 20,
  },
  NUDorangeText: {
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 12,
    color: colors.white,
    width: "80%",
  },
  createBtn: {
    backgroundColor: colors.aqua,
    borderColor: colors.aqua,
    borderWidth: 1,
    padding: 5,
    borderRadius: 10,
    fontSize: 14,
    width: 180,
  },
  createBtnText: {
    color: "#000",
    textAlign: "center",
    fontFamily: "PlusJakartaSansSemiBold",
  },
  NUDrightSideContainer: {
    shadowOffset: { width: 0, height: 0 },
    shadowColor: "#0000003d",
    shadowOpacity: 0.4,
    shadowRadius: 10,
    padding: 10,
    borderRadius: 10,
    backgroundColor: colors.royalBlue,
    flex: Platform.OS === "web" && screenWidth > 760 ? 1 : undefined,
    width: Platform.OS === "web" && screenWidth > 760 ? undefined : "100%",
  },
  TournamentSetupHeading: {
    fontSize: 18,
    fontFamily: "PlusJakartaSansBold",
    textAlign: "left",
    color: colors.white,
    marginTop: Platform.OS === "web" && screenWidth > 760 ? 15 : 30,
  },
  TournamentSetupRight: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 10,
    marginTop: 15,
  },
  cardContainer: {
    backgroundColor: colors.bgColor,
    padding: 15,
    width: "100%",
    borderRadius: 10,
    paddingLeft: 18,
    flexDirection: "column",
    justifyContent: "space-between",
    shadowColor: "#000",
    shadowOffset: {
      width: 5,
      height: 7,
    },
    shadowOpacity: 0.61,
    shadowRadius: 10,
    elevation: 10,
    gap: 12,
  },
  redBorder: {
    borderLeftWidth: 10,
    borderLeftColor: colors.orange,
    borderStyle: "solid",
  },
  skyBlueBorder: {
    borderLeftWidth: 10,
    borderLeftColor: colors.aqua,
    borderStyle: "solid",
  },
  purpleBorder: {
    borderLeftWidth: 10,
    borderLeftColor: "#574FB1",
    borderStyle: "solid",
  },
  CardHeadingContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
    alignItems: "center",
  },
  CardHeadingWrapper: {
    backgroundColor: "#CEDAFA",
    width: 30,
    height: 30,
    borderRadius: 15,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  CardHeadingNumber: {
    fontFamily: "PlusJakartaSansSemiBold",
  },
  CardHeading: {
    fontSize: 14,
    fontFamily: "PlusJakartaSansBold",
    color: colors.white,
  },
});
export default NewUserDashboard;
