import React from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  Dimensions,
} from "react-native";
import DotIcon from "react-native-vector-icons/Entypo";
import { colors } from "../../../../components/reusable/colour";
const screenWidth = Dimensions.get("window").width;

const RealTimeScoring = () => {
  return (
    <View style={{ paddingVertical: 50 }}>
      <View style={styles.RTSSection}>
        <View style={styles.container}>
          <View style={styles.RTSContainer}>
            <View style={styles.RTSLeft}>
              <Image
                source={require("../../../../../assets/features/leftSideOne.png")}
                style={styles.RTSLeftImage}
              />
            </View>
            <View style={styles.RTSRight}>
              <Text style={styles.FeaturesHeading}>
                Real-Time Scoring Updates Goal-Scoring Powerhouse
              </Text>
              <View style={styles.RTSTextWrapper}>
                <DotIcon name="dot-single" style={styles.dotIcon} />
                <Text style={styles.RTSText}>
                  Stay updated with real-time scoring updates during matches.
                </Text>
              </View>
              <View style={styles.RTSTextWrapper}>
                <DotIcon name="dot-single" style={styles.dotIcon} />
                <Text style={styles.RTSText}>
                  Monitor scores, rankings, and match schedules instantly.
                </Text>
              </View>
              <View style={styles.RTSTextWrapper}>
                <DotIcon name="dot-single" style={styles.dotIcon} />
                <Text style={styles.RTSText}>
                  Record and track goals with precision and accuracy.
                </Text>
              </View>
              <View style={styles.RTSTextWrapper}>
                <DotIcon name="dot-single" style={styles.dotIcon} />
                <Text style={styles.RTSText}>
                  Get insights into goal-scoring trends and player performances.
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  RTSSection: {
    width: "100%",
  },
  container: {
    maxWidth: 1320,
    width: "100%",
    height: "100%",
    marginHorizontal: "auto",
    paddingRight: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
    paddingLeft: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
  },
  RTSContainer: {
    width: "100%",
    flexDirection:
      Platform.OS === "web" && screenWidth > 760 ? "row" : "column",
    gap: Platform.OS === "web" && screenWidth > 760 ? 30 : 15,
  },
  RTSLeft: {
    width: Platform.OS === "web" && screenWidth > 760 ? "45%" : "100%",
  },
  RTSRight: {
    flex: 1,
  },
  FeaturesHeading: {
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 30 : 20,
    fontFamily:
      Platform.OS === "web" && screenWidth > 760
        ? "PlusJakartaSansSemiBold"
        : "PlusJakartaSansSemiBold",
    color: colors.white,
    marginBottom: 20,
  },
  RTSLeftImage: {
    width: "100%",
    resizeMode: "contain",
    aspectRatio: 3 / 2.2,
  },

  dotIcon: {
    fontSize: 30,
    color: colors.white,
  },
  RTSTextWrapper: {
    flexDirection: "row",
    gap: 5,
    marginBottom: 10,
  },
  RTSText: {
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 16,
    color: colors.white,
  },
});

export default RealTimeScoring;
