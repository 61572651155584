import {
  View,
  Text,
  StyleSheet,
  Pressable,
  Image,
  ScrollView,
  Dimensions,
  Platform,
} from "react-native";
import React, { useEffect, useState } from "react";
import Navbar from "../../../components/include/Navbar";
import { H2, H3, H4 } from "../../../components/reusable/TextComponent";
import { colors } from "../../../components/reusable/colour";
import Standing from "./Standing";
import Fixtures from "./Fixtures";
import Statistics from "./Statistics";
import { Announcement, Tournament } from "../../../models";
import Results from "../../adminView/Results/Results";
import Gallery from "./Gallery";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  useNavigation,
  useNavigationState,
  useRoute,
} from "@react-navigation/native";
import { DataStore, SortDirection } from "aws-amplify/datastore";
import UpcomingTournamentDetails from "./UpcomingTournamentDetails";
import Teams from "./Teams";
import BellIcon from "react-native-vector-icons/MaterialCommunityIcons";
import CollapseIcon from "react-native-vector-icons/Feather";
import CalanderIcon from "react-native-vector-icons/Feather";
import ResultDetails from "../../adminView/Results/ResultDetails";
import Layout from "../../../components/include/Layout";
import AppBar from "../../../components/include/AppBar";
import { formatDateShort } from "../../../components/FormattedDate";
import PAIcon from "react-native-vector-icons/AntDesign";
import CrossIcon from "react-native-vector-icons/Entypo";
import ZoomableImage from "../../../components/reusable/ZoomableImage";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

const TournamentDetails = ({
  // tournamentId,
  matchId,
  initialTab,
  showResultPage,
  matchNavigation,
  showResultDetailsPage,
  tournamentCardNavigation,
  showResultDetailsFromMatches,
}) => {
  const [activeTab, setActiveTab] = useState(5);
  const [matchData, setMatchData] = useState([]);
  const [goalsData, setGoalsData] = useState([]);
  const [cardsData, setCardsData] = useState([]);
  const [upcomingTournament, setUpcomingTournament] = useState(false);
  const [yellowCardCounts, setYellowCardCounts] = useState([]);
  const [redCardCounts, setRedCardCounts] = useState([]);
  const [teamsData, setTeamsData] = useState([]);
  const [teamsPlayersData, setTeamsPlayersData] = useState([]);
  const [tournamentsData, setTournamentsData] = useState([]);
  const [tournamentDetailsProps, setTournamentDetailsProps]: any = useState({});
  const [matchesDetailsProps, setMatchesDetailsProps]: any = useState({});
  // const [allRoundsData, setAllRoundsData]: any = useState([]);
  // const [dropDownOptions, setDropDownOptions]: any = useState([]);
  // const [allRoundNames, setAllRoundNames] = useState([]);
  // const [selectedRoundName] = useState("");
  // const [preselectedValue, setPreselectedValue]: any = useState("");
  // const [roundTypeData, setRoundTypeData] = useState([]);
  const [searchString1, setSearchString1] = useState("");
  const [searchString2, setSearchString2] = useState("");
  const [notificationsData, setNotificationsData] = useState([]);
  const [notifiCollapsed, setNotifiCollapsed] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [mobNotification, setMobNotification] = useState(true);
  // const [mobilePath, setMobilePath] = useState(false);
  const [myMatchesPropsFromAdmin, setMyMatchesPropsFromAdmin]: any = useState(
    {}
  );
  const route = useRoute();
  console.log("ROuter parms", route.params);
  const { tournamentId }: any = route.params || {};
  console.log("tournamentId tournament details", tournamentId);

  const bannerImage: any = require("../../../../assets/Banner/banner1.png");
  const testLogo: any = "../../../../assets/logo/testLogo.png";

  const navigationState = useNavigationState((state) => state);
  const currentRoute = navigationState.routes[navigationState.index];
  const currentScreen = currentRoute.name;
  const currentPath = currentRoute.path;
  console.log("currentScreen", currentScreen);
  console.log("currentPath", currentPath);

  const handleCollapse = () => {
    setNotifiCollapsed(!notifiCollapsed);
  };

  const handleMobNotification = () => {
    setMobNotification(!mobNotification);
  };

  const getTournamentDetailsData = async (tournamentId: any) => {
    // tournamentProps

    // ObserveQuery
    const subscription = DataStore.observeQuery(Tournament, (c) =>
      c.id.eq(tournamentId)
    ).subscribe(async (snapshot) => {
      const initialTournaments: any = snapshot.items;
      console.log("selectedTournament", initialTournaments);
      if (initialTournaments[0].Status === "SCHEDULED") {
        setUpcomingTournament(true);
        console.log("UpcomingTournament should be rendered");
      } else {
        setUpcomingTournament(false);
        const tournamentDetailsProps = await AsyncStorage.getItem(
          "tournamentProps"
        );
        const tournamentDetails = JSON.parse(tournamentDetailsProps);
        console.log("tournamentDetailsss", tournamentDetails);
        setTournamentDetailsProps(tournamentDetails);

        // async storage
        const upcomingTournamentDetailsProps = await AsyncStorage.getItem(
          "upcomingTournamentProps"
        );
        const upcomingTournamentDetails = JSON.parse(
          upcomingTournamentDetailsProps
        );
        console.log("upcomingTournamentDetails", upcomingTournamentDetails);

        // MyMatches async storage from admin dashboard
        const myMatchesProps = await AsyncStorage.getItem(
          "matchesResultDetailsAdmin"
        );
        const myMatchesFromAdmin = JSON.parse(myMatchesProps);
        console.log("myMatchesFromAdmin", myMatchesFromAdmin);
        setMyMatchesPropsFromAdmin(myMatchesFromAdmin);

        // AllMatches async storage

        const allMatchesProps = await AsyncStorage.getItem(
          "matchesResultDetails"
        );
        const getAllMatchesProps = JSON.parse(allMatchesProps);
        console.log("getAllMatchesProps", getAllMatchesProps);
        setMatchesDetailsProps(getAllMatchesProps);
        setTournamentsData(initialTournaments);
        let teamsDataPromises = [initialTournaments[0].Registrations];
        let teamsData = await teamsDataPromises[0].values;
        console.log("teamsData", teamsData);
        setTeamsData(teamsData);

        // playerData
        const playersData = [];
        for (const team of teamsData) {
          const playersPromise = team.Players.values;
          const players = await playersPromise;
          playersData.push(players);
        }
        console.log("playersData", playersData);
        const playersCount = playersData.flat();
        console.log("playersCount", playersCount);
        setTeamsPlayersData(playersCount);

        // matchesData
        const matchesData = [];
        for (const match of initialTournaments) {
          const matchesPromise = match.Matches.values;
          const matches = await matchesPromise;
          matchesData.push(matches);
        }
        console.log("matchesData", matchesData);
        const matchesCount = matchesData.flat();
        console.log("matchesCount", matchesCount);
        setMatchData(matchesCount);

        // goalsData
        const goalsData = [];
        for (const goals of initialTournaments) {
          const goalsPromise = goals.Goals.values;
          const goalsDataFromPromsie = await goalsPromise;
          goalsData.push(goalsDataFromPromsie);
        }
        console.log("goalsData", goalsData);
        const goalsCount = goalsData.flat();
        console.log("goalsCount", goalsCount);
        setGoalsData(goalsCount);

        // cards data
        const cardsData = [];
        for (const cards of initialTournaments) {
          const cardsPromise = cards.Cards.values;
          const cardsDataFromPromsie = await cardsPromise;
          cardsData.push(cardsDataFromPromsie);
        }
        console.log("cardsData", cardsData);
        const cardsCount = cardsData.flat();
        console.log("cardsCount", cardsCount);
        setCardsData(cardsCount);

        const cardTypeCounts = {
          Yellow: [],
          Red: [],
        };

        cardsData.forEach((innerArray, outerIndex) => {
          let yellowCount = 0;
          let redCount = 0;

          innerArray.forEach((card) => {
            // Check if CardType is "Yellow"
            if (card.CardType === "Yellow") {
              yellowCount += 1;
            }
            // Check if CardType is "Red"
            if (card.CardType === "Red") {
              redCount += 1;
            }
          });
          cardTypeCounts.Yellow[outerIndex] = yellowCount;
          cardTypeCounts.Red[outerIndex] = redCount;
        });
        console.log("Card Type Counts:", cardTypeCounts);
        setYellowCardCounts(cardTypeCounts.Yellow);
        setRedCardCounts(cardTypeCounts.Red);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  };

  const getNotificationDetails = async (tournamentId) => {
    // let tournamentId = await AsyncStorage.getItem("CurrentTournament");
    // console.log("tournamentId", tournamentId);
    const notifications = DataStore.observeQuery(
      Announcement,
      (c) => c.tournamentID.eq(tournamentId),
      { sort: (s) => s.TimeStamp(SortDirection.DESCENDING) }
    ).subscribe(async (snapshot) => {
      console.log("Notifications", snapshot.items);
      setNotificationsData(snapshot.items);
    });
    return () => {
      notifications.unsubscribe();
    };
  };

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        setIsLoading(true);
        console.log("Redirect", tournamentId);
        if (tournamentId) {
          await getNotificationDetails(tournamentId);
          await getTournamentDetailsData(tournamentId);
        } else {
          console.log("tournament id not found");
        }

        if (Platform.OS === "web") {
          let routePath = window.location.href;
          console.log("routePath", routePath);
          let searchString1 = "initialTab=5";
          setSearchString1(searchString1);
          let searchString2 = "initialTab=2";
          setSearchString2(searchString2);
          if (routePath.includes(searchString1)) {
            console.log(`"${searchString1}" found in routePath`);
            handleTabChange(5);
          } else if (routePath.includes(searchString2)) {
            console.log(`"${searchString2}" found in routePath`);
            handleTabChange(2);
          }
        }

        console.log("matchesTab", matchesDetailsProps.initialTab);
        setIsLoading(false);
      } catch (error) {
        console.error(
          "Error retrieving preselectedRoundValue from storage",
          error
        );
      }
    };
    fetchAllData();
    // Call the fetchPreselectedValue function
  }, [
    tournamentId,
    matchId,
    initialTab,
    showResultPage,
    showResultDetailsPage,
    matchNavigation,
    tournamentCardNavigation,
    showResultDetailsFromMatches,
    searchString1,
    searchString2,
    currentScreen,
  ]);

  const handleTabChange = (tabIndex: number) => {
    setActiveTab(tabIndex);
    console.log("tabIndex", tabIndex);
  };
  const navigation = useNavigation();
  const handleBack = () => {
    navigation.goBack();
  };

  return (
    <>
      {Platform.OS !== "web" && (
        <AppBar
          title={"Tournament Details"}
          showBackButton={true}
          onBackPress={handleBack}
        />
      )}
      <Layout>
        <ScrollView>
          {upcomingTournament === true ? (
            <UpcomingTournamentDetails />
          ) : tournamentsData && tournamentsData.length > 0 ? (
            tournamentsData.map((data: any, index: number) => (
              <View style={styles.container} key={index}>
                <View style={styles.DashboardContentContainer}>
                  <View style={styles.boxContainer}>
                    <Image
                      source={{
                        uri: data.Banner ? data.Banner : bannerImage,
                      }}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: 10,
                      }}
                    />
                    {/* <ZoomableImage
                      imageSource={{
                        uri: data.Banner ? data.Banner : bannerImage,
                      }}
                      imageStyle={{
                        width: "100%",
                        height: "100%",
                        borderRadius: 10,
                      }}
                      modalStyle={undefined}
                    /> */}
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      width: "100%",
                      paddingHorizontal:
                        Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
                      gap: Platform.OS === "web" && screenWidth > 760 ? 40 : 10,
                      paddingTop: 10,
                    }}
                  >
                    <View style={styles.circleContainer}>
                      {/* <Image
                        source={{
                          uri: data.Logo ? data.Logo : testLogo,
                        }}
                        style={styles.circle}
                      /> */}
                      <ZoomableImage
                        imageSource={{ uri: data.Logo ? data.Logo : testLogo }}
                        imageStyle={styles.circle}
                        modalStyle={undefined}
                      />
                    </View>
                    <View
                      style={{
                        flexDirection: "column",
                      }}
                    >
                      <H2
                        props={data ? data.Name : "NA"}
                        style={{ color: colors.white }}
                      />
                      <H3
                        props={data ? data.Organizer : "NA"}
                        style={{ color: colors.white }}
                      />
                      <View style={styles.UTDDateWrapper}>
                        <CalanderIcon
                          name="calendar"
                          size={
                            Platform.OS === "web" && screenWidth > 760 ? 20 : 16
                          }
                          color={colors.white}
                        />
                        <Text style={styles.UTDDateText}>
                          {/* {data ? data.StartDate : null} &nbsp; - &nbsp; */}
                          {`${data ? formatDateShort(data.StartDate) : null} -`}
                          {/* <CalanderIcon
                          name="calendar"
                          size={
                            Platform.OS === "web" && screenWidth > 760 ? 20 : 16
                          }
                          color={colors.white}
                        /> */}
                          {/* {data ? data.EndDate : null} */}
                          {`${data ? formatDateShort(data.EndDate) : null}`}
                        </Text>
                      </View>
                    </View>
                  </View>

                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: 10,
                      paddingVertical: 10,
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <View style={styles.card}>
                      <H2
                        props={teamsData.length}
                        style={{ color: colors.white }}
                      />
                      <H4 props={"Teams"} style={{ color: colors.white }} />
                    </View>
                    <View style={styles.card}>
                      <H2
                        props={teamsPlayersData.length}
                        style={{ color: colors.white }}
                      />
                      <H4 props={"Players"} style={{ color: colors.white }} />
                    </View>
                    <View style={styles.card}>
                      <H2
                        props={matchData.length}
                        style={{ color: colors.white }}
                      />
                      <H4 props={"Matches"} style={{ color: colors.white }} />
                    </View>
                    <View style={styles.card}>
                      <H2
                        props={goalsData.length}
                        style={{ color: colors.white }}
                      />
                      <H4 props={"Goals"} style={{ color: colors.white }} />
                    </View>
                    <View style={styles.card}>
                      <H2
                        props={redCardCounts}
                        style={{ color: colors.white }}
                      />
                      <H4 props={"Red Cards"} style={{ color: colors.white }} />
                    </View>
                    <View style={styles.card}>
                      <H2
                        props={yellowCardCounts}
                        style={{ color: colors.white }}
                      />
                      <H4
                        props={"Yellow Cards"}
                        style={{ color: colors.white }}
                      />
                    </View>
                  </View>

                  {/* to be modofied */}
                  <View
                    style={{
                      flexDirection: "row",
                      gap: 20,
                      width: "100%",
                      // padding: 10,
                      alignItems: "flex-start",
                    }}
                  >
                    <View style={styles.TabMainContainer}>
                      <ScrollView
                        horizontal={true}
                        showsVerticalScrollIndicator={false}
                        showsHorizontalScrollIndicator={true}
                        style={{
                          width: "100%",
                          marginBottom: 20,
                          backgroundColor: colors.blue,
                          borderRadius: 10,
                        }}
                      >
                        <View
                          style={[
                            styles.tabContainer,
                            { paddingRight: notifiCollapsed ? 0 : 30 },
                          ]}
                        >
                          <Pressable
                            style={[
                              styles.tabButton,
                              activeTab === 5 && styles.activeTabButton,
                            ]}
                            onPress={() => handleTabChange(5)}
                          >
                            <Text
                              style={[
                                styles.tabButtonText,
                                activeTab === 5 && styles.activeTabButtonText,
                              ]}
                            >
                              Teams
                            </Text>
                          </Pressable>

                          <Pressable
                            style={[
                              styles.tabButton,
                              activeTab === 0 && styles.activeTabButton,
                            ]}
                            onPress={() => handleTabChange(0)}
                          >
                            <Text
                              style={[
                                styles.tabButtonText,
                                activeTab === 0 && styles.activeTabButtonText,
                              ]}
                            >
                              Standings
                            </Text>
                          </Pressable>

                          <Pressable
                            style={[
                              styles.tabButton,
                              activeTab === 1 && styles.activeTabButton,
                            ]}
                            onPress={() => handleTabChange(1)}
                          >
                            <Text
                              style={[
                                styles.tabButtonText,
                                activeTab === 1 && styles.activeTabButtonText,
                              ]}
                            >
                              Fixtures
                            </Text>
                          </Pressable>

                          <Pressable
                            style={[
                              styles.tabButton,
                              activeTab === 2 && styles.activeTabButton,
                            ]}
                            onPress={() => handleTabChange(2)}
                          >
                            <Text
                              style={[
                                styles.tabButtonText,
                                activeTab === 2 && styles.activeTabButtonText,
                              ]}
                            >
                              Results
                            </Text>
                          </Pressable>

                          <Pressable
                            style={[
                              styles.tabButton,
                              activeTab === 3 && styles.activeTabButton,
                            ]}
                            onPress={() => handleTabChange(3)}
                          >
                            <Text
                              style={[
                                styles.tabButtonText,
                                activeTab === 3 && styles.activeTabButtonText,
                              ]}
                            >
                              Statistics
                            </Text>
                          </Pressable>

                          <Pressable
                            style={[
                              styles.tabButton,
                              activeTab === 4 && styles.activeTabButton,
                            ]}
                            onPress={() => handleTabChange(4)}
                          >
                            <Text
                              style={[
                                styles.tabButtonText,
                                activeTab === 4 && styles.activeTabButtonText,
                              ]}
                            >
                              Gallery
                            </Text>
                          </Pressable>
                        </View>
                      </ScrollView>
                      <View>
                        {activeTab === 0 && (
                          <Standing
                            tournamentId={tournamentId}
                            // tournamentIdFromManageTournament={""}
                          />
                        )}
                        {activeTab === 1 && (
                          <Fixtures tournamentId={tournamentId} />
                        )}

                        {/* show result page from tournament's navigation */}
                        {activeTab === 2 && (
                          // (searchString1 || currentScreen) &&
                          // tournamentDetailsProps &&
                          // tournamentDetailsProps.showResultPage === true &&
                          // tournamentDetailsProps.showResultDetailsPage === false ?

                          <Results
                            showDetailsForResult={
                              tournamentDetailsProps?.showResultPage
                            }
                            matchID={tournamentDetailsProps?.matchId}
                            tournamentId={tournamentId}
                            onDataReceived={false}
                            showResultFlag={true}
                            // tournamentIdFromManageTournament={""}
                          />
                        )}
                        {/* the below commented code is required */}
                        {/* show result details page from matches navigation */}
                        {/* {activeTab === 2 &&
                      searchString2 &&
                      tournamentId &&
                      matchId &&
                      initialTab &&
                      showResultDetailsFromMatches &&
                      showResultPage &&
                      matchNavigation
                       ? (
                        <ResultDetails
                          matchId={
                            matchesDetailsProps
                              ? matchesDetailsProps.matchId
                              : ""
                          }
                          onDataReceived={false}
                          showResultDetailsFromResult={true}
                          showResultFlag={false}
                        />
                      ) : null} */}

                        {activeTab === 3 && (
                          <Statistics tournamentId={tournamentId} />
                        )}
                        {activeTab === 4 && (
                          <Gallery tournamentId={tournamentId} />
                        )}

                        {activeTab === 5 && (
                          <Teams tournamentId={tournamentId} />
                        )}
                      </View>
                    </View>

                    {/* Announcement  */}
                    {notificationsData &&
                      notificationsData.length > 0 &&
                      Platform.OS === "web" &&
                      screenWidth > 760 && (
                        <View
                          style={
                            notifiCollapsed
                              ? styles.notificationContent
                              : styles.collapsedNotificationContent
                          }
                        >
                          <View
                            style={[
                              styles.announcementCollapseContainer,
                              { width: notifiCollapsed ? "100%" : 35 },
                            ]}
                          >
                            <Text
                              style={{
                                fontFamily: "PlusJakartaSansSemiBold",
                                fontSize: 16,
                                display: notifiCollapsed ? "flex" : "none",
                                color: colors.white,
                              }}
                            >
                              Announcements
                            </Text>
                            <Pressable
                              style={styles.collapseBtnContainer}
                              onPress={() => handleCollapse()}
                            >
                              {notifiCollapsed ? (
                                <CollapseIcon
                                  name="chevrons-right"
                                  size={32}
                                  color={colors.orange}
                                />
                              ) : (
                                <BellIcon
                                  name="bell-ring"
                                  size={24}
                                  color={colors.orange}
                                />
                              )}
                            </Pressable>
                          </View>
                          <View
                            style={[
                              styles.notificationBoxWrapper,
                              { display: notifiCollapsed ? "flex" : "none" },
                            ]}
                          >
                            {notificationsData.map((e) => {
                              return (
                                <View style={styles.notificationCard}>
                                  <Text style={styles.notificationDateText}>
                                    {e.TimeStamp.split("T")[0]}
                                  </Text>
                                  <Text
                                    style={{
                                      fontFamily: "PlusJakartaSansRegular",
                                      fontSize: 12,
                                      color: colors.white,
                                      padding: 5,
                                      textAlign: "left",
                                    }}
                                  >
                                    {e.Description}
                                  </Text>
                                </View>
                              );
                            })}
                          </View>
                        </View>
                      )}
                  </View>
                </View>
              </View>
            ))
          ) : (
            <Text>No data found</Text>
          )}
        </ScrollView>
        {screenWidth < 761 && (
          <View
            style={{
              alignItems: "center",
              height: mobNotification ? undefined : screenHeight * 0.9,
              backgroundColor: mobNotification ? "transparent" : "#fff8f72b",
              position: "absolute",
              left: 0,
              bottom: 0,
              width: "100%",
            }}
          >
            {!mobNotification ? (
              <View
                style={{
                  backgroundColor: colors.newBlue,
                  padding: 10,
                  width: "95%",
                  borderRadius: 10,
                  gap: 5,
                  position: "absolute",
                  bottom: 70,
                  left: 5,
                }}
              >
                {" "}
                {notificationsData.length === 0 ? (
                  <Text style={{ color: colors.white }}>
                    Announcement not available
                  </Text>
                ) : (
                  notificationsData.map((e) => {
                    return (
                      <View style={styles.notificationCard}>
                        <Text style={styles.notificationDateText}>
                          {e.TimeStamp.split("T")[0]}
                        </Text>
                        <Text
                          style={{
                            fontFamily: "PlusJakartaSansRegular",
                            fontSize: 12,
                            color: colors.white,
                            padding: 5,
                            textAlign: "left",
                          }}
                        >
                          {e.Description}
                        </Text>
                      </View>
                    );
                  })
                )}
              </View>
            ) : null}
            <Pressable
              style={styles.paButton}
              onPress={() => handleMobNotification()}
            >
              {mobNotification ? (
                <PAIcon name="notification" size={27} color={colors.white} />
              ) : (
                <CrossIcon name="cross" size={40} color={colors.white} />
              )}
            </Pressable>
          </View>
        )}
      </Layout>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    maxWidth: 1320,
    width: "100%",
    height: "100%",
    marginHorizontal: "auto",
    paddingRight: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
    paddingLeft: Platform.OS === "web" && screenWidth > 760 ? 50 : 10,
  },
  DashboardContentContainer: {
    flex: 1,
    width: "100%",
    paddingVertical: 20,
  },
  circleContainer: {
    width: Platform.OS === "web" && screenWidth > 760 ? 150 : 80,
    height: Platform.OS === "web" && screenWidth > 760 ? 150 : 80,
    borderRadius: 100,
    borderWidth: 3,
    borderColor: colors.blue,
    marginTop: Platform.OS === "web" && screenWidth > 760 ? -55 : -25,
    display: "flex",
    flexDirection: "row",
  },
  circle: {
    width: Platform.OS === "web" && screenWidth > 760 ? 150 : 80,
    height: Platform.OS === "web" && screenWidth > 760 ? 150 : 80,
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    resizeMode: "contain",
    backgroundColor: colors.grayColour,
  },
  tournaHeaderButtonWrapper: {
    display: "flex",
    flexDirection: "row",
    borderColor: "#EDEDED",
    borderWidth: 1,
    borderRadius: 4,
    gap: 4,
    paddingHorizontal: 5,
    paddingVertical: 2,
    alignItems: "center",
  },
  TabMainContainer: {
    marginTop: 10,
    flex: 1,
  },
  tabContainer: {
    width: "100%",
    flexDirection: "row",
    backgroundColor: colors.blue,
    overflow: Platform.OS === "web" && screenWidth > 760 ? "hidden" : "scroll",
    justifyContent: "space-between",
    borderRadius: 10,
    padding: 7,
    gap: 10,
  },
  tabButton: {
    padding: Platform.OS === "web" && screenWidth > 760 ? 10 : undefined,
    backgroundColor: "transparent",
    height: Platform.OS === "web" && screenWidth > 760 ? 35 : 26,
    flex: screenWidth > 760 ? 1 : undefined,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    paddingHorizontal: Platform.OS === "web" && screenWidth > 760 ? 15 : 7,
    width: screenWidth > 760 ? undefined : 80,
  },
  activeTabButtonText: {
    // borderBottomColor: colors.orangeColour,
    // borderBottomWidth: 4,
  },
  activeTabButton: {
    backgroundColor: colors.orange,
  },
  tabButtonText: {
    color: colors.white,
    textAlign: "center",
    fontFamily: "PlusJakartaSansRegular",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 16 : 14,
  },
  card: {
    height: Platform.OS === "web" && screenWidth > 760 ? 100 : 50,
    flex: 1,
    maxWidth: Platform.OS === "web" && screenWidth > 760 ? undefined : "33%",
    minWidth: Platform.OS === "web" && screenWidth > 760 ? undefined : "30%",
    backgroundColor: colors.royalBlue,
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    alignContent: "center",
  },
  boxContainer: {
    width: "100%",
    height: Platform.OS === "web" && screenWidth > 760 ? 400 : 180,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "transparent",
    justifyContent: "center",
    alignItems: "center",
    padding: 5,
    backgroundColor: colors.royalBlue,
  },
  notificationContent: {
    // height: "100%",
    backgroundColor: colors.royalBlue,
    flex: 1,
    maxWidth: 300,
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: 20,
    borderColor: colors.royalBlue,
    flexDirection: "column",
    padding: 10,
    gap: 10,
  },
  collapsedNotificationContent: {
    backgroundColor: colors.royalBlue,
    width: 45,
    height: 45,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 40,
    borderColor: colors.royalBlue,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    position: "absolute",
    right: -22,
    top: 10,
  },
  notificationCard: {
    width: "100%",
    backgroundColor: colors.bgColor,
    padding: 10,
    borderRadius: 5,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 5,
    flexDirection: "column",
  },
  dropdownContainer: {
    borderColor: "#b3c5e9",
    borderWidth: 0.5,
    borderRadius: 8,
    paddingHorizontal: 5,
    width: 220,
    height: Platform.OS === "android" || Platform.OS === "ios" ? 50 : null,
    marginBottom: 10,
    marginTop: 10,
  },
  notificationDateText: {
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 14,
    color: colors.aqua,
    textAlign: "left",
  },
  //
  announcementCollapseContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  collapseBtnContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 15,
    borderColor: colors.orangeColour,
    borderWidth: 1,
    borderRadius: 50,
    width: 35,
    height: 35,
    justifyContent: "center",
    alignSelf: "center",
  },
  notificationBoxWrapper: {
    gap: 10,
    width: "100%",
  },
  UTDDateWrapper: {
    flexDirection: "row",
    gap: 5,
    alignItems: "center",
    marginTop: 7,
  },
  UTDDateText: {
    fontFamily: "PlusJakartaSansMedium",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 14 : 10,
    color: colors.white,
  },
  //
  paButton: {
    width: 45,
    height: 45,
    backgroundColor: colors.orange,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    position: "absolute",
    bottom: 20,
    right: 20,
  },
});
export default TournamentDetails;
