import { View, StyleSheet, ScrollView } from "react-native";
import { colors } from "../../reusable/colour";

import { Text, Pressable } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import ProfileIcon from "react-native-vector-icons/FontAwesome5";
import TrophyIcon from "react-native-vector-icons/FontAwesome5";
import FlagIcon from "react-native-vector-icons/MaterialIcons";
import DollorIcon from "react-native-vector-icons/FontAwesome5";
import HelpIcon from "react-native-vector-icons/MaterialIcons";
import LogOutIcon from "react-native-vector-icons/MaterialIcons";

import React, { useEffect, useState, useContext } from "react";
import { signOut } from "aws-amplify/auth";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { LinearGradient } from "expo-linear-gradient";
import { AreYouSureOrange } from "../../reusable/AreYouSure";
import { DataStore } from "@aws-amplify/datastore";
import { UserMaster } from "../../../models";
import { AuthenticationContext } from "../../../authentication/Authentication";

const MobMenu = () => {
  const navigation: any = useNavigation();
  const [selectedImage, setSelectedImage]: any = useState({});
  const [SuccessModalVisible, setSuccessModalVisible] = useState(false);
  const [SunccessModalMesg, setSunccessModalMesg] = useState("");
  const [UserDteaildata, setUserDteaildata] = useState(null);
  const [TotalTeams, setTotalTeams] = useState("");
  const [TotalParticipateTournament, seTotalParticipateTournament] =
    useState("");
  const [TotalRegistrationTeam, seTotalRegistrationTeam] = useState("");
  const { setIsLoggedIn } = useContext(AuthenticationContext);
  let userDetails: any;

  const getUserdata = async (UserDetails) => {
    console.log("UserDetails", UserDetails);
    const UserObject = await DataStore.query(UserMaster, UserDetails);

    console.log("UserObject", UserObject);

    const wordsArray = UserObject.Name.split(/\s+/);
    console.log("wordsArray", wordsArray);

    setSelectedImage({
      URL: UserObject.PhotoURL,
    });
    setUserDteaildata(UserObject);

    let tournamentdata: any = await [UserObject.UserTournaments];
    let TournamentData = await tournamentdata[0].values;

    let TeamReg: any = await [UserObject.TeamRegistrations];
    let TeamRegPromise = await TeamReg[0].values;

    let Teamsdata: any = await [UserObject.UserTeams];
    let Teamspromisedata = await Teamsdata[0].values;

    seTotalRegistrationTeam(TeamRegPromise.length);
    seTotalParticipateTournament(TournamentData.length);
    setTotalTeams(Teamspromisedata.length);
  };

  useEffect(() => {
    const getUserDetails = async () => {
      userDetails = await AsyncStorage.getItem("UserDetail");
      console.log("userDetails", userDetails);

      console.log("userDetails", userDetails);
      await getUserdata(userDetails);
    };

    const updateStateValues = (userObject) => {
      if (userObject) {
        seTotalRegistrationTeam(userObject.TeamRegistrations?.length || 0);
        seTotalParticipateTournament(userObject.UserTournaments?.length || 0);
        setTotalTeams(userObject.UserTeams?.length || 0);
      }
    };

    getUserDetails();
  }, []);

  const handleLogout = () => {
    setSuccessModalVisible(true);
    setSunccessModalMesg("Do you want to logout");
  };

  const handleClose = () => {
    setSuccessModalVisible(false);
  };

  const handleConfirm = async () => {
    setSuccessModalVisible(false);
    try {
      await signOut();
      setIsLoggedIn(false);
      navigation.navigate("SignIn");
    } catch (error) {
      console.log("Error signing out: ", error);
    }
  };
  return (
    <View style={{ backgroundColor: colors.bgColor, flex: 1 }}>
      <LinearGradient
        style={{
          backgroundColor: colors.royalBlueColour,
          borderBottomLeftRadius: 40.0,
          borderBottomRightRadius: 40.0,
          height: 140,
          paddingTop: 60,
        }}
        colors={[colors.blue, colors.orange]}
        start={{ x: 0, y: 1 }}
        end={{ x: 0, y: 0 }}
      >
        <View
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View style={styles.circleContainer}>
            <View style={styles.circle}></View>
          </View>
        </View>
      </LinearGradient>

      <View
        style={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 70,
        }}
      >
        {UserDteaildata && (
          <Text style={styles.myProfileAdminName}>
            {UserDteaildata !== "" ? UserDteaildata.Name : "Loading"}
          </Text>
        )}
      </View>

      <ScrollView>
        <View style={styles.myProfileDataContainer}>
          {/* Display user data based on availability */}
          {UserDteaildata && (
            <>
              <Pressable style={styles.myProfileDataItem}>
                <Text style={styles.mpdSmallText}>
                  No. of Participated Tournaments
                </Text>
                <Text style={styles.mpdBoldText}>
                  {TotalParticipateTournament}
                </Text>
              </Pressable>
              <Pressable style={styles.myProfileDataItem}>
                <Text style={styles.mpdSmallText}>No. of Teams</Text>
                <Text style={styles.mpdBoldText}>{TotalTeams}</Text>
              </Pressable>
              <Pressable style={styles.myProfileDataItem}>
                <Text style={styles.mpdSmallText}>
                  No. of Total Tournaments
                </Text>
                <Text style={styles.mpdBoldText}>{TotalRegistrationTeam}</Text>
              </Pressable>
            </>
          )}
        </View>

        {/* Menu items */}
        <View style={styles.menuList}>
          <Pressable onPress={() => navigation.navigate("MyProfile")}>
            <View style={styles.menuWrapper}>
              <View style={styles.menuIconTextWrapper}>
                <ProfileIcon
                  name="user-circle"
                  size={26}
                  color={colors.white}
                />
                <Text style={styles.menuTitle}>Manage Profile</Text>
              </View>
              <Ionicons
                name="chevron-forward-outline"
                size={26}
                color={colors.white}
              />
            </View>
          </Pressable>
          <View style={styles.horizontalLine} />

          <Pressable onPress={() => navigation.navigate("MyTournament")}>
            <View style={styles.menuWrapper}>
              <View style={styles.menuIconTextWrapper}>
                <TrophyIcon name="trophy" size={20} color={colors.white} />
                <Text style={styles.menuTitle}>My Tournaments</Text>
              </View>

              <Ionicons
                name="chevron-forward-outline"
                size={26}
                color={colors.white}
              />
            </View>
          </Pressable>
          <View style={styles.horizontalLine} />
          <Pressable
            onPress={async () => {
              navigation.navigate("MyTeams");
            }}
          >
            <View style={styles.menuWrapper}>
              <View style={styles.menuIconTextWrapper}>
                <ProfileIcon name="users" size={20} color={colors.white} />
                <Text style={styles.menuTitle}>My Teams</Text>
              </View>
              <Ionicons
                name="chevron-forward-outline"
                size={26}
                color={colors.white}
              />
            </View>
          </Pressable>
          <View style={styles.horizontalLine} />
          <Pressable
            onPress={() => {
              navigation.navigate("MyMatches");
            }}
          >
            <View style={styles.menuWrapper}>
              <View style={styles.menuIconTextWrapper}>
                <FlagIcon name="sports" size={24} color={colors.white} />
                <Text style={styles.menuTitle}>My Matches</Text>
              </View>
              <Ionicons
                name="chevron-forward-outline"
                size={26}
                color={colors.white}
              />
            </View>
          </Pressable>
          <View style={styles.horizontalLine} />
          <Pressable>
            <View style={styles.menuWrapper}>
              <View style={styles.menuIconTextWrapper}>
                <DollorIcon
                  name="search-dollar"
                  size={20}
                  color={colors.white}
                />
                <Text style={styles.menuTitle}>Transactions</Text>
              </View>
              <Ionicons
                name="chevron-forward-outline"
                size={26}
                color={colors.white}
              />
            </View>
          </Pressable>
          <View style={styles.horizontalLine} />
          <Pressable>
            <View style={styles.menuWrapper}>
              <View style={styles.menuIconTextWrapper}>
                <HelpIcon name="help" size={20} color={colors.white} />
                <Text style={styles.menuTitle}>Help</Text>
              </View>
              <Ionicons
                name="chevron-forward-outline"
                size={26}
                color={colors.white}
              />
            </View>
          </Pressable>
          <View style={styles.horizontalLine} />
          <Pressable
            onPress={async () => {
              handleLogout();
            }}
            style={{ paddingBottom: 30 }}
          >
            <View style={styles.menuWrapper}>
              <View style={styles.menuIconTextWrapper}>
                <LogOutIcon name="logout" size={20} color={colors.white} />
                <Text style={styles.menuTitle}>Logout</Text>
              </View>
              <Ionicons
                name="chevron-forward-outline"
                size={26}
                color={colors.white}
              />
            </View>
          </Pressable>
          {SuccessModalVisible == true ? (
            <AreYouSureOrange
              visibleModal={SuccessModalVisible}
              Message={SunccessModalMesg}
              ButtonText={"Logout"}
              onCancel={handleClose}
              onConfirm={handleConfirm}
            />
          ) : // <SuccessModal
          //   visibleModal={SuccessModalVisible}
          //   handleClose={handleClose}
          //   SuccessMessage={SunccessModalMesg}
          //   handleConfirm={handleConfirm}
          //   handleOk={undefined}
          // />
          null}
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  circleContainer: {
    width: 160,
    height: 160,
    borderRadius: 100,
    margin: "auto",
    justifyContent: "center",
    alignItems: "center",
    padding: 5,
  },
  circle: {
    width: "100%",
    height: "100%",
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.grayColour,
    position: "relative",
  },
  image: {
    width: "100%",
    height: "100%",
    borderRadius: 200,
  },

  myProfileLeft: {
    width: "100%",
    alignItems: "center",
    paddingBottom: 10,
  },

  cameraIconWrapper: {
    backgroundColor: "#140759",
    width: 30,
    height: 30,
    borderRadius: 15,
    position: "absolute",
    right: -2,
    bottom: -1,
    justifyContent: "center",
    alignItems: "center",
  },
  myProfileDataContainer: {
    gap: 10,
    width: "100%",
    marginTop: 15,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 10,
    paddingBottom: 20,
  },
  myProfileDataItem: {
    flexDirection: "row",
    backgroundColor: colors.royalBlue,
    // borderColor: "#ddd",
    // borderWidth: 1,
    width: "100%",
    borderRadius: 10,
    paddingHorizontal: 10,
    paddingVertical: 7,
    justifyContent: "space-between",
    alignItems: "center",
  },
  mpdSmallText: {
    fontFamily: "PlusJakartaSansMedium",
    color: colors.white,
  },
  mpdBoldText: {
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 20,
    color: colors.orangeColour,
  },
  myProfileAdminName: {
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 22,
    color: colors.white,
    paddingBottom: 20,
  },
  menuList: {
    gap: 10,
    width: "100%",
    paddingTop: 10,
    flexDirection: "column",
    paddingHorizontal: 20,
  },
  menuWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    height: 35,
  },
  menuTitle: {
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 16,
    color: colors.white,
  },
  horizontalLine: {
    width: "100%",
    height: 1,
    backgroundColor: colors.darkGray,
  },

  menuIconTextWrapper: {
    flexDirection: "row",
    width: 250,
    gap: 10,
    justifyContent: "flex-start",
    padding: 5,
  },
});

export default MobMenu;
