import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  Dimensions,
  Modal,
  ActivityIndicator,
} from "react-native";
import React, { useEffect, useState } from "react";
import { colors } from "../../../components/reusable/colour";
import { DataStore } from "aws-amplify/datastore";
import { Tournament } from "../../../models";
import KnockOut4 from "../../adminView/Fixture/SubComp/KnockOut4";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigationState, useRoute } from "@react-navigation/native";
import ZoomableImage from "../../../components/reusable/ZoomableImage";
const screenWidth = Dimensions.get("window").width;

const Standing = ({ tournamentId }) => {
  const [roundsData, setRoundsData] = useState([]);
  const [loading, setLoading] = useState(false);

  const lessOpacityImage: any = "../../../../assets/logo/lessOpacity.png";
  const testLogo: any = "../../../../assets/logo/testLogo.png";
  const defaultLogo: any = "../../../../assets/default/defaultLogo.png";

  const navigationState = useNavigationState((state) => state);
  const currentRoute = navigationState.routes[navigationState.index];
  const currentScreen = currentRoute.name;
  console.log("currentScreen", currentScreen);

  const getStandingData = async (tournamentId: any) => {
    try {
      setLoading(true);
      const tournamentDetailsProps = await AsyncStorage.getItem(
        "tournamentProps"
      );
      const tournamentDetails = JSON.parse(tournamentDetailsProps);
      console.log("tournamentDetails", tournamentDetails);

      const subscription = DataStore.observeQuery(Tournament, (c) =>
        c.id.eq(tournamentId)
      ).subscribe(async (snapshot) => {
        const initialTournament = snapshot.items;
        console.log("initialTournament", initialTournament);

        // Extract and set rounds data
        const finalTournamentRoundsPromise = initialTournament.map(
          async (data: any) => {
            const rounds = await data.Rounds.values;

            // Extract and set groups data for each round
            const groupsPromise = rounds.map(async (round: any) => {
              const groups = await round.Groups.values;

              // Extract and set group standings data for each group
              const groupStandingPromise = groups.map(async (group: any) => {
                const groupStandings = await group.GroupStandings.values;
                return { ...group, groupStandings };
              });

              const groupdStandingData = await Promise.all(
                groupStandingPromise
              );

              return { ...round, groups: groupdStandingData };
            });

            const groupsData = await Promise.all(groupsPromise);

            return { ...data, rounds: groupsData };
          }
        );
        const roundsData = await Promise.all(finalTournamentRoundsPromise);
        console.log("roundsData", roundsData);

        const getRoundsData = roundsData.map((data) => data.rounds);
        console.log("getRoundsData", getRoundsData);

        const sortedDataByRoundNo = getRoundsData[0]
          .slice()
          .sort((a: any, b: any) => b.No - a.No);

        console.log("sortedDataByRoundNo", sortedDataByRoundNo);

        setRoundsData(sortedDataByRoundNo);
        setLoading(false);
      });

      return () => {
        subscription.unsubscribe();
      };
    } catch (error) {
      setLoading(false);
      console.log("error occured while fetching the data", error);
    }
  };

  useEffect(() => {
    console.log("tournamentId", tournamentId);
    console.log("pathname", window.location.pathname);
    const getAllData = async () => {
      if (tournamentId) {
        await getStandingData(tournamentId);
      } else {
        console.log("tournamentId in useEffect", tournamentId);
      }
    };

    getAllData();
  }, [tournamentId]);

  return (
    <View>
      <View style={{ flexDirection: "column", gap: 10 }}>
        {Platform.OS === "web" && screenWidth > 760 ? (
          // For web view
          <View>
            {roundsData &&
            roundsData.length > 0 &&
            roundsData !== null &&
            roundsData !== undefined ? (
              roundsData.map((round, index) => (
                <View key={index} style={styles.standingsRoundContainer}>
                  {/* Round Name */}

                  {round.groups.length > 0 ? (
                    <View
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text style={styles.roundNameText}>
                        Round - {round.Name}
                      </Text>
                    </View>
                  ) : null}

                  {/* render knockout UI for knockout round */}

                  {round.Format === "KNOCKOUT" ? (
                    <>
                      <KnockOut4
                        TournamentObj={tournamentId ? tournamentId : ""}
                        KnockOutType={null}
                        Roundobj={round}
                        MatchTeamMasterPlayerUpdate={null}
                        RoundFormatName={round.Name}
                      />
                    </>
                  ) : null}

                  {round.Format !== "KNOCKOUT" &&
                  round.groups &&
                  round.groups.length > 0 ? (
                    round.groups.map((group: any, index: any) => (
                      <View key={index}>
                        {group.groupStandings &&
                        group.groupStandings.length > 0 &&
                        group.Teams &&
                        group.Teams.length > 0 ? (
                          <View style={styles.headerRow}>
                            {Platform.OS === "web" && screenWidth > 760 ? (
                              <View style={styles.groupColumn}>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Text style={styles.tableGroupNameText}>
                                    {group.GroupName ? group.GroupName : null}
                                  </Text>
                                </View>
                                <View style={{ flexDirection: "row" }}>
                                  <Text style={styles.tableSMallHeadingText}>
                                    Posn
                                  </Text>

                                  <Text style={styles.tableSmallHeadingTeam}>
                                    Team
                                  </Text>
                                </View>
                              </View>
                            ) : (
                              <View style={styles.groupColumn}>
                                <Text style={styles.tableGroupNameText}>
                                  {group.GroupName ? group.GroupName : null}
                                </Text>
                              </View>
                            )}

                            <View
                              style={{
                                flexDirection: "row",
                                flex: 1,
                              }}
                            >
                              {Platform.OS === "web" && screenWidth > 760 ? (
                                <View style={styles.gameContainer}>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <View style={styles.blackLine} />
                                    <Text style={styles.tableGroupNameText}>
                                      Games
                                    </Text>
                                    <View style={styles.blackLine} />
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={styles.smallTableHeadingTextCenter}
                                    >
                                      Played
                                    </Text>
                                    <Text
                                      style={styles.smallTableHeadingTextCenter}
                                    >
                                      Won
                                    </Text>
                                    <Text
                                      style={styles.smallTableHeadingTextCenter}
                                    >
                                      Drawn
                                    </Text>
                                    <Text
                                      style={styles.smallTableHeadingTextCenter}
                                    >
                                      Lost
                                    </Text>
                                  </View>
                                </View>
                              ) : (
                                <View style={styles.gameContainer}>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Text
                                      style={styles.smallTableHeadingTextCenter}
                                    >
                                      GP
                                    </Text>
                                    <Text
                                      style={styles.smallTableHeadingTextCenter}
                                    >
                                      W
                                    </Text>
                                    <Text
                                      style={styles.smallTableHeadingTextCenter}
                                    >
                                      D
                                    </Text>
                                    <Text
                                      style={styles.smallTableHeadingTextCenter}
                                    >
                                      L
                                    </Text>
                                  </View>
                                </View>
                              )}
                              {Platform.OS === "web" && screenWidth > 760 ? (
                                <View style={styles.goalContainer}>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <View style={styles.blackLine} />
                                    <Text style={styles.tableGroupNameText}>
                                      Goals
                                    </Text>
                                    <View style={styles.blackLine} />
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={styles.smallTableHeadingTextCenter}
                                    >
                                      For
                                    </Text>
                                    <Text
                                      style={styles.smallTableHeadingTextCenter}
                                    >
                                      Against
                                    </Text>
                                    <Text
                                      style={styles.smallTableHeadingTextCenter}
                                    >
                                      Diff
                                    </Text>
                                  </View>
                                </View>
                              ) : (
                                <View style={styles.goalContainer}>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Text
                                      style={styles.smallTableHeadingTextCenter}
                                    >
                                      F
                                    </Text>
                                    <Text
                                      style={styles.smallTableHeadingTextCenter}
                                    >
                                      A
                                    </Text>
                                    <Text
                                      style={styles.smallTableHeadingTextCenter}
                                    >
                                      GD
                                    </Text>
                                  </View>
                                </View>
                              )}

                              {Platform.OS === "web" && screenWidth > 760 ? (
                                <View style={styles.pointsContainer}>
                                  <Text style={styles.tableSMallHeadingText}>
                                    Points
                                  </Text>
                                </View>
                              ) : (
                                <View
                                  style={{
                                    flexDirection: "column",
                                    width: "7%",
                                    gap: 15,
                                    marginHorizontal: "2%",
                                  }}
                                >
                                  <Text style={styles.tableSMallHeadingText}>
                                    P
                                  </Text>
                                </View>
                              )}
                              {Platform.OS === "web" && screenWidth > 760 ? (
                                <View style={styles.formContainer}>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <View style={styles.blackLine} />
                                    <Text style={styles.tableGroupNameText}>
                                      Form
                                    </Text>
                                    <View style={styles.blackLine} />
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Text style={styles.tableSMallHeadingText}>
                                      Past 5 Games
                                    </Text>
                                  </View>
                                </View>
                              ) : (
                                <View style={styles.formContainer}>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Text style={styles.tableSMallHeadingText}>
                                      Past 5 Games
                                    </Text>
                                  </View>
                                </View>
                              )}
                            </View>
                          </View>
                        ) : null}

                        <View style={{ position: "relative" }}>
                          <Image
                            source={
                              lessOpacityImage ? lessOpacityImage : defaultLogo
                            }
                            // style={styles.backgroundImage}
                            resizeMode="contain"
                          />
                          {group.groupStandings &&
                          group.groupStandings.length > 0
                            ? // Calculate rank based on the specified formula
                              group.groupStandings
                                .map((standing: any) => ({
                                  ...standing,
                                  rank:
                                    standing.For +
                                    standing.Difference * 10000 +
                                    standing.Points * 1000000000,
                                }))
                                // Sort standings by rank in descending order
                                .sort((a: any, b: any) => b.rank - a.rank)
                                // Map to render standings with positions allocated
                                .map(
                                  (
                                    standing: any,
                                    index: number,
                                    sortedStandings: any[]
                                  ) => {
                                    // Calculate position based on sorted index
                                    let currentPosition = index + 1;

                                    // Check for ties with the previous team
                                    if (
                                      index > 0 &&
                                      standing.rank ===
                                        sortedStandings[index - 1].rank
                                    ) {
                                      // If tied, use the same position as the previous team
                                      currentPosition =
                                        sortedStandings[index - 1].position;
                                    }

                                    // Update the standing with the allocated position
                                    standing.position = currentPosition;

                                    return (
                                      <View
                                        style={[
                                          styles.tableDataContainer,
                                          {
                                            backgroundColor:
                                              index % 2 === 0
                                                ? colors.newBlue
                                                : colors.newBlue,
                                          },
                                        ]}
                                        key={index}
                                      >
                                        <View style={styles.tableDataCellSN}>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              justifyContent: "space-around",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Text
                                              style={{
                                                color: colors.white,
                                                paddingHorizontal:
                                                  Platform.OS === "web" &&
                                                  screenWidth > 760
                                                    ? 5
                                                    : 2,
                                              }}
                                            >
                                              {standing.position}
                                            </Text>
                                            {/* <Image
                                              source={{
                                                uri:
                                                  standing &&
                                                  standing.TeamLogo !== ""
                                                    ? standing.TeamLogo
                                                    : defaultLogo,
                                              }}
                                              style={{
                                                width:
                                                  Platform.OS === "web" &&
                                                  screenWidth > 760
                                                    ? 40
                                                    : 30,
                                                height:
                                                  Platform.OS === "web" &&
                                                  screenWidth > 760
                                                    ? 40
                                                    : 30,
                                                paddingHorizontal:
                                                  Platform.OS === "web" &&
                                                  screenWidth > 760
                                                    ? 5
                                                    : 2,
                                                borderRadius: 70,
                                              }}
                                              resizeMode="contain"
                                            /> */}
                                            <ZoomableImage
                                              imageSource={{
                                                uri:
                                                  standing &&
                                                  standing.TeamLogo !== ""
                                                    ? standing.TeamLogo
                                                    : defaultLogo,
                                              }}
                                              imageStyle={{
                                                width:
                                                  Platform.OS === "web" &&
                                                  screenWidth > 760
                                                    ? 40
                                                    : 30,
                                                height:
                                                  Platform.OS === "web" &&
                                                  screenWidth > 760
                                                    ? 40
                                                    : 30,
                                                paddingHorizontal:
                                                  Platform.OS === "web" &&
                                                  screenWidth > 760
                                                    ? 5
                                                    : 2,
                                                borderRadius: 70,
                                                resizeMode: "contain",
                                              }}
                                              modalStyle={undefined}
                                            />
                                          </View>
                                          <View style={{ flex: 1 }}>
                                            {Platform.OS === "web" &&
                                            screenWidth > 760 ? (
                                              <Text style={styles.teamName}>
                                                {/* {standing
                                              ? standing.TeamName
                                              : "NA"} */}
                                                {screenWidth > 760
                                                  ? standing.TeamName
                                                  : standing.TeamShortName}
                                              </Text>
                                            ) : (
                                              <Text style={styles.teamName}>
                                                {standing
                                                  ? standing.TeamName
                                                  : "NA"}
                                              </Text>
                                            )}
                                          </View>
                                        </View>

                                        <View
                                          style={{
                                            flexDirection: "row",
                                            flex: 1,
                                          }}
                                        >
                                          <View style={styles.gameRow}>
                                            <Text style={styles.tableCell}>
                                              {/* {standing ? standing.Played : "0"} */}
                                              {standing
                                                ? standing.Played !== null &&
                                                  standing.Played !== undefined
                                                  ? standing.Played
                                                  : "0"
                                                : "0"}
                                            </Text>
                                            <Text style={styles.tableCell}>
                                              {/* {standing ? standing.Won : "0"} */}
                                              {standing
                                                ? standing.Won !== null &&
                                                  standing.Won !== undefined
                                                  ? standing.Won
                                                  : "0"
                                                : "0"}
                                            </Text>
                                            <Text style={styles.tableCell}>
                                              {/* {standing ? standing.Drawn : "0"} */}
                                              {standing
                                                ? standing.Drawn !== null &&
                                                  standing.Drawn !== undefined
                                                  ? standing.Drawn
                                                  : "0"
                                                : "0"}
                                            </Text>
                                            <Text style={styles.tableCell}>
                                              {/* {standing ? standing.Lost : "0"} */}
                                              {standing
                                                ? standing.Lost !== null &&
                                                  standing.Lost !== undefined
                                                  ? standing.Lost
                                                  : "0"
                                                : "0"}
                                            </Text>
                                          </View>
                                          <View style={styles.goalsRow}>
                                            <Text style={styles.tableCell}>
                                              {/* {standing ? standing.For : "0"} */}
                                              {standing
                                                ? standing.For !== null &&
                                                  standing.For !== undefined
                                                  ? standing.For
                                                  : "0"
                                                : "0"}
                                            </Text>
                                            <Text style={styles.tableCell}>
                                              {/* {standing ? standing.Against : "0"} */}
                                              {standing
                                                ? standing.Against !== null &&
                                                  standing.Against !== undefined
                                                  ? standing.Against
                                                  : "0"
                                                : "0"}
                                            </Text>
                                            <Text style={styles.tableCell}>
                                              {/* {standing ? standing.Difference : "0"} */}
                                              {standing
                                                ? standing.Difference !==
                                                    null &&
                                                  standing.Difference !==
                                                    undefined
                                                  ? standing.Difference
                                                  : "0"
                                                : "0"}
                                            </Text>
                                          </View>
                                          <View style={styles.pointsRow}>
                                            <Text style={styles.tableCell}>
                                              {/* {standing ? standing.Points : "0"} */}
                                              {standing
                                                ? standing.Points !== null &&
                                                  standing.Points !== undefined
                                                  ? standing.Points
                                                  : "0"
                                                : "0"}
                                            </Text>
                                          </View>

                                          {standing.Form
                                            ? standing.Form !== null &&
                                              standing.Form !== "" &&
                                              standing.Form !== undefined
                                              ? standing.Form.map(
                                                  (data: any, index: any) => (
                                                    <View
                                                      style={
                                                        styles.formCircleRow
                                                      }
                                                      key={index}
                                                    >
                                                      <View
                                                        style={
                                                          data === "W"
                                                            ? styles.greenCircle
                                                            : data === "L"
                                                            ? styles.redCircle
                                                            : data === "D"
                                                            ? styles.grayCircle
                                                            : null
                                                        }
                                                      >
                                                        <Text
                                                          style={{
                                                            color: "white",
                                                            fontSize:
                                                              Platform.OS ===
                                                                "web" &&
                                                              screenWidth > 760
                                                                ? 12
                                                                : 9,
                                                          }}
                                                        >
                                                          {data}
                                                        </Text>
                                                      </View>
                                                    </View>
                                                  )
                                                )
                                              : null
                                            : null}
                                        </View>
                                      </View>
                                    );
                                  }
                                )
                            : null}
                        </View>
                      </View>
                    ))
                  ) : round.Format !== "KNOCKOUT" ? (
                    <View style={{ alignItems: "center", width: "100%" }}>
                      <Image
                        source={require("../../../../assets/noData/teams.png")}
                        style={styles.noDataImage}
                        resizeMode="contain"
                      />
                      <Text style={styles.noDataText}>
                        Hang on! Not a single match is completed yet...
                      </Text>
                    </View>
                  ) : null}
                </View>
              ))
            ) : (
              <View style={{ alignItems: "center", width: "100%" }}>
                <Image
                  source={require("../../../../assets/noData/teams.png")}
                  style={styles.noDataImage}
                  resizeMode="contain"
                />
                <Text style={styles.noDataText}>
                  Hang on! Not a single match is complete yet...
                </Text>
              </View>
            )}
          </View>
        ) : (
          // For mobile view
          <View
            style={{
              paddingBottom: 100,
            }}
          >
            {roundsData &&
            roundsData.length > 0 &&
            roundsData !== null &&
            roundsData !== undefined ? (
              roundsData.map((round, index) => (
                <View key={index} style={[styles.standingsRoundContainer]}>
                  {round.groups.length > 0 ? (
                    <View
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text style={styles.roundNameText}>{round.Name}</Text>
                    </View>
                  ) : null}

                  {round.Format === "KNOCKOUT" ? (
                    <>
                      {/* <Text style={{ color: colors.white }}>{round.Name}</Text> */}
                      <KnockOut4
                        TournamentObj={tournamentId ? tournamentId : ""}
                        KnockOutType={null}
                        Roundobj={round}
                        MatchTeamMasterPlayerUpdate={null}
                        RoundFormatName={round.Name}
                      />
                    </>
                  ) : null}

                  {round.Format !== "KNOCKOUT" &&
                  round.groups &&
                  round.groups.length > 0 ? (
                    round.groups.map((group: any, index: any) => (
                      <View key={index}>
                        {group.groupStandings &&
                        group.groupStandings.length > 0 &&
                        group.Teams &&
                        group.Teams.length > 0 ? (
                          <View style={styles.headerRow}>
                            {Platform.OS === "web" && screenWidth > 760 ? (
                              <View style={styles.groupColumn}>
                                <View
                                  style={{
                                    flexDirection: "row",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Text style={styles.tableGroupNameText}>
                                    {group.GroupName ? group.GroupName : null}
                                  </Text>
                                </View>
                                <View style={{ flexDirection: "row" }}>
                                  <Text style={styles.tableSMallHeadingText}>
                                    Posn
                                  </Text>

                                  <Text style={styles.tableSmallHeadingTeam}>
                                    Team
                                  </Text>
                                </View>
                              </View>
                            ) : (
                              <View style={styles.groupColumn}>
                                <Text style={styles.tableGroupNameText}>
                                  {group.GroupName ? group.GroupName : null}
                                </Text>
                              </View>
                            )}

                            <View
                              style={{
                                flexDirection: "row",
                                flex: 1,
                                alignItems: "center",
                              }}
                            >
                              {Platform.OS === "web" && screenWidth > 760 ? (
                                <View style={styles.gameContainer}>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <View style={styles.blackLine} />
                                    <Text style={styles.tableGroupNameText}>
                                      Games
                                    </Text>
                                    <View style={styles.blackLine} />
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={styles.smallTableHeadingTextCenter}
                                    >
                                      Played
                                    </Text>
                                    <Text
                                      style={styles.smallTableHeadingTextCenter}
                                    >
                                      Won
                                    </Text>
                                    <Text
                                      style={styles.smallTableHeadingTextCenter}
                                    >
                                      Drawn
                                    </Text>
                                    <Text
                                      style={styles.smallTableHeadingTextCenter}
                                    >
                                      Lost
                                    </Text>
                                  </View>
                                </View>
                              ) : (
                                <View style={[styles.gameContainer]}>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Text
                                      style={styles.smallTableHeadingTextCenter}
                                    >
                                      GP
                                    </Text>
                                    <Text
                                      style={styles.smallTableHeadingTextCenter}
                                    >
                                      W
                                    </Text>
                                    <Text
                                      style={styles.smallTableHeadingTextCenter}
                                    >
                                      D
                                    </Text>
                                    <Text
                                      style={styles.smallTableHeadingTextCenter}
                                    >
                                      L
                                    </Text>
                                  </View>
                                </View>
                              )}
                              {Platform.OS === "web" && screenWidth > 760 ? (
                                <View style={styles.goalContainer}>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <View style={styles.blackLine} />
                                    <Text style={styles.tableGroupNameText}>
                                      Goals
                                    </Text>
                                    <View style={styles.blackLine} />
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      style={styles.smallTableHeadingTextCenter}
                                    >
                                      For
                                    </Text>
                                    <Text
                                      style={styles.smallTableHeadingTextCenter}
                                    >
                                      Against
                                    </Text>
                                    <Text
                                      style={styles.smallTableHeadingTextCenter}
                                    >
                                      Diff
                                    </Text>
                                  </View>
                                </View>
                              ) : (
                                <View style={styles.goalContainer}>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Text
                                      style={styles.smallTableHeadingTextCenter}
                                    >
                                      F
                                    </Text>
                                    <Text
                                      style={styles.smallTableHeadingTextCenter}
                                    >
                                      A
                                    </Text>
                                    <Text
                                      style={styles.smallTableHeadingTextCenter}
                                    >
                                      GD
                                    </Text>
                                  </View>
                                </View>
                              )}

                              {Platform.OS === "web" && screenWidth > 760 ? (
                                <View style={styles.pointsContainer}>
                                  <Text style={styles.tableSMallHeadingText}>
                                    Points
                                  </Text>
                                </View>
                              ) : (
                                <View
                                  style={{
                                    flexDirection: "column",
                                    width: "7%",
                                    gap: 15,
                                    marginHorizontal: "2%",
                                  }}
                                >
                                  <Text style={styles.tableSMallHeadingText}>
                                    P
                                  </Text>
                                </View>
                              )}
                              {Platform.OS === "web" && screenWidth > 760 ? (
                                <View style={styles.formContainer}>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <View style={styles.blackLine} />
                                    <Text style={styles.tableGroupNameText}>
                                      Form
                                    </Text>
                                    <View style={styles.blackLine} />
                                  </View>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Text style={styles.tableSMallHeadingText}>
                                      Past 5 Games
                                    </Text>
                                  </View>
                                </View>
                              ) : (
                                <View style={styles.formContainer}>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Text style={styles.tableSMallHeadingText}>
                                      Form
                                    </Text>
                                  </View>
                                </View>
                              )}
                            </View>
                          </View>
                        ) : null}

                        <View style={{ position: "relative" }}>
                          <Image
                            source={
                              lessOpacityImage ? lessOpacityImage : defaultLogo
                            }
                            // style={styles.backgroundImage}
                            resizeMode="contain"
                          />
                          {group.groupStandings &&
                          group.groupStandings.length > 0
                            ? group.groupStandings
                                .map((standing: any) => ({
                                  ...standing,
                                  rank:
                                    standing.For +
                                    standing.Difference * 10000 +
                                    standing.Points * 1000000000,
                                }))
                                // Sort standings by rank in descending order
                                .sort((a: any, b: any) => b.rank - a.rank)
                                // Map to render standings with positions allocated
                                .map(
                                  (
                                    standing: any,
                                    index: number,
                                    sortedStandings: any[]
                                  ) => {
                                    // Calculate position based on sorted index
                                    let currentPosition = index + 1;

                                    // Check for ties with the previous team
                                    if (
                                      index > 0 &&
                                      standing.rank ===
                                        sortedStandings[index - 1].rank
                                    ) {
                                      // If tied, use the same position as the previous team
                                      currentPosition =
                                        sortedStandings[index - 1].position;
                                    }

                                    // Update the standing with the allocated position
                                    standing.position = currentPosition;

                                    return (
                                      <View
                                        style={[
                                          styles.tableDataContainer,
                                          {
                                            backgroundColor:
                                              screenWidth > 760
                                                ? colors.tableData
                                                : "transparent",
                                            // backgroundColor:
                                            //   index % 2 === 0
                                            //     ? colors.tableData
                                            //     : colors.tableData,
                                          },
                                        ]}
                                        key={index}
                                      >
                                        <View style={styles.tableDataCellSN}>
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              justifyContent: "space-around",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Text
                                              style={{
                                                color: colors.white,
                                                paddingHorizontal:
                                                  Platform.OS === "web" &&
                                                  screenWidth > 760
                                                    ? 5
                                                    : 2,
                                              }}
                                            >
                                              {standing.position}
                                            </Text>

                                            <ZoomableImage
                                              imageSource={{
                                                uri: standing
                                                  ? standing.TeamLogo
                                                  : defaultLogo,
                                              }}
                                              imageStyle={{
                                                width:
                                                  Platform.OS === "web" &&
                                                  screenWidth > 760
                                                    ? 40
                                                    : 30,
                                                height:
                                                  Platform.OS === "web" &&
                                                  screenWidth > 760
                                                    ? 40
                                                    : 30,
                                                paddingHorizontal:
                                                  Platform.OS === "web" &&
                                                  screenWidth > 760
                                                    ? 5
                                                    : 2,
                                                borderRadius: 70,
                                                resizeMode: "contain",
                                              }}
                                              modalStyle={undefined}
                                            />
                                          </View>
                                          <View style={{ flex: 1 }}>
                                            {Platform.OS === "web" &&
                                            screenWidth > 760 ? (
                                              <Text style={styles.teamName}>
                                                {standing
                                                  ? standing.TeamName
                                                  : "NA"}
                                              </Text>
                                            ) : (
                                              <Text style={styles.teamName}>
                                                {standing.TeamShortName
                                                  ? standing.TeamShortName
                                                  : "NA"}
                                              </Text>
                                            )}
                                          </View>
                                        </View>

                                        <View
                                          style={{
                                            flexDirection: "row",
                                            flex: 1,
                                          }}
                                        >
                                          <View style={styles.gameRow}>
                                            <Text style={styles.tableCell}>
                                              {/* {standing ? standing.Played : "0"} */}
                                              {standing
                                                ? standing.Played !== null &&
                                                  standing.Played !== undefined
                                                  ? standing.Played
                                                  : "0"
                                                : "0"}
                                            </Text>
                                            <Text style={styles.tableCell}>
                                              {/* {standing ? standing.Won : "0"} */}
                                              {standing
                                                ? standing.Won !== null &&
                                                  standing.Won !== undefined
                                                  ? standing.Won
                                                  : "0"
                                                : "0"}
                                            </Text>
                                            <Text style={styles.tableCell}>
                                              {/* {standing ? standing.Drawn : "0"} */}
                                              {standing
                                                ? standing.Drawn !== null &&
                                                  standing.Drawn !== undefined
                                                  ? standing.Drawn
                                                  : "0"
                                                : "0"}
                                            </Text>
                                            <Text style={styles.tableCell}>
                                              {/* {standing ? standing.Lost : "0"} */}
                                              {standing
                                                ? standing.Lost !== null &&
                                                  standing.Lost !== undefined
                                                  ? standing.Lost
                                                  : "0"
                                                : "0"}
                                            </Text>
                                          </View>
                                          <View style={styles.goalsRow}>
                                            <Text style={styles.tableCell}>
                                              {/* {standing ? standing.For : "0"} */}
                                              {standing
                                                ? standing.For !== null &&
                                                  standing.For !== undefined
                                                  ? standing.For
                                                  : "0"
                                                : "0"}
                                            </Text>
                                            <Text style={styles.tableCell}>
                                              {/* {standing ? standing.Against : "0"} */}
                                              {standing
                                                ? standing.Against !== null &&
                                                  standing.Against !== undefined
                                                  ? standing.Against
                                                  : "0"
                                                : "0"}
                                            </Text>
                                            <Text style={styles.tableCell}>
                                              {/* {standing ? standing.Difference : "0"} */}
                                              {standing
                                                ? standing.Difference !==
                                                    null &&
                                                  standing.Difference !==
                                                    undefined
                                                  ? standing.Difference
                                                  : "0"
                                                : "0"}
                                            </Text>
                                          </View>
                                          <View style={styles.pointsRow}>
                                            <Text style={styles.tableCell}>
                                              {/* {standing ? standing.Points : "0"} */}
                                              {standing
                                                ? standing.Points !== null &&
                                                  standing.Points !== undefined
                                                  ? standing.Points
                                                  : "0"
                                                : "0"}
                                            </Text>
                                          </View>

                                          {standing.Form
                                            ? standing.Form !== null &&
                                              standing.Form !== "" &&
                                              standing.Form !== undefined
                                              ? standing.Form.map(
                                                  (data: any, index: any) => (
                                                    <View
                                                      style={
                                                        styles.formCircleRow
                                                      }
                                                      key={index}
                                                    >
                                                      <View
                                                        style={
                                                          data === "W"
                                                            ? styles.greenCircle
                                                            : data === "L"
                                                            ? styles.redCircle
                                                            : data === "D"
                                                            ? styles.grayCircle
                                                            : null
                                                        }
                                                      >
                                                        <Text
                                                          style={{
                                                            color: "white",
                                                            fontSize:
                                                              Platform.OS ===
                                                                "web" &&
                                                              screenWidth > 760
                                                                ? 12
                                                                : 9,
                                                          }}
                                                        >
                                                          {data}
                                                        </Text>
                                                      </View>
                                                    </View>
                                                  )
                                                )
                                              : null
                                            : null}
                                        </View>
                                      </View>
                                    );
                                  }
                                )
                            : null}
                        </View>
                      </View>
                    ))
                  ) : (
                    <View style={{ alignItems: "center", width: "100%" }}>
                      <Image
                        source={require("../../../../assets/noData/teams.png")}
                        style={styles.noDataImage}
                        resizeMode="contain"
                      />
                      <Text style={styles.noDataText}>
                        Hang on! Not a single match is completed yet...
                      </Text>
                    </View>
                  )}
                </View>
              ))
            ) : (
              <View style={{ alignItems: "center", width: "100%" }}>
                <Image
                  source={require("../../../../assets/noData/teams.png")}
                  style={styles.noDataImage}
                  resizeMode="contain"
                />
                <Text style={styles.noDataText}>
                  Hang on! Not a single match is completed yet...
                </Text>
              </View>
            )}
          </View>
        )}

        {loading ? (
          <Modal transparent animationType="none">
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <ActivityIndicator size="large" color="#0000ff" />
              <Text>Loading</Text>
            </View>
          </Modal>
        ) : null}
      </View>
    </View>
  );
};

const styles: any = StyleSheet.create({
  headerRow: {
    backgroundColor: screenWidth > 760 ? colors.bgColor : colors.royalBlue,
    fontFamily: "PlusJakartaSansBold",
    width: "100%",
    flexDirection: "row",
    textAlign: "center",
    borderRadius: 10,
    borderColor: "rgba(208, 219, 241, 1)",
    padding: 10,
  },
  tableDataContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    flexDirection: "row",
    marginTop: 10,
    borderRadius: 10,
    shadowColor: "#00000021",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    elevation: 5,
    shadowRadius: screenWidth > 760 ? 10 : 0,
    padding: 10,
    paddingVertical: screenWidth > 760 ? undefined : 0,
    textAlign: "center",
  },
  tableDataGrayContainer: {
    backgroundColor: "#e9e9e9c4",
    display: "flex",
    width: "100%",
    alignItems: "center",
    gap: 130,
    flexDirection: "row",
    marginTop: 10,
    borderRadius: 10,

    paddingHorizontal: 20,
    paddingVertical: 10,
    textAlign: "center",
  },
  tableDataCellSN: {
    flexDirection: "row",
    width: Platform.OS === "web" && screenWidth > 760 ? "30%" : "25%",
    // justifyContent: "space-between",
    textAlign: "center",
    alignItems: "center",
    fontFamily: "PlusJakartaSansExtraLight",
  },
  tableCell: {
    fontFamily: "PlusJakartaSansExtraLight",
    fontSize: 14,
    textAlign: "center",
    alignItems: "center",
    color: colors.white,
    flex: 1,
  },
  circleText: {
    fontFamily: "PlusJakartaSansExtraLight",
    fontSize: 14,
    textAlign: "center",
    alignItems: "center",
  },
  formCircleRow: {
    flexDirection: "row",
    alignItems: "center",
    width: Platform.OS === "web" && screenWidth > 760 ? "2%" : "40%",
    marginHorizontal:
      Platform.OS === "web" && screenWidth > 760 ? 10 : undefined,
    gap: 2,
    justifyContent: "center",
  },
  greenCircle: {
    width: Platform.OS === "web" && screenWidth > 760 ? 24 : 18,
    height: Platform.OS === "web" && screenWidth > 760 ? 24 : 18,
    borderRadius: 20,
    backgroundColor: colors.success,
    justifyContent: "center",
    alignItems: "center",
    margin: Platform.OS === "web" && screenWidth > 760 ? 5 : undefined,
  },
  redCircle: {
    width: Platform.OS === "web" && screenWidth > 760 ? 24 : 18,
    height: Platform.OS === "web" && screenWidth > 760 ? 24 : 18,
    borderRadius: 20,
    backgroundColor: colors.red,
    justifyContent: "center",
    alignItems: "center",
    margin: Platform.OS === "web" && screenWidth > 760 ? 5 : undefined,
  },
  grayCircle: {
    width: Platform.OS === "web" && screenWidth > 760 ? 24 : 18,
    height: Platform.OS === "web" && screenWidth > 760 ? 24 : 18,
    borderRadius: 20,
    backgroundColor: "rgba(217, 217, 217, 1)",
    justifyContent: "center",
    alignItems: "center",
    margin: Platform.OS === "web" && screenWidth > 760 ? 5 : undefined,
  },
  gameRow: {
    flexDirection: "row",
    width: Platform.OS === "web" && screenWidth > 760 ? "35%" : "30%",
    alignItems: "center",
  },
  goalsRow: {
    flexDirection: "row",
    width: Platform.OS === "web" && screenWidth > 760 ? "28%" : "23%",
    alignItems: "center",
    marginHorizontal: "2%",
  },
  pointsRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: Platform.OS === "web" && screenWidth > 760 ? "7%" : "5%",
    alignItems: "center",
  },
  backgroundImage: {
    height: 150,
    width: 150,
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: [{ translateX: -75 }, { translateY: -75 }],
  },
  groupColumn: {
    flexDirection: "column",
    width: Platform.OS === "web" && screenWidth > 760 ? "30%" : "25%",
    gap: Platform.OS === "web" && screenWidth > 760 ? 15 : 5,
  },
  gameContainer: {
    flexDirection: "column",
    width: Platform.OS === "web" && screenWidth > 760 ? "35%" : "32%",
    gap: Platform.OS === "web" && screenWidth > 760 ? 15 : 5,
  },
  goalContainer: {
    flexDirection: "column",
    width: Platform.OS === "web" && screenWidth > 760 ? "28%" : "25%",
    gap: Platform.OS === "web" && screenWidth > 760 ? 15 : 5,
    marginHorizontal:
      Platform.OS === "web" && screenWidth > 760 ? "2%" : undefined,
  },
  pointsContainer: {
    flexDirection: "column",
    justifyContent: "flex-end",
    width: Platform.OS === "web" && screenWidth > 760 ? "7%" : "3%",
  },
  formContainer: {
    flexDirection: "column",
    width: Platform.OS === "web" && screenWidth > 760 ? "22%" : "38%",
    gap: Platform.OS === "web" && screenWidth > 760 ? 15 : 5,
  },
  teamName: {
    textAlign: Platform.OS === "web" && screenWidth > 760 ? "left" : "center",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 15 : 14,
    fontFamily: "PlusJakartaSansExtraLight",
    paddingLeft: Platform.OS === "web" && screenWidth > 760 ? 5 : undefined,
    color: colors.white,
  },
  blackLine: {
    height: 1,
    flex: 1,
    backgroundColor: colors.white,
  },
  dropdownContainer: {
    borderColor: "#b3c5e9",
    borderWidth: 0.5,
    borderRadius: 8,
    paddingHorizontal: 5,
    width: 220,
    height: Platform.OS === "android" || Platform.OS === "ios" ? 50 : null,
    marginBottom: 10,
  },
  standingsRoundContainer: {
    shadowColor: "#00000021",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    elevation: 5,
    shadowRadius: 10,
    padding: screenWidth > 760 ? 7 : 0,
    borderRadius: 10,
    backgroundColor: screenWidth > 760 ? colors.royalBlue : "transparent",
    gap: 15,
    marginBottom: 10,
  },
  roundNameText: {
    fontFamily: "PlusJakartaSansExtraLight",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 16 : 14,
    color: colors.white,
    backgroundColor: screenWidth > 760 ? colors.bgColor : colors.royalBlue,
    paddingVertical: 4,
    paddingHorizontal: 20,
    borderRadius: 20,
  },
  noDataImage: {
    width: 270,
    height: 270,
  },
  noDataText: {
    fontFamily: "PlusJakartaSansSemiBold",
    color: colors.white,
    fontSize: 20,
    maxWidth: 400,
    textAlign: "center",
  },
  tableGroupNameText: {
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 14,
    paddingHorizontal: 5,
    color: colors.white,
  },
  tableSMallHeadingText: {
    fontFamily: "PlusJakartaSansExtraLight",
    fontSize: 13,
    color: colors.white,
  },
  tableSmallHeadingTeam: {
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 13,
    textAlign: "center",
    paddingLeft: 35,
    color: colors.white,
  },
  smallTableHeadingTextCenter: {
    fontFamily: "PlusJakartaSansSemiBold",
    fontSize: 13,
    flex: 1,
    textAlign: "center",
    color: colors.white,
  },
});

export default Standing;
