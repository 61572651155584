import {
  View,
  Text,
  StyleSheet,
  Image,
  TextInput,
  Pressable,
  Modal,
  ActivityIndicator,
} from "react-native";
import React, { useContext, useEffect, useState } from "react";
import Layout from "../../../../components/include/Layout";
import { colors } from "../../../../components/reusable/colour";

import UploadImage from "../../../../components/reusable/UploadImage";
import { DataStore } from "@aws-amplify/datastore";
import {
  PlayerMaster,
  TeamRegistrations,
  TeamRegistrationsPlayerMaster,
  Tournament,
  UserMaster,
  UserMasterTournament,
} from "../../../../models";
import { useNavigation, useRoute } from "@react-navigation/native";
import { AuthenticationContext } from "../../../../authentication/Authentication";
import { getUserMasterData } from "../../../../api/UserMasterAPI";
import { uploadData } from "aws-amplify/storage";
import awsmobile from "../../../../aws-exports.js";
import {
  ErrorModal,
  SuccessModalNew,
} from "../../../../components/reusable/AreYouSure";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { handlePlayerSerialNumber } from "../../../../components/reusable/CreatePlayerSerialNumber";
import CustomDatePicker from "../../../../components/reusable/CustomeDatePicker";
const defaultLogo: any = require("../../../../../assets/default/defaultLogo.png");

const PlayerRegistration = () => {
  const bucketName = awsmobile.aws_user_files_s3_bucket;
  const route = useRoute();
  const navigation: any = useNavigation();
  const { createAdminUser, getAdminUser, isLoggedIn } = useContext(
    AuthenticationContext
  );
  const [teamsRegistrationData, setTeamsRegistrationData]: any = useState({});
  const [tournamentData, setTournamentData]: any = useState({});
  const { tournamentId, teamId }: any = route.params || {};
  const [uuid, setUUID] = useState("");
  const [userData, setUserData]: any = useState({});
  const [teamID, setTeamID] = useState("");
  const [tournamentID, setTournamentID] = useState("");

  // const [playerSerialNumber, setPlayerSerialNumber] = useState("");

  let playerSerialNumber: any;
  let admin1UserData: any;

  const [playersList, setPlayersList]: any = useState({
    Name: "",
    DOB: "",
    JerseyNo: "",
    MobileNo: "",
    PhotoURL: "",
    UUID: "",
    InstagramId: "",
  });
  const [loading, setLoading] = useState(false);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [successModalMesg, setSuccessModalMesg] = useState("");
  const [errorModalMesg, setErrorModalMesg] = useState("");

  const [selectedLogoSize, setSelectedLogoSize]: any = useState(Number);
  const [tournamentName, setTournamentName] = useState("");
  const [teamLogoURL, setTeamLogoURL] = useState("");
  const [teamName, setTeamName] = useState("");
  const [teamShortName, setTeamShortName] = useState("");
  const [coachName, setCoachName]: any = useState("");
  const [coachMobileNo, setCoachMobileNo]: any = useState(Number);
  const [profilePicsArray, setProfilePicsArray] = useState({
    Pic: null,
    UploadedPercentage: "",
    Url: "",
  });

  const [PlayerSummaryData, setPlayerSummaryData]: any = useState({});
  const [userMobileNumber, setUserMobileNumber]: any = useState("");

  const [isDuplicatePlayerFound, setIsDuplicatePlayerFound] = useState(false);

  const [isTournamentIdValid, setIsTournamentIdValid] = useState(false);
  const [isTeamIdValid, setIsTeamIdValid] = useState(false);

  let userDetails: any,
    previousPageURL: any,
    hasErrors = false,
    isRoutesIdValid: any;

  const handleOk = () => {
    setSuccessModalVisible(false);
    navigation.navigate("AllTournament");
  };

  const handleSubmitError = () => {
    setErrorModalVisible(false);
    if (!isLoggedIn) {
      navigation.navigate("SignIn");
    } else {
      console.log("User details found");
    }
    if (isDuplicatePlayerFound) {
      navigation.navigate("AllTournament");
    }
    if (
      tournamentData.id !== tournamentId ||
      teamsRegistrationData.id !== teamId
    ) {
      navigation.navigate("AllTournament");
    }
  };

  const handleClose = async () => {
    navigation.navigate("AllTournament");
  };

  const getUserdata = async () => {
    try {
      userDetails = await AsyncStorage.getItem("UserDetail");
      console.log("userDetails", userDetails);
      if (userDetails && userDetails !== null) {
        const userData = await DataStore.query(UserMaster, userDetails);
        console.log("userData", userData);

        admin1UserData = userData;
        console.log(
          "admin1UserData",
          admin1UserData,
          "userUUID",
          userData.UUID
        );

        if (userData) {
          const playerMobileNo = userData.ContactNo;
          const updatedPlayerMobileNo = {
            ...playersList,
            MobileNo: playerMobileNo,
          };
          console.log("updatedPlayerMobileNo", updatedPlayerMobileNo);
          setPlayersList(updatedPlayerMobileNo);
          // setUserMobileNumber(userData?.ContactNo);
          setUserData(userData);
          setUUID(userData?.UUID);
          setErrorModalVisible(false);
        } else if (playersList.MobileNo) {
          setPlayersList(playersList.MobileNo);
          // setUserMobileNumber(playersList.MobileNo);

          throw new Error("User data not found");
        } else {
          console.log("user not found");
        }
      } else {
        console.log("No user found in temp storage");
        setErrorModalVisible(true);
        setErrorModalMesg(
          "You are not loggedIn ! Please login to your account ! Press 'OK' to redirect to login page"
        );
        throw new Error("No user found in temp storage");
      }
    } catch (error) {
      console.log(error.message);
      setErrorModalVisible(true);
      setErrorModalMesg(
        "You are not logged in! Please log in to your account! Press 'OK' to redirect to the login page"
      );
    }
  };

  const generateSerialNumber = async () => {
    const originLocation = window.location.origin;
    console.log("origin location", originLocation);

    try {
      const serialNumber = await handlePlayerSerialNumber(originLocation);
      // setPlayerSerialNumber(serialNumber);
      playerSerialNumber = serialNumber;
      console.log("Generated Player Serial Number:", serialNumber);
    } catch (error) {
      console.error("Error generating player serial number", error);
    }
  };

  const validateRoutesId = async () => {
    let tournament = await DataStore.query(Tournament, tournamentId);
    console.log("tournament", tournament);

    let team = await DataStore.query(TeamRegistrations, teamId);
    console.log("team", team);

    if (Object.keys(tournament).length > 0 && Object.keys(team).length > 0) {
      console.log("tournament is valid !");
      setErrorModalVisible(false);
      setIsTournamentIdValid(true);
      setIsTeamIdValid(true);
    } else {
      console.log("tournament is not valid !");
      setIsTournamentIdValid(false);
      setIsTeamIdValid(false);
      setErrorModalVisible(true);
      setErrorModalMesg("Invalid URL !! ");
    }
  };

  useEffect(() => {
    console.log("tournamentId", tournamentId, "teamId", teamId);
    console.log("duplicate player", isDuplicatePlayerFound);
    console.log("error modal", errorModalVisible);
    console.log("origin path:", window.location.origin);
    validateRoutesId();

    if (tournamentId && teamId) {
      getTournamentData();
      getSubmittedTeamsData();
      setTeamID(teamId);
      setTournamentID(tournamentId);
      getUserdata();
    } else {
      console.log("tournamentId and teamId is not found !!");
    }

    if (userData) {
      handleDuplicatePlayer();
    } else {
      console.log("User is not logged in");
    }

    AsyncStorage.setItem("TournamentId", tournamentId);
    AsyncStorage.setItem("TeamId", teamId);

    const setCurrentURLPath = async () => {
      const pathname = window.location.href; // This gives the current URL
      console.log("pathname", pathname);

      await AsyncStorage.setItem("pageUrl", pathname)
        .then(() => {
          console.log("URL saved successfully");
        })
        .catch((error) => {
          console.error("Error saving URL:", error);
        });
    };
    setCurrentURLPath();
  }, [uuid, isDuplicatePlayerFound, tournamentId, teamId]);

  const handleInputPlayerChange = (field, value) => {
    console.log("hellotext", field, value);

    setPlayersList((lastValue) => {
      return {
        ...lastValue,
        [field]: value,
      };
    });
  };

  const getTournamentData = () => {
    try {
      setLoading(true);
      const subscription = DataStore.observeQuery(Tournament, (c) =>
        c.id.eq(tournamentId)
      ).subscribe(async (snapshot) => {
        const initialTournaments = snapshot.items[0];
        console.log("initialTournaments : ", initialTournaments);

        // const checkIsTournmanetId = initialTournaments.id == tournamentId;
        // console.log("checkIsTournmanetId", checkIsTournmanetId);

        // if (initialTournaments.id == tournamentId) {
        //   setIsTournamentIdValid(true);
        // } else {
        //   setIsTournamentIdValid(false);
        // }

        setTournamentData(initialTournaments);
        let tournamentName = initialTournaments?.Name;
        setTournamentName(tournamentName);
        setLoading(false);
      });
      return () => {
        subscription.unsubscribe();
      };
    } catch (error) {
      setLoading(false);
      console.log("Error occured while fetching the data", error);
    }
  };

  const getSubmittedTeamsData = async () => {
    try {
      setLoading(true);
      const subscription = await DataStore.observeQuery(
        TeamRegistrations,
        (c) => c.id.eq(teamId)
      ).subscribe(async (snapshot) => {
        const initialTeamsRegistration = snapshot.items[0];
        console.log("initialTeamsRegistration", initialTeamsRegistration);

        // const checkIsTeamValid = initialTeamsRegistration.id == teamId;
        // console.log("checkIsTeamValid", checkIsTeamValid);

        // if (initialTeamsRegistration.id == teamId) {
        //   setIsTeamIdValid(true);
        // } else {
        //   setIsTeamIdValid(false);
        // }
        setTeamsRegistrationData(initialTeamsRegistration);

        const teamName = initialTeamsRegistration.TeamName;
        setTeamName(teamName);

        const teamLogo = initialTeamsRegistration.Logo;
        console.log("teamLogo", teamLogo);
        setTeamLogoURL(teamLogo);

        const coachUUID = initialTeamsRegistration?.Coach?.UUID;
        if (coachUUID) {
          getUserMasterData(coachUUID).then(async (response) => {
            if (response) {
              console.log("Coach Data", response);
              const coachName = response.Name;
              setCoachName(coachName);
              const coachMobileNo = response.ContactNo;
              setCoachMobileNo(coachMobileNo);
              setLoading(false);
            } else {
              console.log("No response get from userMasterData");
            }
          });
        } else {
          console.log("CoachId not found");
        }
      });
      return () => {
        subscription.unsubscribe();
      };
    } catch (error) {
      setLoading(false);
      console.log("Error occured while fetching the data", error);
    }
  };

  const cognitoUserDetails = async (phoneNumber) => {
    const response = await getAdminUser(phoneNumber);
    if (response) {
      console.log("UserDetails : ", response, response.statusCode);
    }

    return response;
  };

  const addUser = async (phoneNumber) => {
    let finalResponse;
    const userAttribute = [
      {
        Name: "phone_number" /* required */,
        Value: phoneNumber,
      },
    ];
    const response = await createAdminUser(phoneNumber, userAttribute).then(
      async (data) => {
        console.log("CreateUserResponse : ", data);
        finalResponse = data;
      }
    );
    console.log("response", response, "finalResponse", finalResponse);

    return finalResponse;
  };

  async function uploadLogoFileToStorage(uri) {
    try {
      const response = await fetch(uri);
      const blob = await response.blob();
      const randomNumber = (
        Math.floor(Math.random() * 900000) + 100000
      ).toString();
      await uploadData({
        key:
          "team/" +
          teamName.trim().replaceAll(" ", "_") +
          randomNumber +
          "/logo.png",
        data: blob,
      });
      let url =
        `https://${bucketName}.s3.ap-south-1.amazonaws.com/public/team/` +
        teamName.trim().replaceAll(" ", "_") +
        randomNumber +
        "/logo.png";
      setTeamLogoURL(url);

      // contentType: 'image/png' // contentType is optional
    } catch (err) {
      console.log("Error uploading file:", err);
    }
  }

  const handleSetProfilePic = (e, field) => {
    console.log("targetvalue");
    console.log("We are in handle set PP");
    if (field === "Pic") {
      const { Pic, value } = e;
      const list = { ...profilePicsArray };
      console.log("Pic : ", e);
      list.Pic = e;
      console.log("list", list);
      console.log("We are in Pic");
      setProfilePicsArray(list);
    }
    if (field === "UploadedPercentage") {
      const { UploadedPercentage, value } = e;
      const list = { ...profilePicsArray };

      list.UploadedPercentage = e;
      console.log("list", list);
      console.log("We are in handle set Percentage");
      setProfilePicsArray(list);
    } else {
      const { Url, value } = e;
      const list = { ...profilePicsArray };

      list.Url = e;
      console.log("list", list);
      console.log("We are in handle set Url");
      setProfilePicsArray(list);
    }
  };

  async function uploadProfilePicToStorage(uri) {
    const number = Math.floor(100000 + Math.random() * 900000)
      .toString()
      .substring(0, 4);
    try {
      const response = await fetch(uri);
      const blob = await response.blob();
      await uploadData({
        key: "userPhotos/" + number + ".jpg",
        data: blob,
        options: {
          onProgress: ({ transferredBytes, totalBytes }) => {
            if (totalBytes) {
              console.log(
                `Upload progress ${Math.round(
                  (transferredBytes / totalBytes) * 100
                )} %`
              );
            }
            let percentage = Math.round((transferredBytes / totalBytes) * 100);
            let url =
              `https://${bucketName}.s3.ap-south-1.amazonaws.com/public/userPhotos/` +
              number +
              ".jpg";
            console.log("Percentage : ", percentage);
            if (percentage === 100) {
              console.log("Percentage is 100");
              handleInputPlayerChange("PhotoURL", url);
            }
            handleSetProfilePic(percentage, "UploadedPercentage");
            handleSetProfilePic(url, "Url");
          },
        },
        // contentType: 'image/png' // contentType is optional
      });
    } catch (err) {
      console.log("Error uploading file:", err);
    }
  }

  const handleImageSelection = (uri, kindOfFile) => {
    if (kindOfFile == "logo") {
      console.log("uri", uri);
      setTeamLogoURL(uri);

      uploadLogoFileToStorage(uri);
      console.log("uri.length", uri.length);
      setSelectedLogoSize(uri.length);

      console.log("selectedLogoSize", selectedLogoSize);
    } else if (kindOfFile == "profilePic") {
      uploadProfilePicToStorage(uri);
    } else {
      setSelectedLogoSize(0);

      console.log("selectedLogoSize", selectedLogoSize);
    }
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (date) => {
    const formattedDate = formatDate(date);
    const updatedPlayer = { ...playersList, DOB: formattedDate };
    console.log("updatedPlayer", updatedPlayer);
    setPlayersList(updatedPlayer);
  };

  const handleDuplicatePlayer = async () => {
    const subscription = DataStore.observeQuery(TeamRegistrations, (c) =>
      c.Players?.playerMaster?.PlayerData?.UUID?.eq(uuid)
    ).subscribe(async (snapshot) => {
      const playerTeamRegistration = snapshot.items;
      console.log("playerTeamRegistration", playerTeamRegistration);
      // check current team id with playerTeamRegistration

      const checkFilterTeamId = playerTeamRegistration.filter(
        (data) => data.id == teamId
      );
      console.log("checkFilterTeamId", checkFilterTeamId);

      if (checkFilterTeamId.length > 0) {
        setIsDuplicatePlayerFound(true);
        setErrorModalVisible(true);
        setErrorModalMesg(
          "The logged-in user is already registered with this team !"
        );
      } else {
        setIsDuplicatePlayerFound(false);
        setErrorModalVisible(false);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  };

  let teamRegistrationsPlayerMaster,
    userMasterTournament,
    playerSummaryData,
    playerUserMasterData;

  const handleTeamPlayerSubmit = async () => {
    console.log("Teamid after submit", teamID);

    await areTeamAndPlayersFieldsFilled();

    try {
      setLoading(true);

      if (!hasErrors) {
        // Step 1 : Check the player mobile number is already exist or not otherwise create new user and follow the next process

        // if playerMaster is not empty then create playerMaster and usermaster creation

        if (playersList.MobileNo !== "") {
          let res = await cognitoUserDetails("+91" + playersList.MobileNo);
          console.log("res", res);

          if (res.statusCode === 400) {
            console.log("No player found ", res.statusCode);
            console.log(
              "Name : ",
              playersList.Name,
              "PhotoURL : ",
              playersList.PhotoURL,
              "Mobile No : ",
              playersList.MobileNo
            );

            const resAddPlayer = await addUser("+91" + playersList.MobileNo);
            console.log("resAddPlayer : ", resAddPlayer);
            playersList.UUID = resAddPlayer?.User?.Username;
            console.log("Player ", "UUID ", playersList.UUID);
          } else {
            // WHEN NEW MOBILE NO IS FOUND THEN THE BELOW BLOCK OF CODE WILL NOT EXECUTE
            playersList.UUID = res.Username;
            console.log(" playersList.UUID", playersList.UUID);

            // checking if user uuid is already exist or not
            await getUserMasterData(playersList.UUID).then(async (value) => {
              console.log("value", value);
              if (value?.userMasterPlayerSummaryId == "") {
                console.log(
                  "value",
                  value,
                  "value.userMasterPlayerSummaryId",
                  value.userMasterPlayerSummaryId
                );
                await generateSerialNumber();
                try {
                  playerSummaryData = await DataStore.save(
                    new PlayerMaster({
                      Name: playersList.Name,
                      PhotoURL: playersList.PhotoURL,
                      JerseyNo: playersList.JerseyNo,
                      DateOfBirth: playersList.DOB,
                      PlayerData: value,
                      SrNo: playerSerialNumber,
                    })
                  );

                  console.log("Player Summary Data : ", playerSummaryData);
                  console.log(
                    "Player Summary Data Id : ",
                    playerSummaryData.id
                  );
                } catch (e) {
                  console.log("Player Summary Error : ", e);
                }
              }
            });
          }
          await getUserMasterData(playersList.UUID).then(async (response) => {
            if (response) {
              console.log("Player ", " Data", response);
              playerUserMasterData = response;
              console.log("playerUserMasterData", playerUserMasterData);

              if (playerUserMasterData.userMasterPlayerSummaryId == "") {
                await generateSerialNumber();
                try {
                  playerSummaryData = await DataStore.save(
                    new PlayerMaster({
                      Name: playersList.Name,
                      PhotoURL: playersList.PhotoURL,
                      JerseyNo: playersList.JerseyNo,
                      DateOfBirth: playersList.DOB,
                      PlayerData: playerUserMasterData,
                      SrNo: playerSerialNumber,
                    })
                  );

                  console.log("Player Summary Data : ", playerSummaryData);
                  console.log(
                    "Player Summary Data Id : ",
                    playerSummaryData.id
                  );
                } catch (e) {
                  console.log("Player Summary Error : ", e);
                }
              } else {
                playerSummaryData =
                  playerUserMasterData.userMasterPlayerSummaryId;
                console.log("Player Summary Data : ", playerSummaryData);
                console.log("Player Summary Data Id : ", playerSummaryData.id);
              }
            } else {
              console.log("Response not found");
              playerUserMasterData = response;
              console.log("playerUserMasterData", playerUserMasterData);
              // if user is not found then proceed with the below functionality
              await generateSerialNumber();
              try {
                playerSummaryData = await DataStore.save(
                  new PlayerMaster({
                    Name: playersList.Name,
                    PhotoURL: playersList.PhotoURL,
                    DateOfBirth: playersList.DOB,
                    JerseyNo: playersList.JerseyNo,
                    SrNo: playerSerialNumber,
                  })
                );

                console.log("playerSummaryData", playerSummaryData);
                setPlayerSummaryData(playerSummaryData);

                playerUserMasterData = await DataStore.save(
                  new UserMaster({
                    UUID: playersList.UUID,
                    Name: playersList.Name,
                    ContactNo: Number(playersList.MobileNo),
                    EmailId: "",
                    PhotoURL: playersList.PhotoURL,
                    InstagramId: playersList.InstagramId,
                    CreatedBy: userData.Name,
                    LastUpdatedBy: userData.Name,
                    RoleCoach: false,
                    RolePlayer: true,
                    PlayerSummary: playerSummaryData,
                  })
                );
                console.log("UserMaster Player: ", playerUserMasterData);

                const updatePlayerMasterPlayerDataUUID = {
                  playerMasterPlayerDataUUID: playerUserMasterData.UUID,
                };
                console.log(
                  "updatePlayerMasterPlayerDataUUID",
                  updatePlayerMasterPlayerDataUUID
                );

                let updatedItem;
                const original = await DataStore.query(
                  PlayerMaster,
                  playerUserMasterData.userMasterPlayerSummaryId
                );
                try {
                  if (updatePlayerMasterPlayerDataUUID) {
                    console.log(
                      "Current Item : ",
                      updatePlayerMasterPlayerDataUUID
                    );
                    updatedItem = PlayerMaster.copyOf(original, (ele) => {
                      ele.playerMasterPlayerDataUUID =
                        updatePlayerMasterPlayerDataUUID.playerMasterPlayerDataUUID;
                    });
                    await DataStore.save(updatedItem);

                    console.log("Updated Item:", updatedItem);
                  } else {
                    console.error("Item not found");
                  }
                } catch (error) {
                  console.error("Error updating item:", error);
                }
              } catch (e) {
                console.log("Error : ", e);
              }

              try {
                userMasterTournament = await DataStore.save(
                  new UserMasterTournament({
                    userMasterUUID: playerUserMasterData?.UUID,
                    tournamentId: tournamentID,
                  })
                );
                console.log("userMasterTournament", userMasterTournament);
              } catch (err) {
                console.log("Error in UserMasterTournament : ", err);
              }
            }
          });
        } else {
          // else condition if mobile number is empty

          try {
            await generateSerialNumber();
            try {
              playerSummaryData = await DataStore.save(
                new PlayerMaster({
                  Name: playersList.Name,
                  PhotoURL: playersList.PhotoURL,
                  JerseyNo: playersList.JerseyNo,
                  DateOfBirth: playersList.DOB,
                  SrNo: playerSerialNumber,
                })
              );

              console.log("Player Summary Data : ", playerSummaryData);
              console.log("Player Summary Data Id : ", playerSummaryData.id);
            } catch (e) {
              console.log("Player Summary Error : ", e);
            }
          } catch (error) {
            console.log("Error in playerSummaryData ", error);
          }
        }

        // Step: 3 Functionality for TeamRegistrationPlayerMaster
        console.log(
          "before team registration update",
          teamID,
          "playerSummaryData",
          playerSummaryData,
          ""
        );
        if (teamID && playerSummaryData) {
          try {
            teamRegistrationsPlayerMaster = await DataStore.save(
              new TeamRegistrationsPlayerMaster({
                teamRegistrationsId: teamID,
                playerMasterId: playerSummaryData.id,
              })
            );
            console.log(
              "teamRegistrationsPlayerMaster",
              teamRegistrationsPlayerMaster
            );
          } catch (error) {
            console.log("Error in TeamRegistrationsPlayerMaster : ", error);
          }
        } else {
          console.log("TeamID and  playerSummaryDataArray is not found");
        }
        // form submission completed
        setLoading(false);
        setSuccessModalVisible(true);
        setSuccessModalMesg(
          `Your details have been registered successfully! Keep visiting http://www.trackmytournament.com/ for more details about your tournament!`
        );
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("Error occured while filling up the form", error);
    }
  };

  const areTeamAndPlayersFieldsFilled = async () => {
    console.log("teamLogo", teamLogoURL);

    if (!playersList.Name) {
      console.log("isPlayerNameEmpty", true);
      setErrorModalMesg("Please provide the player's name!");
      setErrorModalVisible(true);
      hasErrors = true;
    }
    if (!playersList.DOB) {
      console.log("isDOBEmpty", true);
      setErrorModalMesg("Please provide the date of birth!");
      setErrorModalVisible(true);
      hasErrors = true;
    }
    if (!playersList.JerseyNo) {
      console.log("isPlayerJerseyNoEmpty", true);
      setErrorModalMesg("Please provide the player's jersey number!");
      setErrorModalVisible(true);
      hasErrors = true;
    }
    if (!playersList.PhotoURL) {
      console.log("isPlayerPhotoURLEmpty", true);
      setErrorModalMesg("Please provide the player's photo!");
      setErrorModalVisible(true);
      hasErrors = true;
    }
  };

  return (
    <Layout>
      <View style={styles.outerContainer}>
        {isTournamentIdValid && isTeamIdValid ? (
          <View style={styles.innerContainer}>
            <Text style={styles.headerText}>
              {`${
                tournamentName ? tournamentName : null
              } - Player Registration Form`}
            </Text>
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                gap: 10,
                marginTop: 15,
              }}
            >
              <Image
                source={{
                  uri: teamLogoURL ? teamLogoURL : defaultLogo,
                }}
                style={styles.teamLogo}
              />
              <View style={{ flex: 1 }}>
                <View>
                  <Text style={styles.lableText}>Team Name</Text>
                  <Text style={styles.teamName}>
                    {teamName ? teamName : null}
                  </Text>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    gap: 10,
                    flexWrap: "wrap",
                    marginTop: 10,
                  }}
                >
                  <View style={styles.halfItem}>
                    <Text style={styles.lableText}>Coach Name</Text>
                    <Text style={styles.text}>
                      {coachName ? coachName : null}
                    </Text>
                  </View>
                  <View style={styles.halfItem}>
                    <Text style={styles.lableText}>Coach Mobile No.</Text>
                    <Text style={styles.text}>
                      {coachMobileNo ? coachMobileNo : null}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.playerCard}>
              <Text style={[styles.headerText, { textAlign: "left" }]}>
                Register as player
              </Text>
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  gap: 10,
                  marginTop: 15,
                }}
              >
                <View>
                  <UploadImage
                    onImageSelect={handleImageSelection}
                    kindOfFile="profilePic"
                    // index={i}
                  />
                  <Text style={styles.lableText}>
                    Upload Player Photo
                    <Text style={{ color: colors.red }}>*</Text>
                  </Text>
                  {playersList.PhotoURL == "" ? (
                    <Text style={{ color: "red", textAlign: "center" }}>
                      Required
                    </Text>
                  ) : null}
                </View>
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    gap: 10,
                    flexWrap: "wrap",
                    marginTop: 10,
                  }}
                >
                  <View style={styles.halfItem}>
                    <Text style={styles.lableText}>
                      Player Name <Text style={{ color: colors.red }}>*</Text>
                    </Text>
                    <TextInput
                      editable={true}
                      style={styles.playerInput}
                      placeholder={"Enter Player Name"}
                      onChangeText={(text) =>
                        handleInputPlayerChange("Name", text)
                      }
                      value={playersList.Name}
                    />
                    {playersList.Name == "" ? (
                      <Text style={{ color: "red" }}>Required</Text>
                    ) : null}
                  </View>

                  <View style={styles.halfItem}>
                    <Text style={styles.lableText}>Mobile No.</Text>
                    <TextInput
                      editable={true}
                      style={styles.playerInput}
                      placeholder={"Enter Mobile No."}
                      onChangeText={(text) =>
                        handleInputPlayerChange("MobileNo", text)
                      }
                      value={playersList.MobileNo}
                      // value={userMobileNumber}
                      inputMode="numeric"
                      maxLength={10}
                    />

                    {playersList &&
                    playersList.MobileNo &&
                    (playersList.MobileNo.length < 10 ||
                      playersList.MobileNo.length > 10) ? (
                      <Text style={{ color: "red" }}>Invalid Number</Text>
                    ) : null}
                  </View>

                  <View style={styles.halfItem}>
                    <Text style={styles.lableText}>
                      Date of Birth <Text style={{ color: colors.red }}>*</Text>
                    </Text>
                    <View>
                      {/* <CustomDateField
                      label={undefined}
                      containerStyle={undefined}
                      placeholder={"Select Date"}
                      isRequired={true}
                      value={playersList?.DOB}
                      onChangeText={(e) => {
                        const newList = { ...playersList };
                        newList.DOB = e.target.value;
                        setPlayersList(newList);
                      }}
                      onBlur={undefined}
                      id={undefined}
                    /> */}

                      <CustomDatePicker
                        selected={playersList?.DOB}
                        onChange={(date) => handleDateChange(date)}
                        containerStyle={{
                          height: 30,
                          width: 140,
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: colors.royalBlue,
                          borderColor: colors.borderColor,
                          borderWidth: 1,
                          borderRadius: 5,
                        }}
                        portalId={undefined}
                      />

                      {playersList.DOB == "" ? (
                        <Text style={{ color: "red" }}>Required</Text>
                      ) : null}
                    </View>
                  </View>
                  <View style={styles.halfItem}>
                    <Text style={styles.lableText}>
                      Jersey No
                      <Text style={{ color: colors.red }}>*</Text>
                    </Text>
                    <TextInput
                      editable={true}
                      style={styles.playerInput}
                      placeholder={"JerseyNo"}
                      inputMode="numeric"
                      onChangeText={(text) =>
                        handleInputPlayerChange("JerseyNo", text)
                      }
                      value={playersList?.JerseyNo}
                    />
                    {playersList.JerseyNo == "" || null ? (
                      <Text style={{ color: "red" }}>Required</Text>
                    ) : null}
                  </View>

                  <View style={styles.halfItem}>
                    <Text style={styles.lableText}>Instagram Id</Text>
                    <TextInput
                      editable={true}
                      style={styles.playerInput}
                      placeholder={"InstagramId"}
                      onChangeText={(text) =>
                        handleInputPlayerChange("InstagramId", text)
                      }
                      value={playersList?.InstagramId}
                    />
                    {/* {playersList.InstagramId == "" || null ? (
                    <Text style={{ color: "red" }}>Required</Text>
                  ) : null} */}
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                marginTop: 15,
                flexDirection: "row",
                justifyContent: "center",
                gap: 20,
              }}
            >
              <Pressable style={styles.btnContainer} onPress={handleClose}>
                <Text style={styles.btnText}>Cancel</Text>
              </Pressable>
              <Pressable
                style={[
                  styles.btnContainer,
                  { backgroundColor: colors.aqua, borderColor: colors.aqua },
                ]}
                onPress={handleTeamPlayerSubmit}
              >
                <Text
                  style={[
                    styles.btnText,
                    {
                      color: colors.royalBlue,
                      fontFamily: "PlusJakartaSansSemiBold",
                    },
                  ]}
                >
                  Submit
                </Text>
              </Pressable>
            </View>
          </View>
        ) : (
          <Text style={{ color: colors.white, textAlign: "center" }}>
            URL is invalid !! Please enter valid URL !!
          </Text>
        )}

        {/* Loader */}
        {loading && (
          <Modal transparent animationType="none">
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <ActivityIndicator size="large" color="#0000ff" />
              <Text style={{ color: "white" }}>Loading</Text>
            </View>
          </Modal>
        )}

        {successModalVisible == true ? (
          <SuccessModalNew
            visibleModal={successModalVisible}
            SuccessMessage={successModalMesg}
            handleOk={handleOk}
            handleClose={undefined}
            tournamentId={undefined}
            teamId={undefined}
          />
        ) : null}

        {errorModalVisible == true ? (
          <ErrorModal
            visibleModal={errorModalVisible}
            SuccessMessage={errorModalMesg}
            header={undefined}
            onSubmit={handleSubmitError}
          />
        ) : null}
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create({
  outerContainer: {
    width: "100%",
    minHeight: 500,
    padding: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  innerContainer: {
    backgroundColor: colors.royalBlue,
    borderRadius: 15,
    padding: 10,
    width: "100%",
    maxWidth: 700,
  },
  headerText: {
    color: colors.orange,
    fontFamily: "PlusJakartaSansMedium",
    textAlign: "center",
    fontSize: 16,
  },
  teamLogo: {
    width: 100,
    height: 100,
    borderRadius: 100,
    resizeMode: "contain",
  },
  teamName: {
    color: colors.white,
    fontSize: 15,
    fontFamily: "PlusJakartaSansMedium",
  },
  lableText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansExtraLight",
    fontSize: 12,
  },
  text: {
    color: colors.white,
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 14,
  },
  halfItem: {
    flex: 1,
    maxWidth: "50%",
    minWidth: "45%",
    gap: 5,
  },
  playerCard: {
    borderColor: colors.white,
    borderWidth: 1,
    borderRadius: 10,
    marginTop: 10,
    padding: 10,
  },
  playerInput: {
    borderColor: colors.borderColor,
    color: colors.white,
    backgroundColor: colors.bgColor,
    borderWidth: 1,
    height: 30,
    width: "100%",
    borderRadius: 5,
    paddingHorizontal: 5,
    fontFamily: "PlusJakartaSansExtraLight",
    fontSize: 13,
  },
  btnContainer: {
    width: 120,
    height: 30,
    borderColor: colors.white,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderRadius: 7,
  },
  btnText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
    fontSize: 12,
  },
});

export default PlayerRegistration;
