import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import {
  View,
  Platform,
  ScrollView,
  Text,
  StyleSheet,
  Pressable,
  Image,
  Dimensions,
  Linking,
  Modal,
  ActivityIndicator,
} from "react-native";
import {
  H2,
  H3,
  CommonHeading,
} from "../../../components/reusable/TextComponent";
import Graph from "react-native-vector-icons/Entypo";
import Play from "react-native-vector-icons/Entypo";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { DataStore } from "aws-amplify/datastore";
import {
  MatchMaster,
  RoundMaster,
  TeamRegistrations,
  Tournament,
  UserMaster,
  UserMasterTournament,
} from "../../../models";
import DashBoardTR from "./SubComp/DashBoardTR";
import NewUserDashboard from "./SubComp/NewUserDashboard";
import AdminLayout from "../../../components/include/AdminLayout";
import { formatDate } from "../../../components/FormattedDate";
import Home from "../../view/Home/Home";
import { colors } from "../../../components/reusable/colour";
const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;
import { LinearGradient } from "expo-linear-gradient";
import { ErrorModal } from "../../../components/reusable/AreYouSure";
import Registration from "../Tournament/SubComp/Registration";
import Matches from "../../view/Home/SubComp/Matches";
import HomeTournaments from "../../view/Home/SubComp/HomeTournaments";
import HeroSection from "../../view/Home/SubComp/HeroSection";
import HowToUseApp from "../../view/Home/SubComp/HowToUseApp";
import Counter from "../../view/Home/SubComp/Counter";
import TournamentSetup from "../../view/Home/SubComp/TournamentSetup";
const icon1: any = require("../../../../assets/Admin/dashboard/icon/1.png");
const icon2: any = require("../../../../assets/Admin/dashboard/icon/2.png");
const icon3: any = require("../../../../assets/Admin/dashboard/icon/3.png");
const icon4: any = require("../../../../assets/Admin/dashboard/icon/4.png");
const homeLogo: any = require("../../../../assets/home/logo.png");
import ZoomableImage from "../../../components/reusable/ZoomableImage";

const Dashboard = () => {
  const navigation: any = useNavigation();
  // const Finaldata = route.params.data;
  // const TournamentCurrentData = AsyncStorage.getItem("CurrentTournament");
  // console.log("Finaldata", route, Finaldata);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [TournamentData, setTournamentData] = useState([]);
  const [TodayDate, setTodayDate]: any = useState();
  const [TeamListData, setTeamListData] = useState([]);
  const [TournamentObj, setTournamentObj]: any = useState({});
  const [UserDetails, setUserDetails]: any = useState({});
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorModalMesg, setErrorModalMesg] = useState("");
  const Defaultlogo =
    "https://trackmytournament-dev125444-dev.s3.ap-south-1.amazonaws.com/public/default_images/football-club.png";

  const img1 = "../../../../assets/Admin/dashboard/icon/1.png";
  const img2 = "../../../../assets/Admin/dashboard/icon/2.png";
  const img3 = "../../../../assets/Admin/dashboard/icon/3.png";
  const img4 = "../../../../assets/Admin/dashboard/icon/4.png";
  // const handleTabChange = (tabIndex: number) => {
  //   setActiveTab(tabIndex);
  // };

  const getTournamentObject = async (key) => {
    // if (Platform.OS == "web") {
    console.log("helloTournament", key);

    // }
    return new Promise(async (resolve) => {
      let FindPlayerdata;
      let subscription = await DataStore.observeQuery(Tournament, (c) =>
        c.id.eq(key)
      ).subscribe(async (snapshot) => {
        // if (snapshot.isSynced == true) {
        FindPlayerdata = snapshot.items;
        // if (Platform.OS == "web") {
        console.log("FindPlayerdata", FindPlayerdata, FindPlayerdata[0]);
        // }
        resolve(FindPlayerdata[0]);
        // }
      });
      return () => {
        subscription.unsubscribe();
      };
    });
  };

  // const getUserdatas = async () => {
  //   const userdata: any = await DataStore.query(
  //     Tournament,
  //     "a9ed7b65-f3ca-4d3c-853d-b85ce0c23dae"
  //   );
  //   let Roundsdata = await [userdata.Rounds];
  //   let final = await Roundsdata[0].values;
  //   console.log("Roundsdata", final);
  //   let registration = await [userdata.Registrations];
  //   let registrationdata = await registration[0].values;
  //   console.log("registrationdata", registrationdata);
  // };

  const handleMatchdatas = async (matchdata) => {
    // console.log("RoundData 1", RoundData);
    // let Matchpromise: any = await [RoundData.Matches];
    // let matches = await Matchpromise[0].values;
    let matches = matchdata;
    let data = {};
    let arrayOfMatches =
      matches &&
      matches.length > 0 &&
      matches.map(async (ele) => ({
        ...ele,
        Team1: await ele.Team1,
        Team2: await ele.Team2,
        // Team1Score:
        //   (await ele.Team1.Score.FirstHalf) +
        //   (await ele.Team1.Score.SecondHalf) +
        //   (await ele.Team1.Score.ExtraTime),
        // Team2Score:
        //   (await ele.Team2.Score.FirstHalf) +
        //   (await ele.Team2.Score.SecondHalf) +
        //   (await ele.Team2.Score.ExtraTime),
      }));
    let final = [];
    let AllData = [];

    if (arrayOfMatches && arrayOfMatches.length > 0) {
      final = await Promise.all(arrayOfMatches);
    }

    if (Platform.OS == "web") {
      console.log("AAAAAAAA", final);
    }
    let finalArry =
      final &&
      final.length > 0 &&
      final.map(async (ele) => ({
        // console.log(
        //   "Team1Score",
        //   ele.Team1.Score !== null ? ele.Team1.Score : 0
        // );
        // console.log(
        //   "Team2Score",
        //   ele.Team2.Score !== null ? ele.Team2.Score : 0
        // );
        ...ele,
        Team1Score:
          ele.Team1.Score !== null
            ? ele.Team1.Score.FirstHalf +
              ele.Team1.Score.SecondHalf +
              ele.Team1.Score.ExtraTime
            : 0,
        Team2Score:
          ele.Team2.Score !== null
            ? ele.Team2.Score.FirstHalf +
              ele.Team2.Score.SecondHalf +
              ele.Team2.Score.ExtraTime
            : 0,
      }));

    if (Platform.OS == "web") {
      console.log("Matchfinal", finalArry);
    }

    if (finalArry && finalArry.length > 0) {
      AllData = await Promise.all(finalArry);
    }

    if (Platform.OS == "web") {
      console.log("AllData", AllData);
    }
    let reorderedArray = [];
    if (AllData && AllData.length > 0) {
      reorderedArray = [
        ...AllData.filter((obj) => obj.Status === "INPROGRESS"),
        ...AllData.filter((obj) => obj.Status !== "INPROGRESS"),
      ];
    }

    if (reorderedArray && reorderedArray.length > 0) {
      data = reorderedArray.find((ele) => ele.Status == "INPROGRESS");
      if (Platform.OS == "web") {
        console.log("InProgressMatch", data);
      }
    }
    if (Platform.OS == "web") {
      console.log("particularMatch", matches);
      console.log("matches.length", matches.length);
    }
    let Matchobj = {
      AllMatch: AllData.length > 0 ? reorderedArray : [],
      InprogresssMatch: data !== undefined ? data : {},
    };
    if (Platform.OS == "web") {
      console.log("Matchobj", Matchobj);
    }
    return Matchobj;
  };

  const handleSubmit = () => {
    setErrorModalVisible(false);
  };

  const handleRegistrationdata = async (data) => {
    // console.log("RoundData", RoundData);
    // let registrationPromise: any = await [RoundData.Registrations];
    // let Regdata = await registrationPromise[0].values;
    // console.log("Regdata", Regdata);
    let Regdata = await DataStore.query(TeamRegistrations, (registration) =>
      registration.Tournament.contains(data.id)
    );
    console.log("containsdata", Regdata);
    let filterdaata = [];
    let UnPaid = [];
    let Submitdata = [];
    if (Regdata && Regdata.length > 0) {
      Submitdata = Regdata.filter((ele) => ele.Status === "SUBMITTED");
      filterdaata = Regdata.filter((ele) => ele.Status === "APPROVED");
      UnPaid = Regdata.filter((ele) => ele.PaymentStatus === "UNPAID");
    }

    let obj = {
      Submitdata: Submitdata.length,
      Approveddata: filterdaata.length,
      Pendingdata: UnPaid.length,
      Alldata: Regdata.length,
    };

    return obj;
  };

  const getDataRound = async (data) => {
    if (Platform.OS == "web") {
      console.log("tournmentObj", data);
    }

    // let RoundPromise: any = await [data.Rounds];
    // let Data = await RoundPromise[0].values;

    let Data = await DataStore.query(RoundMaster, (Roundsdata) =>
      Roundsdata.Tournament.contains(data.id)
    );
    console.log("Rounddatas", Data);
    if (Platform.OS == "web") {
      console.log("RoundPromise", Data);
    }

    let abbreviation = "";
    Data &&
      Data.length > 0 &&
      Data.forEach((item) => {
        const words = item.Format.split("_");
        abbreviation += words.map((word) => word.charAt(0)).join("");
        abbreviation += "+";
      });

    // Remove the extra '+' character at the end
    abbreviation = abbreviation.slice(0, -1);
    return abbreviation;
  };

  const handlegetAdminflag = (TournamentObj, userdata) => {
    let Adminflag = false;
    // if (Platform.OS == "web") {
    console.log("handlegetAdminflag", userdata.UUID, TournamentObj);
    // }

    if (
      TournamentObj.Admin1 !== null &&
      TournamentObj.Admin1.UUID == userdata.UUID
    ) {
      Adminflag = true;
    } else if (
      TournamentObj.Admin2 !== null &&
      TournamentObj.Admin2.UUID == userdata.UUID
    ) {
      Adminflag = true;
    } else if (
      TournamentObj.Admin3 !== null &&
      TournamentObj.Admin3.UUID == userdata.UUID
    ) {
      Adminflag = true;
    }
    return Adminflag;
  };

  const getUserdata = async () => {
    const UserDetail = await AsyncStorage.getItem("UserDetail");
    console.log("UserDetail", UserDetail);
    try {
      setLoading(true);
      const userdata = await DataStore.query(UserMaster, UserDetail);
      console.log("userdata", userdata);
      setUserDetails(userdata);

      // let uniqueArray = [];
      // let tournamentdata: any = await [userdata.UserTournaments];
      // if (Platform.OS == "web") {
      //   console.log("Roundsdata", tournamentdata);
      // }
      const TournamentData = await DataStore.query(Tournament, (tournament) =>
        tournament.Users.userMaster.UUID.eq(userdata.UUID)
      );
      console.log("FinalDataofTournament", TournamentData);
      const ListofTeams = await DataStore.query(
        TeamRegistrations,
        (tournament) => tournament.Admins.userMaster.UUID.eq(userdata.UUID)
      );
      console.log("ListofTeams", ListofTeams);
      let TeamsTournamentdata =
        ListofTeams &&
        ListofTeams.length > 0 &&
        ListofTeams.map(async (ele) => ({
          ...ele,
          Tournament: await DataStore.query(Tournament, ele.Tournament),
          TournamentAdminflag: await handlegetAdminflag(
            await DataStore.query(Tournament, ele.Tournament),
            userdata
          ),
        }));
      let FinalTeamTournamentData = [];
      if (ListofTeams && ListofTeams.length > 0) {
        FinalTeamTournamentData = await Promise.all(TeamsTournamentdata);
      }

      if (FinalTeamTournamentData && FinalTeamTournamentData.length > 0) {
        console.log("FinalTeamTournamentData", FinalTeamTournamentData);
        let data = FinalTeamTournamentData.filter(
          (ele) => ele.TournamentAdminflag == false
        );
        console.log("finalteamlist", data);
        const Teamlist = data.filter(
          (obj, index, self) =>
            index ===
            self.findIndex((o) => o.Tournament.id === obj.Tournament.id)
        );
        console.log("finalteamlist", Teamlist);
        setTeamListData(Teamlist);
      }

      let Turnamentlist = [];
      try {
        let data =
          TournamentData &&
          TournamentData.length > 0 &&
          TournamentData.map(async (ele) => ({
            Tournamentdata: ele,
            TournamentAdminflag: await handlegetAdminflag(ele, userdata),
            match: await handleMatchdatas(
              await DataStore.query(MatchMaster, (match) =>
                match.tournamentData.id.eq(ele.id)
              )
            ),
            RegistrationObject: await handleRegistrationdata(ele),
            RoundData: await getDataRound(ele),
          }));
        if (TournamentData && TournamentData.length > 0) {
          Turnamentlist = await Promise.all(data);
        } else {
          Turnamentlist = [];
        }
      } catch (error) {
        console.log("Errorvalue", error);
      }
      console.log("Turnamentlist", Turnamentlist);
      if (Turnamentlist && Turnamentlist.length > 0) {
        const reorderedArray = [
          ...Turnamentlist.filter(
            (ele) => Object.keys(ele.match.InprogresssMatch).length !== 0
          ),
          ...Turnamentlist.filter(
            (ele) => Object.keys(ele.match.InprogresssMatch).length == 0
          ),
        ];
        console.log("reorderedArray", reorderedArray);
        const uniqueArrlistv = reorderedArray.filter(
          (obj, index, self) =>
            index ===
            self.findIndex((o) => o.Tournamentdata.id === obj.Tournamentdata.id)
        );
        console.log("uniqueArrlistv", uniqueArrlistv);
        setTournamentData(uniqueArrlistv);
        setLoading(false);
      } else {
        setTournamentData([]);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("JSON Parse error:", error.message);
    }
  };

  const handleSelectTournament = async (Tournamentobj) => {
    if (Platform.OS == "web") {
      console.log(" Tournamentobj", Tournamentobj);
    }
    await AsyncStorage.setItem(
      "CurrentTournament",
      Tournamentobj.Tournamentdata.id
    );
    setTournamentObj(Tournamentobj);
    navigation.navigate("ManageTournament", {
      tournamentId: Tournamentobj.Tournamentdata.id,
    });
  };

  // const handlegetdate = (date) => {
  //   console.log("hellodate", date);
  //   const dateString = date !== undefined && date !== null ? date : "";
  //   if (date !== undefined && date !== null) {
  //     console.log("AAAA");
  //     const dateParts = dateString.includes("/")
  //       ? dateString.split("/").map(Number)
  //       : dateString.split("-").map(Number);

  //     const [day, month, year] = dateParts;

  //     // Create a Date object
  //     const dateObject = new Date(year, month - 1, day); // Month is zero-indexed

  //     // Format the date as "Do MMM YY"
  //     const formattedDate = dateObject.toLocaleDateString("en-US", {
  //       day: "numeric",
  //       month: "short",
  //       year: "2-digit",
  //     });

  //     // Create the ordinal suffix for the day
  //     const ordinalDay =
  //       day +
  //       (day % 10 === 1 && day !== 11
  //         ? "st"
  //         : day % 10 === 2 && day !== 12
  //         ? "nd"
  //         : day % 10 === 3 && day !== 13
  //         ? "rd"
  //         : "th");

  //     return `${ordinalDay} ${formattedDate}`;
  //   } else {
  //     console.log("BBBB");
  //     return dateString;
  //   }
  // };

  const getTodayDate = () => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const date = new Date();
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const formattedDate = `${day} ${months[monthIndex]}`;
    if (Platform.OS == "web") {
      console.log(formattedDate); // Output will be something like "19 Feb" for February 19th}
    }
    setTodayDate(formattedDate);
  };

  // const handlegetdateformat = (inputDate: string) => {
  //   console.log("handlegetdateformat", inputDate);
  //   // if (inputDate.length > 0 || inputDate !== "" || inputDate !== null) {
  //   //   const [day, month, year] = inputDate.includes("/")
  //   //     ? inputDate.split("/")
  //   //     : inputDate.split("-");
  //   //   return `${year}/${month}/${day}`;
  //   // }
  // };

  const handleOnClickWatchMatch = async (LiveURL: string) => {
    if (LiveURL) {
      await Linking.openURL(LiveURL);
    } else {
      setErrorModalVisible(true);
      setErrorModalMesg("No Live URL is provided ");
      // alert("No Live URL is provided ");
    }
  };

  useEffect(() => {
    // setLoading(true);
    // console.log("Rajashri Test");
    // getUserdatas();
    getTodayDate();
    getUserdata();
    // setLoading(false);
    // getTournament();
  }, []);

  const handleMatchesResultDetailsNavigation = async (
    tournamentId: any,
    matchId: any
  ) => {
    if (Platform.OS == "web") {
      console.log("redirectmatch", tournamentId, matchId);
    }
    navigation.navigate("TournamentDetails", {
      tournamentId: tournamentId,
      matchId: matchId,
      initialTab: 2,
      showResultDetailsFromMatches: true,
      showResultPage: false,
      matchNavigation: true,
    });
    const matchesPropsObject = {
      tournamentId: tournamentId,
      matchId: matchId,
      initialTab: 2,
      showResultDetailsFromMatches: true,
      showResultPage: false,
      matchNavigation: true,
    };
    if (Platform.OS == "web") {
      console.log("matchesPropsObject", matchesPropsObject);
    }
    await AsyncStorage.setItem(
      "matchesResultDetailsAdmin",
      JSON.stringify(matchesPropsObject)
    );
  };

  return (
    <AdminLayout>
      <View style={styles.DashboardContentContainer}>
        {TeamListData.length === 0 && TournamentData.length === 0 ? (
          Platform.OS === "web" ? (
            <NewUserDashboard />
          ) : (
            <>
              <Home />
            </>
          )
        ) : null}
        {/* top section started */}
        {TournamentData &&
        TournamentData.length > 0 &&
        (UserDetails?.RoleCoach == false ||
          UserDetails?.RolePlayer == false) ? (
          <View style={styles.topContainer}>
            <View>
              <H2
                props={`Today, ${TodayDate}`}
                style={{ color: colors.white }}
              />
              <CommonHeading
                props={"Manage your Tournament"}
                style={{ marginTop: 15 }}
              />
            </View>
          </View>
        ) : null}

        {/* top section ended */}
        {/* tournament card started */}
        <View style={styles.tabContent}>
          <View style={{ width: "100%" }}>
            {TournamentData &&
              TournamentData.length > 0 &&
              TournamentData.map((obj, index) => {
                return obj.TournamentAdminflag == true ? (
                  <View style={{ marginBottom: 40 }} key={index}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection:
                          Platform.OS === "web" && screenWidth > 760
                            ? "row"
                            : "column",
                        gap: 15,
                      }}
                    >
                      <View style={styles.dashboard2TournamentCard}>
                        {obj.match.InprogresssMatch &&
                        Object.keys(obj.match.InprogresssMatch).length > 0 ? (
                          <Text
                            style={[
                              styles.matchCardBadge,
                              {
                                backgroundColor: "#FFCA0D",
                                color: colors.blue,
                              },
                            ]}
                          >
                            In Progress
                          </Text>
                        ) : null}
                        <Text style={styles.CardHeadingStyle}>
                          {obj.Tournamentdata.Name}
                        </Text>
                        <View style={styles.cardSmallDetailsWrapper}>
                          <Text style={styles.cardDetailsText}>
                            {/* {obj.Tournamentdata.StartDate} */}
                            {
                              // handlegetdateformat(
                              obj.Tournamentdata.StartDate
                              // )
                            }
                          </Text>
                          <Text style={styles.cardDetailsText}>
                            {obj.RoundData}
                          </Text>
                        </View>
                        <View style={styles.horizontalLine} />
                        <View style={styles.cardSmallDetailsWrapper}>
                          <Text style={styles.cardDetailsText}>
                            {obj.Tournamentdata.Type !== null
                              ? obj.Tournamentdata.Type
                              : ""}
                          </Text>

                          <Text style={styles.cardDetailsText}>
                            {obj.Tournamentdata.AgeGroup !== null
                              ? obj.Tournamentdata.AgeGroup
                              : ""}
                          </Text>
                        </View>
                        <View style={styles.horizontalLine} />
                        <View style={styles.cardSmallDetailsWrapper}>
                          {obj.Tournamentdata.City !== null ||
                          obj.Tournamentdata.State !== null ? (
                            <Text style={styles.cardDetailsText}>
                              {`${
                                obj.Tournamentdata?.Area !== null
                                  ? obj.Tournamentdata?.Area
                                  : ""
                              },${
                                obj.Tournamentdata?.City !== null
                                  ? obj.Tournamentdata?.City
                                  : ""
                              },${
                                obj.Tournamentdata?.State !== null
                                  ? obj.Tournamentdata?.State
                                  : ""
                              }`}
                            </Text>
                          ) : (
                            <Text style={styles.cardDetailsText}></Text>
                          )}
                          <Text style={styles.cardDetailsText}>
                            Entry Fee:{" "}
                            {obj.Tournamentdata.Fees !== null
                              ? obj.Tournamentdata.Fees
                              : "0"}
                          </Text>
                        </View>
                        <View
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {(UserDetails.RoleCoach == false &&
                            UserDetails.RolePlayer == false) ||
                          obj.TournamentAdminflag == true ? (
                            <Pressable
                              onPress={() => handleSelectTournament(obj)}
                              style={{
                                backgroundColor: colors.aqua,
                                width: 150,
                                height: 35,
                                borderRadius: 5,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginVertical: 10,
                              }}
                            >
                              <Text
                                style={{
                                  fontFamily: "PlusJakartaSansSemiBold",
                                  color: "#02205F",
                                }}
                              >
                                Manage
                              </Text>
                            </Pressable>
                          ) : null}
                        </View>
                      </View>

                      <View
                        style={{
                          backgroundColor: colors.royalBlue,
                          flex: 1,
                          borderRadius: 15,
                          borderWidth: 1,
                          borderColor: colors.royalBlue,
                          borderStyle: "solid",
                          shadowOffset: { width: 0, height: 0 },
                          shadowColor: "#0000003d",
                          shadowRadius: 10,
                          padding:
                            Platform.OS === "web" && screenWidth > 760 ? 15 : 6,
                        }}
                      >
                        <H3 props={"Updates"} style={{ color: colors.white }} />
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap:
                              Platform.OS === "web" && screenWidth > 760
                                ? 25
                                : 6,
                            marginTop: 20,
                            flexWrap: "wrap",
                          }}
                        >
                          <View style={styles.dashboardUpdateCard}>
                            <Image
                              // source={{ uri: img1 }}
                              source={require("../../../../assets/Admin/dashboard/icon/1.png")}
                              style={styles.dashboardUpdateIcon}
                            />
                            <View style={styles.cardTextNumberWrapper}>
                              <Text style={styles.updateCardNumber}>
                                {obj.RegistrationObject.Alldata}
                              </Text>
                              <Text style={styles.updateCardName}>
                                Total Registrations
                              </Text>
                            </View>
                          </View>
                          <View style={styles.dashboardUpdateCard}>
                            <Image
                              // source={{ uri: img2 }}
                              source={require("../../../../assets/Admin/dashboard/icon/2.png")}
                              style={styles.dashboardUpdateIcon}
                            />
                            <View style={styles.cardTextNumberWrapper}>
                              <Text style={styles.updateCardNumber}>
                                {obj.RegistrationObject.Submitdata}
                              </Text>
                              <Text style={styles.updateCardName}>
                                New Registrations
                              </Text>
                            </View>
                          </View>
                          <View style={styles.dashboardUpdateCard}>
                            <Image
                              // source={{ uri: img3 }}
                              source={require("../../../../assets/Admin/dashboard/icon/3.png")}
                              style={styles.dashboardUpdateIcon}
                            />
                            <View style={styles.cardTextNumberWrapper}>
                              <Text style={styles.updateCardNumber}>
                                {obj.RegistrationObject.Approveddata}
                              </Text>
                              <Text style={styles.updateCardName}>
                                Approved Registrations
                              </Text>
                            </View>
                          </View>
                          <View style={styles.dashboardUpdateCard}>
                            <Image
                              // source={{ uri: img4 }}
                              source={require("../../../../assets/Admin/dashboard/icon/4.png")}
                              style={styles.dashboardUpdateIcon}
                            />
                            <View style={styles.cardTextNumberWrapper}>
                              <Text style={styles.updateCardNumber}>
                                {obj.RegistrationObject.Pendingdata}
                              </Text>
                              <Text style={styles.updateCardName}>
                                Payment Pending
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>

                    {obj.match.AllMatch && obj.match.AllMatch.length > 0 ? (
                      <View
                        style={{
                          marginTop: 15,
                          flex: 1,
                        }}
                      >
                        <CommonHeading props={"Matches"} />
                        <ScrollView
                          horizontal
                          showsVerticalScrollIndicator={false}
                          showsHorizontalScrollIndicator={true}
                          style={{ flex: 1 }}
                        >
                          <View style={styles.dashboardMatchContainer}>
                            {/* new item */}

                            {obj.match.AllMatch.map((ele) => {
                              return (
                                <LinearGradient
                                  style={styles.matchCard}
                                  start={{ x: 0, y: 1 }}
                                  end={{ x: 1, y: 1 }}
                                  colors={
                                    ele.Status == "INPROGRESS"
                                      ? [colors.orange, colors.royalBlue]
                                      : [colors.royalBlue, colors.royalBlue]
                                  }
                                >
                                  <Text style={styles.matchCardHeadingWhite}>
                                    {obj.Tournamentdata.Name}
                                  </Text>
                                  <View style={styles.matchDetailsWrapper}>
                                    <View style={styles.matchTeamNAmeLogo}>
                                      <ZoomableImage
                                        imageSource={{
                                          uri:
                                            ele &&
                                            ele.Team1 &&
                                            ele.Team1.LogoURL !== null &&
                                            ele.Team1.LogoURL !== ""
                                              ? ele.Team1.LogoURL
                                              : Defaultlogo,
                                        }}
                                        imageStyle={styles.matchClubLogo}
                                        modalStyle={undefined}
                                      />
                                      <Text
                                        style={styles.matchCardDateTextWhite}
                                        numberOfLines={1}
                                      >
                                        {ele.Team1 ? ele.Team1.TeamName : null}
                                      </Text>
                                    </View>
                                    <View style={styles.matchScoreBadge}>
                                      <Text style={styles.matchScoreTextWhite}>
                                        {`${ele.Team1Score}-${ele.Team2Score}`}
                                      </Text>
                                      {ele.Status == "INPROGRESS" ? (
                                        <View
                                          style={{
                                            flexDirection: "row",
                                            alignItems: "center",
                                            gap: 3,
                                          }}
                                        >
                                          <View
                                            style={{
                                              width: 6,
                                              height: 6,
                                              borderRadius: 3,
                                              backgroundColor: "red",
                                            }}
                                          ></View>
                                          <Text
                                            style={{
                                              color: colors.white,
                                              fontFamily:
                                                "PlusJakartaSansMedium",
                                            }}
                                          >
                                            Live
                                          </Text>
                                        </View>
                                      ) : null}
                                    </View>
                                    <View style={styles.matchTeamNAmeLogo}>
                                      <ZoomableImage
                                        imageSource={{
                                          uri:
                                            ele &&
                                            ele.Team2 &&
                                            ele.Team2.LogoURL !== null &&
                                            ele.Team2.LogoURL !== ""
                                              ? ele.Team2.LogoURL
                                              : Defaultlogo,
                                        }}
                                        imageStyle={styles.matchClubLogo}
                                        modalStyle={undefined}
                                      />
                                      <Text
                                        style={styles.matchCardDateTextWhite}
                                        numberOfLines={1}
                                      >
                                        {ele.Team2 ? ele.Team2.TeamName : null}
                                      </Text>
                                    </View>
                                  </View>
                                  <View style={styles.matchCardDateWrapper}>
                                    <Text style={styles.matchCardDateTextWhite}>
                                      {ele.Date !== null ? ele.Date : ""}
                                    </Text>
                                    <Text style={styles.matchCardDateTextWhite}>
                                      {ele.Time !== null ? ele.Time : ""}
                                    </Text>
                                    <Text style={styles.matchCardDateTextWhite}>
                                      Balewadi
                                    </Text>
                                  </View>
                                  <View
                                    style={[
                                      styles.horizontalLine,
                                      { marginVertical: 10 },
                                    ]}
                                  />
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Pressable
                                      style={styles.matchCardRow}
                                      onPress={() => {
                                        handleMatchesResultDetailsNavigation(
                                          obj.Tournamentdata.id,
                                          ele.id
                                        );
                                      }}
                                    >
                                      <Graph
                                        name="bar-graph"
                                        size={16}
                                        color={colors.white}
                                      />
                                      <Text style={styles.matchCardBtnText}>
                                        View Details
                                      </Text>
                                    </Pressable>
                                    <Pressable
                                      style={styles.matchCardRow}
                                      onPress={() => {
                                        handleOnClickWatchMatch(
                                          ele.LiveURL ? ele.LiveURL : ""
                                        );
                                      }}
                                    >
                                      <Play
                                        name="controller-play"
                                        size={16}
                                        color={colors.white}
                                      />
                                      <Text style={styles.matchCardBtnText}>
                                        Watch Match
                                      </Text>
                                    </Pressable>
                                  </View>
                                </LinearGradient>
                              );
                            })}
                          </View>
                        </ScrollView>
                      </View>
                    ) : null}
                  </View>
                ) : null;
              })}
          </View>
        </View>
        {/* tournament card ended */}
        {TeamListData.length > 0 ? (
          <DashBoardTR ListOfTeam={TeamListData} />
        ) : null}
        {loading && (
          <Modal transparent animationType="none">
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <ActivityIndicator size="large" color="#0000ff" />
              <Text style={{ color: "white" }}>Loading</Text>
            </View>
          </Modal>
        )}
      </View>

      {errorModalVisible == true ? (
        <ErrorModal
          visibleModal={errorModalVisible}
          SuccessMessage={errorModalMesg}
          onSubmit={handleSubmit}
          header={"Attention"}
        />
      ) : null}
    </AdminLayout>
  );
};

const styles = StyleSheet.create({
  DashboardContentContainer: {
    padding: Platform.OS === "web" && screenWidth > 760 ? 20 : 10,
    paddingRight: Platform.OS == "web" && screenWidth > 760 ? undefined : 0, // only for app
    paddingBottom: Platform.OS == "web" && screenWidth > 760 ? undefined : 100,
  },
  topContainer: {
    display: "flex",
    flexDirection:
      Platform.OS === "web" && screenWidth > 760 ? "row" : "column-reverse",
    justifyContent: "space-between",
    marginBottom: 20,
    gap: 15,
  },
  topSearchBar: {
    width: 350,
    height: 40,
  },
  tabContainer: {
    flexDirection: "row",
    marginBottom: 20,
    gap: Platform.OS === "web" && screenWidth > 760 ? 20 : 10,
  },
  tabButton: {
    padding: 10,
    // marginHorizontal: 10,
    backgroundColor: "#eee",
    borderRadius: 5,
    width: Platform.OS === "web" && screenWidth > 760 ? 280 : 195,
  },
  activeTabButton: {
    backgroundColor: "#02205F",
  },
  tabButtonText: {
    color: "#333",
    textAlign: "center",
    fontFamily: "PlusJakartaSansRegular",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 17 : 14,
  },
  activeTabButtonText: {
    color: colors.white,
  },
  tabContent: {
    alignItems: "center",
  },
  shareButton: {
    borderColor: "#02205F",
    borderWidth: 2,
    width: 100,
    height: 35,
    borderRadius: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  shareButtonText: {
    color: "#02205F",
    fontSize: 16,
    fontFamily: "PlusJakartaSansSemiBold",
  },
  //
  homeTournamentCard: {
    backgroundColor: colors.white,
    width: Platform.OS === "web" && screenWidth > 760 ? 330 : "100%",
    borderRadius: 15,
    borderWidth: 1,
    borderColor: "#ddd",
    borderStyle: "solid",
    shadowOffset: { width: 0, height: 0 },
    shadowColor: "#0000003d",
    shadowOpacity: 0.4,
    shadowRadius: 10,
  },
  TournamentBannerImage: {
    width: "100%",
    height: 140,
    objectFit: "contain",
    // backgroundColor: "yellow",
    borderRadius: 15,
  },
  TournamentCardContentWrapper: {
    paddingHorizontal: 20,
  },
  TournamentCardHeadingWrapper: {
    backgroundColor: colors.white,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 10,
    shadowColor: "#00000026",
    shadowOpacity: 1,
    elevation: 3,
    paddingTop: 10,
    paddingRight: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    marginTop: -20,
    borderRadius: 15,
    marginBottom: 15,
  },
  CardHeadingStyle: {
    textAlign: "center",
    fontSize: 20,
    textTransform: "uppercase",
    fontWeight: "600",
    color: colors.white,
    marginBottom: 10,
  },
  CardOrganizerName: {
    textAlign: "center",
    fontSize: 15,
    fontWeight: "500",
    color: "#000",
  },
  cardSmallDetailsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 10,
    paddingRight: 0,
    paddingBottom: 10,
    paddingLeft: 0,
  },
  cardDetailsText: {
    fontSize: 14,
    fontWeight: "600",
    color: colors.white,
  },
  dashboardUpdateCard: {
    minWidth: "40%",
    maxWidth: "50%",
    flex: 1,
    height: 100,
    backgroundColor: colors.bgColor,
    shadowOffset: { width: 0, height: 0 },
    shadowColor: "#00000033",
    shadowRadius: 10,
    borderRadius: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: Platform.OS === "web" && screenWidth > 760 ? 20 : 6,
    padding: Platform.OS === "web" && screenWidth > 760 ? 15 : 5,
  },
  //
  dashboardUpdateIcon: {
    width: Platform.OS === "web" && screenWidth > 760 ? 50 : 40,
    height: Platform.OS === "web" && screenWidth > 760 ? 50 : 40,
  },
  updateCardNumber: {
    fontFamily: "PlusJakartaSansExtraBold",
    fontSize: Platform.OS === "web" && screenWidth > 760 ? 35 : 25,
    color: colors.orange,
  },
  updateCardName: {
    fontFamily: "PlusJakartaSansRegular",
    fontSize: 14,
    width: "100%",
    flexWrap: "wrap",
    color: colors.white,
  },
  cardTextNumberWrapper: {
    flex: 1,
  },
  //
  dashboardMatchContainer: {
    width: "100%",
    marginTop: 15,
    display: "flex",
    flexDirection: "row",
    gap: 25,
    flexWrap: "nowrap",
    // overflow: "scroll",
    paddingBottom: 20,
  },
  matchCard: {
    // backgroundColor: "#F4F4F4",
    padding: 15,
    width: 350,
    borderRadius: 10,
    shadowOffset: {
      width: 3,
      height: 4,
    },
    shadowColor: "#000000",
    shadowOpacity: 0.22,
    shadowRadius: 10,
    elevation: 3,
  },

  matchCardHeadingWhite: {
    color: colors.white,
    textAlign: "center",
    fontSize: 16,
    marginBottom: 15,
    fontFamily: "PlusJakartaSansSemiBold",
  },
  matchClubLogo: {
    width: 60,
    height: 60,
    borderRadius: 40,
  },
  matchDetailsWrapper: {
    flexDirection: "row",
    gap: 10,
    justifyContent: "space-between",
  },
  matchScoreBadge: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: 85,
    gap: 5,
  },
  matchScoreText: {
    fontSize: 20,
    fontWeight: "600",
  },
  matchScoreTextWhite: {
    fontSize: 20,
    fontWeight: "600",
    color: colors.white,
  },
  matchScoreStatus: {
    backgroundColor: "#00175b",
    color: colors.white,
    paddingVertical: 2,
    paddingHorizontal: 5,
  },
  matchCardDateWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: 5,
    marginTop: 15,
  },

  matchCardDateTextWhite: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
  },
  fixtureControlContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#00175b",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    gap: 5,
    paddingVertical: 8,
    paddingHorizontal: 15,
    marginTop: 15,
  },
  // fixtureControl: {
  //   color: colors.white,
  //   fontFamily: "PlusJakartaSansRegular",
  // },
  matchCardBadge: {
    position: "absolute",
    top: -10,
    right: 0,
    borderRadius: 4,
    paddingHorizontal: 10,
    paddingVertical: 3,
    fontFamily: "PlusJakartaSansSemiBold",
  },
  dashboard2TournamentCard: {
    width: Platform.OS === "web" && screenWidth > 760 ? 450 : "100%",
    padding: 20,
    paddingTop: 30,
    borderRadius: 15,
    borderWidth: 1,
    borderColor: colors.royalBlue,
    borderStyle: "solid",
    shadowOffset: { width: 0, height: 0 },
    shadowColor: "#0000003d",
    shadowOpacity: 0.4,
    shadowRadius: 10,
    position: "relative",
    backgroundColor: colors.royalBlue,
  },
  horizontalLine: {
    width: "100%",
    height: 1,
    backgroundColor: "#dbd6d6",
  },
  matchCardRow: {
    flexDirection: "row",
    gap: 10,
  },
  matchCardBtnText: {
    color: colors.white,
    fontFamily: "PlusJakartaSansMedium",
  },

  matchCardVsText: {
    color: "#02205f",
    fontFamily: "PlusJakartaSansBold",
    fontSize: 22,
  },
  matchTeamNAmeLogo: {
    alignItems: "center",
    gap: 3,
    flex: 1,
  },
});

export default Dashboard;
