import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { colors } from "../../../components/reusable/colour";

function Venue({
  handlegetVenuedata,
  Index,
  VenueList,
  Nameflag,
  VenueObject,
}) {
  const [address, setAddress] = useState("");
  const [googleApiLoaded, setGoogleApiLoaded] = useState(false);
  const [VenueName, setVenueName] = useState("");
  const [Venuelist, setVenuelist] = useState([]);
  const [SelectedIndex, setSelectedIndex] = useState("");
  const [venuedata, setVenuedata] = useState({});
  useEffect(() => {
    if (!window.google) {
      console.log("hellogoogle2");
      // Load the Google Maps API if it's not already loaded
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBAz20ZT4-ncCs47i92sSAVmDqhCYpB1hY&libraries=places`;
      script.onload = () => {
        setGoogleApiLoaded(true);
      };
      document.body.appendChild(script);
    } else {
      console.log("hellogoogle");
      setGoogleApiLoaded(true);
    }
    console.log("VenueObject", VenueObject, Index, VenueList);
    setVenuelist(VenueList);
    if (VenueObject && Object.keys(VenueObject).length > 0) {
      setVenuedata(VenueObject);
    }
    setSelectedIndex(Index);
  }, [Index, VenueList, VenueObject]);

  const handleSelect = async (address) => {
    console.log("handleSelect", address);
    // setVenuedata({ venuedata, Address: address });
    console.log("googleApiLoaded", Venuelist, googleApiLoaded);
    try {
      const results = await geocodeByAddress(address);
      console.log("GetResults", results);
      let area = "";
      const city = results[0].address_components.find((component) =>
        component.types.includes("locality")
      );
      if (city) {
        results[0].address_components.forEach((component) => {
          if (
            (component.types.includes("sublocality_level_1") ||
              component.types.includes("sublocality")) &&
            component.long_name !== city.long_name
          ) {
            area = component.long_name;
          }
        });
      }
      console.log("AreaFiled", area);
      const { lat, lng } = await getLatLng(results[0]);
      console.log("hellolatlang", address, lat, lng);
      // let VenueObj = {
      //   Name: VenueName,
      //   Address: address,
      //   Lat: lat,
      //   Lng: lng,
      // };
      setAddress(address);
      let Data = JSON.parse(JSON.stringify(Venuelist));
      setVenuedata({ venuedata, Address: address });
      Data[SelectedIndex].Address = address;
      Data[SelectedIndex].Coordinates = {
        Lattitude: lat.toString(),
        Longitude: lng.toString(),
      };

      handlegetVenuedata(Data, area);
      // setVenueName("");
    } catch (error) {
      console.log("Error geocoding address:", error);
    }
  };

  if (!googleApiLoaded) {
    return <div>Loading...</div>;
  }

  const handleChangeName = (e) => {
    console.log("targetvalue", e.target.value);
    setVenuedata({ venuedata, Name: e.target.value });
    let Data = JSON.parse(JSON.stringify(Venuelist));
    Data[SelectedIndex].Name = e.target.value;
    // setVenueName(e.target.value);

    setVenuelist(Data);
    handlegetVenuedata(Data);
  };

  return (
    <>
      <div
        style={{
          flexDirection: "row",
          width: "100%",
          gap: 15,
          display: "flex",
        }}
      >
        {Nameflag !== undefined ? (
          <div style={{ flex: 1 }}>
            <input
              type="text"
              onChange={(e) => handleChangeName(e)}
              value={venuedata?.Name}
              placeholder="Enter name for the Venue"
              style={{
                width: "97%",
                height: "34px",
                border: "1px solid",
                borderColor: "rgb(85, 85, 85)",
                color: colors.white,
                paddingLeft: "10px",
                borderRadius: "5px",
                fontSize: "15px",
                fontFamily: "PlusJakartaSansRegular",
                background: colors.royalBlue,
              }}
            />
          </div>
        ) : null}
        <div style={{ flex: 1 }}>
          <PlacesAutocomplete
            value={address}
            onChange={(text) => {
              console.log("Doremon", text);
              setVenuedata({ venuedata, Address: text });
              setAddress(text);
            }}
            onSelect={handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <input
                  type="text"
                  {...getInputProps({
                    placeholder: "Search location in Google maps",
                  })}
                  style={{
                    width: "96%",
                    height: "30px",
                    border: "1px solid",
                    borderRadius: "5px",
                    paddingLeft: "10px",
                    fontSize: "14px",
                    fontFamily: "PlusJakartaSansRegular",
                    borderColor: colors.darkGray,
                    color: colors.white,
                    background: colors.royalBlue,
                  }}
                  value={venuedata?.Address}
                  // onChange={(e) => {
                  //   const value = e.target.value;
                  //   console.log("venuedata", value);
                  //   // setVenuedata({ venuedata, Address: e.target.value });
                  // }}
                />
                {suggestions && suggestions.length > 0 && (
                  <div
                    style={{
                      borderRadius: "0 0 10px 10px",
                      border: "1px solid grey",
                      fontSize: "14px",
                      // borderColor: colors.darkGray,
                      padding: "10px",
                      width: "96%",
                      // backgroundColor: colors.bgColor,
                    }}
                  >
                    {loading ? <div>Loading...</div> : null}
                    {suggestions.map((suggestion) => {
                      const style = {
                        backgroundColor: suggestion.active
                          ? colors.aqua
                          : colors.royalBlue,
                        fontFamily: "PlusJakartaSansExtraLight",
                        color: suggestion.active
                          ? colors.bgColor
                          : colors.white,
                        paddingbottom: "3px",
                      };
                      return (
                        <div {...getSuggestionItemProps(suggestion, { style })}>
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </PlacesAutocomplete>
        </div>
      </div>
    </>
  );
}

export default Venue;
